import React from 'react'
import styled from 'styled-components'

const CircleLoaderStyle = styled.div`
  .artboard {
    padding-top: 3.2rem;
  }
  .domino > div {
    border-radius: 8px;
    display: inline-flex;
    transform: rotate(45deg);
    width: 30px;
    height: 30px;
    animation: domino-effect 2.4s infinite ease-in-out;
  }
  .domino > div:nth-child(1) {
    background: #f66929;
  }
  .domino > div:nth-child(1):after {
    background: #f66929;
    animation-delay: 0.6s;
  }
  .domino > div:nth-child(2) {
    background: #5f3bb2;
  }
  .domino > div:nth-child(2):after {
    background: #5f3bb2;
    animation-delay: 1.2s;
  }
  .domino > div:nth-child(3) {
    background: #a84e6d;
  }
  .domino > div:nth-child(3):after {
    background: #a84e6d;
    animation-delay: 1.8s;
  }
  .domino > div:nth-child(4) {
    background: #f66929;
  }
  .domino > div:nth-child(4):after {
    background: #f66929;
    animation-delay: 2.4s;
  }
  .domino > div:nth-child(5) {
    background: #5f3bb2;
  }
  .domino > div:nth-child(5):after {
    background: #5f3bb2;
    animation-delay: 3s;
  }
  .domino > div:nth-child(1) {
    margin: 6px;
    animation-delay: 0.6s;
  }
  .domino > div:nth-child(2) {
    margin: 6px;
    animation-delay: 1.2s;
  }
  .domino > div:nth-child(3) {
    margin: 6px;
    animation-delay: 1.8s;
  }
  .domino > div:nth-child(4) {
    margin: 6px;
    animation-delay: 2.4s;
  }
  .domino > div:nth-child(5) {
    margin: 6px;
    animation-delay: 3s;
  }
  .domino > div:after {
    content: '';
    position: relative;
    top: 0;
    left: -34px;
    opacity: 0;
    border-radius: 8px;
    width: 30px;
    height: 30px;
    animation: illusion 2.4s infinite ease-in-out;
  }

  @keyframes beat {
    to {
      transform: scale(1.4);
    }
  }

  @keyframes spin {
    to {
      transform: rotate(140deg);
    }
  }

  @keyframes domino-effect {
    20%,
    100% {
      transform: rotate(135deg);
    }
  }

  @keyframes illusion {
    0%,
    25% {
      opacity: 1;
    }
    26.10%,
    33.33%,
    100% {
      opacity: 0;
    }
  }
`

const CircleLoader = () => {
  return (
    <CircleLoaderStyle>
      <div className="artboard">
        <div className="domino">
          <div />
          <div />
          <div />
          <div />
        </div>
      </div>
    </CircleLoaderStyle>
  )
}

export default CircleLoader
