import { createAction } from '@reduxjs/toolkit'
import { SWAP_TAB } from './types'

// fired once when the app reloads but before the app renders
// allows any updates to be applied to store data loaded from localStorage
export const updateVersion = createAction<void>('global/updateVersion')

export const setCryptoUSDRates = createAction<{ cryptoUSDRates: Map<string, number> }>('global/setCryptoUSDRates')
export const updateCryptoUSDRates = createAction<{ key: string; usdRate: number }>('global/updateCryptoUSDRates')
export const setSwapSelectedTab = createAction<{ selectedTab: SWAP_TAB }>('swap/setSwapSelectedTab')

export default updateVersion
