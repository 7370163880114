import { CurrencyAmount } from '@raydium-io/raydium-sdk'
import { createReducer } from '@reduxjs/toolkit'
import _ from 'lodash'
import { ACTION_STEP_STATE, POOL_STATES } from 'state/pools/types'
import { AmountKey } from 'views/Pools/type'
import {
  getPoolsList,
  resetPoolState,
  setActionState,
  setActiveStepIndex,
  setFarmDeposit,
  setLpBalanceState,
  setPairLiquidityState,
  setPoolAddress,
  setPoolChainId,
  setStepResult,
  setSteps,
  updatePoolPageState,
  setUserShare,
  setUserAssets,
  setFarmPendingReward,
  resetPoolAssets,
  resetFarmsAssets,
  setFocusSide,
  setGenericStats,
  setAutoRefresh,
} from './actions'
import { PoolsState } from './types'

export const initialState: PoolsState = {
  pools: [],
  poolChainId: null,
  steps: [],
  pageState: POOL_STATES.DEFAULT,
  activeStepIndex: 0,
  actionStepState: {
    status: ACTION_STEP_STATE.IN_PROGRESS,
    message: '',
  },
  routerAddress: '',
  pairAddress: '',
  liquidity: {
    [AmountKey.INPUT]: '0',
    [AmountKey.OUTPUT]: '0',
  },
  lpBalance: '0',
  lpDeposited: '0',
  farmAddress: '',
  userShare: '0',
  userAssets: {
    baseCurrency: '0',
    quoteCurrency: '0',
  },
  pendingReward: '0',
  focusSide: 'coin1',
  totalLiquidity: 0,
  totalVolume: 0,
  autoRefresh: false,
}

export default createReducer(initialState, (builder) =>
  builder
    .addCase(setGenericStats, (state, { payload: { liquidity, volume } }) => {
      // (parseFloat(state.totalLiquidity.toString())
      const totalLiquidity = parseInt(parseFloat(liquidity.toString()).toString())
      // (parseFloat(state.totalVolume.toString())
      const totalVolume = parseInt(parseFloat(volume.toString()).toString())
      return {
        ...state,
        totalLiquidity,
        totalVolume,
      }
    })
    .addCase(setAutoRefresh, (state, { payload: { refresh } }) => {
      return {
        ...state,
        autoRefresh: refresh,
      }
    })
    .addCase(getPoolsList, (state, { payload: { list } }) => {
      const poolsList = _.cloneDeep(list)
      return {
        ...state,
        pools: poolsList,
      }
    })
    .addCase(setPoolChainId, (state, { payload: { chainId } }) => {
      return {
        ...state,
        poolChainId: chainId,
      }
    })
    .addCase(setSteps, (state, { payload: { steps } }) => {
      return {
        ...state,
        steps,
      }
    })
    .addCase(updatePoolPageState, (state, { payload: { poolPageState } }) => {
      return {
        ...state,
        pageState: poolPageState,
      }
    })
    .addCase(setActionState, (state, { payload: { actionStepState } }) => {
      return {
        ...state,
        actionStepState,
      }
    })
    .addCase(setStepResult, (state, { payload: { actionStepState } }) => {
      const updatedSteps = _.cloneDeep(state.steps)
      const { payload } = actionStepState
      const index = state.activeStepIndex
      updatedSteps[index].result = payload
      return {
        ...state,
        steps: updatedSteps,
      }
    })
    .addCase(setActiveStepIndex, (state, { payload: { resultActionStepState } }) => {
      const updatedSteps = _.cloneDeep(state.steps)
      updatedSteps[state.activeStepIndex].result = resultActionStepState.payload
      return {
        ...state,
        activeStepIndex: state.activeStepIndex + 1,
        steps: updatedSteps,
        actionStepState: {
          status: ACTION_STEP_STATE.IN_PROGRESS,
          message: '',
        },
      }
    })
    .addCase(setPoolAddress, (state, { payload: { pairAddress, routerAddress, farmAddress } }) => {
      return {
        ...state,
        routerAddress,
        pairAddress,
        farmAddress,
      }
    })
    .addCase(resetPoolState, (state) => {
      return {
        ...state,
        steps: [],
        pageState: POOL_STATES.DEFAULT,
        activeStepIndex: 0,
        actionStepState: {
          status: ACTION_STEP_STATE.IN_PROGRESS,
          message: '',
        },
      }
    })
    .addCase(setPairLiquidityState, (state, { payload: { liquidity } }) => {
      return {
        ...state,
        liquidity,
      }
    })
    .addCase(setLpBalanceState, (state, { payload: { lpBalance } }) => {
      return {
        ...state,
        lpBalance,
      }
    })
    .addCase(setUserShare, (state, { payload: { userShare } }) => {
      return {
        ...state,
        userShare,
      }
    })
    .addCase(setUserAssets, (state, { payload: { userAssets } }) => {
      return {
        ...state,
        userAssets: userAssets,
      }
    })
    .addCase(setFarmDeposit, (state, { payload: { lpDeposited } }) => {
      return {
        ...state,
        lpDeposited,
      }
    })
    .addCase(setFarmPendingReward, (state, { payload: { pendingReward } }) => {
      return {
        ...state,
        pendingReward,
      }
    })
    .addCase(resetPoolAssets, (state) => {
      return {
        ...state,
        userShare: '0',
        userAssets: {
          baseCurrency: '0',
          quoteCurrency: '0',
        },
      }
    })
    .addCase(resetFarmsAssets, (state) => {
      return {
        ...state,
        lpDeposited: '0',
        pendingReward: '0',
      }
    })
    .addCase(setFocusSide, (state, { payload: { focusSide } }) => {
      return {
        ...state,
        focusSide: focusSide,
      }
    }),
)
