import { Box, Flex } from 'components/Box'
import { Text } from 'components/Text'
import React, { useEffect, useState } from 'react'
import { Modal } from 'widgets/Modal'
import { NoteText, SwapFooter, WarningIcon, WarningInfo, WarningText } from './styles'
import SwapInfoCard from './components/SwapInfoCard'
import useTheme from 'hooks/useTheme'
import {
  useActiveStepIndex,
  useChainInfo,
  useCurrency,
  useSteps,
  useSwapState,
  useTransactionId,
} from 'state/swap/selectors'
import Button from 'components/Button'
import { useSwapActionHandlers } from 'state/swap/hooks'
import { SWAP_STATES } from 'state/swap/types'
import { useTranslation } from 'contexts/Localization'
import { Field } from 'state/types'
import { usePostLogs } from 'utils/postLogs'
import useSolanaTPS from 'hooks/useSolanaTps'
import { SupportedChainId } from 'config/constants/chains'
import { useUpdateTransaction } from 'views/Swap/Default/hooks/postTransactions'
import { TransactionStatus } from 'config/enums/transactionStatus'

interface ITokenListProps {
  handleDismiss: () => void
}

const SwapProgressModal: React.FC<ITokenListProps> = (props?: ITokenListProps) => {
  const { handleDismiss } = props
  const { activeStepIndex } = useActiveStepIndex()
  const { onResetSwapState } = useSwapActionHandlers()
  const { steps } = useSteps()
  const pageState = useSwapState()
  const { t } = useTranslation()
  const inputCurrency = useCurrency(Field.INPUT)
  const outputCurrency = useCurrency(Field.OUTPUT)
  const { postLogs } = usePostLogs()
  const { tps } = useSolanaTPS()
  const { updateTransaction } = useUpdateTransaction()
  const { transactionId } = useTransactionId()

  const [, setGoToSlide] = useState(0)
  const { theme } = useTheme()

  const inputChainId = useChainInfo(Field.INPUT)
  const outputChainId = useChainInfo(Field.OUTPUT)

  useEffect(() => {
    setGoToSlide(activeStepIndex)
  }, [activeStepIndex])

  const [c, setC] = useState<any>()

  useEffect(() => {
    const carousel = ($('#carousel') as any).flipster({
      style: 'carousel',
      spacing: -0.5,
      nav: false,
      buttons: false,
      click: false,
      keyboard: false,
      scrollwheel: false,
      touch: false,
    })

    setC(carousel)

    /**
     * use following methods to set next and previous one
     * carousel.flipster('next') - For next slide
     * carousel.flipster('prev') - For previous slide
     */
  }, [])
  useEffect(() => {
    if (c) c.flipster('jump', activeStepIndex)
  }, [activeStepIndex, c])

  useEffect(() => {
    if (pageState === SWAP_STATES.SWAP_COMPLETED) {
      postLogs('SUCCESS', [])
      updateTransaction(transactionId, TransactionStatus.DONE)
    }
  }, [pageState])

  return (
    <Modal hideCloseButton bodyPadding={'0px'} onDismiss={handleDismiss} title={''} minWidth="500px">
      <WarningText
        isShow={
          steps[activeStepIndex].chainId === SupportedChainId.SOLANA &&
          tps &&
          tps < 2000 &&
          pageState !== SWAP_STATES.SWAP_COMPLETED
        }
        style={{ textAlign: 'center' }}
      >
        <WarningIcon /> {t('Warning')}
        <WarningInfo>
          {t('The Solana transactions per second are below')}
          <br />
          {t('Current TPS').replace('%tps', `${tps}`)}
        </WarningInfo>
      </WarningText>
      <Flex className="mx-auto" width="100%" justifyContent={'center'} alignItems="center">
        <Box width="100%">
          <div id="carousel">
            <ul className="flip-items" style={{ height: '100%' }}>
              {steps.map((step, index) => (
                <li key={index} data-flip-title={step.name} style={{ height: '100%' }}>
                  <Flex height={'100%'} alignItems={'flex-end'}>
                    <SwapInfoCard step={step} index={index} handleDismiss={handleDismiss} />
                  </Flex>
                </li>
              ))}
            </ul>
          </div>
        </Box>
      </Flex>
      <Box mt="2rem">
        <SwapFooter>
          <Text
            className="title"
            color={pageState === SWAP_STATES.SWAP_COMPLETED ? theme.colors.success : theme.colors.text}
          >
            {pageState === SWAP_STATES.SWAP_COMPLETED
              ? t('Successfully swapped').replace('%f', inputCurrency.symbol).replace('%t', outputCurrency.symbol)
              : steps[activeStepIndex].subTitle === 'Converting token'
              ? t(`${steps[activeStepIndex].subTitle}`)
                  .replace('%f', inputCurrency.symbol)
                  .replace('%t', outputCurrency.symbol)
              : t(`${steps[activeStepIndex].subTitle}`)}
          </Text>
          {pageState !== SWAP_STATES.SWAP_COMPLETED && (
            <>
              <Text className="sub-title">{Math.floor((Number(activeStepIndex) / steps.length) * 100)}%</Text>
              <Box background={`${theme.colors.success}3B`} width="100%" height="12px" borderRadius={'6px'}>
                <Box
                  background={theme.colors.success}
                  width={`${Math.floor((Number(activeStepIndex) / steps.length) * 100)}%`}
                  height="12px"
                  borderRadius={'6px'}
                />
              </Box>
            </>
          )}
          {pageState === SWAP_STATES.SWAP_COMPLETED && (
            <Button
              m="1rem auto 0 auto"
              p="0.4rem 1.8rem"
              borderRadius={'12px'}
              onClick={() => {
                onResetSwapState()
                handleDismiss()
              }}
            >
              {t('Done')}
            </Button>
          )}
          <NoteText
            isShow={
              steps[activeStepIndex].chainId === SupportedChainId.SOLANA &&
              inputChainId !== outputChainId &&
              outputChainId === SupportedChainId.SOLANA &&
              pageState !== SWAP_STATES.SWAP_COMPLETED
                ? true
                : false
            }
          >
            {t('Note: There will be several transactions for approval')}
          </NoteText>
        </SwapFooter>
      </Box>
    </Modal>
  )
}

export default SwapProgressModal
