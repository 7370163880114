import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 37.208 32.387" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="0.154"
          y1="0.384"
          x2="0.735"
          y2="1.143"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#ff6c21" />
          <stop offset="0.025" stopColor="#f66929" />
          <stop offset="0.254" stopColor="#ac4f6a" />
          <stop offset="0.418" stopColor="#7e3f92" />
          <stop offset="0.5" stopColor="#6c39a2" />
          <stop offset="1" stopColor="#403fd8" />
        </linearGradient>
      </defs>
      <g id="Group_16" data-name="Group 16">
        <path
          id="Path_28"
          data-name="Path 28"
          d="M3812.987,327.954l-3.754-.463-7.9-18.933-14.656,19.955,15.408-26.793Zm-6.671-19.859,8.984,21.616-20.47-2.667-2.345,3.118,28.713,3.782Zm-16.146,23.146,12.5-16.374-1.521-3.643-17.156,22.883h29.227Z"
          transform="translate(-3783.99 -301.72)"
          fill="url(#linear-gradient)"
        />
      </g>
    </Svg>
  )
}

export default Icon
