import { ChainDetailsWithTokens, TokenInfoWithChainDetails } from 'config/allbridge/tokens-info'
import { SupportedChainId } from 'config/constants/chains'
import BlockchainNetworks from 'config/constants/network'
import { IBlockchainNetworks } from 'config/constants/types'
import { CurrencyAmount } from 'config/entities'
import { useSelector } from 'react-redux'
import { AppState } from 'state'
import { SWAP_STATES } from 'state/swap/types'
import { Field } from 'state/types'

/**
 * Returns Swap Page State
 */
export function useStableSwapState(): SWAP_STATES {
  const state = useSelector<AppState, AppState['stableSwap']>((s) => s.stableSwap)
  return state.stableSwapPageState
}

/**
 * Returns selected currency
 * @param field
 */
export function useCurrency(field: Field): TokenInfoWithChainDetails {
  const state = useSelector<AppState, AppState['stableSwap']>((s) => s.stableSwap)
  return state[field].currency
}

/**
 * Returns all Currencies
 * @param field
 */
export function useCurrencies(field: Field): TokenInfoWithChainDetails[] {
  const state = useSelector<AppState, AppState['stableSwap']>((s) => s.stableSwap)
  return state[field].currencies
}

/**
 * Returns supportive chains for all bridge
 */
export function useStableChainList() {
  const state = useSelector<AppState, AppState['stableSwap']>((s) => s.stableSwap)
  return state.supportedChains
}

/**
 * Returns amount entered by user from stable swap
 */
export function useInputAmount(): CurrencyAmount {
  const state = useSelector<AppState, AppState['stableSwap']>((s) => s.stableSwap)
  return state.inputAmount
}

/**
 * Returns supportive chains detail
 */
export function useStableChainInfo(key): ChainDetailsWithTokens | null {
  const state = useSelector<AppState, AppState['stableSwap']>((s) => s.stableSwap)
  return state?.supportedChains ? state?.supportedChains[key] : null
}

/**
 * Returns amount entered by user from stable swap
 */
export function useSwapFee(): CurrencyAmount {
  const state = useSelector<AppState, AppState['stableSwap']>((s) => s.stableSwap)
  return state.fee
}

/**
 * Returns Wallet of selected Chain
 * @param field
 */
export function useGetBlockchainInfo(field: Field): IBlockchainNetworks {
  const state = useSelector<AppState, AppState['stableSwap']>((s) => s.stableSwap)
  return BlockchainNetworks.find((network) => network.chainId === state[field]?.chainId)
}

/**
 * Returns Wallet of selected Chain
 * @param field
 */
export function useChainInfo(field: Field): SupportedChainId {
  const state = useSelector<AppState, AppState['stableSwap']>((s) => s.stableSwap)
  return state[field].chainId
}

/**
 * Returns received amount  from stable swap
 */
export function useReceivedAmount(): CurrencyAmount {
  const state = useSelector<AppState, AppState['stableSwap']>((s) => s.stableSwap)
  return state.receivedAmount
}

export function useActiveStepIndex() {
  const state = useSelector<AppState, AppState['stableSwap']>((s) => s.stableSwap)
  return state.activeStepIndex
}

export function useTransactionId() {
  const state = useSelector<AppState, AppState['stableSwap']>((s) => s.stableSwap)
  return state.transactionId
}

export function useActiveStepState() {
  const state = useSelector<AppState, AppState['stableSwap']>((s) => s.stableSwap)
  return state.actionStepState
}

export function useActiveStep(activeStepIndex: number) {
  const state = useSelector<AppState, AppState['stableSwap']>((s) => s.stableSwap)
  return state.steps[activeStepIndex]
}

export function useSteps() {
  const state = useSelector<AppState, AppState['stableSwap']>((s) => s.stableSwap)
  return state.steps
}
