import random from 'lodash/random'

export const getEndpoint = () => {
  return 'https://chaotic-sly-flower.solana-mainnet.discover.quiknode.pro/58d46671a6b71d0b85d1b3f6c52b1f7d0f8fe79c/'
}

const bscNodes = [
  'https://bsc-dataseed.binance.org',
  'https://bsc-dataseed1.defibit.io',
  'https://bsc-dataseed1.ninicoin.io',
]
export const getBSCNodeURL = (): string => {
  const randomIndex = random(0, bscNodes.length - 1)
  return bscNodes[randomIndex]
}

const avaxNodes = ['https://api.avax.network/ext/bc/C/rpc']
export const getAvaxNodeUrl = (): string => {
  const randomIndex = random(0, avaxNodes.length - 1)
  return avaxNodes[randomIndex]
}

const fantomNodes = ['https://rpc.ftm.tools/']
export const getFantomNodeUrl = (): string => {
  const randomIndex = random(0, avaxNodes.length - 1)
  return fantomNodes[randomIndex]
}

const polygonNodes = ['https://rpc-mainnet.maticvigil.com/v1/71b393674f070620b17f5605ca77fe6c9cce093f']
export const getPolygonNodeURL = (): string => {
  const randomIndex = random(0, polygonNodes.length - 1)
  return polygonNodes[randomIndex]
}

// Array of available nodes to connect to
export const ethNodes = [
  'https://mainnet.infura.io/v3/4e3e9eec0461480e918bb370c32ebcc0',
  'https://mainnet.infura.io/v3/49333d3c0b36439fb20a6ea0a6e99c93',
]

export const getETHNodeUrl = (): string => {
  const randomIndex = random(0, ethNodes.length - 1)
  return ethNodes[randomIndex]
}

export default getETHNodeUrl

export const getEVMNodeURL = {
  1: getETHNodeUrl(),
  56: getBSCNodeURL(),
  137: getPolygonNodeURL(),
  43114: getAvaxNodeUrl(),
  250: getFantomNodeUrl(),
}
