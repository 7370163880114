import { createAction } from '@reduxjs/toolkit'
import { Field } from 'state/types'
import { IWalletInfo, WalletStateEnum } from './types'

export const updateWalletStatus = createAction<{ field: Field; walletInfo: IWalletInfo | null }>(
  'swap/updateWalletStatus',
)

export const switchWallets = createAction<void>('swap/switchWallets')
export const setWalletState = createAction<{ walletState: WalletStateEnum }>('swap/setWalletState')
