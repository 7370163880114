import { Box } from 'components/Box'
import { CardWrapper } from './styles'
import { CardPropsInterface } from './type'

const Card: React.FC<CardPropsInterface> = (props) => {
  const { children } = props
  return (
    <CardWrapper {...props}>
      <Box className="inner-section">{children}</Box>
    </CardWrapper>
  )
}

export default Card
