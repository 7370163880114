import * as Sentry from '@sentry/react'
import { useWallet } from '@solana/wallet-adapter-react'
import { useEffect } from 'react'
import useActiveWeb3React from './useActiveWeb3React'

function useSentryUser() {
  const { account } = useActiveWeb3React()
  const { publicKey } = useWallet()
  useEffect(() => {
    let accountForUser = null
    if (account && publicKey) {
      accountForUser = `${account}-${publicKey.toBase58()}`
    } else if (account) {
      accountForUser = account
    } else if (publicKey) {
      accountForUser = publicKey.toBase58()
    }

    if (accountForUser) {
      Sentry.setUser({ account: accountForUser })
    }
  }, [account, publicKey])
}

export default useSentryUser
