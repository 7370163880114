import { createGlobalStyle } from 'styled-components'

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme {}
}

const GlobalStyle = createGlobalStyle`
  * {
    font-family: sans-serif;
  }
  body {
    background-color: ${({ theme }) => theme.colors.background};
    margin: 0;
    img {
      height: auto;
      max-width: 100%;
    }
  }
  .pointer{
    cursor : pointer !important;
  }
  .row{
    margin-right : 0px !important;
    margin-left : 0px !important;
  }
  input:focus {
    outline:none;
  }

  .nav-link {
    padding: 0.4rem 0.75rem !important;
    height : 100%;
    &:hover {
      opacity: 0.5;
    }
  }

  .nav-link.disabled {
    opacity: 0.4 !important;
  }
  .nav-tabs .nav-link.active {
    background-color: #5F5E75 !important;
    &:hover {
      transform: scale(1);
    }
  }
  .nav {
    flex-wrap: nowrap !important;
    overflow: auto;
  }


  &::-webkit-scrollbar {
    height: 4px;
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #ffffff40;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #ffffff30;
  }
  a {
    text-decoration: none;
  }

  .flipster__item__content {
    height: 100%;
  }
  .scale-on-hover {
    transition: 0.1s ease;
    &:hover {
      transform: scale(1.1);
    }
  }

  .fade-on-hover {
    transition: 0.1s ease;
    &:hover {
      opacity: 0.8;
    }
  }

  ::selection{
    background-color: ${({ theme }) => theme.colors.primary};
    color: white;
  }

  .tooltip {
    &-top-left {
      @media (max-width: 500px) {
        width: 100%;
        max-width: calc(100% - 20px);
        left: 10px !important;
      }

      &:before,
      &:after {
        @media (max-width: 500px) {
          left: 113px !important;
        }
      }
    }

    &-bottom {
      @media (max-width: 750px) {
        width: 100%;
        max-width: 540px;
        right: 10px !important;
        left: initial !important;
        margin-top: 12px !important;
      }

      @media (max-width: 500px) {
        max-width: calc(100% - 20px);
      }

      &:before,
      &:after {
        @media (max-width: 750px) {
          left: initial !important;
          right: 50%;
          transform: translateX(35px);
        }

        @media (max-width: 500px) {
          transform: translateX(81px);
        }
      }
    }
  }

  .form-check-input:checked {
    background-color: ${({ theme }) => theme.colors.textOrange} !important;
    border-color: ${({ theme }) => theme.colors.textOrange} !important;
  }
  .form-check-input:focus {
    box-shadow: none !important;
  }
  .form-check-input {
    background-color : black !important;
    border-color: ${({ theme }) => theme.colors.textOrange} !important;
  }
  .form-check-input:checked[type=checkbox] {
    background-image: url(./images/tickIcon.png) !important;
  }
}
.progress-bar {
  background-image: linear-gradient(96deg, #ff6c21 0%, #403fd8 100%) !important;
}
.progress{
  height: 7px !important;
}
  
`

export default GlobalStyle
