import { createAction } from '@reduxjs/toolkit'
import { Percent } from 'config/entities'

export const updateUserSlippageTolerance = createAction<{ userSlippageTolerance: Percent }>(
  'user/updateUserSlippageTolerance',
)

export const toggleTheme = createAction<void>('user/toggleTheme')

export const updateGasPrice = createAction<{ gasPrice: string }>('user/updateGasPrice')

export const hidePhishingWarningBanner = createAction<void>('user/hidePhishingWarningBanner')
