import { Contract } from '@ethersproject/contracts'
import type { Signer } from '@ethersproject/abstract-signer'
import type { Provider } from '@ethersproject/providers'
import { JsonRpcSigner, Web3Provider } from '@ethersproject/providers'
import { getAddress } from '@ethersproject/address'
import { AddressZero } from '@ethersproject/constants'
import { simpleRpcProvider } from './providers'
import { BigintIsh } from 'config/constants/types'
import JSBI from 'jsbi'
import { BigNumber } from '@ethersproject/bignumber'
import BlockchainNetworks from 'config/constants/network'
import { CurrencyAmount } from 'config/entities'
import { IRoute } from 'state/swap/types'

export const isNumberValid = (value) => {
  const regex = new RegExp('^[0-9]*[.,]?[0-9]*$')
  if (regex.test(value)) {
    return true
  } else {
    return false
  }
}

export const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
  // return x.toLocaleString()
}

// returns the check summed address if the address is valid, otherwise returns false
export function isAddress(value: any): string | false {
  try {
    return getAddress(value)
  } catch {
    return false
  }
}

// account is not optional
export function getSigner(library: Web3Provider, account: string): JsonRpcSigner {
  return library.getSigner(account).connectUnchecked()
}

// Instead of contract transaction we can send raw transaction directly.
export function getSignerForRawTransactions(library: Web3Provider): JsonRpcSigner {
  return library.getSigner()
}

// account is optional
export function getProviderOrSigner(library: Web3Provider, account?: string): Web3Provider | JsonRpcSigner {
  return account ? getSigner(library, account) : library
}

export function calculateGasMargin(value: BigNumber): BigNumber {
  return value.mul(BigNumber.from(10000).add(BigNumber.from(1000))).div(BigNumber.from(10000))
}
export function calculateRelayerGasMargin(value: BigNumber): BigNumber {
  return value.mul(BigNumber.from(10000).add(BigNumber.from(5000))).div(BigNumber.from(10000))
}

// account is optional
export function getContract(address: string, ABI: any, signer?: Signer | Provider): Contract {
  if (!isAddress(address) || address === AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }

  return new Contract(address, ABI, signer ?? simpleRpcProvider)
}

export function parseBigintIsh(bigintIsh: BigintIsh): JSBI {
  return bigintIsh instanceof JSBI ? bigintIsh : JSBI.BigInt(bigintIsh)
}

export const animateValue = (setNumber, start, end, duration) => {
  let startTimestamp = null
  const step = (timestamp) => {
    if (!startTimestamp) startTimestamp = timestamp
    const progress = Math.min((timestamp - startTimestamp) / duration, 1)
    setNumber(Math.floor(progress * (start + end) + start))
    if (progress < 1) {
      window.requestAnimationFrame(step)
    }
  }
  window.requestAnimationFrame(step)
}

export const getBlockChainNetwork = (chainId) => {
  return BlockchainNetworks.find((network) => {
    return network.chainId === chainId
  })
}
export const isNativeCurrency = (currency) => {
  return (
    currency.address === '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee' ||
    currency.address === '11111111111111111111111111111111'
  )
}

export function normalizeAmount(
  amount: CurrencyAmount,
  decimals: number,
  converterCurrencyDecimals = 0,
): CurrencyAmount {
  let normalizedAmount = amount
  if (decimals > 8) {
    const bigNumber = normalizedAmount.toWei().div(10 ** (decimals - 8))
    normalizedAmount = new CurrencyAmount(parseInt(bigNumber.toString()), converterCurrencyDecimals)
  }
  return normalizedAmount
}

export function deNormalizeAmount(amount: CurrencyAmount, decimals: number): CurrencyAmount {
  let deNormalizedAmount = amount
  if (decimals > 8) {
    const bigNumber = deNormalizedAmount.toEther().times(10 ** decimals - 8)
    deNormalizedAmount = new CurrencyAmount(bigNumber, decimals)
  }
  return deNormalizedAmount
}

export function defaultAmount(amount: CurrencyAmount): CurrencyAmount {
  return amount
}

export function sortRoutes(routes: IRoute[]): IRoute[] {
  return routes.sort((a, b) => {
    return b.destinationOutputAmount.toEther().comparedTo(a.destinationOutputAmount.toEther())
  })
}
