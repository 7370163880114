import { COVALENT_API_RECENT_TX } from 'config/constants/endpoints'
import promiseRetry from 'promise-retry'
import axios from 'axios'
import { Connection } from '@solana/web3.js'

export const getTransactionReceipt = async (provider, hash, chainId, account) => {
  const receipt = await promiseRetry(
    async (retry) => {
      const txDetail = await provider.getTransaction(hash)
      if (!txDetail) {
        const url = COVALENT_API_RECENT_TX.replace(':chainId', chainId).replace(':address', account)

        const { data } = await axios(url)
        if (data.data.items.length === 0) {
          throw new Error('No Transaction found')
        }
        const tx = data.data.items[0]

        const result = await provider.getTransactionReceipt(tx.tx_hash)
        return result
        // if (tx.to_address && tx.to_address.toLowerCase() === atlasSwapContract.toLowerCase()) {

        // } else {
        //   // here means user has dropped tx.
        //   throw new Error('Transactions Cancelled')
        // }
      } else if (txDetail.hash && txDetail.blockNumber) {
        const result = await provider.getTransactionReceipt(hash)
        return result
      } else if (!txDetail.blockNumber && txDetail.hash) {
        retry(new Error('Error fetching transaction'))
      } else {
        //: TODO return error
      }
    },
    {
      retries: 10000,
      minTimeout: 1000,
      maxTimeout: 1500,
    },
  )
  if (!receipt) {
    throw new Error('Error while fetching transaction receipt.')
  }
  return receipt
}

export const getTransactionConfirmations = async (provider, hash, confirmations = 1, callback = null) => {
  const receipt = await promiseRetry(
    async (retry) => {
      const result = await provider.getTransactionReceipt(hash)
      if (result && result.confirmations >= confirmations) {
        return result
      } else if (result) {
        callback(result.confirmations)
        retry(new Error('Waiting For Confirmation'))
      } else {
        retry(new Error('Error fetching transaction'))
      }
    },
    {
      retries: 10000,
      minTimeout: 1000,
      maxTimeout: 1500,
    },
  )
  if (!receipt) {
    throw new Error('Error while fetching transaction receipt.')
  }
  return receipt
}

export const waitForSolanaTxConfirmation = async (
  txHash: string,
  commitment,
  connection,
  confirmations = 1,
  callback = null,
) => {
  const receipt = await promiseRetry(
    async (retry) => {
      const result = await connection.getTransaction(txHash, {
        commitment,
      })

      const slot = await connection.getSlot()
      const currentConfirmations = slot - result?.slot
      if (result && currentConfirmations >= confirmations) {
        return result
      } else if (result) {
        callback && callback(currentConfirmations)
        retry(new Error('Waiting For Confirmation'))
        return
      } else {
        retry(new Error('Error fetching transaction'))
        return
      }
    },
    {
      retries: 29,
      maxTimeout: 1000,
    },
  )
  if (!receipt) {
    throw new Error('transaction could not be confirmed')
  }
  return receipt
}

export const waitForSolanaVersionTxConfirmation = async (
  txHash: string,
  connection: Connection,
  confirmations = 1,
  callback = null,
) => {
  const receipt = await promiseRetry(
    async (retry) => {
      const result = await connection.getTransaction(txHash, {
        commitment: 'confirmed',
        maxSupportedTransactionVersion: 0,
      })

      const slot = await connection.getSlot()
      const currentConfirmations = slot - result?.slot
      if (result && currentConfirmations >= confirmations) {
        return result
      } else if (result) {
        callback && callback(currentConfirmations)
        retry(new Error('Waiting For Confirmation'))
        return
      } else {
        retry(new Error('Error fetching transaction'))
        return
      }
    },
    {
      retries: 29,
      maxTimeout: 1000,
    },
  )
  if (!receipt) {
    throw new Error('transaction could not be confirmed')
  }
  return receipt
}
