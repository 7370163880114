import { SupportedChainId } from 'config/constants/chains'
import { Field } from 'state/types'

export interface IWalletInfo {
  chainId: SupportedChainId
}

export interface WalletState {
  [Field.INPUT]: IWalletInfo | null
  [Field.OUTPUT]: IWalletInfo | null
  isReadyToConnect: WalletStateEnum
}

export enum WalletStateEnum {
  READY = 'READY',
  CONNECTED = 'CONNECTED',
  NOT = 'NOT',
}
