import { useCallback } from 'react'
import { Fee, FeeCollector } from 'config/enums'
import { Currency, CurrencyAmount } from 'config/entities'
import { ACTION_STEP_STATE, IRoute } from 'state/swap/types'
import {
  AddressLookupTableAccount,
  PublicKey,
  Transaction,
  TransactionMessage,
  VersionedMessage,
  VersionedTransaction,
} from '@solana/web3.js'
import { useConnection, useWallet } from '@solana/wallet-adapter-react'
import { useUserSlippageTolerance } from 'state/user/hooks'
import { SOLANA_ADDRESS, WRAPPED_SOLANA_ADDRESS } from 'config/constants'
import { waitForSolanaTxConfirmation, waitForSolanaVersionTxConfirmation } from 'utils/callHelper'
import * as Sentry from '@sentry/react'
import { getOriginalAssetSol, hexToUint8Array, transferFromSolana, tryNativeToHexString } from '@certusone/wormhole-sdk'
import { getAtlasDexSwapAddress, getWormholeBridgeAddress, getWormholeTokenBridgeAddress } from 'utils/addressHelpers'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { NETWORKS_INFO } from 'config/constants/chains'
import { zeroPad } from '@ethersproject/bytes'
import { deNormalizeAmount } from 'utils'
import { setStepResult } from 'state/swap/actions'
import { useDispatch } from 'react-redux'
import { AppDispatch } from 'state'
import JSBI from 'jsbi'
import { useIsAutomaticOnTargetChain, useRelayerFee, useTransactionId } from 'state/swap/selectors'
import { ethers } from 'ethers'
import { createAssociatedTokenAccountInstruction, getAssociatedTokenAddress } from 'views/Staking/utils/SolanaWeb3'
import axios from 'axios'
import { JUPITER_API_URL_V3 } from 'config/constants/endpoints'

const useJupiter = () => {
  const dispatch = useDispatch<AppDispatch>()

  const [userSlippageTolerance] = useUserSlippageTolerance()
  const { connection: solanaConnectionObj } = useConnection()
  const { publicKey, signTransaction } = useWallet()
  const { account, library } = useActiveWeb3React()
  const { isAutomaticOnTargetChain } = useIsAutomaticOnTargetChain()
  const { transactionId: transactionIdOfDB } = useTransactionId()
  const { relayerFee } = useRelayerFee()
  const dispatchTxHash = (dispatch, txHash) => {
    dispatch(
      setStepResult({
        actionStepState: {
          status: ACTION_STEP_STATE.IN_PROGRESS,
          message: 'IN-Progress',
          payload: {
            txid: txHash,
          },
        },
      }),
    )
  }

  const handleJupiterRoutes = useCallback(
    async (inputCurrency: Currency, outputCurrency: Currency, amount: CurrencyAmount) => {
      try {
        const inputMintAddress =
          inputCurrency.address === SOLANA_ADDRESS ? WRAPPED_SOLANA_ADDRESS : inputCurrency.address
        const outputMintAddress =
          outputCurrency.address === SOLANA_ADDRESS ? WRAPPED_SOLANA_ADDRESS : outputCurrency.address
        const urlForRoutes = `${JUPITER_API_URL_V3}/quote?inputMint=${inputMintAddress}&outputMint=${outputMintAddress}&amount=${JSBI.BigInt(
          amount.toWei().toString(),
        )}&slippageBps=${Math.ceil(+userSlippageTolerance.toFixed(3) * 100)}&swapMode=ExactIn&feeBps=${
          Fee.DEFAULT * 100
        }`

        const {
          data: { data: jupiterRoutes },
        } = await axios(urlForRoutes)

        const computedRoutes: IRoute[] = []
        for (let index = 0; index < jupiterRoutes.length; index++) {
          const route: IRoute = {
            inputCurrency: inputCurrency,
            outputCurrency: outputCurrency,
            name: jupiterRoutes[index].marketInfos[0].label,
            estimatedGas: 0,
            sourceInputAmount: amount,
            destinationOutputAmount: new CurrencyAmount(jupiterRoutes[index].outAmount, outputCurrency.decimals),
            payload: jupiterRoutes[index],
            priceImpact: parseFloat((jupiterRoutes[index].priceImpactPct * 100).toFixed(5)),
          }

          computedRoutes.push(route)
        } // end of for loop
        return computedRoutes
      } catch (error) {
        Sentry.captureException(error)
        return []
      }
    },
    [userSlippageTolerance],
  )
  const handleJupiterRoutesForRelayer = useCallback(
    async (inputCurrency: Currency, outputCurrency: Currency, amount: CurrencyAmount): Promise<CurrencyAmount> => {
      try {
        const computedRoutes = await handleJupiterRoutes(inputCurrency, outputCurrency, amount)
        return computedRoutes.length > 0
          ? computedRoutes[0].destinationOutputAmount
          : new CurrencyAmount(0, outputCurrency.decimals)
      } catch (error) {
        Sentry.captureException(error)
        return new CurrencyAmount(0, outputCurrency.decimals)
      }
    },
    [userSlippageTolerance],
  )

  const handleJupiterSwap = useCallback(
    async (routeInfo: IRoute) => {
      try {
        const urlForSwap = `${JUPITER_API_URL_V3}/swap`
        const body = {
          route: routeInfo.payload,
          userPublicKey: publicKey.toBase58(),
          wrapUnwrapSOL: true,
          feeAccount: FeeCollector.SOL_FEE_COLLECTOR,
        }

        const {
          data: { swapTransaction },
        } = await axios({
          method: 'post',
          url: urlForSwap,
          data: body,
        })
        const transaction = VersionedTransaction.deserialize(Buffer.from(swapTransaction, 'base64'))

        const addressLookupTableAccounts = await Promise.all(
          transaction.message.addressTableLookups.map(async (lookup) => {
            return new AddressLookupTableAccount({
              key: lookup.accountKey,
              state: AddressLookupTableAccount.deserialize(
                await solanaConnectionObj.getAccountInfo(lookup.accountKey).then((res) => res.data),
              ),
            })
          }),
        )
        const message = TransactionMessage.decompile(transaction.message, {
          addressLookupTableAccounts: addressLookupTableAccounts,
        })
        transaction.message = message.compileToV0Message(addressLookupTableAccounts)

        const signedTx = await signTransaction(transaction)
        const transactionId = await solanaConnectionObj.sendRawTransaction(signedTx.serialize(), {
          skipPreflight: false,
        })

        dispatchTxHash(dispatch, transactionId)
        const receipt = await waitForSolanaVersionTxConfirmation(transactionId, solanaConnectionObj)
        let postBalanceAmount = 0
        if (routeInfo.inputLockCurrency) {
          const postBalance = receipt.meta.postTokenBalances.find(
            (balance) => balance.mint === routeInfo.inputLockCurrency.address && balance.owner === publicKey.toBase58(),
          )
          postBalanceAmount = postBalance ? postBalance.uiTokenAmount.amount : '0'
        }

        return {
          status: ACTION_STEP_STATE.OK,
          message: 'success',
          payload: {
            txid: transactionId,
            outputAmount: postBalanceAmount,
          },
        }
      } catch (error) {
        Sentry.captureException(error)
        return {
          status: ACTION_STEP_STATE.ERROR,
          message: error['message'],
          error: error,
        }
      }
    },
    [userSlippageTolerance, dispatch],
  )

  const lockOnSolanaSide = useCallback(
    async (routeInfo: IRoute, { outputAmount }) => {
      try {
        const tokenAccountsForLockCurrency = await solanaConnectionObj.getParsedTokenAccountsByOwner(
          publicKey,
          {
            mint: new PublicKey(routeInfo.inputLockCurrency.address),
          },
          'confirmed',
        )

        if (tokenAccountsForLockCurrency.value.length < 1) {
          throw new Error('Associate Token Info not Found')
        }

        const BRIDGE_TOKEN_ADDRESS = getWormholeBridgeAddress(routeInfo.inputLockCurrency.chainId)
        const SOL_TOKEN_BRIDGE_ADDRESS = getWormholeTokenBridgeAddress(routeInfo.inputLockCurrency.chainId)
        const payerAddress = publicKey.toBase58()
        const fromAddress = tokenAccountsForLockCurrency.value[0].pubkey.toBase58() //  this variables as naming in wormhole.
        const mintAddress = routeInfo.inputLockCurrency.address

        const amount = outputAmount.toWei().toFixed(0)
        const targetAddress = zeroPad(hexToUint8Array(account.slice(2)), 32)
        const targetChainId = NETWORKS_INFO[routeInfo.outputMintCurrency.chainId].wormholeChainId

        const originAssetDetail = await getOriginalAssetSol(
          solanaConnectionObj,
          SOL_TOKEN_BRIDGE_ADDRESS,
          routeInfo.inputLockCurrency.address,
        )

        let payloadForRelayer = null
        let receiverAddressOnDestinationChain = targetAddress
        if (isAutomaticOnTargetChain) {
          const targetChainAtlasDexAddress = getAtlasDexSwapAddress(routeInfo.outputCurrency.chainId)

          const targetChainAtlasDexInByte32 = tryNativeToHexString(targetChainAtlasDexAddress, targetChainId)

          receiverAddressOnDestinationChain = Buffer.from(hexToUint8Array(targetChainAtlasDexInByte32))

          const targetCurrencyInByte32 = Buffer.from(
            hexToUint8Array(tryNativeToHexString(routeInfo.outputCurrency.address, targetChainId)),
          )

          const transactionIdForPayload = ethers.utils.formatBytes32String(transactionIdOfDB)

          const slippage = +userSlippageTolerance.toFixed(2) * 1000 // this thousand is to remove floating so to send on contract.
          const fee = relayerFee.toWei().toFixed(0)
          const abiCoder = new ethers.utils.AbiCoder()
          payloadForRelayer = abiCoder
            .encode(
              ['bytes32', 'bytes32', 'bytes32', 'uint256', 'uint256'],
              [targetAddress, targetCurrencyInByte32, transactionIdForPayload, slippage, fee],
            )
            .slice(2)
          payloadForRelayer = hexToUint8Array(payloadForRelayer)
        }
        const transaction = await transferFromSolana(
          solanaConnectionObj,
          BRIDGE_TOKEN_ADDRESS,
          SOL_TOKEN_BRIDGE_ADDRESS,
          payerAddress,
          fromAddress,
          mintAddress,
          amount,
          receiverAddressOnDestinationChain,
          targetChainId,
          originAssetDetail.assetAddress,
          originAssetDetail.chainId,
          payerAddress,
          BigInt(0),
          payloadForRelayer,
        )

        const signedTx = await signTransaction(transaction)
        const transactionId = await solanaConnectionObj.sendRawTransaction(signedTx.serialize())

        dispatchTxHash(dispatch, transactionId)

        const receipt = await waitForSolanaTxConfirmation(transactionId, 'confirmed', solanaConnectionObj)

        if (receipt.meta.error) {
          return {
            status: ACTION_STEP_STATE.ERROR,
            message: 'error',
          }
        } else {
          const lockedAmount = deNormalizeAmount(
            new CurrencyAmount(outputAmount.toWei().toFixed(0), routeInfo.inputLockCurrency.decimals),
            routeInfo.outputMintCurrency.decimals,
          )
          return {
            status: ACTION_STEP_STATE.OK,
            message: 'success',
            payload: {
              chainId: routeInfo.inputLockCurrency.chainId,
              lockedAmount,
              txid: transactionId,
            },
          }
        }
      } catch (error) {
        console.log(error)
        Sentry.captureException(error)
        return {
          status: ACTION_STEP_STATE.ERROR,
          message: error['message'],
          error: error,
        }
      }
    },
    [
      solanaConnectionObj,
      publicKey,
      library,
      account,
      dispatch,
      isAutomaticOnTargetChain,
      transactionIdOfDB,
      relayerFee,
      userSlippageTolerance,
    ],
  )

  const swapUnlockedSolana = useCallback(
    async (routeInfo: IRoute, lockedAmount: CurrencyAmount) => {
      try {
        const inputMintAddress =
          routeInfo.outputMintCurrency.address === SOLANA_ADDRESS
            ? WRAPPED_SOLANA_ADDRESS
            : routeInfo.outputMintCurrency.address
        const outputMintAddress =
          routeInfo.outputCurrency.address === SOLANA_ADDRESS
            ? WRAPPED_SOLANA_ADDRESS
            : routeInfo.outputCurrency.address
        const urlForRoutes = `${JUPITER_API_URL_V3}/quote?inputMint=${inputMintAddress}&outputMint=${outputMintAddress}&amount=${JSBI.BigInt(
          lockedAmount.toWei().toString(),
        )}&slippageBps=${Math.ceil(+userSlippageTolerance.toFixed(3) * 100)}&swapMode=ExactIn&feeBps=${
          Fee.DEFAULT * 100
        }`

        console.info('JUPITER URL ==== ', urlForRoutes)

        const {
          data: { data: jupiterRoutes },
        } = await axios(urlForRoutes)

        const urlForSwap = `${JUPITER_API_URL_V3}/swap`
        const body = {
          route: jupiterRoutes[0],
          userPublicKey: publicKey.toBase58(),
          wrapUnwrapSOL: true,
          feeAccount: FeeCollector.SOL_FEE_COLLECTOR,
        }

        const {
          data: { swapTransaction },
        } = await axios({
          method: 'post',
          url: urlForSwap,
          data: body,
        })
        const transaction = VersionedTransaction.deserialize(Buffer.from(swapTransaction, 'base64'))

        const addressLookupTableAccounts = await Promise.all(
          transaction.message.addressTableLookups.map(async (lookup) => {
            return new AddressLookupTableAccount({
              key: lookup.accountKey,
              state: AddressLookupTableAccount.deserialize(
                await solanaConnectionObj.getAccountInfo(lookup.accountKey).then((res) => res.data),
              ),
            })
          }),
        )
        const message = TransactionMessage.decompile(transaction.message, {
          addressLookupTableAccounts: addressLookupTableAccounts,
        })
        transaction.message = message.compileToV0Message(addressLookupTableAccounts)

        const signedTx = await signTransaction(transaction)
        const transactionId = await solanaConnectionObj.sendRawTransaction(signedTx.serialize(), {
          skipPreflight: false,
        })

        dispatchTxHash(dispatch, transactionId)
        await waitForSolanaVersionTxConfirmation(transactionId, solanaConnectionObj)

        return {
          status: ACTION_STEP_STATE.OK,
          message: 'success',
          payload: {
            txid: transactionId,
          },
        }
      } catch (error) {
        Sentry.captureException(error)
        return {
          status: ACTION_STEP_STATE.ERROR,
          message: error['message'],
          error: error,
        }
      }
    },
    [userSlippageTolerance, dispatch],
  )

  const onAssociateAccountCreate = useCallback(
    async (currencyForAccount: Currency) => {
      try {
        const mintPublicKey = new PublicKey(currencyForAccount.address)
        const tokenAccountsForLockCurrency = await solanaConnectionObj.getParsedTokenAccountsByOwner(
          publicKey,
          {
            mint: new PublicKey(currencyForAccount.address),
          },
          'confirmed',
        )

        if (tokenAccountsForLockCurrency.value.length === 0) {
          const associatedAddress = await getAssociatedTokenAddress(mintPublicKey, publicKey)

          const transaction = new Transaction().add(
            await createAssociatedTokenAccountInstruction(
              publicKey, // payer
              associatedAddress, // associated address
              publicKey, // owner
              mintPublicKey,
            ),
          )
          transaction.feePayer = publicKey
          const latestBlockHashResult = await solanaConnectionObj.getRecentBlockhash('confirmed')

          transaction.recentBlockhash = latestBlockHashResult.blockhash

          const signedTx = await signTransaction(transaction)
          const txid = await solanaConnectionObj.sendRawTransaction(signedTx.serialize())
          dispatchTxHash(dispatch, txid)
          await waitForSolanaTxConfirmation(txid, 'confirmed', solanaConnectionObj)
        }

        return {
          status: ACTION_STEP_STATE.OK,
          message: 'success',
        }
      } catch (error) {
        console.log('--- ERRROR', error)
        Sentry.captureException(error)
        return {
          status: ACTION_STEP_STATE.ERROR,
          message: error['message'],
          error: error,
        }
      }
    },
    [solanaConnectionObj, publicKey],
  )
  return {
    handleJupiterRoutes,
    handleJupiterRoutesForRelayer,
    handleJupiterSwap,
    lockOnSolanaSide,
    swapUnlockedSolana,
    onAssociateAccountCreate,
  }
}

export default useJupiter
