import Svg from '../Svg'
import { SvgProps } from '../types'

const TronIcon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.00293 1.02578C1.13448 1.39585 4.33705 10 4.34324 10C4.35472 10 9 3.94359 9 3.92861C9 3.92244 8.62967 3.54064 8.17704 3.08018L7.35409 2.24296L6.85578 2.14543C6.58171 2.0918 5.16105 1.81212 3.69877 1.52396C2.23648 1.23578 1.02966 1 1.01691 1C1.00055 1 0.996449 1.00756 1.00293 1.02578ZM3.13921 1.91242C3.62996 2.00918 4.62606 2.20506 5.35276 2.34769C6.07945 2.49033 6.68801 2.61169 6.7051 2.61738C6.73202 2.62635 6.6204 2.73089 5.86827 3.40103C5.39094 3.82633 4.99248 4.17357 4.98282 4.17269C4.96806 4.17132 2.33742 1.84759 2.25643 1.76438C2.24149 1.74905 2.23325 1.7365 2.23811 1.7365C2.24297 1.7365 2.64847 1.81567 3.13921 1.91242ZM3.39834 3.42152C4.15535 4.09309 4.77567 4.64325 4.77684 4.64411C4.7808 4.64699 4.3297 8.61786 4.32481 8.62307C4.32144 8.62668 2.02351 2.4561 1.90139 2.11552C1.88852 2.0796 1.71842 1.9312 3.39834 3.42152ZM7.72543 3.30156C8.09158 3.67352 8.18801 3.77893 8.16986 3.78732C8.13958 3.80135 5.61993 4.28841 5.61347 4.2815C5.60674 4.2743 7.23122 2.82652 7.24603 2.82652C7.25252 2.82652 7.46824 3.04028 7.72543 3.30156ZM8.06845 4.37685C8.03493 4.41937 7.28713 5.39408 6.40667 6.54286C5.52621 7.69162 4.80267 8.62814 4.7988 8.62401C4.79495 8.61988 4.88704 7.77196 5.00346 6.73977L5.21513 4.86304L6.60521 4.59429C7.36975 4.44649 8.01696 4.32036 8.04346 4.31401C8.1286 4.29362 8.13041 4.2982 8.06845 4.37685Z"
        fill="#FF060A"
      />
    </Svg>
  )
}

export default TronIcon
