import addresses from 'config/constants/contracts'
import { Address } from 'config/constants/types'
import { SupportedChainId } from '../config/constants/chains'

export const getAddress = (address: Address, chainId = SupportedChainId.BINANCE): string => {
  return address[chainId]
}

export const getMulticallAddress = (chainId) => {
  return getAddress(addresses.multiCall, chainId)
}

export const getWormholeTokenBridgeAddress = (chainId) => {
  const address = addresses.wormholeTokenBridge
  return address[chainId]
}

export const getWrappedAddress = (chainId) => {
  return addresses.wrapped[chainId]
}

export const getWormholeBridgeAddress = (chainId) => {
  const address = addresses.wormholeBridge
  return address[chainId]
}

export const getAtlasDexSwapAddress = (chainId) => {
  const address = addresses.atlasDexSwap
  return address[chainId]
}
