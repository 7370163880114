import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, AppState } from '../../index'

import { IActionStepState, IStep, SWAP_STATES } from '../../swap/types'
import {
  setStableSwapChains,
  setStableInputAmount,
  setStableReceivedAmount,
  switchCurrencies,
  selectCurrency,
  updateStableSwapPageState,
  selectChain,
  resetSwapState,
  setSteps,
  setActiveStepIndex,
  setActionState,
  setStepResult,
  setChainAndToken,
  updateTransactionId,
  setStableFee,
} from '../actions'
import { Field } from 'state/types'
import { CurrencyAmount } from 'config/entities'
import { TokenInfoWithChainDetails } from 'config/allbridge'
// import { AllbridgeCoreSdk } from 'config/allbridge'
import { SupportedChainId } from 'config/constants/chains'
import { computeStableSwapSteps } from '../utils'
import axios from 'axios'

// this hook is to sync the state in start of page.
export async function useAllBrideStableSwapDataSync() {
  const dispatch = useDispatch<AppDispatch>()
  const syncAllBridgeBlockchains = async () => {
    try {
      const {
        data: { supportedChains },
      } = await axios({
        method: 'GET',
        url: `/api/allbridge/supported-chains`,
      })
      dispatch(setStableSwapChains({ supportedChains }))
    } catch (error) {
      console.error('ERROR IN UPDATE IN_supportedChains', error)
    }
  }
  useEffect(() => {
    syncAllBridgeBlockchains()
  }, [dispatch])
}

// Handler to call in our views
export function useStableSwapActionHandlers(): {
  onSelectCurrency(currency: TokenInfoWithChainDetails, field: Field): void
  onSelectChain: (field: Field, chainId: SupportedChainId) => void

  onUserInput: (typedValue: CurrencyAmount) => void
  onSwitchCurrencies: () => void
  onUpdateSwapPageState: (swapPageState: SWAP_STATES) => void
  onResetSwapState: () => void
  onNextStep: (resultActionStepState: IActionStepState) => void
  onActionStepStateChanged: (actionStepState: IActionStepState) => void
  onSetChainToken: (
    fromChain: number,
    toChain: number,
    fromCurrency: TokenInfoWithChainDetails,
    toCurrency: TokenInfoWithChainDetails,
    fromCurrencies: TokenInfoWithChainDetails[],
    toCurrencies: TokenInfoWithChainDetails[],
  ) => void
  onSetTransactionId: (transactionId: string) => void

  onUpdateReceiveAmount: (receivedAmount: CurrencyAmount) => void
  onSetStepResult: (actionStepState: IActionStepState) => void
} {
  const dispatch = useDispatch<AppDispatch>()
  // const sdk = new AllbridgeCoreSdk()

  const sourceToken = useSelector<AppState, AppState['stableSwap'][Field.INPUT]['currency']>(
    (state) => state.stableSwap[Field.INPUT].currency,
  )
  const destinationToken = useSelector<AppState, AppState['stableSwap'][Field.OUTPUT]['currency']>(
    (state) => state.stableSwap[Field.OUTPUT].currency,
  )

  const stateInfo = useSelector<AppState, AppState['stableSwap']>((state) => state.stableSwap)

  const onSetTransactionId = useCallback(
    (transactionId: string) => {
      dispatch(
        updateTransactionId({
          transactionId,
        }),
      )
    },
    [dispatch],
  )

  const onSelectChain = useCallback(
    async (field: Field, chainId: SupportedChainId) => {
      dispatch(
        selectChain({
          field,
          chainId,
        }),
      )
    },
    [dispatch],
  )
  const onSelectCurrency = useCallback(
    (currency: TokenInfoWithChainDetails, field: Field) => {
      dispatch(
        selectCurrency({
          currency,
          field,
        }),
      )
    },
    [dispatch],
  )
  const onSwitchCurrencies = useCallback(() => {
    dispatch(switchCurrencies())
  }, [dispatch])

  const onUserInput = useCallback(
    async (inputAmount: CurrencyAmount) => {
      // enable route loading here
      let fee = new CurrencyAmount(0, 0)

      dispatch(updateStableSwapPageState({ swapPageState: SWAP_STATES.ROUTES_LOADING }))
      let receivedAmount = new CurrencyAmount(0, 0)
      dispatch(setStableInputAmount({ inputAmount }))
      // const calculateFeePercentOnSourceChain = await sdk.calculateFeePercentOnSourceChain(
      //   inputAmount.toEther().toString(),
      //   sourceToken
      // )
      // const calculateFeePercentOnDestinationChain = await sdk.calculateFeePercentOnDestinationChain(
      //   inputAmount.toEther().toString(),
      //   sourceToken,
      //   destinationToken
      // )
      // console.log('calculateFee *****************************')
      // console.log('calculateFee Percent On SourceChain', calculateFeePercentOnSourceChain)
      // console.log('calculateFee Percent On Destination', calculateFeePercentOnDestinationChain)
      // console.log('calculateFee *****************************')

      try {
        //Calculate received amount
        const {
          data: { transactionCost, amountToBeReceived },
        } = await axios({
          method: 'POST',
          url: `/api/allbridge/quote`,
          data: {
            sourceToken,
            destinationToken,
            inputAmount: inputAmount.toEther().toString(),
          },
        })
        receivedAmount = new CurrencyAmount(amountToBeReceived, destinationToken?.decimals, false)
        fee = new CurrencyAmount(
          transactionCost,
          stateInfo[Field.INPUT].chainId === SupportedChainId.SOLANA
            ? 9
            : stateInfo[Field.INPUT].chainId === SupportedChainId.TRON
            ? 6
            : 18,
          true,
        )

        dispatch(setStableFee({ fee }))
      } catch (error) {
        console.error(error)
      } finally {
        //update value in state
        const steps: IStep[] = computeStableSwapSteps(
          stateInfo[Field.INPUT].chainId,
          stateInfo[Field.OUTPUT].chainId,
          stateInfo[Field.INPUT].currency,
          stateInfo[Field.OUTPUT].currency,
          inputAmount,
        )
        dispatch(setSteps({ steps }))
        dispatch(setStableReceivedAmount({ receivedAmount }))
        dispatch(updateStableSwapPageState({ swapPageState: SWAP_STATES.DEFAULT }))
      }
    },
    [dispatch, sourceToken, destinationToken],
  )

  const onUpdateSwapPageState = useCallback(
    (swapPageState: SWAP_STATES) => {
      dispatch(
        updateStableSwapPageState({
          swapPageState,
        }),
      )
    },
    [dispatch],
  )

  const onUpdateReceiveAmount = useCallback(
    (receivedAmount: CurrencyAmount) => {
      dispatch(
        setStableReceivedAmount({
          receivedAmount,
        }),
      )
    },
    [dispatch],
  )

  const onResetSwapState = useCallback(() => {
    dispatch(resetSwapState())
  }, [dispatch])

  const onNextStep = useCallback(
    async (resultActionStepState: IActionStepState) => {
      dispatch(
        setActiveStepIndex({
          resultActionStepState,
        }),
      )
    },
    [dispatch],
  )

  const onActionStepStateChanged = useCallback(
    (actionStepState: IActionStepState) => {
      dispatch(
        setActionState({
          actionStepState,
        }),
      )
      dispatch(
        setStepResult({
          actionStepState,
        }),
      )
    },
    [dispatch],
  )
  const onSetChainToken = useCallback(
    (
      fromChain: number,
      toChain: number,
      fromCurrency: TokenInfoWithChainDetails,
      toCurrency: TokenInfoWithChainDetails,
      fromCurrencies: TokenInfoWithChainDetails[],
      toCurrencies: TokenInfoWithChainDetails[],
    ) => {
      dispatch(
        setChainAndToken({
          fromChain,
          toChain,
          fromCurrency,
          toCurrency,
          fromCurrencies,
          toCurrencies,
        }),
      )
    },
    [dispatch],
  )
  const onSetStepResult = useCallback(
    (actionStepState: IActionStepState) => {
      dispatch(
        setStepResult({
          actionStepState,
        }),
      )
    },
    [dispatch],
  )
  return {
    onSelectCurrency,
    onSelectChain,
    onSwitchCurrencies,
    onUserInput,
    onUpdateSwapPageState,
    onResetSwapState,
    onNextStep,
    onActionStepStateChanged,
    onSetChainToken,
    onUpdateReceiveAmount,
    onSetStepResult,
    onSetTransactionId,
  }
} // end of use Stable Swap Action Handler
