import { AddressWrapper, IconButton } from '../../styles'
import { Flex } from 'components/Box'
import { IoCopyOutline, IoLogOutOutline } from 'react-icons/io5'
import { FiClock, FiExternalLink } from 'react-icons/fi'
import { useTranslation } from 'contexts/Localization'
import { useWalletActionHandlers } from 'state/wallets/hooks'
import copyToClipboard from 'utils/copyToClipboard'
import { ADDRESS_EXPLORER_LINK } from 'config/constants/endpoints'
import { Text } from 'components/Text'
import useAuth from 'hooks/wallets/useAuthEVM'
import { SupportedChainId, SupportedEVMChainId } from 'config/constants/chains'
import useToast from 'hooks/useToast'
import HistoryModal from 'views/Swap/Default/components/HistoryModal'
import { useModal } from 'widgets/Modal'

const WalletInfo = ({ walletLocalState }) => {
  const { t } = useTranslation()
  const { toastSuccess } = useToast()
  const { disconnectEVM, disconnectSol, disconnectTron } = useAuth()

  const { getConnectedWalletAddressFromChainID } = useWalletActionHandlers()
  const activeUserAddress = getConnectedWalletAddressFromChainID(walletLocalState.chainId)

  const openExplorer = (chainId) => {
    window.open(`${ADDRESS_EXPLORER_LINK[chainId]}${activeUserAddress}`)
  }

  const [showHistoryModal, onHistoryDismiss] = useModal(<HistoryModal handleDismiss={() => onHistoryDismiss()} />, true)

  return (
    <Flex height={'100%'} alignItems="center" justifyContent={'center'} mt={'40px'}>
      <Flex
        flexDirection={'column'}
        justifyContent={'center'}
        alignItems="center"
        className="wallet-info"
        width={'100%'}
      >
        <AddressWrapper>
          <Text className="wallet-address">{activeUserAddress}</Text>
        </AddressWrapper>
        <Flex marginTop="1.4rem" width="100%" justifyContent={'space-around'}>
          <IconButton
            onClick={() => {
              copyToClipboard(activeUserAddress)
              toastSuccess('Success', 'Copied the text successfully!')
            }}
          >
            <IoCopyOutline className="icon" />
            <Text className="tab-name">{t('Copy Wallet')}</Text>
          </IconButton>
          <IconButton
            onClick={() => {
              openExplorer(walletLocalState.chainId)
            }}
          >
            <FiExternalLink className="icon" />
            <Text className="tab-name">{t('Explorer')}</Text>
          </IconButton>
          <IconButton
            onClick={() => {
              showHistoryModal()
            }}
          >
            <FiClock className="icon" />
            <Text className="tab-name">{t('History')}</Text>
          </IconButton>
          <IconButton
            onClick={async () => {
              if (SupportedEVMChainId(walletLocalState.chainId)) {
                disconnectEVM()
              } else if (walletLocalState.chainId === SupportedChainId.TRON) {
                disconnectTron()
              } else {
                disconnectSol()
              }
            }}
          >
            <IoLogOutOutline className="icon" />
            <Text className="tab-name">{t('Disconnect')}</Text>
          </IconButton>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default WalletInfo
