import { SvgProps } from './types'
import React from 'react'
import styled from 'styled-components'
import { Flex } from '../Box'
import useTheme from 'hooks/useTheme'
import Image from '../Image'

const SuccessAnimationWrapper = styled.div`
  .checkmark {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: block;
    stroke-width: 5;
    stroke: ${({ theme }) => theme.colors.success};
    stroke-miterlimit: 10;
    box-shadow: inset 1px 0 0 ${({ theme }) => theme.colors.success};
    animation: fill 0.4s ease-in-out 0.4s forwards, scale 0.3s ease-in-out 0.9s both;
    position: relative;
    top: 5px;
    margin: 0 auto;
  }
  .checkmark__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 6;
    stroke-miterlimit: 10;
    stroke: ${({ theme }) => theme.colors.success};
    fill: ${({ theme }) => theme.colors.background};
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
  }

  .checkmark__check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
  }

  .star {
    animation-name: scale-animation;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-timing-function: linear;
  }

  @keyframes scale-animation {
    0% {
      transform: scale(0);
      opacity: 0;
    }

    60% {
      transform: scale(0);
      opacity: 0;
    }
    100% {
      transform: scale(1.3);
      opacity: 1;
    }
  }

  @keyframes stroke {
    100% {
      stroke-dashoffset: 0;
    }
  }

  @keyframes scale {
    0%,
    100% {
      transform: none;
    }

    50% {
      transform: scale3d(1.1, 1.1, 1);
    }
  }

  @keyframes fill {
    100% {
      box-shadow: inset 0 0 0 30px #4bb71b;
    }
  }
`

const Star = ({ duration }) => {
  const { theme } = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
      className={'star'}
      style={{ animationDuration: `${duration}s` }}
      xmlSpace="preserve"
      preserveAspectRatio="none"
      width={'16'}
      height={'16'}
    >
      <path
        d="M12 3C12 7.97056 16.0294 12 21 12C16.0294 12 12 16.0294 12 21C12 16.0294 7.97056 12 3 12C7.97056 12 12 7.97056 12 3Z"
        stroke={theme.colors.success}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill={theme.colors.textOrange}
      />
    </svg>
  )
}
const CheckMark: React.FC<SvgProps> = () => {
  return (
    <SuccessAnimationWrapper>
      <Flex width={'100%'} justifyContent={'space-between'}>
        <Star duration={1.5} />
        <Star duration={1.2} />
      </Flex>

      <Image src={'./images/loading.gif'} width={'100'} height={'100'} />

      <Flex style={{ transform: 'rotate(5deg)' }} width={'100%'} justifyContent={'space-between'}>
        <Star duration={1.7} />
        <Star duration={1.3} />
      </Flex>
    </SuccessAnimationWrapper>
  )
}

export default CheckMark
