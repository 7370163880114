import type { Signer } from '@ethersproject/abstract-signer'
import type { Provider } from '@ethersproject/providers'
import { Contract } from '@ethersproject/contracts'
import { simpleRpcProvider } from 'utils/providers'

// Addresses
import { getMulticallAddress } from 'utils/addressHelpers'

// ABI'
import bep20Abi from 'config/abi/erc20.json'
import MultiCallAbi from 'config/abi/Multicall.json'
import lpTokenAbi from 'config/abi/lpToken.json'
import wormholeBridgeAbi from 'config/abi/IBridgeWormhole.json'
import atlasDexSwapAbi from 'config/abi/AtlasDexSwap.json'

// Types
import { Erc20, Multicall } from 'config/abi/types'
import { getContract } from './index'

// const getContract = (abi: any, address: string, signer?: Signer | Provider) => {
//   const signerOrProvider = signer ?? simpleRpcProvider
//   return new Contract(address, abi, signerOrProvider)
// }

export const getBep20Contract = (address: string, signer?: Signer | Provider) => {
  return getContract(address, bep20Abi, signer) as Erc20
}

export const getMulticallContract = (chainId, signer?: Signer | Provider) => {
  return getContract(getMulticallAddress(chainId), MultiCallAbi, signer) as Multicall
}

export const getWormholeBridgeContract = (address: string, signer?: Signer | Provider) => {
  return getContract(address, wormholeBridgeAbi, signer)
}

export const getAtlasDexSwapContract = (address: string, signer?: Signer | Provider) => {
  return getContract(address, atlasDexSwapAbi, signer)
}

export const getLpContract = (address: string, signer?: Signer | Provider) => {
  return getContract(address, lpTokenAbi, signer)
}
