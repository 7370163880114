import { Dropdown } from 'react-bootstrap'
import { DropdownBoxProps } from './type'
import { useState } from 'react'
import { StyleDropDown } from './style'
import { ChevronDownIcon, GlobeIcon } from 'components/Svg'
import { Text } from 'components/Text'
import useTheme from 'hooks/useTheme'
import { Box } from 'components/Box'
import { useTranslation } from 'contexts/Localization'

const Select: React.FC<DropdownBoxProps> = (props) => {
  const { options } = props
  const [isDropdownOpen, setDropdownOpen] = useState(false)
  const { theme } = useTheme()
  const { setLanguage, currentLanguage } = useTranslation()
  return (
    <StyleDropDown>
      <Dropdown.Toggle
        onClickCapture={() => setDropdownOpen(!isDropdownOpen)}
        onBlur={() => setDropdownOpen(false)}
        variant=""
        className={'d-flex align-items-center border-0 shadow-none dropdown'}
        id="dropdown-basic"
      >
        <GlobeIcon iconName={'globe-icon'} fontSize={'14px'} />{' '}
        <Text
          mx={'0.2rem'}
          fontSize={'medium'}
          fontFamily={theme.fonts.secondary}
          fontWeight={theme.fonts.regular}
          color={theme.colors.text}
        >
          {currentLanguage.language}
        </Text>{' '}
        <ChevronDownIcon />
      </Dropdown.Toggle>
      <Dropdown.Menu style={{ minWidth: 'auto' }}>
        {options &&
          options.map((option: any, index) => (
            <Dropdown.Item key={index} onClick={() => setLanguage(option)}>
              <Box mx="auto" width="100%">
                <Text
                  fontSize={'16px'}
                  textAlign="center"
                  fontFamily={theme.fonts.secondary}
                  fontWeight={theme.fonts.semiBold}
                  color={theme.colors.text}
                >
                  {option?.language}
                </Text>
              </Box>
            </Dropdown.Item>
          ))}
      </Dropdown.Menu>
    </StyleDropDown>
  )
}

export default Select
