import { createReducer } from '@reduxjs/toolkit'
import { setCryptoUSDRates, updateCryptoUSDRates, updateVersion } from '../global/actions'
import { setSwapSelectedTab } from './actions'
import { GlobalState, SWAP_TAB } from './types'

export const initialState: GlobalState = {
  selectedSwapTab: SWAP_TAB.DEFAULT,
  cryptoUSDRates: new Map(),
}

export default createReducer(initialState, (builder) =>
  builder
    .addCase(updateVersion, () => {
      return {
        ...initialState,
      }
    })
    .addCase(setSwapSelectedTab, (state, { payload: { selectedTab } }) => {
      return {
        ...state,
        selectedSwapTab: selectedTab,
      }
    })
    .addCase(updateCryptoUSDRates, (state, { payload: { key, usdRate } }) => {
      state.cryptoUSDRates.set(key, usdRate)
      return {
        ...state,
      }
    })
    .addCase(setCryptoUSDRates, (state, { payload: { cryptoUSDRates } }) => {
      return {
        ...state,
        cryptoUSDRates,
      }
    }),
)
