import { useWallet } from '@solana/wallet-adapter-react'
import { useWeb3React } from '@web3-react/core'
import { SupportedChainId, SupportedEVMChainId } from 'config/constants/chains'
import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, AppState } from 'state'
import { Field } from 'state/types'
import { setWalletState, switchWallets, updateWalletStatus } from './actions'
import { IWalletInfo, WalletStateEnum } from './types'
import * as Sentry from '@sentry/react'
import { useTranslation } from 'contexts/Localization'
import useToast from 'hooks/useToast'
import { useSwapActionHandlers } from 'state/swap/hooks'
import { UPDATE_ACTIONS } from 'state/swap/types'

export function useWalletStateHandler(field: Field, isForConfirmation: boolean) {
  const { toastError } = useToast()
  const { t } = useTranslation()
  const { onWalletStatusUpdate, onWalletStateUpdate } = useWalletActionHandlers()
  const { wallet: solanaWalletAdapter, connect, connected, publicKey } = useWallet()
  const stateInfo = useSelector<AppState, AppState['wallets']>((s) => s.wallets)
  const swapStateInfo = useSelector<AppState, AppState['swaps']>((s) => s.swaps)
  const dispatch = useDispatch<AppDispatch>()

  useMemo(() => {
    const inputChainId = swapStateInfo[Field.INPUT].chainId
    const outputChainId = swapStateInfo[Field.OUTPUT].chainId

    const connectSolanaWallet = async () => {
      try {
        await connect().catch(() => {
          toastError(t('Authorization Error'), t('Please authorize to access your account'))
          throw new Error(t('Please authorize to access your account'))
        })
        if (isForConfirmation) {
          !SupportedEVMChainId(inputChainId) && onWalletStatusUpdate(Field.INPUT, { chainId: inputChainId })
          !SupportedEVMChainId(outputChainId) && onWalletStatusUpdate(Field.OUTPUT, { chainId: outputChainId })
        }
        onWalletStateUpdate(WalletStateEnum.CONNECTED)
      } catch (error) {
        Sentry.captureException(error)
      }
    }

    const disconnectSolanaWallet = async () => {
      !SupportedEVMChainId(inputChainId) && onWalletStatusUpdate(Field.INPUT, null)
      !SupportedEVMChainId(outputChainId) && onWalletStatusUpdate(Field.OUTPUT, null)
      onWalletStateUpdate(WalletStateEnum.NOT)
    }
    stateInfo.isReadyToConnect === WalletStateEnum.READY && connectSolanaWallet()
    !solanaWalletAdapter && stateInfo.isReadyToConnect === WalletStateEnum.CONNECTED && disconnectSolanaWallet()
  }, [solanaWalletAdapter, connected, publicKey, dispatch, stateInfo, swapStateInfo])
} // end of use Wallet State Handler

// Handler to call in our views
export function useWalletActionHandlers(): {
  onWalletStatusUpdate: (field: Field, walletInfo: IWalletInfo) => void
  getConnectedWalletAddress: (field: Field) => string | null
  getConnectedWalletAddressFromChainID: (chainId: SupportedChainId) => string | null
  onWalletSingleStatusUpdate: (field: Field, walletInfo: IWalletInfo) => void
  onSwitchWallets: () => void
  onWalletStateUpdate: (walletState: WalletStateEnum) => void
} {
  const dispatch = useDispatch<AppDispatch>()
  const { account, active } = useWeb3React()
  const stateInfo = useSelector<AppState, AppState['wallets']>((s) => s.wallets)
  const { connected, publicKey } = useWallet()
  const { onUpdateShouldFetchTransaction, onUpdateSwapTransaction } = useSwapActionHandlers()

  const getConnectedWalletAddress = useCallback(
    (field: Field) => {
      const wallet = stateInfo[field]
      let walletAddress = ''
      if (!wallet) {
        // this check is to return null if wallet not connected.
        return null
      }
      if (SupportedEVMChainId(wallet.chainId)) {
        walletAddress = account ? account : null
      } else if (wallet.chainId === SupportedChainId.TRON) {
        walletAddress =
          window.tronWeb && window.tronWeb.ready && window.tronWeb.defaultAddress
            ? window.tronWeb.defaultAddress.base58
            : null
      } else {
        walletAddress = publicKey ? publicKey.toBase58() : null
      }

      return walletAddress
    },
    [account, publicKey, connected, active, stateInfo, dispatch],
  )
  const getConnectedWalletAddressFromChainID = useCallback(
    (chainId) => {
      let walletAddress = ''
      if (SupportedEVMChainId(chainId)) {
        walletAddress = account ? account : null
      } else if (chainId === SupportedChainId.TRON) {
        walletAddress =
          window.tronWeb && window.tronWeb.ready && window.tronWeb.defaultAddress
            ? window.tronWeb.defaultAddress.base58
            : null
      } else {
        walletAddress = publicKey ? publicKey.toBase58() : null
      }

      return walletAddress
    },
    [account, publicKey, connected, active, stateInfo, dispatch, window?.tronWeb?.defaultAddress],
  )
  const onWalletStatusUpdate = useCallback(
    (field: Field, walletInfo: IWalletInfo) => {
      dispatch(updateWalletStatus({ field, walletInfo }))
      onUpdateSwapTransaction([])
      onUpdateShouldFetchTransaction(UPDATE_ACTIONS.FIRST_TIME)
    },
    [dispatch],
  )

  const onWalletSingleStatusUpdate = useCallback(
    (field: Field, walletInfo: IWalletInfo) => {
      dispatch(updateWalletStatus({ field, walletInfo }))
      onUpdateSwapTransaction([])
      onUpdateShouldFetchTransaction(UPDATE_ACTIONS.FIRST_TIME)
    },
    [dispatch],
  )

  const onWalletStateUpdate = useCallback(
    (walletState: WalletStateEnum) => {
      dispatch(setWalletState({ walletState }))
    },
    [dispatch],
  )

  const onSwitchWallets = useCallback(() => {
    dispatch(switchWallets())
  }, [dispatch])

  return {
    onWalletStatusUpdate,
    getConnectedWalletAddress,
    onWalletSingleStatusUpdate,
    onSwitchWallets,
    onWalletStateUpdate,
    getConnectedWalletAddressFromChainID,
  }
} // end of use Swap Action Handler

/**
 * Returns Wallet of selected Chain
 * @param field
 */
export function useWalletState(field: Field): IWalletInfo {
  const state = useSelector<AppState, AppState['wallets']>((s) => s.wallets)
  return state[field]
}

export function useOppositeWalletState(field: Field): IWalletInfo {
  const state = useSelector<AppState, AppState['wallets']>((s) => s.wallets)
  const oppositeField = field === Field.INPUT ? Field.OUTPUT : Field.INPUT
  return state[oppositeField]
}

/**
 * Returns Wallet ready state
 * @param field
 */
export function useWalletReadyState(): WalletStateEnum {
  const state = useSelector<AppState, AppState['wallets']>((s) => s.wallets)
  return state.isReadyToConnect
}
