import { createReducer } from '@reduxjs/toolkit'
import { switchWallets, updateWalletStatus, setWalletState } from './actions'
import updateVersion from 'state/global/actions'
import { WalletState, WalletStateEnum } from './types'
import { Field } from 'state/types'

const initialWalletState: WalletState = {
  [Field.INPUT]: null,
  [Field.OUTPUT]: null,
  isReadyToConnect: WalletStateEnum.NOT,
}

export default createReducer(initialWalletState, (builder) =>
  builder
    .addCase(updateVersion, () => {
      return {
        ...initialWalletState,
      }
    })
    .addCase(updateWalletStatus, (state, { payload: { field, walletInfo } }) => {
      return {
        ...state,
        [field]: walletInfo,
      }
    })
    .addCase(switchWallets, (state) => {
      const inputWallet = state[Field.INPUT]
      const outputWallet = state[Field.OUTPUT]
      return {
        ...state,
        [Field.INPUT]: {
          ...outputWallet,
        },
        [Field.OUTPUT]: {
          ...inputWallet,
        },
      }
    })
    .addCase(setWalletState, (state, { payload: { walletState } }) => {
      return {
        ...state,
        isReadyToConnect: walletState,
      }
    }),
)
