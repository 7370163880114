import axios from 'axios'
import { useCurrency, useInputAmount, useRelayerFee, useRelayerFeeDollar, useSwapInfo } from 'state/swap/selectors'
import { Field } from 'state/types'
import { useUserSlippageTolerance } from 'state/user/hooks'
import { RELAYER_BACKEND_BASE_URL } from 'config/constants/endpoints'
import { useCallback } from 'react'
import { TransactionStatus } from 'config/enums/transactionStatus'

const useSwapSummary = () => {
  const inputCurrency = useCurrency(Field.INPUT),
    outputCurrency = useCurrency(Field.OUTPUT),
    { swapInfo } = useSwapInfo(),
    [slippage] = useUserSlippageTolerance(),
    { inputAmount } = useInputAmount(),
    { relayerFee } = useRelayerFee(),
    { relayerFeeDollarValue } = useRelayerFeeDollar()
  return {
    fromToken: inputCurrency.address,
    fromChainId: Number(`${inputCurrency.chainId}`),
    fromSideTxHash: '',
    fromAmount: Number(inputAmount.toEther()),
    toToken: outputCurrency.address,
    toChainId: Number(`${outputCurrency.chainId}`),
    toSideTxHash: '',
    toAmount: swapInfo ? Number(swapInfo.minimumReceived.toEther()) : 0,
    slippage: Number(slippage.toFixed()),
    vaa: Date.now().toString(),
    payload: '',
    type: Number(`${inputCurrency.chainId}`) === Number(`${outputCurrency.chainId}`) ? 'swap' : 'cross',
    status: TransactionStatus.INITIATED,
    relayerFee: relayerFee.toEther().toNumber(),
    relayerFeeDollarValue,
  }
}

export const usePostTransaction = () => {
  const data = useSwapSummary()

  const postTransaction = useCallback((fromAddress: string, toAddress: string, captcha: string, isAutomatic) => {
    return axios.post(`${RELAYER_BACKEND_BASE_URL}/transaction/add`, {
      ...data,
      fromAddress,
      toAddress,
      captcha,
      isWithRelayer: isAutomatic,
    })
  }, [])

  return { postTransaction }
}

export const useUpdateTransaction = () => {
  /* all params are optional
    vaa
    fromSideTxHash
    toSideTxHash
    payload
    status
   */
  const updateTransaction = useCallback(
    (
      _id: string,
      status?: TransactionStatus,
      fromSideTxHash?: string,
      toSideTxHash?: string,
      vaa?: string,
      payload?: string,
    ) => {
      const params = {
        status: status,
        fromSideTxHash: fromSideTxHash,
        toSideTxHash: toSideTxHash,
        vaa: vaa,
        payload: payload,
      }
      axios.post(`${RELAYER_BACKEND_BASE_URL}/transaction/update`, {
        _id,
        ...params,
      })
    },
    [],
  )

  /* Update transaction status OBH of ids
  ids
 */
  const updateTransactionStatusByIds = useCallback((ids: string[]) => {
    axios.post(`${RELAYER_BACKEND_BASE_URL}/transaction/update/all`, {
      ids,
    })
  }, [])

  return { updateTransaction, updateTransactionStatusByIds }
}
