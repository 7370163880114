import Svg from '../Svg'
import { IconComponentType } from '../types'

const Icon: React.FC<IconComponentType> = (props) => {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 20 20" {...props}>
      <path
        id="Path_3219"
        data-name="Path 3219"
        d="M10,0A10,10,0,1,0,20,10,10.029,10.029,0,0,0,10,0Zm6.5,6.625c.5,0,.875.375,1.375.375a2.074,2.074,0,0,1-2.5-.125,2.985,2.985,0,0,1,1.125-.25ZM1.25,10a7.689,7.689,0,0,1,.125-1.625c.125,0,.25.125.375.125a.436.436,0,0,1,.125.25.67.67,0,0,0,.625.625c1,.125,1.375,1,2.25,1.25.25.125.125.375,0,.625-.75,1-.125,1.75.5,2.375.625.5.625,1,.75,1.75a7.125,7.125,0,0,0,.5,2.75A8.964,8.964,0,0,1,1.25,10ZM10,18.75a8.883,8.883,0,0,1-2.625-.375.8.8,0,0,1,0-.75A19.9,19.9,0,0,1,9,14.875,1.265,1.265,0,0,0,9.5,14a2.317,2.317,0,0,1,.25-.875c.375-.625.25-1-.25-1.125-1-.25-1.5-1.125-2.25-1.5a2.217,2.217,0,0,0-2.125-.25c-.25.125-.625.25-.625-.125,0-.5-.625-.875-.5-1.375a.459.459,0,0,0-.375.125c-.125.125-.25.25-.5.125C2.875,8.75,3,8.5,3,8.25a.976.976,0,0,1,.5-.5c.5-.125,1-.125,1.25.5A4.269,4.269,0,0,1,6.625,6,8.813,8.813,0,0,1,7.75,5.125c.125,0,.25.25.5.375.25,0,.375,0,.375-.25a1.339,1.339,0,0,0-.75-1.5A.122.122,0,0,1,8,3.625c.375-.125.875-.375.75-.75,0-.5-.5-.75-1-.75A1.585,1.585,0,0,0,7,2.25a4.141,4.141,0,0,1-1.875.5A8.334,8.334,0,0,1,10,1.25h1a7.546,7.546,0,0,0-2,.625c.75.125.875.5.625,1.125a.457.457,0,0,0,.25.625c.25.125.5.125.625-.125a2.029,2.029,0,0,1,1.125-.625A2.113,2.113,0,0,0,12.875,2c0-.125.125-.125.25-.25A10.133,10.133,0,0,1,15.375,3c-.125,0-.125.125-.25.125-.25.25-.625.375-.25.875.125.25,0,.375-.125.5-.25.125-.375,0-.5-.125S14.125,4,13.75,4c-.125.25-.5.375-.5.75.625,0,.5.5.625.875-.75.125-1,.5-.625,1.125.125.25-.125.375-.25.5-.5.75-1,1.25-1,2.125A1.51,1.51,0,0,0,13.625,11c1.125-.125,1.125-.125,1.5.875,0,.125.125.25.125.375a.827.827,0,0,1,.125.75,2.907,2.907,0,0,0,.5,2.5,1.726,1.726,0,0,0,.375.5A8.319,8.319,0,0,1,10,18.75Z"
        fill="#9e9fad"
      />
    </Svg>
  )
}

export default Icon
