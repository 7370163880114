import { createAction } from '@reduxjs/toolkit'
import { SupportedChainId } from 'config/constants/chains'
import { BigNumber } from '@ethersproject/bignumber'

export const updateGasLimit = createAction<{ chainId: SupportedChainId; gasLimit: BigNumber }>(
  'blockchain/updateGasLimit',
)
export const updateGasPrice = createAction<{ chainId: SupportedChainId; gasPrice: BigNumber }>(
  'blockchain/updateGasPrice',
)
