import { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { AppDispatch } from 'state'
import { setCryptoUSDRates, setSwapSelectedTab } from './actions'
import { SWAP_TAB } from './types'
import * as Sentry from '@sentry/react'
import { getNativeCurrency } from 'utils/getNativeCurrency'
import axios from 'axios'

export function useCryptoUSDRates() {
  const dispatch = useDispatch<AppDispatch>()

  useEffect(() => {
    const fetchCryptoUSDRates = async () => {
      try {
        // Fetching Crypto USDRates
        const allNativeCurrenciesKeys = Object.keys(getNativeCurrency)
        let keysForCoinGecko = ''
        for (const key of allNativeCurrenciesKeys) {
          keysForCoinGecko += `${getNativeCurrency[key].coinGeckoId},`
        }

        const url = `https://api.coingecko.com/api/v3/simple/price?ids=${keysForCoinGecko}&vs_currencies=usd
        `
        const { data } = await axios(url)

        const cryptoUSDRates = new Map<string, number>()
        for (const key of allNativeCurrenciesKeys) {
          cryptoUSDRates.set(key, data[getNativeCurrency[key].coinGeckoId].usd)
        }

        dispatch(setCryptoUSDRates({ cryptoUSDRates }))
      } catch (error) {
        Sentry.captureException(error)
      }
    } // end of function fetchCryptoUSDRates

    fetchCryptoUSDRates()
  }, [dispatch])
}
// Handler to call in our views
export function useGlobalActionHandlers(): {
  onSelectSwapTab: (selectedTab: SWAP_TAB) => void
} {
  const dispatch = useDispatch<AppDispatch>()

  const onSelectSwapTab = useCallback(
    (selectedTab: SWAP_TAB) => {
      dispatch(
        setSwapSelectedTab({
          selectedTab,
        }),
      )
    },
    [dispatch],
  )

  return {
    onSelectSwapTab,
  }
} // end of use Swap Action Handler
