import { SupportedChainId } from 'config/constants/chains'
import { Currency, CurrencyAmount } from 'config/entities'
import { Field } from 'state/types'
import { RouteInfo } from '@jup-ag/core'
import { TransactionStatus } from 'config/enums/transactionStatus'

export enum SWAP_ACTIONS {
  CHANGE_NETWORK = 'CHANGE_NETWORK',
  APPROVE_CONTRACT = 'APPROVE_CONTRACT',
  SWAP = 'SWAP',
  SWAP_SOLANA = 'SWAP_SOLANA',
  LOCK_EVM = 'LOCK_EVM',
  UNLOCK_EVM = 'UNLOCK_EVM',
  LOCK_SOLANA = 'LOCK_SOLANA',
  BRIDGE = 'BRIDGE',
  UNLOCK_SOLANA = 'UNLOCK_SOLANA',
  SWAP_UNLOCKED_SOLANA = 'SWAP_UNLOCKED_SOLANA',
  CREATE_ASSOCIATE_ACCOUNT = 'CREATE_ASSOCIATE_ACCOUNT',
  RELAYER = 'RELAYER',
}

export enum ROUTES_DECIDER {
  PENDING = 'PENDING',
  SOURCE_ROUTES = 'SOURCE_ROUTES',
  DESTINATION_ROUTES = 'DESTINATION_ROUTES',
  MERGE_ROUTES = 'MERGE_ROUTES',
  BRIDGE_ROUTES = 'BRIDGE_ROUTES',
  NO_ROUTES = 'NO_ROUTES',
}

export interface EVMSwapData {
  _1inchData: string
  _0xData: string
  value: string
  _IsWrapped: boolean
  _IsUnWrapped: boolean
  _amount: string
}

export enum ROUTE_PROVIDER {
  ONE_INCH = '1inch',
  ZERO_X = '0x',
  WORMHOLE = 'Wormhole',
}

export enum ACTION_STEP_STATE {
  OK = 'OK',
  IN_PROGRESS = 'IN_PROGRESS',
  WAITING = 'WAITING',
  ERROR = 'ERROR',
  WAIT_FOR_LIBRARY = 'WAIT_FOR_LIBRARY',
  DEFAULT = 'DEFAULT',
}

export enum SWAP_STATES {
  DEFAULT = 'DEFAULT',
  ROUTES_LOADING = 'ROUTES_LOADING',
  BEFORE_SWAP_WALLET_CONFIRMATIONS = 'BEFORE_SWAP_WALLET_CONFIRMATIONS',
  SWAP_IN_PROGRESS = 'SWAP_IN_PROGRESS',
  SWAP_COMPLETED = 'SWAP_COMPLETED',
  NO_ROUTES = 'NO_ROUTES',
}

export interface ISwapSection {
  currency: Currency
  currencies: Currency[]
  chainId: SupportedChainId
}

export interface IOutputSwapSection extends ISwapSection {
  routes: IRoute[]
}

export interface IRoute {
  name: string
  parts?: number
  inputCurrency: Currency
  outputCurrency: Currency
  inputLockCurrency?: Currency
  outputMintCurrency?: Currency
  estimatedGas?: number
  sourceInputAmount: CurrencyAmount
  sourceOutputAmount?: CurrencyAmount
  destinationInputAmount?: CurrencyAmount
  destinationOutputAmount: CurrencyAmount
  payload?: RouteInfo
  priceImpact?: number
  evmRouteName?: string
  relayerFee?: CurrencyAmount
}

export interface ICrossChainRoute extends IRoute {
  sourceGasLimit?: number
  destinationGasLimit?: number
  relayerFee?: CurrencyAmount
}

export interface IStep {
  name: SWAP_ACTIONS
  title: string
  chainId: number
  payload: any
  result?: any
  dependOnStep?: SWAP_ACTIONS
  subTitle: string
  retries?: number
}

export interface IActionStepState {
  status: ACTION_STEP_STATE
  message: string
  error?: any
  payload?: any
}

export interface SwapInfo {
  routeName: string
  routeInfo: string
  fromSymbol: string
  toSymbol: string
  fromRate: string
  toRate: string
  route: string[]
  priceImpact: number
  minimumReceived: CurrencyAmount
  estimatedTime: string
}

export interface SwapState {
  shouldUpdateBalance: boolean
  [Field.INPUT]: ISwapSection
  [Field.OUTPUT]: IOutputSwapSection
  typedValue: string
  inputAmount: CurrencyAmount
  steps: IStep[]
  pageState: SWAP_STATES
  activeStepIndex: number
  actionStepState: IActionStepState
  swapInfo?: SwapInfo
  isAutomaticOnTargetChain: boolean
  transactionId: string
  transactionStatus: TransactionStatus
  relayerFee: CurrencyAmount
  relayerFeeDollarValue?: number
  fromSideNetworkFee: CurrencyAmount
  toSideNetworkFee: CurrencyAmount
  isValidInput?: boolean
  minimumAmount?: number
  swapTransactions?: ITransaction[]
  shouldFetchTransaction: UPDATE_ACTIONS
}

export type SwapType = 'swap' | 'cross' | 'stable'

export class ITransaction {
  _id: string
  fromAddress: string
  fromToken: string
  fromChainId: number
  fromSideTxHash: string
  fromAmount: string
  fromTimestamp: number
  fromNonce: number
  fromSequence: number
  toAddress: string
  toToken: string
  toChainId: number
  toSideTxHash: string
  toAmount: string
  slippage: number
  status: TransactionStatus
  vaa: string
  payload: string
  type: SwapType
  relayerFee: number
  relayerFeeDollarValue: number
  isWithRelayer: boolean
  createdAt: Date
  updatedAt: Date
}

export enum UPDATE_ACTIONS {
  FIRST_TIME = 'FIRST_TIME',
  SHOULD_UPDATE = 'SHOULD_UPDATE',
  SHOULD_NOT_UPDATE = 'SHOULD_NOT_UPDATE',
}

export const swapActionSet = new Set([SWAP_ACTIONS.SWAP, SWAP_ACTIONS.SWAP_SOLANA, SWAP_ACTIONS.SWAP_UNLOCKED_SOLANA])
export const lockActionSet = new Set([SWAP_ACTIONS.LOCK_EVM, SWAP_ACTIONS.LOCK_SOLANA])
export const unlockActionSet = new Set([SWAP_ACTIONS.UNLOCK_EVM, SWAP_ACTIONS.UNLOCK_SOLANA])
