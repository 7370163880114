import { createReducer } from '@reduxjs/toolkit'
import { SupportedChainId } from 'config/constants/chains'
import {
  resetSwapState,
  selectChain,
  selectCurrency,
  setActionState,
  setActiveStepIndex,
  setChainAndToken,
  setEmptyRoutes,
  setInputAmount,
  setSelectedRoute,
  setStepResult,
  setSteps,
  switchCurrencies,
  typeInput,
  updateCurrentStepPayload,
  updateRetriesCount,
  updateSwapPageState,
  selectAutomaticOnTargetChain,
  updateTransactionId,
  updateRelayerData,
  setSwapTransactions,
  setShouldFetchTransaction,
  updateTransactionNetworkFee,
} from './actions'
import { ACTION_STEP_STATE, IOutputSwapSection, ISwapSection, SWAP_STATES, SwapState, UPDATE_ACTIONS } from './types'

import {
  AvalancheTokens,
  BSCTokens,
  EthereumTokens,
  FantomTokens,
  PolygonTokens,
  SolanaTokens,
} from 'config/tokenLists'
import updateVersion from 'state/global/actions'
import invariant from 'tiny-invariant'
import { Currency, CurrencyAmount } from 'config/entities'
import { Field } from 'state/types'
import { computeSteps, computeSwapInfo } from './utils'
import _ from 'lodash'
import { TransactionStatus } from 'config/enums/transactionStatus'

const initialFromSwapSectionState: ISwapSection = {
  currencies: SolanaTokens,
  currency: SolanaTokens[0],
  chainId: SupportedChainId.SOLANA,
}

const initialToSwapSectionState: IOutputSwapSection = {
  currencies: SolanaTokens,
  currency: SolanaTokens[1],
  chainId: SupportedChainId.SOLANA,
  routes: [],
}

export const initialState: SwapState = {
  shouldUpdateBalance: false,
  [Field.INPUT]: initialFromSwapSectionState,
  [Field.OUTPUT]: initialToSwapSectionState,
  steps: [],
  pageState: SWAP_STATES.DEFAULT,
  typedValue: '0',
  inputAmount: new CurrencyAmount(0, initialFromSwapSectionState.currency.decimals),
  activeStepIndex: 0,
  actionStepState: {
    status: ACTION_STEP_STATE.IN_PROGRESS,
    message: '',
  },
  isAutomaticOnTargetChain: false,
  transactionId: '',
  transactionStatus: TransactionStatus.INITIATED,
  relayerFee: new CurrencyAmount(0, initialFromSwapSectionState.currency.decimals),
  relayerFeeDollarValue: 0,
  isValidInput: true,
  minimumAmount: 0,
  swapTransactions: [],
  shouldFetchTransaction: UPDATE_ACTIONS.FIRST_TIME,
  fromSideNetworkFee: new CurrencyAmount(0, initialFromSwapSectionState.currency.decimals),
  toSideNetworkFee: new CurrencyAmount(0, initialFromSwapSectionState.currency.decimals),
}

export default createReducer(initialState, (builder) =>
  builder
    .addCase(updateVersion, () => {
      return {
        ...initialState,
      }
    })
    .addCase(selectAutomaticOnTargetChain, (state, { payload: { isAutomatic } }) => {
      return {
        ...state,
        isAutomaticOnTargetChain: isAutomatic,
      }
    })
    .addCase(updateTransactionNetworkFee, (state, { payload: { fromSideNetworkFee, toSideNetworkFee } }) => {
      return {
        ...state,
        fromSideNetworkFee,
        toSideNetworkFee,
      }
    })
    .addCase(updateCurrentStepPayload, (state, { payload: { payload: payloadObject } }) => {
      const updatedSteps = _.cloneDeep(state.steps)
      updatedSteps[state.activeStepIndex].payload = payloadObject
      return {
        ...state,
        steps: updatedSteps,
      }
    })
    .addCase(updateTransactionId, (state, { payload: { transactionId } }) => {
      return {
        ...state,
        transactionId,
      }
    })

    .addCase(updateRetriesCount, (state) => {
      const updatedSteps = _.cloneDeep(state.steps)
      updatedSteps[state.activeStepIndex].retries = updatedSteps[state.activeStepIndex].retries
        ? updatedSteps[state.activeStepIndex].retries + 1
        : 1
      return {
        ...state,
        steps: updatedSteps,
      }
    })
    .addCase(setEmptyRoutes, (state) => {
      const stateInfo = state[Field.OUTPUT]
      return {
        ...state,
        [Field.OUTPUT]: {
          ...stateInfo,
          routes: [],
        },
        relayerFeeDollarValue: 0,
        relayerFee: new CurrencyAmount(0, 0),
        fromSideNetworkFee: new CurrencyAmount(0, 0),
        toSideNetworkFee: new CurrencyAmount(0, 0),
        pageState: SWAP_STATES.DEFAULT,
        swapInfo: null,
      }
    })
    .addCase(selectCurrency, (state, { payload: { field, currency } }) => {
      const otherSideCurrency = field === Field.INPUT ? state[Field.OUTPUT].currency : state[Field.INPUT].currency
      if (currency === otherSideCurrency) {
        return state
      }
      //:TODO instead of this invariant, we need to handle this situation like showing message to user to reload all currencies and change currencies or chain ID.
      invariant(currency.chainId === state[field].chainId, `Invalid currency`)
      const stateInfo = state[field]

      return {
        ...state,
        [field]: {
          ...stateInfo,
          currency,
        },
      }
    })
    .addCase(selectChain, (state, { payload: { chainId, field } }) => {
      let currencies = SolanaTokens
      const stateInfo = state[field]
      //Set token in state for stable swap
      if (chainId === SupportedChainId.BINANCE) {
        currencies = BSCTokens
      } else if (chainId === SupportedChainId.ETHEREUM) {
        currencies = EthereumTokens
      } else if (chainId === SupportedChainId.POLYGON) {
        currencies = PolygonTokens
      } else if (chainId === SupportedChainId.AVAX) {
        currencies = AvalancheTokens
      } else if (chainId === SupportedChainId.FANTOM) {
        currencies = FantomTokens
      }

      let currency: Currency =
        field === Field.INPUT && state[Field.OUTPUT].currency === currencies[0] ? currencies[1] : currencies[0]

      const otherSideCurrency = field === Field.INPUT ? state[Field.OUTPUT].currency : state[Field.INPUT].currency
      if (currency.address === otherSideCurrency.address) {
        currency = currencies[1]
      }

      return {
        ...state,
        [field]: {
          ...stateInfo,
          chainId,
          currencies: currencies,
          currency,
        },
      }
    })
    .addCase(typeInput, (state, { payload: { field, typedValue, routes } }) => {
      const stateInfo = state[Field.OUTPUT]
      return {
        ...state,
        typedValue,
        pageState:
          routes.length === 0 && !isNaN(Number(state.inputAmount.wei.toFixed()))
            ? SWAP_STATES.NO_ROUTES
            : SWAP_STATES.DEFAULT,
        [field]: {
          ...stateInfo,
          routes: isNaN(Number(state.inputAmount.wei.toFixed())) ? [] : routes,
        },
      }
    })
    .addCase(
      updateRelayerData,
      (state, { payload: { relayerFee, relayerFeeDollarValue, isValidInput, minimumAmount } }) => {
        return {
          ...state,
          relayerFee,
          relayerFeeDollarValue,
          isValidInput,
          minimumAmount,
        }
      },
    )
    .addCase(updateSwapPageState, (state, { payload: { swapPageState } }) => {
      return {
        ...state,
        pageState: swapPageState,
      }
    })

    .addCase(setSelectedRoute, (state, { payload: { route, userSlippageTolerance } }) => {
      const isAutomate =
        state[Field.INPUT].chainId !== state[Field.OUTPUT].chainId &&
        state[Field.OUTPUT].chainId !== SupportedChainId.SOLANA
          ? state.isAutomaticOnTargetChain
          : false
      const stateSteps = computeSteps(
        state[Field.INPUT].chainId,
        state[Field.OUTPUT].chainId,
        route,
        {
          chainId: state[Field.INPUT].chainId,
          name: state[Field.INPUT].currency.name,
          address: state[Field.INPUT].currency.address,
          decimals: state[Field.INPUT].currency.decimals,
          logoURI: state[Field.INPUT].currency.logoURI,
          symbol: state[Field.INPUT].currency.symbol,
        },
        isAutomate,
      )
      const swapInfo = computeSwapInfo(stateSteps, route, userSlippageTolerance, isAutomate)
      return {
        ...state,
        steps: stateSteps,
        swapInfo: swapInfo,
      }
    })
    .addCase(setSteps, (state, { payload: { steps, swapInfo } }) => {
      return {
        ...state,
        steps,
        swapInfo,
      }
    })
    .addCase(setInputAmount, (state, { payload: { inputAmount } }) => {
      return {
        ...state,
        inputAmount,
      }
    })
    .addCase(setActionState, (state, { payload: { actionStepState } }) => {
      return {
        ...state,
        actionStepState,
      }
    })

    .addCase(setActiveStepIndex, (state, { payload: { resultActionStepState } }) => {
      const updatedSteps = _.cloneDeep(state.steps)
      updatedSteps[state.activeStepIndex].result = resultActionStepState.payload
      return {
        ...state,
        activeStepIndex: state.activeStepIndex + 1,
        steps: updatedSteps,
        actionStepState: {
          status: ACTION_STEP_STATE.IN_PROGRESS,
          message: '',
        },
      }
    })
    .addCase(switchCurrencies, (state) => {
      const inputStateInfo = state[Field.INPUT]
      const outputStateInfo = state[Field.OUTPUT]
      return {
        ...state,
        [Field.INPUT]: {
          ...outputStateInfo,
        },
        [Field.OUTPUT]: {
          ...inputStateInfo,
          routes: outputStateInfo.routes,
        },
      }
    })
    .addCase(setStepResult, (state, { payload: { actionStepState } }) => {
      const updatedSteps = _.cloneDeep(state.steps)
      const { payload } = actionStepState
      const index = state.activeStepIndex
      updatedSteps[index].result = payload
      return {
        ...state,
        steps: updatedSteps,
      }
    })
    .addCase(setSwapTransactions, (state, { payload: { swapTransactions } }) => {
      return {
        ...state,
        swapTransactions: swapTransactions,
      }
    })
    .addCase(setShouldFetchTransaction, (state, { payload: { shouldFetchTransaction } }) => {
      return {
        ...state,
        shouldFetchTransaction,
      }
    })
    .addCase(resetSwapState, (state) => {
      const inputStateInfo = state[Field.INPUT]
      const outputStateInfo = state[Field.OUTPUT]
      return {
        ...state,
        [Field.OUTPUT]: {
          ...outputStateInfo,
          routes: [],
        },
        shouldUpdateBalance: !state.shouldUpdateBalance,
        steps: [],
        pageState: SWAP_STATES.DEFAULT,
        inputAmount: new CurrencyAmount(0, inputStateInfo.currency.decimals),
        activeStepIndex: 0,
        actionStepState: {
          status: ACTION_STEP_STATE.IN_PROGRESS,
          message: '',
        },
        transactionId: '',
        transactionStatus: TransactionStatus.INITIATED,
        relayerFee: new CurrencyAmount(0, initialFromSwapSectionState.currency.decimals),
        relayerFeeDollarValue: 0,
        isValidInput: true,
        minimumAmount: 0,
      }
    })
    .addCase(
      setChainAndToken,
      (state, { payload: { fromChain, toChain, toToken, fromToken, fromCurrencies, toCurrencies } }) => {
        return {
          ...state,
          [Field.OUTPUT]: {
            chainId: toChain,
            currency: toToken,
            currencies: toCurrencies,
            routes: [],
          },
          [Field.INPUT]: {
            chainId: fromChain,
            currency: fromToken,
            currencies: fromCurrencies,
          },
        }
      },
    ),
)
// .addCase(setSwapTransactions, (state, { payload: { swapTransactions } }) => {
//   return {
//     ...state,
//     swapTransactions,
//   }
// })
