import { Modal, useModal } from 'widgets/Modal'
import React, { useEffect, useMemo, useState } from 'react'
import { ContinueButton, ErrorText } from './styles'
import { Box } from 'components/Box'
import { useTranslation } from 'contexts/Localization'
import { useConnection, useWallet } from '@solana/wallet-adapter-react'
import { useSwapActionHandlers } from 'state/swap/hooks'
import { Field } from 'state/types'
import { useChainInfo, useCurrency, useIsAutomaticOnTargetChain } from 'state/swap/selectors'
import { useWalletActionHandlers, useWalletState, useWalletStateHandler } from 'state/wallets/hooks'
import SwapProgressModal from 'views/Swap/Default/components/SwapProgressModal'
import { SWAP_STATES, UPDATE_ACTIONS } from 'state/swap/types'
import useMinimumFee from 'hooks/useMinimumFee'
import useMinimumBalance from 'hooks/useMinimumBalance'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import SingleWalletModal from './components/SingleWallet'
import { HeadingText } from './components'
import { CHAIN_KEYS, SupportedChainId, SupportedEVMChainId } from 'config/constants/chains'
import SwapWalletModal from 'components/WalletModal/components/SwapWallet'
import { usePostTransaction } from 'views/Swap/Default/hooks/postTransactions'
import { useStableChainList } from 'state/stableSwap/selectors'
import { useStableSwapActionHandlers } from 'state/stableSwap/hooks'
import { useSwapSelectedTab } from 'state/global/selectors'
import { SWAP_TAB } from 'state/global/types'

interface WalletLocalState {
  chainId: SupportedChainId
}

const WalletModal = ({ handleDismiss, field, isForConfirmation }) => {
  const { account, library } = useActiveWeb3React()
  const { publicKey } = useWallet()
  const { connection } = useConnection()
  const selectedSwapTab = useSwapSelectedTab()
  const { onSelectCurrency } = useStableSwapActionHandlers()
  const chainID = useChainInfo(field)
  const SupportedChains = useStableChainList()

  const { t } = useTranslation()
  const { onUpdateSwapPageState } = useSwapActionHandlers()
  const [isDisable, setIsDisabled] = useState(true)
  const [isLoading, setIsLoading] = useState(true)
  const [openProgressModel, swapProgressDismiss, setCloseOnOverlayClick] = useModal(
    <SwapProgressModal handleDismiss={() => swapProgressDismiss()} />,
    false,
  )
  // to store selected chain Locally
  const [errorText, setErrorText] = useState('')
  const fromWalletInfo = useWalletState(Field.INPUT)
  const toWalletInfo = useWalletState(Field.OUTPUT)

  let inputChainId = useChainInfo(Field.INPUT)
  let outputChainId = useChainInfo(Field.OUTPUT)

  const inputCurrency = useCurrency(Field.INPUT)
  const { handleMinReqFee } = useMinimumFee()
  const { handleMinReqBalance } = useMinimumBalance()
  const { onChainSelection, onSetTransactionId, onUpdateShouldFetchTransaction } = useSwapActionHandlers()
  const { onWalletStatusUpdate, getConnectedWalletAddress } = useWalletActionHandlers()

  const [walletLocalState, setWalletLocalState] = useState<WalletLocalState>()
  const [inputWalletState, setInputWalletState] = useState<WalletLocalState>()
  const [outputWalletState, setOutputWalletState] = useState<WalletLocalState>()

  useWalletStateHandler(field, isForConfirmation)
  const { isAutomaticOnTargetChain } = useIsAutomaticOnTargetChain()
  const { postTransaction } = usePostTransaction()

  useMemo(() => {
    const handleInitialChain = () => {
      setWalletLocalState({
        chainId: chainID,
      })
    }
    handleInitialChain()
  }, [chainID])

  useMemo(() => {
    const handleInitialChain = () => {
      setInputWalletState({
        chainId: inputChainId,
      })
      setOutputWalletState({
        chainId: outputChainId,
      })
    }
    handleInitialChain()
  }, [inputChainId, outputChainId])

  useEffect(() => {
    const ValidateBalances = async () => {
      let message = ''
      if (isForConfirmation) {
        setIsDisabled(true)
        setIsLoading(true)
        if (fromWalletInfo?.chainId === undefined) {
          message = t('Connect Source Wallet')
        } else if (toWalletInfo?.chainId === undefined) {
          message = t('Connect Destination Wallet')
        } else {
          const inputFee = await handleMinReqFee(fromWalletInfo?.chainId)
          // As when user selected output key, then we don't need to check for output amount.
          const outputFee = isAutomaticOnTargetChain
            ? {
                hasRequiredBalance: true,
                message: '',
                network: {
                  eventKey: '',
                },
              }
            : await handleMinReqFee(toWalletInfo?.chainId)
          if (!inputFee.hasRequiredBalance) {
            message = t(inputFee.message).replace('%s', inputFee.network.eventKey)
          } else if (!outputFee.hasRequiredBalance) {
            message = t(outputFee.message).replace('%s', outputFee.network.eventKey)
          } else {
            const balance = await handleMinReqBalance(fromWalletInfo?.chainId, inputCurrency, inputFee)
            if (!balance.hasRequiredBalance) {
              message = t(balance.message).replace('%s', inputCurrency.symbol)
            } else {
              setIsDisabled(false)
            }
          }
        }
        setIsLoading(false)
      }
      setErrorText(message)
    }
    ValidateBalances()
  }, [fromWalletInfo?.chainId, toWalletInfo?.chainId, isForConfirmation, publicKey, account, library, connection])

  function selectNetwork() {
    onChainSelection(field, walletLocalState.chainId)
    if (field === Field.INPUT) {
      inputChainId = walletLocalState.chainId
    } else {
      outputChainId = walletLocalState.chainId
    }

    if (selectedSwapTab === SWAP_TAB.STABLE) {
      const tokenList = SupportedChains ? SupportedChains[CHAIN_KEYS[walletLocalState.chainId]]?.tokens : []
      onSelectCurrency(tokenList && tokenList.length > 0 ? tokenList[0] : null, field)
    }

    if (account) {
      SupportedEVMChainId(inputChainId) && onWalletStatusUpdate(Field.INPUT, { chainId: inputChainId })
      SupportedEVMChainId(outputChainId) && onWalletStatusUpdate(Field.OUTPUT, { chainId: outputChainId })
    } else {
      SupportedEVMChainId(inputChainId) && onWalletStatusUpdate(Field.INPUT, null)
      SupportedEVMChainId(outputChainId) && onWalletStatusUpdate(Field.OUTPUT, null)
    }
    if (
      window.tronWeb &&
      window.tronWeb.defaultAddress &&
      window.tronWeb.defaultAddress.base58 &&
      window.tronWeb.ready
    ) {
      inputChainId === SupportedChainId.TRON && onWalletStatusUpdate(Field.INPUT, { chainId: inputChainId })
      outputChainId === SupportedChainId.TRON && onWalletStatusUpdate(Field.OUTPUT, { chainId: outputChainId })
    } else {
      inputChainId === SupportedChainId.TRON && onWalletStatusUpdate(Field.INPUT, null)
      outputChainId === SupportedChainId.TRON && onWalletStatusUpdate(Field.OUTPUT, null)
    }
    if (publicKey) {
      !SupportedEVMChainId(inputChainId) && onWalletStatusUpdate(Field.INPUT, { chainId: inputChainId })
      !SupportedEVMChainId(outputChainId) && onWalletStatusUpdate(Field.OUTPUT, { chainId: outputChainId })
    } else {
      !SupportedEVMChainId(inputChainId) &&
        inputChainId !== SupportedChainId.TRON &&
        onWalletStatusUpdate(Field.INPUT, null)
      !SupportedEVMChainId(outputChainId) &&
        outputChainId !== SupportedChainId.TRON &&
        onWalletStatusUpdate(Field.OUTPUT, null)
    }
  }

  return (
    <Modal
      hideCloseButton={false}
      onDismiss={() => {
        if (!isForConfirmation) {
          selectNetwork()
        }
        onUpdateSwapPageState(SWAP_STATES.DEFAULT)
        handleDismiss()
      }}
      title={
        <HeadingText
          step={isForConfirmation ? 0 : 1}
          heading={isForConfirmation ? t('Continue With Wallets') : t('Choose Network')}
        />
      }
      isConformation={isForConfirmation}
      minWidth={isForConfirmation ? '450px' : '481px'}
    >
      {/* Body of the modal  */}
      {!isForConfirmation ? (
        <SingleWalletModal
          field={field}
          isForConfirmation={false}
          walletLocalState={walletLocalState}
          setWalletLocalState={setWalletLocalState}
        />
      ) : (
        <SwapWalletModal
          inputWalletState={inputWalletState}
          setInputWalletState={setInputWalletState}
          outputWalletState={outputWalletState}
          setOutputWalletState={setOutputWalletState}
          isForConfirmation={true}
        />
      )}

      {/* Footer of the modal */}
      <ContinueButton
        isLoading={isForConfirmation && isLoading}
        disabled={isForConfirmation && isLoading}
        onClick={async () => {
          if (isForConfirmation) {
            setIsLoading(true)
            if (!fromWalletInfo || !toWalletInfo || isDisable) {
              handleDismiss()
              return
            }
            // const isLocalHost = window.location.host.includes('localhost')
            const fromAddress = getConnectedWalletAddress(Field.INPUT)
            const toAddress = getConnectedWalletAddress(Field.OUTPUT)
            const result = await postTransaction(fromAddress, toAddress, 'local', isAutomaticOnTargetChain)
            setIsLoading(false)
            if (result.status === 200 && result.data.code === 200) {
              onUpdateShouldFetchTransaction(UPDATE_ACTIONS.SHOULD_UPDATE)
              onSetTransactionId(result.data.transactionId)
              onUpdateSwapPageState(SWAP_STATES.SWAP_IN_PROGRESS)
              handleDismiss()
              setCloseOnOverlayClick(false)
              return openProgressModel()
            } else {
              //todo what if there is an error in creating transaction
            }
          } else {
            selectNetwork()
            handleDismiss()
          }
        }}
      >
        {t('Continue')}
      </ContinueButton>
      <Box>
        {/* <HCaptcha
          // This is testing sitekey, will autopass
          // Make sure to replace
          sitekey={SITE_KEY}
          size="invisible"
          onVerify={setCaptcha}
          onError={onError}
          onExpire={onExpire}
          ref={captchaRef}
        /> */}
      </Box>
      <Box width="100%">
        <ErrorText>
          {!fromWalletInfo && isForConfirmation ? t('Connect Source Wallet') : isLoading ? '' : errorText}
        </ErrorText>
      </Box>

      {/*<Box width={'100%'}>*/}
      {/*  <TermsAndConditions textAlign={'center'}>{t('Terms & Conditions')}</TermsAndConditions>*/}
      {/*</Box>*/}
    </Modal>
  )
}

export default WalletModal
