// Set of helper functions to facilitate wallet setup

import { ACTION_STEP_STATE, IActionStepState } from 'state/swap/types'
import * as Sentry from '@sentry/react'

const chains = {
  1: {
    chainName: 'Ethereum Mainnet',
    nativeCurrency: {
      name: 'ETH',
      symbol: 'eth',
      decimals: 18,
    },
    rpcUrls: ['https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'],
    blockExplorerUrls: [`https://etherscan.io`],
  },
  56: {
    chainName: 'Binance Mainnet',
    nativeCurrency: {
      name: 'BNB',
      symbol: 'bnb',
      decimals: 18,
    },
    rpcUrls: [
      'https://bsc-dataseed.binance.org/',
      'https://bsc-dataseed1.defibit.io/',
      'https://bsc-dataseed1.ninicoin.io/',
    ],
    blockExplorerUrls: [`https://bscscan.com/`],
  },
  137: {
    chainName: 'POLYGON Mainnet',
    nativeCurrency: {
      name: 'MATIC',
      symbol: 'MATIC',
      decimals: 18,
    },
    rpcUrls: ['https://polygon-rpc.com'],
    blockExplorerUrls: ['https://polygonscan.com/'],
  },
  43114: {
    chainName: 'AVAX Mainnet',
    nativeCurrency: {
      name: 'Avalaunch',
      symbol: 'AVAX',
      decimals: 18,
    },
    rpcUrls: ['https://api.avax.network/ext/bc/C/rpc'],
    blockExplorerUrls: ['https://snowtrace.io/'],
  },
  250: {
    chainName: 'FANTOM Mainnet',
    nativeCurrency: {
      name: 'FANTOM',
      symbol: 'FTM',
      decimals: 18,
    },
    rpcUrls: ['https://rpc.ftm.tools/'],
    blockExplorerUrls: ['https://ftmscan.com/'],
  },
}
/**
 * Prompt the user to add BSC as a network on Metamask, or switch to BSC if the wallet is on a different network
 * @returns {boolean} true if the setup succeeded, false otherwise
 */
export const setupNetwork = async (chainId = 1) => {
  const provider = window.ethereum
  if (provider) {
    try {
      await provider.request({
        method: 'wallet_addEthereumChain',
        params: [
          {
            chainId: `0x${chainId.toString(16)}`,
            ...chains[chainId],
          },
        ],
      })
      return true
    } catch (error) {
      Sentry.captureException(error)
      return false
    }
  } else {
    Sentry.captureException(new Error("Can't setup the BSC network on metamask because window.ethereum is undefined"))
    return false
  }
}
export const isSameNetwork = async (chainId) => {
  const provider = window.ethereum
  if (provider) {
    const selectedChainId = await provider.request({
      method: 'eth_chainId',
    })
    // @ts-ignore
    return selectedChainId === `0x${chainId.toString(16)}`
  }
}

export const getMetaMaskNetwork = async () => {
  const provider = window.ethereum
  // if (provider) {
  //   return await provider.request({
  //     method: 'eth_chainId',
  //   })
  // }
  return await provider.request({
    method: 'eth_chainId',
  })
}

export const changeNetwork = async (chainId = 1): Promise<IActionStepState> => {
  const provider = window.ethereum
  if (provider) {
    try {
      const isSame = await isSameNetwork(chainId)
      if (isSame) {
        return getSuccessResponse()
      }
      await provider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: `0x${chainId.toString(16)}` }],
      })
      return getWaitForLibraryStatus()
    } catch (error) {
      //chain unrecognized adding new chain
      if (error['code'] === 4902) {
        return await addNewNetwork(chainId)
      } else if (error['code'] === 4001) {
        return getErrorResponse()
      }
    }
    return getErrorResponse()
  }
}
const addNewNetwork = async (chainId): Promise<IActionStepState> => {
  const provider = window.ethereum
  if (provider) {
    try {
      await provider.request({
        method: 'wallet_addEthereumChain',
        params: [
          {
            chainId: `0x${chainId.toString(16)}`,
            ...chains[chainId],
          },
        ],
      })
      const selectedChainId = await provider.request({
        method: 'eth_chainId',
      })
      // @ts-ignore
      if (selectedChainId === `0x${chainId.toString(16)}`) {
        return getWaitForLibraryStatus()
      } else {
        return getErrorResponse()
      }
    } catch (error) {
      if (error['code'] === 4001) {
        return getErrorResponse()
      }
    }
  }
  return getErrorResponse()
}

const getSuccessResponse = () => {
  return {
    status: ACTION_STEP_STATE.OK,
    message: 'Network Changed',
  }
}
const getWaitForLibraryStatus = () => {
  return {
    status: ACTION_STEP_STATE.WAIT_FOR_LIBRARY,
    message: 'Wait for library change',
  }
}
const getErrorResponse = () => {
  return {
    status: ACTION_STEP_STATE.ERROR,
    message: 'User rejected the request.',
  }
}

/**
 * Prompt the user to add a custom token to metamask
 * @param tokenAddress
 * @param tokenSymbol
 * @param tokenDecimals
 * @returns {boolean} true if the token has been added, false otherwise
 */
export const registerToken = async (tokenAddress: string, tokenSymbol: string, tokenDecimals: number) => {
  const tokenAdded = await window.ethereum.request({
    method: 'wallet_watchAsset',
    params: {
      type: 'ERC20',
      options: {
        address: tokenAddress,
        symbol: tokenSymbol,
        decimals: tokenDecimals,
        image: `/images/tokens/${tokenAddress}.png`,
      },
    },
  })

  return tokenAdded
}
