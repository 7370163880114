import { Box, Flex } from 'components/Box'
import useTheme from 'hooks/useTheme'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { breakpointMap } from 'theme/base'
import useViewport from 'hooks/useViewport'
import { Navbar as NavbarBootstrap, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Logo, MenuIcon } from 'components/Svg'
import { Button, NavTitle, NavItemsContainer, RoundBox, IconButton } from './styles'
import { useModal } from 'widgets/Modal'
import WalletModal from '../WalletModal'
import { Field } from 'state/types'
import { useWalletActionHandlers } from 'state/wallets/hooks'
import truncateHash from 'utils/truncateHash'
import { Text } from 'components/Text'
import useATSPrice from 'hooks/useAtsPrice'
import Select from 'components/Header/components/LanguageMenu'
import { languageList } from 'config/localization/languages'
import { useTranslation } from 'contexts/Localization'
import MobileDrawer from './components/MobileDrawer'
import HistoryModal from 'views/Swap/Default/components/HistoryModal'
import { FiClock } from 'react-icons/fi'
import StableWalletModal from '../WalletModalStable'
import { useSwapSelectedTab } from 'state/global/selectors'
import { SWAP_TAB } from 'state/global/types'

const NavItem = ({ text, isDisabled, to, externalLink = false }) => {
  const { theme } = useTheme()

  if (isDisabled) {
    return (
      <OverlayTrigger placement="bottom" overlay={<Tooltip>Coming Soon</Tooltip>}>
        <Box style={{ opacity: 0.5 }} m="0 1%" p={'0px 16px'} borderRadius="8px" className="cursor-pointer">
          <NavTitle disabled>{text}</NavTitle>
        </Box>
      </OverlayTrigger>
    )
  }

  return (
    <Box m="0 1%" p={'0px 16px'} borderRadius="8px" className="cursor-pointer">
      {externalLink ? (
        <a href={to} target="_blank">
          <NavTitle>{text}</NavTitle>
        </a>
      ) : (
        <Link href={to}>
          <NavTitle>{text}</NavTitle>
        </Link>
      )}
      {window.location.pathname === to && (
        <Box width="100%" height={'4px'} borderRadius="4px" background={theme.colors.gradientBackground}></Box>
      )}
    </Box>
  )
}

const Header: React.FC = () => {
  const { width } = useViewport()
  const router = useRouter()
  const { theme } = useTheme()
  const { t } = useTranslation()

  if (width <= 1200) {
    return <MobileDrawer />
  }

  return (
    <>
      <NavbarBootstrap expand="xl" style={{ padding: '6px 1rem', borderBottom: `solid 1px ${theme.colors.secondary}` }}>
        <NavbarBootstrap.Brand onClick={() => router.push('/swap')}>
          <Logo width={width <= breakpointMap.md ? '90' : '160'} />
        </NavbarBootstrap.Brand>
        {width <= breakpointMap.xl && <WalletConnection />}

        <NavbarBootstrap.Toggle aria-controls="basic-navbar-nav">
          <MenuIcon width="26px" height="26px" />
        </NavbarBootstrap.Toggle>
        <NavbarBootstrap.Collapse id="basic-navbar-nav">
          <NavItemsContainer>
            {/* <NavItem text={'Dashboard'} isDisabled={false} to="/" /> */}
            <NavItem text={t('Swap')} isDisabled={false} to="/swap" />
            <NavItem text={t('Staking')} isDisabled={false} to="/stake" />
            <NavItem
              text={t('Pools')}
              isDisabled={false}
              to="/pools"
              // externalLink={true}
            />
            <NavItem text={t('Farms')} isDisabled={false} to="/farms" />
          </NavItemsContainer>
        </NavbarBootstrap.Collapse>
        {!(width <= breakpointMap.xl) && <WalletConnection />}
      </NavbarBootstrap>
    </>
  )
}

const WalletConnection = () => {
  const { theme } = useTheme()
  const atsPriceInUSD = useATSPrice()
  const { width } = useViewport()
  const { t } = useTranslation()
  const selectedTab = useSwapSelectedTab()

  const { getConnectedWalletAddress } = useWalletActionHandlers()
  const activeUserAddress = getConnectedWalletAddress(Field.INPUT)

  const [shoWalletConnectModal, onDismiss] = useModal(
    <WalletModal handleDismiss={() => onDismiss()} field={Field.INPUT} isForConfirmation={false} />,
    true,
  )

  const [showStableWalletConnectModal, onDismissStable] = useModal(
    <StableWalletModal handleDismiss={() => onDismissStable()} field={Field.INPUT} isForConfirmation={false} />,
    true,
  )

  const [showHistoryModal, onHistoryDismiss] = useModal(<HistoryModal handleDismiss={() => onHistoryDismiss()} />, true)

  return (
    <Flex justifyContent="center" alignItems="center">
      <Select weight={400} options={languageList} />
      <IconButton onClick={showHistoryModal}>
        <FiClock className="icon" />
      </IconButton>
      <RoundBox>
        <Box className="inner-section">
          <img
            src="https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HJbNXx2YMRxgfUJ6K4qeWtjatMK5KYQT1QnsCdDWywNv/logo.png"
            alt=""
            width={'50%'}
          />
        </Box>
      </RoundBox>
      <Text
        fontSize={'16px'}
        fontFamily={theme.fonts.secondary}
        fontWeight={theme.fonts.semiBold}
        color={theme.colors.text}
        mr={width <= 990 ? '0px' : '20px'}
      >
        ${atsPriceInUSD.toFixed(3)}
      </Text>

      <Button
        onClick={() => {
          selectedTab === SWAP_TAB.STABLE ? showStableWalletConnectModal() : shoWalletConnectModal()
        }}
      >
        <div>{!activeUserAddress ? t('Connect Wallet') : truncateHash(activeUserAddress)}</div>
      </Button>
    </Flex>
  )
}

export default Header
