export enum TransactionStatus {
  // Transaction is just initiated by frontend
  INITIATED = 'INITIATED',
  // A third party service is not available
  INVALID = 'INVALID',
  // The transfer is pending
  PENDING = 'PENDING',
  // the transfer is broadcasted on target chain.
  WAIT_DESTINATION_TRANSACTION = 'WAIT_DESTINATION_TRANSACTION',

  // these 4 states will come in order from backend

  // before generating vaa transaction is in block confirmation state
  BLOCK_CONFIRMATION = 'BLOCK_CONFIRMATION',
  // the transfer listen at relayer
  IN_QUEUE = 'IN_QUEUE',
  // The transfer is processing
  PROCESSING = 'PROCESSING',
  // The transfer is done
  DONE = 'DONE',
  // The transfer failed
  FAILED = 'FAILED',
}

export enum TRANSACTION_STATUS {
  INVALID = 'Service not available',
  PENDING = 'In progress',
  WAIT_DESTINATION_TRANSACTION = 'Waiting confirmation',
  BLOCK_CONFIRMATION = 'Confirming blocks',
  IN_QUEUE = 'In queue',
  PROCESSING = 'Processing',
  DONE = 'Done',
  FAILED = 'Transaction failed',
}
