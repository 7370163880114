import { Box, Flex } from 'components/Box'
import { useTranslation } from 'contexts/Localization'
import { useWalletActionHandlers } from 'state/wallets/hooks'
import { useWallet } from '@solana/wallet-adapter-react'
import useAuth from 'hooks/wallets/useAuthEVM'
import { SupportedChainId } from 'config/constants/chains'
import useToast from 'hooks/useToast'
import Image from 'components/Image'
import { EVM_WALLETS } from 'config'
import { WalletReadyState } from '@solana/wallet-adapter-base'
import { FC, useEffect, useState } from 'react'
import { WalletIconProps } from 'components/WalletModal/type'
import { WalletName } from 'components/WalletModal/styles'
import { WalletStateEnum } from 'state/wallets/types'
import { useRouter } from 'next/router'
import { TronLinkIcon } from 'components/Svg'

const RenderWalletList = ({ field, isForConfirmation, setWalletLocalState, walletLocalState }) => {
  const { t } = useTranslation()
  const { toastError } = useToast()

  const { connectEVM } = useAuth()
  const { wallets: solanaWallets, select } = useWallet()
  const { onWalletStateUpdate, onWalletStatusUpdate } = useWalletActionHandlers()
  const [isTron, setIsTron] = useState<boolean>(false)

  const [disabled, setDisabled] = useState(false)
  const router = useRouter()
  useEffect(() => {
    if (router.pathname === '/farms' || router.pathname === '/pools') {
      setDisabled(true)
    } else {
      setDisabled(false)
    }
  }, [router])

  useEffect(() => {
    let interval = null
    const getTronWeb = () => {
      // Obtain the tronweb object injected by tronLink
      interval = setInterval(async () => {
        if (window.tronWeb && window.tronWeb.ready) {
          if (window.tronWeb.defaultAddress.base58) {
            setIsTron(false)
            onWalletStatusUpdate(field, { chainId: SupportedChainId.TRON })
            setWalletLocalState({
              ...walletLocalState,
            })
          }
        } else {
          //Here we have to enable Tron again
          setIsTron(false)
        }
      }, 20)
    }
    isTron && getTronWeb()
    return () => {
      clearInterval(interval)
    }
  }, [isTron])

  //List of wallets For Solana
  const SolanaWalletRow = () => {
    return (
      <Box className="row" width={'100%'}>
        {solanaWallets.map((item, index) => (
          <Flex
            key={index}
            flexDirection={'column'}
            alignItems={'center'}
            className="col-2 pointer"
            mt="12px"
            onClick={async () => {
              const walletObj = item
              if (
                walletObj.readyState === WalletReadyState.Installed ||
                walletObj.readyState === WalletReadyState.Loadable
              ) {
                await select(walletObj.adapter.name)
                onWalletStateUpdate(WalletStateEnum.READY)

                setWalletLocalState({
                  ...walletLocalState,
                })
              } else {
                toastError(t('Error'), t('Please Install Wallet Extension'))
              }
            }}
          >
            <WalletIcon wallet={item} />
            <WalletName>
              {item.adapter.name.includes('(Extension)')
                ? item.adapter.name.replace('(Extension)', '(Ext)')
                : item.adapter.name}
            </WalletName>
          </Flex>
        ))}
      </Box>
    )
  }

  //List of wallets for EVM
  const EvmWalletRow = () => {
    return (
      <Box className="row" width={'100%'}>
        {EVM_WALLETS.map((item, index) => (
          <Flex
            key={index}
            flexDirection={'column'}
            alignItems={'center'}
            className="col-2 pointer"
            mt="12px"
            onClick={async () => {
              if (!disabled) {
                await connectEVM(item.connector, walletLocalState.chainId, field, isForConfirmation)
                setWalletLocalState({
                  ...walletLocalState,
                })
              }
            }}
          >
            <Image src={item.adapter.icon} width={'40px'} height={'40px'} />
            <WalletName>{item.adapter.name} </WalletName>
          </Flex>
        ))}
      </Box>
    )
  }

  const TronWalletRow = () => {
    return (
      <Box className="row" width={'100%'}>
        <Flex className="pointer" mt="12px">
          <Flex
            alignItems={'center'}
            flexDirection={'column'}
            height={'fit-content'}
            onClick={async () => {
              if (!disabled) {
                setIsTron(true)
                // setWalletLocalState({
                //   ...walletLocalState,
                // })
                // await connectEVM(item.connector, walletLocalState.chainId, field, isForConfirmation)
                // setWalletLocalState({
                //   ...walletLocalState,
                // })
              }
            }}
          >
            <TronLinkIcon width={'40px'} height={'40px'} />
            <WalletName>TronLink</WalletName>
          </Flex>
        </Flex>
      </Box>
    )
  }
  return walletLocalState.chainId === SupportedChainId.SOLANA ? (
    <SolanaWalletRow />
  ) : walletLocalState.chainId === SupportedChainId.TRON ? (
    <TronWalletRow />
  ) : (
    <EvmWalletRow />
  )
}
const WalletIcon: FC<WalletIconProps> = ({ wallet }) => {
  return <Image src={wallet.adapter.icon} width={'40px'} height={'40px'} />
}
export default RenderWalletList
