import { Navbar as NavbarBootstrap } from 'react-bootstrap'
import { Logo, MenuIcon } from 'components/Svg'
import { breakpointMap } from 'theme/base'
import { Box, Flex } from 'components/Box'
import { DrawerItem, DrawerItemsContainer, RoundBox, HrLine } from '../../styles'
import Link from 'next/link'
import { Text } from 'components/Text'
import useViewport from 'hooks/useViewport'
import { useRouter } from 'next/router'
import useTheme from 'hooks/useTheme'
import useATSPrice from 'hooks/useAtsPrice'
import truncateHash from 'utils/truncateHash'
import { useModal } from 'widgets/Modal'
import WalletModal from 'components/WalletModal'
import { IoIosArrowForward } from 'react-icons/io'
import { Field } from 'state/types'
import { useTranslation } from 'contexts/Localization'
import { useWalletActionHandlers } from 'state/wallets/hooks'
import Button from 'components/Button'
import { useState } from 'react'
import { STEP_BY_STEP_GUIDE, HOMEPAGE, FAQ } from 'config/constants/endpoints'

const MobileDrawer = () => {
  const { width } = useViewport()
  const router = useRouter()
  const [show, setShow] = useState(false)
  const { theme } = useTheme()
  const { t } = useTranslation()
  const { getConnectedWalletAddress } = useWalletActionHandlers()

  const activeUserAddress = getConnectedWalletAddress(Field.INPUT)
  const [shoWalletConnectModal, onDismiss] = useModal(
    <WalletModal handleDismiss={() => onDismiss()} field={Field.INPUT} isForConfirmation={false} />,
    true,
  )

  return (
    <>
      <NavbarBootstrap expand="xl" style={{ padding: '6px 1rem', borderBottom: `solid 1px ${theme.colors.secondary}` }}>
        <NavbarBootstrap.Brand onClick={() => router.push('/swap')}>
          <Logo width={width <= breakpointMap.md ? '90' : '160'} />
        </NavbarBootstrap.Brand>

        <NavbarBootstrap.Toggle aria-controls="basic-navbar-nav" onClick={() => setShow(true)}>
          <MenuIcon width="26px" height="26px" />
        </NavbarBootstrap.Toggle>
        <NavbarBootstrap.Offcanvas
          id="basic-navbar-nav"
          show={show}
          onShow={() => setShow(true)}
          onHide={() => setShow(false)}
        >
          <Flex p={'1rem'} alignItems={'center'} justifyContent={'center'}>
            <Logo width={'150px'} />
          </Flex>
          <HrLine />
          <DrawerItemsContainer>
            <DrawerItem active={window.location.pathname === '/swap'} onClick={() => setShow(false)}>
              <Link href={'/swap'}>{t('Swap')}</Link>
              <IoIosArrowForward color={theme.colors.text} className="ms-2" />
            </DrawerItem>
            <DrawerItem active={window.location.pathname === '/stake'} onClick={() => setShow(false)}>
              <Link href={'/stake'}>{t('Staking')}</Link>
              <IoIosArrowForward color={theme.colors.text} className="ms-2" />
            </DrawerItem>
            <DrawerItem active={window.location.pathname === '/pools'} onClick={() => setShow(false)}>
              <Link href={'/pools'}>{t('Pools')}</Link>
              <IoIosArrowForward color={theme.colors.text} className="ms-2" />
            </DrawerItem>
            <DrawerItem className="disabled" active={false}>
              <Link href={''}>{t('Farms')}</Link>
              <IoIosArrowForward color={theme.colors.text} className="ms-2" />
            </DrawerItem>
          </DrawerItemsContainer>
          <HrLine />
          <ATSPriceBox />
          <MobileDrawerLinks itemClick={() => setShow(false)} />
          <Flex width={'100%'} mt={'1rem'} justifyContent={'center'}>
            <Button
              onClick={() => {
                shoWalletConnectModal()
                setShow(false)
              }}
              width={'100%'}
              p={'0.4rem'}
            >
              <div>{!activeUserAddress ? t('Connect Wallet') : truncateHash(activeUserAddress)}</div>
            </Button>
          </Flex>
        </NavbarBootstrap.Offcanvas>
      </NavbarBootstrap>
    </>
  )
}

const ATSPriceBox = () => {
  const atsPriceInUSD = useATSPrice()
  const { theme } = useTheme()
  const { width } = useViewport()

  return (
    <Flex p={'1.4rem'} alignItems={'center'} justifyContent={'space-between'}>
      <Flex>
        <RoundBox>
          <Box className="inner-section dex-logo">
            <img
              src="https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HJbNXx2YMRxgfUJ6K4qeWtjatMK5KYQT1QnsCdDWywNv/logo.png"
              alt=""
              width={'16px'}
            />
          </Box>
        </RoundBox>
        <Text
          fontSize={'16px'}
          fontFamily={theme.fonts.secondary}
          fontWeight={theme.fonts.semiBold}
          color={theme.colors.text}
          mr={width <= 990 ? '0px' : '20px'}
        >
          ${atsPriceInUSD}
        </Text>
      </Flex>

      <IoIosArrowForward color={theme.colors.text} className="ms-2" />
    </Flex>
  )
}

const MobileDrawerLinks = (props) => {
  const { theme } = useTheme()
  const { t } = useTranslation()

  return (
    <Box>
      <HrLine />
      <DrawerItemsContainer>
        <DrawerItem active={false} onClick={props.itemClick}>
          <a href={HOMEPAGE} target={'_blank'}>
            {t('Homepage')}
          </a>
          <IoIosArrowForward color={theme.colors.text} className="ms-2" />
        </DrawerItem>
        <DrawerItem active={false} onClick={props.itemClick}>
          <a href={STEP_BY_STEP_GUIDE} target={'_blank'}>
            {t('Step-by-Step Guide')}
          </a>
          <IoIosArrowForward color={theme.colors.text} className="ms-2" />
        </DrawerItem>
        <DrawerItem active={false} onClick={props.itemClick}>
          <a href={FAQ} target={'_blank'}>
            {t('FAQs')}
          </a>
          <IoIosArrowForward color={theme.colors.text} className="ms-2" />
        </DrawerItem>
      </DrawerItemsContainer>
      <HrLine />
    </Box>
  )
}

export default MobileDrawer
