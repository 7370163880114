import { useCallback } from 'react'
import { IRoute } from 'state/swap/types'
import { Currency, CurrencyAmount } from 'config/entities'
import use1inchQuote from './use1inchQuote'
import use0xQuote from 'views/Swap/Default/hooks/use0xQuote'
import * as Sentry from '@sentry/react'

export const useQuote = () => {
  const { handle1InchRoute } = use1inchQuote()
  const { handle0xRate } = use0xQuote()

  const handleQuotes = useCallback(
    /**
     *
     * @param inputCurrency
     * @param outputCurrency
     * @param amount1 : amount1 is for 1inch Route
     * @param amount2 : amount2 is for 0x Route
     */
    async (inputCurrency: Currency, outputCurrency: Currency, amount1: CurrencyAmount, amount2: CurrencyAmount) => {
      const routes: IRoute[] = []
      try {
        const routeRes = await handle1InchRoute(inputCurrency, outputCurrency, amount1)
        routes.push(routeRes)
      } catch (e) {
        Sentry.captureException(e)
      }
      try {
        // const routeRes = await handle0xRate(inputCurrency, outputCurrency, amount2)
        // routes.push(routeRes)
      } catch (e) {
        Sentry.captureException(e)
      }
      return routes
    },
    [],
  )
  return { handleQuotes }
}
