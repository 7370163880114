import React from 'react'
import ChainBox from '../ChainBox'
import WalletBox from '../WalletBox'
import { useTranslation } from 'contexts/Localization'
import { BoxWrapper, HeadingText } from '..'

const SingleWalletModal = ({ field, isForConfirmation, walletLocalState, setWalletLocalState, setError }) => {
  const { t } = useTranslation()

  return (
    <>
      {/* Card to show all blockChain  */}
      <BoxWrapper>
        <ChainBox field={field} setWalletLocalState={setWalletLocalState} walletLocalState={walletLocalState} />
      </BoxWrapper>
      <HeadingText step={2} heading={t('Choose Wallet')} />
      {/* Card to show all Wallet  */}
      <BoxWrapper>
        <WalletBox
          field={field}
          isForConfirmation={isForConfirmation}
          setWalletLocalState={setWalletLocalState}
          walletLocalState={walletLocalState}
          setError={setError}
        />
      </BoxWrapper>
    </>
  )
}

export default SingleWalletModal
