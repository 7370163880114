import { useCallback } from 'react'
import * as Sentry from '@sentry/react'
import { getMulticallAddress } from 'utils/addressHelpers'
import { multicallv2 } from 'utils/multicall'
import erc20Abi from 'config/abi/erc20.json'
import { CurrencyAmount } from 'config/entities'
import { getEVMNodeURL } from 'utils/getRpcUrl'
import { ethers } from 'ethers'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useConnection, useWallet } from '@solana/wallet-adapter-react'
import { SupportedChainId, SupportedEVMChainId } from 'config/constants/chains'
import { MinimumBalanceResponse } from 'config/constants/types'
import { getBlockChainNetwork } from 'utils'

const useMinimumFee = () => {
  const { account } = useActiveWeb3React()
  const { publicKey } = useWallet()
  const { connection } = useConnection()

  async function handleMultiCalls(network, provider) {
    const balanceCalls = [
      {
        address: getMulticallAddress(network.chainId),
        name: 'getEthBalance',
        params: [account],
      },
    ]
    try {
      const balanceResults = await multicallv2(erc20Abi, balanceCalls, network.chainId, provider)
      return new CurrencyAmount(balanceResults[0], network.decimals)
    } catch (error) {
      Sentry.captureException(error)
    }
    return new CurrencyAmount(0, network.decimals)
  }

  const fetchEVMBalance = async (network): Promise<MinimumBalanceResponse> => {
    const providerURL = getEVMNodeURL[network.chainId]
    const provider = new ethers.providers.JsonRpcProvider(providerURL)
    const nativeCurrencyBalance = await handleMultiCalls(network, provider)

    return {
      hasRequiredBalance: nativeCurrencyBalance.toWei().isGreaterThan(network.minimumFee.toWei()),
      network: network,
      message: `Insufficient Fee`,
      balance: nativeCurrencyBalance,
    }
  }
  const fetchSolBalance = async (network): Promise<MinimumBalanceResponse> => {
    const sol = await connection.getBalance(publicKey)
    const solBalance = new CurrencyAmount(sol, network.decimals)

    return {
      hasRequiredBalance: solBalance.toWei().isGreaterThan(network.minimumFee.toWei()),
      network: network,
      message: `Insufficient Fee`,
      balance: solBalance,
    }
  }

  const fetchTronBalance = async (network): Promise<MinimumBalanceResponse> => {
    const tronWeb = window.tronWeb
    const balance = await tronWeb.trx.getBalance(tronWeb.defaultAddress.base58)
    const tronBalance = new CurrencyAmount(balance, network.decimals)
    return {
      hasRequiredBalance: tronBalance.toWei().isGreaterThan(network.minimumFee.toWei()),
      network: network,
      message: `Insufficient Fee`,
      balance: tronBalance,
    }
  }

  const handleMinReqFee = useCallback(
    async (chainId): Promise<MinimumBalanceResponse> => {
      const network = getBlockChainNetwork(chainId)
      try {
        if (SupportedEVMChainId(chainId)) {
          return await fetchEVMBalance(network)
        } else if (chainId === SupportedChainId.TRON) {
          return await fetchTronBalance(network)
        } else {
          return await fetchSolBalance(network)
        }
      } catch (error) {
        Sentry.captureException(error)
        return {
          hasRequiredBalance: false,
          network: network,
          message: `Insufficient Fee`,
          balance: new CurrencyAmount(0),
        }
      }
    },
    [publicKey, account],
  )
  return { handleMinReqFee }
}

export default useMinimumFee
