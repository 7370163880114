import { createAction } from '@reduxjs/toolkit'
import { SupportedChainId } from 'config/constants/chains'
import { IActionStepState, IPoolStep, POOL_STATES } from 'state/pools/types'
import { IActionLiquidityState, Pool } from './types'

export const getPoolsList = createAction<{ list: Pool[] }>('pools/getPoolsList')
export const setPoolChainId = createAction<{ chainId: SupportedChainId }>('pools/setPoolChainId')
export const setSteps = createAction<{ steps: IPoolStep[] }>('pools/setSteps')
export const updatePoolPageState = createAction<{ poolPageState: POOL_STATES }>('pools/updatePoolPageState')
export const setActionState = createAction<{ actionStepState: IActionStepState }>('pools/setActionStepState')
export const setStepResult = createAction<{ actionStepState: IActionStepState }>('pools/setStepResult')
export const setActiveStepIndex = createAction<{ resultActionStepState: IActionStepState }>('pools/setActiveStepIndex')

export const setPoolAddress = createAction<{ pairAddress: string; routerAddress: string; farmAddress: string }>(
  'pools/setPoolAddress',
)
export const resetPoolState = createAction<void>('pools/resetPoolState')
export const setPairLiquidityState = createAction<{ liquidity: IActionLiquidityState }>('pools/setPairLiquidityState')
export const setLpBalanceState = createAction<{ lpBalance: string }>('pools/setLpBalanceState')
export const setUserShare = createAction<{ userShare: string }>('pools/setUserShare')
export const setUserAssets = createAction<{ userAssets: any }>('pools/setUserAssets')

export const setFarmDeposit = createAction<{ lpDeposited: string }>('pools/setFarmDeposit')
export const setFarmPendingReward = createAction<{ pendingReward: string }>('pools/setFarmPendingReward')
export const resetPoolAssets = createAction<void>('pools/resetPoolValue')
export const resetFarmsAssets = createAction<void>('pools/resetFarmsAssets')
export const setFocusSide = createAction<{ focusSide: string }>('pools/setFocusSide')
export const setGenericStats = createAction<{ liquidity: number; volume: number }>('pools/setGenericStats')
export const setAutoRefresh = createAction<{ refresh: boolean }>('pools/setAutoRefresh')
