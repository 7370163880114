import React from 'react'
import WalletBox from '../WalletBox'
import { BoxWrapper } from '..'
import { Field } from 'state/types'
import { Box } from 'components/Box'

const SwapWalletModal = ({
  inputWalletState,
  setInputWalletState,
  outputWalletState,
  setOutputWalletState,
  isForConfirmation,
}) => {
  return (
    <>
      <BoxWrapper>
        <WalletBox
          field={Field.INPUT}
          isForConfirmation={isForConfirmation}
          walletLocalState={inputWalletState}
          setWalletLocalState={setInputWalletState}
        />
      </BoxWrapper>
      <Box className="mt-3">
        <BoxWrapper>
          <WalletBox
            field={Field.OUTPUT}
            isForConfirmation={isForConfirmation}
            walletLocalState={outputWalletState}
            setWalletLocalState={setOutputWalletState}
          />
        </BoxWrapper>
      </Box>
    </>
  )
}

export default SwapWalletModal
