import React from 'react'
import { useTranslation } from 'contexts/Localization'
import truncateHash from 'utils/truncateHash'
import { Text } from 'components/Text'

interface DescriptionWithTxProps {
  description?: string
  txHash?: string
}

const DescriptionWithTx: React.FC<DescriptionWithTxProps> = ({ txHash, children }) => {
  const { t } = useTranslation()

  return (
    <>
      {typeof children === 'string' ? <Text>{children}</Text> : children}
      {txHash && (
        <a href={txHash}>
          {t('View')}: {truncateHash(txHash, 8, 0)}
        </a>
      )}
    </>
  )
}

export default DescriptionWithTx
