import { useCallback } from 'react'
import axios from 'axios'
import * as Sentry from '@sentry/react'
import { ONE_INCH_API_URL } from 'config/constants/endpoints'
import { RouteOutput } from 'config/entities/routeOutput'
import { Fee } from 'config/enums'
import { Currency, CurrencyAmount } from 'config/entities'
import { IRoute, ROUTE_PROVIDER } from 'state/swap/types'

const use1inchQuote = () => {
  const handle1InchRoute = useCallback(
    async (inputCurrency: Currency, outputCurrency: Currency, amount: CurrencyAmount) => {
      try {
        const url = `${ONE_INCH_API_URL}${inputCurrency.chainId}/quote?fromTokenAddress=${
          inputCurrency.address
        }&toTokenAddress=${outputCurrency.address}&amount=${amount.toWei().toFixed(0)}&fee=${Fee.DEFAULT}`

        Sentry.addBreadcrumb({
          type: 'EVM_QUOTE_1inch',
          message: `Call 1inch for Quote`,
          data: {
            url,
          },
        })
        const { data } = await axios(url)
        const result: RouteOutput = data
        const route: IRoute = {
          inputCurrency: inputCurrency,
          outputCurrency: outputCurrency,
          name: ROUTE_PROVIDER.ONE_INCH,
          evmRouteName: ROUTE_PROVIDER.ONE_INCH,
          estimatedGas: result.estimatedGas,
          sourceInputAmount: amount,
          priceImpact: 0,
          destinationOutputAmount: new CurrencyAmount(result.toTokenAmount, outputCurrency.decimals),
        }
        return route
      } catch (error) {
        Sentry.captureException(error)
        throw error
      }
    },
    [],
  )
  return { handle1InchRoute }
}

export default use1inchQuote
