import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg
      viewBox="0 0 300 300"
      spin={true}
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      {...props}
    >
      <defs>
        <linearGradient
          id="eblEki8JHc36-fill"
          x1="0.154"
          y1="0.384"
          x2="0.735"
          y2="1.143"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="matrix(1 0 0 1 0 0)"
        >
          <stop id="eblEki8JHc36-fill-0" offset="0%" stopColor="#FE8947" />
          <stop id="eblEki8JHc36-fill-1" offset="2.5%" stopColor="#FE8947" />
          <stop id="eblEki8JHc36-fill-2" offset="25.4%" stopColor="blue" />
          <stop id="eblEki8JHc36-fill-3" offset="41.8%" stopColor="blue" />
          <stop id="eblEki8JHc36-fill-4" offset="50%" stopColor="rgb(108,57,162)" />
          <stop id="eblEki8JHc36-fill-5" offset="100%" stopColor="blue" />
        </linearGradient>
      </defs>
      <g id="eblEki8JHc32" transform="matrix(0.705774 0.004147 -0.004147 0.705772 -2126.391907 -184.420752)">
        <g id="eblEki8JHc33" transform="matrix(1 0 0 1 3078.09 287.44)" opacity="0">
          <rect id="eblEki8JHc34" width="300" height="300" rx="0" ry="0" fill="blue" stroke="none" strokeWidth="1" />
          <rect
            id="eblEki8JHc35"
            width="299"
            height="299"
            rx="0"
            ry="0"
            transform="matrix(1 0 0 1 0.5 0.5)"
            fill="none"
            stroke="blue"
            strokeWidth="1"
          />
        </g>
        <path
          id="eblEki8JHc36"
          d="M2869.634,502.4L2840.916,498.861L2780.5,354.028L2668.381,506.68L2786.246,301.72ZM2818.6,350.488L2887.326,515.843L2730.74,495.443L2712.8,519.293L2932.446,548.221ZM2695.082,527.546L2790.676,402.292L2779.041,374.423L2647.809,549.471L2871.382,549.471Z"
          transform="matrix(0.999983 0.005761 -0.005761 0.999983 437.969 -14.28)"
          fill="url(#eblEki8JHc36-fill)"
          stroke="none"
          strokeWidth="1"
        />
      </g>
    </Svg>
  )
}

export default Icon
