import JSBI from 'jsbi'
import { ReactNode } from 'react'
import { SupportedChainId } from './chains'
import { Currency, CurrencyAmount } from 'config/entities'

export type TranslatableText =
  | string
  | {
      key: string
      data?: {
        [key: string]: string | number
      }
    }
export interface Address {
  97?: string
  56: string
}

export interface SerializedToken {
  chainId: number
  address: string
  decimals: number
  symbol?: string
  name?: string
  projectLink?: string
}

export type Images = {
  lg: string
  md: string
  sm: string
  ipfs?: string
}

export type PageMeta = {
  title: string
  description?: string
  image?: string
}

export interface Language {
  code: string
  language: string
  locale: string
}

export enum ConnectorNames {
  Injected = 'injected',
}

export const connectorLocalStorageKey = 'connectorIdv2'
export const connectorFieldTypeLocalStorageKey = 'connectorFieldTypeIdv2'

// exports for external consumption
export type BigintIsh = JSBI | string | number

export enum TradeType {
  EXACT_INPUT,
  EXACT_OUTPUT,
}

export enum Rounding {
  ROUND_DOWN,
  ROUND_HALF_UP,
  ROUND_UP,
}

export const MaxUint256 = JSBI.BigInt('0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff')

export interface IBlockchainNetworks {
  name: string
  icon: ReactNode | undefined
  chainId: SupportedChainId
  eventKey: string
  minimumFee: CurrencyAmount
  decimals: number
}

export interface MinimumBalanceResponse {
  hasRequiredBalance: boolean
  network: IBlockchainNetworks
  message: string
  balance?: CurrencyAmount
}
