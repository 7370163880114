import { NETWORKS_INFO, SupportedChainId } from 'config/constants/chains'
import { IRoute, IStep, SWAP_ACTIONS, SwapInfo } from '../types'
import { Currency, CurrencyAmount, Percent } from 'config/entities'

/**
 *
 * @param inputChainId
 * @param outputChainId
 * @param route
 * @param inputCurrency
 * @param isAutomaticOnTargetChain
 */
export function computeSteps(
  inputChainId: SupportedChainId,
  outputChainId: SupportedChainId,
  route: IRoute,
  inputCurrency: Currency,
  isAutomaticOnTargetChain: boolean,
): IStep[] {
  const steps: IStep[] = []
  if (inputChainId === outputChainId && inputChainId === SupportedChainId.SOLANA) {
    // SOL to SOL
    steps.push({
      title: 'Swap action step',
      chainId: inputChainId,
      name: SWAP_ACTIONS.SWAP_SOLANA,
      payload: route,
      subTitle: 'Converting token',
    })
  } else if (inputChainId !== outputChainId && inputChainId === SupportedChainId.SOLANA) {
    // SOL  To EVM

    // Here we will not swap when selecting same currency
    if (inputCurrency.address !== route.inputLockCurrency.address) {
      steps.push({
        title: 'Swap action step',
        chainId: inputChainId,
        name: SWAP_ACTIONS.SWAP_SOLANA,
        payload: route,
        subTitle: 'Converting token',
      })
    }

    steps.push({
      title: 'Lock Token',
      chainId: inputChainId,
      name: SWAP_ACTIONS.LOCK_SOLANA,
      dependOnStep: SWAP_ACTIONS.SWAP_SOLANA,
      payload: route,
      subTitle: 'Sending Tokens To Bridge',
    })
    steps.push({
      title: 'Bridge',
      chainId: inputChainId,
      name: SWAP_ACTIONS.BRIDGE,
      dependOnStep: SWAP_ACTIONS.LOCK_SOLANA,
      payload: {
        totalConfirmations: NETWORKS_INFO[inputChainId].blockConfirmations,
        currentConfirmations: 0,
      },
      subTitle: 'Confirming Tokens Are Locked In The Bridge',
    })
    steps.push({
      title: 'Change network step',
      chainId: outputChainId,
      name: SWAP_ACTIONS.CHANGE_NETWORK,
      payload: {
        chainId: outputChainId,
      },
      subTitle: 'Switching the chain',
    })
    steps.push({
      title: 'Approve contract step',
      chainId: outputChainId,
      name: SWAP_ACTIONS.APPROVE_CONTRACT,
      payload: {
        inputCurrency: route.outputMintCurrency,
        amount: route.destinationInputAmount,
      },
      subTitle: 'Kindly approve',
    })
    steps.push({
      title: 'Swap action step',
      chainId: outputChainId,
      name: SWAP_ACTIONS.UNLOCK_EVM,
      dependOnStep: SWAP_ACTIONS.BRIDGE,
      payload: route,
      subTitle: 'Redeeming Tokens From Bridge',
    })
  } else if (inputChainId !== outputChainId && outputChainId === SupportedChainId.SOLANA) {
    // EVM to SOL

    steps.push({
      title: 'Create Associate step',
      chainId: outputChainId,
      name: SWAP_ACTIONS.CREATE_ASSOCIATE_ACCOUNT,
      payload: route,
      subTitle: 'Create Associate',
    })
    steps.push({
      title: 'Change network step',
      chainId: inputChainId,
      name: SWAP_ACTIONS.CHANGE_NETWORK,
      payload: {
        chainId: inputChainId,
      },
      subTitle: 'Switching the chain',
    })
    steps.push({
      title: 'Approve contract step',
      chainId: inputChainId,
      name: SWAP_ACTIONS.APPROVE_CONTRACT,
      payload: {
        inputCurrency: inputCurrency,
        amount: route.sourceInputAmount,
      },
      subTitle: 'Kindly approve',
    })
    if (inputCurrency.address !== route.inputLockCurrency.address) {
      steps.push({
        title: 'Approve contract step',
        chainId: inputChainId,
        name: SWAP_ACTIONS.APPROVE_CONTRACT,
        payload: {
          inputCurrency: route.inputLockCurrency,
          amount: route.sourceOutputAmount,
        },
        subTitle: 'Kindly approve',
      })
    }
    steps.push({
      title: 'Lock Token',
      chainId: inputChainId,
      name: SWAP_ACTIONS.LOCK_EVM,
      payload: route,
      subTitle: 'Sending Tokens To Bridge',
    })
    steps.push({
      title: 'Bridge',
      chainId: inputChainId,
      name: SWAP_ACTIONS.BRIDGE,
      dependOnStep: SWAP_ACTIONS.LOCK_EVM,
      payload: {
        totalConfirmations: NETWORKS_INFO[inputChainId].blockConfirmations,
        currentConfirmations: 0,
      },
      subTitle: 'Confirming Tokens Are Locked In The Bridge',
    })
    steps.push({
      title: 'Unlock Token',
      chainId: outputChainId,
      name: SWAP_ACTIONS.UNLOCK_SOLANA,
      dependOnStep: SWAP_ACTIONS.BRIDGE,
      payload: route,
      subTitle: 'Redeeming Tokens From Bridge',
    })
    steps.push({
      title: 'Swap Token',
      chainId: outputChainId,
      name: SWAP_ACTIONS.SWAP_UNLOCKED_SOLANA,
      dependOnStep: SWAP_ACTIONS.LOCK_EVM,
      payload: route,
      subTitle: 'Converting token',
    })
  } else if (inputChainId === outputChainId && inputChainId !== SupportedChainId.SOLANA) {
    // EVM to EVM SAME CHAIN
    steps.push({
      title: 'Change network step',
      chainId: inputChainId,
      name: SWAP_ACTIONS.CHANGE_NETWORK,
      payload: {
        chainId: inputChainId,
      },
      subTitle: 'Switching the chain',
    })
    steps.push({
      title: 'Approve contract step',
      chainId: inputChainId,
      name: SWAP_ACTIONS.APPROVE_CONTRACT,
      payload: {
        inputCurrency: inputCurrency,
        amount: route.sourceInputAmount,
      },
      subTitle: 'Kindly approve',
    })
    steps.push({
      title: 'Swap action step',
      chainId: inputChainId,
      name: SWAP_ACTIONS.SWAP,
      payload: route,
      subTitle: 'Converting token',
    })
  } else {
    // Here ELSE means EVM TO EVM CROSS CHAIN
    steps.push({
      title: 'Change network step',
      chainId: inputChainId,
      name: SWAP_ACTIONS.CHANGE_NETWORK,
      payload: {
        chainId: inputChainId,
      },
      subTitle: 'Switching the chain',
    })
    steps.push({
      title: 'Approve contract step',
      chainId: inputChainId,
      name: SWAP_ACTIONS.APPROVE_CONTRACT,
      payload: {
        inputCurrency: inputCurrency,
        amount: route.sourceInputAmount,
      },
      subTitle: 'Kindly approve',
    })
    if (inputCurrency.address !== route.inputLockCurrency.address) {
      steps.push({
        title: 'Approve contract step',
        chainId: inputChainId,
        name: SWAP_ACTIONS.APPROVE_CONTRACT,
        payload: {
          inputCurrency: route.inputLockCurrency,
          amount: route.sourceOutputAmount,
        },
        subTitle: 'Kindly approve',
      })
    }
    steps.push({
      title: 'Lock Token',
      chainId: inputChainId,
      name: SWAP_ACTIONS.LOCK_EVM,
      payload: route,
      subTitle: 'Sending Tokens To Bridge',
    })
    steps.push({
      title: 'Bridge',
      chainId: inputChainId,
      name: SWAP_ACTIONS.BRIDGE,
      dependOnStep: SWAP_ACTIONS.LOCK_EVM,
      payload: {
        totalConfirmations: NETWORKS_INFO[inputChainId].blockConfirmations,
        currentConfirmations: 0,
      },
      subTitle: 'Confirming Tokens Are Locked In The Bridge',
    })
    steps.push({
      title: 'Change network step',
      chainId: outputChainId,
      name: SWAP_ACTIONS.CHANGE_NETWORK,
      payload: {
        chainId: outputChainId,
      },
      subTitle: 'Switching the chain',
    })
    if (route.outputCurrency.address !== route.outputMintCurrency.address) {
      steps.push({
        title: 'Approve contract step',
        chainId: outputChainId,
        name: SWAP_ACTIONS.APPROVE_CONTRACT,
        payload: {
          inputCurrency: route.outputMintCurrency,
          amount: route.destinationInputAmount,
        },
        subTitle: 'Kindly approve',
      })
    }

    steps.push({
      title: 'Unlock Token',
      chainId: outputChainId,
      name: SWAP_ACTIONS.UNLOCK_EVM,
      dependOnStep: SWAP_ACTIONS.BRIDGE,
      payload: route,
      subTitle: 'Redeeming Tokens From Bridge',
    })
  }
  return computeAutomaticSteps(inputChainId, outputChainId, steps, isAutomaticOnTargetChain)
}

export function computeAutomaticSteps(
  inputChainId: SupportedChainId,
  outputChainId: SupportedChainId,
  backupSteps: IStep[],
  isAutomatic: boolean,
): IStep[] {
  let steps: IStep[] = []
  if (!isAutomatic) {
    steps = backupSteps
  } else {
    for (const backupStep of backupSteps) {
      if (backupStep.name === SWAP_ACTIONS.BRIDGE) {
        break
      } else {
        steps.push(backupStep)
      }
    }
    //todo change title, subtitle when ui is finalized
    steps.push({
      name: SWAP_ACTIONS.RELAYER,
      title: 'Relayer',
      chainId: outputChainId,
      payload: {
        inputChainId,
        outputChainId,
      },
      subTitle: 'Waiting for transaction',
    })
  }
  return steps
}

export function computeSwapInfo(steps: IStep[], route: IRoute, slippage: Percent, isAutomaticOnTargetChain): SwapInfo {
  const fromRate = route.destinationOutputAmount.toEther().dividedBy(route.sourceInputAmount.toEther())
  const toRate = route.sourceInputAmount.toEther().dividedBy(route.destinationOutputAmount.toEther())
  const routeImages = []
  const routeInfo = []

  const onlySwapSolana = steps.findIndex((step) => step.name === SWAP_ACTIONS.LOCK_SOLANA) === -1
  for (const step of steps) {
    if (step.name === SWAP_ACTIONS.SWAP) {
      routeImages.push(step.payload.inputCurrency.logoURI)
      routeImages.push(step.payload.outputCurrency.logoURI)
      routeInfo.push(step.payload.inputCurrency.symbol)
      routeInfo.push(step.payload.outputCurrency.symbol)
    } else if (step.name === SWAP_ACTIONS.SWAP_SOLANA && onlySwapSolana) {
      if (step.payload.inputLockCurrency) {
        routeImages.push(step.payload.inputCurrency.logoURI)
        routeImages.push(step.payload.inputLockCurrency.logoURI)
        routeInfo.push(step.payload.inputCurrency.symbol)
        routeInfo.push(step.payload.inputLockCurrency.symbol)
      } else {
        routeImages.push(step.payload.inputCurrency.logoURI)
        routeImages.push(step.payload.outputCurrency.logoURI)
        routeInfo.push(step.payload.inputCurrency.symbol)
        routeInfo.push(step.payload.outputCurrency.symbol)
      }
    } else if (step.name === SWAP_ACTIONS.BRIDGE) {
      routeImages.push('Bridge')
      routeInfo.push('Bridge')
    } else if (step.name === SWAP_ACTIONS.LOCK_EVM) {
      const payload = step.payload
      if (payload.inputCurrency.address.toLowerCase() === payload?.inputLockCurrency?.address.toLowerCase()) {
        routeImages.push(payload.inputCurrency.logoURI)
        routeInfo.push(payload.inputCurrency.symbol)
      } else {
        if (payload.inputLockCurrency) {
          routeImages.push(payload.inputCurrency.logoURI)
          routeImages.push(payload.inputLockCurrency.logoURI)
          routeInfo.push(payload.inputCurrency.symbol)
          routeInfo.push(payload.inputLockCurrency.symbol)
        } else {
          routeImages.push(payload.inputCurrency.logoURI)
          routeInfo.push(payload.inputCurrency.symbol)
        }
      }
    } else if (step.name === SWAP_ACTIONS.LOCK_SOLANA) {
      const payload = step.payload
      if (payload.inputCurrency.address.toLowerCase() === payload?.inputLockCurrency?.address.toLowerCase()) {
        routeImages.push(payload.inputCurrency.logoURI)
        routeInfo.push(payload.inputCurrency.symbol)
      } else {
        if (payload.inputLockCurrency) {
          routeImages.push(payload.inputCurrency.logoURI)
          routeImages.push(payload.inputLockCurrency.logoURI)
          routeInfo.push(payload.inputCurrency.symbol)
          routeInfo.push(payload.inputLockCurrency.symbol)
        } else {
          routeImages.push(payload.inputCurrency.logoURI)
          routeInfo.push(payload.inputCurrency.symbol)
        }
      }
    } else if (step.name === SWAP_ACTIONS.UNLOCK_EVM) {
      const payload = step.payload
      if (payload.outputCurrency.address.toLowerCase() === payload?.outputMintCurrency?.address.toLowerCase()) {
        routeImages.push(payload.outputCurrency.logoURI)
        routeInfo.push(payload.outputCurrency.symbol)
      } else {
        if (payload.outputMintCurrency) {
          routeImages.push(payload.outputMintCurrency.logoURI)
          routeImages.push(payload.outputCurrency.logoURI)
          routeInfo.push(payload.outputMintCurrency.symbol)
          routeInfo.push(payload.outputCurrency.symbol)
        } else {
          routeImages.push(payload.outputCurrency.logoURI)
          routeInfo.push(payload.outputCurrency.symbol)
        }
      }
    } else if (step.name === SWAP_ACTIONS.UNLOCK_SOLANA) {
      const payload = step.payload
      if (payload.outputCurrency.address.toLowerCase() === payload?.outputMintCurrency?.address.toLowerCase()) {
        routeImages.push(payload.outputCurrency.logoURI)
        routeInfo.push(payload.outputCurrency.symbol)
      } else {
        if (payload.outputMintCurrency) {
          routeImages.push(payload.outputMintCurrency.logoURI)
          routeImages.push(payload.outputCurrency.logoURI)
          routeInfo.push(payload.outputMintCurrency.symbol)
          routeInfo.push(payload.outputCurrency.symbol)
        } else {
          routeImages.push(payload.outputCurrency.logoURI)
          routeInfo.push(payload.outputCurrency.symbol)
        }
      }
    }
  }
  if (isAutomaticOnTargetChain) {
    routeImages.push('Bridge')
    routeImages.push(route.outputCurrency.logoURI)
  }
  const percent = slippage.divide(100).toFixed(4)
  const slippageAmount = route.destinationOutputAmount.toEther().multipliedBy(percent)
  const minimumReceived = route.destinationOutputAmount.toEther().minus(slippageAmount)
  const isCrossChain = route.inputCurrency.chainId !== route.outputCurrency.chainId
  const estimatedTime = isCrossChain
    ? route.inputCurrency.chainId === SupportedChainId.POLYGON ||
      route.outputCurrency.chainId === SupportedChainId.POLYGON
      ? '20 min'
      : '2 min'
    : '1 min'
  return {
    fromSymbol: route.inputCurrency.symbol,
    toSymbol: route.outputCurrency.symbol,
    fromRate: `${fromRate.toFixed(5)} ${route.outputCurrency.symbol}`,
    toRate: `${toRate.toFixed(5)} ${route.inputCurrency.symbol}`,
    route: routeImages,
    priceImpact: route.priceImpact,
    minimumReceived: new CurrencyAmount(minimumReceived),
    estimatedTime: estimatedTime,
    routeName: route.name,
    routeInfo: routeInfo.join(' -> '),
  }
}
