import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 21.5 21.5" {...props}>
      <g id="info" transform="translate(20.75 20.75) rotate(180)">
        <circle
          id="Ellipse_1"
          data-name="Ellipse 1"
          cx="10"
          cy="10"
          r="10"
          fill="none"
          stroke="#14f195"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="Path_61"
          data-name="Path 61"
          d="M0,0"
          transform="translate(10 10)"
          fill="none"
          stroke="#14f195"
          strokeLinecap="round"
          strokeWidth="1.5"
        />
        <g id="check" transform="translate(15 13) rotate(180)">
          <path
            id="check-2"
            data-name="check"
            d="M14,6,7.125,13,4,9.818"
            transform="translate(-4 -6)"
            fill="none"
            stroke="#14f195"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
        </g>
      </g>
    </Svg>
  )
}

export default Icon
