import { createAction } from '@reduxjs/toolkit'
import { ChainDetailsMap, TokenInfoWithChainDetails } from 'config/allbridge/tokens-info'
import { SupportedChainId } from 'config/constants/chains'
import { CurrencyAmount } from 'config/entities'
import { IActionStepState, IStep, SWAP_STATES } from 'state/swap/types'
import { Field } from 'state/types'

// this first action is a bit of a special case, as it's used to set the initial state by syncing from server.
export const setStableSwapChains = createAction<{ supportedChains: ChainDetailsMap }>('stableSwap/setStableSwapChains')

export const setStableFee = createAction<{ fee: CurrencyAmount }>('stableSwap/setStableFee')
export const setStableReceivedAmount = createAction<{ receivedAmount: CurrencyAmount }>(
  'stableSwap/setStableReceivedAmount',
)

// Comparing with Swap
export const selectChain = createAction<{ field: Field; chainId: SupportedChainId }>('stableSwap/selectChain')
export const selectCurrency = createAction<{ field: Field; currency: TokenInfoWithChainDetails }>(
  'stableSwap/selectCurrency',
)
export const switchCurrencies = createAction<void>('stableSwap/switchCurrencies')
export const updateStableSwapPageState = createAction<{ swapPageState: SWAP_STATES }>(
  'stableSwap/updateStableSwapPageState',
)
export const resetSwapState = createAction<void>('stableSwap/resetSwapState')
export const setStableInputAmount = createAction<{ inputAmount: CurrencyAmount }>('stableSwap/setStableInputAmount')
export const setSteps = createAction<{ steps: IStep[] }>('stableSwap/setSteps')
export const setActiveStepIndex = createAction<{ resultActionStepState: IActionStepState }>(
  'stableSwap/setActiveStepIndex',
)
export const setActionState = createAction<{ actionStepState: IActionStepState }>('stableSwap/setActionStepState')
export const setStepResult = createAction<{ actionStepState: IActionStepState }>('stableSwap/setStepResult')
export const setChainAndToken = createAction<{
  fromChain: number
  toChain: number
  fromCurrency: TokenInfoWithChainDetails
  toCurrency: TokenInfoWithChainDetails
  fromCurrencies: TokenInfoWithChainDetails[]
  toCurrencies: TokenInfoWithChainDetails[]
}>('stableSwap/setChainAndToken')

// this is transaction id from db we get after creating a swap
export const updateTransactionId = createAction<{ transactionId: string }>('stableSwap/updateTransactionId')
