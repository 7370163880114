import { TokenInfoWithChainDetails } from 'config/allbridge'
import { SupportedChainId } from 'config/constants/chains'
import { CurrencyAmount } from 'config/entities'
import { IStep, SWAP_ACTIONS } from 'state/swap/types'

/**
 * @param inputChainId
 * @param inputCurrency
 * @param outputCurrency
 * @param inputAmount
 */
export function computeStableSwapSteps(
  inputChainId: SupportedChainId,
  outputChainId: SupportedChainId,
  inputCurrency: TokenInfoWithChainDetails,
  outputCurrency: TokenInfoWithChainDetails,
  inputAmount: CurrencyAmount,
): IStep[] {
  const steps: IStep[] = []
  if (inputChainId !== SupportedChainId.SOLANA) {
    if (inputChainId !== SupportedChainId.TRON) {
      steps.push({
        title: 'Change network step',
        chainId: inputChainId,
        name: SWAP_ACTIONS.CHANGE_NETWORK,
        payload: {
          chainId: inputChainId,
        },
        subTitle: 'Switching the chain',
      })
    }
    // approve our poc contract to spend
    steps.push({
      title: 'Approve contract step',
      chainId: inputChainId,
      name: SWAP_ACTIONS.APPROVE_CONTRACT,
      payload: {
        inputCurrency: inputCurrency,
        inputChainId,
        amount: inputAmount,
      },
      subTitle: 'Kindly approve',
    })
  }
  steps.push({
    title: 'Swap action step',
    chainId: inputChainId,
    name: SWAP_ACTIONS.SWAP,
    payload: {
      inputCurrency: inputCurrency,
      outputCurrency: outputCurrency,
      amount: inputAmount,
      outputChainId,
    },
    subTitle: 'Swaping token',
  })

  steps.push({
    title: 'Bridge',
    chainId: inputChainId,
    name: SWAP_ACTIONS.BRIDGE,
    dependOnStep: SWAP_ACTIONS.LOCK_EVM,
    payload: {
      emitterAddress: inputCurrency?.bridgeAddress,
      totalConfirmations: inputCurrency?.confirmations,
      currentConfirmations: 0,
    },
    subTitle: 'Confirming Tokens Are Locked In The Bridge',
  })

  return steps
}
