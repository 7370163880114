import axios from 'axios'
import { useChainInfo, useCurrency, useInputAmount, useReceivedAmount, useSwapFee } from 'state/stableSwap/selectors'
import { Field } from 'state/types'
import { useUserSlippageTolerance } from 'state/user/hooks'
import { RELAYER_BACKEND_BASE_URL } from 'config/constants/endpoints'
import { useCallback } from 'react'
import { TransactionStatus } from 'config/enums/transactionStatus'
import { useWalletActionHandlers } from 'state/wallets/hooks'

const useSwapSummary = () => {
  const { getConnectedWalletAddress } = useWalletActionHandlers()

  const inputCurrency = useCurrency(Field.INPUT),
    outputCurrency = useCurrency(Field.OUTPUT),
    inputChainInfo = useChainInfo(Field.INPUT),
    outputChainInfo = useChainInfo(Field.OUTPUT),
    receivedAmount = useReceivedAmount(),
    [slippage] = useUserSlippageTolerance(),
    inputAmount = useInputAmount(),
    swapFee = useSwapFee(),
    fromAddress = getConnectedWalletAddress(Field.INPUT),
    toAddress = getConnectedWalletAddress(Field.OUTPUT)
  return {
    fromToken: inputCurrency.tokenAddress,
    fromChainId: `${+inputChainInfo}`,
    fromSideTxHash: '',
    fromAmount: Number(inputAmount.toEther()),
    toToken: outputCurrency.tokenAddress,
    toChainId: `${+outputChainInfo}`,
    toSideTxHash: '',
    toAmount: receivedAmount.toEther().toNumber(),
    slippage: Number(slippage.toFixed()),
    vaa: `NO_VAA_${Date.now().toString()}`,
    payload: '',
    type: 'stable',
    status: TransactionStatus.INITIATED,
    relayerFee: swapFee.toEther().toNumber(),
    relayerFeeDollarValue: 0,
    fromAddress,
    toAddress,
  }
}

export const usePostTransaction = () => {
  const data = useSwapSummary()

  const postStableAllBridgeTransaction = useCallback(() => {
    return axios.post(`${RELAYER_BACKEND_BASE_URL}/transaction/add`, {
      ...data,
      isWithRelayer: true,
      captcha: 'local',
    })
  }, [])

  /* all params are optional
  vaa
  fromSideTxHash
  toSideTxHash
  payload
  status
 */
  const updateStableTransaction = useCallback(
    (
      _id: string,
      status?: TransactionStatus,
      fromSideTxHash?: string,
      toSideTxHash?: string,
      vaa?: string,
      payload?: string,
    ) => {
      const params = {
        status: status,
        fromSideTxHash: fromSideTxHash,
        toSideTxHash: toSideTxHash,
        vaa: vaa,
        payload: payload,
      }
      axios.post(`${RELAYER_BACKEND_BASE_URL}/transaction/update`, {
        _id,
        ...params,
      })
    },
    [],
  )

  return { postStableAllBridgeTransaction, updateStableTransaction }
}
