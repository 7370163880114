import { useCallback } from 'react'
import erc20Abi from 'config/abi/erc20.json'
import { multicallv2 } from 'utils/multicall'
import { SupportedChainId, SupportedEVMChainId } from 'config/constants/chains'
import { ethers } from 'ethers'
import { getEVMNodeURL } from 'utils/getRpcUrl'
import * as Sentry from '@sentry/react'

import {
  AvalancheTokens,
  BSCTokens,
  EthereumTokens,
  FantomTokens,
  PolygonTokens,
  SolanaTokens,
} from 'config/tokenLists'
import { TronTokens } from 'config/tokenLists/tron'
import axios from 'axios'

const useTokenInfo = () => {
  function prepareMultiCall(address) {
    return [
      {
        address: address,
        name: 'name',
        params: [],
      },
      {
        address: address,
        name: 'decimals',
        params: [],
      },
      {
        address: address,
        name: 'symbol',
        params: [],
      },
    ]
  }

  async function fetchEVMTokenInfo(chainId, address) {
    const balanceCalls = prepareMultiCall(address)
    try {
      const providerURL = getEVMNodeURL[chainId]
      const provider = new ethers.providers.JsonRpcProvider(providerURL)
      const rawResult = await multicallv2(erc20Abi, balanceCalls, chainId, provider)
      return {
        success: true,
        name: rawResult[0][0],
        decimal: Number(rawResult[1][0]),
        symbol: rawResult[2][0],
        logoURI: null,
      }
    } catch (error) {
      Sentry.captureException(error)
    }
    return { success: false, name: '', decimal: 0, symbol: '', logoURI: null }
  }

  async function fetchSolanaTokenInfo(chainId, address) {
    try {
      const {
        data: { tokenInfo },
      } = await axios(`/api/tokens/info?address=${address}`)
      return tokenInfo
    } catch (error) {
      Sentry.captureException(error)
    }
    return { success: false, name: '', decimal: 0, symbol: '', logoURI: null }
  }

  const fetchTokenInfo = useCallback(async (chainId: SupportedChainId, tokenAddress: string) => {
    if (SupportedEVMChainId(chainId)) {
      if (ethers.utils.isAddress(tokenAddress)) {
        return await fetchEVMTokenInfo(chainId, tokenAddress)
      }
    } else {
      return await fetchSolanaTokenInfo(chainId, tokenAddress)
    }
    return { success: false, name: '', decimal: 0, symbol: '', logoURI: null }
  }, [])

  const getTokenInfo = useCallback((chainId: SupportedChainId, tokenAddress: string) => {
    let currencies = []
    if (chainId === SupportedChainId.BINANCE) {
      currencies = BSCTokens
    } else if (chainId === SupportedChainId.ETHEREUM) {
      currencies = EthereumTokens
    } else if (chainId === SupportedChainId.POLYGON) {
      currencies = PolygonTokens
    } else if (chainId === SupportedChainId.AVAX) {
      currencies = AvalancheTokens
    } else if (chainId === SupportedChainId.FANTOM) {
      currencies = FantomTokens
    } else if (chainId === SupportedChainId.SOLANA) {
      currencies = SolanaTokens
    } else if (chainId === SupportedChainId.TRON) {
      currencies = TronTokens
    }
    return currencies.find((currency) => {
      return currency.address.toLowerCase() === tokenAddress.toLowerCase()
    })
  }, [])

  return { fetchTokenInfo, getTokenInfo }
}

export default useTokenInfo
