import { Flex } from 'components/Box'
import { AnimatedArrows, ArrowIcon, FlexIconWrapper, TextBox } from '../styles'
import { useActiveStepState, useSteps } from 'state/swap/selectors'
import { ACTION_STEP_STATE, SWAP_ACTIONS } from 'state/swap/types'
import { getIconByUrl } from './SwapSuccessCard'

const SwapTokenCard = ({ step }) => {
  const { activeStepState } = useActiveStepState()
  const { steps } = useSteps()
  const onlySwapSolana = steps.findIndex((step) => step.name === SWAP_ACTIONS.LOCK_SOLANA) === -1

  return (
    <Flex width={'100%'} justifyContent={'space-between'}>
      {step.name === SWAP_ACTIONS.SWAP_UNLOCKED_SOLANA ? (
        <FlexIconWrapper>
          {getIconByUrl(step.payload.outputMintCurrency.logoURI)}
          <TextBox>{step.payload.outputMintCurrency.symbol}</TextBox>
        </FlexIconWrapper>
      ) : (
        <FlexIconWrapper>
          {getIconByUrl(step.payload.inputCurrency.logoURI)}
          <TextBox>{step.payload.inputCurrency.symbol}</TextBox>
        </FlexIconWrapper>
      )}
      <Flex width={'100%'} alignItems={'center'} justifyContent={'center'} flex={1}>
        {activeStepState.status === ACTION_STEP_STATE.OK || activeStepState.status === ACTION_STEP_STATE.ERROR ? (
          <ArrowIcon />
        ) : (
          <AnimatedArrows>
            <span className="arrow one next bounceAlpha" />
            <span className="arrow two next bounceAlpha" />
            <span className="arrow three next bounceAlpha" />
          </AnimatedArrows>
        )}
      </Flex>
      {(step.name === SWAP_ACTIONS.SWAP_SOLANA && onlySwapSolana) ||
      step.name === SWAP_ACTIONS.SWAP ||
      step.name === SWAP_ACTIONS.SWAP_UNLOCKED_SOLANA ? (
        <FlexIconWrapper>
          {getIconByUrl(step.payload.outputCurrency.logoURI)}
          <TextBox>{step.payload.outputCurrency.symbol}</TextBox>
        </FlexIconWrapper>
      ) : (
        <FlexIconWrapper>
          {getIconByUrl(step.payload.inputLockCurrency.logoURI)}
          <TextBox>{step.payload.inputLockCurrency.symbol}</TextBox>
        </FlexIconWrapper>
      )}
    </Flex>
  )
}

export default SwapTokenCard
