import { SupportedChainId } from 'config/constants/chains'
import { Currency } from 'config/entities'
export const EthereumTokens: Currency[] = [
  {
    symbol: 'ETH',
    name: 'Ethereum',
    decimals: 18,
    address: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
    logoURI: 'https://tokens.1inch.io/0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    chainId: SupportedChainId.ETHEREUM,
    symbol: 'WETH',
    name: 'Wrapped Ether',
    address: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
    decimals: 18,
    logoURI:
      'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png',
  },
  {
    address: '0xa693B19d2931d498c5B318dF961919BB4aee87a5',
    chainId: SupportedChainId.ETHEREUM,
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9vMJfxuKxXBoEa7rM12mYLMwTacLMLDJqHozw96WQL8i/logo.png',
    name: 'UST (Wormhole)',
    symbol: 'UST',
  },
  {
    address: '0x7B4B0B9b024109D182dCF3831222fbdA81369423',
    chainId: SupportedChainId.ETHEREUM,
    decimals: 18,
    logoURI: 'https://raw.githubusercontent.com/certusone/wormhole-token-list/main/assets/BUSDbs_wh.png',
    name: 'Binance USD (Wormhole from BSC)',
    symbol: 'BUSDbs',
  },
  {
    address: '0x566957eF80F9fd5526CD2BEF8BE67035C0b81130',
    chainId: SupportedChainId.ETHEREUM,
    decimals: 6,
    logoURI: 'https://raw.githubusercontent.com/certusone/wormhole-token-list/main/assets/USDCpo_wh.png',
    name: 'USD Coin (PoS) (Wormhole from Polygon)',
    symbol: 'USDCpo',
  },
  {
    address: '0x85f138bfee4ef8e540890cfb48f620571d67eda3',
    chainId: SupportedChainId.ETHEREUM,
    decimals: 18,
    logoURI: 'https://static.icodrops.com/images/avalanche-wormhole.png',
    name: 'Wrapped AVAX(Wormhole)',
    symbol: 'WAVAX',
  },
  {
    address: '0x41f7B8b9b897276b7AAE926a9016935280b44E97',
    chainId: SupportedChainId.ETHEREUM,
    decimals: 6,
    logoURI: 'https://raw.githubusercontent.com/certusone/wormhole-token-list/main/assets/USDCso_wh.png',
    name: 'USD Coin (Wormhole from Solana)',
    symbol: 'USDCso',
  },
  {
    symbol: 'BTC++',
    name: 'PieDAO BTC++',
    decimals: 18,
    address: '0x0327112423f3a68efdf1fcf402f6c5cb9f7c33fd',
    logoURI: 'https://tokens.1inch.io/0x0327112423f3a68efdf1fcf402f6c5cb9f7c33fd.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'UMA',
    name: 'UMA Voting Token v1',
    decimals: 18,
    address: '0x04fa0d235c4abf4bcf4787af4cf447de572ef828',
    logoURI: 'https://tokens.1inch.io/0x04fa0d235c4abf4bcf4787af4cf447de572ef828.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'MATH',
    name: 'MATH Token',
    address: '0x08d967bb0134f2d07f7cfb6e246680c53927dd30',
    decimals: 18,
    logoURI: 'https://tokens.1inch.io/0x08d967bb0134f2d07f7cfb6e246680c53927dd30.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'STAKE',
    name: 'STAKE',
    address: '0x0ae055097c6d159879521c384f1d2123d1f195e6',
    decimals: 18,
    logoURI: 'https://tokens.1inch.io/0x0ae055097c6d159879521c384f1d2123d1f195e6.png',

    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'TRB',
    name: 'Tellor Tributes',
    address: '0x88df592f8eb5d7bd38bfef7deb0fbc02cf3778a0',
    decimals: 18,
    logoURI: 'https://tokens.1inch.io/0x0ba45a8b5d5575935b8158a88c631e9f9c95a2e5.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'YFI',
    name: 'yearn.finance',
    decimals: 18,
    address: '0x0bc529c00c6401aef6d220be8c6ea1667f6ad93e',
    logoURI: 'https://tokens.1inch.io/0x0bc529c00c6401aef6d220be8c6ea1667f6ad93e.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'wNXM',
    name: 'Wrapped NXM',
    decimals: 18,
    address: '0x0d438f3b5175bebc262bf23753c1e53d03432bde',
    logoURI: 'https://tokens.1inch.io/0x0d438f3b5175bebc262bf23753c1e53d03432bde.png',

    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'BAT',
    name: 'Basic Attention Token',
    address: '0x0d8775f648430679a709e98d2b0cb6250d2887ef',
    decimals: 18,
    logoURI: 'https://tokens.1inch.io/0x0d8775f648430679a709e98d2b0cb6250d2887ef.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'ABYSS',
    name: 'Abyss',
    decimals: 18,
    address: '0x0e8d6b471e332f140e7d9dbb99e5e3822f728da6',
    logoURI: 'https://tokens.1inch.io/0x0e8d6b471e332f140e7d9dbb99e5e3822f728da6.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'MANA',
    name: 'Mana',
    address: '0x0f5d2fb29fb7d3cfee444a200298f468908cc942',
    decimals: 18,
    logoURI: 'https://tokens.1inch.io/0x0f5d2fb29fb7d3cfee444a200298f468908cc942.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'XIO',
    name: 'XIO Network',
    address: '0x0f7f961648ae6db43c75663ac7e5414eb79b5704',
    decimals: 18,
    logoURI: 'https://tokens.1inch.io/0x0f7f961648ae6db43c75663ac7e5414eb79b5704.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'NMR',
    name: 'Numeraire',
    address: '0x1776e1f26f98b1a5df9cd347953a26dd3cb46671',
    decimals: 18,
    logoURI: 'https://tokens.1inch.io/0x1776e1f26f98b1a5df9cd347953a26dd3cb46671.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'EWTB',
    name: 'Energy Web Token Bridged',
    address: '0x178c820f862b14f316509ec36b13123da19a6054',
    decimals: 18,
    logoURI: 'https://tokens.1inch.io/0x178c820f862b14f316509ec36b13123da19a6054.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'OWL',
    name: 'OWL Token',
    address: '0x1a5f9352af8af974bfc03399e3767df6370d82e4',
    decimals: 18,
    logoURI: 'https://tokens.1inch.io/0x1a5f9352af8af974bfc03399e3767df6370d82e4.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'PAR',
    name: 'Parachute',
    address: '0x1beef31946fbbb40b877a72e4ae04a8d1a5cee06',
    decimals: 18,
    logoURI: 'https://tokens.1inch.io/0x1beef31946fbbb40b877a72e4ae04a8d1a5cee06.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'RSV',
    name: 'Reserve',
    address: '0x196f4727526ea7fb1e17b2071b3d8eaa38486988',
    decimals: 18,
    logoURI: 'https://tokens.1inch.io/0x196f4727526ea7fb1e17b2071b3d8eaa38486988.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'BNT',
    name: 'Bancor',
    address: '0x1f573d6fb3f13d689ff844b4ce37794d79a7ff1c',
    decimals: 18,
    logoURI: 'https://tokens.1inch.io/0x1f573d6fb3f13d689ff844b4ce37794d79a7ff1c.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'REPv2',
    name: 'Reputation V2',
    decimals: 18,
    address: '0x221657776846890989a759ba2973e427dff5c9bb',
    logoURI: 'https://tokens.1inch.io/0x221657776846890989a759ba2973e427dff5c9bb.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'WBTC',
    name: 'Wrapped BTC',
    address: '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
    decimals: 8,
    logoURI: 'https://tokens.1inch.io/0x2260fac5e5542a773aa44fbcfedf7c193bc2c599.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'AST',
    name: 'AirSwap',
    decimals: 4,
    address: '0x27054b13b1b798b345b591a4d22e6562d47ea75a',
    logoURI: 'https://tokens.1inch.io/0x27054b13b1b798b345b591a4d22e6562d47ea75a.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'YFL',
    name: 'YFLink',
    address: '0x28cb7e841ee97947a86b06fa4090c8451f64c0be',
    decimals: 18,
    logoURI: 'https://tokens.1inch.io/0x28cb7e841ee97947a86b06fa4090c8451f64c0be.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'XBP',
    name: 'BlitzPredict',
    address: '0x28dee01d53fed0edf5f6e310bf8ef9311513ae40',
    decimals: 18,
    logoURI: 'https://tokens.1inch.io/0x28dee01d53fed0edf5f6e310bf8ef9311513ae40.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'HEX',
    name: 'HEX',
    address: '0x2b591e99afe9f32eaa6214f7b7629768c40eeb39',
    decimals: 8,
    logoURI: 'https://tokens.1inch.io/0x2b591e99afe9f32eaa6214f7b7629768c40eeb39.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'CREAM',
    name: 'Cream',
    address: '0x2ba592f78db6436527729929aaf6c908497cb200',
    decimals: 18,
    logoURI: 'https://tokens.1inch.io/0x2ba592f78db6436527729929aaf6c908497cb200.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'DEC',
    name: 'Decentr',
    address: '0x30f271c9e86d2b7d00a6376cd96a1cfbd5f0b9b3',
    decimals: 18,
    logoURI: 'https://tokens.1inch.io/0x30f271c9e86d2b7d00a6376cd96a1cfbd5f0b9b3.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'VEE',
    name: 'BLOCKv',
    address: '0x340d2bde5eb28c1eed91b2f790723e3b160613b7',
    decimals: 18,
    logoURI: 'https://tokens.1inch.io/0x340d2bde5eb28c1eed91b2f790723e3b160613b7.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'PERX',
    name: 'PeerEx Network',
    address: '0x3c6ff50c9ec362efa359317009428d52115fe643',
    decimals: 18,
    logoURI: 'https://tokens.1inch.io/0x3c6ff50c9ec362efa359317009428d52115fe643.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'REN',
    name: 'Republic',
    address: '0x408e41876cccdc0f92210600ef50372656052a38',
    decimals: 18,
    logoURI: 'https://tokens.1inch.io/0x408e41876cccdc0f92210600ef50372656052a38.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'XOR',
    name: 'Sora Token',
    address: '0x40fd72257597aa14c7231a7b1aaa29fce868f677',
    decimals: 18,
    logoURI: 'https://tokens.1inch.io/0x40fd72257597aa14c7231a7b1aaa29fce868f677.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'FUN',
    name: 'FunFair',
    address: '0x419d0d8bdd9af5e606ae2232ed285aff190e711b',
    decimals: 8,
    logoURI: 'https://tokens.1inch.io/0x419d0d8bdd9af5e606ae2232ed285aff190e711b.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'CVC',
    name: 'Civic',
    address: '0x41e5560054824ea6b0732e656e3ad64e20e94e45',
    decimals: 8,
    logoURI: 'https://tokens.1inch.io/0x41e5560054824ea6b0732e656e3ad64e20e94e45.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'CAP',
    name: 'Cap',
    address: '0x43044f861ec040db59a7e324c40507addb673142',
    decimals: 18,
    logoURI: 'https://tokens.1inch.io/0x43044f861ec040db59a7e324c40507addb673142.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'VIDT',
    name: 'VIDT Datalink',
    address: '0xfef4185594457050cc9c23980d301908fe057bb1',
    decimals: 18,
    logoURI: 'https://tokens.1inch.io/0xfef4185594457050cc9c23980d301908fe057bb1.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'FOAM',
    name: 'FOAM Token',
    address: '0x4946fcea7c692606e8908002e55a582af44ac121',
    decimals: 18,
    logoURI: 'https://tokens.1inch.io/0x4946fcea7c692606e8908002e55a582af44ac121.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'QNT',
    name: 'Quant',
    address: '0x4a220e6096b25eadb88358cb44068a3248254675',
    decimals: 18,
    logoURI: 'https://tokens.1inch.io/0x4a220e6096b25eadb88358cb44068a3248254675.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'aTUSDv1',
    name: 'Aave Interest bearing TUSD',
    decimals: 18,
    address: '0x4da9b813057d04baef4e5800e36083717b4a0341',
    logoURI: 'https://tokens.1inch.io/0x4da9b813057d04baef4e5800e36083717b4a0341.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'MCB',
    name: 'MCDEX Token',
    address: '0x4e352cf164e64adcbad318c3a1e222e9eba4ce42',
    decimals: 18,
    logoURI: 'https://tokens.1inch.io/0x4e352cf164e64adcbad318c3a1e222e9eba4ce42.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'BUSD',
    name: 'Binance USD',
    decimals: 18,
    address: '0x4fabb145d64652a948d72533023f6e7a623c7c53',
    logoURI: 'https://tokens.1inch.io/0x4fabb145d64652a948d72533023f6e7a623c7c53.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'LINK',
    name: 'Chain Link',
    address: '0x514910771af9ca656af840dff83e8264ecf986ca',
    decimals: 18,
    logoURI: 'https://tokens.1inch.io/0x514910771af9ca656af840dff83e8264ecf986ca.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'GEN',
    name: 'DAOStack',
    address: '0x543ff227f64aa17ea132bf9886cab5db55dcaddf',
    decimals: 18,
    logoURI: 'https://tokens.1inch.io/0x543ff227f64aa17ea132bf9886cab5db55dcaddf.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'BZRX',
    name: 'bZx Protocol Token',
    decimals: 18,
    address: '0x56d811088235f11c8920698a204a5010a788f4b3',
    logoURI: 'https://tokens.1inch.io/0x56d811088235f11c8920698a204a5010a788f4b3.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'BLZ',
    name: 'Bluzelle',
    address: '0x5732046a883704404f284ce41ffadd5b007fd668',
    decimals: 18,
    logoURI: 'https://tokens.1inch.io/0x5732046a883704404f284ce41ffadd5b007fd668.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'TRUST',
    name: 'TRUST DAO',
    address: '0x57700244b20f84799a31c6c96dadff373ca9d6c5',
    decimals: 18,
    logoURI: 'https://tokens.1inch.io/0x57700244b20f84799a31c6c96dadff373ca9d6c5.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'LPT',
    name: 'Livepeer Token',
    decimals: 18,
    address: '0x58b6a8a3302369daec383334672404ee733ab239',
    logoURI: 'https://tokens.1inch.io/0x58b6a8a3302369daec383334672404ee733ab239.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'POWR',
    name: 'Power Ledger',
    address: '0x595832f8fc6bf59c85c527fec3740a1b7a361269',
    decimals: 6,
    logoURI: 'https://tokens.1inch.io/0x595832f8fc6bf59c85c527fec3740a1b7a361269.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'DTH',
    name: 'Dether',
    address: '0x5adc961d6ac3f7062d2ea45fefb8d8167d44b190',
    decimals: 18,
    logoURI: 'https://tokens.1inch.io/0x5adc961d6ac3f7062d2ea45fefb8d8167d44b190.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'COT',
    name: 'CoTrader',
    address: '0x5c872500c00565505f3624ab435c222e558e9ff8',
    decimals: 18,
    logoURI: 'https://tokens.1inch.io/0x5c872500c00565505f3624ab435c222e558e9ff8.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'DEV',
    name: 'Dev',
    address: '0x5caf454ba92e6f2c929df14667ee360ed9fd5b26',
    decimals: 18,
    logoURI: 'https://tokens.1inch.io/0x5caf454ba92e6f2c929df14667ee360ed9fd5b26.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'cDAI',
    name: 'Compound Dai',
    decimals: 8,
    address: '0x5d3a536e4d6dbd6114cc1ead35777bab948e3643',
    logoURI: 'https://tokens.1inch.io/0x5d3a536e4d6dbd6114cc1ead35777bab948e3643.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'MYB',
    name: 'MyBit',
    address: '0x5d60d8d7ef6d37e16ebabc324de3be57f135e0bc',
    decimals: 18,
    logoURI: 'https://tokens.1inch.io/0x5d60d8d7ef6d37e16ebabc324de3be57f135e0bc.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'PIE',
    name: 'DeFiPIE Token',
    decimals: 18,
    address: '0x607c794cda77efb21f8848b7910ecf27451ae842',
    logoURI: 'https://tokens.1inch.io/0x607c794cda77efb21f8848b7910ecf27451ae842.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'RLC',
    name: 'iExec RLC',
    address: '0x607f4c5bb672230e8672085532f7e901544a7375',
    decimals: 9,
    logoURI: 'https://tokens.1inch.io/0x607f4c5bb672230e8672085532f7e901544a7375.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'aSUSDv1',
    name: 'Aave Interest bearing SUSD',
    decimals: 18,
    address: '0x625ae63000f46200499120b906716420bd059240',
    logoURI: 'https://tokens.1inch.io/0x625ae63000f46200499120b906716420bd059240.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'MFG',
    name: 'Smart MFG',
    address: '0x6710c63432a2de02954fc0f851db07146a6c0312',
    decimals: 18,
    logoURI: 'https://tokens.1inch.io/0x6710c63432a2de02954fc0f851db07146a6c0312.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'GNO',
    name: 'Gnosis',
    address: '0x6810e776880c02933d47db1b9fc05908e5386b96',
    decimals: 18,
    logoURI: 'https://tokens.1inch.io/0x6810e776880c02933d47db1b9fc05908e5386b96.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'SRN',
    name: 'Sirin Labs',
    address: '0x68d57c9a1c35f63e2c83ee8e49a64e9d70528d25',
    decimals: 18,
    logoURI: 'https://tokens.1inch.io/0x68d57c9a1c35f63e2c83ee8e49a64e9d70528d25.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'VLT',
    name: 'Bankroll Vault',
    address: '0x6b785a0322126826d8226d77e173d75dafb84d11',
    decimals: 18,
    logoURI: 'https://tokens.1inch.io/0x6b785a0322126826d8226d77e173d75dafb84d11.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'GEEQ',
    name: 'Geeq',
    address: '0x6b9f031d718dded0d681c20cb754f97b3bb81b78',
    decimals: 18,
    logoURI: 'https://tokens.1inch.io/0x6b9f031d718dded0d681c20cb754f97b3bb81b78.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'HOT',
    name: 'HoloToken',
    address: '0x6c6ee5e31d828de241282b9606c8e98ea48526e2',
    decimals: 18,
    logoURI: 'https://tokens.1inch.io/0x6c6ee5e31d828de241282b9606c8e98ea48526e2.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'aUSDTv1',
    name: 'Aave Interest bearing USDT',
    decimals: 6,
    address: '0x71fc860f7d3a592a4a98740e39db31d25db65ae8',
    logoURI: 'https://tokens.1inch.io/0x71fc860f7d3a592a4a98740e39db31d25db65ae8.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'SNT',
    name: 'Status',
    address: '0x744d70fdbe2ba4cf95131626614a1763df805b9e',
    decimals: 18,
    logoURI: 'https://tokens.1inch.io/0x744d70fdbe2ba4cf95131626614a1763df805b9e.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'MRPH',
    name: 'Morpheus Network',
    address: '0x7b0c06043468469967dba22d1af33d77d44056c8',
    decimals: 4,
    logoURI: 'https://tokens.1inch.io/0x7b0c06043468469967dba22d1af33d77d44056c8.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'buidl_1',
    name: 'dfohub',
    decimals: 18,
    address: '0x7b123f53421b1bf8533339bfbdc7c98aa94163db',
    logoURI: 'https://tokens.1inch.io/0x7b123f53421b1bf8533339bfbdc7c98aa94163db.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'SAN',
    name: 'Santiment',
    address: '0x7c5a0ce9267ed19b22f8cae653f198e3e8daf098',
    decimals: 18,
    logoURI: 'https://tokens.1inch.io/0x7c5a0ce9267ed19b22f8cae653f198e3e8daf098.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'XRT',
    name: 'Robonomics',
    address: '0x7de91b204c1c737bcee6f000aaa6569cf7061cb7',
    decimals: 9,
    logoURI: 'https://tokens.1inch.io/0x7de91b204c1c737bcee6f000aaa6569cf7061cb7.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'LEND',
    name: 'EthLend',
    address: '0x80fb784b7ed66730e8b1dbd9820afd29931aab03',
    decimals: 18,
    logoURI: 'https://tokens.1inch.io/0x80fb784b7ed66730e8b1dbd9820afd29931aab03.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'MDT_1',
    name: 'Measurable Data Token',
    address: '0x814e0908b12a99fecf5bc101bb5d0b8b5cdf7d26',
    decimals: 18,
    logoURI: 'https://tokens.1inch.io/0x814e0908b12a99fecf5bc101bb5d0b8b5cdf7d26.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'OGN',
    name: 'Origin Protocol',
    address: '0x8207c1ffc5b6804f6024322ccf34f29c3541ae26',
    decimals: 18,
    logoURI: 'https://tokens.1inch.io/0x8207c1ffc5b6804f6024322ccf34f29c3541ae26.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'ATIS',
    name: 'ATIS Token',
    address: '0x821144518dfe9e7b44fcf4d0824e15e8390d4637',
    decimals: 18,
    logoURI: 'https://tokens.1inch.io/0x821144518dfe9e7b44fcf4d0824e15e8390d4637.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'UBT',
    name: 'UniBright',
    decimals: 8,
    address: '0x8400d94a5cb0fa0d041a3788e395285d61c9ee5e',
    logoURI: 'https://tokens.1inch.io/0x8400d94a5cb0fa0d041a3788e395285d61c9ee5e.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'DIA',
    name: 'DIAToken',
    decimals: 18,
    address: '0x84ca8bc7997272c7cfb4d0cd3d55cd942b3c9419',
    logoURI: 'https://tokens.1inch.io/0x84ca8bc7997272c7cfb4d0cd3d55cd942b3c9419.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'PNT',
    name: 'pNetwork Token',
    address: '0x89ab32156e46f46d02ade3fecbe5fc4243b9aaed',
    decimals: 18,
    logoURI: 'https://tokens.1inch.io/0x89ab32156e46f46d02ade3fecbe5fc4243b9aaed.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'AKRO',
    name: 'Akropolis',
    address: '0x8ab7404063ec4dbcfd4598215992dc3f8ec853d7',
    decimals: 18,
    logoURI: 'https://tokens.1inch.io/0x8ab7404063ec4dbcfd4598215992dc3f8ec853d7.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'SXP',
    name: 'Swipe',
    address: '0x8ce9137d39326ad0cd6491fb5cc0cba0e089b6a9',
    decimals: 18,
    logoURI: 'https://tokens.1inch.io/0x8ce9137d39326ad0cd6491fb5cc0cba0e089b6a9.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'USDP',
    name: 'Pax Dollar',
    address: '0x8e870d67f660d95d5be530380d0ec0bd388289e1',
    decimals: 18,
    logoURI: 'https://tokens.1inch.io/0x8e870d67f660d95d5be530380d0ec0bd388289e1_1.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'AGI',
    name: 'SingularityNET Token',
    address: '0x8eb24319393716668d768dcec29356ae9cffe285',
    decimals: 8,
    logoURI: 'https://tokens.1inch.io/0x8eb24319393716668d768dcec29356ae9cffe285.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'REQ',
    name: 'Request',
    address: '0x8f8221afbb33998d8584a2b05749ba73c37a938a',
    decimals: 18,
    logoURI: 'https://tokens.1inch.io/0x8f8221afbb33998d8584a2b05749ba73c37a938a.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'ORN',
    name: 'Orion Protocol',
    address: '0x0258f474786ddfd37abce6df6bbb1dd5dfc4434a',
    decimals: 8,
    logoURI: 'https://tokens.1inch.io/0x0258f474786ddfd37abce6df6bbb1dd5dfc4434a.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'PNK',
    name: 'Kleros',
    address: '0x93ed3fbe21207ec2e8f2d3c3de6e058cb73bc04d',
    decimals: 18,
    logoURI: 'https://tokens.1inch.io/0x93ed3fbe21207ec2e8f2d3c3de6e058cb73bc04d.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'LOCK',
    name: 'Meridian Network',
    address: '0x95172ccbe8344fecd73d0a30f54123652981bd6f',
    decimals: 18,
    logoURI: 'https://tokens.1inch.io/0x95172ccbe8344fecd73d0a30f54123652981bd6f.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'ANTv1',
    name: 'Aragon Network Token',
    address: '0x960b236a07cf122663c4303350609a66a7b288c0',
    decimals: 18,
    logoURI: 'https://tokens.1inch.io/0x960b236a07cf122663c4303350609a66a7b288c0.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'OCEAN',
    name: 'OceanToken',
    address: '0x967da4048cd07ab37855c090aaf366e4ce1b9f48',
    decimals: 18,
    logoURI: 'https://tokens.1inch.io/0x967da4048cd07ab37855c090aaf366e4ce1b9f48.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'STRONG',
    name: 'Strong',
    address: '0x990f341946a3fdb507ae7e52d17851b87168017c',
    decimals: 18,
    logoURI: 'https://tokens.1inch.io/0x990f341946a3fdb507ae7e52d17851b87168017c.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'POLY',
    name: 'Polymath',
    address: '0x9992ec3cf6a55b00978cddf2b27bc6882d88d1ec',
    decimals: 18,
    logoURI: 'https://tokens.1inch.io/0x9992ec3cf6a55b00978cddf2b27bc6882d88d1ec.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'aUSDCv1',
    name: 'Aave Interest bearing USDC',
    decimals: 6,
    address: '0x9ba00d6856a4edf4665bca2c2309936572473b7e',
    logoURI: 'https://tokens.1inch.io/0x9ba00d6856a4edf4665bca2c2309936572473b7e.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'DZAR',
    name: 'Digital Rand',
    decimals: 6,
    address: '0x9cb2f26a23b8d89973f08c957c4d7cdf75cd341c',
    logoURI: 'https://tokens.1inch.io/0x9cb2f26a23b8d89973f08c957c4d7cdf75cd341c.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'MKR',
    name: 'Maker',
    address: '0x9f8f72aa9304c8b593d555f12ef6589cc3a579a2',
    decimals: 18,
    logoURI: 'https://tokens.1inch.io/0x9f8f72aa9304c8b593d555f12ef6589cc3a579a2.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'USDC',
    name: 'USD Coin',
    address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    decimals: 6,
    logoURI: 'https://tokens.1inch.io/0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'YFII',
    name: 'YFII.finance',
    address: '0xa1d0e215a23d7030842fc67ce582a6afa3ccab83',
    decimals: 18,
    logoURI: 'https://tokens.1inch.io/0xa1d0e215a23d7030842fc67ce582a6afa3ccab83.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'DXD',
    name: 'DXdao',
    address: '0xa1d65e8fb6e87b60feccbc582f7f97804b725521',
    decimals: 18,
    logoURI: 'https://tokens.1inch.io/0xa1d65e8fb6e87b60feccbc582f7f97804b725521.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'MTA',
    name: 'Meta',
    decimals: 18,
    address: '0xa3bed4e1c75d00fa6f4e5e6922db7261b5e9acd2',
    logoURI: 'https://tokens.1inch.io/0xa3bed4e1c75d00fa6f4e5e6922db7261b5e9acd2.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'EQMT',
    name: 'EQUUSMiningToken',
    address: '0xa462d0e6bb788c7807b1b1c96992ce1f7069e195',
    decimals: 18,
    logoURI: 'https://tokens.1inch.io/0xa462d0e6bb788c7807b1b1c96992ce1f7069e195.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'USDS',
    name: 'StableUSD',
    address: '0xa4bdb11dc0a2bec88d24a3aa1e6bb17201112ebe',
    decimals: 6,
    logoURI: 'https://tokens.1inch.io/0xa4bdb11dc0a2bec88d24a3aa1e6bb17201112ebe.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'aLINKv1',
    name: 'Aave Interest bearing LINK',
    decimals: 18,
    address: '0xa64bd6c70cb9051f6a9ba1f163fdc07e0dfb5f84',
    logoURI: 'https://tokens.1inch.io/0xa64bd6c70cb9051f6a9ba1f163fdc07e0dfb5f84.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'AGRI',
    name: 'AgriChain',
    address: '0xa704fce7b309ec09df16e2f5ab8caf6fe8a4baa9',
    decimals: 18,
    logoURI: 'https://tokens.1inch.io/0xa704fce7b309ec09df16e2f5ab8caf6fe8a4baa9.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'CEL',
    name: 'Celsius',
    address: '0xaaaebe6fe48e54f431b0c390cfaf0b017d09d42d',
    decimals: 4,
    logoURI: 'https://tokens.1inch.io/0xaaaebe6fe48e54f431b0c390cfaf0b017d09d42d.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'TKN',
    name: 'Monolith',
    address: '0xaaaf91d9b90df800df4f55c205fd6989c977e73a',
    decimals: 8,
    logoURI: 'https://tokens.1inch.io/0xaaaf91d9b90df800df4f55c205fd6989c977e73a.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'CEEK',
    name: 'CEEK VR',
    address: '0xb056c38f6b7dc4064367403e26424cd2c60655e1',
    decimals: 18,
    logoURI: 'https://tokens.1inch.io/0xb056c38f6b7dc4064367403e26424cd2c60655e1.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'XCHF',
    name: 'CryptoFranc',
    address: '0xb4272071ecadd69d933adcd19ca99fe80664fc08',
    decimals: 18,
    logoURI: 'https://tokens.1inch.io/0xb4272071ecadd69d933adcd19ca99fe80664fc08.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'PERL',
    name: 'Perlin',
    address: '0xeca82185adce47f39c684352b0439f030f860318',
    decimals: 18,
    logoURI: 'https://tokens.1inch.io/0xeca82185adce47f39c684352b0439f030f860318.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'REL',
    name: 'Relevant',
    address: '0xb6c4267c4877bb0d6b1685cfd85b0fbe82f105ec',
    decimals: 18,
    logoURI: 'https://tokens.1inch.io/0xb6c4267c4877bb0d6b1685cfd85b0fbe82f105ec.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'BAL',
    name: 'Balancer',
    decimals: 18,
    address: '0xba100000625a3754423978a60c9317c58a424e3d',
    logoURI: 'https://tokens.1inch.io/0xba100000625a3754423978a60c9317c58a424e3d.png',

    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'BAND',
    name: 'Band Protocol',
    address: '0xba11d00c5f74255f56a5e366f4f77f5a186d7f55',
    decimals: 18,
    logoURI: 'https://tokens.1inch.io/0xba11d00c5f74255f56a5e366f4f77f5a186d7f55.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'LRC',
    name: 'Loopring',
    address: '0xbbbbca6a901c926f240b89eacb641d8aec7aeafd',
    decimals: 18,
    logoURI: 'https://tokens.1inch.io/0xbbbbca6a901c926f240b89eacb641d8aec7aeafd.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'eMTRG',
    name: 'Meter Governance mapped by Meter.io',
    address: '0xbd2949f67dcdc549c6ebe98696449fa79d988a9f',
    decimals: 18,
    logoURI: 'https://tokens.1inch.io/0xbd2949f67dcdc549c6ebe98696449fa79d988a9f.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'STMX',
    name: 'Kyber StormX Token',
    address: '0xbe9375c6a420d2eeb258962efb95551a5b722803',
    decimals: 18,
    logoURI: 'https://tokens.1inch.io/0xbe9375c6a420d2eeb258962efb95551a5b722803.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'ELF',
    name: 'Aelf',
    address: '0xbf2179859fc6d5bee9bf9158632dc51678a4100e',
    decimals: 18,
    logoURI: 'https://tokens.1inch.io/0xbf2179859fc6d5bee9bf9158632dc51678a4100e.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'COMP',
    name: 'Compound',
    decimals: 18,
    address: '0xc00e94cb662c3520282e6f5717214004a7f26888',
    logoURI: 'https://tokens.1inch.io/0xc00e94cb662c3520282e6f5717214004a7f26888.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'SNX',
    name: 'Synthetix Network Token',
    decimals: 18,
    address: '0xc011a73ee8576fb46f5e1c5751ca3b9fe0af2a6f',
    logoURI: 'https://tokens.1inch.io/0xc011a73ee8576fb46f5e1c5751ca3b9fe0af2a6f.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'ALEPH',
    name: 'aleph.im v2',
    address: '0x27702a26126e0b3702af63ee09ac4d1a084ef628',
    decimals: 18,
    logoURI: 'https://tokens.1inch.io/0x27702a26126e0b3702af63ee09ac4d1a084ef628.png',

    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'cWBTC',
    name: 'Compound Wrapped BTC',
    decimals: 8,
    address: '0xc11b1268c1a384e55c48c2391d8d480264a3a7f4',
    logoURI: 'https://tokens.1inch.io/0xc11b1268c1a384e55c48c2391d8d480264a3a7f4.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'AUC',
    name: 'Auctus',
    address: '0xc12d099be31567add4e4e4d0d45691c3f58f5663',
    decimals: 18,
    logoURI: 'https://tokens.1inch.io/0xc12d099be31567add4e4e4d0d45691c3f58f5663.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'EQUAD',
    name: 'Quadrant Protocol',
    address: '0xc28e931814725bbeb9e670676fabbcb694fe7df2',
    decimals: 18,
    logoURI: 'https://tokens.1inch.io/0xc28e931814725bbeb9e670676fabbcb694fe7df2.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'TRND',
    name: 'Trendering',
    address: '0xc3dd23a0a854b4f9ae80670f528094e9eb607ccb',
    decimals: 18,
    logoURI: 'https://tokens.1inch.io/0xc3dd23a0a854b4f9ae80670f528094e9eb607ccb.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'SWAP',
    name: 'TrustSwap Token',
    address: '0xcc4304a31d09258b0029ea7fe63d032f52e44efe',
    decimals: 18,
    logoURI: 'https://tokens.1inch.io/0xcc4304a31d09258b0029ea7fe63d032f52e44efe.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'NEC',
    name: 'Ethfinex Nectar Token',
    address: '0xcc80c051057b774cd75067dc48f8987c4eb97a5e',
    decimals: 18,
    logoURI: 'https://tokens.1inch.io/0xcc80c051057b774cd75067dc48f8987c4eb97a5e.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'ANJ',
    name: 'Aragon Network Juror',
    address: '0xcd62b1c403fa761baadfc74c525ce2b51780b184',
    decimals: 18,
    logoURI: 'https://tokens.1inch.io/0xcd62b1c403fa761baadfc74c525ce2b51780b184.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'MCX',
    name: 'MachiX Token',
    address: '0xd15ecdcf5ea68e3995b2d0527a0ae0a3258302f8',
    decimals: 18,
    logoURI: 'https://tokens.1inch.io/0xd15ecdcf5ea68e3995b2d0527a0ae0a3258302f8.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'OMG',
    name: 'OmiseGO',
    address: '0xd26114cd6ee289accf82350c8d8487fedb8a0c07',
    decimals: 18,
    logoURI: 'https://tokens.1inch.io/0xd26114cd6ee289accf82350c8d8487fedb8a0c07.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'AMPL',
    name: 'Ampleforth',
    address: '0xd46ba6d942050d489dbd938a2c909a5d5039a161',
    decimals: 9,
    logoURI: 'https://tokens.1inch.io/0xd46ba6d942050d489dbd938a2c909a5d5039a161.png',

    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'CND',
    name: 'Cindicator',
    address: '0xd4c435f5b09f855c3317c8524cb1f586e42795fa',
    decimals: 18,
    logoURI: 'https://tokens.1inch.io/0xd4c435f5b09f855c3317c8524cb1f586e42795fa.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'FTX',
    name: 'FintruX',
    address: '0xd559f20296ff4895da39b5bd9add54b442596a61',
    decimals: 18,
    logoURI: 'https://tokens.1inch.io/0xd559f20296ff4895da39b5bd9add54b442596a61.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'BUIDL_2',
    name: 'DFOHub',
    address: '0xd6f0bb2a45110f819e908a915237d652ac7c5aa8',
    decimals: 18,
    logoURI: 'https://tokens.1inch.io/0xd6f0bb2a45110f819e908a915237d652ac7c5aa8.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'PLU',
    name: 'Pluton',
    address: '0xd8912c10681d8b21fd3742244f44658dba12264e',
    decimals: 18,
    logoURI: 'https://tokens.1inch.io/0xd8912c10681d8b21fd3742244f44658dba12264e.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'USDT',
    name: 'Tether USD',
    address: '0xdac17f958d2ee523a2206206994597c13d831ec7',
    decimals: 6,
    logoURI: 'https://tokens.1inch.io/0xdac17f958d2ee523a2206206994597c13d831ec7.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'EURS',
    name: 'STASIS EURS',
    address: '0xdb25f211ab05b1c97d595516f45794528a807ad8',
    decimals: 2,
    logoURI: 'https://tokens.1inch.io/0xdb25f211ab05b1c97d595516f45794528a807ad8.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'KNCL',
    name: 'KyberNetwork Legacy',
    address: '0xdd974d5c2e2928dea5f71b9825b8b646686bd200',
    decimals: 18,
    logoURI: 'https://tokens.1inch.io/0xdd974d5c2e2928dea5f71b9825b8b646686bd200.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'MFT',
    name: 'Mainframe',
    address: '0xdf2c7238198ad8b389666574f2d8bc411a4b7428',
    decimals: 18,
    logoURI: 'https://tokens.1inch.io/0xdf2c7238198ad8b389666574f2d8bc411a4b7428.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'HUSD',
    name: 'HUSD',
    decimals: 8,
    address: '0xdf574c24545e5ffecb9a659c229253d4111d87e1',
    logoURI: 'https://tokens.1inch.io/0xdf574c24545e5ffecb9a659c229253d4111d87e1.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'KARMA',
    name: 'Karma',
    address: '0xdfe691f37b6264a90ff507eb359c45d55037951c',
    decimals: 4,
    logoURI: 'https://tokens.1inch.io/0xdfe691f37b6264a90ff507eb359c45d55037951c.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'RMPL',
    name: 'RMPL',
    address: '0xe17f017475a709de58e976081eb916081ff4c9d5',
    decimals: 9,
    logoURI: 'https://tokens.1inch.io/0xe17f017475a709de58e976081eb916081ff4c9d5.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'PLR',
    name: 'Pillar',
    address: '0xe3818504c1b32bf1557b16c238b2e01fd3149c17',
    decimals: 18,
    logoURI: 'https://tokens.1inch.io/0xe3818504c1b32bf1557b16c238b2e01fd3149c17.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'ZRX',
    name: '0x Protocol',
    address: '0xe41d2489571d322189246dafa5ebde1f4699f498',
    decimals: 18,
    logoURI: 'https://tokens.1inch.io/0xe41d2489571d322189246dafa5ebde1f4699f498.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: '2KEY',
    name: '2key.network',
    address: '0xe48972fcd82a274411c01834e2f031d4377fa2c0',
    decimals: 18,
    logoURI: 'https://tokens.1inch.io/0xe48972fcd82a274411c01834e2f031d4377fa2c0.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'renBTC',
    name: 'renBTC',
    decimals: 8,
    address: '0xeb4c2781e4eba804ce9a9803c67d0893436bb27d',
    logoURI: 'https://tokens.1inch.io/0xeb4c2781e4eba804ce9a9803c67d0893436bb27d.png',

    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'MLN',
    name: 'Melon Token',
    address: '0xec67005c4e498ec7f55e092bd1d35cbc47c91892',
    decimals: 18,
    logoURI: 'https://tokens.1inch.io/0xec67005c4e498ec7f55e092bd1d35cbc47c91892.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'ASKO',
    name: 'Askobar Network',
    address: '0xeeee2a622330e6d2036691e983dee87330588603',
    decimals: 18,
    logoURI: 'https://tokens.1inch.io/0xeeee2a622330e6d2036691e983dee87330588603.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'HEDG',
    name: 'Hedge Trade',
    address: '0xf1290473e210b2108a85237fbcd7b6eb42cc654f',
    decimals: 18,
    logoURI: 'https://tokens.1inch.io/0xf1290473e210b2108a85237fbcd7b6eb42cc654f.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'UNC',
    name: 'UniCrypt',
    address: '0xf29e46887ffae92f1ff87dfe39713875da541373',
    decimals: 18,
    logoURI: 'https://tokens.1inch.io/0xf29e46887ffae92f1ff87dfe39713875da541373.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'POWER',
    name: 'UniPower',
    address: '0xf2f9a7e93f845b3ce154efbeb64fb9346fcce509',
    decimals: 18,
    logoURI: 'https://tokens.1inch.io/0xf2f9a7e93f845b3ce154efbeb64fb9346fcce509.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'MTL',
    name: 'Metal',
    address: '0xf433089366899d83a9f26a773d59ec7ecf30355e',
    decimals: 8,
    logoURI: 'https://tokens.1inch.io/0xf433089366899d83a9f26a773d59ec7ecf30355e.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'ENJ',
    name: 'Enjin Coin',
    address: '0xf629cbd94d3791c9250152bd8dfbdf380e2a3b9c',
    decimals: 18,
    logoURI: 'https://tokens.1inch.io/0xf629cbd94d3791c9250152bd8dfbdf380e2a3b9c.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'cUSDT',
    name: 'Compound USDT',
    decimals: 8,
    address: '0xf650c3d88d12db855b8bf7d11be6c55a4e07dcc9',
    logoURI: 'https://tokens.1inch.io/0xf650c3d88d12db855b8bf7d11be6c55a4e07dcc9.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'IND',
    name: 'Indorse',
    address: '0xf8e386eda857484f5a12e4b5daa9984e06e73705',
    decimals: 18,
    logoURI: 'https://tokens.1inch.io/0xf8e386eda857484f5a12e4b5daa9984e06e73705.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'XAMP',
    name: 'Antiample',
    decimals: 9,
    address: '0xf911a7ec46a2c6fa49193212fe4a2a9b95851c27',
    logoURI: 'https://tokens.1inch.io/0xf911a7ec46a2c6fa49193212fe4a2a9b95851c27.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'RCN',
    name: 'Ripio',
    address: '0xf970b8e36e23f7fc3fd752eea86f8be8d83375a6',
    decimals: 18,
    logoURI: 'https://tokens.1inch.io/0xf970b8e36e23f7fc3fd752eea86f8be8d83375a6.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'aDAIv1',
    name: 'Aave Interest bearing DAI',
    decimals: 18,
    address: '0xfc1e690f61efd961294b3e1ce3313fbd8aa4f85d',
    logoURI: 'https://tokens.1inch.io/0xfc1e690f61efd961294b3e1ce3313fbd8aa4f85d.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'TGBP',
    name: 'TrueGBP',
    decimals: 18,
    address: '0x00000000441378008ea67f4284a57932b1c000a5',
    logoURI: 'https://tokens.1inch.io/0x00000000441378008ea67f4284a57932b1c000a5.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'CHAI',
    name: 'Chai Token',
    decimals: 18,
    address: '0x06af07097c9eeb7fd685c692751d5c66db49c215',
    logoURI: 'https://tokens.1inch.io/0x06af07097c9eeb7fd685c692751d5c66db49c215.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'cSAI',
    name: 'Compound Sai',
    decimals: 8,
    address: '0xf5dce57282a584d2746faf1593d3121fcac444dc',
    logoURI: 'https://tokens.1inch.io/0xf5dce57282a584d2746faf1593d3121fcac444dc.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'cETH',
    name: 'Compound ETH',
    decimals: 8,
    address: '0x4ddc2d193948926d02f9b1fe9e1daa0718270ed5',
    logoURI: 'https://tokens.1inch.io/0x4ddc2d193948926d02f9b1fe9e1daa0718270ed5.png',

    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'cUSDC',
    name: 'Compound USD Coin',
    decimals: 8,
    address: '0x39aa39c021dfbae8fac545936693ac917d5e7563',
    logoURI: 'https://tokens.1inch.io/0x39aa39c021dfbae8fac545936693ac917d5e7563.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'HT',
    name: 'HuobiToken',
    decimals: 18,
    address: '0x6f259637dcd74c767781e37bc6133cd6a68aa161',
    logoURI: 'https://tokens.1inch.io/0x6f259637dcd74c767781e37bc6133cd6a68aa161.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'MATIC',
    name: 'Matic Token',
    decimals: 18,
    address: '0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0',
    logoURI: 'https://tokens.1inch.io/0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'DAI',
    name: 'Dai Stablecoin',
    decimals: 18,
    address: '0x6b175474e89094c44da98b954eedeac495271d0f',
    logoURI: 'https://tokens.1inch.io/0x6b175474e89094c44da98b954eedeac495271d0f.png',

    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'SAI',
    name: 'Sai Stablecoin',
    decimals: 18,
    address: '0x89d24a6b4ccb1b6faa2625fe562bdd9a23260359',
    logoURI: 'https://tokens.1inch.io/0x89d24a6b4ccb1b6faa2625fe562bdd9a23260359.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'sUSD',
    name: 'Synth sUSD',
    decimals: 18,
    address: '0x57ab1ec28d129707052df4df418d58a2d46d5f51',
    logoURI: 'https://tokens.1inch.io/0x57ab1ec28d129707052df4df418d58a2d46d5f51.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'TUSD',
    name: 'TrueUSD',
    decimals: 18,
    address: '0x0000000000085d4780b73119b644ae5ecd22b376',
    logoURI: 'https://tokens.1inch.io/0x0000000000085d4780b73119b644ae5ecd22b376.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'aETHv1',
    name: 'Aave Interest bearing ETH',
    decimals: 18,
    address: '0x3a3a65aab0dd2a17e3f1947ba16138cd37d08c04',
    logoURI: 'https://tokens.1inch.io/0x3a3a65aab0dd2a17e3f1947ba16138cd37d08c04.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'aBATv1',
    name: 'Aave Interest bearing BAT',
    decimals: 18,
    address: '0xe1ba0fb44ccb0d11b80f92f4f8ed94ca3ff51d00',
    logoURI: 'https://tokens.1inch.io/0xe1ba0fb44ccb0d11b80f92f4f8ed94ca3ff51d00.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'aKNCv1',
    name: 'Aave Interest bearing KNC',
    decimals: 18,
    address: '0x9d91be44c06d373a8a226e1f3b146956083803eb',
    logoURI: 'https://tokens.1inch.io/0x9d91be44c06d373a8a226e1f3b146956083803eb.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'aLENDv1',
    name: 'Aave Interest bearing LEND',
    decimals: 18,
    address: '0x7d2d3688df45ce7c552e19c27e007673da9204b8',
    logoURI: 'https://tokens.1inch.io/0x7d2d3688df45ce7c552e19c27e007673da9204b8.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'aMANAv1',
    name: 'Aave Interest bearing MANA',
    decimals: 18,
    address: '0x6fce4a401b6b80ace52baaefe4421bd188e76f6f',
    logoURI: 'https://tokens.1inch.io/0x6fce4a401b6b80ace52baaefe4421bd188e76f6f.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'aMKRv1',
    name: 'Aave Interest bearing MKR',
    decimals: 18,
    address: '0x7deb5e830be29f91e298ba5ff1356bb7f8146998',
    logoURI: 'https://tokens.1inch.io/0x7deb5e830be29f91e298ba5ff1356bb7f8146998.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'aREPv1',
    name: 'Aave Interest bearing REP',
    decimals: 18,
    address: '0x71010a9d003445ac60c4e6a7017c1e89a477b438',
    logoURI: 'https://tokens.1inch.io/0x71010a9d003445ac60c4e6a7017c1e89a477b438.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'aSNXv1',
    name: 'Aave Interest bearing SNX',
    decimals: 18,
    address: '0x328c4c80bc7aca0834db37e6600a6c49e12da4de',
    logoURI: 'https://tokens.1inch.io/0x328c4c80bc7aca0834db37e6600a6c49e12da4de.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'aWBTCv1',
    name: 'Aave Interest bearing WBTC',
    decimals: 8,
    address: '0xfc4b8ed459e00e5400be803a9bb3954234fd50e3',
    logoURI: 'https://tokens.1inch.io/0xfc4b8ed459e00e5400be803a9bb3954234fd50e3.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'aZRXv1',
    name: 'Aave Interest bearing ZRX',
    decimals: 18,
    address: '0x6fb0855c404e09c47c3fbca25f08d4e41f9f062f',
    logoURI: 'https://tokens.1inch.io/0x6fb0855c404e09c47c3fbca25f08d4e41f9f062f.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'yDAIv2',
    name: 'iearn DAIv2',
    decimals: 18,
    address: '0x16de59092dae5ccf4a1e6439d611fd0653f0bd01',
    logoURI: 'https://tokens.1inch.io/0x16de59092dae5ccf4a1e6439d611fd0653f0bd01.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'yDAIv3',
    name: 'iearn DAI v3',
    decimals: 18,
    address: '0xc2cb1040220768554cf699b0d863a3cd4324ce32',
    logoURI: 'https://tokens.1inch.io/0xc2cb1040220768554cf699b0d863a3cd4324ce32.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'yBTC',
    name: 'iearn WBTC',
    decimals: 8,
    address: '0x04aa51bbcb46541455ccf1b8bef2ebc5d3787ec9',
    logoURI: 'https://tokens.1inch.io/0x04aa51bbcb46541455ccf1b8bef2ebc5d3787ec9.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'yUSDTv2',
    name: 'iearn USDT v2',
    decimals: 6,
    address: '0x83f798e925bcd4017eb265844fddabb448f1707d',
    logoURI: 'https://tokens.1inch.io/0x83f798e925bcd4017eb265844fddabb448f1707d.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'yUSDTv3',
    name: 'iearn USDT v3',
    decimals: 6,
    address: '0xe6354ed5bc4b393a5aad09f21c46e101e692d447',
    logoURI: 'https://tokens.1inch.io/0xe6354ed5bc4b393a5aad09f21c46e101e692d447.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'yUSDCv2',
    name: 'iearn USDC v2',
    decimals: 6,
    address: '0xd6ad7a6750a7593e092a9b218d66c0a814a3436e',
    logoURI: 'https://tokens.1inch.io/0xd6ad7a6750a7593e092a9b218d66c0a814a3436e.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'yUSDCv3',
    name: 'iearn USDC v3',
    decimals: 6,
    address: '0x26ea744e5b887e5205727f55dfbe8685e3b21951',
    logoURI: 'https://tokens.1inch.io/0x26ea744e5b887e5205727f55dfbe8685e3b21951.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'BCDT',
    name: 'Blockchain Certified Data Token',
    decimals: 18,
    address: '0xacfa209fb73bf3dd5bbfb1101b9bc999c49062a5',
    logoURI: 'https://tokens.1inch.io/0xacfa209fb73bf3dd5bbfb1101b9bc999c49062a5.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'LST',
    name: 'Lendroid Support Token',
    decimals: 18,
    address: '0x4de2573e27e648607b50e1cfff921a33e4a34405',
    logoURI: 'https://tokens.1inch.io/0x4de2573e27e648607b50e1cfff921a33e4a34405.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'PAN',
    name: 'Panvala pan',
    decimals: 18,
    address: '0xd56dac73a4d6766464b38ec6d91eb45ce7457c44',
    logoURI: 'https://tokens.1inch.io/0xd56dac73a4d6766464b38ec6d91eb45ce7457c44.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'GUSD',
    name: 'Gemini dollar',
    decimals: 2,
    address: '0x056fd409e1d7a124bd7017459dfea2f387b6d5cd',
    logoURI: 'https://tokens.1inch.io/0x056fd409e1d7a124bd7017459dfea2f387b6d5cd.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'aBUSDv1',
    name: 'Aave Interest bearing Binance USD',
    decimals: 18,
    address: '0x6ee0f7bb50a54ab5253da0667b0dc2ee526c30a8',
    logoURI: 'https://tokens.1inch.io/0x6ee0f7bb50a54ab5253da0667b0dc2ee526c30a8.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'XPR',
    name: 'Proton',
    decimals: 4,
    address: '0xd7efb00d12c2c13131fd319336fdf952525da2af',
    logoURI: 'https://tokens.1inch.io/0xd7efb00d12c2c13131fd319336fdf952525da2af.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'KEEP',
    name: 'KEEP Token',
    decimals: 18,
    address: '0x85eee30c52b0b379b046fb0f85f4f3dc3009afec',
    logoURI: 'https://tokens.1inch.io/0x85eee30c52b0b379b046fb0f85f4f3dc3009afec.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'renZEC',
    name: 'renZEC',
    decimals: 8,
    address: '0x1c5db575e2ff833e46a2e9864c22f4b22e0b37c2',
    logoURI: 'https://tokens.1inch.io/0x1c5db575e2ff833e46a2e9864c22f4b22e0b37c2.png',

    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'renBCH',
    name: 'renBCH',
    decimals: 8,
    address: '0x459086f2376525bdceba5bdda135e4e9d3fef5bf',
    logoURI: 'https://tokens.1inch.io/0x459086f2376525bdceba5bdda135e4e9d3fef5bf.png',

    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'tBTC',
    name: 'tBTC',
    decimals: 18,
    address: '0x8daebade922df735c38c80c7ebd708af50815faa',
    logoURI: 'https://tokens.1inch.io/0x8daebade922df735c38c80c7ebd708af50815faa.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'HBTC',
    name: 'Huobi BTC',
    decimals: 18,
    address: '0x0316eb71485b0ab14103307bf65a021042c6d380',
    logoURI: 'https://tokens.1inch.io/0x0316eb71485b0ab14103307bf65a021042c6d380.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'SHUF',
    name: 'Shuffle.Monster V3',
    decimals: 18,
    address: '0x3a9fff453d50d4ac52a6890647b823379ba36b9e',
    logoURI: 'https://tokens.1inch.io/0x3a9fff453d50d4ac52a6890647b823379ba36b9e.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'DONUT',
    name: 'DONUT',
    decimals: 18,
    address: '0xc0f9bd5fa5698b6505f643900ffa515ea5df54a9',
    logoURI: 'https://tokens.1inch.io/0xc0f9bd5fa5698b6505f643900ffa515ea5df54a9.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'PAXG',
    name: 'Paxos Gold',
    decimals: 18,
    address: '0x45804880de22913dafe09f4980848ece6ecbaf78',
    logoURI: 'https://tokens.1inch.io/0x45804880de22913dafe09f4980848ece6ecbaf78.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: '0xMR',
    name: '0xMonero',
    decimals: 18,
    address: '0x035df12e0f3ac6671126525f1015e47d79dfeddf',
    logoURI: 'https://tokens.1inch.io/0x035df12e0f3ac6671126525f1015e47d79dfeddf.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'STORJ',
    name: 'Storj',
    decimals: 8,
    address: '0xb64ef51c888972c908cfacf59b47c1afbc0ab8ac',
    logoURI: 'https://tokens.1inch.io/0xb64ef51c888972c908cfacf59b47c1afbc0ab8ac.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'SALT',
    name: 'Salt',
    decimals: 8,
    address: '0x4156d3342d5c385a87d264f90653733592000581',
    logoURI: 'https://tokens.1inch.io/0x4156d3342d5c385a87d264f90653733592000581.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'yCurve',
    name: 'Curve.fi iearn pool token',
    decimals: 18,
    address: '0xdf5e0e81dff6faf3a7e52ba697820c5e32d806a8',
    logoURI: 'https://tokens.1inch.io/0xdf5e0e81dff6faf3a7e52ba697820c5e32d806a8.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'RARI',
    name: 'Rarible',
    decimals: 18,
    address: '0xfca59cd816ab1ead66534d82bc21e7515ce441cf',
    logoURI: 'https://tokens.1inch.io/0xfca59cd816ab1ead66534d82bc21e7515ce441cf.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'pBTC',
    name: 'pTokens BTC',
    decimals: 18,
    address: '0x5228a22e72ccc52d415ecfd199f99d0665e7733b',
    logoURI: 'https://tokens.1inch.io/0x5228a22e72ccc52d415ecfd199f99d0665e7733b.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'SRM',
    name: 'Serum',
    decimals: 6,
    address: '0x476c5e26a75bd202a9683ffd34359c0cc15be0ff',
    logoURI: 'https://tokens.1inch.io/0x476c5e26a75bd202a9683ffd34359c0cc15be0ff.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'NIOX',
    name: 'Autonio',
    decimals: 4,
    address: '0xc813ea5e3b48bebeedb796ab42a30c5599b01740',
    logoURI: 'https://tokens.1inch.io/0xc813ea5e3b48bebeedb796ab42a30c5599b01740.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'STA',
    name: 'Statera',
    decimals: 18,
    address: '0xa7de087329bfcda5639247f96140f9dabe3deed1',
    logoURI: 'https://tokens.1inch.io/0xa7de087329bfcda5639247f96140f9dabe3deed1.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'FNT',
    name: 'Falcon',
    decimals: 6,
    address: '0xdc5864ede28bd4405aa04d93e05a0531797d9d59',
    logoURI: 'https://tokens.1inch.io/0xdc5864ede28bd4405aa04d93e05a0531797d9d59.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'YAM',
    name: 'YAM',
    decimals: 18,
    address: '0x0aacfbec6a24756c20d41914f2caba817c0d8521',
    logoURI: 'https://tokens.1inch.io/0x0aacfbec6a24756c20d41914f2caba817c0d8521.png',

    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'ADX',
    name: 'AdEx Network',
    decimals: 18,
    address: '0xade00c28244d5ce17d72e40330b1c318cd12b7c3',
    logoURI: 'https://tokens.1inch.io/0xade00c28244d5ce17d72e40330b1c318cd12b7c3.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'CRV',
    name: 'Curve DAO Token',
    decimals: 18,
    address: '0xd533a949740bb3306d119cc777fa900ba034cd52',
    logoURI: 'https://tokens.1inch.io/0xd533a949740bb3306d119cc777fa900ba034cd52.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'RING',
    name: 'Darwinia Network Native Token',
    decimals: 18,
    address: '0x9469d013805bffb7d3debe5e7839237e535ec483',
    logoURI: 'https://tokens.1inch.io/0x9469d013805bffb7d3debe5e7839237e535ec483.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'OMv1',
    name: 'OM Token',
    decimals: 18,
    address: '0x2baecdf43734f22fd5c152db08e3c27233f0c7d2',
    logoURI: 'https://tokens.1inch.io/0x2baecdf43734f22fd5c152db08e3c27233f0c7d2.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'CTSI',
    name: 'Cartesi Token',
    decimals: 18,
    address: '0x491604c0fdf08347dd1fa4ee062a822a5dd06b5d',
    logoURI: 'https://tokens.1inch.io/0x491604c0fdf08347dd1fa4ee062a822a5dd06b5d.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'LAYER',
    name: 'Unilayer',
    decimals: 18,
    address: '0x0ff6ffcfda92c53f615a4a75d982f399c989366b',
    logoURI: 'https://tokens.1inch.io/0x0ff6ffcfda92c53f615a4a75d982f399c989366b.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'MEME',
    name: 'MEME',
    decimals: 8,
    address: '0xd5525d397898e5502075ea5e830d8914f6f0affe',
    logoURI: 'https://tokens.1inch.io/0xd5525d397898e5502075ea5e830d8914f6f0affe.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'TRAC',
    name: 'Trace',
    decimals: 18,
    address: '0xaa7a9ca87d3694b5755f213b5d04094b8d0f0a6f',
    logoURI: 'https://tokens.1inch.io/0xaa7a9ca87d3694b5755f213b5d04094b8d0f0a6f.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'JRT',
    name: 'Jarvis Reward Token',
    decimals: 18,
    address: '0x8a9c67fee641579deba04928c4bc45f66e26343a',
    logoURI: 'https://tokens.1inch.io/0x8a9c67fee641579deba04928c4bc45f66e26343a.png',

    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'USDN',
    name: 'Neutrino',
    decimals: 18,
    address: '0x674c6ad92fd080e4004b2312b45f796a192d27a0',
    logoURI: 'https://tokens.1inch.io/0x674c6ad92fd080e4004b2312b45f796a192d27a0.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'PRQ',
    name: 'Parsiq Token',
    decimals: 18,
    address: '0x362bc847a3a9637d3af6624eec853618a43ed7d2',
    logoURI: 'https://tokens.1inch.io/0x362bc847a3a9637d3af6624eec853618a43ed7d2.png',

    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'HAKKA',
    name: 'Hakka Finance',
    decimals: 18,
    address: '0x0e29e5abbb5fd88e28b2d355774e73bd47de3bcd',
    logoURI: 'https://tokens.1inch.io/0x0e29e5abbb5fd88e28b2d355774e73bd47de3bcd.png',

    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'CVP',
    name: 'Concentrated Voting Power',
    decimals: 18,
    address: '0x38e4adb44ef08f22f5b5b76a8f0c2d0dcbe7dca1',
    logoURI: 'https://tokens.1inch.io/0x38e4adb44ef08f22f5b5b76a8f0c2d0dcbe7dca1.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'OPT',
    name: 'Open Predict Token',
    decimals: 18,
    address: '0x4fe5851c9af07df9e5ad8217afae1ea72737ebda',
    logoURI: 'https://tokens.1inch.io/0x4fe5851c9af07df9e5ad8217afae1ea72737ebda.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'SUSHI',
    name: 'SushiToken',
    decimals: 18,
    address: '0x6b3595068778dd592e39a122f4f5a5cf09c90fe2',
    logoURI: 'https://tokens.1inch.io/0x6b3595068778dd592e39a122f4f5a5cf09c90fe2.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'DSLA',
    name: 'DSLA',
    decimals: 18,
    address: '0x3affcca64c2a6f4e3b6bd9c64cd2c969efd1ecbe',
    logoURI: 'https://tokens.1inch.io/0x3affcca64c2a6f4e3b6bd9c64cd2c969efd1ecbe.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'SWFL',
    name: 'Swapfolio',
    decimals: 18,
    address: '0xba21ef4c9f433ede00badefcc2754b8e74bd538a',
    logoURI: 'https://tokens.1inch.io/0xba21ef4c9f433ede00badefcc2754b8e74bd538a.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'FSW',
    name: 'FalconSwap Token',
    decimals: 18,
    address: '0xfffffffff15abf397da76f1dcc1a1604f45126db',
    logoURI: 'https://tokens.1inch.io/0xfffffffff15abf397da76f1dcc1a1604f45126db.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'SWRV',
    name: 'Swerve DAO Token',
    decimals: 18,
    address: '0xb8baa0e4287890a5f79863ab62b7f175cecbd433',
    logoURI: 'https://tokens.1inch.io/0xb8baa0e4287890a5f79863ab62b7f175cecbd433.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'MXX',
    name: 'Multiplier',
    decimals: 8,
    address: '0x8a6f3bf52a26a21531514e23016eeae8ba7e7018',
    logoURI: 'https://tokens.1inch.io/0x8a6f3bf52a26a21531514e23016eeae8ba7e7018.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'yUSD',
    name: 'yearn Curve.fi yDAI/yUSDC/yUSDT/yTUSD',
    decimals: 18,
    address: '0x5dbcf33d8c2e976c6b560249878e6f1491bca25c',
    logoURI: 'https://tokens.1inch.io/0x5dbcf33d8c2e976c6b560249878e6f1491bca25c.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'STEP',
    name: '1Step.finance',
    decimals: 8,
    address: '0x50026ad58b338cf3eccc2b422deb8faa725f377f',
    logoURI: 'https://tokens.1inch.io/0x50026ad58b338cf3eccc2b422deb8faa725f377f.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'PRDX',
    name: 'Predix Network',
    decimals: 18,
    address: '0x556148562d5ddeb72545d7ec4b3ec8edc8f55ba7',
    logoURI: 'https://tokens.1inch.io/0x556148562d5ddeb72545d7ec4b3ec8edc8f55ba7.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'DPI',
    name: 'DefiPulse Index',
    decimals: 18,
    address: '0x1494ca1f11d487c2bbe4543e90080aeba4ba3c2b',
    logoURI: 'https://tokens.1inch.io/0x1494ca1f11d487c2bbe4543e90080aeba4ba3c2b.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'GHST',
    name: 'Aavegotchi GHST Token',
    decimals: 18,
    address: '0x3f382dbd960e3a9bbceae22651e88158d2791550',
    logoURI: 'https://tokens.1inch.io/0x3f382dbd960e3a9bbceae22651e88158d2791550.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'UNI',
    name: 'Uniswap',
    decimals: 18,
    address: '0x1f9840a85d5af5bf1d1762f925bdaddc4201f984',
    logoURI: 'https://tokens.1inch.io/0x1f9840a85d5af5bf1d1762f925bdaddc4201f984.png',

    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'PICKLE',
    name: 'PickleToken',
    decimals: 18,
    address: '0x429881672b9ae42b8eba0e26cd9c73711b891ca5',
    logoURI: 'https://tokens.1inch.io/0x429881672b9ae42b8eba0e26cd9c73711b891ca5.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'FRONT',
    name: 'Frontier Token',
    decimals: 18,
    address: '0xf8c3527cc04340b208c854e985240c02f7b7793f',
    logoURI: 'https://tokens.1inch.io/0xf8c3527cc04340b208c854e985240c02f7b7793f.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'DHT',
    name: 'dHedge DAO Token',
    decimals: 18,
    address: '0xca1207647ff814039530d7d35df0e1dd2e91fa84',
    logoURI: 'https://tokens.1inch.io/0xca1207647ff814039530d7d35df0e1dd2e91fa84.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'FARM',
    name: 'FARM Reward Token',
    decimals: 18,
    address: '0xa0246c9032bc3a600820415ae600c6388619a14d',
    logoURI: 'https://tokens.1inch.io/0xa0246c9032bc3a600820415ae600c6388619a14d.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'GOF',
    name: 'Golff.finance',
    decimals: 18,
    address: '0x488e0369f9bc5c40c002ea7c1fe4fd01a198801c',
    logoURI: 'https://tokens.1inch.io/0x488e0369f9bc5c40c002ea7c1fe4fd01a198801c.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'OUSD',
    name: 'Origin Dollar',
    decimals: 18,
    address: '0x2a8e1e676ec238d8a992307b495b45b3feaa5e86',
    logoURI: 'https://tokens.1inch.io/0x2a8e1e676ec238d8a992307b495b45b3feaa5e86.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'AAVE',
    name: 'Aave Token',
    decimals: 18,
    address: '0x7fc66500c84a76ad7e9c93437bfc5ac33e2ddae9',
    logoURI: 'https://tokens.1inch.io/0x7fc66500c84a76ad7e9c93437bfc5ac33e2ddae9.png',

    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'DODO',
    name: 'DODO bird',
    decimals: 18,
    address: '0x43dfc4159d86f3a37a5a4b3d4580b888ad7d4ddd',
    logoURI: 'https://tokens.1inch.io/0x43dfc4159d86f3a37a5a4b3d4580b888ad7d4ddd.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'SHAKE',
    name: 'SHAKE token by SpaceSwap v2 ',
    decimals: 18,
    address: '0x6006fc2a849fedaba8330ce36f5133de01f96189',
    logoURI: 'https://tokens.1inch.io/0x6006fc2a849fedaba8330ce36f5133de01f96189.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'MILK2',
    name: 'MilkyWay Token by SpaceSwap v2',
    decimals: 18,
    address: '0x80c8c3dcfb854f9542567c8dac3f44d709ebc1de',
    logoURI: 'https://tokens.1inch.io/0x80c8c3dcfb854f9542567c8dac3f44d709ebc1de.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'CORE',
    name: 'cVault.finance',
    decimals: 18,
    address: '0x62359ed7505efc61ff1d56fef82158ccaffa23d7',
    logoURI: 'https://tokens.1inch.io/0x62359ed7505efc61ff1d56fef82158ccaffa23d7.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'PERP',
    name: 'Perpetual',
    decimals: 18,
    address: '0xbc396689893d065f41bc2c6ecbee5e0085233447',
    logoURI: 'https://tokens.1inch.io/0xbc396689893d065f41bc2c6ecbee5e0085233447.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'USDK',
    name: 'USDK',
    decimals: 18,
    address: '0x1c48f86ae57291f7686349f12601910bd8d470bb',
    logoURI: 'https://tokens.1inch.io/0x1c48f86ae57291f7686349f12601910bd8d470bb.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'SWAG',
    name: 'Swag Token',
    decimals: 18,
    address: '0x87edffde3e14c7a66c9b9724747a1c5696b742e6',
    logoURI: 'https://tokens.1inch.io/0x87edffde3e14c7a66c9b9724747a1c5696b742e6.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'DEFI+S',
    name: 'PieDAO DEFI Small Cap',
    decimals: 18,
    address: '0xad6a626ae2b43dcb1b39430ce496d2fa0365ba9c',
    logoURI: 'https://tokens.1inch.io/0xad6a626ae2b43dcb1b39430ce496d2fa0365ba9c.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'DOUGH',
    name: 'PieDAO DOUGH v2',
    decimals: 18,
    address: '0xad32a8e6220741182940c5abf610bde99e737b2d',
    logoURI: 'https://tokens.1inch.io/0xad32a8e6220741182940c5abf610bde99e737b2d.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'cUNI',
    name: 'Compound Uniswap',
    decimals: 8,
    address: '0x35a18000230da775cac24873d00ff85bccded550',
    logoURI: 'https://tokens.1inch.io/0x35a18000230da775cac24873d00ff85bccded550.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'mUSD',
    name: 'mStable USD',
    decimals: 18,
    address: '0xe2f2a5c287993345a840db3b0845fbc70f5935a5',
    logoURI: 'https://tokens.1inch.io/0xe2f2a5c287993345a840db3b0845fbc70f5935a5.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'ZEE',
    name: 'ZeroSwapToken',
    decimals: 18,
    address: '0x2edf094db69d6dcd487f1b3db9febe2eec0dd4c5',
    logoURI: 'https://tokens.1inch.io/0x2edf094db69d6dcd487f1b3db9febe2eec0dd4c5.png',

    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'HEGIC',
    name: 'Hegic',
    decimals: 18,
    address: '0x584bc13c7d411c00c01a62e8019472de68768430',
    logoURI: 'https://tokens.1inch.io/0x584bc13c7d411c00c01a62e8019472de68768430.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'ASTRO',
    name: 'AstroTools.io',
    decimals: 18,
    address: '0xcbd55d4ffc43467142761a764763652b48b969ff',
    logoURI: 'https://tokens.1inch.io/0xcbd55d4ffc43467142761a764763652b48b969ff.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'AMP',
    name: 'Amp',
    decimals: 18,
    address: '0xff20817765cb7f73d4bde2e66e067e58d11095c2',
    logoURI: 'https://tokens.1inch.io/0xff20817765cb7f73d4bde2e66e067e58d11095c2.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'BOND',
    name: 'BarnBridge Governance Token',
    decimals: 18,
    address: '0x0391d2021f89dc339f60fff84546ea23e337750f',
    logoURI: 'https://tokens.1inch.io/0x0391d2021f89dc339f60fff84546ea23e337750f.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'ANT',
    name: 'Aragon Network Token',
    decimals: 18,
    address: '0xa117000000f279d81a1d3cc75430faa017fa5a2e',
    logoURI: 'https://tokens.1inch.io/0xa117000000f279d81a1d3cc75430faa017fa5a2e.png',

    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'FUSE',
    name: 'Fuse Token',
    decimals: 18,
    address: '0x970b9bb2c0444f5e81e9d0efb84c8ccdcdcaf84d',
    logoURI: 'https://tokens.1inch.io/0x970b9bb2c0444f5e81e9d0efb84c8ccdcdcaf84d.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'ESD',
    name: 'Empty Set Dollar',
    decimals: 18,
    address: '0x36f3fd68e7325a35eb768f1aedaae9ea0689d723',
    logoURI: 'https://tokens.1inch.io/0x36f3fd68e7325a35eb768f1aedaae9ea0689d723.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'KP3R',
    name: 'Keep3rV1',
    decimals: 18,
    address: '0x1ceb5cb57c4d4e2b2433641b95dd330a33185a44',
    logoURI: 'https://tokens.1inch.io/0x1ceb5cb57c4d4e2b2433641b95dd330a33185a44.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'DUSD',
    name: 'DefiDollar',
    decimals: 18,
    address: '0x5bc25f649fc4e26069ddf4cf4010f9f706c23831',
    logoURI: 'https://tokens.1inch.io/0x5bc25f649fc4e26069ddf4cf4010f9f706c23831.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'VOICE',
    name: 'Voice Token',
    decimals: 18,
    address: '0x2e2364966267b5d7d2ce6cd9a9b5bd19d9c7c6a9',
    logoURI: 'https://tokens.1inch.io/0x2e2364966267b5d7d2ce6cd9a9b5bd19d9c7c6a9.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'HEZ',
    name: 'Hermez Network Token',
    decimals: 18,
    address: '0xeef9f339514298c6a857efcfc1a762af84438dee',
    logoURI: 'https://tokens.1inch.io/0xeef9f339514298c6a857efcfc1a762af84438dee.png',

    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'SURF',
    name: 'SURF.Finance',
    decimals: 18,
    address: '0xea319e87cf06203dae107dd8e5672175e3ee976c',
    logoURI: 'https://tokens.1inch.io/0xea319e87cf06203dae107dd8e5672175e3ee976c.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'wANATHA',
    name: 'Wrapped ANATHA',
    decimals: 18,
    address: '0x3383c5a8969dc413bfddc9656eb80a1408e4ba20',
    logoURI: 'https://tokens.1inch.io/0x3383c5a8969dc413bfddc9656eb80a1408e4ba20.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'AUDIO',
    name: 'Audius',
    decimals: 18,
    address: '0x18aaa7115705e8be94bffebde57af9bfc265b998',
    logoURI: 'https://tokens.1inch.io/0x18aaa7115705e8be94bffebde57af9bfc265b998.png',

    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'YFM',
    name: 'yfarm.finance',
    decimals: 18,
    address: '0xa665fed1b0c9da00e91ca582f77df36e325048c5',
    logoURI: 'https://tokens.1inch.io/0xa665fed1b0c9da00e91ca582f77df36e325048c5.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'ATRI',
    name: 'AtariToken',
    decimals: 0,
    address: '0xdacd69347de42babfaecd09dc88958378780fb62',
    logoURI: 'https://tokens.1inch.io/0xdacd69347de42babfaecd09dc88958378780fb62.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'INDEX',
    name: 'Index',
    decimals: 18,
    address: '0x0954906da0bf32d5479e25f46056d22f08464cab',
    logoURI: 'https://tokens.1inch.io/0x0954906da0bf32d5479e25f46056d22f08464cab.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'PTF',
    name: 'PowerTrade Fuel Token',
    decimals: 18,
    address: '0xc57d533c50bc22247d49a368880fb49a1caa39f7',
    logoURI: 'https://tokens.1inch.io/0xc57d533c50bc22247d49a368880fb49a1caa39f7.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'DFD',
    name: 'DefiDollar DAO',
    decimals: 18,
    address: '0x20c36f062a31865bed8a5b1e512d9a1a20aa333a',
    logoURI: 'https://tokens.1inch.io/0x20c36f062a31865bed8a5b1e512d9a1a20aa333a.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'APY',
    name: 'APY Governance Token',
    decimals: 18,
    address: '0x95a4492f028aa1fd432ea71146b433e7b4446611',
    logoURI: 'https://tokens.1inch.io/0x95a4492f028aa1fd432ea71146b433e7b4446611.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'GYSR',
    name: 'Geyser',
    decimals: 18,
    address: '0xbea98c05eeae2f3bc8c3565db7551eb738c8ccab',
    logoURI: 'https://tokens.1inch.io/0xbea98c05eeae2f3bc8c3565db7551eb738c8ccab_1.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'KP4R',
    name: 'Keep4r',
    decimals: 18,
    address: '0xa89ac6e529acf391cfbbd377f3ac9d93eae9664e',
    logoURI: 'https://tokens.1inch.io/0xa89ac6e529acf391cfbbd377f3ac9d93eae9664e.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'AXS',
    name: 'Axie Infinity Shard',
    decimals: 18,
    address: '0xbb0e17ef65f82ab018d8edd776e8dd940327b28b',
    logoURI: 'https://tokens.1inch.io/0xbb0e17ef65f82ab018d8edd776e8dd940327b28b.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'ALBT',
    name: 'AllianceBlock Token',
    decimals: 18,
    address: '0x00a8b738e453ffd858a7edf03bccfe20412f0eb0',
    logoURI: 'https://tokens.1inch.io/0x00a8b738e453ffd858a7edf03bccfe20412f0eb0.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'TOMOE',
    name: 'TomoChain',
    decimals: 18,
    address: '0x05d3606d5c81eb9b7b18530995ec9b29da05faba',
    logoURI: 'https://tokens.1inch.io/0x05d3606d5c81eb9b7b18530995ec9b29da05faba.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'LUA',
    name: 'LuaToken',
    decimals: 18,
    address: '0xb1f66997a5760428d3a87d68b90bfe0ae64121cc',
    logoURI: 'https://tokens.1inch.io/0xb1f66997a5760428d3a87d68b90bfe0ae64121cc.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'Yf-DAI',
    name: 'YfDAI.finance',
    decimals: 18,
    address: '0xf4cd3d3fda8d7fd6c5a500203e38640a70bf9577',
    logoURI: 'https://tokens.1inch.io/0xf4cd3d3fda8d7fd6c5a500203e38640a70bf9577.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'POLS',
    name: 'PolkastarterToken',
    decimals: 18,
    address: '0x83e6f1e41cdd28eaceb20cb649155049fac3d5aa',
    logoURI: 'https://tokens.1inch.io/0x83e6f1e41cdd28eaceb20cb649155049fac3d5aa.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'RFuel',
    name: 'Rio Fuel Token',
    decimals: 18,
    address: '0xaf9f549774ecedbd0966c52f250acc548d3f36e5',
    logoURI: 'https://tokens.1inch.io/0xaf9f549774ecedbd0966c52f250acc548d3f36e5.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'UFT',
    name: 'UniLend Finance Token',
    decimals: 18,
    address: '0x0202be363b8a4820f3f4de7faf5224ff05943ab1',
    logoURI: 'https://tokens.1inch.io/0x0202be363b8a4820f3f4de7faf5224ff05943ab1.png',

    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'LGCY',
    name: 'LGCY Network',
    decimals: 18,
    address: '0xae697f994fc5ebc000f8e22ebffee04612f98a0d',
    logoURI: 'https://tokens.1inch.io/0xae697f994fc5ebc000f8e22ebffee04612f98a0d.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: '$ROPE',
    name: '$ROPE',
    decimals: 18,
    address: '0x9d47894f8becb68b9cf3428d256311affe8b068b',
    logoURI: 'https://tokens.1inch.io/0x9d47894f8becb68b9cf3428d256311affe8b068b.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'PLOT',
    name: 'PLOT',
    decimals: 18,
    address: '0x72f020f8f3e8fd9382705723cd26380f8d0c66bb',
    logoURI: 'https://tokens.1inch.io/0x72f020f8f3e8fd9382705723cd26380f8d0c66bb.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'KEN',
    name: 'Kenysians Network',
    decimals: 18,
    address: '0x6a7ef4998eb9d0f706238756949f311a59e05745',
    logoURI: 'https://tokens.1inch.io/0x6a7ef4998eb9d0f706238756949f311a59e05745.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'Nsure',
    name: 'Nsure Network Token',
    decimals: 18,
    address: '0x20945ca1df56d237fd40036d47e866c7dccd2114',
    logoURI: 'https://tokens.1inch.io/0x20945ca1df56d237fd40036d47e866c7dccd2114.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'TIME',
    name: 'ChronoTech Token',
    decimals: 8,
    address: '0x485d17a6f1b8780392d53d64751824253011a260',
    logoURI: 'https://tokens.1inch.io/0x485d17a6f1b8780392d53d64751824253011a260.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'aYFIv1',
    name: 'Aave Interest bearing YFI',
    decimals: 18,
    address: '0x12e51e77daaa58aa0e9247db7510ea4b46f9bead',
    logoURI: 'https://tokens.1inch.io/0x12e51e77daaa58aa0e9247db7510ea4b46f9bead.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'aAAVEv1',
    name: 'Aave Interest bearing Aave Token',
    decimals: 18,
    address: '0xba3d9687cf50fe253cd2e1cfeede1d6787344ed5',
    logoURI: 'https://tokens.1inch.io/0xba3d9687cf50fe253cd2e1cfeede1d6787344ed5.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'aUNIv1',
    name: 'Aave Interest bearing Uniswap',
    decimals: 18,
    address: '0xb124541127a0a657f056d9dd06188c4f1b0e5aab',
    logoURI: 'https://tokens.1inch.io/0xb124541127a0a657f056d9dd06188c4f1b0e5aab.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'aENJv1',
    name: 'Aave Interest bearing ENJ',
    decimals: 18,
    address: '0x712db54daa836b53ef1ecbb9c6ba3b9efb073f40',
    logoURI: 'https://tokens.1inch.io/0x712db54daa836b53ef1ecbb9c6ba3b9efb073f40.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'SFI',
    name: 'Spice',
    decimals: 18,
    address: '0xb753428af26e81097e7fd17f40c88aaa3e04902c',
    logoURI: 'https://tokens.1inch.io/0xb753428af26e81097e7fd17f40c88aaa3e04902c.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'MPH',
    name: '88mph.app',
    decimals: 18,
    address: '0x8888801af4d980682e47f1a9036e589479e835c5',
    logoURI: 'https://tokens.1inch.io/0x8888801af4d980682e47f1a9036e589479e835c5.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'eXRD',
    name: 'E-RADIX',
    decimals: 18,
    address: '0x6468e79a80c0eab0f9a2b574c8d5bc374af59414',
    logoURI: 'https://tokens.1inch.io/0x6468e79a80c0eab0f9a2b574c8d5bc374af59414.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'BOOST',
    name: 'Boosted Finance',
    decimals: 18,
    address: '0x3e780920601d61cedb860fe9c4a90c9ea6a35e78',
    logoURI: 'https://tokens.1inch.io/0x3e780920601d61cedb860fe9c4a90c9ea6a35e78.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'DF',
    name: 'dForce',
    decimals: 18,
    address: '0x431ad2ff6a9c365805ebad47ee021148d6f7dbe0',
    logoURI: 'https://tokens.1inch.io/0x431ad2ff6a9c365805ebad47ee021148d6f7dbe0.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'LTO',
    name: 'LTO Network Token',
    decimals: 8,
    address: '0x3db6ba6ab6f95efed1a6e794cad492faaabf294d',
    logoURI: 'https://tokens.1inch.io/0x3db6ba6ab6f95efed1a6e794cad492faaabf294d.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'CGT',
    name: 'CACHE Gold',
    decimals: 8,
    address: '0xf5238462e7235c7b62811567e63dd17d12c2eaa0',
    logoURI: 'https://tokens.1inch.io/0xf5238462e7235c7b62811567e63dd17d12c2eaa0.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'NU',
    name: 'NuCypher',
    decimals: 18,
    address: '0x4fe83213d56308330ec302a8bd641f1d0113a4cc',
    logoURI: 'https://tokens.1inch.io/0x4fe83213d56308330ec302a8bd641f1d0113a4cc.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'OCT',
    name: 'Wrapped OCT',
    decimals: 8,
    address: '0x9e70240d2a8a30a592d3f076441c4f303b26de12',
    logoURI: 'https://tokens.1inch.io/0x9e70240d2a8a30a592d3f076441c4f303b26de12.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'QRX',
    name: 'QuiverX',
    decimals: 18,
    address: '0x6e0dade58d2d89ebbe7afc384e3e4f15b70b14d8',
    logoURI: 'https://tokens.1inch.io/0x6e0dade58d2d89ebbe7afc384e3e4f15b70b14d8.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'BTSG',
    name: 'BitSong',
    decimals: 18,
    address: '0x05079687d35b93538cbd59fe5596380cae9054a9',
    logoURI: 'https://tokens.1inch.io/0x05079687d35b93538cbd59fe5596380cae9054a9.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'aRENv1',
    name: 'Aave Interest bearing REN',
    decimals: 18,
    address: '0x69948cc03f478b95283f7dbf1ce764d0fc7ec54c',
    logoURI: 'https://tokens.1inch.io/0x69948cc03f478b95283f7dbf1ce764d0fc7ec54c.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'VAL',
    name: 'Sora Validator Token',
    decimals: 18,
    address: '0xe88f8313e61a97cec1871ee37fbbe2a8bf3ed1e4',
    logoURI: 'https://tokens.1inch.io/0xe88f8313e61a97cec1871ee37fbbe2a8bf3ed1e4.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'API3',
    name: 'API3',
    decimals: 18,
    address: '0x0b38210ea11411557c13457d4da7dc6ea731b88a',
    logoURI: 'https://tokens.1inch.io/0x0b38210ea11411557c13457d4da7dc6ea731b88a.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'BAC',
    name: 'BAC',
    decimals: 18,
    address: '0x3449fc1cd036255ba1eb19d65ff4ba2b8903a69a',
    logoURI: 'https://tokens.1inch.io/0x3449fc1cd036255ba1eb19d65ff4ba2b8903a69a.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'PIPT',
    name: 'Power Index Pool Token',
    decimals: 18,
    address: '0x26607ac599266b21d13c7acf7942c7701a8b699c',
    logoURI: 'https://tokens.1inch.io/0x26607ac599266b21d13c7acf7942c7701a8b699c.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'MEGA',
    name: 'MegaCryptoPolis $MEGA Token (MEGA)',
    decimals: 18,
    address: '0x3218a02f8f8b5c3894ce30eb255f10bcba13e654',
    logoURI: 'https://tokens.1inch.io/0x3218a02f8f8b5c3894ce30eb255f10bcba13e654.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'BASE',
    name: 'Base Protocol',
    decimals: 9,
    address: '0x07150e919b4de5fd6a63de1f9384828396f25fdc',
    logoURI: 'https://tokens.1inch.io/0x07150e919b4de5fd6a63de1f9384828396f25fdc.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'BONDLY',
    name: 'Bondly',
    decimals: 18,
    address: '0x91dfbee3965baaee32784c2d546b7a0c62f268c9',
    logoURI: 'https://tokens.1inch.io/0x91dfbee3965baaee32784c2d546b7a0c62f268c9.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'NEXO',
    name: 'Nexo',
    decimals: 18,
    address: '0xb62132e35a6c13ee1ee0f84dc5d40bad8d815206',
    logoURI: 'https://tokens.1inch.io/0xb62132e35a6c13ee1ee0f84dc5d40bad8d815206.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'aAAVE',
    name: 'Aave interest bearing AAVE',
    decimals: 18,
    address: '0xffc97d72e13e01096502cb8eb52dee56f74dad7b',
    logoURI: 'https://tokens.1inch.io/0xffc97d72e13e01096502cb8eb52dee56f74dad7b.png',

    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'aBAT',
    name: 'Aave interest bearing BAT',
    decimals: 18,
    address: '0x05ec93c0365baaeabf7aeffb0972ea7ecdd39cf1',
    logoURI: 'https://tokens.1inch.io/0x05ec93c0365baaeabf7aeffb0972ea7ecdd39cf1.png',

    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'aBUSD',
    name: 'Aave interest bearing BUSD',
    decimals: 18,
    address: '0xa361718326c15715591c299427c62086f69923d9',
    logoURI: 'https://tokens.1inch.io/0xa361718326c15715591c299427c62086f69923d9.png',

    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'aDAI',
    name: 'Aave interest bearing DAI',
    decimals: 18,
    address: '0x028171bca77440897b824ca71d1c56cac55b68a3',
    logoURI: 'https://tokens.1inch.io/0x028171bca77440897b824ca71d1c56cac55b68a3.png',

    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'aENJ',
    name: 'Aave interest bearing ENJ',
    decimals: 18,
    address: '0xac6df26a590f08dcc95d5a4705ae8abbc88509ef',
    logoURI: 'https://tokens.1inch.io/0xac6df26a590f08dcc95d5a4705ae8abbc88509ef.png',

    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'aKNC',
    name: 'Aave interest bearing KNC',
    decimals: 18,
    address: '0x39c6b3e42d6a679d7d776778fe880bc9487c2eda',
    logoURI: 'https://tokens.1inch.io/0x39c6b3e42d6a679d7d776778fe880bc9487c2eda.png',

    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'aLINK',
    name: 'Aave interest bearing LINK',
    decimals: 18,
    address: '0xa06bc25b5805d5f8d82847d191cb4af5a3e873e0',
    logoURI: 'https://tokens.1inch.io/0xa06bc25b5805d5f8d82847d191cb4af5a3e873e0.png',

    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'aMANA',
    name: 'Aave interest bearing MANA',
    decimals: 18,
    address: '0xa685a61171bb30d4072b338c80cb7b2c865c873e',
    logoURI: 'https://tokens.1inch.io/0xa685a61171bb30d4072b338c80cb7b2c865c873e.png',

    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'aMKR',
    name: 'Aave interest bearing MKR',
    decimals: 18,
    address: '0xc713e5e149d5d0715dcd1c156a020976e7e56b88',
    logoURI: 'https://tokens.1inch.io/0xc713e5e149d5d0715dcd1c156a020976e7e56b88.png',

    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'aREN',
    name: 'Aave Interest bearing REN',
    decimals: 18,
    address: '0xcc12abe4ff81c9378d670de1b57f8e0dd228d77a',
    logoURI: 'https://tokens.1inch.io/0xcc12abe4ff81c9378d670de1b57f8e0dd228d77a.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'aSNX',
    name: 'Aave interest bearing SNX',
    decimals: 18,
    address: '0x35f6b052c598d933d69a4eec4d04c73a191fe6c2',
    logoURI: 'https://tokens.1inch.io/0x35f6b052c598d933d69a4eec4d04c73a191fe6c2.png',

    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'aSUSD',
    name: 'Aave interest bearing SUSD',
    decimals: 18,
    address: '0x6c5024cd4f8a59110119c56f8933403a539555eb',
    logoURI: 'https://tokens.1inch.io/0x6c5024cd4f8a59110119c56f8933403a539555eb.png',

    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'aTUSD',
    name: 'Aave interest bearing TUSD',
    decimals: 18,
    address: '0x101cc05f4a51c0319f570d5e146a8c625198e636',
    logoURI: 'https://tokens.1inch.io/0x101cc05f4a51c0319f570d5e146a8c625198e636.png',

    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'aUNI',
    name: 'Aave interest bearing UNI',
    decimals: 18,
    address: '0xb9d7cb55f463405cdfbe4e90a6d2df01c2b92bf1',
    logoURI: 'https://tokens.1inch.io/0xb9d7cb55f463405cdfbe4e90a6d2df01c2b92bf1.png',

    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'aUSDC',
    name: 'Aave interest bearing USDC',
    decimals: 6,
    address: '0xbcca60bb61934080951369a648fb03df4f96263c',
    logoURI: 'https://tokens.1inch.io/0xbcca60bb61934080951369a648fb03df4f96263c.png',

    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'aUSDT',
    name: 'Aave interest bearing USDT',
    decimals: 6,
    address: '0x3ed3b47dd13ec9a98b44e6204a523e766b225811',
    logoURI: 'https://tokens.1inch.io/0x3ed3b47dd13ec9a98b44e6204a523e766b225811.png',

    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'aWBTC',
    name: 'Aave interest bearing WBTC',
    decimals: 8,
    address: '0x9ff58f4ffb29fa2266ab25e75e2a8b3503311656',
    logoURI: 'https://tokens.1inch.io/0x9ff58f4ffb29fa2266ab25e75e2a8b3503311656.png',

    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'aWETH',
    name: 'Aave interest bearing WETH',
    decimals: 18,
    address: '0x030ba81f1c18d280636f32af80b9aad02cf0854e',
    logoURI: 'https://tokens.1inch.io/0x030ba81f1c18d280636f32af80b9aad02cf0854e.png',

    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'aYFI',
    name: 'Aave interest bearing YFI',
    decimals: 18,
    address: '0x5165d24277cd063f5ac44efd447b27025e888f37',
    logoURI: 'https://tokens.1inch.io/0x5165d24277cd063f5ac44efd447b27025e888f37.png',

    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'aZRX',
    name: 'Aave interest bearing ZRX',
    decimals: 18,
    address: '0xdf7ff54aacacbff42dfe29dd6144a69b629f8c9e',
    logoURI: 'https://tokens.1inch.io/0xdf7ff54aacacbff42dfe29dd6144a69b629f8c9e.png',

    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'ECO',
    name: 'ECO TOKEN',
    decimals: 8,
    address: '0x191557728e4d8caa4ac94f86af842148c0fa8f7e',
    logoURI: 'https://tokens.1inch.io/0x191557728e4d8caa4ac94f86af842148c0fa8f7e.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'UTK',
    name: 'Utrust Token',
    decimals: 18,
    address: '0xdc9ac3c20d1ed0b540df9b1fedc10039df13f99c',
    logoURI: 'https://tokens.1inch.io/0xdc9ac3c20d1ed0b540df9b1fedc10039df13f99c.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'BADGER',
    name: 'Badger',
    decimals: 18,
    address: '0x3472a5a71965499acd81997a54bba8d852c6e53d',
    logoURI: 'https://tokens.1inch.io/0x3472a5a71965499acd81997a54bba8d852c6e53d.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'GRT',
    name: 'Graph Token',
    decimals: 18,
    address: '0xc944e90c64b2c07662a292be6244bdf05cda44a7',
    logoURI: 'https://tokens.1inch.io/0xc944e90c64b2c07662a292be6244bdf05cda44a7.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'LDO',
    name: 'Lido DAO Token',
    decimals: 18,
    address: '0x5a98fcbea516cf06857215779fd812ca3bef1b32',
    logoURI: 'https://tokens.1inch.io/0x5a98fcbea516cf06857215779fd812ca3bef1b32.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'stETH',
    name: 'stETH',
    decimals: 18,
    address: '0xae7ab96520de3a18e5e111b5eaab095312d7fe84',
    logoURI: 'https://tokens.1inch.io/0xae7ab96520de3a18e5e111b5eaab095312d7fe84.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'MAHA',
    name: 'MahaDAO',
    decimals: 18,
    address: '0xb4d930279552397bba2ee473229f89ec245bc365',
    logoURI: 'https://tokens.1inch.io/0xb4d930279552397bba2ee473229f89ec245bc365.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'POND',
    name: 'Marlin POND',
    decimals: 18,
    address: '0x57b946008913b82e4df85f501cbaed910e58d26c',
    logoURI: 'https://tokens.1inch.io/0x57b946008913b82e4df85f501cbaed910e58d26c.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'OMv2',
    name: 'MANTRA DAO',
    decimals: 18,
    address: '0x3593d125a4f7849a1b059e64f4517a86dd60c95d',
    logoURI: 'https://tokens.1inch.io/0x3593d125a4f7849a1b059e64f4517a86dd60c95d.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'FXS',
    name: 'Frax Share',
    decimals: 18,
    address: '0x3432b6a60d23ca0dfca7761b7ab56459d9c964d0',
    logoURI: 'https://tokens.1inch.io/0x3432b6a60d23ca0dfca7761b7ab56459d9c964d0.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: '1INCH',
    name: '1INCH Token',
    decimals: 18,
    address: '0x111111111117dc0aa78b770fa6a738034120c302',
    logoURI: 'https://tokens.1inch.io/0x111111111117dc0aa78b770fa6a738034120c302.png',

    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'PPAY',
    name: 'Plasma',
    decimals: 18,
    address: '0x054d64b73d3d8a21af3d764efd76bcaa774f3bb2',
    logoURI: 'https://tokens.1inch.io/0x054d64b73d3d8a21af3d764efd76bcaa774f3bb2.png',

    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'MIC',
    name: 'MIC',
    decimals: 18,
    address: '0x368b3a58b5f49392e5c9e4c998cb0bb966752e51',
    logoURI: 'https://tokens.1inch.io/0x368b3a58b5f49392e5c9e4c998cb0bb966752e51.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'MIS',
    name: 'MIS',
    decimals: 18,
    address: '0x4b4d2e899658fb59b1d518b68fe836b100ee8958',
    logoURI: 'https://tokens.1inch.io/0x4b4d2e899658fb59b1d518b68fe836b100ee8958.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'XED',
    name: 'Exeedme',
    decimals: 18,
    address: '0xee573a945b01b788b9287ce062a0cfc15be9fd86',
    logoURI: 'https://tokens.1inch.io/0xee573a945b01b788b9287ce062a0cfc15be9fd86.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'WOZX',
    name: 'EFFORCE IEO',
    decimals: 18,
    address: '0x34950ff2b487d9e5282c5ab342d08a2f712eb79f',
    logoURI: 'https://tokens.1inch.io/0x34950ff2b487d9e5282c5ab342d08a2f712eb79f.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'DSD',
    name: 'Dynamic Set Dollar',
    decimals: 18,
    address: '0xbd2f0cd039e0bfcf88901c98c0bfac5ab27566e3',
    logoURI: 'https://tokens.1inch.io/0xbd2f0cd039e0bfcf88901c98c0bfac5ab27566e3.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'FOX',
    name: 'FOX',
    decimals: 18,
    address: '0xc770eefad204b5180df6a14ee197d99d808ee52d',
    logoURI: 'https://tokens.1inch.io/0xc770eefad204b5180df6a14ee197d99d808ee52d.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'COVER',
    name: 'Cover Protocol Governance Token',
    decimals: 18,
    address: '0x4688a8b1f292fdab17e9a90c8bc379dc1dbd8713',
    logoURI: 'https://tokens.1inch.io/0x4688a8b1f292fdab17e9a90c8bc379dc1dbd8713.png',

    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'WISE',
    name: 'Wise Token',
    decimals: 18,
    address: '0x66a0f676479cee1d7373f3dc2e2952778bff5bd6',
    logoURI: 'https://tokens.1inch.io/0x66a0f676479cee1d7373f3dc2e2952778bff5bd6.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'COMBO',
    name: 'Furucombo',
    decimals: 18,
    address: '0xffffffff2ba8f66d4e51811c5190992176930278',
    logoURI: 'https://tokens.1inch.io/0xffffffff2ba8f66d4e51811c5190992176930278.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'USDFL',
    name: 'USDFreeLiquidity',
    decimals: 18,
    address: '0x2b4200a8d373d484993c37d63ee14aee0096cd12',
    logoURI: 'https://tokens.1inch.io/0x2b4200a8d373d484993c37d63ee14aee0096cd12.png',

    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'FET',
    name: 'Fetch',
    decimals: 18,
    address: '0xaea46a60368a7bd060eec7df8cba43b7ef41ad85',
    logoURI: 'https://tokens.1inch.io/0xaea46a60368a7bd060eec7df8cba43b7ef41ad85.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'PHA',
    name: 'Phala',
    decimals: 18,
    address: '0x6c5ba91642f10282b576d91922ae6448c9d52f4e',
    logoURI: 'https://tokens.1inch.io/0x6c5ba91642f10282b576d91922ae6448c9d52f4e.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'pBTC35A',
    name: 'POW BTC-35W/T',
    decimals: 18,
    address: '0xa8b12cc90abf65191532a12bb5394a714a46d358',
    logoURI: 'https://tokens.1inch.io/0xa8b12cc90abf65191532a12bb5394a714a46d358.png',

    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'FRAX',
    name: 'Frax',
    decimals: 18,
    address: '0x853d955acef822db058eb8505911ed77f175b99e',
    logoURI: 'https://tokens.1inch.io/0x853d955acef822db058eb8505911ed77f175b99e.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'INJ',
    name: 'Injective Token',
    decimals: 18,
    address: '0xe28b3b32b6c345a34ff64674606124dd5aceca30',
    logoURI: 'https://tokens.1inch.io/0xe28b3b32b6c345a34ff64674606124dd5aceca30.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'YLD_APP',
    name: 'Yield',
    decimals: 18,
    address: '0xf94b5c5651c888d928439ab6514b93944eee6f48',
    logoURI: 'https://tokens.1inch.io/0xf94b5c5651c888d928439ab6514b93944eee6f48.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'CFi',
    name: 'CyberFi Token',
    decimals: 18,
    address: '0x63b4f3e3fa4e438698ce330e365e831f7ccd1ef4',
    logoURI: 'https://tokens.1inch.io/0x63b4f3e3fa4e438698ce330e365e831f7ccd1ef4.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'RGT',
    name: 'Rari Governance Token',
    decimals: 18,
    address: '0xd291e7a03283640fdc51b121ac401383a46cc623',
    logoURI: 'https://tokens.1inch.io/0xd291e7a03283640fdc51b121ac401383a46cc623.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'ROOK',
    name: 'ROOK',
    decimals: 18,
    address: '0xfa5047c9c78b8877af97bdcb85db743fd7313d4a',
    logoURI: 'https://tokens.1inch.io/0xfa5047c9c78b8877af97bdcb85db743fd7313d4a.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'YOP',
    name: 'YOP',
    decimals: 8,
    address: '0xae1eaae3f627aaca434127644371b67b18444051',
    logoURI: 'https://tokens.1inch.io/0xae1eaae3f627aaca434127644371b67b18444051.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'NFTX',
    name: 'NFTX',
    decimals: 18,
    address: '0x87d73e916d7057945c9bcd8cdd94e42a6f47f776',
    logoURI: 'https://tokens.1inch.io/0x87d73e916d7057945c9bcd8cdd94e42a6f47f776.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'RBC',
    name: 'Rubic',
    decimals: 18,
    address: '0xa4eed63db85311e22df4473f87ccfc3dadcfa3e3',
    logoURI: 'https://tokens.1inch.io/0xa4eed63db85311e22df4473f87ccfc3dadcfa3e3.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'SDT',
    name: 'Stake DAO Token',
    decimals: 18,
    address: '0x73968b9a57c6e53d41345fd57a6e6ae27d6cdb2f',
    logoURI: 'https://tokens.1inch.io/0x73968b9a57c6e53d41345fd57a6e6ae27d6cdb2f.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'DEBASE',
    name: 'Debase',
    decimals: 18,
    address: '0x9248c485b0b80f76da451f167a8db30f33c70907',
    logoURI: 'https://tokens.1inch.io/0x9248c485b0b80f76da451f167a8db30f33c70907.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'ANKR',
    name: 'Ankr Network',
    decimals: 18,
    address: '0x8290333cef9e6d528dd5618fb97a76f268f3edd4',
    logoURI: 'https://tokens.1inch.io/0x8290333cef9e6d528dd5618fb97a76f268f3edd4.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'DIP_Insurance',
    name: 'Decentralized Insurance Protocol',
    decimals: 18,
    address: '0xc719d010b63e5bbf2c0551872cd5316ed26acd83',
    logoURI: 'https://tokens.1inch.io/0xc719d010b63e5bbf2c0551872cd5316ed26acd83.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'RUNE',
    name: 'THORChain ETH.RUNE',
    decimals: 18,
    address: '0x3155ba85d5f96b2d030a4966af206230e46849cb',
    logoURI: 'https://tokens.1inch.io/0x3155ba85d5f96b2d030a4966af206230e46849cb.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'BAO',
    name: 'BaoToken',
    decimals: 18,
    address: '0x374cb8c27130e2c9e04f44303f3c8351b9de61c1',
    logoURI: 'https://tokens.1inch.io/0x374cb8c27130e2c9e04f44303f3c8351b9de61c1.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'REEF',
    name: 'Reef.finance',
    decimals: 18,
    address: '0xfe3e6a25e6b192a42a44ecddcd13796471735acf',
    logoURI: 'https://tokens.1inch.io/0xfe3e6a25e6b192a42a44ecddcd13796471735acf.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'TRU',
    name: 'TrustToken',
    decimals: 8,
    address: '0x4c19596f5aaff459fa38b0f7ed92f11ae6543784',
    logoURI: 'https://tokens.1inch.io/0x4c19596f5aaff459fa38b0f7ed92f11ae6543784.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'NDX',
    name: 'Indexed',
    decimals: 18,
    address: '0x86772b1409b61c639eaac9ba0acfbb6e238e5f83',
    logoURI: 'https://tokens.1inch.io/0x86772b1409b61c639eaac9ba0acfbb6e238e5f83.png',

    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'MARK',
    name: 'Benchmark',
    decimals: 9,
    address: '0x67c597624b17b16fb77959217360b7cd18284253',
    logoURI: 'https://tokens.1inch.io/0x67c597624b17b16fb77959217360b7cd18284253.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'ZERO',
    name: 'Zero.Exchange Token',
    decimals: 18,
    address: '0xf0939011a9bb95c3b791f0cb546377ed2693a574',
    logoURI: 'https://tokens.1inch.io/0xf0939011a9bb95c3b791f0cb546377ed2693a574.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'OCTO',
    name: 'Octo.fi',
    decimals: 18,
    address: '0x7240ac91f01233baaf8b064248e80feaa5912ba3',
    logoURI: 'https://tokens.1inch.io/0x7240ac91f01233baaf8b064248e80feaa5912ba3.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'ORAI',
    name: 'Oraichain Token',
    decimals: 18,
    address: '0x4c11249814f11b9346808179cf06e71ac328c1b5',
    logoURI: 'https://tokens.1inch.io/0x4c11249814f11b9346808179cf06e71ac328c1b5.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'DDIM',
    name: 'DuckDaoDime',
    decimals: 18,
    address: '0xfbeea1c75e4c4465cb2fccc9c6d6afe984558e20',
    logoURI: 'https://tokens.1inch.io/0xfbeea1c75e4c4465cb2fccc9c6d6afe984558e20.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'BIRD',
    name: 'Bird.Money',
    decimals: 18,
    address: '0x70401dfd142a16dc7031c56e862fc88cb9537ce0',
    logoURI: 'https://tokens.1inch.io/0x70401dfd142a16dc7031c56e862fc88cb9537ce0.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: '$ANRX',
    name: 'AnRKey X',
    decimals: 18,
    address: '0xcae72a7a0fd9046cf6b165ca54c9e3a3872109e0',
    logoURI: 'https://tokens.1inch.io/0xcae72a7a0fd9046cf6b165ca54c9e3a3872109e0.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'SPC',
    name: 'SpaceChainV2',
    decimals: 18,
    address: '0x86ed939b500e121c0c5f493f399084db596dad20',
    logoURI: 'https://tokens.1inch.io/0x86ed939b500e121c0c5f493f399084db596dad20.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'RAMP',
    name: 'RAMP DEFI',
    decimals: 18,
    address: '0x33d0568941c0c64ff7e0fb4fba0b11bd37deed9f',
    logoURI: 'https://tokens.1inch.io/0x33d0568941c0c64ff7e0fb4fba0b11bd37deed9f.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'STBZ',
    name: 'Stabilize Token',
    decimals: 18,
    address: '0xb987d48ed8f2c468d52d6405624eadba5e76d723',
    logoURI: 'https://tokens.1inch.io/0xb987d48ed8f2c468d52d6405624eadba5e76d723.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'INFI',
    name: 'INFI',
    decimals: 18,
    address: '0x159751323a9e0415dd3d6d42a1212fe9f4a0848c',
    logoURI: 'https://tokens.1inch.io/0x159751323a9e0415dd3d6d42a1212fe9f4a0848c.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'CRO',
    name: 'CRO',
    decimals: 8,
    address: '0xa0b73e1ff0b80914ab6fe0444e65848c4c34450b',
    logoURI: 'https://tokens.1inch.io/0xa0b73e1ff0b80914ab6fe0444e65848c4c34450b.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'LYXe',
    name: 'LUKSO Token',
    decimals: 18,
    address: '0xa8b919680258d369114910511cc87595aec0be6d',
    logoURI: 'https://tokens.1inch.io/0xa8b919680258d369114910511cc87595aec0be6d.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'TVK',
    name: 'Terra Virtua Kolect',
    decimals: 18,
    address: '0xd084b83c305dafd76ae3e1b4e1f1fe2ecccb3988',
    logoURI: 'https://tokens.1inch.io/0xd084b83c305dafd76ae3e1b4e1f1fe2ecccb3988.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'DIGG',
    name: 'Digg',
    decimals: 9,
    address: '0x798d1be841a82a273720ce31c822c61a67a601c3',
    logoURI: 'https://tokens.1inch.io/0x798d1be841a82a273720ce31c822c61a67a601c3.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'ALPHA',
    name: 'AlphaToken',
    decimals: 18,
    address: '0xa1faa113cbe53436df28ff0aee54275c13b40975',
    logoURI: 'https://tokens.1inch.io/0xa1faa113cbe53436df28ff0aee54275c13b40975_1.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'CUDOS',
    name: 'CudosToken',
    decimals: 18,
    address: '0x817bbdbc3e8a1204f3691d14bb44992841e3db35',
    logoURI: 'https://tokens.1inch.io/0x817bbdbc3e8a1204f3691d14bb44992841e3db35.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'DEXE',
    name: 'Dexe',
    decimals: 18,
    address: '0xde4ee8057785a7e8e800db58f9784845a5c2cbd6',
    logoURI: 'https://tokens.1inch.io/0xde4ee8057785a7e8e800db58f9784845a5c2cbd6.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'SAND',
    name: 'SAND',
    decimals: 18,
    address: '0x3845badade8e6dff049820680d1f14bd3903a5d0',
    logoURI: 'https://tokens.1inch.io/0x3845badade8e6dff049820680d1f14bd3903a5d0.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'CVR',
    name: 'PolkaCover',
    decimals: 18,
    address: '0x3c03b4ec9477809072ff9cc9292c9b25d4a8e6c6',
    logoURI: 'https://tokens.1inch.io/0x3c03b4ec9477809072ff9cc9292c9b25d4a8e6c6.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'FRM',
    name: 'Ferrum Network Token',
    decimals: 6,
    address: '0xe5caef4af8780e59df925470b050fb23c43ca68c',
    logoURI: 'https://tokens.1inch.io/0xe5caef4af8780e59df925470b050fb23c43ca68c.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'PBR',
    name: 'PolkaBridge',
    decimals: 18,
    address: '0x298d492e8c1d909d3f63bc4a36c66c64acb3d695',
    logoURI: 'https://tokens.1inch.io/0x298d492e8c1d909d3f63bc4a36c66c64acb3d695.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'SNOW',
    name: 'SnowSwap',
    decimals: 18,
    address: '0xfe9a29ab92522d14fc65880d817214261d8479ae',
    logoURI: 'https://tokens.1inch.io/0xfe9a29ab92522d14fc65880d817214261d8479ae.png',

    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'DIS',
    name: 'TosDis',
    decimals: 18,
    address: '0x220b71671b649c03714da9c621285943f3cbcdc6',
    logoURI: 'https://tokens.1inch.io/0x220b71671b649c03714da9c621285943f3cbcdc6.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'POOLZ',
    name: '$Poolz Finance',
    decimals: 18,
    address: '0x69a95185ee2a045cdc4bcd1b1df10710395e4e23',
    logoURI: 'https://tokens.1inch.io/0x69a95185ee2a045cdc4bcd1b1df10710395e4e23.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'ZKS',
    name: 'Zks',
    decimals: 18,
    address: '0xe4815ae53b124e7263f08dcdbbb757d41ed658c6',
    logoURI: 'https://tokens.1inch.io/0xe4815ae53b124e7263f08dcdbbb757d41ed658c6.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'ARMOR',
    name: 'Armor',
    decimals: 18,
    address: '0x1337def16f9b486faed0293eb623dc8395dfe46a',
    logoURI: 'https://tokens.1inch.io/0x1337def16f9b486faed0293eb623dc8395dfe46a.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'arNXM',
    name: 'Armor NXM',
    decimals: 18,
    address: '0x1337def18c680af1f9f45cbcab6309562975b1dd',
    logoURI: 'https://tokens.1inch.io/0x1337def18c680af1f9f45cbcab6309562975b1dd.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'OPIUM',
    name: 'Opium Governance Token',
    decimals: 18,
    address: '0x888888888889c00c67689029d7856aac1065ec11',
    logoURI: 'https://tokens.1inch.io/0x888888888889c00c67689029d7856aac1065ec11.png',

    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'HH',
    name: 'Holyheld',
    decimals: 18,
    address: '0x3fa729b4548becbad4eab6ef18413470e6d5324c',
    logoURI: 'https://tokens.1inch.io/0x3fa729b4548becbad4eab6ef18413470e6d5324c.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'YETI',
    name: 'Yearn Ecosystem Token Index',
    decimals: 18,
    address: '0xb4bebd34f6daafd808f73de0d10235a92fbb6c3d',
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7939.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'DYP',
    name: 'DeFiYieldProtocol',
    decimals: 18,
    address: '0x961c8c0b1aad0c0b10a51fef6a867e3091bcef17',
    logoURI: 'https://tokens.1inch.io/0x961c8c0b1aad0c0b10a51fef6a867e3091bcef17.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'aETHc',
    name: 'Ankr Eth2 Reward Bearing Bond',
    decimals: 18,
    address: '0xe95a203b1a91a908f9b9ce46459d101078c2c3cb',
    logoURI: 'https://tokens.1inch.io/0xe95a203b1a91a908f9b9ce46459d101078c2c3cb.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'FTM',
    name: 'Fantom Token',
    decimals: 18,
    address: '0x4e15361fd6b4bb609fa63c81a2be19d873717870',
    logoURI: 'https://tokens.1inch.io/0x4e15361fd6b4bb609fa63c81a2be19d873717870.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'PROS',
    name: 'Prosper',
    decimals: 18,
    address: '0x8642a849d0dcb7a15a974794668adcfbe4794b56',
    logoURI: 'https://tokens.1inch.io/0x8642a849d0dcb7a15a974794668adcfbe4794b56.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'RFI',
    name: 'reflect.finance',
    decimals: 9,
    address: '0xa1afffe3f4d611d252010e3eaf6f4d77088b0cd7',
    logoURI: 'https://tokens.1inch.io/0xa1afffe3f4d611d252010e3eaf6f4d77088b0cd7.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'UST',
    name: 'Wrapped UST Token',
    decimals: 18,
    address: '0xa47c8bf37f92abed4a126bda807a7b7498661acd',
    logoURI: 'https://tokens.1inch.io/0xa47c8bf37f92abed4a126bda807a7b7498661acd.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'renDOGE',
    name: 'renDOGE',
    decimals: 8,
    address: '0x3832d2f059e55934220881f831be501d180671a7',
    logoURI: 'https://tokens.1inch.io/0x3832d2f059e55934220881f831be501d180671a7.png',

    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'MIR',
    name: 'Wrapped MIR Token',
    decimals: 18,
    address: '0x09a3ecafa817268f77be1283176b946c4ff2e608',
    logoURI: 'https://tokens.1inch.io/0x09a3ecafa817268f77be1283176b946c4ff2e608.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'CHART',
    name: 'ChartEx',
    decimals: 18,
    address: '0x1d37986f252d0e349522ea6c3b98cb935495e63e',
    logoURI: 'https://tokens.1inch.io/0x1d37986f252d0e349522ea6c3b98cb935495e63e.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'BMI',
    name: 'Bridge Mutual',
    decimals: 18,
    address: '0x725c263e32c72ddc3a19bea12c5a0479a81ee688',
    logoURI: 'https://tokens.1inch.io/0x725c263e32c72ddc3a19bea12c5a0479a81ee688.png',

    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'LON',
    name: 'Tokenlon',
    decimals: 18,
    address: '0x0000000000095413afc295d19edeb1ad7b71c952',
    logoURI: 'https://tokens.1inch.io/0x0000000000095413afc295d19edeb1ad7b71c952.png',

    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'ARCH',
    name: 'Archer DAO Governance Token',
    decimals: 18,
    address: '0x1f3f9d3068568f8040775be2e8c03c103c61f3af',
    logoURI: 'https://tokens.1inch.io/0x1f3f9d3068568f8040775be2e8c03c103c61f3af.png',

    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'LTX',
    name: 'Lattice Token',
    decimals: 8,
    address: '0xa393473d64d2f9f026b60b6df7859a689715d092',
    logoURI: 'https://tokens.1inch.io/0xa393473d64d2f9f026b60b6df7859a689715d092.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'ONX',
    name: 'OnX.finance',
    decimals: 18,
    address: '0xe0ad1806fd3e7edf6ff52fdb822432e847411033',
    logoURI: 'https://tokens.1inch.io/0xe0ad1806fd3e7edf6ff52fdb822432e847411033.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'BiFi',
    name: 'BiFi',
    decimals: 18,
    address: '0x2791bfd60d232150bff86b39b7146c0eaaa2ba81',
    logoURI: 'https://tokens.1inch.io/0x2791bfd60d232150bff86b39b7146c0eaaa2ba81.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'FIS',
    name: 'StaFi',
    decimals: 18,
    address: '0xef3a930e1ffffacd2fc13434ac81bd278b0ecc8d',
    logoURI: 'https://tokens.1inch.io/0xef3a930e1ffffacd2fc13434ac81bd278b0ecc8d.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'LINA',
    name: 'Linear Token',
    decimals: 18,
    address: '0x3e9bc21c9b189c09df3ef1b824798658d5011937',
    logoURI: 'https://tokens.1inch.io/0x3e9bc21c9b189c09df3ef1b824798658d5011937.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'GNYerc20',
    name: 'GNYerc20',
    decimals: 18,
    address: '0xb1f871ae9462f1b2c6826e88a7827e76f86751d4',
    logoURI: 'https://tokens.1inch.io/0xb1f871ae9462f1b2c6826e88a7827e76f86751d4.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'OIN',
    name: 'oinfinance',
    decimals: 8,
    address: '0x9aeb50f542050172359a0e1a25a9933bc8c01259',
    logoURI: 'https://tokens.1inch.io/0x9aeb50f542050172359a0e1a25a9933bc8c01259.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'xINCHa',
    name: 'xINCH',
    decimals: 18,
    address: '0x8f6a193c8b3c949e1046f1547c3a3f0836944e4b',
    logoURI: 'https://tokens.1inch.io/0x8f6a193c8b3c949e1046f1547c3a3f0836944e4b.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'CWS',
    name: 'Crowns',
    decimals: 18,
    address: '0xac0104cca91d167873b8601d2e71eb3d4d8c33e0',
    logoURI: 'https://tokens.1inch.io/0xac0104cca91d167873b8601d2e71eb3d4d8c33e0.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'SHIB',
    name: 'SHIBA INU',
    decimals: 18,
    address: '0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce',
    logoURI: 'https://tokens.1inch.io/0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'PRT',
    name: 'Portion Token',
    decimals: 18,
    address: '0x6d0f5149c502faf215c89ab306ec3e50b15e2892',
    logoURI: 'https://tokens.1inch.io/0x6d0f5149c502faf215c89ab306ec3e50b15e2892.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'NCT',
    name: 'NameChangeToken',
    decimals: 18,
    address: '0x8a9c4dfe8b9d8962b31e4e16f8321c44d48e246e',
    logoURI: 'https://tokens.1inch.io/0x8a9c4dfe8b9d8962b31e4e16f8321c44d48e246e.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'MUSE',
    name: 'Muse',
    decimals: 18,
    address: '0xb6ca7399b4f9ca56fc27cbff44f4d2e4eef1fc81',
    logoURI: 'https://tokens.1inch.io/0xb6ca7399b4f9ca56fc27cbff44f4d2e4eef1fc81.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'MAPS',
    name: 'Maps.me Token',
    decimals: 6,
    address: '0x2b915b505c017abb1547aa5ab355fbe69865cc6d',
    logoURI: 'https://tokens.1inch.io/0x2b915b505c017abb1547aa5ab355fbe69865cc6d.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'GUM',
    name: 'GourmetGalaxy',
    decimals: 18,
    address: '0x4f5fa8f2d12e5eb780f6082dd656c565c48e0f24',
    logoURI: 'https://tokens.1inch.io/0x4f5fa8f2d12e5eb780f6082dd656c565c48e0f24.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'TXL',
    name: 'Tixl Token',
    decimals: 18,
    address: '0x8eef5a82e6aa222a60f009ac18c24ee12dbf4b41',
    logoURI: 'https://tokens.1inch.io/0x8eef5a82e6aa222a60f009ac18c24ee12dbf4b41.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'RAZOR',
    name: 'RAZOR',
    decimals: 18,
    address: '0x50de6856358cc35f3a9a57eaaa34bd4cb707d2cd',
    logoURI: 'https://tokens.1inch.io/0x50de6856358cc35f3a9a57eaaa34bd4cb707d2cd.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: '$TRDL',
    name: 'Strudel Finance',
    decimals: 18,
    address: '0x297d33e17e61c2ddd812389c2105193f8348188a',
    logoURI: 'https://tokens.1inch.io/0x297d33e17e61c2ddd812389c2105193f8348188a.png',

    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'YVS',
    name: 'YVS.Finance',
    decimals: 18,
    address: '0xec681f28f4561c2a9534799aa38e0d36a83cf478',
    logoURI: 'https://tokens.1inch.io/0xec681f28f4561c2a9534799aa38e0d36a83cf478.png',
    chainId: SupportedChainId.ETHEREUM,
  },

  {
    symbol: 'SASHIMI',
    name: 'SashimiToken',
    decimals: 18,
    address: '0xc28e27870558cf22add83540d2126da2e4b464c2',
    logoURI: 'https://tokens.1inch.io/0xc28e27870558cf22add83540d2126da2e4b464c2.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'HGET',
    name: 'Hedget',
    decimals: 6,
    address: '0x7968bc6a03017ea2de509aaa816f163db0f35148',
    logoURI: 'https://tokens.1inch.io/0x7968bc6a03017ea2de509aaa816f163db0f35148.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'GALA',
    name: 'Gala',
    decimals: 8,
    address: '0x15d4c048f83bd7e37d49ea4c83a07267ec4203da',
    logoURI: 'https://tokens.1inch.io/0x15d4c048f83bd7e37d49ea4c83a07267ec4203da.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'SHARE',
    name: 'Seigniorage Shares',
    decimals: 9,
    address: '0x39795344cbcc76cc3fb94b9d1b15c23c2070c66d',
    logoURI: 'https://tokens.1inch.io/0x39795344cbcc76cc3fb94b9d1b15c23c2070c66d.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'UNISTAKE',
    name: 'Unistake',
    decimals: 18,
    address: '0x9ed8e7c9604790f7ec589f99b94361d8aab64e5e',
    logoURI: 'https://tokens.1inch.io/0x9ed8e7c9604790f7ec589f99b94361d8aab64e5e.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'AZUKI',
    name: 'DokiDokiAzuki',
    decimals: 18,
    address: '0x910524678c0b1b23ffb9285a81f99c29c11cbaed',
    logoURI: 'https://tokens.1inch.io/0x910524678c0b1b23ffb9285a81f99c29c11cbaed.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'DEXTF',
    name: 'DEXTF Token',
    decimals: 18,
    address: '0x5f64ab1544d28732f0a24f4713c2c8ec0da089f0',
    logoURI: 'https://tokens.1inch.io/0x5f64ab1544d28732f0a24f4713c2c8ec0da089f0.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'MP3',
    name: 'mp3',
    decimals: 18,
    address: '0x018fb5af9d015af25592a014c4266a84143de7a0',
    logoURI: 'https://tokens.1inch.io/0x018fb5af9d015af25592a014c4266a84143de7a0.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'LUNA',
    name: 'Wrapped LUNA Token',
    decimals: 18,
    address: '0xd2877702675e6ceb975b4a1dff9fb7baf4c91ea9',
    logoURI: 'https://tokens.1inch.io/0xd2877702675e6ceb975b4a1dff9fb7baf4c91ea9.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'SYNC',
    name: 'SYNC',
    decimals: 18,
    address: '0xb6ff96b8a8d214544ca0dbc9b33f7ad6503efd32',
    logoURI: 'https://tokens.1inch.io/0xb6ff96b8a8d214544ca0dbc9b33f7ad6503efd32.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'BOTS',
    name: 'Bot Ocean',
    decimals: 18,
    address: '0xf9fbe825bfb2bf3e387af0dc18cac8d87f29dea8',
    logoURI: 'https://tokens.1inch.io/0xf9fbe825bfb2bf3e387af0dc18cac8d87f29dea8.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'Mars',
    name: 'MarsToken',
    decimals: 18,
    address: '0x66c0dded8433c9ea86c8cf91237b14e10b4d70b7',
    logoURI: 'https://tokens.1inch.io/0x66c0dded8433c9ea86c8cf91237b14e10b4d70b7.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'LOOT',
    name: 'NFTLootBox.com',
    decimals: 18,
    address: '0x7b3d36eb606f873a75a6ab68f8c999848b04f935',
    logoURI: 'https://tokens.1inch.io/0x7b3d36eb606f873a75a6ab68f8c999848b04f935.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'DUCK',
    name: 'DLP Duck Token',
    decimals: 18,
    address: '0xc0ba369c8db6eb3924965e5c4fd0b4c1b91e305f',
    logoURI: 'https://tokens.1inch.io/0xc0ba369c8db6eb3924965e5c4fd0b4c1b91e305f.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'BTMX',
    name: 'BitMax token',
    decimals: 18,
    address: '0xcca0c9c383076649604ee31b20248bc04fdf61ca',
    logoURI: 'https://tokens.1inch.io/0xcca0c9c383076649604ee31b20248bc04fdf61ca.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'ID',
    name: 'Everest ID',
    decimals: 18,
    address: '0xebd9d99a3982d547c5bb4db7e3b1f9f14b67eb83',
    logoURI: 'https://tokens.1inch.io/0xebd9d99a3982d547c5bb4db7e3b1f9f14b67eb83.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'SKL',
    name: 'SKALE',
    decimals: 18,
    address: '0x00c83aecc790e8a4453e5dd3b0b4b3680501a7a7',
    logoURI: 'https://tokens.1inch.io/0x00c83aecc790e8a4453e5dd3b0b4b3680501a7a7.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'DAO',
    name: 'DAO Maker',
    decimals: 18,
    address: '0x0f51bb10119727a7e5ea3538074fb341f56b09ad',
    logoURI: 'https://tokens.1inch.io/0x0f51bb10119727a7e5ea3538074fb341f56b09ad.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'BT',
    name: 'BT.Finance',
    decimals: 18,
    address: '0x76c5449f4950f6338a393f53cda8b53b0cd3ca3a',
    logoURI: 'https://tokens.1inch.io/0x76c5449f4950f6338a393f53cda8b53b0cd3ca3a.png',

    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'BFLY',
    name: 'Butterfly Protocol Governance Token',
    decimals: 18,
    address: '0xf680429328caaacabee69b7a9fdb21a71419c063',
    logoURI: 'https://tokens.1inch.io/0xf680429328caaacabee69b7a9fdb21a71419c063.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'UMB',
    name: 'Umbrella',
    decimals: 18,
    address: '0x6fc13eace26590b80cccab1ba5d51890577d83b2',
    logoURI: 'https://tokens.1inch.io/0x6fc13eace26590b80cccab1ba5d51890577d83b2.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'USDP_2',
    name: 'USDP Stablecoin',
    decimals: 18,
    address: '0x1456688345527be1f37e9e627da0837d6f08c925',
    logoURI: 'https://tokens.1inch.io/0x1456688345527be1f37e9e627da0837d6f08c925.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'SOCKS',
    name: 'Unisocks Edition 0',
    decimals: 18,
    address: '0x23b608675a2b2fb1890d3abbd85c5775c51691d5',
    logoURI: 'https://tokens.1inch.io/0x23b608675a2b2fb1890d3abbd85c5775c51691d5.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'MFI',
    name: 'MarginSwap',
    decimals: 18,
    address: '0xaa4e3edb11afa93c41db59842b29de64b72e355b',
    logoURI: 'https://tokens.1inch.io/0xaa4e3edb11afa93c41db59842b29de64b72e355b.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'EVN',
    name: 'Evn Token',
    decimals: 18,
    address: '0x9af15d7b8776fa296019979e70a5be53c714a7ec',
    logoURI: 'https://tokens.1inch.io/0x9af15d7b8776fa296019979e70a5be53c714a7ec.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'KBTC',
    name: 'KBTC',
    decimals: 18,
    address: '0xe6c3502997f97f9bde34cb165fbce191065e068f',
    logoURI: 'https://tokens.1inch.io/0xe6c3502997f97f9bde34cb165fbce191065e068f.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'OPEN',
    name: 'BBB',
    decimals: 18,
    address: '0x69e8b9528cabda89fe846c67675b5d73d463a916',
    logoURI: 'https://tokens.1inch.io/0x69e8b9528cabda89fe846c67675b5d73d463a916.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'CTASK',
    name: 'CTASK Token',
    decimals: 18,
    address: '0x196c81385bc536467433014042788eb707703934',
    logoURI: 'https://tokens.1inch.io/0x196c81385bc536467433014042788eb707703934.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'NUX',
    name: 'NUX Peanut.trade',
    decimals: 18,
    address: '0x89bd2e7e388fab44ae88bef4e1ad12b4f1e0911c',
    logoURI: 'https://tokens.1inch.io/0x89bd2e7e388fab44ae88bef4e1ad12b4f1e0911c.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'DEPAY',
    name: 'DePay',
    decimals: 18,
    address: '0xa0bed124a09ac2bd941b10349d8d224fe3c955eb',
    logoURI: 'https://tokens.1inch.io/0xa0bed124a09ac2bd941b10349d8d224fe3c955eb.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'ZCN',
    name: '0chain',
    decimals: 10,
    address: '0xb9ef770b6a5e12e45983c5d80545258aa38f3b78',
    logoURI: 'https://tokens.1inch.io/0xb9ef770b6a5e12e45983c5d80545258aa38f3b78.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'UNCX',
    name: 'UniCrypt',
    decimals: 18,
    address: '0xadb2437e6f65682b85f814fbc12fec0508a7b1d0',
    logoURI: 'https://tokens.1inch.io/0xadb2437e6f65682b85f814fbc12fec0508a7b1d0.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'WARP',
    name: 'Warp Token',
    decimals: 18,
    address: '0xed40834a13129509a89be39a9be9c0e96a0ddd71',
    logoURI: 'https://tokens.1inch.io/0xed40834a13129509a89be39a9be9c0e96a0ddd71.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'IDLE',
    name: 'Idle',
    decimals: 18,
    address: '0x875773784af8135ea0ef43b5a374aad105c5d39e',
    logoURI: 'https://tokens.1inch.io/0x875773784af8135ea0ef43b5a374aad105c5d39e.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'SRK',
    name: 'SparkPoint',
    decimals: 18,
    address: '0x0488401c3f535193fa8df029d9ffe615a06e74e6',
    logoURI: 'https://tokens.1inch.io/0x0488401c3f535193fa8df029d9ffe615a06e74e6.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'GLCH',
    name: 'Glitch',
    decimals: 18,
    address: '0x038a68ff68c393373ec894015816e33ad41bd564',
    logoURI: 'https://tokens.1inch.io/0x038a68ff68c393373ec894015816e33ad41bd564.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'UMX',
    name: 'unimex network',
    decimals: 18,
    address: '0x10be9a8dae441d276a5027936c3aaded2d82bc15',
    logoURI: 'https://tokens.1inch.io/0x10be9a8dae441d276a5027936c3aaded2d82bc15.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'WHITE',
    name: 'Whiteheart Token',
    decimals: 18,
    address: '0x5f0e628b693018f639d10e4a4f59bd4d8b2b6b44',
    logoURI: 'https://tokens.1inch.io/0x5f0e628b693018f639d10e4a4f59bd4d8b2b6b44.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'DENT',
    name: 'DENT',
    decimals: 8,
    address: '0x3597bfd533a99c9aa083587b074434e61eb0a258',
    logoURI: 'https://tokens.1inch.io/0x3597bfd533a99c9aa083587b074434e61eb0a258.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'ZEFU',
    name: 'Zenfuse Trading Platform Token',
    decimals: 18,
    address: '0xb1e9157c2fdcc5a856c8da8b2d89b6c32b3c1229',
    logoURI: 'https://tokens.1inch.io/0xb1e9157c2fdcc5a856c8da8b2d89b6c32b3c1229.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'MOONS',
    name: 'MoonTools.io',
    decimals: 18,
    address: '0x260e63d91fccc499606bae3fe945c4ed1cf56a56',
    logoURI: 'https://tokens.1inch.io/0x260e63d91fccc499606bae3fe945c4ed1cf56a56.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'mBTC',
    name: 'mStable BTC',
    decimals: 18,
    address: '0x945facb997494cc2570096c74b5f66a3507330a1',
    logoURI: 'https://tokens.1inch.io/0x945facb997494cc2570096c74b5f66a3507330a1.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'VSP',
    name: 'VesperToken',
    decimals: 18,
    address: '0x1b40183efb4dd766f11bda7a7c3ad8982e998421',
    logoURI: 'https://tokens.1inch.io/0x1b40183efb4dd766f11bda7a7c3ad8982e998421.png',

    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'SHROOM',
    name: 'shroom.finance',
    decimals: 18,
    address: '0xed0439eacf4c4965ae4613d77a5c2efe10e5f183',
    logoURI: 'https://tokens.1inch.io/0xed0439eacf4c4965ae4613d77a5c2efe10e5f183.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'GSWAP',
    name: 'gameswap.org',
    decimals: 18,
    address: '0xaac41ec512808d64625576eddd580e7ea40ef8b2',
    logoURI: 'https://tokens.1inch.io/0xaac41ec512808d64625576eddd580e7ea40ef8b2.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'RAI',
    name: 'Rai Reflex Index',
    decimals: 18,
    address: '0x03ab458634910aad20ef5f1c8ee96f1d6ac54919',
    logoURI: 'https://tokens.1inch.io/0x03ab458634910aad20ef5f1c8ee96f1d6ac54919.png',

    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'DOKI',
    name: 'DokiDokiFinance',
    decimals: 18,
    address: '0x9ceb84f92a0561fa3cc4132ab9c0b76a59787544',
    logoURI: 'https://tokens.1inch.io/0x9ceb84f92a0561fa3cc4132ab9c0b76a59787544.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'ESS',
    name: 'ESSENTIA',
    decimals: 18,
    address: '0xfc05987bd2be489accf0f509e44b0145d68240f7',
    logoURI: 'https://tokens.1inch.io/0xfc05987bd2be489accf0f509e44b0145d68240f7.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'XFT',
    name: 'Offshift',
    decimals: 18,
    address: '0xabe580e7ee158da464b51ee1a83ac0289622e6be',
    logoURI: 'https://tokens.1inch.io/0xabe580e7ee158da464b51ee1a83ac0289622e6be.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'SEEN',
    name: 'seen.haus',
    decimals: 18,
    address: '0xca3fe04c7ee111f0bbb02c328c699226acf9fd33',
    logoURI: 'https://tokens.1inch.io/0xca3fe04c7ee111f0bbb02c328c699226acf9fd33.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'arte',
    name: 'ethart',
    decimals: 18,
    address: '0x34612903db071e888a4dadcaa416d3ee263a87b9',
    logoURI: 'https://tokens.1inch.io/0x34612903db071e888a4dadcaa416d3ee263a87b9.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'ALPA',
    name: 'AlpaToken',
    decimals: 18,
    address: '0x7ca4408137eb639570f8e647d9bd7b7e8717514a',
    logoURI: 'https://tokens.1inch.io/0x7ca4408137eb639570f8e647d9bd7b7e8717514a.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'UTU',
    name: 'UTU Coin',
    decimals: 18,
    address: '0xa58a4f5c4bb043d2cc1e170613b74e767c94189b',
    logoURI: 'https://tokens.1inch.io/0xa58a4f5c4bb043d2cc1e170613b74e767c94189b.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'AC',
    name: 'ACoconut',
    decimals: 18,
    address: '0x9a0aba393aac4dfbff4333b06c407458002c6183',
    logoURI: 'https://tokens.1inch.io/0x9a0aba393aac4dfbff4333b06c407458002c6183.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'ROYA',
    name: 'Royale',
    decimals: 18,
    address: '0x7eaf9c89037e4814dc0d9952ac7f888c784548db',
    logoURI: 'https://tokens.1inch.io/0x7eaf9c89037e4814dc0d9952ac7f888c784548db.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'PREMIA',
    name: 'Premia',
    decimals: 18,
    address: '0x6399c842dd2be3de30bf99bc7d1bbf6fa3650e70',
    logoURI: 'https://tokens.1inch.io/0x6399c842dd2be3de30bf99bc7d1bbf6fa3650e70.png',

    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'POOL',
    name: 'PoolTogether',
    decimals: 18,
    address: '0x0cec1a9154ff802e7934fc916ed7ca50bde6844e',
    logoURI: 'https://tokens.1inch.io/0x0cec1a9154ff802e7934fc916ed7ca50bde6844e.png',

    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'SMARTCREDIT',
    name: 'SMARTCREDIT Token',
    decimals: 18,
    address: '0x72e9d9038ce484ee986fea183f8d8df93f9ada13',
    logoURI: 'https://tokens.1inch.io/0x72e9d9038ce484ee986fea183f8d8df93f9ada13.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'ROOT',
    name: 'RootKit',
    decimals: 18,
    address: '0xcb5f72d37685c3d5ad0bb5f982443bc8fcdf570e',
    logoURI: 'https://tokens.1inch.io/0xcb5f72d37685c3d5ad0bb5f982443bc8fcdf570e.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'REVV',
    name: 'REVV',
    decimals: 18,
    address: '0x557b933a7c2c45672b610f8954a3deb39a51a8ca',
    logoURI: 'https://tokens.1inch.io/0x557b933a7c2c45672b610f8954a3deb39a51a8ca.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'PHNX',
    name: 'PhoenixDAO',
    decimals: 18,
    address: '0x38a2fdc11f526ddd5a607c1f251c065f40fbf2f7',
    logoURI: 'https://tokens.1inch.io/0x38a2fdc11f526ddd5a607c1f251c065f40fbf2f7.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'KIT',
    name: 'DexKit',
    decimals: 18,
    address: '0x7866e48c74cbfb8183cd1a929cd9b95a7a5cb4f4',
    logoURI: 'https://tokens.1inch.io/0x7866e48c74cbfb8183cd1a929cd9b95a7a5cb4f4.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'WOO',
    name: 'Wootrade Network',
    decimals: 18,
    address: '0x4691937a7508860f876c9c0a2a617e7d9e945d4b',
    logoURI: 'https://tokens.1inch.io/0x4691937a7508860f876c9c0a2a617e7d9e945d4b.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'MOD',
    name: 'Modefi',
    decimals: 18,
    address: '0xea1ea0972fa092dd463f2968f9bb51cc4c981d71',
    logoURI: 'https://tokens.1inch.io/0xea1ea0972fa092dd463f2968f9bb51cc4c981d71.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'ANY',
    name: 'Anyswap',
    decimals: 18,
    address: '0xf99d58e463a2e07e5692127302c20a191861b4d6',
    logoURI: 'https://tokens.1inch.io/0xf99d58e463a2e07e5692127302c20a191861b4d6.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'RLY',
    name: 'Rally',
    decimals: 18,
    address: '0xf1f955016ecbcd7321c7266bccfb96c68ea5e49b',
    logoURI: 'https://tokens.1inch.io/0xf1f955016ecbcd7321c7266bccfb96c68ea5e49b.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'KEX',
    name: 'KIRA Network',
    decimals: 6,
    address: '0x16980b3b4a3f9d89e33311b5aa8f80303e5ca4f8',
    logoURI: 'https://tokens.1inch.io/0x16980b3b4a3f9d89e33311b5aa8f80303e5ca4f8.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'UOS',
    name: 'Ultra Token',
    decimals: 4,
    address: '0xd13c7342e1ef687c5ad21b27c2b65d772cab5c8c',
    logoURI: 'https://tokens.1inch.io/0xd13c7342e1ef687c5ad21b27c2b65d772cab5c8c.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'GEO',
    name: 'GeoDB Coin',
    decimals: 18,
    address: '0x147faf8de9d8d8daae129b187f0d02d819126750',
    logoURI: 'https://tokens.1inch.io/0x147faf8de9d8d8daae129b187f0d02d819126750.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'GET',
    name: 'GET',
    decimals: 18,
    address: '0x8a854288a5976036a725879164ca3e91d30c6a1b',
    logoURI: 'https://tokens.1inch.io/0x8a854288a5976036a725879164ca3e91d30c6a1b.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'UniFi',
    name: 'UniFi',
    decimals: 18,
    address: '0x9e78b8274e1d6a76a0dbbf90418894df27cbceb5',
    logoURI: 'https://tokens.1inch.io/0x9e78b8274e1d6a76a0dbbf90418894df27cbceb5.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'DDX',
    name: 'DerivaDAO',
    decimals: 18,
    address: '0x3a880652f47bfaa771908c07dd8673a787daed3a',
    logoURI: 'https://tokens.1inch.io/0x3a880652f47bfaa771908c07dd8673a787daed3a.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'RFOX',
    name: 'RFOX',
    decimals: 18,
    address: '0xa1d6df714f91debf4e0802a542e13067f31b8262',
    logoURI: 'https://tokens.1inch.io/0xa1d6df714f91debf4e0802a542e13067f31b8262.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'MONA',
    name: 'Monavale',
    decimals: 18,
    address: '0x275f5ad03be0fa221b4c6649b8aee09a42d9412a',
    logoURI: 'https://tokens.1inch.io/0x275f5ad03be0fa221b4c6649b8aee09a42d9412a.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'HY',
    name: 'hybrix hydra',
    decimals: 18,
    address: '0x9b53e429b0badd98ef7f01f03702986c516a5715',
    logoURI: 'https://tokens.1inch.io/0x9b53e429b0badd98ef7f01f03702986c516a5715.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'SUPER',
    name: 'SuperFarm',
    decimals: 18,
    address: '0xe53ec727dbdeb9e2d5456c3be40cff031ab40a55',
    logoURI: 'https://tokens.1inch.io/0xe53ec727dbdeb9e2d5456c3be40cff031ab40a55.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'LPOOL',
    name: 'Launchpool token',
    decimals: 18,
    address: '0x6149c26cd2f7b5ccdb32029af817123f6e37df5b',
    logoURI: 'https://tokens.1inch.io/0x6149c26cd2f7b5ccdb32029af817123f6e37df5b.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'MASK_NTWRK',
    name: 'Mask Network',
    decimals: 18,
    address: '0x69af81e73a73b40adf4f3d4223cd9b1ece623074',
    logoURI: 'https://tokens.1inch.io/0x69af81e73a73b40adf4f3d4223cd9b1ece623074.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'XTK',
    name: 'xToken',
    decimals: 18,
    address: '0x7f3edcdd180dbe4819bd98fee8929b5cedb3adeb',
    logoURI: 'https://tokens.1inch.io/0x7f3edcdd180dbe4819bd98fee8929b5cedb3adeb.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'SIG',
    name: 'xSigma',
    decimals: 18,
    address: '0x7777777777697cfeecf846a76326da79cc606517',
    logoURI: 'https://tokens.1inch.io/0x7777777777697cfeecf846a76326da79cc606517.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'HOPR',
    name: 'HOPR Token',
    decimals: 18,
    address: '0xf5581dfefd8fb0e4aec526be659cfab1f8c781da',
    logoURI: 'https://tokens.1inch.io/0xf5581dfefd8fb0e4aec526be659cfab1f8c781da.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'FRY',
    name: 'Foundry Logistics Token',
    decimals: 18,
    address: '0x6c972b70c533e2e045f333ee28b9ffb8d717be69',
    logoURI: 'https://tokens.1inch.io/0x6c972b70c533e2e045f333ee28b9ffb8d717be69.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'RNDR',
    name: 'Render Token',
    decimals: 18,
    address: '0x6de037ef9ad2725eb40118bb1702ebb27e4aeb24',
    logoURI: 'https://tokens.1inch.io/0x6de037ef9ad2725eb40118bb1702ebb27e4aeb24.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'OVR',
    name: 'OVR',
    decimals: 18,
    address: '0x21bfbda47a0b4b5b1248c767ee49f7caa9b23697',
    logoURI: 'https://tokens.1inch.io/0x21bfbda47a0b4b5b1248c767ee49f7caa9b23697.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'MTLX',
    name: 'Mettalex',
    decimals: 18,
    address: '0x2e1e15c44ffe4df6a0cb7371cd00d5028e571d14',
    logoURI: 'https://tokens.1inch.io/0x2e1e15c44ffe4df6a0cb7371cd00d5028e571d14.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'POLK',
    name: 'Polkamarkets',
    decimals: 18,
    address: '0xd478161c952357f05f0292b56012cd8457f1cfbf',
    logoURI: 'https://tokens.1inch.io/0xd478161c952357f05f0292b56012cd8457f1cfbf.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'vBNT',
    name: 'Bancor Governance Token',
    decimals: 18,
    address: '0x48fb253446873234f2febbf9bdeaa72d9d387f94',
    logoURI: 'https://tokens.1inch.io/0x48fb253446873234f2febbf9bdeaa72d9d387f94.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'crDAI',
    name: 'Cream Dai Stablecoin',
    decimals: 8,
    address: '0x92b767185fb3b04f881e3ac8e5b0662a027a1d9f',
    logoURI: 'https://tokens.1inch.io/0x92b767185fb3b04f881e3ac8e5b0662a027a1d9f.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'NORD',
    name: 'Nord Token',
    decimals: 18,
    address: '0x6e9730ecffbed43fd876a264c982e254ef05a0de',
    logoURI: 'https://tokens.1inch.io/0x6e9730ecffbed43fd876a264c982e254ef05a0de.png',

    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'DOWS',
    name: 'Shadows Network',
    decimals: 18,
    address: '0x661ab0ed68000491d98c796146bcf28c20d7c559',
    logoURI: 'https://tokens.1inch.io/0x661ab0ed68000491d98c796146bcf28c20d7c559.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'MINT',
    name: 'Public Mint',
    decimals: 18,
    address: '0x0cdf9acd87e940837ff21bb40c9fd55f68bba059',
    logoURI: 'https://tokens.1inch.io/0x0cdf9acd87e940837ff21bb40c9fd55f68bba059.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'DEGEN',
    name: 'DEGEN Index',
    decimals: 18,
    address: '0x126c121f99e1e211df2e5f8de2d96fa36647c855',
    logoURI: 'https://tokens.1inch.io/0x126c121f99e1e211df2e5f8de2d96fa36647c855.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'BFC',
    name: 'Bifrost',
    decimals: 18,
    address: '0x0c7d5ae016f806603cb1782bea29ac69471cab9c',
    logoURI: 'https://tokens.1inch.io/0x0c7d5ae016f806603cb1782bea29ac69471cab9c.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'SI',
    name: 'SIREN',
    decimals: 18,
    address: '0xd23ac27148af6a2f339bd82d0e3cff380b5093de',
    logoURI: 'https://tokens.1inch.io/0xd23ac27148af6a2f339bd82d0e3cff380b5093de.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'FONT',
    name: 'Font',
    decimals: 18,
    address: '0x4c25bdf026ea05f32713f00f73ca55857fbf6342',
    logoURI: 'https://tokens.1inch.io/0x4c25bdf026ea05f32713f00f73ca55857fbf6342.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'MOON',
    name: 'MoonToken',
    decimals: 18,
    address: '0x68a3637ba6e75c0f66b61a42639c4e9fcd3d4824',
    logoURI: 'https://tokens.1inch.io/0x68a3637ba6e75c0f66b61a42639c4e9fcd3d4824.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'JUP',
    name: 'Jupiter',
    decimals: 18,
    address: '0x4b1e80cac91e2216eeb63e29b957eb91ae9c2be8',
    logoURI: 'https://tokens.1inch.io/0x4b1e80cac91e2216eeb63e29b957eb91ae9c2be8.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'NDR',
    name: 'NodeRunners',
    decimals: 18,
    address: '0x739763a258640919981f9ba610ae65492455be53',
    logoURI: 'https://tokens.1inch.io/0x739763a258640919981f9ba610ae65492455be53.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'RAD',
    name: 'Radicle',
    decimals: 18,
    address: '0x31c8eacbffdd875c74b94b077895bd78cf1e64a3',
    logoURI: 'https://tokens.1inch.io/0x31c8eacbffdd875c74b94b077895bd78cf1e64a3.png',

    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'ALCX',
    name: 'Alchemix',
    decimals: 18,
    address: '0xdbdb4d16eda451d0503b854cf79d55697f90c8df',
    logoURI: 'https://tokens.1inch.io/0xdbdb4d16eda451d0503b854cf79d55697f90c8df.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'BANK',
    name: 'Float Bank',
    decimals: 18,
    address: '0x24a6a37576377f63f194caa5f518a60f45b42921',
    logoURI: 'https://tokens.1inch.io/0x24a6a37576377f63f194caa5f518a60f45b42921.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'MATTER',
    name: 'Antimatter.Finance Governance Token',
    decimals: 18,
    address: '0x9b99cca871be05119b2012fd4474731dd653febe',
    logoURI: 'https://tokens.1inch.io/0x1c9491865a1de77c5b6e19d2e6a5f1d7a6f2b25f_1.png',

    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'FCL',
    name: 'Fractal Protocol Token',
    decimals: 18,
    address: '0xf4d861575ecc9493420a3f5a14f85b13f0b50eb3',
    logoURI: 'https://tokens.1inch.io/0xf4d861575ecc9493420a3f5a14f85b13f0b50eb3.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'VRA',
    name: 'VERA',
    decimals: 18,
    address: '0xf411903cbc70a74d22900a5de66a2dda66507255',
    logoURI: 'https://tokens.1inch.io/0xf411903cbc70a74d22900a5de66a2dda66507255.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'NFTI',
    name: 'NFT INDEX',
    decimals: 18,
    address: '0xe5feeac09d36b18b3fa757e5cf3f8da6b8e27f4c',
    logoURI: 'https://tokens.1inch.io/0xe5feeac09d36b18b3fa757e5cf3f8da6b8e27f4c.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'BDP',
    name: 'BDPToken',
    decimals: 18,
    address: '0xf3dcbc6d72a4e1892f7917b7c43b74131df8480e',
    logoURI: 'https://tokens.1inch.io/0xf3dcbc6d72a4e1892f7917b7c43b74131df8480e.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'ERN',
    name: '@EthernityChain $ERN Token',
    decimals: 18,
    address: '0xbbc2ae13b23d715c30720f079fcd9b4a74093505',
    logoURI: 'https://tokens.1inch.io/0xbbc2ae13b23d715c30720f079fcd9b4a74093505.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'ROBOT',
    name: 'MetaFactory',
    decimals: 18,
    address: '0xfb5453340c03db5ade474b27e68b6a9c6b2823eb',
    logoURI: 'https://tokens.1inch.io/0xfb5453340c03db5ade474b27e68b6a9c6b2823eb.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'PAINT',
    name: 'Paint',
    decimals: 18,
    address: '0x4c6ec08cf3fc987c6c4beb03184d335a2dfc4042',
    logoURI: 'https://tokens.1inch.io/0x4c6ec08cf3fc987c6c4beb03184d335a2dfc4042.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'RULER',
    name: 'Ruler Protocol',
    decimals: 18,
    address: '0x2aeccb42482cc64e087b6d2e5da39f5a7a7001f8',
    logoURI: 'https://tokens.1inch.io/0x2aeccb42482cc64e087b6d2e5da39f5a7a7001f8.png',

    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'xFUND',
    name: 'unification.com/xfund',
    decimals: 9,
    address: '0x892a6f9df0147e5f079b0993f486f9aca3c87881',
    logoURI: 'https://tokens.1inch.io/0x892a6f9df0147e5f079b0993f486f9aca3c87881.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'bALPHA',
    name: 'bAlpha',
    decimals: 18,
    address: '0x7a5ce6abd131ea6b148a022cb76fc180ae3315a6',
    logoURI: 'https://tokens.1inch.io/0x7a5ce6abd131ea6b148a022cb76fc180ae3315a6.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'CHZ',
    name: 'chiliZ',
    decimals: 18,
    address: '0x3506424f91fd33084466f402d5d97f05f8e3b4af',
    logoURI: 'https://tokens.1inch.io/0x3506424f91fd33084466f402d5d97f05f8e3b4af.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'INV',
    name: 'Inverse DAO',
    decimals: 18,
    address: '0x41d5d79431a913c4ae7d69a668ecdfe5ff9dfb68',
    logoURI: 'https://tokens.1inch.io/0x41d5d79431a913c4ae7d69a668ecdfe5ff9dfb68.png',

    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'GOVI',
    name: 'GOVI',
    decimals: 18,
    address: '0xeeaa40b28a2d1b0b08f6f97bb1dd4b75316c6107',
    logoURI: 'https://tokens.1inch.io/0xeeaa40b28a2d1b0b08f6f97bb1dd4b75316c6107.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'BEPRO',
    name: 'BetProtocolToken',
    decimals: 18,
    address: '0xcf3c8be2e2c42331da80ef210e9b1b307c03d36a',
    logoURI: 'https://tokens.1inch.io/0xcf3c8be2e2c42331da80ef210e9b1b307c03d36a.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'NFY',
    name: 'Non-Fungible Yearn',
    decimals: 18,
    address: '0x1cbb83ebcd552d5ebf8131ef8c9cd9d9bab342bc',
    logoURI: 'https://tokens.1inch.io/0x1cbb83ebcd552d5ebf8131ef8c9cd9d9bab342bc.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'DUCK_UNIT',
    name: 'Unit Protocol',
    decimals: 18,
    address: '0x92e187a03b6cd19cb6af293ba17f2745fd2357d5',
    logoURI: 'https://tokens.1inch.io/0x92e187a03b6cd19cb6af293ba17f2745fd2357d5.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'DFX',
    name: 'DFX Token',
    decimals: 18,
    address: '0x888888435fde8e7d4c54cab67f206e4199454c60',
    logoURI: 'https://tokens.1inch.io/0x888888435fde8e7d4c54cab67f206e4199454c60.png',

    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'B20',
    name: 'B.20',
    decimals: 18,
    address: '0xc4de189abf94c57f396bd4c52ab13b954febefd8',
    logoURI: 'https://tokens.1inch.io/0xc4de189abf94c57f396bd4c52ab13b954febefd8.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'TAP',
    name: 'Tapmydata',
    decimals: 18,
    address: '0x7f1f2d3dfa99678675ece1c243d3f7bc3746db5d',
    logoURI: 'https://tokens.1inch.io/0x7f1f2d3dfa99678675ece1c243d3f7bc3746db5d.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'TACO',
    name: 'Tacos',
    decimals: 18,
    address: '0x00d1793d7c3aae506257ba985b34c76aaf642557',
    logoURI: 'https://tokens.1inch.io/0x00d1793d7c3aae506257ba985b34c76aaf642557.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'ARCX_OLD',
    name: 'ARC Governance Token (Old)',
    decimals: 18,
    address: '0xed30dd7e50edf3581ad970efc5d9379ce2614adb',
    logoURI: 'https://tokens.1inch.io/0xed30dd7e50edf3581ad970efc5d9379ce2614adb.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'STABLEx',
    name: 'ARC STABLEx',
    decimals: 18,
    address: '0xcd91538b91b4ba7797d39a2f66e63810b50a33d0',
    logoURI: 'https://tokens.1inch.io/0xcd91538b91b4ba7797d39a2f66e63810b50a33d0.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'DUSK',
    name: 'Dusk Network',
    decimals: 18,
    address: '0x940a2db1b7008b6c776d4faaca729d6d4a4aa551',
    logoURI: 'https://tokens.1inch.io/0x940a2db1b7008b6c776d4faaca729d6d4a4aa551.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'ETH2x-FLI',
    name: 'ETH 2x Flexible Leverage Index',
    decimals: 18,
    address: '0xaa6e8127831c9de45ae56bb1b0d4d4da6e5665bd',
    logoURI: 'https://tokens.1inch.io/0xaa6e8127831c9de45ae56bb1b0d4d4da6e5665bd.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'FTX Token',
    name: 'FTT',
    decimals: 18,
    address: '0x50d1c9771902476076ecfc8b2a83ad6b9355a4c9',
    logoURI: 'https://tokens.1inch.io/0x50d1c9771902476076ecfc8b2a83ad6b9355a4c9.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'LCX',
    name: 'LCX',
    decimals: 18,
    address: '0x037a54aab062628c9bbae1fdb1583c195585fe41',
    logoURI: 'https://tokens.1inch.io/0x037a54aab062628c9bbae1fdb1583c195585fe41.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'INSUR',
    name: 'InsurAce',
    decimals: 18,
    address: '0x544c42fbb96b39b21df61cf322b5edc285ee7429',
    logoURI: 'https://tokens.1inch.io/0x544c42fbb96b39b21df61cf322b5edc285ee7429.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'CGG',
    name: 'ChainGuardians Governance Token',
    decimals: 18,
    address: '0x1fe24f25b1cf609b9c4e7e12d802e3640dfa5e43',
    logoURI: 'https://tokens.1inch.io/0x1fe24f25b1cf609b9c4e7e12d802e3640dfa5e43.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'CRU',
    name: 'CRUST',
    decimals: 18,
    address: '0x32a7c02e79c4ea1008dd6564b35f131428673c41',
    logoURI: 'https://tokens.1inch.io/0x32a7c02e79c4ea1008dd6564b35f131428673c41.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'ALICE',
    name: 'ALICE',
    decimals: 6,
    address: '0xac51066d7bec65dc4589368da368b212745d63e8',
    logoURI: 'https://tokens.1inch.io/0xac51066d7bec65dc4589368da368b212745d63e8.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'TOWER',
    name: 'TOWER',
    decimals: 18,
    address: '0x1c9922314ed1415c95b9fd453c3818fd41867d0b',
    logoURI: 'https://tokens.1inch.io/0x1c9922314ed1415c95b9fd453c3818fd41867d0b.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'YIELD',
    name: 'Yield Protocol',
    decimals: 18,
    address: '0xa8b61cff52564758a204f841e636265bebc8db9b',
    logoURI: 'https://tokens.1inch.io/0xa8b61cff52564758a204f841e636265bebc8db9b.png',

    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'KONO',
    name: 'Konomi',
    decimals: 18,
    address: '0x850aab69f0e0171a9a49db8be3e71351c8247df4',
    logoURI: 'https://tokens.1inch.io/0x850aab69f0e0171a9a49db8be3e71351c8247df4.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'SOAR',
    name: 'SOAR.FI',
    decimals: 9,
    address: '0xbae5f2d8a1299e5c4963eaff3312399253f27ccb',
    logoURI: 'https://tokens.1inch.io/0xbae5f2d8a1299e5c4963eaff3312399253f27ccb.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'DOV',
    name: 'DOVU',
    decimals: 18,
    address: '0xac3211a5025414af2866ff09c23fc18bc97e79b1',
    logoURI: 'https://tokens.1inch.io/0xac3211a5025414af2866ff09c23fc18bc97e79b1.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'JGN',
    name: 'Juggernaut DeFi',
    decimals: 18,
    address: '0x73374ea518de7addd4c2b624c0e8b113955ee041',
    logoURI: 'https://tokens.1inch.io/0x73374ea518de7addd4c2b624c0e8b113955ee041.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'HOGE',
    name: 'hoge.finance',
    decimals: 9,
    address: '0xfad45e47083e4607302aa43c65fb3106f1cd7607',
    logoURI: 'https://tokens.1inch.io/0xfad45e47083e4607302aa43c65fb3106f1cd7607.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'NOW',
    name: 'ChangeNOW',
    decimals: 8,
    address: '0xe9a95d175a5f4c9369f3b74222402eb1b837693b',
    logoURI: 'https://tokens.1inch.io/0xe9a95d175a5f4c9369f3b74222402eb1b837693b.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'HTRE',
    name: 'HodlTree',
    decimals: 18,
    address: '0xdea67845a51e24461d5fed8084e69b426af3d5db',
    logoURI: 'https://tokens.1inch.io/0xdea67845a51e24461d5fed8084e69b426af3d5db.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'PKF',
    name: 'PolkaFoundry',
    decimals: 18,
    address: '0x8b39b70e39aa811b69365398e0aace9bee238aeb',
    logoURI: 'https://tokens.1inch.io/0x8b39b70e39aa811b69365398e0aace9bee238aeb.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'EXRT',
    name: 'EXRT',
    decimals: 8,
    address: '0xb20043f149817bff5322f1b928e89abfc65a9925',
    logoURI: 'https://tokens.1inch.io/0xb20043f149817bff5322f1b928e89abfc65a9925.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'DERI',
    name: 'Deri',
    decimals: 18,
    address: '0xa487bf43cf3b10dffc97a9a744cbb7036965d3b9',
    logoURI: 'https://tokens.1inch.io/0xa487bf43cf3b10dffc97a9a744cbb7036965d3b9.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'BCUG',
    name: 'Blockchain Cuties Universe Governance Token',
    decimals: 18,
    address: '0x14da7b27b2e0fedefe0a664118b0c9bc68e2e9af',
    logoURI: 'https://tokens.1inch.io/0x14da7b27b2e0fedefe0a664118b0c9bc68e2e9af.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'LABS',
    name: 'LABS Group',
    decimals: 18,
    address: '0x8b0e42f366ba502d787bb134478adfae966c8798',
    logoURI: 'https://tokens.1inch.io/0x8b0e42f366ba502d787bb134478adfae966c8798.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'KINE',
    name: 'Kine Governance Token',
    decimals: 18,
    address: '0xcbfef8fdd706cde6f208460f2bf39aa9c785f05d',
    logoURI: 'https://tokens.1inch.io/0xcbfef8fdd706cde6f208460f2bf39aa9c785f05d.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'HAPI',
    name: 'HAPI',
    decimals: 18,
    address: '0xd9c2d319cd7e6177336b0a9c93c21cb48d84fb54',
    logoURI: 'https://tokens.1inch.io/0xd9c2d319cd7e6177336b0a9c93c21cb48d84fb54.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'yveCRV-DAO',
    name: 'veCRV-DAO yVault',
    decimals: 18,
    address: '0xc5bddf9843308380375a611c18b50fb9341f502a',
    logoURI: 'https://tokens.1inch.io/0xc5bddf9843308380375a611c18b50fb9341f502a.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'K21',
    name: 'k21.kanon.art',
    decimals: 18,
    address: '0xb9d99c33ea2d86ec5ec6b8a4dd816ebba64404af',
    logoURI: 'https://tokens.1inch.io/0xb9d99c33ea2d86ec5ec6b8a4dd816ebba64404af.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'UNN',
    name: 'UNION Protocol Governance Token',
    decimals: 18,
    address: '0x226f7b842e0f0120b7e194d05432b3fd14773a9d',
    logoURI: 'https://tokens.1inch.io/0x226f7b842e0f0120b7e194d05432b3fd14773a9d.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'HBT',
    name: 'Habitat Token',
    decimals: 10,
    address: '0x0ace32f6e87ac1457a5385f8eb0208f37263b415',
    logoURI: 'https://tokens.1inch.io/0x0ace32f6e87ac1457a5385f8eb0208f37263b415.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'CATE',
    name: 'Cash Tech',
    decimals: 18,
    address: '0xa42f266684ac2ad6ecb00df95b1c76efbb6f136c',
    logoURI: 'https://tokens.1inch.io/0xa42f266684ac2ad6ecb00df95b1c76efbb6f136c.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'DORA',
    name: 'Dorayaki',
    decimals: 18,
    address: '0xbc4171f45ef0ef66e76f979df021a34b46dcc81d',
    logoURI: 'https://tokens.1inch.io/0xbc4171f45ef0ef66e76f979df021a34b46dcc81d.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'erowan',
    name: 'erowan',
    decimals: 18,
    address: '0x07bac35846e5ed502aa91adf6a9e7aa210f2dcbe',
    logoURI: 'https://tokens.1inch.io/0x07bac35846e5ed502aa91adf6a9e7aa210f2dcbe.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'SNTVT',
    name: 'Sentivate',
    decimals: 18,
    address: '0x7865af71cf0b288b4e7f654f4f7851eb46a2b7f8',
    logoURI: 'https://tokens.1inch.io/0x7865af71cf0b288b4e7f654f4f7851eb46a2b7f8.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'CHAIN',
    name: 'Chain Games',
    decimals: 18,
    address: '0xc4c2614e694cf534d407ee49f8e44d125e4681c4',
    logoURI: 'https://tokens.1inch.io/0xc4c2614e694cf534d407ee49f8e44d125e4681c4.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'UBXT',
    name: 'UpBots',
    decimals: 18,
    address: '0x8564653879a18c560e7c0ea0e084c516c62f5653',
    logoURI: 'https://tokens.1inch.io/0x8564653879a18c560e7c0ea0e084c516c62f5653.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'GLQ',
    name: 'GraphLinq',
    decimals: 18,
    address: '0x9f9c8ec3534c3ce16f928381372bfbfbfb9f4d24',
    logoURI: 'https://tokens.1inch.io/0x9f9c8ec3534c3ce16f928381372bfbfbfb9f4d24.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'LYM',
    name: 'Lympo tokens',
    decimals: 18,
    address: '0xc690f7c7fcffa6a82b79fab7508c466fefdfc8c5',
    logoURI: 'https://tokens.1inch.io/0xc690f7c7fcffa6a82b79fab7508c466fefdfc8c5.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'VIDYA',
    name: 'Vidya',
    decimals: 18,
    address: '0x3d3d35bb9bec23b06ca00fe472b50e7a4c692c30',
    logoURI: 'https://tokens.1inch.io/0x3d3d35bb9bec23b06ca00fe472b50e7a4c692c30.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'FIRE',
    name: 'Fire Protocol',
    decimals: 8,
    address: '0xf921ae2dac5fa128dc0f6168bf153ea0943d2d43',
    logoURI: 'https://tokens.1inch.io/0xf921ae2dac5fa128dc0f6168bf153ea0943d2d43.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'DAFI',
    name: 'DAFI Token',
    decimals: 18,
    address: '0xfc979087305a826c2b2a0056cfaba50aad3e6439',
    logoURI: 'https://tokens.1inch.io/0xfc979087305a826c2b2a0056cfaba50aad3e6439.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'ODDZ',
    name: 'OddzToken',
    decimals: 18,
    address: '0xcd2828fc4d8e8a0ede91bb38cf64b1a81de65bf6',
    logoURI: 'https://tokens.1inch.io/0xcd2828fc4d8e8a0ede91bb38cf64b1a81de65bf6.png',

    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'EPAN',
    name: 'Paypolitan Token',
    decimals: 18,
    address: '0x72630b1e3b42874bf335020ba0249e3e9e47bafc',
    logoURI: 'https://tokens.1inch.io/0x72630b1e3b42874bf335020ba0249e3e9e47bafc.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'ARA',
    name: 'Ara Token',
    decimals: 18,
    address: '0xa92e7c82b11d10716ab534051b271d2f6aef7df5',
    logoURI: 'https://tokens.1inch.io/0xa92e7c82b11d10716ab534051b271d2f6aef7df5.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'BOND_finance',
    name: 'bonded.finance',
    decimals: 8,
    address: '0x5dc02ea99285e17656b8350722694c35154db1e8',
    logoURI: 'https://tokens.1inch.io/0x5dc02ea99285e17656b8350722694c35154db1e8.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'vETH',
    name: 'Voucher Ethereum',
    decimals: 18,
    address: '0xc3d088842dcf02c13699f936bb83dfbbc6f721ab',
    logoURI: 'https://tokens.1inch.io/0xc3d088842dcf02c13699f936bb83dfbbc6f721ab.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'AMLT',
    name: 'AMLT',
    decimals: 18,
    address: '0xca0e7269600d353f70b14ad118a49575455c0f2f',
    logoURI: 'https://tokens.1inch.io/0xca0e7269600d353f70b14ad118a49575455c0f2f.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'VTX',
    name: 'Vortex DeFi',
    decimals: 18,
    address: '0xceb286c9604c542d3cc08b41aa6c9675b078a832',
    logoURI: 'https://tokens.1inch.io/0xceb286c9604c542d3cc08b41aa6c9675b078a832.png',

    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'vVSP',
    name: 'vVSP pool',
    decimals: 18,
    address: '0xba4cfe5741b357fa371b506e5db0774abfecf8fc',
    logoURI: 'https://tokens.1inch.io/0x1b40183efb4dd766f11bda7a7c3ad8982e998421.png',

    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'TRIBE',
    name: 'Tribe',
    decimals: 18,
    address: '0xc7283b66eb1eb5fb86327f08e1b5816b0720212b',
    logoURI: 'https://tokens.1inch.io/0xc7283b66eb1eb5fb86327f08e1b5816b0720212b.png',

    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'FEI',
    name: 'Fei USD',
    decimals: 18,
    address: '0x956f47f50a910163d8bf957cf5846d573e7f87ca',
    logoURI: 'https://tokens.1inch.io/0x956f47f50a910163d8bf957cf5846d573e7f87ca.png',

    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'XSGD',
    name: 'XSGD',
    decimals: 6,
    address: '0x70e8de73ce538da2beed35d14187f6959a8eca96',
    logoURI: 'https://tokens.1inch.io/0x70e8de73ce538da2beed35d14187f6959a8eca96.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'AIOZ',
    name: 'AIOZ Network',
    decimals: 18,
    address: '0x626e8036deb333b408be468f951bdb42433cbf18',
    logoURI: 'https://tokens.1inch.io/0x626e8036deb333b408be468f951bdb42433cbf18.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'SPH',
    name: 'Spheroid',
    decimals: 18,
    address: '0xa0cf46eb152656c7090e769916eb44a138aaa406',
    logoURI: 'https://tokens.1inch.io/0xa0cf46eb152656c7090e769916eb44a138aaa406.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'PMON',
    name: 'Polkamon',
    decimals: 18,
    address: '0x1796ae0b0fa4862485106a0de9b654efe301d0b2',
    logoURI: 'https://tokens.1inch.io/0x1796ae0b0fa4862485106a0de9b654efe301d0b2.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'SYLO',
    name: 'Sylo',
    decimals: 18,
    address: '0xf293d23bf2cdc05411ca0eddd588eb1977e8dcd4',
    logoURI: 'https://tokens.1inch.io/0xf293d23bf2cdc05411ca0eddd588eb1977e8dcd4.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'EMB',
    name: 'Emblem',
    decimals: 8,
    address: '0xdb0acc14396d108b3c5574483acb817855c9dc8d',
    logoURI: 'https://tokens.1inch.io/0xdb0acc14396d108b3c5574483acb817855c9dc8d.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'URQA',
    name: 'UREEQA Token',
    decimals: 18,
    address: '0x1735db6ab5baa19ea55d0adceed7bcdc008b3136',
    logoURI: 'https://tokens.1inch.io/0x1735db6ab5baa19ea55d0adceed7bcdc008b3136.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'LPL',
    name: 'LinkPool',
    decimals: 18,
    address: '0x99295f1141d58a99e939f7be6bbe734916a875b8',
    logoURI: 'https://tokens.1inch.io/0x99295f1141d58a99e939f7be6bbe734916a875b8.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'XCUR',
    name: 'Curate',
    decimals: 8,
    address: '0xe1c7e30c42c24582888c758984f6e382096786bd',
    logoURI: 'https://tokens.1inch.io/0xe1c7e30c42c24582888c758984f6e382096786bd.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'COOK',
    name: 'Cook Token',
    decimals: 18,
    address: '0xff75ced57419bcaebe5f05254983b013b0646ef5',
    logoURI: 'https://tokens.1inch.io/0xff75ced57419bcaebe5f05254983b013b0646ef5.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'CELL',
    name: 'Cellframe Token',
    decimals: 18,
    address: '0x26c8afbbfe1ebaca03c2bb082e69d0476bffe099',
    logoURI: 'https://tokens.1inch.io/0x26c8afbbfe1ebaca03c2bb082e69d0476bffe099.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'MAD',
    name: 'MADToken',
    decimals: 18,
    address: '0x5b09a0371c1da44a8e24d36bf5deb1141a84d875',
    logoURI: 'https://tokens.1inch.io/0x5b09a0371c1da44a8e24d36bf5deb1141a84d875.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'CONV',
    name: 'Convergence',
    decimals: 18,
    address: '0xc834fa996fa3bec7aad3693af486ae53d8aa8b50',
    logoURI: 'https://tokens.1inch.io/0xc834fa996fa3bec7aad3693af486ae53d8aa8b50.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'BASv2',
    name: 'BASv2',
    decimals: 18,
    address: '0x106538cc16f938776c7c180186975bca23875287',
    logoURI: 'https://tokens.1inch.io/0x106538cc16f938776c7c180186975bca23875287.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'SWM',
    name: 'SWARM',
    decimals: 18,
    address: '0x3505f494c3f0fed0b594e01fa41dd3967645ca39',
    logoURI: 'https://tokens.1inch.io/0x3505f494c3f0fed0b594e01fa41dd3967645ca39.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'EDDA',
    name: 'EDDA',
    decimals: 18,
    address: '0xfbbe9b1142c699512545f47937ee6fae0e4b0aa9',
    logoURI: 'https://tokens.1inch.io/0xfbbe9b1142c699512545f47937ee6fae0e4b0aa9.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'TIDAL',
    name: 'Tidal Token',
    decimals: 18,
    address: '0x29cbd0510eec0327992cd6006e63f9fa8e7f33b7',
    logoURI: 'https://tokens.1inch.io/0x29cbd0510eec0327992cd6006e63f9fa8e7f33b7.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'DRC_2',
    name: 'Dracula Token',
    decimals: 18,
    address: '0xb78b3320493a4efaa1028130c5ba26f0b6085ef8',
    logoURI: 'https://tokens.1inch.io/0xb78b3320493a4efaa1028130c5ba26f0b6085ef8.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'XYO',
    name: 'XY Oracle',
    decimals: 18,
    address: '0x55296f69f40ea6d20e478533c15a6b08b654e758',
    logoURI: 'https://tokens.1inch.io/0x55296f69f40ea6d20e478533c15a6b08b654e758.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'ARCONA',
    name: 'Arcona Distribution Contract',
    decimals: 18,
    address: '0x0f71b8de197a1c84d31de0f1fa7926c365f052b3',
    logoURI: 'https://tokens.1inch.io/0x0f71b8de197a1c84d31de0f1fa7926c365f052b3.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'DPR',
    name: 'Deeper Network',
    decimals: 18,
    address: '0xf3ae5d769e153ef72b4e3591ac004e89f48107a1',
    logoURI: 'https://tokens.1inch.io/0xf3ae5d769e153ef72b4e3591ac004e89f48107a1.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'GAINS',
    name: 'Gains',
    decimals: 18,
    address: '0xd9b312d77bc7bed9b9cecb56636300bed4fe5ce9',
    logoURI: 'https://tokens.1inch.io/0xd9b312d77bc7bed9b9cecb56636300bed4fe5ce9.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'LUSD',
    name: 'LUSD Stablecoin',
    decimals: 18,
    address: '0x5f98805a4e8be255a32880fdec7f6728c6568ba0',
    logoURI: 'https://tokens.1inch.io/0x5f98805a4e8be255a32880fdec7f6728c6568ba0.png',

    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'GS',
    name: 'Gen Shards',
    decimals: 18,
    address: '0xe0b9a2c3e9f40cf74b2c7f591b2b0cca055c3112',
    logoURI: 'https://tokens.1inch.io/0xe0b9a2c3e9f40cf74b2c7f591b2b0cca055c3112.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'CARDS',
    name: 'CARD.STARTER',
    decimals: 18,
    address: '0x3d6f0dea3ac3c607b3998e6ce14b6350721752d9',
    logoURI: 'https://tokens.1inch.io/0x3d6f0dea3ac3c607b3998e6ce14b6350721752d9.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'EBOX',
    name: 'ethbox Token',
    decimals: 18,
    address: '0x33840024177a7daca3468912363bed8b425015c5',
    logoURI: 'https://tokens.1inch.io/0x33840024177a7daca3468912363bed8b425015c5.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'PRE',
    name: 'Presearch',
    decimals: 18,
    address: '0xec213f83defb583af3a000b1c0ada660b1902a0f',
    logoURI: 'https://tokens.1inch.io/0xec213f83defb583af3a000b1c0ada660b1902a0f.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'PUSH',
    name: 'Ethereum Push Notification Service',
    decimals: 18,
    address: '0xf418588522d5dd018b425e472991e52ebbeeeeee',
    logoURI: 'https://tokens.1inch.io/0xf418588522d5dd018b425e472991e52ebbeeeeee.png',

    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'ZORA',
    name: 'Zoracles',
    decimals: 9,
    address: '0xd8e3fb3b08eba982f2754988d70d57edc0055ae6',
    logoURI: 'https://tokens.1inch.io/0xd8e3fb3b08eba982f2754988d70d57edc0055ae6.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'BOSON',
    name: 'Boson Token',
    decimals: 18,
    address: '0xc477d038d5420c6a9e0b031712f61c5120090de9',
    logoURI: 'https://tokens.1inch.io/0xc477d038d5420c6a9e0b031712f61c5120090de9.png',

    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'UBI',
    name: 'Universal Basic Income',
    decimals: 18,
    address: '0xdd1ad9a21ce722c151a836373babe42c868ce9a4',
    logoURI: 'https://tokens.1inch.io/0xdd1ad9a21ce722c151a836373babe42c868ce9a4.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'DG',
    name: 'DeGate Token',
    decimals: 18,
    address: '0x53c8395465a84955c95159814461466053dedede',
    logoURI: 'https://tokens.1inch.io/0x53c8395465a84955c95159814461466053dedede.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'TCAP',
    name: 'TCAP Token',
    decimals: 18,
    address: '0x16c52ceece2ed57dad87319d91b5e3637d50afa4',
    logoURI: 'https://tokens.1inch.io/0x16c52ceece2ed57dad87319d91b5e3637d50afa4.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'BASK',
    name: 'BasketDAO Gov',
    decimals: 18,
    address: '0x44564d0bd94343f72e3c8a0d22308b7fa71db0bb',
    logoURI: 'https://tokens.1inch.io/0x44564d0bd94343f72e3c8a0d22308b7fa71db0bb.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'NKN',
    name: 'NKN',
    decimals: 18,
    address: '0x5cf04716ba20127f1e2297addcf4b5035000c9eb',
    logoURI: 'https://tokens.1inch.io/0x5cf04716ba20127f1e2297addcf4b5035000c9eb.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'FORTH',
    name: 'Ampleforth Governance',
    decimals: 18,
    address: '0x77fba179c79de5b7653f68b5039af940ada60ce0',
    logoURI: 'https://tokens.1inch.io/0x77fba179c79de5b7653f68b5039af940ada60ce0.png',

    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'BLES',
    name: 'Blind Boxes Token',
    decimals: 18,
    address: '0xe796d6ca1ceb1b022ece5296226bf784110031cd',
    logoURI: 'https://tokens.1inch.io/0xe796d6ca1ceb1b022ece5296226bf784110031cd.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'SWISE',
    name: 'StakeWise',
    decimals: 18,
    address: '0x48c3399719b582dd63eb5aadf12a40b4c3f52fa2',
    logoURI: 'https://tokens.1inch.io/0x48c3399719b582dd63eb5aadf12a40b4c3f52fa2.png',

    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'KNC',
    name: 'Kyber Network Crystal v2',
    decimals: 18,
    address: '0xdefa4e8a7bcba345f687a2f1456f5edd9ce97202',
    logoURI: 'https://tokens.1inch.io/0xdefa4e8a7bcba345f687a2f1456f5edd9ce97202.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'YAXIS',
    name: 'yAxis V2',
    decimals: 18,
    address: '0x0ada190c81b814548ddc2f6adc4a689ce7c1fe73',
    logoURI: 'https://tokens.1inch.io/0x0ada190c81b814548ddc2f6adc4a689ce7c1fe73.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'ORBS',
    name: 'Orbs',
    decimals: 18,
    address: '0xff56cc6b1e6ded347aa0b7676c85ab0b3d08b0fa',
    logoURI: 'https://tokens.1inch.io/0xff56cc6b1e6ded347aa0b7676c85ab0b3d08b0fa.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'WXT',
    name: 'Wirex Token',
    decimals: 18,
    address: '0xa02120696c7b8fe16c09c749e4598819b2b0e915',
    logoURI: 'https://tokens.1inch.io/0xa02120696c7b8fe16c09c749e4598819b2b0e915.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'BAG',
    name: 'BAG',
    decimals: 18,
    address: '0x28a06c02287e657ec3f8e151a13c36a1d43814b0',
    logoURI: 'https://tokens.1inch.io/0x28a06c02287e657ec3f8e151a13c36a1d43814b0.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'OCC',
    name: 'OCC',
    decimals: 18,
    address: '0x2f109021afe75b949429fe30523ee7c0d5b27207',
    logoURI: 'https://tokens.1inch.io/0x2f109021afe75b949429fe30523ee7c0d5b27207.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'ILV',
    name: 'Illuvium',
    decimals: 18,
    address: '0x767fe9edc9e0df98e07454847909b5e959d7ca0e',
    logoURI: 'https://tokens.1inch.io/0x767fe9edc9e0df98e07454847909b5e959d7ca0e.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'eRSDL',
    name: 'UnFederalReserveToken',
    decimals: 18,
    address: '0x5218e472cfcfe0b64a064f055b43b4cdc9efd3a6',
    logoURI: 'https://tokens.1inch.io/0x5218e472cfcfe0b64a064f055b43b4cdc9efd3a6.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'ICE',
    name: 'IceToken',
    decimals: 18,
    address: '0xf16e81dce15b08f326220742020379b855b87df9',
    logoURI: 'https://tokens.1inch.io/0xf16e81dce15b08f326220742020379b855b87df9.png',

    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'XEND',
    name: 'XEND',
    decimals: 18,
    address: '0xe4cfe9eaa8cdb0942a80b7bc68fd8ab0f6d44903',
    logoURI: 'https://tokens.1inch.io/0xe4cfe9eaa8cdb0942a80b7bc68fd8ab0f6d44903.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'MARSH',
    name: 'UnmarshalToken',
    decimals: 18,
    address: '0x5a666c7d92e5fa7edcb6390e4efd6d0cdd69cf37',
    logoURI: 'https://tokens.1inch.io/0x5a666c7d92e5fa7edcb6390e4efd6d0cdd69cf37.png',

    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'Coval',
    name: 'CircuitsOfValue',
    decimals: 8,
    address: '0x3d658390460295fb963f54dc0899cfb1c30776df',
    logoURI: 'https://tokens.1inch.io/0x3d658390460295fb963f54dc0899cfb1c30776df.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'GLM',
    name: 'Golem Network Token',
    decimals: 18,
    address: '0x7dd9c5cba05e151c895fde1cf355c9a1d5da6429',
    logoURI: 'https://tokens.1inch.io/0x7dd9c5cba05e151c895fde1cf355c9a1d5da6429.png',

    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'TEL',
    name: 'Telcoin',
    decimals: 2,
    address: '0x467bccd9d29f223bce8043b84e8c8b282827790f',
    logoURI: 'https://tokens.1inch.io/0x467bccd9d29f223bce8043b84e8c8b282827790f.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'UDT',
    name: 'Unlock Discount Token',
    decimals: 18,
    address: '0x90de74265a416e1393a450752175aed98fe11517',
    logoURI: 'https://tokens.1inch.io/0x90de74265a416e1393a450752175aed98fe11517.png',

    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'PENDLE',
    name: 'Pendle',
    decimals: 18,
    address: '0x808507121b80c02388fad14726482e061b8da827',
    logoURI: 'https://tokens.1inch.io/0x808507121b80c02388fad14726482e061b8da827.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'WAXE',
    name: 'WAX Economic Token',
    decimals: 8,
    address: '0x7a2bc711e19ba6aff6ce8246c546e8c4b4944dfd',
    logoURI: 'https://tokens.1inch.io/0x7a2bc711e19ba6aff6ce8246c546e8c4b4944dfd.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'STC',
    name: 'Student Coin',
    decimals: 18,
    address: '0x15b543e986b8c34074dfc9901136d9355a537e7e',
    logoURI: 'https://tokens.1inch.io/0x15b543e986b8c34074dfc9901136d9355a537e7e.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'ROUTE',
    name: 'Route',
    decimals: 18,
    address: '0x16eccfdbb4ee1a85a33f3a9b21175cd7ae753db4',
    logoURI: 'https://tokens.1inch.io/0x16eccfdbb4ee1a85a33f3a9b21175cd7ae753db4.png',

    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'NII',
    name: 'Nahmii',
    decimals: 15,
    address: '0x7c8155909cd385f120a56ef90728dd50f9ccbe52',
    logoURI: 'https://tokens.1inch.io/0x7c8155909cd385f120a56ef90728dd50f9ccbe52.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'PAID',
    name: 'PAID Network',
    decimals: 18,
    address: '0x1614f18fc94f47967a3fbe5ffcd46d4e7da3d787',
    logoURI: 'https://tokens.1inch.io/0x1614f18fc94f47967a3fbe5ffcd46d4e7da3d787.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'TANGO',
    name: 'keyTango Token',
    decimals: 18,
    address: '0x182f4c4c97cd1c24e1df8fc4c053e5c47bf53bef',
    logoURI: 'https://tokens.1inch.io/0x182f4c4c97cd1c24e1df8fc4c053e5c47bf53bef.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'SHOPX',
    name: 'SPLYT SHOPX',
    decimals: 18,
    address: '0x7bef710a5759d197ec0bf621c3df802c2d60d848',
    logoURI: 'https://tokens.1inch.io/0x7bef710a5759d197ec0bf621c3df802c2d60d848.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'ARES',
    name: 'Ares Protocol',
    decimals: 18,
    address: '0x358aa737e033f34df7c54306960a38d09aabd523',
    logoURI: 'https://tokens.1inch.io/0x358aa737e033f34df7c54306960a38d09aabd523.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'CTX',
    name: 'Cryptex',
    decimals: 18,
    address: '0x321c2fe4446c7c963dc41dd58879af648838f98d',
    logoURI: 'https://tokens.1inch.io/0x321c2fe4446c7c963dc41dd58879af648838f98d.png',

    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'BANK_BANKLESS',
    name: 'Bankless Token',
    decimals: 18,
    address: '0x2d94aa3e47d9d5024503ca8491fce9a2fb4da198',
    logoURI: 'https://tokens.1inch.io/0x2d94aa3e47d9d5024503ca8491fce9a2fb4da198.png',

    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'BTC2x-FLI',
    name: 'BTC 2x Flexible Leverage Index',
    decimals: 18,
    address: '0x0b498ff89709d3838a063f1dfa463091f9801c2b',
    logoURI: 'https://tokens.1inch.io/0x0b498ff89709d3838a063f1dfa463091f9801c2b.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'SHIH',
    name: 'Shih Tzu',
    decimals: 18,
    address: '0x841fb148863454a3b3570f515414759be9091465',
    logoURI: 'https://tokens.1inch.io/0x841fb148863454a3b3570f515414759be9091465.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'FEG',
    name: 'FEGtoken',
    decimals: 9,
    address: '0x389999216860ab8e0175387a0c90e5c52522c945',
    logoURI: 'https://tokens.1inch.io/0x389999216860ab8e0175387a0c90e5c52522c945.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'O3',
    name: 'O3 Swap Token',
    decimals: 18,
    address: '0xee9801669c6138e84bd50deb500827b776777d28',
    logoURI: 'https://tokens.1inch.io/0xee9801669c6138e84bd50deb500827b776777d28.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'SHIBAKEN',
    name: 'ShibaKen.Finance',
    decimals: 0,
    address: '0xa4cf2afd3b165975afffbf7e487cdd40c894ab6b',
    logoURI: 'https://tokens.1inch.io/0xa4cf2afd3b165975afffbf7e487cdd40c894ab6b.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'KIRO',
    name: 'Kirobo',
    decimals: 18,
    address: '0xb1191f691a355b43542bea9b8847bc73e7abb137',
    logoURI: 'https://tokens.1inch.io/0xb1191f691a355b43542bea9b8847bc73e7abb137.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'CVX',
    name: 'Convex Token',
    decimals: 18,
    address: '0x4e3fbd56cd56c3e72c1403e103b45db9da5b9d2b',
    logoURI: 'https://tokens.1inch.io/0x4e3fbd56cd56c3e72c1403e103b45db9da5b9d2b.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'SNFT',
    name: 'SeedSwap Token',
    decimals: 18,
    address: '0xa3c4dc4a9ce2a6b40b57f25f8b50decc2c64dec2',
    logoURI: 'https://tokens.1inch.io/0xa3c4dc4a9ce2a6b40b57f25f8b50decc2c64dec2.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: '8PAY',
    name: '8PAY Network',
    decimals: 18,
    address: '0xfeea0bdd3d07eb6fe305938878c0cadbfa169042',
    logoURI: 'https://tokens.1inch.io/0xfeea0bdd3d07eb6fe305938878c0cadbfa169042.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'GTC',
    name: 'Gitcoin',
    decimals: 18,
    address: '0xde30da39c46104798bb5aa3fe8b9e0e1f348163f',
    logoURI: 'https://tokens.1inch.io/0xde30da39c46104798bb5aa3fe8b9e0e1f348163f.png',

    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'alUSD',
    name: 'Alchemix USD',
    decimals: 18,
    address: '0xbc6da0fe9ad5f3b0d58160288917aa56653660e9',
    logoURI: 'https://tokens.1inch.io/0xbc6da0fe9ad5f3b0d58160288917aa56653660e9.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'SARCO',
    name: 'Sarcophagus',
    decimals: 18,
    address: '0x7697b462a7c4ff5f8b55bdbc2f4076c2af9cf51a',
    logoURI: 'https://tokens.1inch.io/0x7697b462a7c4ff5f8b55bdbc2f4076c2af9cf51a.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'VUSD',
    name: 'VUSD',
    decimals: 18,
    address: '0x677ddbd918637e5f2c79e164d402454de7da8619',
    logoURI: 'https://tokens.1inch.io/0x677ddbd918637e5f2c79e164d402454de7da8619.png',

    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'ELON',
    name: 'Dogelon',
    decimals: 18,
    address: '0x761d38e5ddf6ccf6cf7c55759d5210750b5d60f3',
    logoURI: 'https://tokens.1inch.io/0x761d38e5ddf6ccf6cf7c55759d5210750b5d60f3.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'SUPERBID',
    name: 'SuperBid',
    decimals: 18,
    address: '0x0563dce613d559a47877ffd1593549fb9d3510d6',
    logoURI: 'https://tokens.1inch.io/0x0563dce613d559a47877ffd1593549fb9d3510d6.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'TONE',
    name: 'TE-FOOD/TustChain',
    decimals: 18,
    address: '0x2ab6bb8408ca3199b8fa6c92d5b455f820af03c4',
    logoURI: 'https://tokens.1inch.io/0x2ab6bb8408ca3199b8fa6c92d5b455f820af03c4.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'Skey',
    name: 'SmartKey',
    decimals: 8,
    address: '0x06a01a4d579479dd5d884ebf61a31727a3d8d442',
    logoURI: 'https://tokens.1inch.io/0x06a01a4d579479dd5d884ebf61a31727a3d8d442.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'NBU',
    name: 'Nimbus',
    decimals: 18,
    address: '0xeb58343b36c7528f23caae63a150240241310049',
    logoURI: 'https://tokens.1inch.io/0xeb58343b36c7528f23caae63a150240241310049.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'LEASH',
    name: 'DOGE KILLER',
    decimals: 18,
    address: '0x27c70cd1946795b66be9d954418546998b546634',
    logoURI: 'https://tokens.1inch.io/0x27c70cd1946795b66be9d954418546998b546634.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'DNT',
    name: 'district0x Network Token',
    decimals: 18,
    address: '0x0abdace70d3790235af448c88547603b945604ea',
    logoURI: 'https://tokens.1inch.io/0x0abdace70d3790235af448c88547603b945604ea.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'DFYN',
    name: 'DFYN Token',
    decimals: 18,
    address: '0x9695e0114e12c0d3a3636fab5a18e6b737529023',
    logoURI: 'https://tokens.1inch.io/0x9695e0114e12c0d3a3636fab5a18e6b737529023.png',

    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'MVI',
    name: 'Metaverse Index',
    decimals: 18,
    address: '0x72e364f2abdc788b7e918bc238b21f109cd634d7',
    logoURI: 'https://tokens.1inch.io/0x72e364f2abdc788b7e918bc238b21f109cd634d7.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'AKITA',
    name: 'Akita Inu',
    decimals: 18,
    address: '0x3301ee63fb29f863f2333bd4466acb46cd8323e6',
    logoURI: 'https://tokens.1inch.io/0x3301ee63fb29f863f2333bd4466acb46cd8323e6.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'LQTY',
    name: 'LQTY',
    decimals: 18,
    address: '0x6dea81c8171d0ba574754ef6f8b412f2ed88c54d',
    logoURI: 'https://tokens.1inch.io/0x6dea81c8171d0ba574754ef6f8b412f2ed88c54d.png',

    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'VRX',
    name: 'Verox',
    decimals: 18,
    address: '0x87de305311d5788e8da38d19bb427645b09cb4e5',
    logoURI: 'https://tokens.1inch.io/0x87de305311d5788e8da38d19bb427645b09cb4e5.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'TRU_Truebit',
    name: 'Truebit',
    decimals: 18,
    address: '0xf65b5c5104c4fafd4b709d9d60a185eae063276c',
    logoURI: 'https://tokens.1inch.io/0xf65b5c5104c4fafd4b709d9d60a185eae063276c.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'BBTC',
    name: 'Binance Wrapped BTC',
    decimals: 8,
    address: '0x9be89d2a4cd102d8fecc6bf9da793be995c22541',
    logoURI: 'https://tokens.1inch.io/0x9be89d2a4cd102d8fecc6bf9da793be995c22541.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'MUNCH',
    name: 'MUNCH Token',
    decimals: 9,
    address: '0x944eee930933be5e23b690c8589021ec8619a301',
    logoURI: 'https://tokens.1inch.io/0x944eee930933be5e23b690c8589021ec8619a301.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'BEZOGE',
    name: 'Bezoge Earth',
    decimals: 9,
    address: '0xdc349913d53b446485e98b76800b6254f43df695',
    logoURI: 'https://tokens.1inch.io/0xdc349913d53b446485e98b76800b6254f43df695.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'ISLE',
    name: 'Island',
    decimals: 9,
    address: '0x1681bcb589b3cfcf0c0616b0ce9b19b240643dc1',
    logoURI: 'https://tokens.1inch.io/0x1681bcb589b3cfcf0c0616b0ce9b19b240643dc1.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'DRC_1',
    name: 'Digital Reserve Currency',
    decimals: 0,
    address: '0xa150db9b1fa65b44799d4dd949d922c0a33ee606',
    logoURI: 'https://tokens.1inch.io/0xa150db9b1fa65b44799d4dd949d922c0a33ee606.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'BCP',
    name: 'BitcashPay',
    decimals: 8,
    address: '0xe047705117eb07e712c3d684f5b18e74577e83ac',
    logoURI: 'https://tokens.1inch.io/0xe047705117eb07e712c3d684f5b18e74577e83ac.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'eMax',
    name: 'EthereumMax',
    decimals: 18,
    address: '0x15874d65e649880c2614e7a480cb7c9a55787ff6',
    logoURI: 'https://tokens.1inch.io/0x15874d65e649880c2614e7a480cb7c9a55787ff6.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'INST',
    name: 'Instadapp',
    decimals: 18,
    address: '0x6f40d4a6237c257fff2db00fa0510deeecd303eb',
    logoURI: 'https://tokens.1inch.io/0x6f40d4a6237c257fff2db00fa0510deeecd303eb.png',

    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'CAVA',
    name: 'Cavapoo',
    decimals: 9,
    address: '0x456d8f0d25a4e787ee60c401f8b963a465148f70',
    logoURI: 'https://tokens.1inch.io/0x456d8f0d25a4e787ee60c401f8b963a465148f70.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'SWAPP',
    name: 'Swapp Token',
    decimals: 18,
    address: '0x8cb924583681cbfe487a62140a994a49f833c244',
    logoURI: 'https://tokens.1inch.io/0x8cb924583681cbfe487a62140a994a49f833c244.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'DVI',
    name: 'Dvision',
    decimals: 18,
    address: '0x10633216e7e8281e33c86f02bf8e565a635d9770',
    logoURI: 'https://tokens.1inch.io/0x10633216e7e8281e33c86f02bf8e565a635d9770.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'CADC',
    name: 'CAD Coin',
    decimals: 18,
    address: '0xcadc0acd4b445166f12d2c07eac6e2544fbe2eef',
    logoURI: 'https://tokens.1inch.io/0xcadc0acd4b445166f12d2c07eac6e2544fbe2eef.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'ARCX',
    name: 'ARCx Governance Token',
    decimals: 18,
    address: '0x1321f1f1aa541a56c31682c57b80ecfccd9bb288',
    logoURI: 'https://tokens.1inch.io/0xed30dd7e50edf3581ad970efc5d9379ce2614adb.png',

    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'DFI',
    name: 'Amun DeFi Index',
    decimals: 18,
    address: '0xa9536b9c75a9e0fae3b56a96ac8edf76abc91978',
    logoURI: 'https://tokens.1inch.io/0xa9536b9c75a9e0fae3b56a96ac8edf76abc91978.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'xSUSHI',
    name: 'SushiBar',
    decimals: 18,
    address: '0x8798249c2e607446efb7ad49ec89dd1865ff4272',
    logoURI: 'https://tokens.1inch.io/0x8798249c2e607446efb7ad49ec89dd1865ff4272.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'Auction',
    name: 'Bounce Token',
    decimals: 18,
    address: '0xa9b1eb5908cfc3cdf91f9b8b3a74108598009096',
    logoURI: 'https://tokens.1inch.io/0xa9b1eb5908cfc3cdf91f9b8b3a74108598009096.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'AGIX',
    name: 'SingularityNET Token',
    decimals: 8,
    address: '0x5b7533812759b45c2b44c19e320ba2cd2681b542',
    logoURI: 'https://tokens.1inch.io/0x5b7533812759b45c2b44c19e320ba2cd2681b542.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'OLY',
    name: 'Olyseum',
    decimals: 18,
    address: '0x6595b8fd9c920c81500dca94e53cdc712513fb1f',
    logoURI: 'https://tokens.1inch.io/0x6595b8fd9c920c81500dca94e53cdc712513fb1f.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'GERO',
    name: 'GeroWallet',
    decimals: 18,
    address: '0x3431f91b3a388115f00c5ba9fdb899851d005fb5',
    logoURI: 'https://tokens.1inch.io/0x3431f91b3a388115f00c5ba9fdb899851d005fb5.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'UNO',
    name: 'UnoRe',
    decimals: 18,
    address: '0x474021845c4643113458ea4414bdb7fb74a01a77',
    logoURI: 'https://tokens.1inch.io/0x474021845c4643113458ea4414bdb7fb74a01a77.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'STAK',
    name: 'Jigstack',
    decimals: 18,
    address: '0x1f8a626883d7724dbd59ef51cbd4bf1cf2016d13',
    logoURI: 'https://tokens.1inch.io/0x1f8a626883d7724dbd59ef51cbd4bf1cf2016d13.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'MPL',
    name: 'Maple Token',
    decimals: 18,
    address: '0x33349b282065b0284d756f0577fb39c158f935e6',
    logoURI: 'https://tokens.1inch.io/0x33349b282065b0284d756f0577fb39c158f935e6.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'FLX',
    name: 'Flex Ungovernance Token',
    decimals: 18,
    address: '0x6243d8cea23066d098a15582d81a598b4e8391f4',
    logoURI: 'https://tokens.1inch.io/0x6243d8cea23066d098a15582d81a598b4e8391f4.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'ETHV',
    name: 'ETH Volatility Index',
    decimals: 18,
    address: '0xc53342fd7575f572b0ff4569e31941a5b821ac76',
    logoURI: 'https://tokens.1inch.io/0xc53342fd7575f572b0ff4569e31941a5b821ac76.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'iETHV',
    name: 'Inverse ETH Volatility Index',
    decimals: 18,
    address: '0x3a707d56d538e85b783e8ce12b346e7fb6511f90',
    logoURI: 'https://tokens.1inch.io/0x3a707d56d538e85b783e8ce12b346e7fb6511f90.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'NRCH',
    name: 'EnreachDAO',
    decimals: 9,
    address: '0x69fa8e7f6bf1ca1fb0de61e1366f7412b827cc51',
    logoURI: 'https://tokens.1inch.io/0x69fa8e7f6bf1ca1fb0de61e1366f7412b827cc51.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'DEXT',
    name: 'DEXTools',
    decimals: 18,
    address: '0xfb7b4564402e5500db5bb6d63ae671302777c75a',
    logoURI: 'https://tokens.1inch.io/0x26ce25148832c04f3d7f26f32478a9fe55197166.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'one1INCH',
    name: 'Stable 1INCH',
    decimals: 18,
    address: '0x853bb55c1f469902f088a629db8c8803a9be3857',
    logoURI: 'https://tokens.1inch.io/0x853bb55c1f469902f088a629db8c8803a9be3857.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'MYOBU',
    name: 'Myōbu',
    decimals: 9,
    address: '0x75d12e4f91df721fafcae4c6cd1d5280381370ac',
    logoURI: 'https://tokens.1inch.io/0x75d12e4f91df721fafcae4c6cd1d5280381370ac.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'DVF',
    name: 'DeversiFi Token',
    decimals: 18,
    address: '0xdddddd4301a082e62e84e43f474f044423921918',
    logoURI: 'https://tokens.1inch.io/0xdddddd4301a082e62e84e43f474f044423921918.png',

    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'MM_2',
    name: 'Million',
    decimals: 18,
    address: '0x6b4c7a5e3f0b99fcd83e9c089bddd6c7fce5c611',
    logoURI: 'https://tokens.1inch.io/0x6b4c7a5e3f0b99fcd83e9c089bddd6c7fce5c611.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'EURT',
    name: 'Euro Tether',
    decimals: 6,
    address: '0xc581b735a1688071a1746c968e0798d642ede491',
    logoURI: 'https://tokens.1inch.io/0xdac17f958d2ee523a2206206994597c13d831ec7.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'HANU',
    name: 'Hanu Yokia',
    decimals: 12,
    address: '0x72e5390edb7727e3d4e3436451dadaff675dbcc0',
    logoURI: 'https://tokens.1inch.io/0x72e5390edb7727e3d4e3436451dadaff675dbcc0.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'zUSD',
    name: 'Zerogoki USD',
    decimals: 18,
    address: '0x76417e660df3e5c90c0361674c192da152a806e4',
    logoURI: 'https://tokens.1inch.io/0x76417e660df3e5c90c0361674c192da152a806e4.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'STARL',
    name: 'StarLink',
    decimals: 18,
    address: '0x8e6cd950ad6ba651f6dd608dc70e5886b1aa6b24',
    logoURI: 'https://tokens.1inch.io/0x8e6cd950ad6ba651f6dd608dc70e5886b1aa6b24.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'UPI',
    name: 'Pawtocol Network UPI Token',
    decimals: 18,
    address: '0x70d2b7c19352bb76e4409858ff5746e500f2b67c',
    logoURI: 'https://tokens.1inch.io/0x70d2b7c19352bb76e4409858ff5746e500f2b67c.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'EDEN',
    name: 'Eden',
    decimals: 18,
    address: '0x1559fa1b8f28238fd5d76d9f434ad86fd20d1559',
    logoURI: 'https://tokens.1inch.io/0x1559fa1b8f28238fd5d76d9f434ad86fd20d1559.png',

    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'PEEPS',
    name: 'The People’s Coin',
    decimals: 18,
    address: '0xe1030b48b2033314979143766d7dc1f40ef8ce11',
    logoURI: 'https://tokens.1inch.io/0xe1030b48b2033314979143766d7dc1f40ef8ce11.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'GDAO',
    name: 'Governor',
    decimals: 18,
    address: '0x515d7e9d75e2b76db60f8a051cd890eba23286bc',
    logoURI: 'https://tokens.1inch.io/0x515d7e9d75e2b76db60f8a051cd890eba23286bc.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'MINDS',
    name: 'Minds',
    decimals: 18,
    address: '0xb26631c6dda06ad89b93c71400d25692de89c068',
    logoURI: 'https://tokens.1inch.io/0xb26631c6dda06ad89b93c71400d25692de89c068.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'VOL',
    name: 'Volatility Protocol Token',
    decimals: 18,
    address: '0x5166e09628b696285e3a151e84fb977736a83575',
    logoURI: 'https://tokens.1inch.io/0x5166e09628b696285e3a151e84fb977736a83575.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'MASQ',
    name: 'MASQ',
    decimals: 18,
    address: '0x06f3c323f0238c72bf35011071f2b5b7f43a054c',
    logoURI: 'https://tokens.1inch.io/0x06f3c323f0238c72bf35011071f2b5b7f43a054c.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'KEANU',
    name: 'Keanu Inu',
    decimals: 9,
    address: '0x106552c11272420aad5d7e94f8acab9095a6c952',
    logoURI: 'https://tokens.1inch.io/0x106552c11272420aad5d7e94f8acab9095a6c952.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'COTI',
    name: 'COTI Token',
    decimals: 18,
    address: '0xddb3422497e61e13543bea06989c0789117555c5',
    logoURI: 'https://tokens.1inch.io/0xddb3422497e61e13543bea06989c0789117555c5.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'DYDX',
    name: 'dYdX',
    decimals: 18,
    address: '0x92d6c1e31e14520e676a687f0a93788b716beff5',
    logoURI: 'https://assets.coingecko.com/coins/images/17500/large/hjnIm9bV.jpg?1628009360',

    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: '1MIL',
    name: '1MILNFT',
    decimals: 18,
    address: '0xa4ef4b0b23c1fc81d3f9ecf93510e64f58a4a016',
    logoURI: 'https://tokens.1inch.io/0xa4ef4b0b23c1fc81d3f9ecf93510e64f58a4a016.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'SLAB',
    name: 'SLINK LABS',
    decimals: 9,
    address: '0xeb494890465f49c2b94457d9b61811392e5b1fea',
    logoURI: 'https://tokens.1inch.io/0xeb494890465f49c2b94457d9b61811392e5b1fea.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'BID',
    name: 'Bidao',
    decimals: 18,
    address: '0x25e1474170c4c0aa64fa98123bdc8db49d7802fa',
    logoURI: 'https://tokens.1inch.io/0x25e1474170c4c0aa64fa98123bdc8db49d7802fa.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'AGRS',
    name: 'Agoras Token',
    decimals: 8,
    address: '0x738865301a9b7dd80dc3666dd48cf034ec42bdda',
    logoURI: 'https://tokens.1inch.io/0x738865301a9b7dd80dc3666dd48cf034ec42bdda.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'BSL',
    name: 'BankSocial',
    decimals: 8,
    address: '0x0af55d5ff28a3269d69b98680fd034f115dd53ac',
    logoURI: 'https://tokens.1inch.io/0x0af55d5ff28a3269d69b98680fd034f115dd53ac.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'HAKA',
    name: 'TribeOne',
    decimals: 18,
    address: '0xd85ad783cc94bd04196a13dc042a3054a9b52210',
    logoURI: 'https://tokens.1inch.io/0xd85ad783cc94bd04196a13dc042a3054a9b52210.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'DHV',
    name: 'DeHive.finance',
    decimals: 18,
    address: '0x62dc4817588d53a056cbbd18231d91ffccd34b2a',
    logoURI: 'https://tokens.1inch.io/0x62dc4817588d53a056cbbd18231d91ffccd34b2a.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'TMM',
    name: 'Take My Muffin',
    decimals: 6,
    address: '0x19042021329fddcfbea5f934fb5b2670c91f7d20',
    logoURI: 'https://tokens.1inch.io/0x19042021329fddcfbea5f934fb5b2670c91f7d20.png',

    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'GEL',
    name: 'Gelato Network Token',
    decimals: 18,
    address: '0x15b7c0c907e4c6b9adaaaabc300c08991d6cea05',
    logoURI: 'https://tokens.1inch.io/0x15b7c0c907e4c6b9adaaaabc300c08991d6cea05.png',

    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'GOKU',
    name: 'Goku Inu',
    decimals: 9,
    address: '0xa64dfe8d86963151e6496bee513e366f6e42ed79',
    logoURI: 'https://tokens.1inch.io/0xa64dfe8d86963151e6496bee513e366f6e42ed79.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'MIM',
    name: 'Magic Internet Money',
    decimals: 18,
    address: '0x99d8a9c45b2eca8864373a26d1459e3dff1e17f3',
    logoURI: 'https://tokens.1inch.io/0x99d8a9c45b2eca8864373a26d1459e3dff1e17f3.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'SPELL',
    name: 'Spell Token',
    decimals: 18,
    address: '0x090185f2135308bad17527004364ebcc2d37e5f6',
    logoURI: 'https://tokens.1inch.io/0x090185f2135308bad17527004364ebcc2d37e5f6.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'DEUS',
    name: 'DEUS',
    decimals: 18,
    address: '0xde5ed76e7c05ec5e4572cfc88d1acea165109e44',
    logoURI: 'https://tokens.1inch.io/0xde5ed76e7c05ec5e4572cfc88d1acea165109e44.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'UNV',
    name: 'Unvest',
    decimals: 18,
    address: '0xf009f5531de69067435e32c4b9d36077f4c4a673',
    logoURI: 'https://tokens.1inch.io/0xf009f5531de69067435e32c4b9d36077f4c4a673.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'wCFG',
    name: 'Wrapped Centrifuge',
    decimals: 18,
    address: '0xc221b7e65ffc80de234bbb6667abdd46593d34f0',
    logoURI: 'https://tokens.1inch.io/0xc221b7e65ffc80de234bbb6667abdd46593d34f0.png',

    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'wstETH',
    name: 'Wrapped liquid staked Ether 2.0',
    decimals: 18,
    address: '0x7f39c581f595b53c5cb19bd0b3f8da6c935e2ca0',
    logoURI: 'https://tokens.1inch.io/0x7f39c581f595b53c5cb19bd0b3f8da6c935e2ca0.png',

    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'FLOKIPUP',
    name: 'Floki Pup',
    decimals: 9,
    address: '0xf50a07e4ff052a14f3f608da8936d8ae0ed5be50',
    logoURI: 'https://tokens.1inch.io/0xf50a07e4ff052a14f3f608da8936d8ae0ed5be50.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'PAWTH',
    name: 'Pawthereum',
    decimals: 9,
    address: '0xaecc217a749c2405b5ebc9857a16d58bdc1c367f',
    logoURI: 'https://tokens.1inch.io/0xaecc217a749c2405b5ebc9857a16d58bdc1c367f.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'MC',
    name: 'Merit Circle',
    decimals: 18,
    address: '0x949d48eca67b17269629c7194f4b727d4ef9e5d6',
    logoURI: 'https://tokens.1inch.io/0x949d48eca67b17269629c7194f4b727d4ef9e5d6.png',

    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'sBTC',
    name: 'Synth sBTC',
    decimals: 18,
    address: '0xfe18be6b3bd88a2d2a7f928d00292e7a9963cfc6',
    logoURI: 'https://tokens.1inch.io/0xfe18be6b3bd88a2d2a7f928d00292e7a9963cfc6.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'sETH',
    name: 'Synth sETH',
    decimals: 18,
    address: '0x5e74c9036fb86bd7ecdcb084a0673efc32ea31cb',
    logoURI: 'https://tokens.1inch.io/0x5e74c9036fb86bd7ecdcb084a0673efc32ea31cb.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'REDPANDA',
    name: 'Red Panda',
    decimals: 9,
    address: '0x514cdb9cd8a2fb2bdcf7a3b8ddd098caf466e548',
    logoURI: 'https://tokens.1inch.io/0x514cdb9cd8a2fb2bdcf7a3b8ddd098caf466e548.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'ZINU',
    name: 'Zombie Inu',
    decimals: 9,
    address: '0xc50ef449171a51fbeafd7c562b064b6471c36caa',
    logoURI: 'https://tokens.1inch.io/0xc50ef449171a51fbeafd7c562b064b6471c36caa.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'miniSAITAMA',
    name: 'mini SAITAMA',
    decimals: 9,
    address: '0x0c3685559af6f3d20c501b1076a8056a0a14426a',
    logoURI: 'https://tokens.1inch.io/0x0c3685559af6f3d20c501b1076a8056a0a14426a.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'TKING',
    name: 'Tiger King',
    decimals: 18,
    address: '0x24e89bdf2f65326b94e36978a7edeac63623dafa',
    logoURI: 'https://tokens.1inch.io/0x24e89bdf2f65326b94e36978a7edeac63623dafa.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'AURORA',
    name: 'Aurora',
    decimals: 18,
    address: '0xaaaaaa20d9e0e2461697782ef11675f668207961',
    logoURI: 'https://tokens.1inch.io/0xaaaaaa20d9e0e2461697782ef11675f668207961.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'MEL',
    name: 'Melalie',
    decimals: 18,
    address: '0xed0889f7e1c7c7267407222be277e1f1ef4d4892',
    logoURI: 'https://tokens.1inch.io/0xed0889f7e1c7c7267407222be277e1f1ef4d4892.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'ENS',
    name: 'Ethereum Name Service',
    decimals: 18,

    address: '0xc18360217d8f7ab5e7c516566761ea12ce7f9d72',
    logoURI: 'https://tokens.1inch.io/0xc18360217d8f7ab5e7c516566761ea12ce7f9d72.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'PP',
    name: 'Pension Plan',
    decimals: 8,
    address: '0xf14b9adf84812ba463799357f4dc716b4384010b',
    logoURI: 'https://tokens.1inch.io/0xf14b9adf84812ba463799357f4dc716b4384010b.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'ROTTS',
    name: 'ROTTSCHILD.com',
    decimals: 9,
    address: '0xfb40e79e56cc7d406707b66c4fd175e07eb2ae3c',
    logoURI: 'https://tokens.1inch.io/0xfb40e79e56cc7d406707b66c4fd175e07eb2ae3c.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'CHOPPER',
    name: 'CHOPPER INU',
    decimals: 9,
    address: '0x28c5805b64d163588a909012a628b5a03c1041f9',
    logoURI: 'https://tokens.1inch.io/0x28c5805b64d163588a909012a628b5a03c1041f9.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'CIV',
    name: 'Civilization',
    decimals: 18,
    address: '0x37fe0f067fa808ffbdd12891c0858532cfe7361d',
    logoURI: 'https://tokens.1inch.io/0x37fe0f067fa808ffbdd12891c0858532cfe7361d.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'Inu',
    name: 'Hachiko Inu',
    decimals: 18,
    address: '0xf32aa187d5bc16a2c02a6afb7df1459d0d107574',
    logoURI: 'https://tokens.1inch.io/0xf32aa187d5bc16a2c02a6afb7df1459d0d107574.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'GYEN',
    name: 'GMO JPY',
    decimals: 6,
    address: '0xc08512927d12348f6620a698105e1baac6ecd911',
    logoURI: 'https://tokens.1inch.io/0xc08512927d12348f6620a698105e1baac6ecd911.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'PSP',
    name: 'ParaSwap',
    decimals: 18,

    address: '0xcafe001067cdef266afb7eb5a286dcfd277f3de5',
    logoURI: 'https://tokens.1inch.io/0xcafe001067cdef266afb7eb5a286dcfd277f3de5.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'DINU',
    name: 'Dogey-Inu',
    decimals: 18,
    address: '0xbb1ee07d6c7baeb702949904080eb61f5d5e7732',
    logoURI: 'https://tokens.1inch.io/0xbb1ee07d6c7baeb702949904080eb61f5d5e7732.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'TONCOIN',
    name: 'Wrapped TON Coin',
    decimals: 9,
    address: '0x582d872a1b094fc48f5de31d3b73f2d9be47def1',
    logoURI: 'https://tokens.1inch.io/0x582d872a1b094fc48f5de31d3b73f2d9be47def1.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'HOKK',
    name: 'Hokkaido Inu',
    decimals: 18,
    address: '0xe87e15b9c7d989474cb6d8c56b3db4efad5b21e8',
    logoURI: 'https://tokens.1inch.io/0xe87e15b9c7d989474cb6d8c56b3db4efad5b21e8.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'APW',
    name: 'APWine Token',
    decimals: 18,
    address: '0x4104b135dbc9609fc1a9490e61369036497660c8',
    logoURI: 'https://tokens.1inch.io/0x4104b135dbc9609fc1a9490e61369036497660c8.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'rETH',
    name: 'Rocket Pool ETH',
    decimals: 18,
    address: '0xae78736cd615f374d3085123a210448e74fc6393',
    logoURI: 'https://tokens.1inch.io/0xae78736cd615f374d3085123a210448e74fc6393.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'SCA',
    name: 'ScaleSwapToken',
    decimals: 18,
    address: '0x1fbd3df007eb8a7477a1eab2c63483dcc24effd6',
    logoURI: 'https://tokens.1inch.io/0x1fbd3df007eb8a7477a1eab2c63483dcc24effd6.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'SHIBLI',
    name: 'Studio Shibli',
    decimals: 9,
    address: '0xb1a88c33091490218965787919fcc9862c1798ee',
    logoURI: 'https://tokens.1inch.io/0xb1a88c33091490218965787919fcc9862c1798ee.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'P4C',
    name: 'Parts of Four Coin',
    decimals: 18,
    address: '0x8037b1b69d6fa63a9cc053c25f7e168e6e6d857a',
    logoURI: 'https://tokens.1inch.io/0x8037b1b69d6fa63a9cc053c25f7e168e6e6d857a.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'NOTE',
    name: 'Notional',
    decimals: 8,
    address: '0xcfeaead4947f0705a14ec42ac3d44129e1ef3ed5',
    logoURI: 'https://tokens.1inch.io/0xcfeaead4947f0705a14ec42ac3d44129e1ef3ed5.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'BLANK',
    name: 'GoBlank Token',
    decimals: 18,
    address: '0x41a3dba3d677e573636ba691a70ff2d606c29666',
    logoURI: 'https://tokens.1inch.io/0xaec7e1f531bb09115103c53ba76829910ec48966.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'CMERGE',
    name: 'Coin Merge',
    decimals: 9,
    address: '0xc48b4814faed1ccc885dd6fde62a6474aecbb19a',
    logoURI: 'https://tokens.1inch.io/0xc48b4814faed1ccc885dd6fde62a6474aecbb19a.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'RBX',
    name: 'RBX',
    decimals: 18,
    address: '0x8254e26e453eb5abd29b3c37ac9e8da32e5d3299',
    logoURI: 'https://tokens.1inch.io/0x8254e26e453eb5abd29b3c37ac9e8da32e5d3299.png',

    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'SOS',
    name: 'SOS',
    decimals: 18,
    address: '0x3b484b82567a09e2588a13d54d032153f0c0aee0',
    logoURI: 'https://tokens.1inch.io/0x3b484b82567a09e2588a13d54d032153f0c0aee0.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'VXL',
    name: 'Voxel X Network',
    decimals: 18,
    address: '0x16cc8367055ae7e9157dbcb9d86fd6ce82522b31',
    logoURI: 'https://tokens.1inch.io/0x16cc8367055ae7e9157dbcb9d86fd6ce82522b31.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'GAS',
    name: 'Gas DAO',
    decimals: 18,
    address: '0x6bba316c48b49bd1eac44573c5c871ff02958469',
    logoURI: 'https://tokens.1inch.io/0x6bba316c48b49bd1eac44573c5c871ff02958469.png',

    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'TEMP',
    name: 'Tempus',
    decimals: 18,
    address: '0xa36fdbbae3c9d55a1d67ee5821d53b50b63a1ab9',
    logoURI: 'https://tokens.1inch.io/0xa36fdbbae3c9d55a1d67ee5821d53b50b63a1ab9.png',

    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'KTO',
    name: 'Kounotori',
    decimals: 9,
    address: '0x616ef40d55c0d2c506f4d6873bda8090b79bf8fc',
    logoURI: 'https://tokens.1inch.io/0x616ef40d55c0d2c506f4d6873bda8090b79bf8fc.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'RADAR',
    name: 'DappRadar',
    decimals: 18,
    address: '0x44709a920fccf795fbc57baa433cc3dd53c44dbe',
    logoURI: 'https://tokens.1inch.io/0x44709a920fccf795fbc57baa433cc3dd53c44dbe.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'ALPHA_1',
    name: 'Alpha',
    decimals: 18,
    address: '0x138c2f1123cf3f82e4596d097c118eac6684940b',
    logoURI: 'https://tokens.1inch.io/0x138c2f1123cf3f82e4596d097c118eac6684940b_1.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: '0xBTC',
    name: '0xBitcoin Token',
    decimals: 8,
    address: '0xb6ed7644c69416d67b522e20bc294a9a9b405b31',
    logoURI: 'https://tokens.1inch.io/0xb6ed7644c69416d67b522e20bc294a9a9b405b31.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'BORING',
    name: 'BoringDAO',
    decimals: 18,
    address: '0xbc19712feb3a26080ebf6f2f7849b417fdd792ca',
    logoURI: 'https://tokens.1inch.io/0xbc19712feb3a26080ebf6f2f7849b417fdd792ca.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'WTF',
    name: 'fees.wtf',
    decimals: 18,
    address: '0xa68dd8cb83097765263adad881af6eed479c4a33',
    logoURI: 'https://tokens.1inch.io/0xa68dd8cb83097765263adad881af6eed479c4a33.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'ACYC',
    name: 'AllCoinsYieldCapital',
    decimals: 18,
    address: '0xb56a1f3310578f23120182fb2e58c087efe6e147',
    logoURI: 'https://tokens.1inch.io/0xb56a1f3310578f23120182fb2e58c087efe6e147.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'GMI',
    name: 'Bankless DeFi Innovation Index',
    decimals: 18,
    address: '0x47110d43175f7f2c2425e7d15792acc5817eb44f',
    logoURI: 'https://tokens.1inch.io/0x47110d43175f7f2c2425e7d15792acc5817eb44f.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'ACCEL',
    name: 'ACCEL',
    decimals: 18,
    address: '0x7475c42f8bf2c19f4eaf12feaababa859fdc8914',
    logoURI: 'https://tokens.1inch.io/0x7475c42f8bf2c19f4eaf12feaababa859fdc8914_1.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'T',
    name: 'Threshold Network Token',
    decimals: 18,
    address: '0xcdf7028ceab81fa0c6971208e83fa7872994bee5',
    logoURI: 'https://tokens.1inch.io/0xcdf7028ceab81fa0c6971208e83fa7872994bee5.png',

    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'FLOKI',
    name: 'FLOKI',
    decimals: 9,
    address: '0xcf0c122c6b73ff809c693db761e7baebe62b6a2e',
    logoURI: 'https://etherscan.io/token/images/floki_32.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'OOKI',
    name: 'Ooki Token',
    decimals: 18,
    address: '0x0de05f6447ab4d22c8827449ee4ba2d5c288379b',
    logoURI: 'https://tokens.1inch.io/0x0de05f6447ab4d22c8827449ee4ba2d5c288379b.png',

    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'IXS',
    name: 'Ixs Token',
    decimals: 18,
    address: '0x73d7c860998ca3c01ce8c808f5577d94d545d1b4',
    logoURI: 'https://tokens.1inch.io/0x73d7c860998ca3c01ce8c808f5577d94d545d1b4.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'LOOKS',
    name: 'LooksRare Token',
    decimals: 18,
    address: '0xf4d2888d29d722226fafa5d9b24f9164c092421e',
    logoURI: 'https://tokens.1inch.io/0xf4d2888d29d722226fafa5d9b24f9164c092421e.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'JACY',
    name: 'JACY',
    decimals: 9,
    address: '0x916c5de09cf63f6602d1e1793fb41f6437814a62',
    logoURI: 'https://tokens.1inch.io/0x916c5de09cf63f6602d1e1793fb41f6437814a62.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'ANGLE',
    name: 'ANGLE',
    decimals: 18,
    address: '0x31429d1856ad1377a8a0079410b297e1a9e214c2',
    logoURI: 'https://tokens.1inch.io/0x31429d1856ad1377a8a0079410b297e1a9e214c2.png',

    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'AVINOC',
    name: 'AVINOC Token',
    decimals: 18,
    address: '0xf1ca9cb74685755965c7458528a36934df52a3ef',
    logoURI: 'https://tokens.1inch.io/0xf1ca9cb74685755965c7458528a36934df52a3ef.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'XYZ',
    name: 'XYZ Governance Token',
    decimals: 18,
    address: '0x618679df9efcd19694bb1daa8d00718eacfa2883',
    logoURI: 'https://tokens.1inch.io/0x618679df9efcd19694bb1daa8d00718eacfa2883_1.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'Metis',
    name: 'Metis Token',
    decimals: 18,
    address: '0x9e32b13ce7f2e80a01932b42553652e053d6ed8e',
    logoURI: 'https://tokens.1inch.io/0x9e32b13ce7f2e80a01932b42553652e053d6ed8e.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'SIS',
    name: 'Symbiosis',
    decimals: 18,
    address: '0xd38bb40815d2b0c2d2c866e0c72c5728ffc76dd9',
    logoURI: 'https://tokens.1inch.io/0xd38bb40815d2b0c2d2c866e0c72c5728ffc76dd9.png',

    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'DEPO',
    name: 'DePo Token',
    decimals: 18,
    address: '0xa5def515cfd373d17830e7c1de1639cb3530a112',
    logoURI: 'https://tokens.1inch.io/0xa5def515cfd373d17830e7c1de1639cb3530a112.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'X2Y2',
    name: 'X2Y2Token',
    decimals: 18,
    address: '0x1e4ede388cbc9f4b5c79681b7f94d36a11abebc9',
    logoURI: 'https://tokens.1inch.io/0x1e4ede388cbc9f4b5c79681b7f94d36a11abebc9.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'WRLD',
    name: 'NFT Worlds',
    decimals: 18,
    address: '0xd5d86fc8d5c0ea1ac1ac5dfab6e529c9967a45e9',
    logoURI: 'https://tokens.1inch.io/0xd5d86fc8d5c0ea1ac1ac5dfab6e529c9967a45e9.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'VADER',
    name: 'Vader',
    decimals: 18,
    address: '0x2602278ee1882889b946eb11dc0e810075650983',
    logoURI: 'https://tokens.1inch.io/0x2602278ee1882889b946eb11dc0e810075650983.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'UFO',
    name: 'THE TRUTH',
    decimals: 18,
    address: '0x249e38ea4102d0cf8264d3701f1a0e39c4f2dc3b',
    logoURI: 'https://tokens.1inch.io/0x249e38ea4102d0cf8264d3701f1a0e39c4f2dc3b.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'IMX',
    name: 'Immutable X',
    decimals: 18,
    address: '0xf57e7e7c23978c3caec3c3548e3d615c346e79ff',
    logoURI: 'https://tokens.1inch.io/0xf57e7e7c23978c3caec3c3548e3d615c346e79ff.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'TOKE',
    name: 'Tokemak',
    decimals: 18,
    address: '0x2e9d63788249371f1dfc918a52f8d799f4a38c94',
    logoURI: 'https://tokens.1inch.io/0x2e9d63788249371f1dfc918a52f8d799f4a38c94.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'NCR',
    name: 'Neos Credits',
    decimals: 18,
    address: '0xdb5c3c46e28b53a39c255aa39a411dd64e5fed9c',
    logoURI: 'https://tokens.1inch.io/0xdb5c3c46e28b53a39c255aa39a411dd64e5fed9c.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'SYN',
    name: 'Synapse',
    decimals: 18,
    address: '0x0f2d719407fdbeff09d87557abb7232601fd9f29',
    logoURI: 'https://tokens.1inch.io/0x0f2d719407fdbeff09d87557abb7232601fd9f29.png',

    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'NFTI',
    name: 'Scalara NFT Index',
    decimals: 18,
    address: '0x525ef76138bf76118d786dbedeae5f87aabf4a81',
    logoURI: 'https://tokens.1inch.io/0x525ef76138bf76118d786dbedeae5f87aabf4a81.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'WFLOW',
    name: 'Wrapped Flow',
    decimals: 18,
    address: '0x5c147e74d63b1d31aa3fd78eb229b65161983b2b',
    logoURI: 'https://tokens.1inch.io/0x5c147e74d63b1d31aa3fd78eb229b65161983b2b.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'BST',
    name: 'BlocksquareToken',
    decimals: 18,
    address: '0x509a38b7a1cc0dcd83aa9d06214663d9ec7c7f4a',
    logoURI: 'https://tokens.1inch.io/0x509a38b7a1cc0dcd83aa9d06214663d9ec7c7f4a.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'XDB',
    name: 'digitalbits',
    decimals: 7,
    address: '0xb9eefc4b0d472a44be93970254df4f4016569d27',
    logoURI: 'https://tokens.1inch.io/0xb9eefc4b0d472a44be93970254df4f4016569d27.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'stkATOM',
    name: 'pSTAKE Staked ATOM',
    decimals: 6,
    address: '0x44017598f2af1bd733f9d87b5017b4e7c1b28dde',
    logoURI: 'https://tokens.1inch.io/0x44017598f2af1bd733f9d87b5017b4e7c1b28dde.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'THOR',
    name: 'THORSwap Token',
    decimals: 18,
    address: '0xa5f2211b9b8170f694421f2046281775e8468044',
    logoURI: 'https://tokens.1inch.io/0xa5f2211b9b8170f694421f2046281775e8468044.png',

    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'FODL',
    name: 'Fodl',
    decimals: 18,
    address: '0x4c2e59d098df7b6cbae0848d66de2f8a4889b9c3',
    logoURI: 'https://tokens.1inch.io/0x4c2e59d098df7b6cbae0848d66de2f8a4889b9c3.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'APE',
    name: 'ApeCoin',
    decimals: 18,
    address: '0x4d224452801aced8b2f0aebe155379bb5d594381',
    logoURI: 'https://tokens.1inch.io/0x4d224452801aced8b2f0aebe155379bb5d594381.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  {
    symbol: 'STG',
    name: 'StargateToken',
    decimals: 18,
    address: '0xaf5191b0de278c7286d6c7cc6ab6bb8a73ba2cd6',
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18934.png',
    chainId: SupportedChainId.ETHEREUM,
  },
]
