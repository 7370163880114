import { useEffect, useRef, useState } from 'react'
import useTheme from 'hooks/useTheme'
import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'
import { useSwapState } from 'state/swap/selectors'
import { SWAP_STATES } from 'state/swap/types'

interface ISpinnerProgress extends SvgProps {
  color?: string
  seconds?: number
}

const Icon: React.FC<ISpinnerProgress> = (props) => {
  const { theme } = useTheme()
  const progress = useRef<any>()
  const pageState = useSwapState()
  const defaultTime = props.seconds ? props.seconds : 20
  const [time, setTime] = useState(defaultTime)

  useEffect(() => {
    if (pageState === SWAP_STATES.ROUTES_LOADING) setTime(0)
    else {
      setTimeout(() => {
        setTime(defaultTime)
        handleClick()
      }, 100)
    }
  }, [pageState])

  const handleClick = () => {
    progress?.current?.beginElement()
  }

  return (
    <Svg viewBox="-10 -10 120 120" {...props} style={{ transform: 'rotate(-90deg)' }} onClick={handleClick}>
      <circle cx="50" cy="50" r="44" fill="none" stroke={theme.colors.text} strokeWidth="15" />
      <circle
        cx="50"
        cy="50"
        r="44"
        fill="none"
        stroke={props.color || theme.colors.textOrange}
        strokeWidth="15"
        pathLength="100"
        strokeDasharray="100"
        strokeDashoffset="100"
      >
        <animate
          ref={progress}
          attributeName="stroke-dashoffset"
          values="0;100"
          dur={`${time}s`}
          repeatCount="indefinite"
        />
      </circle>
    </Svg>
  )
}

export default Icon
