import { Pool } from 'state/pools/types'

export interface StakingForm {
  setRefresh: Function //to sync both stake unstake tab on transaction
  refresh: boolean
}

export interface PoolProps {
  isPoolScreen: boolean
  pools?: Pool[]
  loading?: boolean
}

export interface PoolStatsProps {
  pool: Pool
}

export enum AmountKey {
  INPUT = 'amount0',
  OUTPUT = 'amount1',
}

export interface IAmountType {
  [AmountKey.INPUT]: string
  [AmountKey.OUTPUT]: string
}
