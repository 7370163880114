import { TabText, WalletInfoSection } from '../../styles'
import { Flex } from 'components/Box'
import { useTranslation } from 'contexts/Localization'
import { SupportedChainId, SupportedEVMChainId } from 'config/constants/chains'
import WalletInfo from '../WalletInfo'
import { Field } from 'state/types'
import RenderWalletList from '../WalletList'
import { useGetBlockchainInfo } from 'state/swap/selectors'

import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useWallet } from '@solana/wallet-adapter-react'

const WalletBox = ({ field, isForConfirmation, setWalletLocalState, walletLocalState }) => {
  const { t } = useTranslation()
  const blockchainNetwork = useGetBlockchainInfo(field)

  const { account } = useActiveWeb3React()
  const { publicKey } = useWallet()

  const RenderChooseWallet = () => {
    return (
      <>
        {SupportedEVMChainId(walletLocalState.chainId) && account ? (
          <WalletInfo walletLocalState={walletLocalState} />
        ) : walletLocalState.chainId === SupportedChainId.TRON &&
          window.tronWeb &&
          window.tronWeb.defaultAddress.base58 ? (
          <WalletInfo walletLocalState={walletLocalState} />
        ) : !SupportedEVMChainId(walletLocalState.chainId) && publicKey ? (
          <WalletInfo walletLocalState={walletLocalState} />
        ) : (
          <RenderWalletList
            field={field}
            isForConfirmation={isForConfirmation}
            setWalletLocalState={setWalletLocalState}
            walletLocalState={walletLocalState}
          />
        )}
      </>
    )
  }

  /*
  *  walletInfo ? (
          (walletInfo.chainId !== SupportedChainId.SOLANA && localChain !== SupportedChainId.SOLANA) ||
          (walletInfo.chainId === SupportedChainId.SOLANA && localChain === SupportedChainId.SOLANA) ? (
            <WalletInfo field={field} isForConfirmation={isForConfirmation} />
          ) : (
            <RenderWalletList field={field} isForConfirmation={isForConfirmation} localChain={localChain} />
          )
        ) : (
          <RenderWalletList field={field} isForConfirmation={isForConfirmation} localChain={localChain} />
        )
  * */

  return (
    <WalletInfoSection padding={'0.1rem 0'} flexDirection={'row'} justifyContent={'start'}>
      {/* wallet list box header , only show for swap wallet modal  */}
      {isForConfirmation && (
        <Flex width={'100%'} justifyContent={'space-between'}>
          <TabText ml="10px">{field === Field.INPUT ? t('From') : t('To')}</TabText>
          <Flex mr="10px">
            {blockchainNetwork.icon}
            <TabText ml="4px" textTransform="capitalize">
              {blockchainNetwork.name}
            </TabText>
          </Flex>
        </Flex>
      )}
      {/* Render body for wallet list box */}
      <RenderChooseWallet />
    </WalletInfoSection>
  )
}

export default WalletBox
