import { useCallback } from 'react'
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core'

import { connectorsByName } from 'utils/web3React'
import { setupNetwork } from 'utils/wallet'
import { useTranslation } from 'contexts/Localization'
import { clearUserStates } from 'utils/clearUserStates'
import { connectorLocalStorageKey, ConnectorNames } from 'config/constants/types'
import {
  NoEthereumProviderError,
  UserRejectedRequestError as UserRejectedRequestErrorInjected,
} from '@web3-react/injected-connector'
import { Field } from 'state/types'
import { useSwapActionHandlers } from 'state/swap/hooks'
import { useWalletActionHandlers } from 'state/wallets/hooks'
import useToast from 'hooks/useToast'
import { useChainInfo } from 'state/swap/selectors'
import { SupportedChainId, SupportedEVMChainId } from 'config/constants/chains'
import { useWallet } from '@solana/wallet-adapter-react'
import { useChainInfo as useChainStableSwapInfo } from 'state/stableSwap/selectors'
import { useSwapSelectedTab } from 'state/global/selectors'
import { SWAP_TAB } from 'state/global/types'

const useAuth = () => {
  const { t } = useTranslation()
  const { chainId: metamaskActiveChainId, activate, deactivate } = useWeb3React()
  const { toastError } = useToast()
  const { disconnect: disconnectSolana } = useWallet()

  const { onWalletStatusUpdate } = useWalletActionHandlers()
  const selectedTab = useSwapSelectedTab()

  const inputChainId = selectedTab === SWAP_TAB.STABLE ? useChainStableSwapInfo(Field.INPUT) : useChainInfo(Field.INPUT)
  const outputChainId =
    selectedTab === SWAP_TAB.STABLE ? useChainStableSwapInfo(Field.OUTPUT) : useChainInfo(Field.OUTPUT)

  const connectEVM = useCallback(
    async (connectorID: ConnectorNames, chainId, field: Field, isForConfirmation: boolean) => {
      const connector = connectorsByName[connectorID]

      if (connector) {
        await activate(connector, async (error: Error) => {
          if (error instanceof NoEthereumProviderError) {
            toastError(t('Error'), t('Please Install Wallet Extension'))
          } else if (error instanceof UnsupportedChainIdError) {
            const hasSetup = await setupNetwork(chainId)
            if (hasSetup) {
              activate(connector)
              if (isForConfirmation) {
                SupportedEVMChainId(inputChainId) && onWalletStatusUpdate(Field.INPUT, { chainId: inputChainId })
                SupportedEVMChainId(outputChainId) && onWalletStatusUpdate(Field.OUTPUT, { chainId: outputChainId })
              }
            }
          } else {
            window.localStorage.removeItem(connectorLocalStorageKey)
            if (error instanceof UserRejectedRequestErrorInjected) {
              toastError(t('Authorization Error'), t('Please authorize to access your account'))
            } else {
              if (error?.message.includes('Already processing')) {
                toastError(t('Already Processing'), t('Kindly Check Your Wallet'))
              } else {
                toastError(error.name, error.message)
              }
            }
          }
        })
        if (isForConfirmation) {
          SupportedEVMChainId(inputChainId) && onWalletStatusUpdate(Field.INPUT, { chainId: inputChainId })
          SupportedEVMChainId(outputChainId) && onWalletStatusUpdate(Field.OUTPUT, { chainId: outputChainId })
        }
      } else {
        toastError(t('Error'), t('Invalid Wallet'))
      }
    },
    [t, activate, metamaskActiveChainId],
  )

  const disconnectEVM = useCallback(async () => {
    deactivate()
    SupportedEVMChainId(inputChainId) && onWalletStatusUpdate(Field.INPUT, null)
    SupportedEVMChainId(outputChainId) && onWalletStatusUpdate(Field.OUTPUT, null)
    toastError(t('Disconnect Info'), t('Wallet Disconnect'))
    clearUserStates()
  }, [deactivate])

  const disconnectSol = useCallback(async () => {
    // just disconnect solana function useWalletStateHandler in state/wallet/hooks.ts will handle the redux state for solana disconnection
    disconnectSolana()
    toastError(t('Disconnect Info'), t('Wallet Disconnect'))
    clearUserStates()
  }, [deactivate])

  const disconnectTron = useCallback(async () => {
    inputChainId === SupportedChainId.TRON && onWalletStatusUpdate(Field.INPUT, null)
    outputChainId === SupportedChainId.TRON && onWalletStatusUpdate(Field.OUTPUT, null)
    toastError(t('Disconnect Info'), t('Wallet Disconnect'))
    clearUserStates()
  }, [deactivate])

  return { connectEVM, disconnectEVM, disconnectSol, disconnectTron }
}

export default useAuth
