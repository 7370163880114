import { SolanaIcon, EthIcon, BNBIcon, PolygonIcon, AvaxIcon, FTMIcon } from 'components/Svg'
import { SupportedChainId } from './chains'
import { IBlockchainNetworks } from './types'
import { CurrencyAmount } from 'config/entities'
import TronIcon from 'components/Svg/Icons/Tron'

const BlockchainNetworks: IBlockchainNetworks[] = [
  {
    name: 'Solana',
    icon: <SolanaIcon />,
    chainId: SupportedChainId.SOLANA,
    eventKey: 'SOL',
    minimumFee: new CurrencyAmount(0.005, 9, false),
    decimals: 9,
  },
  {
    name: 'Ethereum',
    icon: <EthIcon />,
    chainId: SupportedChainId.ETHEREUM,
    eventKey: 'ETH',
    minimumFee: new CurrencyAmount(0.01, 18, false),
    decimals: 18,
  },
  {
    name: 'Binance',
    icon: <BNBIcon />,
    chainId: SupportedChainId.BINANCE,
    eventKey: 'BNB',
    minimumFee: new CurrencyAmount(0.006, 18, false),
    decimals: 18,
  },
  {
    name: 'Polygon',
    icon: <PolygonIcon />,
    chainId: SupportedChainId.POLYGON,
    eventKey: 'MATIC',
    minimumFee: new CurrencyAmount(0.2, 18, false),
    decimals: 18,
  },
  {
    name: 'Avalanche',
    icon: <AvaxIcon />,
    chainId: SupportedChainId.AVAX,
    eventKey: 'AVAX',
    minimumFee: new CurrencyAmount(0.03, 18, false),
    decimals: 18,
  },
  {
    name: 'Tron',
    icon: <TronIcon />,
    chainId: SupportedChainId.TRON,
    eventKey: 'TRX',
    minimumFee: new CurrencyAmount(20, 6, false),
    decimals: 6,
  },
  {
    name: 'Fantom',
    icon: <FTMIcon />,
    chainId: SupportedChainId.FANTOM,
    eventKey: 'FTM',
    minimumFee: new CurrencyAmount(0.3, 18, false),
    decimals: 18,
  },
]

export default BlockchainNetworks
