import { createReducer } from '@reduxjs/toolkit'
import { updateVersion } from '../global/actions'
import {
  updateStableSwapPageState,
  setStableSwapChains,
  setStableInputAmount,
  setStableFee,
  selectChain,
  selectCurrency,
  switchCurrencies,
  resetSwapState,
  setStableReceivedAmount,
  setSteps,
  setActiveStepIndex,
  setActionState,
  setStepResult,
  setChainAndToken,
  updateTransactionId,
} from './actions'
import { Field } from 'state/types'
import { ACTION_STEP_STATE, SWAP_STATES } from 'state/swap/types'
import { IStableSwapSection, StableSwapState } from './type'
import { CHAIN_KEYS, CHAIN_KEYS_TO_SUPPORTED_CHAIN_ID } from 'config/constants/chains'
import { CurrencyAmount } from 'config/entities'
import _ from 'lodash'

const initialSwapSectionState: IStableSwapSection = {
  currencies: [],
  currency: null,
  chainId: null,
}

export const initialState: StableSwapState = {
  [Field.INPUT]: initialSwapSectionState,
  [Field.OUTPUT]: initialSwapSectionState,
  stableSwapPageState: SWAP_STATES.DEFAULT,
  supportedChains: null,
  inputAmount: new CurrencyAmount(0, 0),
  fee: new CurrencyAmount(0, 0),
  receivedAmount: new CurrencyAmount(0, 0),
  steps: [],
  activeStepIndex: 0,
  actionStepState: {
    status: ACTION_STEP_STATE.IN_PROGRESS,
    message: '',
  },
  transactionId: '',
}

export default createReducer(initialState, (builder) =>
  builder
    .addCase(updateVersion, () => {
      return {
        ...initialState,
      }
    })
    .addCase(updateStableSwapPageState, (state, { payload: { swapPageState } }) => {
      return {
        ...state,
        stableSwapPageState: swapPageState,
      }
    })
    .addCase(updateTransactionId, (state, { payload: { transactionId } }) => {
      return {
        ...state,
        transactionId,
      }
    })
    .addCase(selectCurrency, (state, { payload: { field, currency } }) => {
      const otherSideCurrency = field === Field.INPUT ? state[Field.OUTPUT].currency : state[Field.INPUT].currency
      if (currency === otherSideCurrency) {
        return state
      }
      const stateInfo = state[field]

      return {
        ...state,
        [field]: {
          ...stateInfo,
          currency,
        },
      }
    })
    .addCase(selectChain, (state, { payload: { chainId, field } }) => {
      const stateInfo = state[field]
      //Set token in state for stable swap
      const currencies = state?.supportedChains ? state?.supportedChains[CHAIN_KEYS[chainId]]?.tokens : []

      // to set default token when user change network.
      let currency = currencies && currencies.length > 0 ? currencies[0] : null
      if (
        (field === Field.INPUT && chainId === state.OUTPUT.chainId) ||
        (field === Field.OUTPUT && chainId === state.INPUT.chainId)
      ) {
        currency = currencies && currencies.length > 1 ? currencies[1] : null
      }
      return {
        ...state,
        [field]: {
          ...stateInfo,
          chainId,
          currencies,
          currency,
        },
      }
    })
    .addCase(switchCurrencies, (state) => {
      const inputStateInfo = state[Field.INPUT]
      const outputStateInfo = state[Field.OUTPUT]
      return {
        ...state,
        [Field.INPUT]: {
          ...outputStateInfo,
        },
        [Field.OUTPUT]: {
          ...inputStateInfo,
        },
      }
    })
    .addCase(resetSwapState, (state) => {
      const inputStateInfo = state[Field.INPUT]
      const outputStateInfo = state[Field.OUTPUT]
      return {
        ...state,
        [Field.OUTPUT]: {
          ...outputStateInfo,
        },
        pageState: SWAP_STATES.DEFAULT,
        inputAmount: new CurrencyAmount(0, inputStateInfo.currency.decimals),
        receivedAmount: new CurrencyAmount(0, 0),
        steps: [],
        activeStepIndex: 0,
        actionStepState: {
          status: ACTION_STEP_STATE.IN_PROGRESS,
          message: '',
        },
        transactionId: '',
      }
    })
    .addCase(setStableSwapChains, (state, { payload: { supportedChains } }) => {
      // this is called for the first time when page load.
      const supportedChainKeys = Object.keys(supportedChains)
      if (supportedChainKeys.length < 2) {
        // in case empty or null
        return {
          ...state,
          supportedChains: null,
        }
      }
      return {
        ...state,
        supportedChains,

        [Field.INPUT]: {
          currencies: supportedChains[supportedChainKeys[0]].tokens,
          currency: supportedChains[supportedChainKeys[0]].tokens[0],
          chainId: CHAIN_KEYS_TO_SUPPORTED_CHAIN_ID[supportedChainKeys[0]],
        },
        [Field.OUTPUT]: {
          currencies: supportedChains[supportedChainKeys[1]].tokens,
          currency: supportedChains[supportedChainKeys[1]].tokens[0],
          chainId: CHAIN_KEYS_TO_SUPPORTED_CHAIN_ID[supportedChainKeys[1]],
        },
      }
    })
    .addCase(setStableInputAmount, (state, { payload: { inputAmount } }) => {
      return {
        ...state,
        inputAmount,
      }
    })
    .addCase(setStableReceivedAmount, (state, { payload: { receivedAmount } }) => {
      return {
        ...state,
        receivedAmount,
      }
    })
    .addCase(setStableFee, (state, { payload: { fee } }) => {
      return {
        ...state,
        fee,
      }
    })
    .addCase(setSteps, (state, { payload: { steps } }) => {
      return {
        ...state,
        steps,
      }
    })
    .addCase(setActiveStepIndex, (state, { payload: { resultActionStepState } }) => {
      const updatedSteps = _.cloneDeep(state.steps)
      updatedSteps[state.activeStepIndex].result = resultActionStepState.payload
      return {
        ...state,
        activeStepIndex: state.activeStepIndex + 1,
        steps: updatedSteps,
        actionStepState: {
          status: ACTION_STEP_STATE.IN_PROGRESS,
          message: '',
        },
      }
    })
    .addCase(setActionState, (state, { payload: { actionStepState } }) => {
      return {
        ...state,
        actionStepState,
      }
    })
    .addCase(setStepResult, (state, { payload: { actionStepState } }) => {
      const updatedSteps = _.cloneDeep(state.steps)
      const { payload } = actionStepState
      const index = state.activeStepIndex
      if (updatedSteps && updatedSteps[index]) {
        updatedSteps[index].result = payload
      }
      return {
        ...state,
        steps: updatedSteps,
      }
    })
    .addCase(
      setChainAndToken,
      (state, { payload: { fromChain, toChain, fromCurrency, toCurrency, fromCurrencies, toCurrencies } }) => {
        const inputStateInfo = state[Field.INPUT],
          outputStateInfo = state[Field.OUTPUT]

        return {
          ...state,
          [Field.OUTPUT]: {
            ...outputStateInfo,
            chainId: toChain,
            currency: toCurrency,
            currencies: toCurrencies,
          },
          [Field.INPUT]: {
            ...inputStateInfo,
            chainId: fromChain,
            currency: fromCurrency,
            currencies: fromCurrencies,
          },
        }
      },
    ),
)
