import { Box, Flex } from 'components/Box'
import { FailureIcon, InfoIcon, SuccessIcon } from 'components/Svg'
import React, { useCallback, useEffect, useRef } from 'react'
import { CSSTransition } from 'react-transition-group'
import styled from 'styled-components'
import { ToastProps, types as toastTypes } from './types'

const StyledToast = styled.div`
  right: 16px;
  position: fixed;
  min-width: 300px;
  transition: all 250ms ease-in;
  max-width: 400px;
  background: linear-gradient(105deg, #22203e 0%, #111027 100%);
  border: solid 1px ${({ theme }) => theme.colors.backgroundSubtle};
  border-radius: 4px;
  padding: 0.6rem;

  .title {
    color: ${({ theme }) => theme.colors.text};
    font-family: ${({ theme }) => theme.fonts.primary};
    font-weight: ${({ theme }) => theme.fonts.semiBold};
    margin: 0;
  }

  .description {
    color: ${({ theme }) => theme.colors.text};
    font-family: ${({ theme }) => theme.fonts.secondary};
    font-weight: ${({ theme }) => theme.fonts.light};
    margin: 0;
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    max-width: 400px;
  }
`

const Toast: React.FC<ToastProps> = ({ toast, onRemove, style, ttl, ...props }) => {
  const timer = useRef<number>()
  const ref = useRef(null)
  const removeHandler = useRef(onRemove)
  const { id, title, description, type } = toast

  const handleRemove = useCallback(() => removeHandler.current(id), [id, removeHandler])

  const handleMouseEnter = () => {
    clearTimeout(timer.current)
  }

  const handleMouseLeave = () => {
    if (timer.current) {
      clearTimeout(timer.current)
    }

    timer.current = window.setTimeout(() => {
      handleRemove()
    }, ttl)
  }

  useEffect(() => {
    if (timer.current) {
      clearTimeout(timer.current)
    }

    timer.current = window.setTimeout(() => {
      handleRemove()
    }, ttl)

    return () => {
      clearTimeout(timer.current)
    }
  }, [timer, ttl, handleRemove])

  return (
    <CSSTransition nodeRef={ref} timeout={250} style={style} {...props}>
      <StyledToast ref={ref} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <div>
          <Flex alignItems="flex-start">
            <Box>
              {type === toastTypes.SUCCESS ? (
                <SuccessIcon width={60} />
              ) : type === toastTypes.DANGER ? (
                <FailureIcon width={60} />
              ) : (
                <InfoIcon width={60} />
              )}
            </Box>
            <Flex ml="0.4rem" flexDirection="column">
              <p className="title">{title}</p>
              <p className="description">{description}</p>
            </Flex>
          </Flex>
        </div>
      </StyledToast>
    </CSSTransition>
  )
}

export default Toast
