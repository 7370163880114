import { createReducer } from '@reduxjs/toolkit'
import { updateBalance } from './actions'
import updateVersion from 'state/global/actions'
import { IStakeState } from './types'

const initialStakeState: IStakeState = {
  shouldUpdateBalance: false,
}

export default createReducer(initialStakeState, (builder) =>
  builder
    .addCase(updateVersion, () => {
      return {
        ...initialStakeState,
      }
    })
    .addCase(updateBalance, (state) => {
      return {
        ...state,
        shouldUpdateBalance: !state.shouldUpdateBalance,
      }
    }),
)
