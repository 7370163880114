import { Flex } from 'components/Box'
import { AnimatedArrows, ArrowIcon, FlexIconWrapper, TextBox } from '../styles'
import { useActiveStepState } from 'state/swap/selectors'
import { ACTION_STEP_STATE, lockActionSet, SWAP_ACTIONS, unlockActionSet } from 'state/swap/types'
import { BridgeIcon } from 'components/Svg'
import { getIconByUrl } from './SwapSuccessCard'

const LockUnlockCard = ({ step }) => {
  const { activeStepState } = useActiveStepState()

  return (
    <Flex width={'100%'} justifyContent={'space-between'}>
      {step.name === SWAP_ACTIONS.UNLOCK_EVM ? (
        <FlexIconWrapper>
          {getIconByUrl(step.payload.outputMintCurrency.logoURI)}
          <TextBox>{step.payload.outputMintCurrency.symbol}</TextBox>
        </FlexIconWrapper>
      ) : lockActionSet.has(step.name) ? (
        <FlexIconWrapper>
          {getIconByUrl(step.payload.inputLockCurrency.logoURI)}
          <TextBox>{step.payload.inputLockCurrency.symbol}</TextBox>
        </FlexIconWrapper>
      ) : (
        <FlexIconWrapper>
          <BridgeIcon width="40px" height="40px" />
          <TextBox>Bridge</TextBox>
        </FlexIconWrapper>
      )}
      <Flex width={'100%'} alignItems={'center'} justifyContent={'center'} flex={1}>
        {activeStepState.status === ACTION_STEP_STATE.OK || activeStepState.status === ACTION_STEP_STATE.ERROR ? (
          <ArrowIcon />
        ) : (
          <AnimatedArrows>
            <span className="arrow one next bounceAlpha" />
            <span className="arrow two next bounceAlpha" />
            <span className="arrow three next bounceAlpha" />
          </AnimatedArrows>
        )}
      </Flex>
      {step.name === SWAP_ACTIONS.UNLOCK_EVM ? (
        <FlexIconWrapper>
          {getIconByUrl(step.payload.outputCurrency.logoURI)}
          <TextBox>{step.payload.outputCurrency.symbol}</TextBox>
        </FlexIconWrapper>
      ) : unlockActionSet.has(step.name) ? (
        <FlexIconWrapper>
          {getIconByUrl(step.payload.outputMintCurrency.logoURI)}
          <TextBox>{step.payload.outputMintCurrency.symbol}</TextBox>
        </FlexIconWrapper>
      ) : (
        <FlexIconWrapper>
          <BridgeIcon width="40px" height="40px" />
          <TextBox>Bridge</TextBox>
        </FlexIconWrapper>
      )}
    </Flex>
  )
}

export default LockUnlockCard
