import Footer from 'components/Footer'
import Header from 'components/Header'
import styled from 'styled-components'

const LayoutWrapper = ({ children }) => {
  return (
    <Contaier>
      <Header />
      {children}
      <Footer />
    </Contaier>
  )
}

export default LayoutWrapper

const Contaier = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`
