import { createReducer } from '@reduxjs/toolkit'
import { updateGasLimit, updateGasPrice } from './actions'
import updateVersion from 'state/global/actions'
import { IBlockChainState } from './types'
import { SupportedChainId } from 'config/constants/chains'
import { BigNumber } from '@ethersproject/bignumber'

const initialStakeState: IBlockChainState = {
  blockChainUtils: [
    {
      chainId: SupportedChainId.SOLANA,
      gasPrice: BigNumber.from(100000000000),
      gasLimit: BigNumber.from(50000),
      symbol: 'SOL',
    },
    {
      chainId: SupportedChainId.ETHEREUM,
      gasPrice: BigNumber.from(75000000000),
      gasLimit: BigNumber.from(500000),
      symbol: 'ETH',
    },
    {
      chainId: SupportedChainId.BINANCE,
      gasPrice: BigNumber.from(10000000000),
      gasLimit: BigNumber.from(500000),
      symbol: 'BNB',
    },
    {
      chainId: SupportedChainId.POLYGON,
      gasPrice: BigNumber.from(40000000000),
      gasLimit: BigNumber.from(500000),
      symbol: 'MATIC',
    },
    {
      chainId: SupportedChainId.AVAX,
      gasPrice: BigNumber.from(35000000000),
      gasLimit: BigNumber.from(500000),
      symbol: 'AVAX',
    },
    {
      chainId: SupportedChainId.FANTOM,
      gasPrice: BigNumber.from(250000000000),
      gasLimit: BigNumber.from(500000),
      symbol: 'FTM',
    },
  ],
}

export default createReducer(initialStakeState, (builder) =>
  builder
    .addCase(updateVersion, () => {
      return {
        ...initialStakeState,
      }
    })
    .addCase(updateGasLimit, (state, { payload: { chainId, gasLimit } }) => {
      return {
        ...state,
        blockChainUtils: state.blockChainUtils.map((content) =>
          content.chainId === chainId ? { ...content, gasLimit: gasLimit } : content,
        ),
      }
    })
    .addCase(updateGasPrice, (state, { payload: { chainId, gasPrice } }) => {
      return {
        ...state,
        blockChainUtils: state.blockChainUtils.map((content) =>
          content.chainId === chainId ? { ...content, gasPrice: gasPrice } : content,
        ),
      }
    }),
)
