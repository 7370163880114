import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, AppState } from '../../index'
import { toggleTheme as toggleThemeAction, updateUserSlippageTolerance, hidePhishingWarningBanner } from '../actions'
import { Fraction, Percent } from 'config/entities'
import { toPercent } from 'config/entities/fractions/percent'

export function useThemeManager(): [boolean, () => void] {
  const dispatch = useDispatch<AppDispatch>()
  const isDark = useSelector<AppState, AppState['user']['isDark']>((state) => state.user.isDark)

  const toggleTheme = useCallback(() => {
    dispatch(toggleThemeAction())
  }, [dispatch])

  return [isDark, toggleTheme]
}

export function useUserSlippageTolerance(): [Percent, (slippage: Percent) => void] {
  const dispatch = useDispatch<AppDispatch>()
  const userSlippageTolerance = useSelector<AppState, AppState['user']['userSlippageTolerance']>((state) => {
    return state.user.userSlippageTolerance
  })

  const setUserSlippageTolerance = useCallback(
    (slippage: Percent) => {
      dispatch(updateUserSlippageTolerance({ userSlippageTolerance: slippage }))
    },
    [dispatch],
  )

  return [
    toPercent(new Fraction(userSlippageTolerance.numerator, userSlippageTolerance.denominator)),
    setUserSlippageTolerance,
  ]
}
