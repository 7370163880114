import { useSelector } from 'react-redux'
import { AppState } from 'state'

export function useSwapSelectedTab() {
  const state = useSelector<AppState, AppState['global']>((s) => s.global)
  return state.selectedSwapTab
}

export function useCryptoUSDRate(key: string) {
  const state = useSelector<AppState, AppState['global']>((s) => s.global)
  return state.cryptoUSDRates.get(key)
}
