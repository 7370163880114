export const API_PROFILE = process.env.NEXT_PUBLIC_API_PROFILE
export const ONE_INCH_API_URL = 'https://api.1inch.exchange/v4.0/'
export const JUPITER_API_URL = 'https://price.jup.ag/v1/'
export const ONE_INCH_API_PATH_FINDER = `https://pathfinder.1inch.io/v1.2/chain`
export const ZERO_X_API_URL = {
  1: 'https://api.0x.org/',
  56: 'https://bsc.api.0x.org/',
  137: 'https://polygon.api.0x.org/',
  43114: 'https://avalanche.api.0x.org/',
  250: 'https://fantom.api.0x.org/',
}

export const APY_URL = 'https://api2.atlasdex.finance/staking/info?key=apy'
export const ATS_TO_USD_URL = 'https://api.coingecko.com/api/v3/simple/price?ids=atlas-dex&vs_currencies=usd'
export const HOW_TO_STAKE_LINK = 'https://docs.atlasdex.finance/atlas/tokenomics-and-governance/ATS-Staking'
export const POOLS_BASE_URL = 'https://pools-with-master.dpb2qot6zcip1.amplifyapp.com'
// export const POOLS_BASE_URL = 'http://localhost:3000'
export const HOMEPAGE = 'https://atlasdex.finance/'
export const STEP_BY_STEP_GUIDE = 'https://docs.atlasdex.finance/atlas/Getting-Started/step-by-step-guide'
export const FAQ = 'https://docs.atlasdex.finance/atlas/Getting-Started/support-and-faqs'

// export const RELAYER_BACKEND_BASE_URL = 'http://localhost:4000'
export const RELAYER_BACKEND_BASE_URL = 'https://api-relayer.atlasdex.finance'

export const COVALENT_API_RECENT_TX =
  'https://api.covalenthq.com/v1/:chainId/address/:address/transactions_v2/?&key=ckey_892dee9a47524a4dabcd4ea223b&limit=1'
export const ADDRESS_EXPLORER_LINK = {
  101: 'https://solscan.io/account/',
  1: 'https://etherscan.io/address/',
  56: 'https://bscscan.com/address/',
  137: 'https://polygonscan.com/address/',
  43114: 'https://snowtrace.io/address/',
  250: 'https://ftmscan.com/address/',
}

export const COINGECKO_BASE_URL = 'https://api.coingecko.com/api/v3'
export const SOLANA_RPC_ENDPOINT =
  'https://chaotic-sly-flower.solana-mainnet.discover.quiknode.pro/58d46671a6b71d0b85d1b3f6c52b1f7d0f8fe79c/'

export const JUPITER_API_URL_V3 = 'https://quote-api.jup.ag/v4'
