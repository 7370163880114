import { useEffect, useState } from 'react'
import * as Sentry from '@sentry/react'
import axios from 'axios'
import { ATS_TO_USD_URL } from 'config/constants/endpoints'
import useRefresh from './useRefresh'

const useATSPrice = () => {
  const { slowRefresh } = useRefresh()
  const [atsPriceInUSD, setATSPriceInUsd] = useState(0)

  useEffect(() => {
    async function getATSPrice() {
      try {
        const { data } = await axios.get(ATS_TO_USD_URL)
        setATSPriceInUsd(data['atlas-dex']?.usd)
      } catch (error) {
        Sentry.captureException(error)
      }
    }

    getATSPrice()
  }, [slowRefresh])

  return atsPriceInUSD
}

export default useATSPrice
