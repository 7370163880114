import { createAction } from '@reduxjs/toolkit'
import { SupportedChainId } from 'config/constants/chains'
import { Currency, CurrencyAmount, Percent } from 'config/entities'
import { Field } from 'state/types'
import { IActionStepState, IRoute, IStep, SWAP_STATES, SwapInfo, ITransaction, UPDATE_ACTIONS } from './types'

export const selectAutomaticOnTargetChain = createAction<{ isAutomatic: boolean }>('swap/selectAutomaticOnTargetChain')

export const selectChain = createAction<{ field: Field; chainId: SupportedChainId }>('swap/selectChain')
export const selectCurrency = createAction<{ field: Field; currency: Currency }>('swap/selectCurrency')
export const switchCurrencies = createAction<void>('swap/switchCurrencies')
export const typeInput = createAction<{
  field: Field
  typedValue: string
  routes: IRoute[]
}>('swap/typeInput')

export const updateRelayerData = createAction<{
  relayerFee: CurrencyAmount
  relayerFeeDollarValue: number
  isValidInput: boolean
  minimumAmount: number
}>('swap/updateRelayerData')
export const updateTransactionNetworkFee = createAction<{
  fromSideNetworkFee: CurrencyAmount
  toSideNetworkFee: CurrencyAmount
}>('swap/updateTransactionNetworkFee')
export const updateSwapPageState = createAction<{ swapPageState: SWAP_STATES }>('swap/updateSwapPageState')
export const updateSwapActionState = createAction<{ swapActionState: SWAP_STATES }>('swap/updateSwapActionState')
export const setSelectedRoute = createAction<{ route: IRoute; userSlippageTolerance: Percent }>('swap/setSelectedRoute')
export const setActiveStepIndex = createAction<{ resultActionStepState: IActionStepState }>('swap/setActiveStepIndex')
export const setSteps = createAction<{ steps: IStep[]; swapInfo: SwapInfo }>('swap/setSteps')
export const setActionState = createAction<{ actionStepState: IActionStepState }>('swap/setActionStepState')
export const resetSwapState = createAction<void>('swap/resetSwapState')
export const setStepResult = createAction<{ actionStepState: IActionStepState }>('swap/setStepResult')
export const setInputAmount = createAction<{ inputAmount: CurrencyAmount }>('swap/setInputAmount')
export const updateCurrentStepPayload = createAction<{ payload }>('swap/updateCurrentStepPayload')
export const setEmptyRoutes = createAction<{ payload }>('swap/setEmptyRoutes')
export const updateRetriesCount = createAction<void>('swap/updateRetriesCount')
export const setChainAndToken = createAction<{
  fromChain: number
  toChain: number
  fromToken: Currency
  toToken: Currency
  fromCurrencies: Currency[]
  toCurrencies: Currency[]
}>('swap/setChainAndToken')
export const updateTransactionId = createAction<{ transactionId }>('swap/updateTransactionId')

export const setSwapTransactions = createAction<{ swapTransactions: ITransaction[] }>('swap/setSwapTransactions')
export const setShouldFetchTransaction = createAction<{ shouldFetchTransaction: UPDATE_ACTIONS }>(
  'swap/setShouldFetchTransaction',
)
