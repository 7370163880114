import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { AppDispatch, AppState } from '../../index'

import {
  resetSwapState,
  selectAutomaticOnTargetChain,
  selectChain,
  selectCurrency,
  setActionState,
  setActiveStepIndex,
  setEmptyRoutes,
  setInputAmount,
  setSelectedRoute,
  setShouldFetchTransaction,
  setStepResult,
  setSteps,
  setSwapTransactions,
  switchCurrencies,
  typeInput,
  updateRelayerData,
  updateRetriesCount,
  updateSwapPageState,
  updateTransactionId,
  updateTransactionNetworkFee,
} from '../actions'
import { Currency } from 'config/entities'
import { SupportedChainId } from 'config/constants/chains'
import { CurrencyAmount } from 'config/entities/fractions/CurrencyAmount'
import { Field } from 'state/types'
import { useQuote } from 'views/Swap/Default/hooks/useQuote'
import {
  IActionStepState,
  ICrossChainRoute,
  IRoute,
  IStep,
  ITransaction,
  ROUTE_PROVIDER,
  ROUTES_DECIDER,
  SWAP_STATES,
  UPDATE_ACTIONS,
} from '../types'

import { getWormholeTokensForBridge } from 'config/tokenLists/wormhole'
import useJupiter from 'views/Swap/Default/hooks/useJupiter'
import { BRIDGE } from 'config/constants'
import { computeSteps, computeSwapInfo } from '../utils'
import { useUserSlippageTolerance } from 'state/user/hooks'
import { deNormalizeAmount, normalizeAmount, sortRoutes } from 'utils'
import useGetRelayerFee from 'views/Swap/Default/hooks/useGetRelayerFee'
import { useIsAutomaticOnTargetChain } from '../selectors'
import { getEVMNodeURL } from 'utils/getRpcUrl'
import { BigNumber, ethers } from 'ethers'
import { getGasLimit } from 'utils/getNativeCurrency'

// Handler to call in our views
export function useSwapActionHandlers(): {
  onUpdateAutomaticOnTargetChain: (isAutomatic: boolean) => void
  onChainSelection: (field: Field, chainId: SupportedChainId) => void
  onCurrencySelection: (field: Field, currency: Currency) => void
  onSwitchTokens: () => void
  onUserInput: (field: Field, typedValue: string) => void
  onSelectedRoute: (route: IRoute) => void
  onNextStep: (resultActionStepState: IActionStepState) => void
  onActionStepStateChanged: (actionStepState: IActionStepState) => void
  onUpdateSwapPageState: (swapPageState: SWAP_STATES) => void
  onResetSwapState: () => void
  onSetEmptyRoutes: () => void
  onSetStepResult: (actionStepState: IActionStepState) => void
  onSetTransactionId: (transactionId: string) => void
  onUpdateRetriesCount: () => void
  onUpdateShouldFetchTransaction: (shouldFetchTransaction: UPDATE_ACTIONS) => void
  onUpdateSwapTransaction: (swapTransaction: ITransaction[]) => void
} {
  const dispatch = useDispatch<AppDispatch>()
  const input = useSelector<AppState, AppState['swaps'][Field.INPUT]['currency']>(
    (state) => state.swaps['INPUT'].currency,
  )
  const output = useSelector<AppState, AppState['swaps'][Field.OUTPUT]['currency']>(
    (state) => state.swaps[Field.OUTPUT].currency,
  )
  const stateInfo = useSelector<AppState, AppState['swaps']>((state) => state.swaps)
  const { handleQuotes } = useQuote()

  const { handleJupiterRoutes } = useJupiter()
  const [userSlippageTolerance] = useUserSlippageTolerance()
  const { getRelayerFee } = useGetRelayerFee()
  const { isAutomaticOnTargetChain } = useIsAutomaticOnTargetChain()

  const onUpdateAutomaticOnTargetChain = useCallback(
    (isAutomatic) => {
      if (!isAutomatic) {
        dispatch(
          updateRelayerData({
            relayerFee: new CurrencyAmount(0, 0, false),
            relayerFeeDollarValue: 0,
            isValidInput: true,
            minimumAmount: 0,
          }),
        )
      }
      dispatch(
        selectAutomaticOnTargetChain({
          isAutomatic,
        }),
      )
    },
    [dispatch],
  )

  const onChainSelection = useCallback(
    (field: Field, chainId: SupportedChainId) => {
      dispatch(
        selectChain({
          field,
          chainId,
        }),
      )
    },
    [dispatch],
  )

  const onSetStepResult = useCallback(
    (actionStepState: IActionStepState) => {
      dispatch(
        setStepResult({
          actionStepState,
        }),
      )
    },
    [dispatch],
  )

  const onSetTransactionId = useCallback(
    (transactionId: string) => {
      dispatch(
        updateTransactionId({
          transactionId,
        }),
      )
    },
    [dispatch],
  )

  const onUpdateShouldFetchTransaction = useCallback(
    (shouldFetchTransaction) => {
      dispatch(
        setShouldFetchTransaction({
          shouldFetchTransaction,
        }),
      )
    },
    [dispatch],
  )
  const onUpdateSwapTransaction = useCallback(
    (swapTransactions: ITransaction[]) => {
      dispatch(
        setSwapTransactions({
          swapTransactions,
        }),
      )
    },
    [dispatch],
  )

  const onActionStepStateChanged = useCallback(
    (actionStepState: IActionStepState) => {
      dispatch(
        setActionState({
          actionStepState,
        }),
      )
      dispatch(
        setStepResult({
          actionStepState,
        }),
      )
    },
    [dispatch],
  )
  const onUpdateSwapPageState = useCallback(
    (swapPageState: SWAP_STATES) => {
      dispatch(
        updateSwapPageState({
          swapPageState,
        }),
      )
    },
    [dispatch],
  )
  const onUpdateRetriesCount = useCallback(() => {
    dispatch(updateRetriesCount())
  }, [dispatch])
  const onCurrencySelection = useCallback(
    (field: Field, currency: Currency) => {
      dispatch(
        selectCurrency({
          field,
          currency,
        }),
      )
    },
    [dispatch],
  )

  const onSelectedRoute = useCallback(
    (route: IRoute) => {
      dispatch(
        setSelectedRoute({
          route,
          userSlippageTolerance,
        }),
      )
    },
    [dispatch],
  )

  const onSwitchTokens = useCallback(() => {
    dispatch(switchCurrencies())
  }, [dispatch])

  const onSetEmptyRoutes = useCallback(() => {
    dispatch(setEmptyRoutes())
  }, [dispatch])

  const onNextStep = useCallback(
    async (resultActionStepState: IActionStepState) => {
      dispatch(
        setActiveStepIndex({
          resultActionStepState,
        }),
      )
    },
    [dispatch],
  )

  const onResetSwapState = useCallback(() => {
    dispatch(resetSwapState())
  }, [dispatch])

  const onUserInput = useCallback(
    async (field: Field, typedValue: string) => {
      dispatch(setInputAmount({ inputAmount: new CurrencyAmount(typedValue, input.decimals, false) }))
      if (Number(typedValue) === 0) return
      onSetEmptyRoutes()
      // SOL to SOL
      if (
        stateInfo[Field.INPUT].chainId === stateInfo[Field.OUTPUT].chainId &&
        stateInfo[Field.INPUT].chainId === SupportedChainId.SOLANA
      ) {
        onUpdateSwapPageState(SWAP_STATES.ROUTES_LOADING) // its for showing loading to user.
        let routes = await handleJupiterRoutes(input, output, new CurrencyAmount(typedValue, input.decimals, false))
        routes = sortRoutes(routes)
        if (routes.length > 0) {
          const steps: IStep[] = computeSteps(
            input.chainId,
            output.chainId,
            routes[0],
            stateInfo[Field.INPUT].currency,
            isAutomaticOnTargetChain,
          )
          const swapInfo = computeSwapInfo(steps, routes[0], userSlippageTolerance, isAutomaticOnTargetChain)
          dispatch(setSteps({ steps, swapInfo }))
        } else {
          // onUpdateSwapPageState(SWAP_STATES.NO_ROUTES)
        }

        dispatch(typeInput({ field, typedValue, routes })) // update state value and also loading stopped in this reducer.
      }
      // SOL to EVM
      else if (
        stateInfo[Field.INPUT].chainId !== stateInfo[Field.OUTPUT].chainId &&
        stateInfo[Field.INPUT].chainId === SupportedChainId.SOLANA
      ) {
        onUpdateSwapPageState(SWAP_STATES.ROUTES_LOADING) // its for showing loading to user.
        const wormholeToken = getWormholeTokensForBridge(
          stateInfo[Field.INPUT].currency,
          stateInfo[Field.OUTPUT].currency,
        )
        const amount = new CurrencyAmount(typedValue, input.decimals, false)
        let isValidInput = true
        let minimumAmount = 0

        // const relayerData = await getRelayerFee(
        //   input.chainId,
        //   output.chainId,
        //   wormholeToken.currencyToLock,
        //   wormholeToken.currencyToMint,
        // )
        // const { relayerFee, relayerFeeDollarValue } = relayerData

        //:TODO this is for disabling the relayer
        const Zero = new CurrencyAmount(0, 8)

        const { relayerFee, relayerFeeDollarValue } = { relayerFee: Zero, relayerFeeDollarValue: 0 }
        let routes: ICrossChainRoute[] = []
        let sourceRoutes: ICrossChainRoute[] = []
        let destinationRoutes: ICrossChainRoute[] = []

        let routesDecider = ROUTES_DECIDER.PENDING
        if (
          wormholeToken.currencyToMint.address.toLowerCase() === output.address.toLowerCase() &&
          wormholeToken.currencyToLock.address.toLowerCase() === input.address.toLowerCase()
        ) {
          routesDecider = ROUTES_DECIDER.BRIDGE_ROUTES
          isValidInput = amount.toEther().gt(relayerFee.toEther())
          minimumAmount = relayerFee.toEther().toNumber()
        } else if (wormholeToken.currencyToMint.address.toLowerCase() === output.address.toLowerCase()) {
          routesDecider = ROUTES_DECIDER.SOURCE_ROUTES
          sourceRoutes = await handleJupiterRoutes(input, wormholeToken.currencyToLock, amount)
          const routeToCheck = sourceRoutes.length > 0 ? sourceRoutes[0] : undefined
          isValidInput = routeToCheck ? routeToCheck.destinationOutputAmount.toEther().gt(relayerFee.toEther()) : false
          minimumAmount = routeToCheck
            ? relayerFee
                .toEther()
                .dividedBy(routeToCheck.destinationOutputAmount.toEther())
                .multipliedBy(amount.toEther())
                .toNumber()
            : 0
          // deduct amount from source routes normalization and denormalization is because of decimals diff
          for (const sourceRoute of sourceRoutes) {
            const deNormalizeAmountObj = deNormalizeAmount(
              sourceRoute.destinationOutputAmount,
              wormholeToken.currencyToMint.decimals,
            )
            const amountAfterDeduction = deNormalizeAmountObj.toWei().minus(relayerFee.toWei())
            sourceRoute.destinationOutputAmount = normalizeAmount(
              new CurrencyAmount(
                isAutomaticOnTargetChain ? amountAfterDeduction : deNormalizeAmountObj.toWei(),
                wormholeToken.currencyToMint.decimals,
              ),
              wormholeToken.currencyToLock.decimals,
              wormholeToken.currencyToMint.decimals,
            )
          }
        } else if (wormholeToken.currencyToLock.address.toLowerCase() === input.address.toLowerCase()) {
          routesDecider = ROUTES_DECIDER.DESTINATION_ROUTES
          isValidInput = amount.toEther().gt(relayerFee.toEther())
          minimumAmount = relayerFee.toEther().toNumber()
          if (isValidInput || !isAutomaticOnTargetChain) {
            const deNormalizeAmountObj = deNormalizeAmount(amount, wormholeToken.currencyToMint.decimals)
            const amountAfterDeduction = deNormalizeAmountObj.toWei().minus(relayerFee.toWei())

            destinationRoutes = await handleQuotes(
              wormholeToken.currencyToMint,
              output,
              new CurrencyAmount(
                isAutomaticOnTargetChain ? amountAfterDeduction : deNormalizeAmountObj.toWei(),
                wormholeToken.currencyToMint.decimals,
              ),
              new CurrencyAmount(
                isAutomaticOnTargetChain ? amountAfterDeduction : deNormalizeAmountObj.toWei(),
                wormholeToken.currencyToMint.decimals,
              ),
            )
          }
        } else {
          routesDecider = ROUTES_DECIDER.MERGE_ROUTES
          sourceRoutes = await handleJupiterRoutes(input, wormholeToken.currencyToLock, amount)
          const routeToCheck = sourceRoutes.length > 0 ? sourceRoutes[0] : undefined

          isValidInput = routeToCheck ? routeToCheck.destinationOutputAmount.toEther().gt(relayerFee.toEther()) : false
          minimumAmount = routeToCheck
            ? relayerFee
                .toEther()
                .dividedBy(routeToCheck.destinationOutputAmount.toEther())
                .multipliedBy(amount.toEther())
                .toNumber()
            : 0
          if (sourceRoutes.length > 0 && (isValidInput || !isAutomaticOnTargetChain)) {
            const srcRoute = sourceRoutes[0]
            const deNormalizeAmountObj = deNormalizeAmount(
              srcRoute.destinationOutputAmount,
              wormholeToken.currencyToMint.decimals,
            )
            const amountAfterDeduction = new CurrencyAmount(
              deNormalizeAmountObj.toWei().minus(relayerFee.toWei()),
              wormholeToken.currencyToMint.decimals,
            )
            destinationRoutes = await handleQuotes(
              wormholeToken.currencyToMint,
              output,
              isAutomaticOnTargetChain ? amountAfterDeduction : new CurrencyAmount(deNormalizeAmountObj.toWei()),
              isAutomaticOnTargetChain ? amountAfterDeduction : new CurrencyAmount(deNormalizeAmountObj.toWei()),
            )
          } else {
            routesDecider = ROUTES_DECIDER.NO_ROUTES
          }
        }
        if (isValidInput || !isAutomaticOnTargetChain) {
          if (routesDecider === ROUTES_DECIDER.MERGE_ROUTES) {
            for (let index = 0; index < destinationRoutes.length; index++) {
              let priceImpactPercent = 0
              if (sourceRoutes[0].priceImpact === 0) {
                priceImpactPercent = destinationRoutes[index].priceImpact
              } else if (destinationRoutes[index].priceImpact === 0) {
                priceImpactPercent = sourceRoutes[0].priceImpact
              } else {
                priceImpactPercent = (destinationRoutes[index].priceImpact + sourceRoutes[0].priceImpact) / 2
              }
              const route: ICrossChainRoute = {
                name: `${sourceRoutes[0].name} | ${destinationRoutes[index].name}`,
                sourceGasLimit: destinationRoutes[index].estimatedGas,
                inputCurrency: sourceRoutes[0].inputCurrency,
                outputCurrency: destinationRoutes[index].outputCurrency,
                inputLockCurrency: wormholeToken.currencyToLock,
                outputMintCurrency: wormholeToken.currencyToMint,
                sourceInputAmount: sourceRoutes[0].sourceInputAmount,
                sourceOutputAmount: sourceRoutes[0].destinationOutputAmount,
                destinationInputAmount: destinationRoutes[index].sourceInputAmount,
                destinationGasLimit: destinationRoutes[index].estimatedGas,
                destinationOutputAmount: destinationRoutes[index].destinationOutputAmount,
                payload: sourceRoutes[0].payload,
                priceImpact: priceImpactPercent,
                evmRouteName: destinationRoutes[index].name,
              }
              routes.push(route)
            }
          } else if (routesDecider === ROUTES_DECIDER.SOURCE_ROUTES) {
            for (let index = 0; index < sourceRoutes.length; index++) {
              const route: ICrossChainRoute = {
                name: `${sourceRoutes[index].name} | ${ROUTE_PROVIDER.WORMHOLE}`,
                inputCurrency: input,
                outputCurrency: output,
                inputLockCurrency: wormholeToken.currencyToLock,
                outputMintCurrency: wormholeToken.currencyToMint,
                sourceInputAmount: sourceRoutes[index].sourceInputAmount,
                sourceOutputAmount: sourceRoutes[index].destinationOutputAmount,
                destinationInputAmount: sourceRoutes[index].destinationOutputAmount,
                destinationOutputAmount: sourceRoutes[index].destinationOutputAmount,
                priceImpact: sourceRoutes[index].priceImpact,
                payload: sourceRoutes[index].payload,
              }
              routes.push(route)
            }
          } else if (routesDecider === ROUTES_DECIDER.DESTINATION_ROUTES) {
            for (let index = 0; index < destinationRoutes.length; index++) {
              const route: ICrossChainRoute = {
                name: `${ROUTE_PROVIDER.WORMHOLE} | ${destinationRoutes[index].name}`,
                inputCurrency: input,
                outputCurrency: output,
                inputLockCurrency: wormholeToken.currencyToLock,
                outputMintCurrency: wormholeToken.currencyToMint,
                sourceInputAmount: amount,
                sourceOutputAmount: destinationRoutes[index].sourceInputAmount,
                destinationInputAmount: destinationRoutes[index].sourceInputAmount,
                destinationOutputAmount: destinationRoutes[index].destinationOutputAmount,
                priceImpact: destinationRoutes[index].priceImpact,
                evmRouteName: destinationRoutes[index].name,
              }
              routes.push(route)
            }
          } else if (routesDecider === ROUTES_DECIDER.BRIDGE_ROUTES) {
            const deNormalizeAmountObj = deNormalizeAmount(amount, wormholeToken.currencyToMint.decimals)
            const amountAfterDeduction = deNormalizeAmountObj.toWei().minus(relayerFee.toWei())

            routes.push({
              inputCurrency: input,
              inputLockCurrency: input,
              outputMintCurrency: output,
              outputCurrency: output,
              sourceGasLimit: 0,
              destinationGasLimit: 0,
              name: BRIDGE,
              sourceOutputAmount: amount,
              sourceInputAmount: amount,
              destinationInputAmount: amount,
              destinationOutputAmount: normalizeAmount(
                new CurrencyAmount(
                  isAutomaticOnTargetChain ? amountAfterDeduction : deNormalizeAmountObj.toWei(),
                  wormholeToken.currencyToMint.decimals,
                ),
                wormholeToken.currencyToLock.decimals,
                wormholeToken.currencyToMint.decimals,
              ),
              priceImpact: 0,
            })
          }
        }
        routes = sortRoutes(routes)
        if (routes.length > 0) {
          const steps: IStep[] = computeSteps(
            input.chainId,
            output.chainId,
            routes[0],
            stateInfo[Field.INPUT].currency,
            isAutomaticOnTargetChain,
          )
          const swapInfo = computeSwapInfo(steps, routes[0], userSlippageTolerance, isAutomaticOnTargetChain)
          dispatch(setSteps({ steps, swapInfo }))
        } else {
          // onUpdateSwapPageState(SWAP_STATES.NO_ROUTES)
        }
        dispatch(typeInput({ field, typedValue, routes })) // update state value and also loading stopped in this reducer.
        dispatch(updateRelayerData({ relayerFee, relayerFeeDollarValue, isValidInput, minimumAmount })) // update relayer related data.
        // As We Are Sol To EVM SO,
        const fromSideNetworkFee = new CurrencyAmount(0.005, 9, false)

        const rpcUrlTargetedChain = getEVMNodeURL[output.chainId]
        const providerTarget = new ethers.providers.JsonRpcProvider(rpcUrlTargetedChain)
        const gasPriceTarget = await providerTarget.getGasPrice()
        const toSideNetworkFee = new CurrencyAmount(
          BigNumber.from(getGasLimit[output.chainId]).mul(gasPriceTarget).toString(),
          18,
        )

        dispatch(updateTransactionNetworkFee({ fromSideNetworkFee, toSideNetworkFee }))
        // end of dispatching fee
      }
      // EVM to SOL
      else if (
        stateInfo[Field.INPUT].chainId !== stateInfo[Field.OUTPUT].chainId &&
        stateInfo[Field.OUTPUT].chainId === SupportedChainId.SOLANA
      ) {
        onUpdateSwapPageState(SWAP_STATES.ROUTES_LOADING) // its for showing loading to user.
        const wormholeToken = getWormholeTokensForBridge(
          stateInfo[Field.INPUT].currency,
          stateInfo[Field.OUTPUT].currency,
        )

        const amount = new CurrencyAmount(typedValue, input.decimals, false)
        let routes: ICrossChainRoute[] = []
        let sourceRoutes: ICrossChainRoute[] = []
        const destinationRoutes: ICrossChainRoute[] = []

        let routesDecider = ROUTES_DECIDER.PENDING

        if (
          wormholeToken.currencyToMint.address.toLowerCase() === output.address.toLowerCase() &&
          wormholeToken.currencyToLock.address.toLowerCase() === input.address.toLowerCase()
        ) {
          routesDecider = ROUTES_DECIDER.BRIDGE_ROUTES
        } else if (wormholeToken.currencyToMint.address.toLowerCase() === output.address.toLowerCase()) {
          routesDecider = ROUTES_DECIDER.SOURCE_ROUTES
          sourceRoutes = await handleQuotes(input, wormholeToken.currencyToLock, amount, amount)
        } else if (wormholeToken.currencyToLock.address.toLowerCase() === input.address.toLowerCase()) {
          routesDecider = ROUTES_DECIDER.DESTINATION_ROUTES
          const tempArray = await handleJupiterRoutes(
            wormholeToken.currencyToMint,
            output,
            normalizeAmount(amount, wormholeToken.currencyToLock.decimals, wormholeToken.currencyToMint.decimals),
          )
          if (tempArray.length > 0) {
            destinationRoutes.push(tempArray[0])
          }
        } else {
          routesDecider = ROUTES_DECIDER.MERGE_ROUTES
          sourceRoutes = await handleQuotes(input, wormholeToken.currencyToLock, amount, amount)
          if (sourceRoutes.length > 0) {
            for (let index = 0; index < sourceRoutes.length; index++) {
              const tempArray = await handleJupiterRoutes(
                wormholeToken.currencyToMint,
                output,
                normalizeAmount(
                  sourceRoutes[index].destinationOutputAmount,
                  wormholeToken.currencyToLock.decimals,
                  wormholeToken.currencyToMint.decimals,
                ),
              )
              if (tempArray.length > 0) {
                destinationRoutes.push(tempArray[0])
              }
            }
          } else {
            routesDecider = ROUTES_DECIDER.NO_ROUTES
          }
        }

        if (routesDecider === ROUTES_DECIDER.MERGE_ROUTES) {
          for (let index = 0; index < destinationRoutes.length; index++) {
            let priceImpactInPercent
            if (sourceRoutes[0].priceImpact === 0) {
              priceImpactInPercent = destinationRoutes[index].priceImpact
            } else if (destinationRoutes[index]?.priceImpact === 0) {
              priceImpactInPercent = sourceRoutes[0].priceImpact
            } else {
              priceImpactInPercent = (destinationRoutes[index].priceImpact + sourceRoutes[0].priceImpact) / 2
            }
            const route: ICrossChainRoute = {
              name: `${sourceRoutes[0].name} | ${destinationRoutes[index].name}`,
              sourceGasLimit: destinationRoutes[index].estimatedGas,
              inputCurrency: sourceRoutes[0].inputCurrency,
              outputCurrency: destinationRoutes[index].outputCurrency,
              inputLockCurrency: wormholeToken.currencyToLock,
              outputMintCurrency: wormholeToken.currencyToMint,
              sourceInputAmount: sourceRoutes[0].sourceInputAmount,
              sourceOutputAmount: sourceRoutes[0].destinationOutputAmount,
              destinationInputAmount: destinationRoutes[index].sourceInputAmount,
              destinationGasLimit: destinationRoutes[index].estimatedGas,
              destinationOutputAmount: destinationRoutes[index].destinationOutputAmount,
              payload: destinationRoutes[0].payload,
              priceImpact: priceImpactInPercent,
              evmRouteName: sourceRoutes[index].name,
            }
            routes.push(route)
          }
        } else if (routesDecider === ROUTES_DECIDER.SOURCE_ROUTES) {
          for (let index = 0; index < sourceRoutes.length; index++) {
            const route: ICrossChainRoute = {
              name: `${sourceRoutes[index].name} | ${ROUTE_PROVIDER.WORMHOLE}`,
              inputCurrency: input,
              outputCurrency: output,
              inputLockCurrency: wormholeToken.currencyToLock,
              outputMintCurrency: wormholeToken.currencyToMint,
              sourceInputAmount: sourceRoutes[index].sourceInputAmount,
              sourceOutputAmount: sourceRoutes[index].destinationOutputAmount,
              destinationInputAmount: sourceRoutes[index].destinationOutputAmount,
              destinationOutputAmount: sourceRoutes[index].destinationOutputAmount,
              priceImpact: sourceRoutes[index].priceImpact,
              evmRouteName: sourceRoutes[index].name,
            }
            routes.push(route)
          }
        } else if (routesDecider === ROUTES_DECIDER.DESTINATION_ROUTES) {
          for (let index = 0; index < destinationRoutes.length; index++) {
            const route: ICrossChainRoute = {
              name: `${ROUTE_PROVIDER.WORMHOLE} | ${destinationRoutes[index].name}`,
              inputCurrency: input,
              outputCurrency: output,
              inputLockCurrency: wormholeToken.currencyToLock,
              outputMintCurrency: wormholeToken.currencyToMint,
              sourceInputAmount: amount,
              sourceOutputAmount: destinationRoutes[index].sourceInputAmount,
              destinationInputAmount: destinationRoutes[index].sourceInputAmount,
              destinationOutputAmount: destinationRoutes[index].destinationOutputAmount,
              priceImpact: destinationRoutes[index].priceImpact,
              payload: destinationRoutes[index].payload,
            }
            routes.push(route)
          }
        } else if (routesDecider === ROUTES_DECIDER.BRIDGE_ROUTES) {
          routes.push({
            inputCurrency: input,
            inputLockCurrency: input,
            outputMintCurrency: output,
            outputCurrency: output,
            sourceGasLimit: 0,
            destinationGasLimit: 0,
            name: BRIDGE,
            sourceOutputAmount: amount,
            sourceInputAmount: amount,
            destinationInputAmount: amount,
            destinationOutputAmount: amount,
            priceImpact: 0,
          })
        }
        routes = sortRoutes(routes)
        if (routes.length > 0) {
          const steps: IStep[] = computeSteps(
            input.chainId,
            output.chainId,
            routes[0],
            stateInfo[Field.INPUT].currency,
            isAutomaticOnTargetChain,
          )
          const swapInfo = computeSwapInfo(steps, routes[0], userSlippageTolerance, isAutomaticOnTargetChain)
          dispatch(setSteps({ steps, swapInfo }))
        } else {
          // onUpdateSwapPageState(SWAP_STATES.NO_ROUTES)
        }
        dispatch(typeInput({ field, typedValue, routes })) // update state value and also loading stopped in this reducer.
        // As We Are EVM To SOL SO,
        const rpcUrlTargetedChain = getEVMNodeURL[input.chainId]
        const provider = new ethers.providers.JsonRpcProvider(rpcUrlTargetedChain)
        const gasPrice = await provider.getGasPrice()
        const fromSideNetworkFee = new CurrencyAmount(BigNumber.from(400000).mul(gasPrice).toString(), 18)
        const toSideNetworkFee = new CurrencyAmount(0.005, 9, false)
        dispatch(updateTransactionNetworkFee({ fromSideNetworkFee, toSideNetworkFee }))
        // end of dispatching fee
      }
      // EVM to EVM
      else if (
        stateInfo[Field.INPUT].chainId === stateInfo[Field.OUTPUT].chainId &&
        stateInfo[Field.INPUT].chainId !== SupportedChainId.SOLANA
      ) {
        onUpdateSwapPageState(SWAP_STATES.ROUTES_LOADING) // its for showing loading to user.
        let routes: IRoute[] = await handleQuotes(
          input,
          output,
          new CurrencyAmount(typedValue, input.decimals, false),
          new CurrencyAmount(typedValue, input.decimals, false),
        )
        if (routes.length > 0) {
          const steps: IStep[] = computeSteps(
            input.chainId,
            output.chainId,
            routes[0],
            stateInfo[Field.INPUT].currency,
            isAutomaticOnTargetChain,
          )
          const swapInfo = computeSwapInfo(steps, routes[0], userSlippageTolerance, isAutomaticOnTargetChain)
          dispatch(setSteps({ steps, swapInfo }))
        } else {
          // onUpdateSwapPageState(SWAP_STATES.NO_ROUTES)
        }
        routes = sortRoutes(routes)
        dispatch(typeInput({ field, typedValue, routes })) // update state value and also loading stopped in this reducer.
      }
      // EVM to EVM (Cross Chain)
      else {
        onUpdateSwapPageState(SWAP_STATES.ROUTES_LOADING) // its for showing loading to user.
        const wormholeToken = getWormholeTokensForBridge(
          stateInfo[Field.INPUT].currency,
          stateInfo[Field.OUTPUT].currency,
        )

        const amount = new CurrencyAmount(typedValue, input.decimals, false)
        // let relayerFee = new CurrencyAmount(0, wormholeToken.currencyToMint.decimals)
        // let relayerFeeDollarValue = 0
        let isValidInput = true
        let minimumAmount = 0
        // if (isAutomaticOnTargetChain) {
        // const relayerData = await getRelayerFee(
        //   input.chainId,
        //   output.chainId,
        //   wormholeToken.currencyToLock,
        //   wormholeToken.currencyToMint,
        // )
        // const { relayerFee, relayerFeeDollarValue } = relayerData

        //:TODO this is for disabling the relayer
        const Zero = new CurrencyAmount(0, 8)

        const { relayerFee, relayerFeeDollarValue } = { relayerFee: Zero, relayerFeeDollarValue: 0 }
        let routes: ICrossChainRoute[] = []
        let sourceRoutes: ICrossChainRoute[] = []
        let destinationRoutes: ICrossChainRoute[] = []

        let routesDecider = ROUTES_DECIDER.PENDING

        if (
          wormholeToken.currencyToMint.address.toLowerCase() === output.address.toLowerCase() &&
          wormholeToken.currencyToLock.address.toLowerCase() === input.address.toLowerCase()
        ) {
          routesDecider = ROUTES_DECIDER.BRIDGE_ROUTES
          isValidInput = amount.toEther().gt(relayerFee.toEther())
          minimumAmount = relayerFee.toEther().toNumber()
        } else if (wormholeToken.currencyToMint.address.toLowerCase() === output.address.toLowerCase()) {
          routesDecider = ROUTES_DECIDER.SOURCE_ROUTES
          sourceRoutes = await handleQuotes(input, wormholeToken.currencyToLock, amount, amount)

          const routeToCheck = sourceRoutes.length > 0 ? sourceRoutes[0] : undefined

          isValidInput = routeToCheck ? routeToCheck.destinationOutputAmount.toEther().gt(relayerFee.toEther()) : false
          minimumAmount = routeToCheck
            ? relayerFee
                .toEther()
                .dividedBy(routeToCheck.destinationOutputAmount.toEther())
                .multipliedBy(amount.toEther())
                .toNumber()
            : 0

          for (const sourceRoute of sourceRoutes) {
            const amountAfterDeduction = sourceRoute.destinationOutputAmount.toWei().minus(relayerFee.toWei())
            sourceRoute.destinationOutputAmount = new CurrencyAmount(
              isAutomaticOnTargetChain ? amountAfterDeduction : sourceRoute.destinationOutputAmount.toWei(),
              wormholeToken.currencyToLock.decimals,
            )
          }
        } else if (wormholeToken.currencyToLock.address.toLowerCase() === input.address.toLowerCase()) {
          routesDecider = ROUTES_DECIDER.DESTINATION_ROUTES
          isValidInput = amount.toEther().gt(relayerFee.toEther())
          minimumAmount = relayerFee.toEther().toNumber()
          if (isValidInput || !isAutomaticOnTargetChain) {
            destinationRoutes = await handleQuotes(
              wormholeToken.currencyToMint,
              output,
              new CurrencyAmount(
                isAutomaticOnTargetChain ? amount.toWei().minus(relayerFee.toWei()) : amount.toWei(),
                amount.decimals,
                true,
              ),
              new CurrencyAmount(
                isAutomaticOnTargetChain ? amount.toWei().minus(relayerFee.toWei()) : amount.toWei(),
                amount.decimals,
                true,
              ),
            )
          }
        } else {
          routesDecider = ROUTES_DECIDER.MERGE_ROUTES
          sourceRoutes = await handleQuotes(input, wormholeToken.currencyToLock, amount, amount)

          const routeToCheck = sourceRoutes.length > 0 ? sourceRoutes[0] : undefined

          isValidInput = routeToCheck ? routeToCheck.destinationOutputAmount.toEther().gt(relayerFee.toEther()) : false
          minimumAmount = routeToCheck
            ? relayerFee
                .toEther()
                .dividedBy(routeToCheck.destinationOutputAmount.toEther())
                .multipliedBy(amount.toEther())
                .toNumber()
            : 0

          if (sourceRoutes.length > 0 && (isValidInput || !isAutomaticOnTargetChain)) {
            const _1inchRoute = sourceRoutes.find((x) => x.name === ROUTE_PROVIDER.ONE_INCH)
            const _0xRoute = sourceRoutes.find((x) => x.name === ROUTE_PROVIDER.ZERO_X)
            let _1InchAmount = _1inchRoute ? _1inchRoute.destinationOutputAmount : new CurrencyAmount(0, 0, false)
            let _0xAmount = _0xRoute ? _0xRoute.destinationOutputAmount : new CurrencyAmount(0, 0, false)

            _1InchAmount = new CurrencyAmount(
              isAutomaticOnTargetChain ? _1InchAmount.toWei().minus(relayerFee.toWei()) : _1InchAmount.toWei(),
              _1InchAmount.decimals,
              true,
            )
            _0xAmount = new CurrencyAmount(
              isAutomaticOnTargetChain ? _0xAmount.toWei().minus(relayerFee.toWei()) : _0xAmount.toWei(),
              _0xAmount.decimals,
              true,
            )

            destinationRoutes = await handleQuotes(wormholeToken.currencyToMint, output, _1InchAmount, _0xAmount)
          } else {
            routesDecider = ROUTES_DECIDER.NO_ROUTES
          }
        }
        if (isValidInput || !isAutomaticOnTargetChain) {
          if (routesDecider === ROUTES_DECIDER.MERGE_ROUTES) {
            for (let index = 0; index < sourceRoutes.length; index++) {
              const destinationRouteFinder = destinationRoutes.find((x) => x.name === sourceRoutes[index].name)
              if (destinationRouteFinder) {
                let priceImpact
                if (sourceRoutes[index].priceImpact === 0) {
                  priceImpact = destinationRouteFinder.priceImpact
                } else if (destinationRouteFinder.priceImpact === 0) {
                  priceImpact = sourceRoutes[index].priceImpact
                } else {
                  priceImpact = (destinationRouteFinder.priceImpact + sourceRoutes[index].priceImpact) / 2
                }
                const route: ICrossChainRoute = {
                  name: `${sourceRoutes[index].name} | ${destinationRouteFinder.name}`,
                  sourceGasLimit: destinationRouteFinder.estimatedGas,
                  inputCurrency: input,
                  outputCurrency: output,
                  inputLockCurrency: wormholeToken.currencyToLock,
                  outputMintCurrency: wormholeToken.currencyToMint,
                  destinationGasLimit: destinationRouteFinder.estimatedGas,
                  sourceInputAmount: sourceRoutes[index].sourceInputAmount,
                  sourceOutputAmount: sourceRoutes[index].destinationOutputAmount,
                  destinationInputAmount: destinationRouteFinder.sourceInputAmount,
                  destinationOutputAmount: destinationRouteFinder.destinationOutputAmount,
                  priceImpact,
                  evmRouteName: sourceRoutes[index].name,
                  relayerFee: relayerFee,
                }
                routes.push(route)
              }
            }
          } else if (routesDecider === ROUTES_DECIDER.SOURCE_ROUTES) {
            for (let index = 0; index < sourceRoutes.length; index++) {
              const route: ICrossChainRoute = {
                name: `${sourceRoutes[index].name} | ${ROUTE_PROVIDER.WORMHOLE}`,
                inputCurrency: input,
                outputCurrency: output,
                inputLockCurrency: wormholeToken.currencyToLock,
                outputMintCurrency: wormholeToken.currencyToMint,
                sourceInputAmount: sourceRoutes[index].sourceInputAmount,
                sourceOutputAmount: sourceRoutes[index].destinationOutputAmount,
                destinationInputAmount: sourceRoutes[index].destinationOutputAmount,
                destinationOutputAmount: sourceRoutes[index].destinationOutputAmount,
                priceImpact: sourceRoutes[index].priceImpact,
                evmRouteName: sourceRoutes[index].name,
                relayerFee: relayerFee,
              }
              routes.push(route)
            }
          } else if (routesDecider === ROUTES_DECIDER.DESTINATION_ROUTES) {
            for (let index = 0; index < destinationRoutes.length; index++) {
              const route: ICrossChainRoute = {
                name: `${ROUTE_PROVIDER.WORMHOLE} | ${destinationRoutes[index].name}`,
                inputCurrency: input,
                outputCurrency: output,
                inputLockCurrency: wormholeToken.currencyToLock,
                outputMintCurrency: wormholeToken.currencyToMint,
                sourceInputAmount: amount,
                sourceOutputAmount: destinationRoutes[index].sourceInputAmount,
                destinationInputAmount: destinationRoutes[index].sourceInputAmount,
                destinationOutputAmount: destinationRoutes[index].destinationOutputAmount,
                priceImpact: destinationRoutes[index].priceImpact,
                evmRouteName: destinationRoutes[index].name,
                relayerFee: relayerFee,
              }
              routes.push(route)
            }
          } else if (routesDecider === ROUTES_DECIDER.BRIDGE_ROUTES) {
            routes.push({
              inputCurrency: input,
              inputLockCurrency: input,
              outputMintCurrency: output,
              outputCurrency: output,
              sourceGasLimit: 0,
              destinationGasLimit: 0,
              name: BRIDGE,
              sourceOutputAmount: amount,
              sourceInputAmount: amount,
              destinationInputAmount: amount,
              destinationOutputAmount: new CurrencyAmount(
                isAutomaticOnTargetChain ? amount.toWei().minus(relayerFee.toWei()) : amount.toWei(),
                amount.decimals,
                true,
              ),
              priceImpact: 0,
              relayerFee: relayerFee,
            })
          }
        }
        routes = sortRoutes(routes)

        if (routes.length > 0) {
          const steps: IStep[] = computeSteps(
            input.chainId,
            output.chainId,
            routes[0],
            stateInfo[Field.INPUT].currency,
            isAutomaticOnTargetChain,
          )
          const swapInfo = computeSwapInfo(steps, routes[0], userSlippageTolerance, isAutomaticOnTargetChain)
          dispatch(setSteps({ steps, swapInfo }))
        } else {
          // onUpdateSwapPageState(SWAP_STATES.NO_ROUTES)
        }
        dispatch(typeInput({ field, typedValue, routes })) // update state value and also loading stopped in this reducer.
        dispatch(updateRelayerData({ relayerFee, relayerFeeDollarValue, isValidInput, minimumAmount })) // update relayer related data.
        // As We Are EVM To EVM Cross Chain SO,
        const rpcUrlInputChain = getEVMNodeURL[input.chainId]
        const provider = new ethers.providers.JsonRpcProvider(rpcUrlInputChain)
        const gasPrice = await provider.getGasPrice()
        const fromSideNetworkFee = new CurrencyAmount(BigNumber.from(400000).mul(gasPrice).toString(), 18)

        const rpcUrlTargetedChain = getEVMNodeURL[output.chainId]
        const providerTarget = new ethers.providers.JsonRpcProvider(rpcUrlTargetedChain)
        const gasPriceTarget = await providerTarget.getGasPrice()
        const toSideNetworkFee = new CurrencyAmount(
          BigNumber.from(getGasLimit[output.chainId]).mul(gasPriceTarget).toString(),
          18,
        )
        dispatch(updateTransactionNetworkFee({ fromSideNetworkFee, toSideNetworkFee }))
      }
    },

    [dispatch, input, output, stateInfo, userSlippageTolerance],
  )

  return {
    onSwitchTokens,
    onCurrencySelection,
    onUserInput,
    onChainSelection,
    onSelectedRoute,
    onNextStep,
    onActionStepStateChanged,
    onUpdateSwapPageState,
    onResetSwapState,
    onSetEmptyRoutes,
    onSetStepResult,
    onUpdateRetriesCount,
    onUpdateAutomaticOnTargetChain,
    onSetTransactionId,
    onUpdateShouldFetchTransaction,
    onUpdateSwapTransaction,
  }
} // end of use Swap Action Handler
