import Svg from './Svg'
import { SvgProps } from './types'
import React from 'react'

interface IShimmerCards extends SvgProps {
  backgroundColor: string
  foregroundColor: string
  height: number
  width?: number
  gap: number
  totalCards: number
}

const Icon: React.FC<IShimmerCards> = (props) => {
  const { foregroundColor, backgroundColor, totalCards, gap, height, width } = props
  const cards = []
  for (let i = 0; i <= totalCards; i++) {
    cards.push(i * (height + gap))
  }
  return (
    <Svg
      role="img"
      width="100%"
      aria-labelledby="loading-aria"
      preserveAspectRatio="none"
      {...props}
      height={height * totalCards + (gap * totalCards + height)}
    >
      <title id="loading-aria">Loading...</title>
      <rect x="0" y="0" width="100%" height="100%" clipPath="url(#clip-path)" style={{ fill: 'url("#fill")' }} />
      <defs>
        <clipPath id="clip-path">
          {cards.map((card, index) => (
            <rect key={index} x="0" y={card} rx="8" ry="8" width={width || '100%'} height={height} />
          ))}
        </clipPath>
        <linearGradient id="fill">
          <stop offset="0.599964" stopColor={backgroundColor} stopOpacity="1">
            <animate
              attributeName="offset"
              values="-2; -2; 1"
              keyTimes="0; 0.25; 1"
              dur="1s"
              repeatCount="indefinite"
            />
          </stop>
          <stop offset="1.59996" stopColor={foregroundColor} stopOpacity="1">
            <animate
              attributeName="offset"
              values="-1; -1; 2"
              keyTimes="0; 0.25; 1"
              dur="1s"
              repeatCount="indefinite"
            />
          </stop>
          <stop offset="2.59996" stopColor={backgroundColor} stopOpacity="1">
            <animate attributeName="offset" values="0; 0; 3" keyTimes="0; 0.25; 1" dur="1s" repeatCount="indefinite" />
          </stop>
        </linearGradient>
      </defs>
    </Svg>
  )
}

export default Icon
