import { useEffect } from 'react'
import useAuth from 'hooks/wallets/useAuthEVM'
import { connectorLocalStorageKey, ConnectorNames } from 'config/constants/types'
import { Field } from 'state/types'

const useEagerConnect = () => {
  // const { connectEVM: connect } = useAuth()
  // useEffect(() => {
  //   const connectorId = window.localStorage.getItem(connectorLocalStorageKey) as ConnectorNames
  //   if (connectorId) {
  //     connect(connectorId, 1, Field.INPUT)
  //   }
  // }, [connect])
}

export default useEagerConnect
