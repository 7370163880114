import { Box, Flex } from 'components/Box'
import styled from 'styled-components'
import { Text } from 'components/Text'
import { FaCheckCircle } from 'react-icons/fa'
import Button from 'components/Button'

export const Description = styled('p')`
  color: ${({ theme }) => theme.colors.textHeading};
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-weight: ${({ theme }) => theme.fonts.light};
  @media screen and (max-width: 1240px) {
    font-size: 13px;
  }
`

export const TabTitle = styled(Flex)<{ fontSize?: string }>`
  .title {
    color: ${({ theme }) => theme.colors.text};
    font-family: ${({ theme }) => theme.fonts.primary};
    font-weight: ${({ theme }) => theme.fonts.semiBold};
    margin: 0;
    margin-left: 6px;
    text-transform: uppercase;
    font-size: ${({ fontSize }) => (fontSize ? fontSize : '18px')};
    @media screen and (max-width: 1440px), (max-height: 800px) {
      font-size: 12px;
    }
  }
`
export const TabContent = styled(Box)<{ height?: string }>`
  background: ${({ theme }) => theme.colors.background}a1;
  border-radius: 0 0 12px 12px;
  padding: 1.4rem;
  min-height: ${({ height }) => (height ? height : '200px')};
`
export const Note = styled(Box)`
  background: ${({ theme }) => theme.colors.background}a1;
  margin-top: 1rem;
  padding: 0.6rem;
  border-radius: 12px;

  .note-text {
    font-size: 14px;
    font-family: ${({ theme }) => theme.fonts.secondary};
    font-weight: ${({ theme }) => theme.fonts.regular};
    color: ${({ theme }) => theme.colors.text};
    margin: 0;
    & a {
      font-weight: ${({ theme }) => theme.fonts.bold};
      color: ${({ theme }) => theme.colors.text};
    }
  }
`

export const TermsAndConditions = styled(Text)`
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-weight: ${({ theme }) => theme.fonts.regular};
  color: ${({ theme }) => theme.colors.text};
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
`

export const WalletRow = styled(Flex)`
  cursor: pointer;
  & img {
    width: 24px;
    height: 24px;
  }
  &:hover {
    opacity: 0.5;
  }
  .wallet-name {
    font-family: ${({ theme }) => theme.fonts.primary};
    font-weight: ${({ theme }) => theme.fonts.semiBold};
    color: ${({ theme }) => theme.colors.text};
    margin: 0 0 0 12px;
    font-size: 16px;
  }
`

export const WalletInfoSection = styled(Flex)<{ padding?: string }>`
  padding: ${({ padding }) => (padding ? padding : '1rem 0')};
  min-height: 180px;
  display: flex;
  flex-direction: column;
  width: 100%;
  .wallet-info {
    .wallet-address {
      font-family: ${({ theme }) => theme.fonts.primary};
      font-weight: ${({ theme }) => theme.fonts.regular};
      color: ${({ theme }) => theme.colors.text};
      margin: 0;
      font-size: small;
      text-align: center;
    }
  }
  .icon {
    width: 20px;
    height: 20px;
  }
  .svg-icon {
    svg {
      width: 33px;
      height: 33px;
    }
  }

  img {
    width: 33px;
    height: 33px;
  }
  .tx-history {
    width: 100%;
    margin-top: 0.4rem;
    & h4 {
      color: #80ecff;
      font-family: ${({ theme }) => theme.fonts.primary};
      font-weight: ${({ theme }) => theme.fonts.semiBold};
      font-size: large;
    }

    .tx-row {
      justify-content: space-between;
      & p {
        color: ${({ theme }) => theme.colors.textSubtle};
        font-size: small;
        font-family: ${({ theme }) => theme.fonts.secondary};
        font-weight: ${({ theme }) => theme.fonts.light};
        margin: 4px 0 0 0;
      }
    }
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    .wallet-info {
      .wallet-address {
        font-size: x-small;
      }
    }
  }
`

export const AddressWrapper = styled(Box)`
  border: dashed 1px #8e91a099;
  padding: 0.6rem 0.8rem;
  border-radius: 11px;
  cursor: pointer;

  & > p {
    font-family: ${({ theme }) => theme.fonts.primary};
    font-weight: ${({ theme }) => theme.fonts.regular};
    color: ${({ theme }) => theme.colors.text};
    margin: 0;
    font-size: small;
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    padding: 0.2rem 0.4rem;
    & > p {
      font-size: x-small;
    }
  }
`

export const IconButton = styled(Flex)`
  cursor: pointer;
  flex-direction: column;
  align-items: center;

  .icon {
    color: #80ecff;
    font-size: 20px;
  }
  .tab-name {
    color: ${({ theme }) => theme.colors.textTertiary};
    font-size: 10px;
    font-family: ${({ theme }) => theme.fonts.primary};
    font-weight: ${({ theme }) => theme.fonts.regular};
    margin-top: 0px;
  }

  &:hover {
    opacity: 0.8;
  }
`

export const TabType = styled('h3')`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-weight: ${({ theme }) => theme.fonts.bold};
  color: ${({ theme }) => theme.colors.text};
  font-size: medium;
  margin: 0.4rem 0 0.2rem 0;
`

export const ErrorText = styled(Text)`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-weight: ${({ theme }) => theme.fonts.regular};
  color: ${({ theme }) => theme.colors.failure};
  font-size: 16px;
  margin-top: 10px;
  text-align: center;
`

export const StepBox = styled(Box)`
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-weight: ${({ theme }) => theme.fonts.bold};
  color: ${({ theme }) => theme.colors.dark};
  font-size: 16px;
  width: 22px;
  height: 22px;
  border-radius: 17px;
  background: ${({ theme }) => theme.colors.text};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 7px;
`

export const ChainFlex = styled(Flex)<{ isSelected?: boolean }>`
  // background: ${({ isSelected }) =>
    isSelected ? 'linear-gradient(114deg, #56566c 0%, #5a3c48 43%, #7561b9 100%)' : 'none'};
  padding: 1px;
  // background : linear-gradient(114deg, #56566c 0%, #5a3c48 43%, #7561b9 100%);
  height: 100%;
  width: 100%;
  border-radius: 12px;
  position: relative;
  .inner {
    border-radius: 12px;
    background: ${({ theme }) => theme.colors.background};
    height: 100%;
    width: 100%;
    padding: 10px;
  }
`

export const TickIcon = styled(FaCheckCircle)`
  color: ${({ theme }) => theme.colors.success};
  position: absolute;
  right: 5px;
  top: 5px;
`

export const ContinueButton = styled(Button)`
  width: 100%;
  padding: 0.6rem 0rem;
  margin-top: 0.6rem;
  border-radius: 8px;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-weight: ${({ theme }) => theme.fonts.regular};
`

export const TabText = styled(Text)`
  color: ${({ theme }) => theme.colors.text};
  font-size: 18px;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-weight: ${({ theme }) => theme.fonts.bold};
`

export const WalletName = styled(Text)`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-weight: ${({ theme }) => theme.fonts.regular};
  color: ${({ theme }) => theme.colors.text};
  font-size: 11px;
  white-space: nowrap;
`

export const ChainIdFlex = styled(Flex)<{ isDisable?: boolean }>`
  opacity: ${({ isDisable }) => (isDisable ? 0.3 : 1)};
  cursor: ${({ isDisable }) => (isDisable ? 'not-allowed' : 'pointer')};
`
