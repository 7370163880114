import useTheme from 'hooks/useTheme'
import { Flex } from 'components/Box'
import { Text } from 'components/Text'
import { InQueueText } from '../styles'
import { useTranslation } from 'contexts/Localization'
import { useChainInfo, useSwapTransactions, useTransactionId } from 'state/swap/selectors'
import { TransactionStatus } from 'config/enums/transactionStatus'
import { useEffect, useState } from 'react'
import { Field } from 'state/types'
import { useSwapActionHandlers } from 'state/swap/hooks'
import { ACTION_STEP_STATE, IActionStepState, SWAP_STATES } from 'state/swap/types'

const ProcessCard = ({ handleBack }) => {
  const { theme } = useTheme()
  const { t } = useTranslation()
  const { transactionId } = useTransactionId()
  const swapTransactions = useSwapTransactions()
  const [hash, setHash] = useState(null)
  let outputChainId = useChainInfo(Field.OUTPUT)
  const { onActionStepStateChanged, onUpdateSwapPageState } = useSwapActionHandlers()

  const inProgressTransaction = swapTransactions.find((txDetail) => txDetail._id === transactionId)

  useEffect(() => {
    let actionStepState: IActionStepState = {
      status: ACTION_STEP_STATE.IN_PROGRESS,
      message: 'Transaction In progress',
    }
    if (!inProgressTransaction) {
      actionStepState = {
        status: ACTION_STEP_STATE.ERROR,
        message: 'Transaction not found',
      }
    } else if (inProgressTransaction.status === TransactionStatus.FAILED) {
      actionStepState = {
        status: ACTION_STEP_STATE.ERROR,
        message: 'Trnasaction failed',
        // message: filteredTransaction.failReason,
      }
    } else if (inProgressTransaction.status === TransactionStatus.DONE) {
      actionStepState = {
        status: ACTION_STEP_STATE.OK,
        message: 'success',
        payload: {
          txid: inProgressTransaction.toSideTxHash,
        },
      }
    }
    if (actionStepState.status === ACTION_STEP_STATE.OK) {
      //if it is last step then update action and page state
      onUpdateSwapPageState(SWAP_STATES.SWAP_COMPLETED)
      onActionStepStateChanged(actionStepState)
    } else if (actionStepState.status === ACTION_STEP_STATE.ERROR) {
      // in case of error just update action state

      onActionStepStateChanged(actionStepState)
    }
  }, [swapTransactions])

  const blockSuccesssArray = new Set([TransactionStatus.IN_QUEUE, TransactionStatus.PROCESSING, TransactionStatus.DONE])
  const queueSuccesssArray = new Set([TransactionStatus.PROCESSING, TransactionStatus.DONE])

  return (
    <Flex justifyContent={'space-between'} flexDirection={'column'} width={'100%'} height={'100%'}>
      <Flex flexDirection={'column'} justifyContent={'center'} mt={'50px'} alignItems={'center'}>
        <InQueueText ml={'10px'} mt={'20px'}>
          Your transaction has been queued
        </InQueueText>
      </Flex>
      <Flex justifyContent={'center'} flexDirection={'column'} width={'100%'} alignItems={'center'} mt={'50px'}>
        <Text role="button" onClick={handleBack} className="fade-on-hover cancel-btn">
          Done
        </Text>
      </Flex>
    </Flex>
  )
}

export default ProcessCard
