import { useCallback } from 'react'
import axios from 'axios'
import * as Sentry from '@sentry/react'
import { Fee, FeeCollector } from 'config/enums'
import { Currency, CurrencyAmount } from 'config/entities'
import { IRoute, ROUTE_PROVIDER } from 'state/swap/types'
import { ZERO_X_API_URL } from 'config/constants/endpoints'

const use0xQuote = () => {
  const handle0xRate = useCallback(
    async (inputCurrency: Currency, outputCurrency: Currency, amount: CurrencyAmount) => {
      try {
        const url = `${ZERO_X_API_URL[inputCurrency.chainId]}swap/v1/quote?sellToken=${
          inputCurrency.address
        }&buyToken=${outputCurrency.address}&sellAmount=${amount.toWei().toFixed(0)}&buyTokenPercentageFee=${
          Fee.DEFAULT / 100
        }&feeRecipient=${FeeCollector.EVM_FEE_COLLECTOR}&skipValidation=true`

        Sentry.addBreadcrumb({
          type: 'EVM_QUOTE_0x',
          message: `Call 0x for Quote`,
          data: {
            url,
          },
        })
        const { data } = await axios(url)
        const route: IRoute = {
          inputCurrency: inputCurrency,
          outputCurrency: outputCurrency,
          name: ROUTE_PROVIDER.ZERO_X,
          evmRouteName: ROUTE_PROVIDER.ZERO_X,
          estimatedGas: data.estimatedGas,
          sourceInputAmount: amount,
          priceImpact: 0,
          destinationOutputAmount: new CurrencyAmount(data.buyAmount, outputCurrency.decimals),
        }
        return route
      } catch (error) {
        Sentry.captureException(error)
        throw error
      }
    },
    [],
  )
  return { handle0xRate }
}

export default use0xQuote
