import { useCallback, useEffect, useState } from 'react'
import * as Sentry from '@sentry/react'
import axios from 'axios'
import { ATS_TO_USD_URL, COINGECKO_BASE_URL, RELAYER_BACKEND_BASE_URL } from 'config/constants/endpoints'
import useRefresh from './useRefresh'
import { CHAIN_ID_ETH, tryNativeToHexString } from '@certusone/wormhole-sdk'

export const useCurrencyDollarPrice = () => {
  const getCurrencyDollarPrice = useCallback(async (coinGekoId: string) => {
    const { data } = await axios.get(`${COINGECKO_BASE_URL}/simple/price?ids=${coinGekoId}&vs_currencies=usd`)
    return data[coinGekoId]?.usd
  }, [])

  return { getCurrencyDollarPrice }
}
