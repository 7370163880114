import React from 'react'
import { Web3ReactProvider } from '@web3-react/core'
import { Provider } from 'react-redux'
import { getLibrary } from 'utils/web3React'
import { LanguageProvider } from 'contexts/Localization'
import { ThemeContextProvider } from 'contexts/ThemeContext'
import { RefreshContextProvider } from 'contexts/RefreshContext'

import { Store } from '@reduxjs/toolkit'
import { SolanaWalletProvider } from 'contexts/Wallets/SolanaWalletContext'
import { ToastsProvider } from 'contexts/ToastsContext'
import { ErrorBoundaryWeb3ProviderNetwork } from 'utils/ErrorBoundaryWeb3ProviderNetwork'
import { ModalProvider } from './widgets/Modal'

const Providers: React.FC<{ store: Store }> = ({ children, store }) => {
  return (
    <SolanaWalletProvider>
      <Web3ReactProvider getLibrary={getLibrary}>
        <ErrorBoundaryWeb3ProviderNetwork>
          <Provider store={store}>
            <LanguageProvider>
              <ThemeContextProvider>
                <ToastsProvider>
                  <ModalProvider>
                    <RefreshContextProvider>{children}</RefreshContextProvider>
                  </ModalProvider>
                </ToastsProvider>
              </ThemeContextProvider>
            </LanguageProvider>
          </Provider>
        </ErrorBoundaryWeb3ProviderNetwork>
      </Web3ReactProvider>
    </SolanaWalletProvider>
  )
}

export default Providers
