import { createReducer } from '@reduxjs/toolkit'
import { INITIAL_ALLOWED_SLIPPAGE } from '../../config/constants'
import { updateVersion } from '../global/actions'
import { toggleTheme, updateGasPrice, updateUserSlippageTolerance, hidePhishingWarningBanner } from './actions'
import { Percent } from 'config/entities'

const currentTimestamp = () => new Date().getTime()

export interface UserState {
  // the timestamp of the last updateVersion action
  lastUpdateVersionTimestamp?: number

  // user defined slippage tolerance in bips, used in all txns
  userSlippageTolerance: Percent

  timestamp: number
  isDark: boolean
  gasPrice: string
  hideTimestampPhishingWarningBanner: number
}

export const initialState: UserState = {
  userSlippageTolerance: INITIAL_ALLOWED_SLIPPAGE,
  timestamp: currentTimestamp(),
  isDark: false,
  gasPrice: '5GWEI',
  hideTimestampPhishingWarningBanner: null,
}

export default createReducer(initialState, (builder) =>
  builder
    .addCase(updateVersion, (state) => {
      // slippage isnt being tracked in local storage, reset to default
      // noinspection SuspiciousTypeOfGuard
      if (typeof state.userSlippageTolerance !== 'number') {
        state.userSlippageTolerance = INITIAL_ALLOWED_SLIPPAGE
      }

      state.lastUpdateVersionTimestamp = currentTimestamp()
    })
    .addCase(updateUserSlippageTolerance, (state, action) => {
      state.userSlippageTolerance = action.payload.userSlippageTolerance
      state.timestamp = currentTimestamp()
    })
    .addCase(toggleTheme, (state) => {
      state.isDark = !state.isDark
    })
    .addCase(updateGasPrice, (state, action) => {
      state.gasPrice = action.payload.gasPrice
    })
    .addCase(hidePhishingWarningBanner, (state) => {
      state.hideTimestampPhishingWarningBanner = currentTimestamp()
    }),
)
