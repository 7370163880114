import { Flex } from 'components/Box'
import Card from 'components/Card'
import { Text } from 'components/Text'
import useTheme from 'hooks/useTheme'
import { StepBox } from '../styles'

export const HeadingText = ({ step, heading }) => {
  const { theme } = useTheme()

  return (
    <Flex justifyContent={'start'} alignItems={'center'} my="18px">
      {step > 0 && <StepBox>{step}</StepBox>}
      <Text fontFamily={theme.fonts.primary} fontWeight={theme.fonts.semiBold} color={theme.colors.text}>
        {heading}
      </Text>
    </Flex>
  )
}

export const BoxWrapper = (props) => {
  const { theme } = useTheme()
  const { children } = props

  return (
    <Card width={'100%'} bgColor={theme.colors.background} padding={'10px 8px'} borderColor={theme.colors.border}>
      {children}
    </Card>
  )
}
