import { SupportedChainId } from './chains'

export default {
  multiCall: {
    [SupportedChainId.ETHEREUM]: '0x5ba1e12693dc8f9c48aad8770482f4739beed696',
    4: '0x5ba1e12693dc8f9c48aad8770482f4739beed696',
    [SupportedChainId.BINANCE]: '0xfF6FD90A470Aaa0c1B8A54681746b07AcdFedc9B',
    97: '0x8F3273Fb89B075b1645095ABaC6ed17B2d4Bc576',
    [SupportedChainId.POLYGON]: '0x275617327c958bD06b5D6b871E7f491D76113dd8',
    [SupportedChainId.AVAX]: '0x8755b94F88D120AB2Cc13b1f6582329b067C760d',
    [SupportedChainId.FANTOM]: '0xD98e3dBE5950Ca8Ce5a4b59630a5652110403E5c',
  },
  wrapped: {
    [SupportedChainId.ETHEREUM]: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    [SupportedChainId.BINANCE]: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    [SupportedChainId.POLYGON]: '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270',
    [SupportedChainId.AVAX]: '0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7',
    [SupportedChainId.FANTOM]: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
  },
  wormholeBridge: {
    [SupportedChainId.ETHEREUM]: '0x98f3c9e6E3fAce36bAAd05FE09d375Ef1464288B',
    [SupportedChainId.BINANCE]: '0x98f3c9e6E3fAce36bAAd05FE09d375Ef1464288B',
    [SupportedChainId.POLYGON]: '0x7A4B5a56256163F07b2C80A7cA55aBE66c4ec4d7',
    [SupportedChainId.AVAX]: '0x54a8e5f9c4CbA08F9943965859F6c34eAF03E26c',
    [SupportedChainId.SOLANA]: 'worm2ZoG2kUd4vFXhvjh93UUH596ayRfgQ2MgjNMTth',
    [SupportedChainId.FANTOM]: '0x126783A6Cb203a3E35344528B26ca3a0489a1485',
  },
  wormholeTokenBridge: {
    [SupportedChainId.ETHEREUM]: '0x3ee18b2214aff97000d974cf647e7c347e8fa585',
    [SupportedChainId.BINANCE]: '0xB6F6D86a8f9879A9c87f643768d9efc38c1Da6E7',
    [SupportedChainId.POLYGON]: '0x5a58505a96D1dbf8dF91cB21B54419FC36e93fdE',
    [SupportedChainId.AVAX]: '0x0e082F06FF657D94310cB8cE8B0D9a04541d8052',
    [SupportedChainId.SOLANA]: 'wormDTUJ6AWPNvk59vGQbDvGJmqbDTdgWgAqcLBCgUb',
    [SupportedChainId.FANTOM]: '0x7C9Fc5741288cDFdD83CeB07f3ea7e22618D79D2',
  },
  usdt: {
    [SupportedChainId.ETHEREUM]: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
    [SupportedChainId.BINANCE]: '0x55d398326f99059ff775485246999027b3197955',
    [SupportedChainId.POLYGON]: '0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
  },
  atlasDexSwap: {
    [SupportedChainId.SOLANA]: '8pM298k7pWWCAcCQAbsQ7yB1d28A3qbG6mejwe33mezj', //:TODO for now added our hot wallet public key but will do replace with smart contract.
    [SupportedChainId.ETHEREUM]: '0x69eb290e7f863defbb817d949de519feafc6fc4e',
    [SupportedChainId.BINANCE]: '0x166d02c840EE61E674819175930C4be5c70d3f32',
    [SupportedChainId.POLYGON]: '0xee3De417A330c4006edB0a72429C6Fd666ddf4ee',
    [SupportedChainId.AVAX]: '0xC350b9910C211e60A8D69b341F69f4ba41eebE8e',
    [SupportedChainId.FANTOM]: '0xA1e29163E0390AEd0a4B5a72F5715Dc023f48789',
  },
  _1inchRouter: {
    [SupportedChainId.ETHEREUM]: '0x1111111254fb6c44bAC0beD2854e76F90643097d',
    [SupportedChainId.BINANCE]: '0x1111111254fb6c44bAC0beD2854e76F90643097d',
    [SupportedChainId.POLYGON]: '0x1111111254fb6c44bAC0beD2854e76F90643097d',
    [SupportedChainId.AVAX]: '0x1111111254fb6c44bAC0beD2854e76F90643097d',
    [SupportedChainId.FANTOM]: '0x1111111254fb6c44bAC0beD2854e76F90643097d',
  },
  _0xRouter: {
    [SupportedChainId.ETHEREUM]: '0xdef1c0ded9bec7f1a1670819833240f027b25eff',
    [SupportedChainId.BINANCE]: '0xdef1c0ded9bec7f1a1670819833240f027b25eff',
    [SupportedChainId.POLYGON]: '0xdef1c0ded9bec7f1a1670819833240f027b25eff',
    [SupportedChainId.AVAX]: '0xdef1c0ded9bec7f1a1670819833240f027b25eff',
    [SupportedChainId.FANTOM]: '0xdef189deaef76e379df891899eb5a00a94cbc250',
  },
}
