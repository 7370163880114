import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0  173.959 171.137" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="0.121"
          y1="0.229"
          x2="0.887"
          y2="0.746"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#f76825" />
          <stop offset="1" stopColor="#403fd8" />
        </linearGradient>
      </defs>
      <g id="Group_2029" data-name="Group 2029" transform="translate(-831.422 -281)">
        <ellipse
          id="Ellipse_1"
          data-name="Ellipse 1"
          cx="35.979"
          cy="34.568"
          rx="35.979"
          ry="34.568"
          transform="translate(892.422 332)"
          fill="url(#linear-gradient)"
          filter="blur(20px)"
        />
        <g id="wallet-outline" transform="translate(870 338.295)">
          <rect
            id="Rectangle_2032"
            data-name="Rectangle 2032"
            width="71.659"
            height="49.61"
            rx="15"
            transform="translate(0 13.231)"
            fill="rgba(198,198,198,0.87)"
          />
          <path
            id="Path_23"
            data-name="Path 23"
            d="M48.122,87.63H45.5V79.19a9.924,9.924,0,0,1,8.066-9.754l.025,0L98.944,61.69a9.924,9.924,0,0,1,11.769,9.747v5.168h-2.622V71.438a7.3,7.3,0,0,0-8.669-7.17l-.025,0L54.045,72.013a7.309,7.309,0,0,0-5.923,7.176Z"
            transform="translate(-44.955 -61.519)"
            fill="#b0aeb0"
          />
          <path
            id="Path_24"
            data-name="Path 24"
            d="M341.512,267.025a5.512,5.512,0,1,1,5.512-5.512A5.512,5.512,0,0,1,341.512,267.025Z"
            transform="translate(-286.39 -223.476)"
            fill="#834e95"
          />
          <path
            id="Path_24_-_Outline"
            data-name="Path 24 - Outline"
            d="M341.274,267.049a5.774,5.774,0,1,1,4.083-1.691A5.737,5.737,0,0,1,341.274,267.049Zm0-11.025a5.25,5.25,0,1,0,5.25,5.25A5.256,5.256,0,0,0,341.274,256.024Z"
            transform="translate(-286.152 -223.238)"
            fill="#707070"
          />
        </g>
      </g>
    </Svg>
  )
}

export default Icon
