import { SupportedChainId } from 'config/constants/chains'
import { Currency } from 'config/entities'
export const FantomTokens: Currency[] = [
  {
    name: 'Fantom',
    address: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
    symbol: 'FTM',
    decimals: 18,
    chainId: SupportedChainId.FANTOM,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/128x128/3513.png',
  },
  {
    name: 'Wrapped Fantom',
    address: '0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83',
    symbol: 'WFTM',
    decimals: 18,
    chainId: SupportedChainId.FANTOM,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/128x128/10240.png',
  },
  {
    address: '0x67c10c397dd0ba417329543c1a40eb48aaa7cd00',
    chainId: SupportedChainId.FANTOM,
    decimals: 18,
    logoURI: 'https://pbs.twimg.com/profile_images/1320025329730203648/g2cxdmVm_400x400.jpg',
    name: 'nETH',
    symbol: 'nETH',
  },
  {
    address: '0xed2a7edd7413021d440b09d654f3b87712abab66',
    chainId: SupportedChainId.FANTOM,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16044.png',
    name: 'nUSD (HotBit)',
    symbol: 'nUSD',
  },
  {
    name: 'Wrapped Ether',
    address: '0x74b23882a30290451A17c44f4F05243b6b58C76d',
    symbol: 'WETH',
    decimals: 18,
    chainId: SupportedChainId.FANTOM,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/128x128/2396.png',
  },
  {
    name: 'Dai Stablecoin',
    address: '0x8D11eC38a3EB5E956B052f67Da8Bdc9bef8Abf3E',
    symbol: 'DAI',
    decimals: 18,
    chainId: SupportedChainId.FANTOM,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/128x128/4943.png',
  },
  {
    name: 'USD Coin',
    address: '0x04068DA6C83AFCFA0e13ba15A6696662335D5B75',
    symbol: 'USDC',
    decimals: 6,
    chainId: SupportedChainId.FANTOM,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/128x128/3408.png',
  },
  {
    name: 'Frapped USDT',
    address: '0x049d68029688eAbF473097a2fC38ef61633A3C7A',
    symbol: 'fUSDT',
    decimals: 6,
    chainId: SupportedChainId.FANTOM,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/128x128/10244.png',
  },
  {
    name: 'Wrapped Bitcoin',
    address: '0x321162Cd933E2Be498Cd2267a90534A804051b11',
    symbol: 'WBTC',
    decimals: 8,
    chainId: SupportedChainId.FANTOM,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/128x128/3717.png',
  },
  {
    name: 'renBTC',
    address: '0xdbf31df14b66535af65aac99c32e9ea844e14501',
    symbol: 'renBTC',
    decimals: 8,
    chainId: SupportedChainId.FANTOM,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5777.png',
  },
  {
    address: '0x846e4D51d7E2043C1a87E0Ab7490B93FB940357b',
    chainId: SupportedChainId.FANTOM,
    decimals: 6,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/128x128/7129.png',
    name: 'UST (Wormhole)',
    symbol: 'UST',
  },
]
