import { SupportedChainId } from 'config/constants/chains'

export const WormholeTokenShortPath = new Map()

// SOLANA - ETHEREUM TOKENS HARD CODED

// USDC
WormholeTokenShortPath.set(
  `${SupportedChainId.SOLANA}-${SupportedChainId.ETHEREUM}-${'0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48'}`,
  {
    currencyToLock: {
      address: 'A9mUU4qviSctJVPJdBJWkb28deg915LYJKrzQ19ji3FM',
      chainId: SupportedChainId.SOLANA,
      decimals: 6,
      logoURI: 'https://raw.githubusercontent.com/certusone/wormhole-token-list/main/assets/USDCso_wh.png',
      name: 'USD Coin (Wormhole from Ethereum)',
      symbol: 'USDC',
    },
    currencyToMint: {
      address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
      chainId: SupportedChainId.ETHEREUM,
      decimals: 6,
      logoURI:
        'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48/logo.png',
      name: 'USD Coin',
      symbol: 'USDC',
    },
  },
)

WormholeTokenShortPath.set(
  `${'0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48'}-${SupportedChainId.ETHEREUM}-${SupportedChainId.SOLANA}`,
  {
    currencyToLock: {
      address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
      chainId: SupportedChainId.ETHEREUM,
      decimals: 6,
      logoURI:
        'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48/logo.png',
      name: 'USD Coin',
      symbol: 'USDC',
    },
    currencyToMint: {
      address: 'A9mUU4qviSctJVPJdBJWkb28deg915LYJKrzQ19ji3FM',
      chainId: SupportedChainId.SOLANA,
      decimals: 6,
      logoURI: 'https://raw.githubusercontent.com/certusone/wormhole-token-list/main/assets/USDCso_wh.png',
      name: 'USD Coin (Wormhole from Ethereum)',
      symbol: 'USDC',
    },
  },
)

// USDT
WormholeTokenShortPath.set(
  `${SupportedChainId.SOLANA}-${SupportedChainId.ETHEREUM}-${'0xdac17f958d2ee523a2206206994597c13d831ec7'}`,
  {
    currencyToLock: {
      address: 'Dn4noZ5jgGfkntzcQSUZ8czkreiZ1ForXYoV2H8Dm7S1',
      chainId: SupportedChainId.SOLANA,
      decimals: 6,
      logoURI:
        'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Dn4noZ5jgGfkntzcQSUZ8czkreiZ1ForXYoV2H8Dm7S1/logo.png',
      name: 'Tether USD (Wormhole from Ethereum)',
      symbol: 'USDT',
    },
    currencyToMint: {
      address: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
      chainId: SupportedChainId.ETHEREUM,
      decimals: 6,
      logoURI: 'https://etherscan.io/token/images/tether_32.png',
      name: 'Tether USD',
      symbol: 'USDT',
    },
  },
)

WormholeTokenShortPath.set(
  `${'0xdac17f958d2ee523a2206206994597c13d831ec7'}-${SupportedChainId.ETHEREUM}-${SupportedChainId.SOLANA}`,
  {
    currencyToLock: {
      address: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
      chainId: SupportedChainId.ETHEREUM,
      decimals: 6,
      logoURI: 'https://etherscan.io/token/images/tether_32.png',
      name: 'Tether USD',
      symbol: 'USDT',
    },
    currencyToMint: {
      address: 'Dn4noZ5jgGfkntzcQSUZ8czkreiZ1ForXYoV2H8Dm7S1',
      chainId: SupportedChainId.SOLANA,
      decimals: 6,
      logoURI:
        'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Dn4noZ5jgGfkntzcQSUZ8czkreiZ1ForXYoV2H8Dm7S1/logo.png',
      name: 'Tether USD (Wormhole from Ethereum)',
      symbol: 'USDT',
    },
  },
)

// ETH
WormholeTokenShortPath.set(
  `${SupportedChainId.SOLANA}-${SupportedChainId.ETHEREUM}-${'0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee'}`,
  {
    currencyToLock: {
      address: '7vfCXTUXx5WJV5JADk17DUJ4ksgau7utNKj4b963voxs',
      chainId: SupportedChainId.SOLANA,
      decimals: 8,
      logoURI:
        'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7vfCXTUXx5WJV5JADk17DUJ4ksgau7utNKj4b963voxs/logo.png',
      name: 'Ether (Wormhole)',
      symbol: 'WETH',
    },
    currencyToMint: {
      address: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
      chainId: SupportedChainId.ETHEREUM,
      decimals: 18,
      logoURI: 'https://etherscan.io/token/images/weth_28.png',
      name: 'Wrapped Ether',
      symbol: 'WETH',
    },
  },
)

WormholeTokenShortPath.set(
  `${'0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee'}-${SupportedChainId.ETHEREUM}-${SupportedChainId.SOLANA}`,
  {
    currencyToLock: {
      address: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
      chainId: SupportedChainId.ETHEREUM,
      decimals: 18,
      logoURI: 'https://etherscan.io/token/images/weth_28.png',
      name: 'Wrapped Ether',
      symbol: 'WETH',
    },
    currencyToMint: {
      address: '7vfCXTUXx5WJV5JADk17DUJ4ksgau7utNKj4b963voxs',
      chainId: SupportedChainId.SOLANA,
      decimals: 8,
      logoURI:
        'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7vfCXTUXx5WJV5JADk17DUJ4ksgau7utNKj4b963voxs/logo.png',
      name: 'Ether (Wormhole)',
      symbol: 'WETH',
    },
  },
)

// END OF SOLANA ETHEREUM STABLE SWAP PAIRS

// SOLANA BSC SWAP PAIRS
// USDC
WormholeTokenShortPath.set(
  `${SupportedChainId.SOLANA}-${SupportedChainId.BINANCE}-${'0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d'}`,
  {
    currencyToLock: {
      address: 'FCqfQSujuPxy6V42UvafBhsysWtEq1vhjfMN1PUbgaxA',
      chainId: SupportedChainId.SOLANA,
      decimals: 8,
      logoURI:
        'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FCqfQSujuPxy6V42UvafBhsysWtEq1vhjfMN1PUbgaxA/logo.png',
      name: 'USD Coin (Wormhole from BSC)',
      symbol: 'USDCbs',
    },
    currencyToMint: {
      address: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
      chainId: SupportedChainId.BINANCE,
      decimals: 18,
      logoURI: 'https://bscscan.com/token/images/centre-usdc_28.png',
      name: 'Binance-Peg USD Coin',
      symbol: 'USDC',
    },
  },
)

WormholeTokenShortPath.set(
  `${'0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d'}-${SupportedChainId.BINANCE}-${SupportedChainId.SOLANA}`,
  {
    currencyToLock: {
      address: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
      chainId: SupportedChainId.BINANCE,
      decimals: 18,
      logoURI: 'https://bscscan.com/token/images/centre-usdc_28.png',
      name: 'Binance-Peg USD Coin',
      symbol: 'USDC',
    },
    currencyToMint: {
      address: 'FCqfQSujuPxy6V42UvafBhsysWtEq1vhjfMN1PUbgaxA',
      chainId: SupportedChainId.SOLANA,
      decimals: 8,
      logoURI:
        'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FCqfQSujuPxy6V42UvafBhsysWtEq1vhjfMN1PUbgaxA/logo.png',
      name: 'USD Coin (Wormhole from BSC)',
      symbol: 'USDCbs',
    },
  },
)

// USDT
WormholeTokenShortPath.set(
  `${SupportedChainId.SOLANA}-${SupportedChainId.BINANCE}-${'0x55d398326f99059ff775485246999027b3197955'}`,
  {
    currencyToLock: {
      address: '8qJSyQprMC57TWKaYEmetUR3UUiTP2M3hXdcvFhkZdmv',
      chainId: SupportedChainId.SOLANA,
      decimals: 8,
      logoURI:
        'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8qJSyQprMC57TWKaYEmetUR3UUiTP2M3hXdcvFhkZdmv/logo.png',
      name: 'Tether USD (Wormhole from BSC)',
      symbol: 'USDT',
    },
    currencyToMint: {
      address: '0x55d398326f99059ff775485246999027b3197955',
      chainId: SupportedChainId.BINANCE,
      decimals: 18,
      logoURI: 'https://bscscan.com/token/images/busdt_32.png',
      name: 'Binance-Peg BSC-USD',
      symbol: 'USDT',
    },
  },
)

WormholeTokenShortPath.set(
  `${'0x55d398326f99059ff775485246999027b3197955'}-${SupportedChainId.BINANCE}-${SupportedChainId.SOLANA}`,
  {
    currencyToLock: {
      address: '0x55d398326f99059ff775485246999027b3197955',
      chainId: SupportedChainId.BINANCE,
      decimals: 18,
      logoURI: 'https://bscscan.com/token/images/busdt_32.png',
      name: 'Binance-Peg BSC-USD',
      symbol: 'USDT',
    },
    currencyToMint: {
      address: '8qJSyQprMC57TWKaYEmetUR3UUiTP2M3hXdcvFhkZdmv',
      chainId: SupportedChainId.SOLANA,
      decimals: 8,
      logoURI:
        'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8qJSyQprMC57TWKaYEmetUR3UUiTP2M3hXdcvFhkZdmv/logo.png',
      name: 'Tether USD (Wormhole from BSC)',
      symbol: 'USDT',
    },
  },
)

// BNB

WormholeTokenShortPath.set(
  `${SupportedChainId.SOLANA}-${SupportedChainId.BINANCE}-${'0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee'}`,
  {
    currencyToLock: {
      address: '9gP2kCy3wA1ctvYWQk75guqXuHfrEomqydHLtcTCqiLa',
      chainId: SupportedChainId.SOLANA,
      decimals: 8,
      logoURI:
        'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9gP2kCy3wA1ctvYWQk75guqXuHfrEomqydHLtcTCqiLa/logo.png',
      name: 'Binance Coin (Wormhole)',
      symbol: 'WBNB',
    },
    currencyToMint: {
      address: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
      chainId: SupportedChainId.BINANCE,
      decimals: 18,
      logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7192.png',
      name: 'Wrapped BNB',
      symbol: 'WBNB',
    },
  },
)

WormholeTokenShortPath.set(
  `${'0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee'}-${SupportedChainId.BINANCE}-${SupportedChainId.SOLANA}`,
  {
    currencyToLock: {
      address: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
      chainId: SupportedChainId.BINANCE,
      decimals: 18,
      logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7192.png',
      name: 'Wrapped BNB',
      symbol: 'WBNB',
    },
    currencyToMint: {
      address: '9gP2kCy3wA1ctvYWQk75guqXuHfrEomqydHLtcTCqiLa',
      chainId: SupportedChainId.SOLANA,
      decimals: 8,
      logoURI:
        'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9gP2kCy3wA1ctvYWQk75guqXuHfrEomqydHLtcTCqiLa/logo.png',
      name: 'Binance Coin (Wormhole)',
      symbol: 'WBNB',
    },
  },
)
// END OF SOLANA BSC SWAP PAIRS
