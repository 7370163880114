import { SupportedChainId } from 'config/constants/chains'
import { useSelector } from 'react-redux'
import { AppState } from 'state'
import { ACTION_STEP_STATE, IActionStepState } from 'state/swap/types'
import { Pool, POOL_STATES } from './types'

export function usePools(): Pool[] {
  const state = useSelector<AppState, AppState['pools']>((s) => s.pools)
  return state.pools
}

export function usePoolChainId(): SupportedChainId {
  const state = useSelector<AppState, AppState['pools']>((s) => s.pools)
  return state.poolChainId
}

export function usePairAddress(): string {
  const state = useSelector<AppState, AppState['pools']>((s) => s.pools)
  return state.pairAddress
}

export function useRouterAddress(): string {
  const state = useSelector<AppState, AppState['pools']>((s) => s.pools)
  return state.routerAddress
}

/**
 * Returns Pool Page State
 */
export function usePoolState(): POOL_STATES {
  const state = useSelector<AppState, AppState['pools']>((s) => s.pools)
  return state.pageState
}

/**
 * Returns Pool action set State
 */
export function usePoolStepState(): IActionStepState {
  const state = useSelector<AppState, AppState['pools']>((s) => s.pools)
  return state.actionStepState
}

export function useActivePoolStepIndex() {
  const state = useSelector<AppState, AppState['pools']>((s) => s.pools)
  return { activeStepIndex: state.activeStepIndex }
}

export function usePoolActiveStep(activeStepIndex: number) {
  const state = useSelector<AppState, AppState['pools']>((s) => s.pools)
  return { step: state.steps[activeStepIndex] }
}

export function usePoolSteps() {
  const state = useSelector<AppState, AppState['pools']>((s) => s.pools)
  return { steps: state.steps }
}

export function usePairLiquidity() {
  const state = useSelector<AppState, AppState['pools']>((s) => s.pools)
  return state.liquidity
}

export function useLpBalance() {
  const state = useSelector<AppState, AppState['pools']>((s) => s.pools)
  return state.lpBalance
}

export function useUserShare() {
  const state = useSelector<AppState, AppState['pools']>((s) => s.pools)
  return state.userShare
}

export function useFocusSide() {
  const state = useSelector<AppState, AppState['pools']>((s) => s.pools)
  return state.focusSide
}

export function useUserAssets() {
  const state = useSelector<AppState, AppState['pools']>((s) => s.pools)
  return state.userAssets
}

export function useLpDeposited() {
  const state = useSelector<AppState, AppState['pools']>((s) => s.pools)
  return state.lpDeposited
}

export function useFarmAddress() {
  const state = useSelector<AppState, AppState['pools']>((s) => s.pools)
  return state.farmAddress
}

export function useTotalLiquidity() {
  const state = useSelector<AppState, AppState['pools']>((s) => s.pools)
  return state.totalLiquidity
}

export function useTotalVolume() {
  const state = useSelector<AppState, AppState['pools']>((s) => s.pools)
  return state.totalVolume
}

export function useFarmPendingReward() {
  const state = useSelector<AppState, AppState['pools']>((s) => s.pools)
  return state.pendingReward
}

export function useAutoRefresh() {
  const state = useSelector<AppState, AppState['pools']>((s) => s.pools)
  return state.autoRefresh
}
