import styled from 'styled-components'
import { Dropdown } from 'react-bootstrap'
import { BsGlobe } from 'react-icons/bs'

export const StyleDropDown = styled(Dropdown)`
  #dropdown-basic::after {
    display: none !important;
  }
  .dropdown {
    border-radius: 20px;
    .icon {
      color: #787889;
      font-size: 24px;
    }
  }
  .dropdown-menu {
    width: 60px !important;
    background: radial-gradient(
        106.46% 161.43% at 1.69% 50%,
        rgba(165, 156, 249, 0.2) 0%,
        rgba(21, 0, 233, 0.032) 77.08%,
        rgba(21, 0, 233, 0) 100%
      ),
      #0d0d18;
    border: none;
    padding: 0;
    transform: translate3d(0, 13px, 0px) !important;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .dropdown-item {
    padding: 0;
  }

  .dropdown-item:hover {
    background: #1e1e30;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .dropdown-text {
    width: 100px;
    text-align: left;
  }
`

export const GlobeIcon = styled(BsGlobe)`
  color: ${({ theme }) => theme.colors.text};
  margin-right: 7px;
  cursor: pointer;
  &:hover {
    opacity: 0.6;
    transform: scale(1.2);
  }
`
