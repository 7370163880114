import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 207.538 32.387" {...props}>
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="0.154"
          y1="0.384"
          x2="0.735"
          y2="1.143"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#ff6c21" />
          <stop offset="0.025" stopColor="#f66929" />
          <stop offset="0.254" stopColor="#ac4f6a" />
          <stop offset="0.418" stopColor="#7e3f92" />
          <stop offset="0.5" stopColor="#6c39a2" />
          <stop offset="1" stopColor="#403fd8" />
        </linearGradient>
      </defs>
      <g id="Group_16" data-name="Group 16">
        <path
          id="Path_28"
          data-name="Path 28"
          d="M3812.987,327.954l-3.754-.463-7.9-18.933-14.656,19.955,15.408-26.793Zm-6.671-19.859,8.984,21.616-20.47-2.667-2.345,3.118,28.713,3.782Zm-16.146,23.146,12.5-16.374-1.521-3.643-17.156,22.883h29.227Z"
          transform="translate(-3783.99 -301.72)"
          fill="url(#linear-gradient)"
        />
      </g>
      <path
        id="Path_73"
        data-name="Path 73"
        d="M308.91,57.484h3.226l3.034-7.414h5.392l3.149,7.414h3.347L318.01,36.3Zm10.4-10.526h-2.774l1.324-3.306Z"
        transform="translate(-260.277 -30.585)"
        fill="#fff"
      />
      <path
        id="Path_74"
        data-name="Path 74"
        d="M662.13,57.484h3.226l3.034-7.414h5.392l3.149,7.414h3.345L671.23,36.3Zm10.4-10.526h-2.793l1.324-3.306Z"
        transform="translate(-557.889 -30.585)"
        fill="#fff"
      />
      <path
        id="Path_75"
        data-name="Path 75"
        d="M436.74,46.505h6.379v17.16H446.2V46.505h6.379V43.64H436.74Z"
        transform="translate(-367.983 -36.77)"
        fill="#fff"
      />
      <path
        id="Path_76"
        data-name="Path 76"
        d="M556.777,43.64H553.72V63.669h13.048V60.78h-9.991Z"
        transform="translate(-466.546 -36.77)"
        fill="#fff"
      />
      <path
        id="Path_77"
        data-name="Path 77"
        d="M809.285,51.785l-.12-.049a8.407,8.407,0,0,1-2.239-1.565,2.461,2.461,0,0,1-.505-1.587,1.967,1.967,0,0,1,.649-1.565,2.833,2.833,0,0,1,1.878-.6,3.328,3.328,0,0,1,1.565.361,2.417,2.417,0,0,1,1.036.987l.241.458,2.047-1.9-.192-.29a5.087,5.087,0,0,0-1.951-1.781,5.864,5.864,0,0,0-2.646-.6,5.649,5.649,0,0,0-3.974,1.373,4.811,4.811,0,0,0-1.541,3.634,5.024,5.024,0,0,0,1.058,3.249,11.016,11.016,0,0,0,3.588,2.48,9.758,9.758,0,0,1,2.865,1.9,2.711,2.711,0,0,1,.77,1.951,2.768,2.768,0,0,1-.819,2.166,3.463,3.463,0,0,1-2.384.776,3.857,3.857,0,0,1-2.166-.6,3.245,3.245,0,0,1-1.3-1.637l-.192-.554-2.143,1.829.121.266a5.288,5.288,0,0,0,2.166,2.527,7.169,7.169,0,0,0,7.944-.625,5.549,5.549,0,0,0,1.685-4.213C814.726,55.876,812.9,53.71,809.285,51.785Z"
        transform="translate(-676.421 -36.777)"
        fill="#fff"
      />
      <path
        id="Path_78"
        data-name="Path 78"
        d="M982.379,46.264c-1.9-1.732-4.285-2.624-7.8-2.624H970.39V63.791h4.068c3.635,0,6.042-.842,7.92-2.528s2.864-4.213,2.864-7.486A9.623,9.623,0,0,0,982.379,46.264Zm-.1,7.488c0,2.454-.65,4.284-1.927,5.439s-3.008,1.757-5.825,1.757h-1.156V46.505h1.4a7.575,7.575,0,0,1,5.537,1.829A7.017,7.017,0,0,1,982.283,53.752Z"
        transform="translate(-817.618 -36.77)"
        fill="#fff"
      />
      <path
        id="Path_79"
        data-name="Path 79"
        d="M1094,63.669h13.144V60.78h-10.11V55.123h8.69V52.234h-8.69v-5.68h10.11V43.64H1094Z"
        transform="translate(-921.768 -36.77)"
        fill="#fff"
      />
      <path
        id="Path_80"
        data-name="Path 80"
        d="M1219.879,53.7l6.667-10.063h-3.9l-4.566,7.242-4.742-7.242h-3.778l6.4,9.818-6.548,10.206h3.8l4.646-7.2,4.622,7.2h3.778Z"
        transform="translate(-1019.008 -36.77)"
        fill="#fff"
      />
    </Svg>
  )
}

export default Icon
