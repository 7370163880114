import { useCallback } from 'react'
import axios from 'axios'
import * as Sentry from '@sentry/react'
import { ONE_INCH_API_URL } from 'config/constants/endpoints'
import { RouteOutput } from 'config/entities/routeOutput'
import { Currency, CurrencyAmount } from 'config/entities'

const use1inchForRelayer = () => {
  const handle1InchForRelayer = useCallback(
    async (inputCurrency: Currency, outputCurrency: Currency, amount: CurrencyAmount): Promise<CurrencyAmount> => {
      try {
        // this means we are locking stable currency so we don't need this
        if (inputCurrency.address.toLowerCase() === outputCurrency.address.toLowerCase()) {
          return amount
        }
        const url = `${ONE_INCH_API_URL}${outputCurrency.chainId}/quote?fromTokenAddress=${
          inputCurrency.address
        }&toTokenAddress=${outputCurrency.address}&amount=${amount.toWei().toFixed(0)}`
        const { data } = await axios(url)
        const result: RouteOutput = data
        return new CurrencyAmount(result.toTokenAmount, outputCurrency.decimals)
      } catch (error) {
        Sentry.captureException(error)
        return new CurrencyAmount(0, outputCurrency.decimals)
      }
    },
    [],
  )
  return { handle1InchForRelayer }
}

export default use1inchForRelayer
