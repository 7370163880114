import { Flex, Box } from 'components/Box'
import styled from 'styled-components'
import { FaArrowRight } from 'react-icons/fa'
import { Text } from 'components/Text'
import { TiWarning } from 'react-icons/ti'

export const SwapFooter = styled(Flex)`
  background: ${({ theme }) => theme.colors.backgroundAlt};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 0 0 16px 16px;
  padding: 2rem;
  .title {
    font-family: ${({ theme }) => theme.fonts.primary};
    font-weight: ${({ theme }) => theme.fonts.semiBold};
    color: ${({ theme }) => theme.colors.text};
    font-size: medium;
  }
  .sub-title {
    font-family: ${({ theme }) => theme.fonts.primary};
    font-weight: ${({ theme }) => theme.fonts.regular};
    color: ${({ theme }) => theme.colors.success};
    font-size: small;
  }
`

export const SwapCardWrapper = styled(Box)<{ small?: boolean }>`
  background: ${({ theme, small }) => (small ? '#55667E' : theme.colors.gradientBackground)};
  border-radius: 16px;
  padding: 2px;
  width: 240px;
  margin: 0 auto;
  height: ${(props) => (props.small ? 'auto' : '100%')};
`

export const SwapCardInner = styled(Flex)<{ small?: boolean }>`
  background: ${({ theme }) => theme.colors.background};
  padding: 1rem 1.2rem;
  border-radius: 16px;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: ${(props) => (props.small ? '240px' : '340px')};
  height: ${(props) => (props.small ? 'auto' : '100%')};
`
export const WarningSection = styled(Flex)`
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 0.4rem;
  width: 94%;
  height: 100%;
  margin: 0 0;
  white-space: normal;
  word-spacing: normal;
  .retry-btn {
    margin: 0.2rem 0;
    padding: 0.4rem 1.2rem;
    border-radius: 12px;
  }
  .help-text {
    font-family: ${({ theme }) => theme.fonts.primary};
    font-family: ${({ theme }) => theme.fonts.regular};
    color: ${({ theme }) => theme.colors.text};
    font-size: small;
    margin-top: 1rem;
  }
  .cancel-btn {
    font-family: ${({ theme }) => theme.fonts.primary};
    font-family: ${({ theme }) => theme.fonts.regular};
    color: ${({ theme }) => theme.colors.textTertiary};
    text-align: center;
  }
  .warning-text {
    color: ${({ theme }) => theme.colors.text};
    margin: 0;
    font-family: ${({ theme }) => theme.fonts.primary};
    font-weight: ${({ theme }) => theme.fonts.regular};
    font-size: 16px;
    text-align: center;
    overflow: auto;
    word-break: break-word;
    max-height: 120px;
    width: 100%;

    &:nth-child(2) {
      margin-top: 0.4rem;
      cursor: pointer;
    }
  }
  .warning-icon {
    color: ${({ theme }) => theme.colors.text};
    font-size: 30px !important;
    margin: 0 0.25rem 4px 0;
  }
`

export const AnimatedArrows = styled(Flex)`
  position: relative;
  width: 100%;
  align-items: center;

  .arrow {
    position: absolute;
    margin-left: 0;
    width: 18px;
    height: 18px;
    background-size: contain;
    left: 21%;
    bottom: 2px;
  }

  .two {
    margin-left: 12px;
  }

  .three {
    margin-left: 24px;
  }

  .next {
    background-image: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiI+PHN0eWxlPi5zdDB7ZmlsbDojZmZmfTwvc3R5bGU+PHBhdGggY2xhc3M9InN0MCIgZD0iTTMxOS4xIDIxN2MyMC4yIDIwLjIgMTkuOSA1My4yLS42IDczLjdzLTUzLjUgMjAuOC03My43LjZsLTE5MC0xOTBjLTIwLjEtMjAuMi0xOS44LTUzLjIuNy03My43UzEwOSA2LjggMTI5LjEgMjdsMTkwIDE5MHoiLz48cGF0aCBjbGFzcz0ic3QwIiBkPSJNMzE5LjEgMjkwLjVjMjAuMi0yMC4yIDE5LjktNTMuMi0uNi03My43cy01My41LTIwLjgtNzMuNy0uNmwtMTkwIDE5MGMtMjAuMiAyMC4yLTE5LjkgNTMuMi42IDczLjdzNTMuNSAyMC44IDczLjcuNmwxOTAtMTkweiIvPjwvc3ZnPg==);
  }

  @keyframes bounceAlpha {
    0% {
      opacity: 1;
      transform: translateX(0px) scale(1);
    }
    25% {
      opacity: 0;
      transform: translateX(10px) scale(0.9);
    }
    26% {
      opacity: 0;
      transform: translateX(-10px) scale(0.9);
    }
    55% {
      opacity: 1;
      transform: translateX(0px) scale(1);
    }
  }

  .bounceAlpha {
    animation-name: bounceAlpha;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  .arrow.one.bounceAlpha {
    animation-name: bounceAlpha;
    animation-duration: 1.4s;
    animation-delay: 0.2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  .arrow.three.bounceAlpha {
    animation-name: bounceAlpha;
    animation-duration: 1.4s;
    animation-delay: 0.6s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
`

export const ArrowIcon = styled(FaArrowRight)`
  font-size: 15px;
  color: ${({ theme }) => theme.colors.textOrange};
`

export const FlexIconWrapper = styled(Flex)`
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  svg {
    width: 40px;
    height: 40px;
  }
`

export const TextBox = styled(Text)`
  font-size: small;
  text-transform: capitalize;
  color: ${({ theme }) => theme.colors.text};
  font-family: ${({ theme }) => theme.fonts.primary};
  font-weight: ${({ theme }) => theme.fonts.regular};
`

export const WarningText = styled.span<{ isShow?: boolean }>`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.textOrange};
  font-family: ${({ theme }) => theme.fonts.primary};
  font-weight: ${({ theme }) => theme.fonts.semiBold};
  margin: -11px 8px 10px 0;
  line-height: 21px;
  opacity: ${(props) => (props.isShow ? '1' : '0')};
`

export const WarningInfo = styled.span`
  color: ${({ theme }) => theme.colors.text};
  font-size: 14px;
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-weight: ${({ theme }) => theme.fonts.regular};
  letter-spacing: 0.05px;
`

export const WarningIcon = styled(TiWarning)`
  color: ${({ theme }) => theme.colors.textOrange};
  font-size: 20px;
  margin-top: -3px;
`
export const NoteText = styled.span<{ isShow?: boolean }>`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.textHeading};
  font-family: ${({ theme }) => theme.fonts.primary};
  font-weight: ${({ theme }) => theme.fonts.semiBold};
  margin-top: 5px;
  opacity: ${(props) => (props.isShow ? '1' : '0')};
`

export const ProcessText = styled(Text)`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.textHeading};
  font-family: ${({ theme }) => theme.fonts.primary};
  font-weight: ${({ theme }) => theme.fonts.regular};
`
export const InQueueText = styled(Text)`
  font-size: 19px;
  width: 188px;
  text-align: center;
  letter-spacing: 0px;
  color: ${({ theme }) => theme.colors.text};
  font-family: ${({ theme }) => theme.fonts.primary};
  font-weight: ${({ theme }) => theme.fonts.regular};
`
