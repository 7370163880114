import { Colors } from './types'

export const baseColors = {
  failure: '#F51818',
  primary: '#6205CB',
  primaryBright: '#FFB703',
  primaryDark: '#0098A1',
  secondary: '#182b3c',
  success: '#14F195',
  warning: '#E9C821',
  gradientBackground: 'linear-gradient(96deg, #FF6C21 0%, #403FD8 100%)',
  cardGradient: 'linear-gradient(114deg, #56566c 0%, #5a3c48 43%, #7561b9 100%)',
}

export const brandColors = {
  binance: '#F0B90B',
}

export const lightColors: Colors = {
  ...baseColors,
  ...brandColors,
  background: '#FAF9FA',
  backgroundDisabled: '#6c6c6c30',
  backgroundSubtle: '#55667E',
  backgroundAlt: '#FFFFFF',
  text: '#000000',
  textTertiary: '#80ECFF',
  textDisabled: '#BDC2C4',
  textSubtle: '#858585',
  borderColor: '#E9EAEB',
  textOrange: '#FE8947',
  textHeading: '#00000059',
  textBlur: '#00000020',
  dark: '#141229',
  border: '#8E91A099',
  lightGray: '#262649',
  cardBgGradient:
    'radial-gradient(106.46% 161.43% at 1.69% 50%,rgba(165,156,249,0.2) 0%,rgba(21,0,233,0.032) 77.08%,rgba(21,0,233,0) 100%),#0d0d18;',
  hrColor: '#182b3c',
}

export const darkColors: Colors = {
  ...baseColors,
  ...brandColors,
  background: '#0D0D18',
  backgroundDisabled: '#6c6c6c30',
  backgroundAlt: '#00000059',
  backgroundSubtle: '#55667E',
  primaryDark: '#0098A1',
  text: '#FFFFFF',
  textDisabled: '#BDC2C4',
  textSubtle: '#F2F2F2',
  textTertiary: '#80ECFF',
  borderColor: '#E9EAEB',
  textOrange: '#FE8947',
  textHeading: '#D7D9EE',
  textBlur: '#9e9fad',
  dark: '#141229',
  border: '#8E91A099',
  hrColor: '#182b3c',
  cardBgGradient:
    'radial-gradient(106.46% 161.43% at 1.69% 50%,rgba(165,156,249,0.2) 0%,rgba(21,0,233,0.032) 77.08%,rgba(21,0,233,0) 100%),#0d0d18;',
  lightGray: '#262649',
}
