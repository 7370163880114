import { BIG_TEN } from '../utils/bigNumber'

export const DEFAULT_EVM_TOKEN_DECIMAL = BIG_TEN.pow(18)
export const DEFAULT_SOLANA_TOKEN_DECIMAL = BIG_TEN.pow(8)
export const SITE_KEY = '335aadfe-1122-4d05-9ea6-3f47d36f874d'

import { ConnectorNames } from './constants/types'
import { WalletReadyState } from '@solana/wallet-adapter-base'

export const EVM_WALLETS = [
  {
    connector: ConnectorNames.Injected,
    adapter: {
      name: 'Metamask',
      icon: './images/metamask.png',
    },
    readyState: WalletReadyState.Installed,
  },
  {
    connector: ConnectorNames.Injected,
    adapter: {
      name: 'Exodus',
      icon: './images/exodus.svg',
    },
    readyState: WalletReadyState.Installed,
  },
]
