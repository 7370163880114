import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <g id="repeat" transform="translate(1.061 18.75) rotate(-90)">
        <path
          id="Path_9"
          data-name="Path 9"
          d="M17,1l4,4L17,9"
          transform="translate(-3 -1)"
          fill="none"
          stroke="#9e9cc8"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="Path_10"
          data-name="Path 10"
          d="M3,11V9A4,4,0,0,1,7,5H21"
          transform="translate(-3 -1)"
          fill="none"
          stroke="#9e9cc8"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="Path_11"
          data-name="Path 11"
          d="M7,23,3,19l4-4"
          transform="translate(-3 -1)"
          fill="none"
          stroke="#9e9cc8"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="Path_12"
          data-name="Path 12"
          d="M21,13v2a4,4,0,0,1-4,4H3"
          transform="translate(-3 -1)"
          fill="none"
          stroke="#9e9cc8"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </g>
    </Svg>
  )
}

export default Icon
