import { Box, Flex } from 'components/Box'
import { Text } from 'components/Text'
import styled from 'styled-components'
import { FiSettings } from 'react-icons/fi'
import { IoIosArrowDown } from 'react-icons/io'
import Card from 'components/Card'

export const SwapWrapper = styled(Flex)`
  background: url('./images/background1.png'), url('./images/background2.png');
  background-repeat: no-repeat;
  background-position: top left, bottom right;
  height: 100%;
  .mb-icon-3 {
    margin-bottom: 4px;
  }
  ${({ theme }) => theme.mediaQueries.md} {
    background: none;
  }
`

export const ChainWrapper = styled(Box)<{ bgColor?: string }>`
  background: ${({ bgColor }) => (bgColor ? bgColor : '#55667e')};
  border-radius: 12px;
  cursor: pointer;
  padding: 0 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 44px;

  .icon {
    margin-left: 0.8rem;
  }
  ${({ theme }) => theme.mediaQueries.sm} {
    padding: 0 0.6rem;
    border-radius: 4px;
  }
`
export const FromTokenWrapper = styled(Box)`
  background: ${({ theme }) => theme.colors.backgroundAlt};
  border-radius: 12px;
  margin-top: 10px;
  padding: 0.7rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .line {
    background: ${({ theme }) => theme.colors.textSubtle}82;
    width: 1px;
    height: 24px;
    margin-left: 16px;
    margin-right: 11px;
    opacity: 0.4;
  }
  input {
    color: ${({ theme }) => theme.colors.textSubtle}b0;
    background: transparent;
    border: none;
    width: 100%;

    &:focus {
      outline: none;
    }
  }
  ${({ theme }) => theme.mediaQueries.sm} {
    padding: 0.5rem 0.6rem;
    border-radius: 4px;
  }
`
export const DestinationTokenWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.backgroundSubtle}36;
  border-radius: 12px;
  padding: 0.1rem 1rem 0rem 0.7rem;
  margin-top: 0.3rem;
  ${({ theme }) => theme.mediaQueries.sm} {
    padding: 0.6rem 0.8rem 0rem 0.6rem;
    border-radius: 4px;
  }
`
export const InfoRow = styled(Flex)`
  justify-content: space-between;
  width: 100%;
  margin: 0.3rem 0;

  .route-text {
    position: relative;
    font-family: ${({ theme }) => theme.fonts.secondary};
    font-weight: ${({ theme }) => theme.fonts.regular};
    margin: 0;
    font-size: 16px;
  }
`

export const SettingIcon = styled(FiSettings)`
  color: ${({ theme }) => theme.colors.text};
  margin-left: 6px;
  cursor: pointer;
  &:hover {
    opacity: 0.6;
    transform: scale(1.2);
  }
`

export const IoIosArrowDownIcon = styled(IoIosArrowDown)`
  color: ${({ theme }) => theme.colors.text};
  margin-left: auto;
  font-size: 18px;
  &:hover {
    opacity: 0.6;
    transform: scale(1.2);
  }
`

export const MaxText = styled(Text)`
  color: ${({ theme }) => theme.colors.textOrange};
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-weight: ${({ theme }) => theme.fonts.regular};
  cursor: pointer;
  font-size: 16px;
  word-break: keep-all;4
  margin-left: 5px;
  white-space: nowrap;
  &:hover {
    opacity: 0.8;
  }
`

export const HistoryWrapper = styled(Flex)`
  .pair-text {
    color: ${({ theme }) => theme.colors.text};
    font-family: ${({ theme }) => theme.fonts.primary};
    font-weight: ${({ theme }) => theme.fonts.semiBold};
    font-size: 14px;
    text-transform: uppercase;
  }
  .txn-processing {
    margin-left: 12px;
    color: ${({ theme }) => theme.colors.textOrange};
    font-family: ${({ theme }) => theme.fonts.primary};
    font-weight: ${({ theme }) => theme.fonts.semiBold};
    font-size: 12px;
  }
  .pair-amount {
    color: ${({ theme }) => theme.colors.text};
    font-family: ${({ theme }) => theme.fonts.primary};
    font-weight: ${({ theme }) => theme.fonts.semiBold};
    font-size: 18;
  }
  .estimate {
    color: ${({ theme }) => theme.colors.textBlur};
    font-family: ${({ theme }) => theme.fonts.secondary};
    font-weight: ${({ theme }) => theme.fonts.regular};
    font-size: 16;
    cursor: pointer;
  }
  .timer {
    color: ${({ theme }) => theme.colors.textOrange};
    font-family: ${({ theme }) => theme.fonts.secondary};
    font-weight: ${({ theme }) => theme.fonts.regular};
    font-size: 16;
  }
`

export const BorderBox = styled(Box)<{ isBorder?: boolean }>`
  border-top: ${({ theme, isBorder }) => (isBorder ? `1px solid  ${theme.colors.border}` : 'none')};
`
export const IconImage = styled.img<{ ml?: string }>`
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin-left: ${({ ml }) => ml || '0'};
`

export const ScrollCardWrapper = styled(Card)`
  height: 70vh;
  overflow: auto;
`

export const TimerText = styled(Text)`
  color: ${({ theme }) => theme.colors.textTertiary};
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-weight: ${({ theme }) => theme.fonts.regular};
  font-size: 16;
  white-space: nowrap;
  margin-right: 0.5rem;
`

export const RadioWrapper = styled(Box)`
  input {
    border: none;
    outline: none;
    background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iQ29tcG9uZW50XzQ4XzEiIGRhdGEtbmFtZT0iQ29tcG9uZW50IDQ4IOKAkyAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMCIgaGVpZ2h0PSIxMCIgdmlld0JveD0iMCAwIDEwIDEwIj4NCiAgPGcgaWQ9IkVsbGlwc2VfMjIiIGRhdGEtbmFtZT0iRWxsaXBzZSAyMiIgZmlsbD0iIzBkMGQxOCIgc3Ryb2tlPSIjZmY2YzIxIiBzdHJva2Utd2lkdGg9IjEiPg0KICAgIDxjaXJjbGUgY3g9IjUiIGN5PSI1IiByPSI1IiBzdHJva2U9Im5vbmUiLz4NCiAgICA8Y2lyY2xlIGN4PSI1IiBjeT0iNSIgcj0iNC41IiBmaWxsPSJub25lIi8+DQogIDwvZz4NCjwvc3ZnPg0K)
      no-repeat center;
    background-size: contain;
    width: 14px;
    height: 14px;
  }
  .form-check-input:checked[type='radio'] {
    background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iQ29tcG9uZW50XzQ3XzEiIGRhdGEtbmFtZT0iQ29tcG9uZW50IDQ3IOKAkyAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMCIgaGVpZ2h0PSIxMCIgdmlld0JveD0iMCAwIDEwIDEwIj4NCiAgPGcgaWQ9IkVsbGlwc2VfMjMiIGRhdGEtbmFtZT0iRWxsaXBzZSAyMyIgZmlsbD0iIzBkMGQxOCIgc3Ryb2tlPSIjZmY2YzIxIiBzdHJva2Utd2lkdGg9IjEiPg0KICAgIDxjaXJjbGUgY3g9IjUiIGN5PSI1IiByPSI1IiBzdHJva2U9Im5vbmUiLz4NCiAgICA8Y2lyY2xlIGN4PSI1IiBjeT0iNSIgcj0iNC41IiBmaWxsPSJub25lIi8+DQogIDwvZz4NCiAgPGNpcmNsZSBpZD0iRWxsaXBzZV8yNCIgZGF0YS1uYW1lPSJFbGxpcHNlIDI0IiBjeD0iMiIgY3k9IjIiIHI9IjIiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDMgMykiIGZpbGw9IiNmZjZjMjEiLz4NCjwvc3ZnPg0K)
      no-repeat center;
    background-size: contain;
  }
`

export const Chevrondown = styled(Box)`
  position: relative;
  width: 20px;
  height: 20px;
  display: flex;
  top: 0px;
  margin-right: 12px;
  transform: scale(0.7);
  left: 9px;
  &.showDropDown {
    transform: scale(0.7) rotate(180deg);
    top: -3px;
    left: 13px;
  }
  &:before,
  &:after {
    content: '';
    position: absolute;
    width: 15px;
    height: 3px;
    background-color: #fff;
    border-radius: 50px;
    overflow: hidden;
  }
  &:before {
    transform: rotate(45deg);
    top: 6px;
    left: 0;
  }
  &:after {
    transform: rotate(-45deg);
    left: 9px;
    top: 6px;
  }
  button {
    background: none !important;
    border: none !important;
  }
`

export const ListRow = styled(Flex)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  cursor: pointer;
  height: auto !important;
  position: static !important;
  justify-content: space-between;
  padding: 10px 5px;
  top: 0 !important;
  .list-row-selected {
    background: #55667e36;
    border-radius: 16px;
    padding: 10px 5px;
    border-bottom: none;
    margin-bottom: 5px;
    margin-top: 10px;
  }
`
export const ListView = styled(Flex)`
  flex-direction: column;
  max-height: 500px;
  overflow: auto;
`
