import BN from 'bn.js'
import { SupportedChainId } from 'config/constants/chains'
import { Currency, CurrencyAmount, Fraction } from 'config/entities'
import { SWAP_STATES } from 'state/swap/types'
import { AmountKey } from 'views/Pools/type'

export interface IStep {
  name: POOL_STATES
  title: string
  chainId: number
  payload: any
  result?: any
  dependOnStep?: POOL_STATES
  subTitle: string
  retries?: number
}

export enum ACTION_STEP_STATE {
  OK = 'OK',
  IN_PROGRESS = 'IN_PROGRESS',
  ERROR = 'ERROR',
  WAIT_FOR_LIBRARY = 'WAIT_FOR_LIBRARY',
  DEFAULT = 'DEFAULT',
}

export interface IActionStepState {
  status: ACTION_STEP_STATE
  message: string
  payload?: any
}

export interface IPoolStep {
  name: POOL_STATES
  title: string
  chainId: number
  payload: any
  result?: any
  dependOnStep?: POOL_STATES
  subTitle: string
  retries?: number
}

export enum POOL_STATES {
  DEFAULT = 'DEFAULT',
  APPROVE_CONTRACT = 'APPROVE_CONTRACT',
  CHANGE_NETWORK = 'CHANGE_NETWORK',
  ADD_LIQUIDITY = 'ADD_LIQUIDITY',
  REMOVE_ADD_LIQUIDITY = 'REMOVE_ADD_LIQUIDITY',
  BEFORE_ADDLIQUIDITY_WALLET_CONFIRMATIONS = 'BEFORE_ADDLIQUIDITY_WALLET_CONFIRMATIONS',
  POOL_IN_PROGRESS = 'POOL_IN_PROGRESS',
  POOL_COMPLETED = 'POOL_COMPLETED',
  ERROR = 'ERROR',
  ADDING_RAYDIUM_LIQUIDITY = 'ADDING_RAYDIUM_LIQUIDITY',
  REMOVE_RAYDIUM_LIQUIDITY = 'REMOVE_RAYDIUM_LIQUIDITY',
  FARM_DEPOSIT_EVM = 'FARM_DEPOSIT_EVM',
  FARM_WITHDRAW_EVM = 'FARM_WITHDRAW_EVM',
  FARM_DEPOSIT_RAYDIUM = 'FARM_DEPOSIT_RAYDIUM',
  FARM_WITHDRAW_RAYDIUM = 'FARM_WITHDRAW_RAYDIUM',
  FARM_HARVEST_RAYDIUM = 'FARM_HARVEST_RAYDIUM',
  FARM_HARVEST_EVM = 'FARM_HARVEST_EVM',
  CREATE_ASSOCIATE_ACCOUNT = 'CREATE_ASSOCIATE_ACCOUNT',
}

export interface Pool {
  updatedAt: Date
  createdAt: Date
  isActive: boolean
  isDeleted: boolean
  _id: string
  token0: Currency
  token1: Currency
  address: string
  chainId: number
  pairAddress: string
  routerAddress: string
  volume: string
  apr: string
  liquidity: string
  __v: number
}

export interface PoolsState {
  pools: Pool[]
  poolChainId: SupportedChainId | null
  steps: IPoolStep[]
  pageState: POOL_STATES
  activeStepIndex: number
  actionStepState: IActionStepState
  routerAddress: string
  pairAddress: string
  liquidity: IActionLiquidityState
  lpBalance: string
  lpDeposited: string
  farmAddress: string
  userShare: string
  userAssets: {
    baseCurrency: string
    quoteCurrency: string
  }
  totalLiquidity: number
  totalVolume: number
  pendingReward: string
  focusSide: string
  autoRefresh: boolean
}

export interface IPairReserveOutput {
  [AmountKey.INPUT]: CurrencyAmount
  [AmountKey.OUTPUT]: CurrencyAmount
}

export interface IActionLiquidityState {
  [AmountKey.INPUT]: string
  [AmountKey.OUTPUT]: string
}

export type Numberish = number | string | bigint | Fraction | BN

export interface Block {
  number: number
  timestamp: string
}

export const BLOCKS_CLIENT = 'https://api.thegraph.com/subgraphs/name/pancakeswap/blocks'
export const NEXT_PUBLIC_BIT_QUERY_ENDPOINT = 'https://graphql.bitquery.io'

export const BSC_SUBGRAPH = 'https://bsc.streamingfast.io/subgraphs/name/pancakeswap/exchange-v2'
export const UNI_SUBGRAPH = 'https://api.thegraph.com/subgraphs/name/uniswap/uniswap-v2'

export const TOTAL_FEE = 0.0025
export const LP_HOLDERS_FEE = 0.0017
export const WEEKS_IN_YEAR = 52.1429

export interface PoolFields {
  id: string
  reserve0: string
  reserve1: string
  reserveUSD: string
  volumeUSD: string
  token0Price: string
  token1Price: string
  token0: {
    id: string
    symbol: string
    name: string
  }
  token1: {
    id: string
    symbol: string
    name: string
  }
}

export interface PoolsQueryResponse {
  now: PoolFields[]
  oneDayAgo: PoolFields[]
  twoDaysAgo: PoolFields[]
  oneWeekAgo: PoolFields[]
  twoWeeksAgo: PoolFields[]
}

export interface FormattedPoolFields
  extends Omit<PoolFields, 'volumeUSD' | 'reserveUSD' | 'reserve0' | 'reserve1' | 'token0Price' | 'token1Price'> {
  volumeUSD: number
  reserveUSD: number
  reserve0: number
  reserve1: number
  token0Price: number
  token1Price: number
}
