import useTheme from 'hooks/useTheme'
import { Flex } from 'components/Box'
import { Text } from 'components/Text'
import { CHAIN_IDS_TO_NAMES, SupportedChainId } from 'config/constants/chains'
import { AnimatedArrows } from '../styles'
import { AvaxIcon, BNBIcon, EthIcon, FTMIcon, PolygonIcon, SolanaIcon } from 'components/Svg'
import useActiveWeb3React from 'hooks/useActiveWeb3React'

export const getIconByChainID = (chainId, large?) => {
  const size = large ? '50px' : '30px'
  switch (chainId) {
    case SupportedChainId.BINANCE:
      return <BNBIcon width={size} height={size} />
    case SupportedChainId.ETHEREUM:
      return <EthIcon width={size} height={size} />
    case SupportedChainId.AVAX:
      return <AvaxIcon width={size} height={size} />
    case SupportedChainId.POLYGON:
      return <PolygonIcon width={size} height={size} />
    case SupportedChainId.SOLANA:
      return <SolanaIcon width={size} height={size} />
    case SupportedChainId.FANTOM:
      return <FTMIcon width={size} height={size} />
  }
}

const SwitchNetworkLoader = ({ chainID }) => {
  const { theme } = useTheme()
  const { chainId } = useActiveWeb3React()

  return (
    <Flex width={'100%'} justifyContent={'space-between'}>
      <Flex width={'100%'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
        {getIconByChainID(chainId, true)}
        <Text
          fontSize={'small'}
          textTransform={'capitalize'}
          color={theme.colors.text}
          fontFamily={theme.fonts.primary}
          fontWeight={theme.fonts.regular}
        >
          {CHAIN_IDS_TO_NAMES[chainId]}
        </Text>
      </Flex>
      <Flex width={'100%'} alignItems={'center'} justifyContent={'center'}>
        <AnimatedArrows>
          <span className="arrow one next bounceAlpha" />
          <span className="arrow two next bounceAlpha" />
          <span className="arrow three next bounceAlpha" />
        </AnimatedArrows>
      </Flex>
      <Flex flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
        {getIconByChainID(chainID, true)}
        <Text
          fontSize={'small'}
          textTransform={'capitalize'}
          color={theme.colors.text}
          fontFamily={theme.fonts.primary}
          fontWeight={theme.fonts.regular}
        >
          {CHAIN_IDS_TO_NAMES[chainID]}
        </Text>
      </Flex>
    </Flex>
  )
}

export default SwitchNetworkLoader
