import axios from 'axios'
import { useCurrency, useInputAmount, useSwapInfo } from 'state/swap/selectors'
import { Field } from 'state/types'
import { useUserSlippageTolerance } from 'state/user/hooks'
import { useWalletActionHandlers } from 'state/wallets/hooks'
import { Fee } from 'config/enums'
import { useCallback } from 'react'
import packageJson from './../../package.json'

const useSwapSummary = () => {
  const inputCurrency = useCurrency(Field.INPUT),
    outputCurrency = useCurrency(Field.OUTPUT),
    { swapInfo } = useSwapInfo(),
    [slippage] = useUserSlippageTolerance(),
    { getConnectedWalletAddress } = useWalletActionHandlers(),
    fromAddress = getConnectedWalletAddress(Field.INPUT),
    toAddress = getConnectedWalletAddress(Field.OUTPUT),
    { inputAmount } = useInputAmount()
  return {
    fromAddress,
    toAddress,
    fromTokenAddress: inputCurrency.address,
    fromSymbol: inputCurrency.symbol,
    fromChain: `${inputCurrency.chainId}`,
    toSymbol: outputCurrency.symbol,
    toTokenAddress: outputCurrency.address,
    minimumReceived: Number(swapInfo.minimumReceived.toEther()),
    toChain: `${outputCurrency.chainId}`,
    slippage: slippage.toFixed(),
    fromAmount: Number(inputAmount.toEther()),
    fromDecimals: Number(inputAmount.decimals),
    feeUSD: Fee.DEFAULT,
    routeName: swapInfo.routeName,
    routeInfo: swapInfo.routeInfo,
  }
}

const useErrorSummary = () => {
  const inputCurrency = useCurrency(Field.INPUT),
    outputCurrency = useCurrency(Field.OUTPUT),
    { inputAmount } = useInputAmount(),
    { getConnectedWalletAddress } = useWalletActionHandlers(),
    fromAddress = getConnectedWalletAddress(Field.INPUT),
    toAddress = getConnectedWalletAddress(Field.OUTPUT)
  return {
    fromAddress,
    toAddress,
    fromTokenAddress: inputCurrency.address,
    fromSymbol: inputCurrency.symbol,
    fromChain: `${inputCurrency.chainId}`,
    toSymbol: outputCurrency.symbol,
    toTokenAddress: outputCurrency.address,
    toChain: `${outputCurrency.chainId}`,
    fromAmount: Number(inputAmount.toEther()),
  }
}

export const usePostLogs = () => {
  const data = useSwapSummary()

  const postLogs = useCallback((type: 'SUCCESS' | 'FAIL' | 'CANCELED', logs: string[]) => {
    axios.post(`api/logs`, {
      ...data,
      type: type,
      logs: logs,
    })
  }, [])

  return { postLogs }
}

export const usePostError = () => {
  const data = useErrorSummary()

  const postError = useCallback(
    (error: string, stackTrace: string, stepName: string, stepData: string) => {
      axios.post(`api/logs/swap/error`, {
        logData: JSON.stringify(data),
        version: packageJson.version,
        error,
        stackTrace,
        stepName,
        stepData,
      })
    },
    [data],
  )

  return { postError }
}
