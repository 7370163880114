import styled from 'styled-components'
import { layout } from 'styled-system'
import { CardProps } from './type'

export const CardWrapper = styled.div<CardProps>`
  background: ${({ borderColor }) =>
    borderColor ? borderColor : 'linear-gradient(114deg, #56566c 0%, #5a3c48 43%, #7561b9 100%)'};
  padding: 1px;
  border-radius: 12px;
  overflow: hidden;
  .inner-section {
    border-radius: 12px;
    background: ${({ bgColor }) =>
      bgColor
        ? bgColor
        : 'radial-gradient(106.46% 161.43% at 1.69% 50%, rgba(165, 156, 249, 0.2) 0%, rgba(21, 0, 233, 0.032) 77.08%,rgba(21, 0, 233, 0) 100%),#0d0d18'};
    height: 100%;
    width: 100%;
    padding: ${({ padding }) => (padding ? padding : '27px 22.3px')};
  }
  ${layout}
  ${({ theme }) => theme.mediaQueries.sm} {
    .inner-section {
      padding: 0.6rem;
    }
  }
`
