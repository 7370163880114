import { Flex } from 'components/Box'
import styled from 'styled-components'

export const FooterWrapper = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  padding: 0rem 1rem;
  width: 56%;
  ${({ theme }) => theme.mediaQueries.xl} {
    flex-direction: column;
  }

  ${({ theme }) => theme.mediaQueries.md} {
    align-items: flex-start;
    .link-container {
      flex-direction: row;
      margin-top: 1rem;
    }
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    align-items: flex-start;
    width: 100%;
    .links {
      flex-direction: column;
      background: red;
    }
  }
`

export const Link = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.text};
  margin: 16px 20px;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-weight: ${({ theme }) => theme.fonts.medium};
  font-size: small;
  ${({ theme }) => theme.mediaQueries.md} {
    margin: 8px 10px;
    font-size: 10px;
  }
  opacity: 0.6;

  &:hover {
    color: ${({ theme }) => theme.colors.text};
    opacity: 1;
  }
`

export const IconContainer = styled(Flex)`
  .icon {
    align-items: center;
    justify-content: center;
    margin: auto 20px auto 0;
    opacity: 0.8;
    &:hover {
      opacity: 1;
      cursor: pointer;
    }
  }
  .icon .alt-text {
    color: ${({ theme }) => theme.colors.textSubtle};
    font-family: ${({ theme }) => theme.fonts.secondary};
    font-weight: ${({ theme }) => theme.fonts.light};
    margin: 0;
    margin-left: 4px;
  }
`
