import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 26 26" {...props}>
      <defs>
        <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
          <stop offset="0" stopColor="#cc2dff" />
          <stop offset="0.502" stopColor="#1cccfe" />
          <stop offset="1" stopColor="#03fca7" />
        </linearGradient>
      </defs>
      <g id="Group_1694" data-name="Group 1694" transform="translate(-0.476)">
        <g
          id="Ellipse_23"
          data-name="Ellipse 23"
          transform="translate(0.476)"
          fill="#09182d"
          stroke="#7d5ade"
          strokeWidth="2"
        >
          <circle cx="13" cy="13" r="13" stroke="none" />
          <circle cx="13" cy="13" r="12" fill="none" />
        </g>
        <g id="Group_1693" data-name="Group 1693" transform="translate(7.383 2.34)">
          <path
            id="git-network"
            d="M75.233,33.832a1.872,1.872,0,1,0-2.818,1.615,2.04,2.04,0,0,1-.845,1.5,3.352,3.352,0,0,1-3.91,0,2.04,2.04,0,0,1-.845-1.5,1.872,1.872,0,1,0-1.876.008A3.932,3.932,0,0,0,66.53,38.44a5.088,5.088,0,0,0,2.15.924v2.209a1.872,1.872,0,1,0,1.872,0V39.364a5.088,5.088,0,0,0,2.15-.924,3.932,3.932,0,0,0,1.589-2.984A1.872,1.872,0,0,0,75.233,33.832ZM65.871,32.9a.936.936,0,1,1-.936.936A.936.936,0,0,1,65.871,32.9ZM69.616,44.13a.936.936,0,1,1,.936-.936A.936.936,0,0,1,69.616,44.13Zm3.745-9.362a.936.936,0,1,1,.936-.936A.936.936,0,0,1,73.361,34.768Z"
            transform="translate(-63.999 -25.419)"
            fill="url(#linear-gradient)"
          />
          <path
            id="flash"
            d="M81.782,23.411a.285.285,0,0,1-.281-.334v0l.436-2.394H80.25a.248.248,0,0,1-.193-.4l3.4-4.2a.286.286,0,0,1,.509.213.084.084,0,0,1,0,.014L83.525,18.7h1.688a.248.248,0,0,1,.193.4l-3.4,4.2A.286.286,0,0,1,81.782,23.411Z"
            transform="translate(-76.66 -15.966)"
            fill="#7d5ade"
          />
        </g>
      </g>
    </Svg>
  )
}

export default Icon
