import {
  CHAIN_ID_AVAX,
  CHAIN_ID_BSC,
  CHAIN_ID_ETH,
  CHAIN_ID_POLYGON,
  CHAIN_ID_SOLANA,
  CHAIN_ID_TERRA,
  CHAIN_ID_FANTOM,
  ChainId,
} from '@certusone/wormhole-sdk'

/**
 * List of all the networks supported by the Uniswap Interface
 */
export enum SupportedChainId {
  SOLANA = 101, // for Non EVM we are putting these Chain IDs to be in negative.
  TERRA = 202, // for Non EVM we are putting these Chain IDs to be in negative.
  ETHEREUM = 1,
  POLYGON = 137,
  BINANCE = 56,
  AVAX = 43114,
  FANTOM = 250,
  TRON = 195, // for trc20 we are putting this Chain ID.
}

export const CHAIN_IDS_TO_NAMES = {
  [SupportedChainId.ETHEREUM]: 'ethereum',
  [SupportedChainId.SOLANA]: 'solana',
  [SupportedChainId.TERRA]: 'terra',
  [SupportedChainId.POLYGON]: 'polygon',
  [SupportedChainId.BINANCE]: 'binance',
  [SupportedChainId.FANTOM]: 'fantom',
  [SupportedChainId.AVAX]: 'avax',
  [SupportedChainId.TRON]: 'tron',
}

export const CHAIN_IDS_TO_SYMBOL = {
  [SupportedChainId.ETHEREUM]: 'ETH',
  [SupportedChainId.SOLANA]: 'SOL',
  [SupportedChainId.TERRA]: 'TERRA',
  [SupportedChainId.POLYGON]: 'POLYGON',
  [SupportedChainId.BINANCE]: 'BSC',
  [SupportedChainId.FANTOM]: 'FANTOM',
  [SupportedChainId.AVAX]: 'AVAX',
  [SupportedChainId.TRON]: 'TRON',
}

/**
 * Array of all the supported chain IDs
 */
export const ALL_SUPPORTED_CHAIN_IDS: SupportedChainId[] = Object.values(SupportedChainId).filter(
  (id) => typeof id === 'number',
) as SupportedChainId[]

export const SUPPORTED_GAS_ESTIMATE_CHAIN_IDS = [
  SupportedChainId.ETHEREUM,
  SupportedChainId.POLYGON,
  SupportedChainId.BINANCE,
]

/**
 * All the chain IDs that are running the EVM protocol.
 */
export const EVM_CHAIN_IDS = [
  SupportedChainId.ETHEREUM,
  SupportedChainId.POLYGON,
  SupportedChainId.BINANCE,
  SupportedChainId.AVAX,
  SupportedChainId.FANTOM,
] as const

export const SupportedEVMChainId = (chainId) => {
  return EVM_CHAIN_IDS.findIndex((evmChain) => evmChain === chainId) > -1
}

export interface INetworkInfo {
  wormholeChainId: ChainId
  blockConfirmations: number
  exploreLink: string
  explorerName: string
}

export type WormholeChainInfoType = {
  [key in SupportedChainId]: INetworkInfo
}
export const NETWORKS_INFO: WormholeChainInfoType = {
  [SupportedChainId.POLYGON]: {
    wormholeChainId: CHAIN_ID_POLYGON,
    blockConfirmations: 920,
    exploreLink: 'https://polygonscan.com/',
    explorerName: 'Polygon Scan',
  },
  [SupportedChainId.ETHEREUM]: {
    wormholeChainId: CHAIN_ID_ETH,
    blockConfirmations: 90,
    exploreLink: 'https://etherscan.io/',
    explorerName: 'EtherScan',
  },
  [SupportedChainId.BINANCE]: {
    wormholeChainId: CHAIN_ID_BSC,
    blockConfirmations: 15,
    exploreLink: 'https://bscscan.com/',
    explorerName: 'BSC Scan',
  },
  [SupportedChainId.AVAX]: {
    wormholeChainId: CHAIN_ID_AVAX,
    blockConfirmations: 15,
    exploreLink: 'https://snowtrace.io/',
    explorerName: 'SnowTrace',
  },
  [SupportedChainId.SOLANA]: {
    wormholeChainId: CHAIN_ID_SOLANA,
    blockConfirmations: 32,
    exploreLink: 'https://solscan.io/',
    explorerName: 'Solscan',
  },
  [SupportedChainId.FANTOM]: {
    wormholeChainId: CHAIN_ID_FANTOM,
    blockConfirmations: 1,
    exploreLink: 'https://ftmscan.com/',
    explorerName: 'FTMScan',
  },
  [SupportedChainId.TERRA]: {
    wormholeChainId: CHAIN_ID_TERRA,
    blockConfirmations: 1,
    exploreLink: '',
    explorerName: '',
  },
  [SupportedChainId.TRON]: {
    wormholeChainId: null,
    blockConfirmations: 150,
    exploreLink: 'https://tronscan.org/#/',
    explorerName: 'TRONSCAN',
  },
}

export const STABLE_CHAINS_SET = new Set([
  SupportedChainId.ETHEREUM,
  SupportedChainId.BINANCE,
  SupportedChainId.SOLANA,
  SupportedChainId.TRON,
  SupportedChainId.POLYGON,
])

export const CHAIN_KEYS = {
  [SupportedChainId.ETHEREUM]: 'ETH',
  [SupportedChainId.SOLANA]: 'SOL',
  [SupportedChainId.BINANCE]: 'BSC',
  [SupportedChainId.TRON]: 'TRX',
  [SupportedChainId.POLYGON]: 'POL',
}

export const CHAIN_KEYS_TO_SUPPORTED_CHAIN_ID = {
  ETH: SupportedChainId.ETHEREUM,
  SOL: SupportedChainId.SOLANA,
  BSC: SupportedChainId.BINANCE,
  TRX: SupportedChainId.TRON,
  POL: SupportedChainId.POLYGON,
}

export const ALLBRIDGE_CHAINS_KEYS = {
  [SupportedChainId.ETHEREUM]: 1,
  [SupportedChainId.SOLANA]: 4,
  [SupportedChainId.BINANCE]: 2,
  [SupportedChainId.TRON]: 3,
  [SupportedChainId.POLYGON]: 5,
}
