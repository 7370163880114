import { ChainFlex, ChainIdFlex, TickIcon, WalletInfoSection } from '../../styles'
import { Box } from 'components/Box'
import { Text } from 'components/Text'
import BlockchainNetworks from 'config/constants/network'
import useTheme from 'hooks/useTheme'
import { useChainInfo } from 'state/swap/selectors'
import { SupportedChainId } from 'config/constants/chains'

const ChainBox = ({ field, walletLocalState, setWalletLocalState }) => {
  const { theme } = useTheme()
  const chainID = useChainInfo(field)

  return (
    <WalletInfoSection padding={'0.1rem 0'} flexDirection={'row'} justifyContent={'start'}>
      <Box className="row" width={'100%'}>
        {BlockchainNetworks.map((item, index) => (
          <Box
            key={index}
            onClick={() => {
              if (item.chainId === SupportedChainId.TRON) {
                return
              }
              setWalletLocalState({
                ...walletLocalState,
                chainId: item.chainId,
              })
            }}
            className="pointer col-2 px-0"
            mt="8px"
            height={'fit-content'}
            mr={'8px'}
          >
            <ChainFlex isSelected={chainID === item.chainId && true}>
              {walletLocalState.chainId === item.chainId && <TickIcon />}
              <ChainIdFlex
                isDisable={item.chainId === SupportedChainId.TRON}
                className="inner"
                flexDirection={'column'}
                alignItems={'center'}
              >
                <Box className="svg-icon">{item.icon}</Box>
                <Text
                  fontFamily={theme.fonts.primary}
                  fontSize={'11px'}
                  fontWeight={theme.fonts.regular}
                  color={theme.colors.text}
                >
                  {item.name}
                </Text>
              </ChainIdFlex>
            </ChainFlex>
          </Box>
        ))}
      </Box>
    </WalletInfoSection>
  )
}

export default ChainBox
