import { useCallback } from 'react'
import * as Sentry from '@sentry/react'
import { multicallv2 } from 'utils/multicall'
import erc20Abi from 'config/abi/erc20.json'
import { CurrencyAmount } from 'config/entities'
import { getEVMNodeURL } from 'utils/getRpcUrl'
import { ethers } from 'ethers'
import { useInputAmount } from 'state/swap/selectors'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useConnection, useWallet } from '@solana/wallet-adapter-react'
import { SupportedChainId, SupportedEVMChainId } from 'config/constants/chains'
import { TOKEN_PROGRAM_ID } from '@solana/spl-token'
import { MinimumBalanceResponse } from 'config/constants/types'
import { getBlockChainNetwork, isNativeCurrency } from 'utils'
import { useInputAmount as useStableSwapInput } from 'state/stableSwap/selectors'
import { useSwapSelectedTab } from 'state/global/selectors'
import { SWAP_TAB } from 'state/global/types'

const useMinimumBalance = () => {
  const { account } = useActiveWeb3React()
  const { publicKey } = useWallet()
  const { connection } = useConnection()
  const { inputAmount: inputAmountInDefault } = useInputAmount()
  const inputAmountInStable = useStableSwapInput()
  const selectedTab = useSwapSelectedTab()

  const inputAmount = selectedTab === SWAP_TAB.STABLE ? inputAmountInStable : inputAmountInDefault
  async function handleMultiCalls(network, currency, provider) {
    const balanceCalls = [
      {
        address: currency.address,
        name: 'balanceOf',
        params: [account],
      },
    ]
    try {
      const balanceResults = await multicallv2(erc20Abi, balanceCalls, network.chainId, provider)
      return new CurrencyAmount(balanceResults[0], network.decimals)
    } catch (error) {
      Sentry.captureException(error)
      return new CurrencyAmount(0, network.decimals)
    }
  }

  const fetchEVMBalance = async (network, currency, inputFee): Promise<MinimumBalanceResponse> => {
    let hasReqBalance
    if (isNativeCurrency(currency)) {
      const totalRequiredBalance = network.minimumFee.toWei().plus(inputAmount.toWei())
      hasReqBalance = inputFee.balance.toWei().isGreaterThanOrEqualTo(totalRequiredBalance)
    } else {
      const providerURL = getEVMNodeURL[network.chainId]
      const provider = new ethers.providers.JsonRpcProvider(providerURL)
      const tokenBalance = await handleMultiCalls(network, currency, provider)
      hasReqBalance = tokenBalance.toWei().isGreaterThanOrEqualTo(inputAmount.toWei())
    }
    return {
      hasRequiredBalance: hasReqBalance,
      network: network,
      message: `Insufficient Balance`,
    }
  }
  const getSolCurrencyBalance = async (network, currency) => {
    const parsedTokenAccounts = await connection.getParsedTokenAccountsByOwner(publicKey, {
      programId: TOKEN_PROGRAM_ID,
    })
    const accountInfo = parsedTokenAccounts.value.find((account) => {
      return account.account.data.parsed.info.mint === currency.address
    })
    const tokenAmount = accountInfo
      ? accountInfo.account.data.parsed.info.tokenAmount
      : {
          amount: 0,
          decimals: currency.decimals,
        }
    return new CurrencyAmount(tokenAmount.amount, tokenAmount.decimals)
  }

  const fetchSolBalance = async (network, currency, inputFee): Promise<MinimumBalanceResponse> => {
    let hasReqBalance
    if (isNativeCurrency(currency)) {
      const totalRequiredBalance = network.minimumFee.toWei().plus(inputAmount.toWei())
      hasReqBalance = inputFee.balance.toWei().isGreaterThanOrEqualTo(totalRequiredBalance)
    } else {
      const nativeCurrencyBalance = await getSolCurrencyBalance(network, currency)
      hasReqBalance = nativeCurrencyBalance.toWei().isGreaterThanOrEqualTo(inputAmount.toWei())
    }

    return {
      hasRequiredBalance: hasReqBalance,
      network: network,
      message: `Insufficient Balance`,
    }
  }

  const fetchTronBalance = async (network, currency, inputFee): Promise<MinimumBalanceResponse> => {
    let hasReqBalance
    if (isNativeCurrency(currency)) {
      const totalRequiredBalance = network.minimumFee.toWei().plus(inputAmount.toWei())
      hasReqBalance = inputFee.balance.toWei().isGreaterThanOrEqualTo(totalRequiredBalance)
    } else {
      if (!window.tronWeb) {
        hasReqBalance = false
      } else {
        const tronWeb = window.tronWeb
        const balance = await tronWeb.trx.getBalance(tronWeb.defaultAddress.base58)
        const tronBalance = new CurrencyAmount(balance, currency.decimals)
        hasReqBalance = tronBalance.toWei().isGreaterThanOrEqualTo(inputAmount.toWei())
      }
    }

    return {
      hasRequiredBalance: hasReqBalance,
      network: network,
      message: `Insufficient Balance`,
    }
  }

  const handleMinReqBalance = useCallback(async (chainId, currency, inputFee): Promise<MinimumBalanceResponse> => {
    const network = getBlockChainNetwork(chainId)
    try {
      if (SupportedEVMChainId(chainId)) {
        return await fetchEVMBalance(network, currency, inputFee)
      } else if (chainId === SupportedChainId.TRON) {
        return await fetchTronBalance(network, currency, inputFee)
      } else {
        return await fetchSolBalance(network, currency, inputFee)
      }
    } catch (error) {
      Sentry.captureException(error)
      return {
        hasRequiredBalance: false,
        network: network,
        message: `Insufficient Balance`,
      }
    }
  }, [])
  return { handleMinReqBalance }
}

export default useMinimumBalance
