import { SupportedChainId } from 'config/constants/chains'
import { WormholeTokenShortPath } from './wormholeShortPart'

export const WormholeToken = new Map()

export const getWormholeTokensForBridge = (inputCurrency, outputCurrency) => {
  const shortPathInDestinationWormhole = WormholeTokenShortPath.get(
    `${inputCurrency.chainId}-${outputCurrency.chainId}-${outputCurrency.address.toLowerCase()}`,
  )
  if (shortPathInDestinationWormhole) {
    return shortPathInDestinationWormhole
  }

  const shortPathInSourceWormhole = WormholeTokenShortPath.get(
    `${inputCurrency.address.toLowerCase()}-${inputCurrency.chainId}-${outputCurrency.chainId}`,
  )
  if (shortPathInSourceWormhole) {
    return shortPathInSourceWormhole
  }
  return WormholeToken.get(`${inputCurrency.chainId}-${outputCurrency.chainId}`)
}

WormholeToken.set(`${SupportedChainId.ETHEREUM}-${SupportedChainId.BINANCE}`, {
  currencyToLock: {
    symbol: 'USDC',
    name: 'USD Coin',
    address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    decimals: 6,
    logoURI: 'https://tokens.1inch.io/0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  currencyToMint: {
    address: '0xB04906e95AB5D797aDA81508115611fee694c2b3',
    chainId: SupportedChainId.BINANCE,
    decimals: 6,
    logoURI: 'https://bscscan.com/token/images/wormholeusdceth_32.png',
    name: 'USD Coin (Wormhole) (USDC)',
    symbol: 'USDCbs',
  },
})

WormholeToken.set(`${SupportedChainId.BINANCE}-${SupportedChainId.ETHEREUM}`, {
  currencyToLock: {
    address: '0xB04906e95AB5D797aDA81508115611fee694c2b3',
    chainId: SupportedChainId.BINANCE,
    decimals: 6,
    logoURI: 'https://bscscan.com/token/images/wormholeusdceth_32.png',
    name: 'USD Coin (Wormhole) (USDC)',
    symbol: 'USDCbs',
  },
  currencyToMint: {
    symbol: 'USDC',
    name: 'USD Coin',
    address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    decimals: 6,
    logoURI: 'https://tokens.1inch.io/0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48.png',
    chainId: SupportedChainId.ETHEREUM,
  },
})

WormholeToken.set(`${SupportedChainId.ETHEREUM}-${SupportedChainId.POLYGON}`, {
  currencyToLock: {
    address: '0x566957eF80F9fd5526CD2BEF8BE67035C0b81130',
    chainId: SupportedChainId.ETHEREUM,
    decimals: 6,
    logoURI: 'https://raw.githubusercontent.com/certusone/wormhole-token-list/main/assets/USDCpo_wh.png',
    name: 'USD Coin (PoS) (Wormhole from Polygon)',
    symbol: 'USDCpo',
  },
  currencyToMint: {
    address: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174',
    chainId: SupportedChainId.POLYGON,
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48/logo.png',
    name: 'USD-Coin',
    symbol: 'USDC',
  },
})

WormholeToken.set(`${SupportedChainId.POLYGON}-${SupportedChainId.ETHEREUM}`, {
  currencyToLock: {
    address: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174',
    chainId: SupportedChainId.POLYGON,
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48/logo.png',
    name: 'USD-Coin',
    symbol: 'USDC',
  },
  currencyToMint: {
    address: '0x566957eF80F9fd5526CD2BEF8BE67035C0b81130',
    chainId: SupportedChainId.ETHEREUM,
    decimals: 6,
    logoURI: 'https://raw.githubusercontent.com/certusone/wormhole-token-list/main/assets/USDCpo_wh.png',
    name: 'USD Coin (PoS) (Wormhole from Polygon)',
    symbol: 'USDCpo',
  },
})
WormholeToken.set(`${SupportedChainId.ETHEREUM}-${SupportedChainId.AVAX}`, {
  currencyToLock: {
    address: '0x85f138bfee4ef8e540890cfb48f620571d67eda3',
    chainId: SupportedChainId.ETHEREUM,
    decimals: 18,
    logoURI: 'https://static.icodrops.com/images/avalanche-wormhole.png',
    name: 'Wrapped AVAX(Wormhole)',
    symbol: 'WAVAX',
  },
  currencyToMint: {
    address: '0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7',
    chainId: SupportedChainId.AVAX,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9462.png',
    name: 'Wrapped AVAX',
    symbol: 'WAVAX',
  },
})
WormholeToken.set(`${SupportedChainId.AVAX}-${SupportedChainId.ETHEREUM}`, {
  currencyToLock: {
    address: '0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7',
    chainId: SupportedChainId.AVAX,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9462.png',
    name: 'Wrapped AVAX',
    symbol: 'WAVAX',
  },
  currencyToMint: {
    address: '0x85f138bfee4ef8e540890cfb48f620571d67eda3',
    chainId: SupportedChainId.ETHEREUM,
    decimals: 18,
    logoURI: 'https://static.icodrops.com/images/avalanche-wormhole.png',
    name: 'Wrapped AVAX(Wormhole)',
    symbol: 'WAVAX',
  },
})

WormholeToken.set(`${SupportedChainId.BINANCE}-${SupportedChainId.POLYGON}`, {
  currencyToLock: {
    address: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    chainId: SupportedChainId.BINANCE,
    decimals: 18,
    logoURI: 'https://raw.githubusercontent.com/certusone/wormhole-token-list/main/assets/BUSDbs_wh.png',
    name: 'Binance-USD',
    symbol: 'BUSD',
  },
  currencyToMint: {
    address: '0xa8d394fe7380b8ce6145d5f85e6ac22d4e91acde',
    chainId: SupportedChainId.POLYGON,
    decimals: 18,
    logoURI: 'https://raw.githubusercontent.com/certusone/wormhole-token-list/main/assets/BUSDbs_wh.png',
    name: 'Binance USD (Wormhole from BSC)',
    symbol: 'BUSDbs',
  },
})

WormholeToken.set(`${SupportedChainId.POLYGON}-${SupportedChainId.BINANCE}`, {
  currencyToLock: {
    address: '0xa8d394fe7380b8ce6145d5f85e6ac22d4e91acde',
    chainId: SupportedChainId.POLYGON,
    decimals: 18,
    logoURI: 'https://raw.githubusercontent.com/certusone/wormhole-token-list/main/assets/BUSDbs_wh.png',
    name: 'Binance USD (Wormhole from BSC)',
    symbol: 'BUSDbs',
  },
  currencyToMint: {
    address: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    chainId: SupportedChainId.BINANCE,
    decimals: 18,
    logoURI: 'https://raw.githubusercontent.com/certusone/wormhole-token-list/main/assets/BUSDbs_wh.png',
    name: 'Binance-USD',
    symbol: 'BUSD',
  },
})

WormholeToken.set(`${SupportedChainId.BINANCE}-${SupportedChainId.AVAX}`, {
  currencyToLock: {
    address: '0x96412902aa9aff61e13f085e70d3152c6ef2a817',
    chainId: SupportedChainId.BINANCE,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9462.png',
    name: 'Wrapped AVAX',
    symbol: 'WAVAX',
  },
  currencyToMint: {
    address: '0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7',
    chainId: SupportedChainId.AVAX,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9462.png',
    name: 'Wrapped AVAX',
    symbol: 'WAVAX',
  },
})

WormholeToken.set(`${SupportedChainId.AVAX}-${SupportedChainId.BINANCE}`, {
  currencyToLock: {
    address: '0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7',
    chainId: SupportedChainId.AVAX,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9462.png',
    name: 'Wrapped AVAX',
    symbol: 'WAVAX',
  },
  currencyToMint: {
    address: '0x96412902aa9aff61e13f085e70d3152c6ef2a817',
    chainId: SupportedChainId.BINANCE,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9462.png',
    name: 'Wrapped AVAX',
    symbol: 'WAVAX',
  },
})

WormholeToken.set(`${SupportedChainId.POLYGON}-${SupportedChainId.AVAX}`, {
  currencyToLock: {
    address: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    chainId: SupportedChainId.POLYGON,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3890.png',
    name: 'Wrapped Matic',
    symbol: 'WMATIC',
  },
  currencyToMint: {
    address: '0xf2f13f0b7008ab2fa4a2418f4ccc3684e49d20eb',
    chainId: SupportedChainId.AVAX,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3890.png',
    name: 'Wrapped Matic (Wormhole)',
    symbol: 'WMATIC',
  },
})

WormholeToken.set(`${SupportedChainId.AVAX}-${SupportedChainId.POLYGON}`, {
  currencyToLock: {
    address: '0xf2f13f0b7008ab2fa4a2418f4ccc3684e49d20eb',
    chainId: SupportedChainId.AVAX,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3890.png',
    name: 'Wrapped Matic (Wormhole)',
    symbol: 'WMATIC',
  },
  currencyToMint: {
    address: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    chainId: SupportedChainId.POLYGON,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3890.png',
    name: 'Wrapped Matic',
    symbol: 'WMATIC',
  },
})

WormholeToken.set(`${SupportedChainId.SOLANA}-${SupportedChainId.ETHEREUM}`, {
  currencyToLock: {
    address: 'A9mUU4qviSctJVPJdBJWkb28deg915LYJKrzQ19ji3FM',
    chainId: SupportedChainId.SOLANA,
    decimals: 6,
    logoURI: 'https://raw.githubusercontent.com/certusone/wormhole-token-list/main/assets/USDCso_wh.png',
    name: 'USD Coin (Wormhole from Ethereum)',
    symbol: 'USDC',
  },
  currencyToMint: {
    address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    chainId: SupportedChainId.ETHEREUM,
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48/logo.png',
    name: 'USD Coin',
    symbol: 'USDC',
  },
})

WormholeToken.set(`${SupportedChainId.ETHEREUM}-${SupportedChainId.SOLANA}`, {
  currencyToLock: {
    address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    chainId: SupportedChainId.ETHEREUM,
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48/logo.png',
    name: 'USD Coin',
    symbol: 'USDC',
  },
  currencyToMint: {
    address: 'A9mUU4qviSctJVPJdBJWkb28deg915LYJKrzQ19ji3FM',
    chainId: SupportedChainId.SOLANA,
    decimals: 6,
    logoURI: 'https://raw.githubusercontent.com/certusone/wormhole-token-list/main/assets/USDCso_wh.png',
    name: 'USD Coin (Wormhole from Ethereum)',
    symbol: 'USDC',
  },
})

WormholeToken.set(`${SupportedChainId.SOLANA}-${SupportedChainId.BINANCE}`, {
  currencyToLock: {
    address: 'FCqfQSujuPxy6V42UvafBhsysWtEq1vhjfMN1PUbgaxA',
    chainId: SupportedChainId.SOLANA,
    decimals: 8,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FCqfQSujuPxy6V42UvafBhsysWtEq1vhjfMN1PUbgaxA/logo.png',
    name: 'USD Coin (Wormhole from BSC)',
    symbol: 'USDCbs',
  },
  currencyToMint: {
    address: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
    chainId: SupportedChainId.BINANCE,
    decimals: 18,
    logoURI: 'https://bscscan.com/token/images/centre-usdc_28.png',
    name: 'Binance-Peg USD Coin',
    symbol: 'USDC',
  },
})

WormholeToken.set(`${SupportedChainId.BINANCE}-${SupportedChainId.SOLANA}`, {
  currencyToLock: {
    address: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
    chainId: SupportedChainId.BINANCE,
    decimals: 18,
    logoURI: 'https://bscscan.com/token/images/centre-usdc_28.png',
    name: 'Binance-Peg USD Coin',
    symbol: 'USDC',
  },
  currencyToMint: {
    address: 'FCqfQSujuPxy6V42UvafBhsysWtEq1vhjfMN1PUbgaxA',
    chainId: SupportedChainId.SOLANA,
    decimals: 8,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FCqfQSujuPxy6V42UvafBhsysWtEq1vhjfMN1PUbgaxA/logo.png',
    name: 'USD Coin (Wormhole from BSC)',
    symbol: 'USDCbs',
  },
})

WormholeToken.set(`${SupportedChainId.SOLANA}-${SupportedChainId.POLYGON}`, {
  currencyToLock: {
    address: 'E2VmbootbVCBkMNNxKQgCLMS1X3NoGMaYAsufaAsf7M',
    chainId: SupportedChainId.SOLANA,
    decimals: 6,
    logoURI: 'https://raw.githubusercontent.com/certusone/wormhole-token-list/main/assets/USDCso_wh.png',
    name: 'USD Coin',
    symbol: 'USDC',
  },
  currencyToMint: {
    address: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174',
    chainId: SupportedChainId.POLYGON,
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48/logo.png',
    name: 'USD Coin',
    symbol: 'USDC',
  },
})

WormholeToken.set(`${SupportedChainId.POLYGON}-${SupportedChainId.SOLANA}`, {
  currencyToLock: {
    address: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174',
    chainId: SupportedChainId.POLYGON,
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48/logo.png',
    name: 'USD Coin',
    symbol: 'USDC',
  },
  currencyToMint: {
    address: 'E2VmbootbVCBkMNNxKQgCLMS1X3NoGMaYAsufaAsf7M',
    chainId: SupportedChainId.SOLANA,
    decimals: 6,
    logoURI: 'https://raw.githubusercontent.com/certusone/wormhole-token-list/main/assets/USDCso_wh.png',
    name: 'USD Coin',
    symbol: 'USDC',
  },
})

WormholeToken.set(`${SupportedChainId.SOLANA}-${SupportedChainId.AVAX}`, {
  currencyToLock: {
    address: 'KgV1GvrHQmRBY8sHQQeUKwTm2r2h8t4C8qt12Cw1HVE',
    chainId: SupportedChainId.SOLANA,
    decimals: 8,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/KgV1GvrHQmRBY8sHQQeUKwTm2r2h8t4C8qt12Cw1HVE/logo.png',
    name: 'AVAX (Wormhole)',
    symbol: 'AVAXwo',
  },
  currencyToMint: {
    address: '0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7',
    chainId: SupportedChainId.AVAX,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9462.png',
    name: 'Wrapped AVAX',
    symbol: 'WAVAX',
  },
})

WormholeToken.set(`${SupportedChainId.AVAX}-${SupportedChainId.SOLANA}`, {
  currencyToLock: {
    address: '0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7',
    chainId: SupportedChainId.AVAX,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9462.png',
    name: 'Wrapped AVAX',
    symbol: 'WAVAX',
  },
  currencyToMint: {
    address: 'KgV1GvrHQmRBY8sHQQeUKwTm2r2h8t4C8qt12Cw1HVE',
    chainId: SupportedChainId.SOLANA,
    decimals: 8,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/KgV1GvrHQmRBY8sHQQeUKwTm2r2h8t4C8qt12Cw1HVE/logo.png',
    name: 'AVAX (Wormhole)',
    symbol: 'AVAXwo',
  },
})

WormholeToken.set(`${SupportedChainId.FANTOM}-${SupportedChainId.SOLANA}`, {
  currencyToLock: {
    address: '0x846e4D51d7E2043C1a87E0Ab7490B93FB940357b',
    chainId: SupportedChainId.FANTOM,
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9vMJfxuKxXBoEa7rM12mYLMwTacLMLDJqHozw96WQL8i/logo.png',
    name: 'UST (Wormhole)',
    symbol: 'UST',
  },
  currencyToMint: {
    address: '9vMJfxuKxXBoEa7rM12mYLMwTacLMLDJqHozw96WQL8i',
    chainId: SupportedChainId.SOLANA,
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9vMJfxuKxXBoEa7rM12mYLMwTacLMLDJqHozw96WQL8i/logo.png',
    name: 'UST (Wormhole)',
    symbol: 'UST',
  },
})

WormholeToken.set(`${SupportedChainId.SOLANA}-${SupportedChainId.FANTOM}`, {
  currencyToLock: {
    address: '9vMJfxuKxXBoEa7rM12mYLMwTacLMLDJqHozw96WQL8i',
    chainId: SupportedChainId.SOLANA,
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9vMJfxuKxXBoEa7rM12mYLMwTacLMLDJqHozw96WQL8i/logo.png',
    name: 'UST (Wormhole)',
    symbol: 'UST',
  },
  currencyToMint: {
    address: '0x846e4D51d7E2043C1a87E0Ab7490B93FB940357b',
    chainId: SupportedChainId.FANTOM,
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9vMJfxuKxXBoEa7rM12mYLMwTacLMLDJqHozw96WQL8i/logo.png',
    name: 'UST (Wormhole)',
    symbol: 'UST',
  },
})

WormholeToken.set(`${SupportedChainId.FANTOM}-${SupportedChainId.ETHEREUM}`, {
  currencyToLock: {
    address: '0x846e4D51d7E2043C1a87E0Ab7490B93FB940357b',
    chainId: SupportedChainId.FANTOM,
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9vMJfxuKxXBoEa7rM12mYLMwTacLMLDJqHozw96WQL8i/logo.png',
    name: 'UST (Wormhole)',
    symbol: 'UST',
  },
  currencyToMint: {
    address: '0xa693B19d2931d498c5B318dF961919BB4aee87a5',
    chainId: SupportedChainId.ETHEREUM,
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9vMJfxuKxXBoEa7rM12mYLMwTacLMLDJqHozw96WQL8i/logo.png',
    name: 'UST (Wormhole)',
    symbol: 'UST',
  },
})

WormholeToken.set(`${SupportedChainId.ETHEREUM}-${SupportedChainId.FANTOM}`, {
  currencyToLock: {
    address: '0xa693B19d2931d498c5B318dF961919BB4aee87a5',
    chainId: SupportedChainId.ETHEREUM,
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9vMJfxuKxXBoEa7rM12mYLMwTacLMLDJqHozw96WQL8i/logo.png',
    name: 'UST (Wormhole)',
    symbol: 'UST',
  },
  currencyToMint: {
    address: '0x846e4D51d7E2043C1a87E0Ab7490B93FB940357b',
    chainId: SupportedChainId.FANTOM,
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9vMJfxuKxXBoEa7rM12mYLMwTacLMLDJqHozw96WQL8i/logo.png',
    name: 'UST (Wormhole)',
    symbol: 'UST',
  },
})

WormholeToken.set(`${SupportedChainId.FANTOM}-${SupportedChainId.BINANCE}`, {
  currencyToLock: {
    address: '0x846e4D51d7E2043C1a87E0Ab7490B93FB940357b',
    chainId: SupportedChainId.FANTOM,
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9vMJfxuKxXBoEa7rM12mYLMwTacLMLDJqHozw96WQL8i/logo.png',
    name: 'UST (Wormhole)',
    symbol: 'UST',
  },
  currencyToMint: {
    address: '0x3d4350cD54aeF9f9b2C29435e0fa809957B3F30a',
    chainId: SupportedChainId.BINANCE,
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9vMJfxuKxXBoEa7rM12mYLMwTacLMLDJqHozw96WQL8i/logo.png',
    name: 'UST (Wormhole)',
    symbol: 'UST',
  },
})

WormholeToken.set(`${SupportedChainId.BINANCE}-${SupportedChainId.FANTOM}`, {
  currencyToLock: {
    address: '0x3d4350cD54aeF9f9b2C29435e0fa809957B3F30a',
    chainId: SupportedChainId.BINANCE,
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9vMJfxuKxXBoEa7rM12mYLMwTacLMLDJqHozw96WQL8i/logo.png',
    name: 'UST (Wormhole)',
    symbol: 'UST',
  },
  currencyToMint: {
    address: '0x846e4D51d7E2043C1a87E0Ab7490B93FB940357b',
    chainId: SupportedChainId.FANTOM,
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9vMJfxuKxXBoEa7rM12mYLMwTacLMLDJqHozw96WQL8i/logo.png',
    name: 'UST (Wormhole)',
    symbol: 'UST',
  },
})

WormholeToken.set(`${SupportedChainId.FANTOM}-${SupportedChainId.POLYGON}`, {
  currencyToLock: {
    address: '0x846e4D51d7E2043C1a87E0Ab7490B93FB940357b',
    chainId: SupportedChainId.FANTOM,
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9vMJfxuKxXBoEa7rM12mYLMwTacLMLDJqHozw96WQL8i/logo.png',
    name: 'UST (Wormhole)',
    symbol: 'UST',
  },
  currencyToMint: {
    address: '0xE6469Ba6D2fD6130788E0eA9C0a0515900563b59',
    chainId: SupportedChainId.POLYGON,
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9vMJfxuKxXBoEa7rM12mYLMwTacLMLDJqHozw96WQL8i/logo.png',
    name: 'UST (Wormhole)',
    symbol: 'UST',
  },
})

WormholeToken.set(`${SupportedChainId.POLYGON}-${SupportedChainId.FANTOM}`, {
  currencyToLock: {
    address: '0xE6469Ba6D2fD6130788E0eA9C0a0515900563b59',
    chainId: SupportedChainId.POLYGON,
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9vMJfxuKxXBoEa7rM12mYLMwTacLMLDJqHozw96WQL8i/logo.png',
    name: 'UST (Wormhole)',
    symbol: 'UST',
  },
  currencyToMint: {
    address: '0x846e4D51d7E2043C1a87E0Ab7490B93FB940357b',
    chainId: SupportedChainId.FANTOM,
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9vMJfxuKxXBoEa7rM12mYLMwTacLMLDJqHozw96WQL8i/logo.png',
    name: 'UST (Wormhole)',
    symbol: 'UST',
  },
})

WormholeToken.set(`${SupportedChainId.FANTOM}-${SupportedChainId.AVAX}`, {
  currencyToLock: {
    address: '0x846e4D51d7E2043C1a87E0Ab7490B93FB940357b',
    chainId: SupportedChainId.FANTOM,
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9vMJfxuKxXBoEa7rM12mYLMwTacLMLDJqHozw96WQL8i/logo.png',
    name: 'UST (Wormhole)',
    symbol: 'UST',
  },
  currencyToMint: {
    address: '0xb599c3590F42f8F995ECfa0f85D2980B76862fc1',
    chainId: SupportedChainId.AVAX,
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9vMJfxuKxXBoEa7rM12mYLMwTacLMLDJqHozw96WQL8i/logo.png',
    name: 'UST (Wormhole)',
    symbol: 'UST',
  },
})

WormholeToken.set(`${SupportedChainId.AVAX}-${SupportedChainId.FANTOM}`, {
  currencyToLock: {
    address: '0xb599c3590F42f8F995ECfa0f85D2980B76862fc1',
    chainId: SupportedChainId.AVAX,
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9vMJfxuKxXBoEa7rM12mYLMwTacLMLDJqHozw96WQL8i/logo.png',
    name: 'UST (Wormhole)',
    symbol: 'UST',
  },
  currencyToMint: {
    address: '0x846e4D51d7E2043C1a87E0Ab7490B93FB940357b',
    chainId: SupportedChainId.FANTOM,
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9vMJfxuKxXBoEa7rM12mYLMwTacLMLDJqHozw96WQL8i/logo.png',
    name: 'UST (Wormhole)',
    symbol: 'UST',
  },
})
