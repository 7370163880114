import { SupportedChainId } from 'config/constants/chains'

export const getNativeCurrency = {
  [SupportedChainId.TRON]: {
    symbol: 'TRX',
    chainId: SupportedChainId.TRON,
    name: 'Tron',
    address: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1094/small/tron-logo.png?1547035066',
    coinGeckoId: 'tron',
  },
  [SupportedChainId.ETHEREUM]: {
    symbol: 'ETH',
    name: 'Ethereum',
    decimals: 18,
    address: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
    logoURI: 'https://tokens.1inch.io/0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee.png',
    chainId: SupportedChainId.ETHEREUM,
    coinGeckoId: 'ethereum',
  },
  [SupportedChainId.BINANCE]: {
    symbol: 'BNB',
    name: 'BNB',
    decimals: 18,
    address: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
    logoURI: 'https://tokens.1inch.io/0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c_1.png',
    chainId: SupportedChainId.BINANCE,
    coinGeckoId: 'binancecoin',
  },
  [SupportedChainId.POLYGON]: {
    symbol: 'MATIC',
    chainId: SupportedChainId.POLYGON,
    name: 'MATIC',
    address: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3890.png',
    coinGeckoId: 'matic-network',
  },
  [SupportedChainId.AVAX]: {
    chainId: SupportedChainId.AVAX,
    symbol: 'AVAX',
    name: 'Avalanche',
    address: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12559/small/coin-round-red.png?1604021818',
    coinGeckoId: 'avalanche-2',
  },
  [SupportedChainId.FANTOM]: {
    name: 'Fantom',
    address: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
    symbol: 'FTM',
    decimals: 18,
    chainId: SupportedChainId.FANTOM,
    logoURI: 'https://raw.githubusercontent.com/sushiswap/icons/master/token/ftm.jpg',
    coinGeckoId: 'fantom',
  },
  [SupportedChainId.SOLANA]: {
    chainId: SupportedChainId.SOLANA,
    symbol: 'SOL',
    name: 'Native Solana',
    address: '11111111111111111111111111111111',
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5426.png',
    coinGeckoId: 'solana',
  },
}

export const getStableCurrency = {
  [SupportedChainId.ETHEREUM]: {
    symbol: 'USDT',
    name: 'Tether USD',
    address: '0xdac17f958d2ee523a2206206994597c13d831ec7',
    decimals: 6,
    logoURI: 'https://tokens.1inch.io/0xdac17f958d2ee523a2206206994597c13d831ec7.png',
    chainId: SupportedChainId.ETHEREUM,
  },
  [SupportedChainId.BINANCE]: {
    address: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    chainId: SupportedChainId.BINANCE,
    decimals: 18,
    logoURI: 'https://pancakeswap.finance/images/tokens/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56.png',
    name: 'Binance-USD',
    symbol: 'BUSD',
  },
  [SupportedChainId.POLYGON]: {
    symbol: 'USDC',
    name: 'USD Coin',
    decimals: 6,
    address: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174',
    logoURI: 'https://tokens.1inch.io/0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48.png',
    chainId: SupportedChainId.POLYGON,
  },
  [SupportedChainId.AVAX]: {
    symbol: 'USDC',
    name: 'USD Coin',
    decimals: 6,
    address: '0xb97ef9ef8734c71904d8002f8b6bc66dd9c48a6e',
    logoURI: 'https://tokens.1inch.io/0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48.png',
    chainId: SupportedChainId.AVAX,
  },
  [SupportedChainId.FANTOM]: {
    name: 'USD Coin',
    address: '0x04068DA6C83AFCFA0e13ba15A6696662335D5B75',
    symbol: 'USDC',
    decimals: 6,
    chainId: SupportedChainId.FANTOM,
    logoURI: 'https://raw.githubusercontent.com/sushiswap/icons/master/token/usdc.jpg',
  },
  [SupportedChainId.SOLANA]: {
    chainId: SupportedChainId.SOLANA,
    symbol: 'USDC',
    name: 'USD Coin',
    address: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v/logo.png',
  },
}

export const getGasLimit = {
  [SupportedChainId.SOLANA]: 1400000,
  [SupportedChainId.ETHEREUM]: 1400000,
  [SupportedChainId.BINANCE]: 1400000,
  [SupportedChainId.POLYGON]: 1400000,
  [SupportedChainId.AVAX]: 1400000,
  [SupportedChainId.FANTOM]: 1400000,
}
