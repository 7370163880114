import { SupportedChainId } from 'config/constants/chains'
import { Currency } from 'config/entities'

export const TronTokens: Currency[] = [
  {
    chainId: SupportedChainId.TRON,
    symbol: 'USDT',
    name: 'Tether USD',
    address: 'TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t',
    decimals: 6,
    logoURI: 'https://tokens.1inch.io/0xdac17f958d2ee523a2206206994597c13d831ec7.png',
  },
]
