import { SupportedChainId } from 'config/constants/chains'
import BlockchainNetworks from 'config/constants/network'
import { IBlockchainNetworks } from 'config/constants/types'
import { Currency, CurrencyAmount } from 'config/entities'
import { useSelector } from 'react-redux'
import { AppState } from 'state'
import { Field } from 'state/types'
import { ITransaction, SWAP_STATES, UPDATE_ACTIONS } from './types'

/**
 * Returns all Currencies
 * @param field
 */
export function useCurrencies(field: Field): Currency[] {
  const state = useSelector<AppState, AppState['swaps']>((s) => s.swaps)
  return state[field].currencies
}

/**
 * Returns true and false only  for relayer ui check box
 */
export function useAutomaticOnTargetChain(): boolean {
  const state = useSelector<AppState, AppState['swaps']>((s) => s.swaps)
  return state.isAutomaticOnTargetChain
}

/**
 * Returns selected currency
 * @param field
 */
export function useCurrency(field: Field): Currency {
  const state = useSelector<AppState, AppState['swaps']>((s) => s.swaps)
  return state[field].currency
}

/**
 * Returns Wallet of selected Chain
 * @param field
 */
export function useChainInfo(field: Field): SupportedChainId {
  const state = useSelector<AppState, AppState['swaps']>((s) => s.swaps)
  return state[field].chainId
}

/**
 * Returns Swap Page State
 */
export function useSwapState(): SWAP_STATES {
  const state = useSelector<AppState, AppState['swaps']>((s) => s.swaps)
  return state.pageState
}

/**
 * Returns Swap Page State
 */
export function useUpdateBalance(): boolean {
  const state = useSelector<AppState, AppState['swaps']>((s) => s.swaps)
  return state.shouldUpdateBalance
}

/**
 * Returns Wallet of selected Chain
 * @param field
 */
export function useGetBlockchainInfo(field: Field): IBlockchainNetworks {
  const state = useSelector<AppState, AppState['swaps']>((s) => s.swaps)
  return BlockchainNetworks.find((network) => network.chainId === state[field].chainId)
}

/**
 * Returns routes and its loading state
 */
export function useRoutes() {
  const state = useSelector<AppState, AppState['swaps']>((s) => s.swaps)
  return { routes: state[Field.OUTPUT].routes }
}

export function useActiveStepIndex() {
  const state = useSelector<AppState, AppState['swaps']>((s) => s.swaps)
  return { activeStepIndex: state.activeStepIndex }
}

export function useActiveStepState() {
  const state = useSelector<AppState, AppState['swaps']>((s) => s.swaps)
  return { activeStepState: state.actionStepState }
}

export function useActiveStep(activeStepIndex: number) {
  const state = useSelector<AppState, AppState['swaps']>((s) => s.swaps)
  return { step: state.steps[activeStepIndex] }
}

export function useSteps() {
  const state = useSelector<AppState, AppState['swaps']>((s) => s.swaps)
  return { steps: state.steps }
}

export function useSwapInfo() {
  const state = useSelector<AppState, AppState['swaps']>((s) => s.swaps)
  return { swapInfo: state.swapInfo }
}

export function useInputAmount() {
  const state = useSelector<AppState, AppState['swaps']>((s) => s.swaps)
  return { inputAmount: state.inputAmount }
}

export function useTransactionId() {
  const state = useSelector<AppState, AppState['swaps']>((s) => s.swaps)
  return { transactionId: state.transactionId }
}

export function useTransactionStatus() {
  const state = useSelector<AppState, AppState['swaps']>((s) => s.swaps)
  return { transactionStatus: state.transactionStatus }
}

export function useRelayerFee() {
  const state = useSelector<AppState, AppState['swaps']>((s) => s.swaps)
  return { relayerFee: state.relayerFee }
}

export function useRelayerFeeDollar() {
  const state = useSelector<AppState, AppState['swaps']>((s) => s.swaps)
  return { relayerFeeDollarValue: state.relayerFeeDollarValue }
}

export function useIsValidInput() {
  const state = useSelector<AppState, AppState['swaps']>((s) => s.swaps)
  return { isValidInput: state.isValidInput }
}

export function useMinimumAmount() {
  const state = useSelector<AppState, AppState['swaps']>((s) => s.swaps)
  return { minimumAmount: state.minimumAmount }
}

export function useSwapTransactions(): ITransaction[] {
  const state = useSelector<AppState, AppState['swaps']>((s) => s.swaps)
  return state.swapTransactions
}

export function useShouldFetchTransaction(): UPDATE_ACTIONS {
  const state = useSelector<AppState, AppState['swaps']>((s) => s.swaps)
  return state.shouldFetchTransaction
}

export function useFetchNetworkFee(): { fromSideNetworkFee: CurrencyAmount; toSideNetworkFee: CurrencyAmount } {
  const state = useSelector<AppState, AppState['swaps']>((s) => s.swaps)
  return { fromSideNetworkFee: state.fromSideNetworkFee, toSideNetworkFee: state.toSideNetworkFee }
}
/**
 * Returns true and false with check if relayer is enabled or not
 */
export function useIsAutomaticOnTargetChain() {
  const state = useSelector<AppState, AppState['swaps']>((s) => s.swaps)
  const inputChainId = state[Field.INPUT].chainId
  const outputChainId = state[Field.OUTPUT].chainId
  return {
    isAutomaticOnTargetChain:
      inputChainId !== outputChainId && outputChainId !== SupportedChainId.SOLANA
        ? state.isAutomaticOnTargetChain
        : false,
  }
}
