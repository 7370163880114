import { Box, Flex } from 'components/Box'
import useTheme from 'hooks/useTheme'
import { Modal } from 'widgets/Modal'
import React, { useMemo, useState } from 'react'
import { Text } from 'components/Text'
import { RiExternalLinkLine } from 'react-icons/ri'
import useTokenInfo from 'hooks/useTokenInfo'

import { CHAIN_IDS_TO_NAMES, NETWORKS_INFO } from 'config/constants/chains'
import moment from 'moment'
import { TransactionStatus } from 'config/enums/transactionStatus'
import { TimerText, Chevrondown, ListRow, ListView } from 'views/Swap/styles'
import { Loader } from 'components/Svg'
import { useTranslation } from 'contexts/Localization'
import { useSwapTransactions } from 'state/swap/selectors'
import truncateHash from 'utils/truncateHash'

interface IHistoryProps {
  handleDismiss: () => void
}

const HistoryModal: React.FC<IHistoryProps> = (props: IHistoryProps) => {
  const { handleDismiss } = props
  const { theme } = useTheme()
  const { t } = useTranslation()
  const { getTokenInfo } = useTokenInfo()
  const [loading, setLoading] = useState(false)
  const [showDropDown, setShowDropDown] = useState(false)

  // To maintain index to open "Showdropdown"
  const [dropDownIndex, setDropDownIndex] = useState<number>(0)

  const swapTransactions = useSwapTransactions()

  const transactions = useMemo(() => {
    return swapTransactions.filter((item) => {
      return item.status === TransactionStatus.DONE || item.status === TransactionStatus.FAILED
    })
  }, [])

  const AdjectValue = (value: number) => {
    return parseFloat(value.toFixed(3))
  }

  // To open specific "Dropdown" on a click
  const handleDropDownbtn = (index) => {
    if (!showDropDown) {
      setShowDropDown(true)
      setDropDownIndex(index)
    } else if (index == dropDownIndex) {
      setShowDropDown(false)
      setDropDownIndex(index)
    } else {
      setShowDropDown(true)
      setDropDownIndex(index)
    }
  }

  const Row = ({ index }) => {
    const fromTokenInfo = getTokenInfo(transactions[index]?.fromChainId, transactions[index]?.fromToken)
    const toTokenInfo = getTokenInfo(transactions[index]?.toChainId, transactions[index]?.toToken)
    return (
      <ListRow
        key={Math.random()}
        justifyContent={'space-between'}
        alignItems={'flex-start'}
        onClick={() => {
          handleDropDownbtn(index)
        }}
      >
        <Flex
          flexDirection="column"
          width="100%"
          className={showDropDown && index == dropDownIndex ? 'list-row-selected' : ''}
        >
          <Flex flexDirection="row" width="100%">
            <Flex flex={1}>
              <Chevrondown
                onClick={() => {
                  handleDropDownbtn(index)
                }}
                className={showDropDown && index == dropDownIndex ? 'showDropDown' : ''}
              ></Chevrondown>
            </Flex>
            <Flex flex={10} flexDirection={'column'} ml={'10px'}>
              <Flex flexDirection={'column'}>
                <Text
                  fontSize={'16px'}
                  fontFamily={theme.fonts.primary}
                  fontWeight={theme.fonts.semiBold}
                  color={
                    transactions[index].status === TransactionStatus.DONE ? theme.colors.text : theme.colors.failure
                  }
                >
                  {`${CHAIN_IDS_TO_NAMES[transactions[index]?.fromChainId].toUpperCase()} to ${CHAIN_IDS_TO_NAMES[
                    transactions[index]?.toChainId
                  ].toUpperCase()}`}
                </Text>
                <Text fontSize={'13px'} fontFamily={theme.fonts.primary} color={theme.colors.textBlur}>
                  {`${AdjectValue(+transactions[index]?.fromAmount)} ${fromTokenInfo?.symbol} to ${AdjectValue(
                    +transactions[index]?.toAmount,
                  )} ${toTokenInfo?.symbol}`}
                </Text>
              </Flex>
            </Flex>
            <Flex flex={2} flexDirection={'column'} alignItems={'center'}>
              <Text
                color={
                  transactions[index].status === TransactionStatus.DONE ? theme.colors.success : theme.colors.failure
                }
              >
                {transactions[index].status.charAt(0).toUpperCase() + transactions[index].status.slice(1).toLowerCase()}
              </Text>
              <TimerText>{moment(transactions[index].createdAt).format('DD MMM YYYY')}</TimerText>
            </Flex>
          </Flex>
          {showDropDown && dropDownIndex === index ? (
            <Flex flexDirection={'column'} flex={13} ml={'60px'} mt={'15px'} mb={'15px'} maxWidth={'100%'}>
              {transactions[index]?.fromSideTxHash && (
                <Flex
                  flexDirection={'column'}
                  mb={'10px'}
                  onClick={() =>
                    window.open(
                      `${NETWORKS_INFO[transactions[index]?.fromChainId].exploreLink}tx/${
                        transactions[index]?.fromSideTxHash
                      }`,
                      '_blank',
                    )
                  }
                >
                  <Text color={theme.colors.text} fontSize={'16px'}>
                    Token Locked
                  </Text>
                  <Flex flexDirection={'row'}>
                    <RiExternalLinkLine color={theme.colors.textBlur} size={'20px'} />
                    <Text color={theme.colors.textBlur} fontSize={'16px'} ml={'10px'}>
                      {truncateHash(transactions[index]?.fromSideTxHash, 15, 15)}
                    </Text>
                  </Flex>
                </Flex>
              )}
              {transactions[index]?.toSideTxHash && (
                <Flex
                  flexDirection={'column'}
                  onClick={() =>
                    window.open(
                      `${NETWORKS_INFO[transactions[index]?.toChainId].exploreLink}tx/${
                        transactions[index]?.toSideTxHash
                      }`,
                      '_blank',
                    )
                  }
                >
                  <Text color={theme.colors.text} fontSize={'16px'}>
                    Token Unlock
                  </Text>
                  <Flex flexDirection={'row'}>
                    <RiExternalLinkLine color={theme.colors.textBlur} size={'20px'} />
                    <Text color={theme.colors.textBlur} fontSize={'16px'} ml={'10px'}>
                      {truncateHash(transactions[index]?.toSideTxHash, 15, 15)}
                    </Text>
                  </Flex>
                </Flex>
              )}
            </Flex>
          ) : (
            ''
          )}
        </Flex>
      </ListRow>
    )
  }

  return (
    <Modal hideCloseButton={false} onDismiss={handleDismiss} title={'History'} minWidth="580px">
      <Box borderRadius={'12px'} background={theme.colors.backgroundAlt} p={'2%'} mt="1rem">
        {loading ? (
          <Flex width={'100%'} justifyContent={'center'} alignItems={'center'}>
            <Loader />
          </Flex>
        ) : transactions.length > 0 ? (
          <ListView flexDirection={'column'}>
            {transactions.map((transaction, index) => {
              return <Row key={transaction._id} index={index} />
            })}
          </ListView>
        ) : (
          <Flex width={'100%'} justifyContent={'center'} alignItems={'center'}>
            <Text
              fontSize={'14px'}
              fontFamily={theme.fonts.primary}
              fontWeight={theme.fonts.semiBold}
              color={theme.colors.textBlur}
            >
              {t('Empty')}
            </Text>
          </Flex>
        )}
      </Box>
      {/* <Flex justifyContent={'center'} my={'15px'} alignItems={'center'} width={'100%'}>
        <RiExternalLinkLine color={theme.colors.text} />
        <Text
          fontSize={'16px'}
          fontFamily={theme.fonts.secondary}
          fontWeight={theme.fonts.regular}
          color={theme.colors.text}
          ml="6px"
        >
          View transactions on Solscan
        </Text>
      </Flex> */}
    </Modal>
  )
}

export default HistoryModal
