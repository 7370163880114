import { Language } from 'config/constants/types'

export const EN: Language = { locale: 'en-US', language: 'EN', code: 'en' }
export const RU: Language = { locale: 'ru', language: 'RU', code: 'ru' }
export const TR: Language = { locale: 'tr', language: 'TR', code: 'tr' }
export const ZH: Language = { locale: 'zh-CN', language: 'ZH', code: 'zh' }
export const ES: Language = { locale: 'es-ES', language: 'ES', code: 'es' }

export const languages = {
  'en-US': EN,
  'zh-CN': ZH,
  tr: TR,
  ru: RU,
  'es-ES': ES,
}

export const languageList = Object.values(languages)
