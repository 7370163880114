import { Percent } from 'config/entities'
import JSBI from 'jsbi'

export const NetworkContextName = 'NETWORK'

export const FASTEST_INTERVAL = 3000000
export const FAST_INTERVAL = 10000000
export const SLOW_INTERVAL = 60000000
export const TEN_SECOND_INTERVAL = 10000

export const BIG_INT_ZERO = JSBI.BigInt(0)

// one basis JSBI.BigInt
export const BIPS_BASE = JSBI.BigInt(10000)
export const ONE_BIPS = new Percent(JSBI.BigInt(1), BIPS_BASE)

// default allowed slippage, in bips
export const INITIAL_ALLOWED_SLIPPAGE = new Percent(JSBI.BigInt(50), BIPS_BASE)

// used for warning states
export const ALLOWED_PRICE_IMPACT_LOW: Percent = new Percent(JSBI.BigInt(100), BIPS_BASE) // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM: Percent = new Percent(JSBI.BigInt(300), BIPS_BASE) // 3%
export const ALLOWED_PRICE_IMPACT_HIGH: Percent = new Percent(JSBI.BigInt(500), BIPS_BASE) // 5%
// if the price slippage exceeds this number, force the user to type 'confirm' to execute
export const PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN: Percent = new Percent(JSBI.BigInt(1000), BIPS_BASE) // 10%
// for non expert mode disable swaps above this
export const BLOCKED_PRICE_IMPACT_NON_EXPERT: Percent = new Percent(JSBI.BigInt(1500), BIPS_BASE) // 15%

export const BETTER_TRADE_LESS_HOPS_THRESHOLD = new Percent(JSBI.BigInt(50), BIPS_BASE)

export const ZERO_PERCENT = new Percent('0')
export const TWO_PERCENT = new Percent(JSBI.BigInt(200), BIPS_BASE)
export const FIFTEEN_PERCENT = new Percent(JSBI.BigInt(1500), BIPS_BASE)

export const ONE_HUNDRED_PERCENT = new Percent('1')

//dev net address
//export const ATS_MINT_ADDRESS = 'CtZWVtdwmUv7hSqwoMPTUJSz4T8Bp4ovdVLhhVNick4o'
//export const xATS_MINT_ADDRESS = '6V9FjrfhGTUkWbSUPR4tvvvmxW4TEqNRLG6uMtGGmRtf'

//main net addresses
export const ATS_MINT_ADDRESS = 'HJbNXx2YMRxgfUJ6K4qeWtjatMK5KYQT1QnsCdDWywNv'
export const xATS_MINT_ADDRESS = 'reZNfhu7z32WzecdjjswvZQMFrkXyUX4mbMh4NZwPNt'
export const ETHER = '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee'

export const SOLANA_ADDRESS = '11111111111111111111111111111111'
export const WRAPPED_SOLANA_ADDRESS = 'So11111111111111111111111111111111111111112'

export const BRIDGE = 'Bridge'
