import { Box, Flex } from 'components/Box'
import styled from 'styled-components'

export const NavTitle = styled('h3')<{ disabled?: boolean }>`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-weight: ${({ theme }) => theme.fonts.semiBold};
  color: ${({ theme }) => theme.colors.text};
  opacity: ${(props) => (props.disabled ? '0.7' : '1')};
  font-size: medium;
  text-transform: uppercase;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  ${({ theme }) => theme.mediaQueries.xl} {
    margin-top: 0.4rem;
  }
`

export const Button = styled('div')<{ isUnSelect?: boolean }>`
  background: ${({ theme, isUnSelect }) => (isUnSelect ? 'none' : theme.colors.gradientBackground)};
  padding: 1px;
  border-radius: 12px;
  position: relative;
  cursor: pointer;
  & div {
    padding: 0.6rem 1.3rem;
    font-family: ${({ theme }) => theme.fonts.primary};
    font-weight: ${({ theme }) => theme.fonts.semiBold};
    background: ${({ theme }) => theme.colors.background};
    color: ${({ theme }) => theme.colors.text};
    border-radius: 12px;
  }
  :hover {
    opacity: 0.7;
  }

  ${({ theme }) => theme.mediaQueries.md} {
    & div {
      padding: 0.4rem 1rem;
      font-size: x-small;
    }
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    & div {
      padding: 0.3rem 0.8rem;
      font-size: xx-small;
    }
  }
`

export const NavItemsContainer = styled(Flex)`
  flex: 2;
  justify-content: center;
  ${({ theme }) => theme.mediaQueries.xl} {
    flex-direction: column;
    align-items: center;
  }
`

export const HrLine = styled(Box)`
  display: block;
  width: 100%;
  height: 1px;
  background: ${({ theme }) => theme.colors.hrColor};
`

export const DrawerItemsContainer = styled(Box)`
  padding: 1rem;
`

export const DrawerItem = styled(Box)<{ active: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 1.5rem;
  padding: 0.4rem;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 4px;
    height: 100%;
    border-radius: 4px;
    background: ${(props) => (props.active ? props.theme.colors.gradientBackground : 'transparent')};
  }

  &:last-child {
    margin: 0;
  }

  &.disabled {
    a,
    svg {
      opacity: 0.5;
    }
  }

  & > a {
    font-family: ${({ theme }) => theme.fonts.primary};
    font-weight: ${({ theme }) => theme.fonts.semiBold};
    color: ${({ theme }) => theme.colors.text};
    margin-left: 0.4rem;
  }
`

export const RoundBox = styled(Box)`
  background-image: linear-gradient(97deg, #ff6c21ba 0%, #403fd8a6 100%);
  padding: 1px;
  margin-right: 0.6rem;
  margin-left: 0.6rem;
  border-radius: 50px;
  height: 25px;
  width: 25px;
  cursor: pointer;
  &:hover {
    transform: scale(1.09);
  }
  .inner-section {
    border-radius: 50px;
    background-color: #0d0d18;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .dex-logo {
    img {
      margin-left: 1px;
      margin-top: -2px;
    }
  }
`
export const IconButton = styled(Flex)`
  cursor: pointer;
  margin-right: 0.5rem;
  .icon {
    color: ${({ theme }) => theme.colors.text};
    font-size: 19px;
  }
  &:hover {
    opacity: 0.8;
  }
`
