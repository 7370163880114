import { SupportedChainId } from 'config/constants/chains'
import { Currency } from 'config/entities'

export const SolanaTokens: Currency[] = [
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'SOL',
    name: 'Native Solana',
    address: '11111111111111111111111111111111',
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5426.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'ATS',
    name: 'Atlas Dex',
    address: 'HJbNXx2YMRxgfUJ6K4qeWtjatMK5KYQT1QnsCdDWywNv',
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17418.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'Bonk',
    name: 'Bonk',
    address: 'DezXAZ8z7PnrnRJjz3wXBoRgixCa6xjnB7YaB1pPB263',
    decimals: 5,
    logoURI:
      'https://quei6zhlcfsxdfyes577gy7bkxmuz7qqakyt72xlbkyh7fysmoza.arweave.net/hQiPZOsRZXGXBJd_82PhVdlM_hACsT_q6wqwf5cSY7I',
  },
  {
    address: '9gP2kCy3wA1ctvYWQk75guqXuHfrEomqydHLtcTCqiLa',
    chainId: SupportedChainId.SOLANA,
    decimals: 8,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9gP2kCy3wA1ctvYWQk75guqXuHfrEomqydHLtcTCqiLa/logo.png',
    name: 'WBNB(Wormhole)',
    symbol: 'WBNB',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'RAY',
    name: 'Raydium',
    address: '4k3Dyjzvzp8eMZWUXbBCjEvwSkkk59S5iCNLY3QrkX6R',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4k3Dyjzvzp8eMZWUXbBCjEvwSkkk59S5iCNLY3QrkX6R/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'CWAR',
    name: 'Cryowar Token',
    address: 'HfYFjMKNZygfMC8LsQ8LtpPsPxEJoXJx4M6tqi75Hajo',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HfYFjMKNZygfMC8LsQ8LtpPsPxEJoXJx4M6tqi75Hajo/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'BSAMO',
    name: 'BUFF SAMO',
    address: '2XSuy8RSESbtYRBbVHxGWuoikn3B6iXKVKzN4i3owTCf',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2XSuy8RSESbtYRBbVHxGWuoikn3B6iXKVKzN4i3owTCf/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'DIO',
    name: 'Decimated',
    address: 'BiDB55p4G3n1fGhwKFpxsokBMqgctL4qnZpDH1bVQxMD',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BiDB55p4G3n1fGhwKFpxsokBMqgctL4qnZpDH1bVQxMD/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'AKELA',
    name: 'AKELA',
    address: '7QTcx3LuVfPSZxVzFJcTmbpQCL35jkfoNSzx4qouX495',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7QTcx3LuVfPSZxVzFJcTmbpQCL35jkfoNSzx4qouX495/logo.svg',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'HIPPO',
    name: 'Hippo Coin',
    address: '3EkHyexJLGCvSxzn5umbtd9N69GoT4p5pfdLTFqCNP9Y',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3EkHyexJLGCvSxzn5umbtd9N69GoT4p5pfdLTFqCNP9Y/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'BLT',
    name: 'Blocto Token',
    address: 'BLT1noyNr3GttckEVrtcfC6oyK6yV1DpPgSyXbncMwef',
    decimals: 8,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BLT1noyNr3GttckEVrtcfC6oyK6yV1DpPgSyXbncMwef/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'BIP',
    name: 'BIP',
    address: 'FoqP7aTaibT5npFKYKQQdyonL99vkW8YALNPwWepdvf5',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FoqP7aTaibT5npFKYKQQdyonL99vkW8YALNPwWepdvf5/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'CKG',
    name: "CryptoKnight's Gold",
    address: 'BYLotMdQmq579hhP9xDcuCJky9kmMrbp77eoktmm7a5Y',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BYLotMdQmq579hhP9xDcuCJky9kmMrbp77eoktmm7a5Y/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'JSOL',
    name: 'JPOOL Solana Token',
    address: '7Q2afV64in6N6SeZsAAB81TJzwDoD6zpqmHkzi9Dcavn',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7Q2afV64in6N6SeZsAAB81TJzwDoD6zpqmHkzi9Dcavn/logo.svg',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'WEED',
    name: 'Solana Weed',
    address: '7JYZmXjHenJxgLUtBxgYsFfoABmWQFA1fW3tHQKUBThV',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7JYZmXjHenJxgLUtBxgYsFfoABmWQFA1fW3tHQKUBThV/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'INU',
    name: 'Solana INU',
    address: '5jFnsfx36DyGk8uVGrbXnVUMTsBkPXGpx6e69BiGFzko',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5jFnsfx36DyGk8uVGrbXnVUMTsBkPXGpx6e69BiGFzko/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'EMON',
    name: 'DORAEMON TOKEN FANS',
    address: 'GJsBLZPMConURkFkewZskmJLFjnYVSENZtHjqV7GnohC',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GJsBLZPMConURkFkewZskmJLFjnYVSENZtHjqV7GnohC/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'MINECRAFT',
    name: 'Synex Coin',
    address: 'FTkj421DxbS1wajE74J34BJ5a1o9ccA97PkK6mYq9hNQ',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FTkj421DxbS1wajE74J34BJ5a1o9ccA97PkK6mYq9hNQ/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'SNJ',
    name: 'Sola Ninja',
    address: 'Hj4sTP4L4rvR9WBR6KyK99sxPptBQQczNWe4y15mxhRD',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/SolaToken/token-list/ninja/assets/mainnet/Hj4sTP4L4rvR9WBR6KyK99sxPptBQQczNWe4y15mxhRD/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'ALM',
    name: 'Almond',
    address: 'ALMmmmbt5KNrPPUBFE4dAKUKSPWTop5s3kUGCdF69gmw',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ALMmmmbt5KNrPPUBFE4dAKUKSPWTop5s3kUGCdF69gmw/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'NEKI',
    name: 'Maneki-neko',
    address: 'ALKiRVrfLgzeAV2mCT7cJHKg3ZoPvsCRSV7VCRWnE8zQ',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ALKiRVrfLgzeAV2mCT7cJHKg3ZoPvsCRSV7VCRWnE8zQ/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'NUTS',
    name: 'NUTS',
    address: 'GCxgQbbvJc4UyqGCsUAUa38npzZX27EMxZwckLuWeEkt',
    decimals: 9,
    logoURI: 'https://user-images.githubusercontent.com/93886730/140664862-6dd80bff-be30-4c68-a978-fcb205011d61.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'SPKL',
    name: 'Spookeletons Token',
    address: '31tCNEE6LiL9yW4Bu153Dq4vi2GuorXxCA9pW9aA6ecU',
    decimals: 9,
    logoURI: 'https://www.spookeletons.com/assets/spookeletons_tokenv2.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'SOLFI',
    name: 'SOLFI',
    address: '3CaBxqxWsP5oqS84Pkja4wLxyZYsHzMivQbnfwFJQeL1',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3CaBxqxWsP5oqS84Pkja4wLxyZYsHzMivQbnfwFJQeL1/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'BTSL',
    name: 'Bitsol Finance',
    address: 'DdUm3gHS5nnB3XKnxEufnazb5ERa9vHjK6S3DgZi7QR5',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DdUm3gHS5nnB3XKnxEufnazb5ERa9vHjK6S3DgZi7QR5/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'BAB',
    name: 'Banana Bucks',
    address: '2Dzzc14S1D7cEFGJyMZMACuoQRHVUYFhVE74C5o8Fwau',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Dzzc14S1D7cEFGJyMZMACuoQRHVUYFhVE74C5o8Fwau/logo.svg',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'SLFT',
    name: 'SOLife',
    address: 'GdRNeX9mbzCt4AnfiUFLbYZmxRuW7pGHfjbbAM59ZybR',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GdRNeX9mbzCt4AnfiUFLbYZmxRuW7pGHfjbbAM59ZybR/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'SLX',
    name: 'Solex Finance',
    address: 'AASdD9rAefJ4PP7iM89MYUsQEyCQwvBofhceZUGDh5HZ',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solexfin/token-list/main/assets/mainnet/AASdD9rAefJ4PP7iM89MYUsQEyCQwvBofhceZUGDh5HZ/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'BMBO',
    name: 'Bamboo',
    address: '5sM9xxcBTM9rWza6nEgq2cShA87JjTBx1Cu82LjgmaEg',
    decimals: 9,
    logoURI: 'https://raw.githubusercontent.com/rishkumaria/bamboo/main/bamboo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'SOLUM',
    name: 'Solum',
    address: '9XtRZwKzDXEJ61A7qCqbPz8jXMYHGT3LwxqrEzB6fqxv',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9XtRZwKzDXEJ61A7qCqbPz8jXMYHGT3LwxqrEzB6fqxv/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'SLB',
    name: 'Solberg',
    address: '2uRFEWRBQLEKpLmF8mohFZGDcFQmrkQEEZmHQvMUBvY7',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2uRFEWRBQLEKpLmF8mohFZGDcFQmrkQEEZmHQvMUBvY7/logo.svg',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'MILLI',
    name: 'MILLIONSY',
    address: 'HDLRMKW1FDz2q5Zg778CZx26UgrtnqpUDkNNJHhmVUFr',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/millionsy/token-list/main/assets/mainnet/HDLRMKW1FDz2q5Zg778CZx26UgrtnqpUDkNNJHhmVUFr/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'SOLA',
    name: 'Sola Token',
    address: 'FYfQ9uaRaYvRiaEGUmct45F9WKam3BYXArTrotnTNFXF',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FYfQ9uaRaYvRiaEGUmct45F9WKam3BYXArTrotnTNFXF/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'USDC',
    name: 'USD Coin',
    address: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'wID_v1',
    name: 'Everest ID (Wormhole v1)',
    address: '5HHv6HAyBtaihyHEapCJvjE6iRbGLRmm3F5EZjz6EzHV',
    decimals: 8,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5HHv6HAyBtaihyHEapCJvjE6iRbGLRmm3F5EZjz6EzHV/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'IVN',
    name: 'Investin Protocol',
    address: 'iVNcrNE9BRZBC9Aqf753iZiZfbszeAVUoikgT9yvr2a',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/iVNcrNE9BRZBC9Aqf753iZiZfbszeAVUoikgT9yvr2a/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'BTC',
    name: 'Wrapped Bitcoin (Sollet)',
    address: '9n4nbM75f5Ui33ZbPYXn59EwSgE8CGsHtAeTH5YFeJ9E',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9n4nbM75f5Ui33ZbPYXn59EwSgE8CGsHtAeTH5YFeJ9E/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'soETH',
    name: 'Wrapped Ethereum (Sollet)',
    address: '2FPyTwcZLUg1MDrwsyoP4D6s1tM7hAkHYRjkNb5w6Pxk',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2FPyTwcZLUg1MDrwsyoP4D6s1tM7hAkHYRjkNb5w6Pxk/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'soYFI',
    name: 'Wrapped YFI (Sollet)',
    address: '3JSf5tPeuscJGtaCp5giEiDhv51gQ4v3zWg8DGgyLfAB',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3JSf5tPeuscJGtaCp5giEiDhv51gQ4v3zWg8DGgyLfAB/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'soLINK',
    name: 'Wrapped Chainlink (Sollet)',
    address: 'CWE8jPTUYhdCTZYWPTe1o5DFqfdjzWKc9WKz6rSjQUdG',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CWE8jPTUYhdCTZYWPTe1o5DFqfdjzWKc9WKz6rSjQUdG/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'soUSDT',
    name: 'Wrapped USDT (Sollet)',
    address: 'BQcdHdAQW1hczDbBi9hiegXAR7A98Q9jx3X3iBBBDiq4',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BQcdHdAQW1hczDbBi9hiegXAR7A98Q9jx3X3iBBBDiq4/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'soSUSHI',
    name: 'Wrapped SUSHI (Sollet)',
    address: 'AR1Mtgh7zAtxuxGd2XPovXPVjcSdY3i4rQYisNadjfKy',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AR1Mtgh7zAtxuxGd2XPovXPVjcSdY3i4rQYisNadjfKy/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'soALEPH',
    name: 'Wrapped ALEPH (Sollet)',
    address: 'CsZ5LZkDS7h9TDKjrbL7VAwQZ9nsRu8vJLhRYfmGaN8K',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CsZ5LZkDS7h9TDKjrbL7VAwQZ9nsRu8vJLhRYfmGaN8K/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'soSXP',
    name: 'Wrapped SXP (Sollet)',
    address: 'SF3oTvfWzEP3DTwGSvUXRrGTvr75pdZNnBLAH9bzMuX',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SF3oTvfWzEP3DTwGSvUXRrGTvr75pdZNnBLAH9bzMuX/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'soHGET',
    name: 'Wrapped Hedget (Sollet)',
    address: 'BtZQfWqDGbk9Wf2rXEiWyQBdBY1etnUUn6zEphvVS7yN',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BtZQfWqDGbk9Wf2rXEiWyQBdBY1etnUUn6zEphvVS7yN/logo.svg',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'soCREAM',
    name: 'Wrapped Cream Finance (Sollet)',
    address: '5Fu5UUgbjpUvdBveb3a1JTNirL8rXtiYeSMWvKjtUNQv',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5Fu5UUgbjpUvdBveb3a1JTNirL8rXtiYeSMWvKjtUNQv/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'soUBXT',
    name: 'Wrapped Upbots (Sollet)',
    address: '873KLxCbz7s9Kc4ZzgYRtNmhfkQrhfyWGZJBmyCbC3ei',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/873KLxCbz7s9Kc4ZzgYRtNmhfkQrhfyWGZJBmyCbC3ei/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'soHNT',
    name: 'Wrapped Helium (Sollet)',
    address: 'HqB7uswoVg4suaQiDP3wjxob1G5WdZ144zhdStwMCq7e',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HqB7uswoVg4suaQiDP3wjxob1G5WdZ144zhdStwMCq7e/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'soFRONT',
    name: 'Wrapped FRONT (Sollet)',
    address: '9S4t2NEAiJVMvPdRYKVrfJpBafPBLtvbvyS3DecojQHw',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9S4t2NEAiJVMvPdRYKVrfJpBafPBLtvbvyS3DecojQHw/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'soAKRO',
    name: 'Wrapped AKRO (Sollet)',
    address: '6WNVCuxCGJzNjmMZoKyhZJwvJ5tYpsLyAtagzYASqBoF',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6WNVCuxCGJzNjmMZoKyhZJwvJ5tYpsLyAtagzYASqBoF/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'soHXRO',
    name: 'Wrapped HXRO (Sollet)',
    address: 'DJafV9qemGp7mLMEn5wrfqaFwxsbLgUsGVS16zKRk9kc',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DJafV9qemGp7mLMEn5wrfqaFwxsbLgUsGVS16zKRk9kc/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'soUNI',
    name: 'Wrapped UNI (Sollet)',
    address: 'DEhAasscXF4kEGxFgJ3bq4PpVGp5wyUxMRvn6TzGVHaw',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DEhAasscXF4kEGxFgJ3bq4PpVGp5wyUxMRvn6TzGVHaw/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'SRM',
    name: 'Serum',
    address: 'SRMuApVNdxXokk5GT7XD5cUUgXMBCoAz2LHeuAoKWRt',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SRMuApVNdxXokk5GT7XD5cUUgXMBCoAz2LHeuAoKWRt/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'soFTT',
    name: 'Wrapped FTT (Sollet)',
    address: 'AGFEad2et2ZJif9jaGpdMixQqvW5i81aBdvKe7PHNfz3',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AGFEad2et2ZJif9jaGpdMixQqvW5i81aBdvKe7PHNfz3/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'soTOMO',
    name: 'Wrapped TOMO (Sollet)',
    address: 'GXMvfY2jpQctDqZ9RoU3oWPhufKiCcFEfchvYumtX7jd',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GXMvfY2jpQctDqZ9RoU3oWPhufKiCcFEfchvYumtX7jd/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'soLUA',
    name: 'Wrapped LUA (Sollet)',
    address: 'EqWCKXfs3x47uVosDpTRgFniThL9Y8iCztJaapxbEaVX',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EqWCKXfs3x47uVosDpTRgFniThL9Y8iCztJaapxbEaVX/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'soMATH',
    name: 'Wrapped MATH (Sollet)',
    address: 'GeDS162t9yGJuLEHPWXXGrb1zwkzinCgRwnT8vHYjKza',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GeDS162t9yGJuLEHPWXXGrb1zwkzinCgRwnT8vHYjKza/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'soKEEP',
    name: 'Wrapped KEEP (Sollet)',
    address: 'GUohe4DJUA5FKPWo3joiPgsB7yzer7LpDmt1Vhzy3Zht',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GUohe4DJUA5FKPWo3joiPgsB7yzer7LpDmt1Vhzy3Zht/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'soSWAG',
    name: 'Wrapped SWAG (Sollet)',
    address: '9F9fNTT6qwjsu4X4yWYKZpsbw5qT7o6yR2i57JF2jagy',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9F9fNTT6qwjsu4X4yWYKZpsbw5qT7o6yR2i57JF2jagy/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'soCEL',
    name: 'Wrapped Celsius (Sollet)',
    address: 'DgHK9mfhMtUwwv54GChRrU54T2Em5cuszq2uMuen1ZVE',
    decimals: SupportedChainId.SOLANA,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DgHK9mfhMtUwwv54GChRrU54T2Em5cuszq2uMuen1ZVE/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'soRSR',
    name: 'Wrapped Reserve Rights (Sollet)',
    address: '7ncCLJpP3MNww17LW8bRvx8odQQnubNtfNZBL5BgAEHW',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7ncCLJpP3MNww17LW8bRvx8odQQnubNtfNZBL5BgAEHW/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'so1INCH',
    name: 'Wrapped 1INCH (Sollet)',
    address: '5wihEYGca7X4gSe97C5mVcqNsfxBzhdTwpv72HKs25US',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5wihEYGca7X4gSe97C5mVcqNsfxBzhdTwpv72HKs25US/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'soGRT',
    name: 'Wrapped GRT (Sollet)',
    address: '38i2NQxjp5rt5B3KogqrxmBxgrAwaB3W1f1GmiKqh9MS',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/38i2NQxjp5rt5B3KogqrxmBxgrAwaB3W1f1GmiKqh9MS/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'soCOMP',
    name: 'Wrapped Compound (Sollet)',
    address: 'Avz2fmevhhu87WYtWQCFj9UjKRjF9Z9QWwN2ih9yF95G',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Avz2fmevhhu87WYtWQCFj9UjKRjF9Z9QWwN2ih9yF95G/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'soPAXG',
    name: 'Wrapped Paxos Gold (Sollet)',
    address: '9wRD14AhdZ3qV8et3eBQVsrb3UoBZDUbJGyFckpTg8sj',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9wRD14AhdZ3qV8et3eBQVsrb3UoBZDUbJGyFckpTg8sj/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'FIDA',
    name: 'Bonfida',
    address: 'EchesyfXePKdLtoiZSL8pBe8Myagyy8ZRqsACNCFGnvp',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EchesyfXePKdLtoiZSL8pBe8Myagyy8ZRqsACNCFGnvp/logo.svg',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'KIN',
    name: 'KIN',
    address: 'kinXdEcpDQeHPEuQnqmUgtYykqKGVFq6CeVX5iAHJq6',
    decimals: 5,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/kinXdEcpDQeHPEuQnqmUgtYykqKGVFq6CeVX5iAHJq6/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'MAPS',
    name: 'MAPS',
    address: 'MAPS41MDahZ9QdKXhVa4dWB9RuyfV4XqhyAZ8XcYepb',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/MAPS41MDahZ9QdKXhVa4dWB9RuyfV4XqhyAZ8XcYepb/logo.svg',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'OXY',
    name: 'Oxygen Protocol',
    address: 'z3dn17yLaGMKffVogeFHQ9zWVcXgqgf3PQnDsNs2g6M',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/z3dn17yLaGMKffVogeFHQ9zWVcXgqgf3PQnDsNs2g6M/logo.svg',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'BRZ',
    name: 'BRZ',
    address: 'FtgGSFADXBtroxq8VCausXRr2of47QBf5AS1NtZCu4GD',
    decimals: SupportedChainId.SOLANA,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FtgGSFADXBtroxq8VCausXRr2of47QBf5AS1NtZCu4GD/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'USDT',
    name: 'USDT',
    address: 'Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB/logo.svg',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'BTSG',
    name: 'BitSong',
    address: 'BDxWSxkMLW1nJ3VggamUKkEKrtCaVqzFxoDApM8HdBks',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BDxWSxkMLW1nJ3VggamUKkEKrtCaVqzFxoDApM8HdBks/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'BNBBULL',
    name: 'BNBBULL',
    address: 'AfjHjdLibuXyvmz7PyTSc5KEcGBh43Kcu8Sr2tyDaJyt',
    decimals: 6,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5217.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'LTCBULL',
    name: 'LTCBULL',
    address: '863ZRjf1J8AaVuCqypAdm5ktVyGYDiBTvD1MNHKrwyjp',
    decimals: 6,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5217.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'BULL',
    name: 'BULL',
    address: 'GkSPaHdY2raetuYzsJYacHtrAtQUfWt64bpd1VzxJgSD',
    decimals: 6,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5217.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'ETHBULL',
    name: 'ETHBULL',
    address: 'CwChm6p9Q3yFrjzVeiLTTbsoJkooscof5SJYZc2CrNqG',
    decimals: 6,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5217.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'ALTBULL',
    name: 'ALTBULL',
    address: 'HRhaNssoyv5tKFRcbPg69ULEbcD8DPv99GdXLcdkgc1A',
    decimals: 6,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5217.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'MIDBULL',
    name: 'MIDBULL',
    address: 'EL1aDTnLKjf4SaGpqtxJPyK94imSBr8fWDbcXjXQrsmj',
    decimals: 6,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5217.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'AAVE',
    name: 'Wrapped Aave',
    address: 'dK83wTVypEpa1pqiBbHY3MNuUnT3ADUZM4wk9VZXZEc',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/dK83wTVypEpa1pqiBbHY3MNuUnT3ADUZM4wk9VZXZEc/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'LQID',
    name: 'LQID',
    address: 'A6aY2ceogBz1VaXBxm1j2eJuNZMRqrWUAnKecrMH85zj',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/A6aY2ceogBz1VaXBxm1j2eJuNZMRqrWUAnKecrMH85zj/logo.svg',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'MAPSPOOL',
    name: 'Bonfida Maps Pool',
    address: 'Gnhy3boBT4MA8TTjGip5ND2uNsceh1Wgeaw1rYJo51ZY',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Gnhy3boBT4MA8TTjGip5ND2uNsceh1Wgeaw1rYJo51ZY/logo.svg',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'OXYPOOL',
    name: 'Bonfida Oxy Pool',
    address: '9iDWyYZ5VHBCxxmWZogoY3Z6FSbKsX4WFe37c728krdT',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9iDWyYZ5VHBCxxmWZogoY3Z6FSbKsX4WFe37c728krdT/logo.svg',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'PERP',
    name: 'PERP',
    address: 'D68NB5JkzvyNCZAvi6EGtEcGvSoRNPanU9heYTAUFFRa',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/D68NB5JkzvyNCZAvi6EGtEcGvSoRNPanU9heYTAUFFRa/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'wCAPS_v1',
    name: 'Wrapped Capsule Coin (Wormhole v1)',
    address: 'FeLoyXk8ac2AYVmDhAWEKNWWT63Z9TczeidYbpDvxF3T',
    decimals: 8,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FeLoyXk8ac2AYVmDhAWEKNWWT63Z9TczeidYbpDvxF3T/logo.svg',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'wFTT_v1',
    name: 'FTT (Wormhole v1)',
    address: 'GbBWwtYTMPis4VHb8MrBbdibPhn28TSrLB53KvUmb7Gi',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GbBWwtYTMPis4VHb8MrBbdibPhn28TSrLB53KvUmb7Gi/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: '1INCH',
    name: '1INCH Token (Wormhole)',
    address: 'AjkPkq3nsyDe1yKcbyZT7N4aK4Evv9om9tzhQD3wsRC',
    decimals: 8,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8F3kZd9XEpFgNZ4fZnEAC5CJZLewnkNE8QCjdvorGWuW/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'wHEX_v1',
    name: 'HEX (Wormhole v1)',
    address: 'ELSnGFd5XnSdYFFSgYQp7n89FEbDqxN4npuRLW4PPPLv',
    decimals: 8,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ELSnGFd5XnSdYFFSgYQp7n89FEbDqxN4npuRLW4PPPLv/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'wDAI_v1',
    name: 'Dai Stablecoin (Wormhole v1)',
    address: 'FYpdBuyAHSbdaAyD1sKkxyLWbAP8uUW9h6uvdhK74ij1',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FYpdBuyAHSbdaAyD1sKkxyLWbAP8uUW9h6uvdhK74ij1/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'wBIRD_v1',
    name: 'Bird.Money (Wormhole v1)',
    address: 'FTPnEQ3NfRRZ9tvmpDW6JFrvweBE5sanxnXSpJL1dvbB',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FTPnEQ3NfRRZ9tvmpDW6JFrvweBE5sanxnXSpJL1dvbB/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'wFRAX_v1',
    name: 'Frax (Wormhole v1)',
    address: '8L8pDf3jutdpdr4m3np68CL9ZroLActrqwxi6s9Ah5xU',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8L8pDf3jutdpdr4m3np68CL9ZroLActrqwxi6s9Ah5xU/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'wLUNA_v1',
    name: 'Wrapped LUNA Token (Wormhole v1)',
    address: '2Xf2yAXJfg82sWwdLUo2x9mZXy6JCdszdMZkcF1Hf4KV',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Xf2yAXJfg82sWwdLUo2x9mZXy6JCdszdMZkcF1Hf4KV/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'wBUSD_v1',
    name: 'Binance USD (Wormhole v1)',
    address: 'AJ1W9A9N9dEMdVyoDiam2rV44gnBm2csrPDP7xqcapgX',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AJ1W9A9N9dEMdVyoDiam2rV44gnBm2csrPDP7xqcapgX/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'oDOP',
    name: 'Dominican Pesos',
    address: '4pk3pf9nJDN1im1kNwWJN1ThjE8pCYCTexXYGyFjqKVf',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4pk3pf9nJDN1im1kNwWJN1ThjE8pCYCTexXYGyFjqKVf/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'COPE',
    name: 'COPE',
    address: '8HGyAAB1yoM1ttS7pXjHMa3dukTFGQggnFFH3hJZgzQh',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8HGyAAB1yoM1ttS7pXjHMa3dukTFGQggnFFH3hJZgzQh/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'ROPE',
    name: 'Rope Token',
    address: '8PMHT4swUMtBzgHnh5U564N5sjPSiUz2cjEQzFnnP1Fo',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8PMHT4swUMtBzgHnh5U564N5sjPSiUz2cjEQzFnnP1Fo/logo.svg',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'MEDIA',
    name: 'Media Network',
    address: 'ETAtLmCmsoiEEKfNrHKJ2kYy3MoABhU6NQvpSfij5tDs',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ETAtLmCmsoiEEKfNrHKJ2kYy3MoABhU6NQvpSfij5tDs/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'STEP',
    name: 'Step',
    address: 'StepAscQoEioFxxWGnh2sLBDFp9d8rvKz2Yp39iDpyT',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/StepAscQoEioFxxWGnh2sLBDFp9d8rvKz2Yp39iDpyT/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'xSTEP',
    name: 'Staked Step',
    address: 'xStpgUCss9piqeFUk2iLVcvJEGhAdJxJQuwLkXP555G',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/xStpgUCss9piqeFUk2iLVcvJEGhAdJxJQuwLkXP555G/logo.svg',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'XSB',
    name: 'Solareum',
    address: '4UuGQgkD3rSeoXatXRWwRfRd21G87d5LiCfkVzNNv1Tt',
    decimals: 9,
    logoURI: 'https://solareum.app/icons/XSB-G.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'COBAN',
    name: 'COBAN',
    address: '7udMmYXh6cuWVY6qQVCd9b429wDVn2J71r5BdxHkQADY',
    decimals: 3,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7udMmYXh6cuWVY6qQVCd9b429wDVn2J71r5BdxHkQADY/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'QUEST',
    name: 'QUEST',
    address: '6ybxMQpMgQhtsTLhvHZqk8uqao7kvoexY6e8JmCTqAB1',
    decimals: SupportedChainId.SOLANA,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6ybxMQpMgQhtsTLhvHZqk8uqao7kvoexY6e8JmCTqAB1/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'SLIM',
    name: 'Solanium',
    address: 'xxxxa1sKNGwFtw2kFn8XauW9xq8hBZ5kVtcSesTT9fW',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/xxxxa1sKNGwFtw2kFn8XauW9xq8hBZ5kVtcSesTT9fW/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'SAMO',
    name: 'Samoyed Coin',
    address: '7xKXtg2CW87d97TXJSDpbD5jBkheTqA83TZRuJosgAsU',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7xKXtg2CW87d97TXJSDpbD5jBkheTqA83TZRuJosgAsU/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'PANDA',
    name: 'Panda Coin',
    address: 'Aw8qLRHGhMcKq7rxs5XBNCd9oe3BvoAhpNMVz7AdGmty',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Aw8qLRHGhMcKq7rxs5XBNCd9oe3BvoAhpNMVz7AdGmty/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'POM',
    name: 'Pom Token',
    address: 'ByJ8a9NWk6G4Jg4iFyFNdrya1iVcusL1aL9aGXWXeoVG',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ByJ8a9NWk6G4Jg4iFyFNdrya1iVcusL1aL9aGXWXeoVG/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'ATLAS',
    name: 'Star Atlas',
    address: 'ATLASXmbPQxBUYbxPsV97usA3fPQYEqzQBUHgiFCUsXx',
    decimals: 8,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ATLASXmbPQxBUYbxPsV97usA3fPQYEqzQBUHgiFCUsXx/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'POLIS',
    name: 'Star Atlas DAO',
    address: 'poLisWXnNRwC6oBu1vHiuKQzFjGL4XDSu4g9qjz9qVk',
    decimals: 8,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/poLisWXnNRwC6oBu1vHiuKQzFjGL4XDSu4g9qjz9qVk/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'YARD',
    name: 'SolYard Finance',
    address: '8RYSc3rrS4X4bvBCtSJnhcpPpMaAJkXnVKZPzANxQHgz',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8RYSc3rrS4X4bvBCtSJnhcpPpMaAJkXnVKZPzANxQHgz/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'STNK',
    name: 'Stonks',
    address: '43VWkd99HjqkhFTZbWBpMpRhjG469nWa7x7uEsgSH7We',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/43VWkd99HjqkhFTZbWBpMpRhjG469nWa7x7uEsgSH7We/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'HOLD',
    name: 'Holdana',
    address: '8GQsW3f7mdwfjqJon2myADcBsSsRjpXmxHYDG8q1pvV6',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8GQsW3f7mdwfjqJon2myADcBsSsRjpXmxHYDG8q1pvV6/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'SNY',
    name: 'Synthetify',
    address: '4dmKkXNHdgYsXqBHCuMikNQWwVomZURhYvkkX5c4pQ7y',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4dmKkXNHdgYsXqBHCuMikNQWwVomZURhYvkkX5c4pQ7y/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'MOLA',
    name: 'MOONLANA',
    address: 'AMdnw9H5DFtQwZowVFr4kUgSXJzLokKSinvgGiUoLSps',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AMdnw9H5DFtQwZowVFr4kUgSXJzLokKSinvgGiUoLSps/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'SOLAPE',
    name: 'SolAPE Token',
    address: 'GHvFFSZ9BctWsEc5nujR1MTmmJWY7tgQz2AXE6WVFtGN',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GHvFFSZ9BctWsEc5nujR1MTmmJWY7tgQz2AXE6WVFtGN/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'FOMO',
    name: 'FOMO',
    address: '92ibHPyfcwQnsscwkutSP7f9yWzz6GaVHu78gyQ37L1i',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/92ibHPyfcwQnsscwkutSP7f9yWzz6GaVHu78gyQ37L1i/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'GLUB',
    name: 'GLUB Token',
    address: 'Rs3Mrx9ZSNwsTM3gtJhEbeHQdTfzcvUWHg8T8Ena3pD',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Rs3Mrx9ZSNwsTM3gtJhEbeHQdTfzcvUWHg8T8Ena3pD/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'WOOF',
    name: 'WOOF',
    address: '9nEqaUcb16sQ3Tn1psbkWqyhPdLmfHWjKGymREjsAgTE',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9nEqaUcb16sQ3Tn1psbkWqyhPdLmfHWjKGymREjsAgTE/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'MER',
    name: 'Mercurial',
    address: 'MERt85fc5boKw3BW1eYdxonEuJNvXbiMbs6hvheau5K',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/MERt85fc5boKw3BW1eYdxonEuJNvXbiMbs6hvheau5K/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'ACMN',
    name: 'ACUMEN',
    address: '9MhNoxy1PbmEazjPo9kiZPCcG7BiFbhi3bWZXZgacfpp',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9MhNoxy1PbmEazjPo9kiZPCcG7BiFbhi3bWZXZgacfpp/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'CAPE',
    name: 'Crazy Ape Coin',
    address: 'GpYMp8eP3HADY8x1jLVfFVBVYqxFNxT5mFhZAZt9Poco',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GpYMp8eP3HADY8x1jLVfFVBVYqxFNxT5mFhZAZt9Poco/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'BOLE',
    name: 'Bole Token',
    address: '7uv3ZvZcQLd95bUp5WMioxG7tyAZVXFfr8JYkwhMYrnt',
    decimals: SupportedChainId.SOLANA,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7uv3ZvZcQLd95bUp5WMioxG7tyAZVXFfr8JYkwhMYrnt/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'APYS',
    name: 'APYSwap',
    address: '5JnZ667P3VcjDinkJFysWh2K2KtViy63FZ3oL5YghEhW',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5JnZ667P3VcjDinkJFysWh2K2KtViy63FZ3oL5YghEhW/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'SOLPAD',
    name: 'Solpad Finance',
    address: 'GfJ3Vq2eSTYf1hJP6kKLE9RT6u7jF9gNszJhZwo5VPZp',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GfJ3Vq2eSTYf1hJP6kKLE9RT6u7jF9gNszJhZwo5VPZp/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'SHBL',
    name: 'Shoebill Coin',
    address: '7fCzz6ZDHm4UWC9Se1RPLmiyeuQ6kStxpcAP696EuE1E',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7fCzz6ZDHm4UWC9Se1RPLmiyeuQ6kStxpcAP696EuE1E/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'TULIP',
    name: 'Tulip',
    address: 'TuLipcqtGVXP9XR62wM8WWCm6a9vhLs7T1uoWBk6FDs',
    decimals: 6,
    logoURI: 'https://raw.githubusercontent.com/sol-farm/token-logos/main/tulip.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'TYNA',
    name: 'wTYNA',
    address: '3QuAYThYKFXSmrTcSHsdd7sAxaFBobaCkLy2DBYJLMDs',
    decimals: 3,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3QuAYThYKFXSmrTcSHsdd7sAxaFBobaCkLy2DBYJLMDs/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'KEKW',
    name: 'kekwcoin',
    address: '2QK9vxydd7WoDwvVFT5JSU8cwE9xmbJSzeqbRESiPGMG',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2QK9vxydd7WoDwvVFT5JSU8cwE9xmbJSzeqbRESiPGMG/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'MEME',
    name: 'MemeMarketplace',
    address: '6DNkUoMa6vNo3CsxAw5XMJhjmdPbPBENHJ6w35eMXESo',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6DNkUoMa6vNo3CsxAw5XMJhjmdPbPBENHJ6w35eMXESo/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'DOGA',
    name: 'Dogana',
    address: 'EdGAZ8JyFTFbmVedVTbaAEQRb6bxrvi3AW3kz8gABz2E',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EdGAZ8JyFTFbmVedVTbaAEQRb6bxrvi3AW3kz8gABz2E/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'CHEEMS',
    name: 'Cheems',
    address: '3FoUAsGDbvTD6YZ4wVKJgTB76onJUKz7GPEBNiR5b8wc',
    decimals: SupportedChainId.SOLANA,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3FoUAsGDbvTD6YZ4wVKJgTB76onJUKz7GPEBNiR5b8wc/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'SBFC',
    name: 'SBF Coin',
    address: 'AWW5UQfMBnPsTaaxCK7cSEmkj1kbX2zUrqvgKXStjBKx',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AWW5UQfMBnPsTaaxCK7cSEmkj1kbX2zUrqvgKXStjBKx/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'CATO',
    name: 'CATO',
    address: '5p2zjqCd1WJzAVgcEnjhb9zWDU7b9XVhFhx4usiyN7jB',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5p2zjqCd1WJzAVgcEnjhb9zWDU7b9XVhFhx4usiyN7jB/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'SLNDN',
    name: 'Solanadon',
    address: 'HBHMiauecxer5FCzPeXgE2A8ZCf7fQgxxwo4vfkFtC7s',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HBHMiauecxer5FCzPeXgE2A8ZCf7fQgxxwo4vfkFtC7s/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'NINJA',
    name: 'NINJA',
    address: 'FgX1WD9WzMU3yLwXaFSarPfkgzjLb2DZCqmkx9ExpuvJ',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FgX1WD9WzMU3yLwXaFSarPfkgzjLb2DZCqmkx9ExpuvJ/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'CHAD',
    name: 'ChadTrader Token',
    address: '5j6BmiZTfHssaWPT23EQYQci3w57VTw7QypKArQZbSZ9',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5j6BmiZTfHssaWPT23EQYQci3w57VTw7QypKArQZbSZ9/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'DXL',
    name: 'Dexlab',
    address: 'GsNzxJfFn6zQdJGeYsupJWzUAm57Ba7335mfhWvFiE9Z',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GsNzxJfFn6zQdJGeYsupJWzUAm57Ba7335mfhWvFiE9Z/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'GRAPE',
    name: 'Grape',
    address: '8upjSpvjcdpuzhfR1zriwg5NXkwDruejqNE9WNbPRtyA',
    decimals: 6,
    logoURI:
      'https://lh3.googleusercontent.com/y7Wsemw9UVBc9dtjtRfVilnS1cgpDt356PPAjne5NvMXIwWz9_x7WKMPH99teyv8vXDmpZinsJdgiFQ16_OAda1dNcsUxlpw9DyMkUk=s0',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'TUTL',
    name: 'TurtleTraders',
    address: '3VhB8EAL8dZ457SiksLPpMUR1pyACpbNh5rTjQUEVCcH',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3VhB8EAL8dZ457SiksLPpMUR1pyACpbNh5rTjQUEVCcH/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'PIPANA',
    name: 'Pipana',
    address: '8tbAqS4dFNEeC6YGWpNnusc3JcxoFLMiiLPyHctgGYFe',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8tbAqS4dFNEeC6YGWpNnusc3JcxoFLMiiLPyHctgGYFe/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'SPW',
    name: 'SpiderSwap',
    address: 'ATxXyewb1cXThrQFmwHUy4dtPTErfsuqkg7JcUXgLgqo',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ATxXyewb1cXThrQFmwHUy4dtPTErfsuqkg7JcUXgLgqo/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'NRA',
    name: 'NORA',
    address: '1C2EYVrwmoXAGbiKirFFBeDFDYUBHPhDeg9trhibTND',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/1C2EYVrwmoXAGbiKirFFBeDFDYUBHPhDeg9trhibTND/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'MEOW',
    name: 'SOL-CATS',
    address: 'HAgX1HSfok8DohiNCS54FnC2UJkDSrRVnT38W3iWFwc8',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HAgX1HSfok8DohiNCS54FnC2UJkDSrRVnT38W3iWFwc8/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'SOLMO',
    name: 'SolMoon',
    address: 'Gro98oTmXxCVX8HKr3q2tMnP5ztoC77q6KehFDnAB983',
    decimals: SupportedChainId.SOLANA,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Gro98oTmXxCVX8HKr3q2tMnP5ztoC77q6KehFDnAB983/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'PGNT',
    name: 'PigeonSol Token',
    address: 'BxHJqGtC629c55swCqWXFGA2rRF1igbbTmh22H8ePUWG',
    decimals: SupportedChainId.SOLANA,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BxHJqGtC629c55swCqWXFGA2rRF1igbbTmh22H8ePUWG/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'APEX',
    name: 'APEX',
    address: '51tMb3zBKDiQhNwGqpgwbavaGH54mk8fXFzxTc1xnasg',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/51tMb3zBKDiQhNwGqpgwbavaGH54mk8fXFzxTc1xnasg/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'SOLD',
    name: 'Solanax',
    address: '5v6tZ1SiAi7G8Qg4rBF1ZdAn4cn6aeQtefewMr1NLy61',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5v6tZ1SiAi7G8Qg4rBF1ZdAn4cn6aeQtefewMr1NLy61/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'ORCA',
    name: 'Orca',
    address: 'orcaEKTdK7LKz57vaAYr9QeNsVEPfiu6QeMU1kektZE',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/orcaEKTdK7LKz57vaAYr9QeNsVEPfiu6QeMU1kektZE/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'SHILL',
    name: 'Project SEED Token',
    address: '6cVgJUqo4nmvQpbgrDZwyfd6RwWw5bfnCamS3M9N1fd',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6cVgJUqo4nmvQpbgrDZwyfd6RwWw5bfnCamS3M9N1fd/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'FTR',
    name: 'Future',
    address: 'HEhMLvpSdPviukafKwVN8BnBUTamirptsQ6Wxo5Cyv8s',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HEhMLvpSdPviukafKwVN8BnBUTamirptsQ6Wxo5Cyv8s/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'renBTC',
    name: 'renBTC',
    address: 'CDJWUqTcYTVAKXAVXoQZFes5JUFc7owSeq7eMQcDSbo5',
    decimals: 8,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CDJWUqTcYTVAKXAVXoQZFes5JUFc7owSeq7eMQcDSbo5/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'renDOGE',
    name: 'renDOGE',
    address: 'ArUkYE2XDKzqy77PRRGjo4wREWwqk6RXTfM9NeqzPvjU',
    decimals: 8,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ArUkYE2XDKzqy77PRRGjo4wREWwqk6RXTfM9NeqzPvjU/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'renLUNA',
    name: 'renLUNA',
    address: '8wv2KAykQstNAj2oW6AHANGBiFKVFhvMiyyzzjhkmGvE',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8wv2KAykQstNAj2oW6AHANGBiFKVFhvMiyyzzjhkmGvE/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'SAIL',
    name: 'SAIL',
    address: '6kwTqmdQkJd8qRr9RjSnUX9XJ24RmJRSrU1rsragP97Y',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6kwTqmdQkJd8qRr9RjSnUX9XJ24RmJRSrU1rsragP97Y/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'RIN',
    name: 'Aldrin',
    address: 'E5ndSkaB17Dm7CsD22dvcjfrYSDLCxFcMd6z8ddCk5wp',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/E5ndSkaB17Dm7CsD22dvcjfrYSDLCxFcMd6z8ddCk5wp/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'SCY',
    name: 'Synchrony',
    address: 'SCYfrGCw8aDiqdgcpdGjV6jp4UVVQLuphxTDLNWu36f',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SCYfrGCw8aDiqdgcpdGjV6jp4UVVQLuphxTDLNWu36f/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'TOX',
    name: 'trollbox',
    address: 'Bx4ykEMurwPQBAFNvthGj73fMBVTvHa8e9cbAyaK4ZSh',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Bx4ykEMurwPQBAFNvthGj73fMBVTvHa8e9cbAyaK4ZSh/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'PPUG',
    name: 'PizzaPugCoin',
    address: 'E7WqtfRHcY8YW8z65u9WmD7CfMmvtrm2qPVicSzDxLaT',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/E7WqtfRHcY8YW8z65u9WmD7CfMmvtrm2qPVicSzDxLaT/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'VCC',
    name: 'VentureCapital',
    address: 'FZgL5motNWEDEa24xgfSdBDfXkB9Ru9KxfEsey9S58bb',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FZgL5motNWEDEa24xgfSdBDfXkB9Ru9KxfEsey9S58bb/logo.svg',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'OXS',
    name: 'Oxbull Sol',
    address: '4TGxgCSJQx2GQk9oHZ8dC5m3JNXTYZHjXumKAW3vLnNx',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4TGxgCSJQx2GQk9oHZ8dC5m3JNXTYZHjXumKAW3vLnNx/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'FAB',
    name: 'FABRIC',
    address: 'EdAhkbj5nF9sRM7XN7ewuW8C9XEUMs8P7cnoQ57SYE96',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EdAhkbj5nF9sRM7XN7ewuW8C9XEUMs8P7cnoQ57SYE96/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'POTATO',
    name: 'POTATO',
    address: 'GEYrotdkRitGUK5UMv3aMttEhVAZLhRJMcG82zKYsaWB',
    decimals: 3,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GEYrotdkRitGUK5UMv3aMttEhVAZLhRJMcG82zKYsaWB/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'NAXAR',
    name: 'Naxar',
    address: 'Fp4gjLpTsPqBN6xDGpDHwtnuEofjyiZKxxZxzvJnjxV6',
    decimals: SupportedChainId.SOLANA,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Fp4gjLpTsPqBN6xDGpDHwtnuEofjyiZKxxZxzvJnjxV6/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'STR',
    name: 'Solster',
    address: '9zoqdwEBKWEi9G5Ze8BSkdmppxGgVv1Kw4LuigDiNr9m',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9zoqdwEBKWEi9G5Ze8BSkdmppxGgVv1Kw4LuigDiNr9m/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'HAMS',
    name: 'Space Hamster',
    address: 'A2T2jDe2bxyEHkKtS8AtrTRmJ9VZRwyY8Kr7oQ8xNyfb',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/A2T2jDe2bxyEHkKtS8AtrTRmJ9VZRwyY8Kr7oQ8xNyfb/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'STVA',
    name: 'SOLtiva',
    address: 'qXu8Tj65H5XR8KHuaKKoyLCWj592KbTG3YWJwsuFrPS',
    decimals: 3,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/qXu8Tj65H5XR8KHuaKKoyLCWj592KbTG3YWJwsuFrPS/logo.svg',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'GU',
    name: 'Kugle GU',
    address: '5KV2W2XPdSo97wQWcuAVi6G4PaCoieg4Lhhi61PAMaMJ',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5KV2W2XPdSo97wQWcuAVi6G4PaCoieg4Lhhi61PAMaMJ/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'SOLC',
    name: 'Solcubator',
    address: 'Bx1fDtvTN6NvE4kjdPHQXtmGSg582bZx9fGy4DQNMmAT',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Bx1fDtvTN6NvE4kjdPHQXtmGSg582bZx9fGy4DQNMmAT/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'DXB',
    name: 'DefiXBet Token',
    address: '8mgeCL7k2cB2KTN8NhD5biqvcbkfrmBogDpYoHmn5cKQ',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5GG1LbgY4EEvPR51YQPNr65QKcZemrHWPooTqC5gRPBA/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'LUNY',
    name: 'Luna Yield',
    address: '7a4cXVvVT7kF6hS5q5LDqtzWfHfys4a9PoK6pf87RKwf',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7a4cXVvVT7kF6hS5q5LDqtzWfHfys4a9PoK6pf87RKwf/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'DINO',
    name: 'DINO',
    address: '6Y7LbYB3tfGBG6CSkyssoxdtHb77AEMTRVXe8JUJRwZ7',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6Y7LbYB3tfGBG6CSkyssoxdtHb77AEMTRVXe8JUJRwZ7/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'LIQ',
    name: 'LIQ Protocol',
    address: '4wjPQJ6PrkC4dHhYghwJzGBVP78DkBzA2U3kHoFNBuhj',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4wjPQJ6PrkC4dHhYghwJzGBVP78DkBzA2U3kHoFNBuhj/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'CRP',
    name: 'CropperFinance',
    address: 'DubwWZNWiNGMMeeQHPnMATNj77YZPZSAz2WVR5WjLJqz',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DubwWZNWiNGMMeeQHPnMATNj77YZPZSAz2WVR5WjLJqz/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'SLRS',
    name: 'Solrise Finance',
    address: 'SLRSSpSLUTP7okbCUBYStWCo1vUgyt775faPqz8HUMr',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SLRSSpSLUTP7okbCUBYStWCo1vUgyt775faPqz8HUMr/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'JET',
    name: 'Jet Protocol',
    address: 'JET6zMJWkCN9tpRT2v2jfAmm5VnQFDpUBCyaKojmGtz',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/JET6zMJWkCN9tpRT2v2jfAmm5VnQFDpUBCyaKojmGtz/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'WOO',
    name: 'Wootrade Network',
    address: 'E5rk3nmgLUuKUiS94gg4bpWwWwyjCMtddsAXkTFLtHEy',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/E5rk3nmgLUuKUiS94gg4bpWwWwyjCMtddsAXkTFLtHEy/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'PKR2',
    name: 'PKR2',
    address: 'A1C9Shy732BThWvHAN936f33N7Wm1HbFvxb2zDSoBx8F',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/C-e-r-b-e-r-u-s/token-list/main/assets/mainnet/A1C9Shy732BThWvHAN936f33N7Wm1HbFvxb2zDSoBx8F/pkr2-logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'LIKE',
    name: 'Only1 (LIKE)',
    address: '3bRTivrVsitbmCTGtqwp7hxXPsybkjn4XLNtPsHqa3zR',
    decimals: 9,
    logoURI: 'https://only1.io/like-token.svg',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'wUST_v1',
    name: 'Wrapped UST (Wormhole v1)',
    address: 'CXLBjMMcwkc17GfJtBos6rQCo1ypeH6eDbB82Kby4MRm',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CXLBjMMcwkc17GfJtBos6rQCo1ypeH6eDbB82Kby4MRm/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'Orbs',
    name: 'Orbs',
    address: '7JnHPPJBBKSTJ7iEmsiGSBcPJgbcKw28uCRXtQgimncp',
    decimals: 8,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7JnHPPJBBKSTJ7iEmsiGSBcPJgbcKw28uCRXtQgimncp/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'SOLBERRY',
    name: 'SOLBERRY',
    address: 'EWS2ATMt5fQk89NWLJYNRmGaNoji8MhFZkUB4DiWCCcz',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EWS2ATMt5fQk89NWLJYNRmGaNoji8MhFZkUB4DiWCCcz/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'SBR',
    name: 'Saber Protocol Token',
    address: 'Saber2gLauYim4Mvftnrasomsv6NvAuncvMEZwcLpD1',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Saber2gLauYim4Mvftnrasomsv6NvAuncvMEZwcLpD1/logo.svg',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'LRA',
    name: 'Lumos Rewards',
    address: 'FMJotGUW16AzexRD3vXJQ94AL71cwrhtFaCTGtK1QHXm',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FMJotGUW16AzexRD3vXJQ94AL71cwrhtFaCTGtK1QHXm/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'wHBTC_v1',
    name: 'HBTC (Wormhole v1)',
    address: '8pBc4v9GAwCBNWPB5XKA93APexMGAS4qMr37vNke9Ref',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8pBc4v9GAwCBNWPB5XKA93APexMGAS4qMr37vNke9Ref/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'wHUSD_v1',
    name: 'HUSD Stablecoin (Wormhole v1)',
    address: 'BybpSTBoZHsmKnfxYG47GDhVPKrnEKX31CScShbrzUhX',
    decimals: 8,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BybpSTBoZHsmKnfxYG47GDhVPKrnEKX31CScShbrzUhX/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'wHAPI',
    name: 'Wrapped HAPI',
    address: '6VNKqgz9hk7zRShTFdg5AnkfKwZUcojzwAkzxSH3bnUm',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6VNKqgz9hk7zRShTFdg5AnkfKwZUcojzwAkzxSH3bnUm/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'LARIX',
    name: 'Larix',
    address: 'Lrxqnh6ZHKbGy3dcrCED43nsoLkM1LTzU2jRfWe8qUC',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Lrxqnh6ZHKbGy3dcrCED43nsoLkM1LTzU2jRfWe8qUC/logo.jpg',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'sSOL',
    name: 'SunnySideUp staked SOL (sSOL)',
    address: '8EDaoeBqpcVACwvkYXh1vAcU29HiBiNhqoF4pRsuUsZS',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8EDaoeBqpcVACwvkYXh1vAcU29HiBiNhqoF4pRsuUsZS/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'mSOL',
    name: 'Marinade staked SOL (mSOL)',
    address: 'mSoLzYCxHdYgdzU16g5QSh3i5K3z3KZK7ytfqcJm7So',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/mSoLzYCxHdYgdzU16g5QSh3i5K3z3KZK7ytfqcJm7So/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'PSOL',
    name: 'Parasol',
    address: 'Hmatmu1ktLbobSvim94mfpZmjL5iiyoM1zidtXJRAdLZ',
    decimals: 7,
    logoURI: 'https://raw.githubusercontent.com/parasol-finance/white-paper/main/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'SWARM',
    name: 'MIM',
    address: '4dydh8EGNEdTz6grqnGBxpduRg55eLnwNZXoNZJetadu',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4dydh8EGNEdTz6grqnGBxpduRg55eLnwNZXoNZJetadu/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'PORT',
    name: 'Port Finance Token',
    address: 'PoRTjZMPXb9T7dyU7tpLEZRQj7e6ssfAE62j2oQuc6y',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/PoRTjZMPXb9T7dyU7tpLEZRQj7e6ssfAE62j2oQuc6y/PORT.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'MNGO',
    name: 'Mango',
    address: 'MangoCzJ36AjZyKwVj3VnYU4GTonjfVEnJmvvWaxLac',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/MangoCzJ36AjZyKwVj3VnYU4GTonjfVEnJmvvWaxLac/token.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'UPS',
    name: 'UPS token (UPFI Network)',
    address: 'EwJN2GqUGXXzYmoAciwuABtorHczTA5LqbukKXV1viH7',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EwJN2GqUGXXzYmoAciwuABtorHczTA5LqbukKXV1viH7/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'gSAIL',
    name: 'SolanaSail Governance Token V2',
    address: 'Gsai2KN28MTGcSZ1gKYFswUpFpS7EM9mvdR9c8f6iVXJ',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solanasail/token-list/main/assets/mainnet/Gsai2KN28MTGcSZ1gKYFswUpFpS7EM9mvdR9c8f6iVXJ/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'PAI',
    name: 'PAI (Parrot USD)',
    address: 'Ea5SjE2Y6yvCeW5dYTn7PYMuW5ikXkvbGdcmSnXeaLjS',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Ea5SjE2Y6yvCeW5dYTn7PYMuW5ikXkvbGdcmSnXeaLjS/logo.svg',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'PRT',
    name: 'PRT (Parrot Protocol)',
    address: 'PRT88RkA4Kg5z7pKnezeNH4mafTvtQdfFgpQTGRjz44',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/PRT88RkA4Kg5z7pKnezeNH4mafTvtQdfFgpQTGRjz44/logo.svg',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'pBTC',
    name: 'pBTC (Parrot BTC)',
    address: 'DYDWu4hE4MN3aH897xQ3sRTs5EAjJDmQsKLNhbpUiKun',
    decimals: 8,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DYDWu4hE4MN3aH897xQ3sRTs5EAjJDmQsKLNhbpUiKun/logo.svg',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'pSOL',
    name: 'pSOL (Parrot SOL)',
    address: '9EaLkQrbjmbbuZG9Wdpo8qfNUEjHATJFSycEmw6f1rGX',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9EaLkQrbjmbbuZG9Wdpo8qfNUEjHATJFSycEmw6f1rGX/logo.svg',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'SUNNY',
    name: 'Sunny Governance Token',
    address: 'SUNNYWgPQmFxe9wTZzNK7iPnJ3vYDrkgnxJRJm1s3ag',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SUNNYWgPQmFxe9wTZzNK7iPnJ3vYDrkgnxJRJm1s3ag/logo.svg',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'CYS',
    name: 'Cyclos',
    address: 'BRLsMczKuaR5w9vSubF4j8HwEGGprVAyyVgS4EX7DKEg',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BRLsMczKuaR5w9vSubF4j8HwEGGprVAyyVgS4EX7DKEg/logo.svg',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'stSOL',
    name: 'Lido Staked SOL',
    address: '7dHbWXmci3dT8UFYWYZweBLXgycu7Y3iL6trKn1Y7ARj',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7dHbWXmci3dT8UFYWYZweBLXgycu7Y3iL6trKn1Y7ARj/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'wstETH',
    name: 'Lido Wrapped Staked ETH',
    address: 'ZScHuTtqZukUrtZS43teTKGs2VqkKL8k4QCouR2n6Uo',
    decimals: 8,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ZScHuTtqZukUrtZS43teTKGs2VqkKL8k4QCouR2n6Uo/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'KURO',
    name: 'Kurobi',
    address: '2Kc38rfQ49DFaKHQaWbijkE7fcymUMLY5guUiUsDmFfn',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Kc38rfQ49DFaKHQaWbijkE7fcymUMLY5guUiUsDmFfn/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'MM',
    name: 'Million',
    address: '2PoF4gqWg97yjJk276yUYaGVkkASE7tqAU7H5faEBkeC',
    decimals: 8,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2PoF4gqWg97yjJk276yUYaGVkkASE7tqAU7H5faEBkeC/logo.svg',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'HOTTO',
    name: 'HottoShotto',
    address: 'Bqd2ujCTEzpKzfjb1FHL7FKrdM6n1rZSnRecJK57EoKz',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Bqd2ujCTEzpKzfjb1FHL7FKrdM6n1rZSnRecJK57EoKz/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'SYP',
    name: 'Sypool',
    address: 'FnKE9n6aGjQoNWRBZXy4RW6LZVao7qwBonUbiD7edUmZ',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/b0e47e39f84cffb655f406eb569c57a88b5211cc/assets/mainnet/FnKE9n6aGjQoNWRBZXy4RW6LZVao7qwBonUbiD7edUmZ/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'CRY',
    name: 'Crystal',
    address: 'HbrmyoumgcK6sDFBi6EZQDi4i4ZgoN16eRB2JseKc7Hi',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HbrmyoumgcK6sDFBi6EZQDi4i4ZgoN16eRB2JseKc7Hi/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'DINOEGG',
    name: 'DINOEGG',
    address: '2TxM6S3ZozrBHZGHEPh9CtM74a9SVXbr7NQ7UxkRvQij',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2TxM6S3ZozrBHZGHEPh9CtM74a9SVXbr7NQ7UxkRvQij/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'ASH',
    name: 'Ashera',
    address: 'FY6XDSCubMhpkU9FAsUjB7jmN8YHYZGezHTWo9RHBSyX',
    decimals: SupportedChainId.SOLANA,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FY6XDSCubMhpkU9FAsUjB7jmN8YHYZGezHTWo9RHBSyX/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'ISOLA',
    name: 'Intersola',
    address: '333iHoRM2Awhf9uVZtSyTfU8AekdGrgQePZsKMFPgKmS',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/333iHoRM2Awhf9uVZtSyTfU8AekdGrgQePZsKMFPgKmS/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'DATE',
    name: 'SolDate(DATE) Token',
    address: 'Ce3PSQfkxT5ua4r2JqCoWYrMwKWC5hEzwsrT9Hb7mAz9',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Ce3PSQfkxT5ua4r2JqCoWYrMwKWC5hEzwsrT9Hb7mAz9/DATE.svg',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'SWAN',
    name: 'Swanlana',
    address: 'SWANaZUGxF82KyVsbxeeNsMaVECtimze5VyCdywkvkH',
    decimals: 9,
    logoURI: 'https://raw.githubusercontent.com/SwanLana/logo/main/SWANLANA_PNG.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'CSTR',
    name: 'CoreStarter',
    address: 'G7uYedVqFy97mzjygebnmmaMUVxWHFhNZotY6Zzsprvf',
    decimals: 9,
    logoURI: 'https://raw.githubusercontent.com/CoreStarter/token-logo/main/corestarter_logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'apUSDT',
    name: 'Wrapped USDT (Allbridge from Polygon)',
    address: 'DNhZkUaxHXYvpxZ7LNnHtss8sQgdAfd1ZYS1fB7LKWUZ',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BQcdHdAQW1hczDbBi9hiegXAR7A98Q9jx3X3iBBBDiq4/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'apUSDC',
    name: 'Wrapped USDC (Allbridge from Polygon)',
    address: 'eqKJTf1Do4MDPyKisMYqVaUFpkEFAs3riGF3ceDH2Ca',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BXXkv6z8ykpG1yuvUDPgh732wzVHB69RnB9YgSYh3itW/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'SOLR',
    name: 'SolRazr',
    address: '7j7H7sgsnNDeCngAPjpaCN4aaaru4HS7NAFYSEUyzJ3k',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7j7H7sgsnNDeCngAPjpaCN4aaaru4HS7NAFYSEUyzJ3k/SOLR.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'XVC',
    name: 'Xverse Token',
    address: '25Vu6457o2gdZRGVVt5K8NbAvaP3esYaQNHbNDitVtw1',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/25Vu6457o2gdZRGVVt5K8NbAvaP3esYaQNHbNDitVtw1/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'SPWN',
    name: 'Bitspawn Token',
    address: '5U9QqCPhqXAJcEv9uyzFJd5zhN93vuPk1aNNkXnUfPnt',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5U9QqCPhqXAJcEv9uyzFJd5zhN93vuPk1aNNkXnUfPnt/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'DSOL',
    name: 'DecentSol',
    address: '5y1YcGVPFy8bEiCJi79kegF9igahmvDe5UrqswFvnpMJ',
    decimals: SupportedChainId.SOLANA,
    logoURI: 'https://gateway.pinata.cloud/ipfs/QmfV1LNrqefadJQ7PzMvrTnio9GzsFLcbwRXAgVQad3ykt/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'scnSOL',
    name: 'Socean staked SOL',
    address: '5oVNBeEEQvYi1cX3ir8Dx5n1P7pdxydbGF2X4TxVusJm',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5oVNBeEEQvYi1cX3ir8Dx5n1P7pdxydbGF2X4TxVusJm/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'AGTE',
    name: 'Agronomist coin',
    address: '4QV4wzDdy7S1EV6y2r9DkmaDsHeoKz6HUvFLVtAsu6dV',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4QV4wzDdy7S1EV6y2r9DkmaDsHeoKz6HUvFLVtAsu6dV/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'SOLX',
    name: 'Soldex',
    address: 'CH74tuRLTYcxG7qNJCsV9rghfLXJCQJbsu7i52a8F1Gn',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CH74tuRLTYcxG7qNJCsV9rghfLXJCQJbsu7i52a8F1Gn/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'ABR',
    name: 'Allbridge',
    address: 'a11bdAAuV8iB2fu7X6AxAvDTo1QZ8FXB3kk5eecdasp',
    decimals: 9,
    logoURI: 'https://raw.githubusercontent.com/allbridge-io/media/main/token.svg',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'abBUSD',
    name: 'Wrapped BUSD (Allbridge from BSC)',
    address: '6nuaX3ogrr2CaoAPjtaKHAoBNWok32BMcRozuf32s2QF',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AJ1W9A9N9dEMdVyoDiam2rV44gnBm2csrPDP7xqcapgX/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'aeWETH',
    name: 'Wrapped ETH (Allbridge from Ethereum)',
    address: 'AaAEw2VCw1XzgvKB8Rj2DyK2ZVau9fbt2bE8hZFWsMyE',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FeGn77dhg1KXRRFeSwwMiykZnZPw5JXW6naf2aQgZDQf/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'aeUSDT',
    name: 'Wrapped USDT (Allbridge from Ethereum)',
    address: 'Bn113WT6rbdgwrm12UJtnmNqGqZjY4it2WoUQuQopFVn',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB/logo.svg',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'aeUSDC',
    name: 'Wrapped USDC (Allbridge from Ethereum)',
    address: 'DdFPRnccQqLD4zCHrBqdY95D6hvw6PLWp9DEXj1fLCL9',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'aeDAI',
    name: 'Wrapped DAI (Allbridge from Ethereum)',
    address: '9w6LpS7RU1DKftiwH3NgShtXbkMM1ke9iNU4g3MBXSUs',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FYpdBuyAHSbdaAyD1sKkxyLWbAP8uUW9h6uvdhK74ij1/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'acUSD',
    name: 'Wrapped CUSD (Allbridge from Celo)',
    address: 'EwxNF8g9UfmsJVcZFTpL9Hx5MCkoQFoJi6XNWzKf1j8e',
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7236.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'LDO',
    name: 'Lido DAO Token (Wormhole)',
    address: 'HZRCwxP2Vq9PCpPXooayhJ2bxTpo5xfpQrwB1svh332p',
    decimals: 8,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HZRCwxP2Vq9PCpPXooayhJ2bxTpo5xfpQrwB1svh332p/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'CPX',
    name: 'Circlepod Protocol Token',
    address: 'CPXDs2uhNwDKAt9V3vXvtspv9U7rsQ2fVr1qAUDmuCaq',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CPXDs2uhNwDKAt9V3vXvtspv9U7rsQ2fVr1qAUDmuCaq/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'CHIH',
    name: 'CHIHUAHUA',
    address: '6xtyNYX6Rf4Kp3629X11m1jqUmkV89mf9xQakUtUQfHq',
    decimals: 9,
    logoURI: 'https://raw.githubusercontent.com/ChihuahuaSol/Chihuahua/main/chihuahua-token.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'WET',
    name: 'Weble Ecosystem Token',
    address: '7R7rZ7SsLDXkYAfJyRCBScLuZwizeMWaTWrwFhSZU2Jq',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7R7rZ7SsLDXkYAfJyRCBScLuZwizeMWaTWrwFhSZU2Jq/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'aeFTT',
    name: 'Wrapped FTT (Allbridge from Ethereum)',
    address: 'BFsCwfk8VsEbSfLkkgmoKsAPk2N6FMJjeTsuxfGa9VEf',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AGFEad2et2ZJif9jaGpdMixQqvW5i81aBdvKe7PHNfz3/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'MDF',
    name: 'MatrixETF DAO Finance',
    address: 'ALQ9KMWjFmxVbew3vMkJj3ypbAKuorSgGst6svCHEe2z',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ALQ9KMWjFmxVbew3vMkJj3ypbAKuorSgGst6svCHEe2z/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'WAG',
    name: 'Waggle Network',
    address: '5tN42n9vMi6ubp67Uy4NnmM5DMZYN8aS8GeB3bEDHr6E',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5tN42n9vMi6ubp67Uy4NnmM5DMZYN8aS8GeB3bEDHr6E/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'SBNK',
    name: 'Solbank',
    address: 'uNrix3Q5g51MCEUrYBUEBDdQ96RQDQspQJJnnQ4T3Vc',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/uNrix3Q5g51MCEUrYBUEBDdQ96RQDQspQJJnnQ4T3Vc/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'MNDE',
    name: 'Marinade',
    address: 'MNDEFzGvMt87ueuHvVU9VcTqsAP5b3fTGPsHuuPA5ey',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/MNDEFzGvMt87ueuHvVU9VcTqsAP5b3fTGPsHuuPA5ey/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'DGLN',
    name: 'Dogelana',
    address: 'E6UU5M1z4CvSAAF99d9wRoXsasWMEXsvHrz3JQRXtm2X',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/E6UU5M1z4CvSAAF99d9wRoXsasWMEXsvHrz3JQRXtm2X/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'xUSD',
    name: 'Synthetic USD',
    address: '83LGLCm7QKpYZbX8q4W2kYWbtt8NJBwbVwEepzkVnJ9y',
    decimals: 6,
    logoURI: 'https://www.synthetify.io/icons/xusd.svg',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'xBTC',
    name: 'Synthetic BTC',
    address: 'HWxpSV3QAGzLQzGAtvhSYAEr7sTQugQygnni1gnUGh1D',
    decimals: 10,
    logoURI: 'https://www.synthetify.io/icons/xbtc.svg',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'xSOL',
    name: 'Synthetic SOL',
    address: 'BdUJucPJyjkHxLMv6ipKNUhSeY3DWrVtgxAES1iSBAov',
    decimals: 9,
    logoURI: 'https://www.synthetify.io/icons/xsol.svg',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'xFTT',
    name: 'Synthetic FTT',
    address: 'Fr3W7NPVvdVbwMcHgA7Gx2wUxP43txdsn3iULJGFbKz9',
    decimals: 8,
    logoURI: 'https://www.synthetify.io/icons/xftt.svg',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'xETH',
    name: 'Synthetic ETH',
    address: '8bqjz8DeSuim1sEAsQatjJN4zseyxSPdhHQcuuhL8PCK',
    decimals: 9,
    logoURI: 'https://www.synthetify.io/icons/xeth.svg',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'xLUNA',
    name: 'Synthetic LUNA',
    address: '6MeoZEcUMhAB788YXTQN4x7K8MnwSt6RHWsLkuq9GJb2',
    decimals: 6,
    logoURI: 'https://www.synthetify.io/icons/xluna.svg',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'USDCet',
    name: 'USD Coin (Wormhole from Ethereum)',
    address: 'A9mUU4qviSctJVPJdBJWkb28deg915LYJKrzQ19ji3FM',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/A9mUU4qviSctJVPJdBJWkb28deg915LYJKrzQ19ji3FM/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'USDCpo',
    name: 'USD Coin (Wormhole from Polygon)',
    address: 'E2VmbootbVCBkMNNxKQgCLMS1X3NoGMaYAsufaAsf7M',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/A9mUU4qviSctJVPJdBJWkb28deg915LYJKrzQ19ji3FM/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'USDTet',
    name: 'Tether USD (Wormhole from Ethereum)',
    address: 'Dn4noZ5jgGfkntzcQSUZ8czkreiZ1ForXYoV2H8Dm7S1',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Dn4noZ5jgGfkntzcQSUZ8czkreiZ1ForXYoV2H8Dm7S1/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'USDTpo',
    name: 'Tether USD (Wormhole from Polygon)',
    address: '5goWRao6a3yNC4d6UjMdQxonkCMvKBwdpubU3qhfcdf1',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Dn4noZ5jgGfkntzcQSUZ8czkreiZ1ForXYoV2H8Dm7S1/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'DAI',
    name: 'Dai Stablecoin (Wormhole)',
    address: 'EjmyN6qEC1Tf1JxiG1ae7UTJhUxSwk1TCWNWqxWV4J6o',
    decimals: 8,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EjmyN6qEC1Tf1JxiG1ae7UTJhUxSwk1TCWNWqxWV4J6o/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'HIMA',
    name: 'Himalayan Cat Coin',
    address: '72hgmvS5zFxaFJfMizq6Gp4gjBqXjTPyX9GDP38krorQ',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/72hgmvS5zFxaFJfMizq6Gp4gjBqXjTPyX9GDP38krorQ/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'FRKT',
    name: 'FRAKT Token',
    address: 'ErGB9xa24Szxbk1M28u2Tx8rKPqzL6BroNkkzk5rG4zj',
    decimals: 8,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ErGB9xa24Szxbk1M28u2Tx8rKPqzL6BroNkkzk5rG4zj/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'aaUSDT',
    name: 'Wrapped USDT (Allbridge from Avalanche)',
    address: 'FwEHs3kJEdMa2qZHv7SgzCiFXUQPEycEXksfBkwmS8gj',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB/logo.svg',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'aaUSDC',
    name: 'Wrapped USDC (Allbridge from Avalanche)',
    address: '8Yv9Jz4z7BUHP68dz8E8m3tMe6NKgpMUKn8KVqrPA6Fr',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'aaDAI',
    name: 'Wrapped DAI (Allbridge from Avalanche)',
    address: 'EgQ3yNtVhdHz7g1ZhjfGbxhFKMPPaFkz8QHXM5RBZBgi',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FYpdBuyAHSbdaAyD1sKkxyLWbAP8uUW9h6uvdhK74ij1/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'aaWBTC',
    name: 'Wrapped BTC (Allbridge from Avalanche)',
    address: 'Fd8xyHHRjTvxfZrBirb6MaxSmrZYw99gRSqFUKdFwFvw',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/qfnqNqs3nCAHjnyCgLRDbBtq4p2MtHZxw8YjSyYhPoL/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'AVAX',
    name: 'AVAX (Allbridge from Avalanche)',
    address: 'AUrMpCDYYcPuHhyNX8gEEqbmDPFUpBpHrNW3vPeCFn5Z',
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/12559/small/coin-round-red.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'AURY',
    name: 'Aurory',
    address: 'AURYydfxJib1ZkTir1Jn1J9ECYUtjb6rKQVmtYaixWPP',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AURYydfxJib1ZkTir1Jn1J9ECYUtjb6rKQVmtYaixWPP/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'DOGO',
    name: 'Dogemon',
    address: '5LSFpvLDkcdV2a3Kiyzmg5YmJsj2XDLySaXvnfP1cgLT',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5LSFpvLDkcdV2a3Kiyzmg5YmJsj2XDLySaXvnfP1cgLT/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'MSI',
    name: 'Matrix Solana Index',
    address: '2e7yNwrmTgXp9ABUmcPXvFJTSrEVLj4YMyrb4GUM4Pdd',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2e7yNwrmTgXp9ABUmcPXvFJTSrEVLj4YMyrb4GUM4Pdd/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'SAO',
    name: 'Sator',
    address: '2HeykdKjzHKGm2LKHw8pDYwjKPiFEoXAz74dirhUgQvq',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2HeykdKjzHKGm2LKHw8pDYwjKPiFEoXAz74dirhUgQvq/Sator_Logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'CAVE',
    name: 'Crypto Cavemen',
    address: '4SZjjNABoqhbd4hnapbvoEPEqT8mnNkfbEoAwALf1V8t',
    decimals: 6,
    logoURI:
      'https://ftoblquxiunjey7bu4eevlz2u7kwg5s3yvou7adgge2jbihcu7qq.arweave.net/LNwVwpdFGpJj4acISq86p9VjdlvFXU-AZjE0kKDip-E/?ext=png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'GOFX',
    name: 'GooseFX',
    address: 'GFX1ZjR2P15tmrSwow6FjyDYcEkoFb4p4gJCpLBjaxHD',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GFX1ZjR2P15tmrSwow6FjyDYcEkoFb4p4gJCpLBjaxHD/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'FTT',
    name: 'FTX Token (Wormhole)',
    address: 'EzfgjvkSwthhgHaceR3LnKXUoRkP6NUhfghdaHAj1tUv',
    decimals: 8,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EzfgjvkSwthhgHaceR3LnKXUoRkP6NUhfghdaHAj1tUv/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'UST',
    name: 'UST (Wormhole)',
    address: '9vMJfxuKxXBoEa7rM12mYLMwTacLMLDJqHozw96WQL8i',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9vMJfxuKxXBoEa7rM12mYLMwTacLMLDJqHozw96WQL8i/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'ETH',
    name: 'Ether (Wormhole)',
    address: '7vfCXTUXx5WJV5JADk17DUJ4ksgau7utNKj4b963voxs',
    decimals: 8,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7vfCXTUXx5WJV5JADk17DUJ4ksgau7utNKj4b963voxs/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'SRMet',
    name: 'Serum (Wormhole from Ethereum)',
    address: 'xnorPhAzWXUczCP3KjU5yDxmKKZi5cSbxytQ1LgE3kG',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/xnorPhAzWXUczCP3KjU5yDxmKKZi5cSbxytQ1LgE3kG/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'LUNA',
    name: 'LUNA (Wormhole)',
    address: 'F6v4wfAdJB8D8p77bMXZgYt8TDKsYxLYxH5AFhUkYx9W',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/F6v4wfAdJB8D8p77bMXZgYt8TDKsYxLYxH5AFhUkYx9W/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'AVAX',
    name: 'AVAX (Wormhole)',
    address: 'KgV1GvrHQmRBY8sHQQeUKwTm2r2h8t4C8qt12Cw1HVE',
    decimals: 8,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/KgV1GvrHQmRBY8sHQQeUKwTm2r2h8t4C8qt12Cw1HVE/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'HUSD',
    name: 'HUSD (Wormhole)',
    address: '7VQo3HFLNH5QqGtM8eC3XQbPkJUu7nS9LeGWjerRh5Sw',
    decimals: 8,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7VQo3HFLNH5QqGtM8eC3XQbPkJUu7nS9LeGWjerRh5Sw/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'BUSDet',
    name: 'Binance USD (Wormhole from Ethereum)',
    address: '33fsBLA8djQm82RpHmE3SuVrPGtZBWNYExsEUeKX1HXX',
    decimals: 8,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/33fsBLA8djQm82RpHmE3SuVrPGtZBWNYExsEUeKX1HXX/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'FRAX',
    name: 'Frax (Wormhole)',
    address: 'FR87nWEUxVgerFGhZM8Y4AggKGLnaXswr1Pd8wZ4kZcp',
    decimals: 8,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FR87nWEUxVgerFGhZM8Y4AggKGLnaXswr1Pd8wZ4kZcp/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'HBTC',
    name: 'Huobi BTC (Wormhole)',
    address: '7dVH61ChzgmN9BwG4PkzwRP8PbYwPJ7ZPNF2vamKT2H8',
    decimals: 8,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7dVH61ChzgmN9BwG4PkzwRP8PbYwPJ7ZPNF2vamKT2H8/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'USDK',
    name: 'USDK (Wormhole)',
    address: '43m2ewFV5nDepieFjT9EmAQnc1HRtAF247RBpLGFem5F',
    decimals: 8,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/43m2ewFV5nDepieFjT9EmAQnc1HRtAF247RBpLGFem5F/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'SUSHI',
    name: 'SushiToken (Wormhole)',
    address: 'ChVzxWRmrTeSgwd3Ui3UumcN8KX7VK3WaD4KGeSKpypj',
    decimals: 8,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ChVzxWRmrTeSgwd3Ui3UumcN8KX7VK3WaD4KGeSKpypj/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'UNI',
    name: 'Uniswap (Wormhole)',
    address: '8FU95xFJhUUkyyCLU13HSzDLs7oC4QZdXQHL6SCeab36',
    decimals: 8,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8FU95xFJhUUkyyCLU13HSzDLs7oC4QZdXQHL6SCeab36/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'BNB',
    name: 'Binance Coin (Wormhole)',
    address: '9gP2kCy3wA1ctvYWQk75guqXuHfrEomqydHLtcTCqiLa',
    decimals: 8,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9gP2kCy3wA1ctvYWQk75guqXuHfrEomqydHLtcTCqiLa/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'LINK',
    name: 'ChainLink Token (Wormhole)',
    address: '2wpTofQ8SkACrkZWrZDjXPitYa8AwWgX8AfxdeBRRVLX',
    decimals: 8,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2wpTofQ8SkACrkZWrZDjXPitYa8AwWgX8AfxdeBRRVLX/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'PAXG',
    name: 'Paxos Gold (Wormhole)',
    address: 'C6oFsE8nXRDThzrMEQ5SxaNFGKoyyfWDDVPw37JKvPTe',
    decimals: 8,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/C6oFsE8nXRDThzrMEQ5SxaNFGKoyyfWDDVPw37JKvPTe/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'SXP',
    name: 'Swipe (Wormhole)',
    address: '3CyiEDRehaGufzkpXJitCP5tvh7cNhRqd9rPBxZrgK5z',
    decimals: 8,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3CyiEDRehaGufzkpXJitCP5tvh7cNhRqd9rPBxZrgK5z/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'CEL',
    name: 'Celsius (Wormhole)',
    address: 'nRtfwU9G82CSHhHGJNxFhtn7FLvWP2rqvQvje1WtL69',
    decimals: SupportedChainId.SOLANA,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/nRtfwU9G82CSHhHGJNxFhtn7FLvWP2rqvQvje1WtL69/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'CREAM',
    name: 'Cream (Wormhole)',
    address: 'HihxL2iM6L6P1oqoSeiixdJ3PhPYNxvSKH9A2dDqLVDH',
    decimals: 8,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HihxL2iM6L6P1oqoSeiixdJ3PhPYNxvSKH9A2dDqLVDH/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'OASIS',
    name: 'Oasis',
    address: '8oiPhiFrmXS93iC98M4ATev8emQ6XGtf8pz8sntbbqGt',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8oiPhiFrmXS93iC98M4ATev8emQ6XGtf8pz8sntbbqGt/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'SAMU',
    name: 'Samusky Token',
    address: 'SAMUmmSvrE8yqtcG94oyP1Zu2P9t8PSRSV3vewsGtPM',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SAMUmmSvrE8yqtcG94oyP1Zu2P9t8PSRSV3vewsGtPM/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'acUSDC',
    name: 'Wrapped USDC (Allbridge from Celo)',
    address: 'DHpoYejUDqzByb6HAdaLWF7KZvwUv2vWYDY9cTENNZui',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'ahBTC',
    name: 'Wrapped BTC (Allbridge from HECO)',
    address: 'Bo4ehCeRcRj2wp5tQpjfCJxYFn4KyRacfDzSa4Aj27tH',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Bo4ehCeRcRj2wp5tQpjfCJxYFn4KyRacfDzSa4Aj27tH/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'ahUSDT',
    name: 'Wrapped USDT (Allbridge from HECO)',
    address: 'GfzU1fLASNV3r4NtEyrnwTyTakJkYzoivnaL3Snh45oj',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GfzU1fLASNV3r4NtEyrnwTyTakJkYzoivnaL3Snh45oj/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'FLOOF',
    name: 'FLOOF',
    address: '3jzdrXXKxwkBk82u2eCWASZLCKoZs1LQTg87HBEAmBJw',
    decimals: 1,
    logoURI: 'https://raw.githubusercontent.com/GreenFailure/Floof/main/OkyT9kpz_400x400.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'BABY',
    name: 'Baby Samo Coin',
    address: 'Uuc6hiKT9Y6ASoqs2phonGGw2LAtecfJu9yEohppzWH',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Uuc6hiKT9Y6ASoqs2phonGGw2LAtecfJu9yEohppzWH/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'SLND',
    name: 'Solend',
    address: 'SLNDpmoWTVADgEdndyvWzroNL7zSi1dF9PC3xHGtPwp',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SLNDpmoWTVADgEdndyvWzroNL7zSi1dF9PC3xHGtPwp/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'SCUM',
    name: 'Solana CUM',
    address: 'cqNTpypmbwghrf1G9VGvSENcw7M7wGSQ7JS8UTQWXwb',
    decimals: 8,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/cqNTpypmbwghrf1G9VGvSENcw7M7wGSQ7JS8UTQWXwb/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'APPLE',
    name: 'Apple Fruit',
    address: '8E5W9PMhnEvdvM2Q9XBLMJW7UsFiieXnRHPj8zhtB23h',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8E5W9PMhnEvdvM2Q9XBLMJW7UsFiieXnRHPj8zhtB23h/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'DEGN',
    name: 'Degen',
    address: 'A9UhP1xfQHWUhSd54NgKPub2XB3ZuQMdPEvf9aMTHxGT',
    decimals: 9,
    logoURI: 'https://raw.githubusercontent.com/flazewang/degencoinsolana/main/degen.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'SHIBA',
    name: 'Shibalana',
    address: 'Dhg9XnzJWzSQqH2aAnhPTEJHGQAkALDfD98MA499A7pa',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Dhg9XnzJWzSQqH2aAnhPTEJHGQAkALDfD98MA499A7pa/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'PORN',
    name: 'Pornlana',
    address: '8gWEnKqB4qVQgC8yAorMxhiEKqsDcxZSVKFVbQ8g1fzB',
    decimals: 9,
    logoURI: 'https://raw.githubusercontent.com/Agaveis/pornlana/main/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'DICK',
    name: 'Dickcoin',
    address: 'E6Hkw5o48QfNo6iUi1aepjEBzVq4ZjQLxh7xVtdTqoyB',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/E6Hkw5o48QfNo6iUi1aepjEBzVq4ZjQLxh7xVtdTqoyB/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'SINU',
    name: 'Samo INU',
    address: 'Ac7GiHwC7vZU2y97GRh9rqCqqnKAAgopYrTAtKccHxUk',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Ac7GiHwC7vZU2y97GRh9rqCqqnKAAgopYrTAtKccHxUk/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'ACE',
    name: 'ACE',
    address: '5fhXkD8tXyDB9rmYZSNJ6LneLr2nMteMpCVxeDDEgXa3',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5fhXkD8tXyDB9rmYZSNJ6LneLr2nMteMpCVxeDDEgXa3/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'VIBE',
    name: 'VIBE',
    address: '8o66EVAf4u2Hr21m2tuRrPtEXFPLr8G8aL1ETStP8fDu',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8o66EVAf4u2Hr21m2tuRrPtEXFPLr8G8aL1ETStP8fDu/VIBE-logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'USDCbs',
    name: 'USD Coin (Wormhole from BSC)',
    address: 'FCqfQSujuPxy6V42UvafBhsysWtEq1vhjfMN1PUbgaxA',
    decimals: 8,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FCqfQSujuPxy6V42UvafBhsysWtEq1vhjfMN1PUbgaxA/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'aSOL',
    name: 'aSOL Aggregate Solana Stake Pool',
    address: 'ASoLXbfe7cd6igh5yiEsU8M7FW64QRxPKkxk7sjAfond',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ASoLXbfe7cd6igh5yiEsU8M7FW64QRxPKkxk7sjAfond/logo.svg',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'USDTbs',
    name: 'Tether USD (Wormhole from BSC)',
    address: '8qJSyQprMC57TWKaYEmetUR3UUiTP2M3hXdcvFhkZdmv',
    decimals: 8,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8qJSyQprMC57TWKaYEmetUR3UUiTP2M3hXdcvFhkZdmv/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'DSC',
    name: 'DoggyStyle Coin',
    address: 'DogscQVvNVj7ndEnhWiCXPVPKKwNy9fJd4ATF7mVi5J',
    decimals: 9,
    logoURI: 'https://raw.githubusercontent.com/Doggystylecoin/DSC/main/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'PUSSY',
    name: 'Pussy',
    address: 'BjTUmZjNUUAPKHVdTs8yZsCmecW5isSK4AbuFihXoUwa',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BjTUmZjNUUAPKHVdTs8yZsCmecW5isSK4AbuFihXoUwa/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'BITCH',
    name: 'Bitch Of Solana',
    address: 'AMNoi4727tzy7adu4wnx3cN2VQbQdG71DqaPoSm7isJ3',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AMNoi4727tzy7adu4wnx3cN2VQbQdG71DqaPoSm7isJ3/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'TICKET',
    name: 'The Ticket Finance',
    address: 'AymKzSDznoLT7Vhsb4wSRnCj1gjcG3zkgYFY8fxsHHer',
    decimals: 8,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AymKzSDznoLT7Vhsb4wSRnCj1gjcG3zkgYFY8fxsHHer/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'PART',
    name: 'Particle',
    address: 'AVKnbqNQgXDY8kbnno9eSGfwpVz5idimBnDKiz1vbWAh',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AVKnbqNQgXDY8kbnno9eSGfwpVz5idimBnDKiz1vbWAh/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'KITTY',
    name: 'Kitty Coin',
    address: '6XWfkyg5mzGtKNftSDgYjyoPyUsLRf2rafj95XSFSFrr',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6XWfkyg5mzGtKNftSDgYjyoPyUsLRf2rafj95XSFSFrr/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'DCN',
    name: 'DoodleCoin',
    address: 'FZMcCAq9U65mymBjUxKXPUJehDJMLg54Ud5bTrmbaHYS',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FZMcCAq9U65mymBjUxKXPUJehDJMLg54Ud5bTrmbaHYS/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'IN',
    name: 'Sol Invictus',
    address: 'inL8PMVd6iiW3RCBJnr5AsrRN6nqr4BTrcNuQWQSkvY',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/inL8PMVd6iiW3RCBJnr5AsrRN6nqr4BTrcNuQWQSkvY/logo-owl.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'LEONIDAS',
    name: 'Leonidas Token',
    address: '7puG5H5Mc6QpvaXjAVLr6GnL5hhUMnpLcUm8G3mEsgHQ',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7puG5H5Mc6QpvaXjAVLr6GnL5hhUMnpLcUm8G3mEsgHQ/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'SHIB',
    name: 'SHIBA INU (Wormhole)',
    address: 'CiKu4eHsVrc1eueVQeHn7qhXTcVu95gSQmBpX4utjL9z',
    decimals: 8,
    logoURI: 'https://cloudflare-ipfs.com/ipfs/QmU8BZDCVQuVGyX25ApGGkRy2KKG2QDqe4UhKrxYbNMjwr/',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'AXSet',
    name: 'Axie Infinity Shard (Wormhole from Ethereum)',
    address: 'HysWcbHiYY9888pHbaqhwLYZQeZrcQMXKQWRqS7zcPK5',
    decimals: 8,
    logoURI: 'https://cloudflare-ipfs.com/ipfs/QmVUzbiJP2xm2rH69Y42rmTxD8MZxEpGmvdfVKe551zZcR/',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'DYDX',
    name: 'dYdX (Wormhole)',
    address: '4Hx6Bj56eGyw8EJrrheM6LBQAvVYRikYCWsALeTrwyRU',
    decimals: 8,
    logoURI: 'https://cloudflare-ipfs.com/ipfs/QmWvzRABL7bNs3wjMy6vjvaZs3525XwQDaPspibkqwa1wQ/',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'MATICpo',
    name: 'Matic (Wormhole from Polygon)',
    address: 'Gz7VkD4MacbEB6yC5XD3HcumEiYx2EtDYYrfikGsvopG',
    decimals: 8,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/C7NNPWuZCNjZBfW5p6JvGsR8pUdsRpEdP1ZAhnoDwj7h/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'BUSDbs',
    name: 'BUSD Token (Wormhole from BSC)',
    address: '5RpUwQ8wtdPCZHhu6MERp2RGrpobsbZ6MH5dDHkUjs2',
    decimals: 8,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5RpUwQ8wtdPCZHhu6MERp2RGrpobsbZ6MH5dDHkUjs2/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'WAGMI',
    name: 'WAGMI',
    address: '3m7A2A8HHdqmiDrjAfaddj7Hxd88FrBHA1KSoqjoELtu',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3m7A2A8HHdqmiDrjAfaddj7Hxd88FrBHA1KSoqjoELtu/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'SLT',
    name: 'Solit',
    address: 'SLT3iSYKeBuCyxvnfij4RUhMfKxZCY3s12Z5pfkTXhV',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SLT3iSYKeBuCyxvnfij4RUhMfKxZCY3s12Z5pfkTXhV/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'THUG',
    name: 'Fraktionalized THUG 2856',
    address: '7osS84AkAG2TCrUvrE1wfKwfAqWTCrHnaCsrsyVJd5pY',
    decimals: 3,
    logoURI: 'https://arweave.net/q4h6GvG6MQfhXXNJTbLILbZY1OIgLqkJNHzNLClHDiw',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'SCT',
    name: 'SolClout',
    address: '4Te4KJgjtnZe4aE2zne8G4NPfrPjCwDmaiEx9rKnyDVZ',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4Te4KJgjtnZe4aE2zne8G4NPfrPjCwDmaiEx9rKnyDVZ/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'PRMS',
    name: 'Parametrise',
    address: 'A98UDy7z8MfmWnTQt6cKjje7UfqV3pTLf4yEbuwL2HrH',
    decimals: 9,
    logoURI: 'https://raw.githubusercontent.com/parametrise/ParametriseLogo/main/Parametrise_Logomark_Dark.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'PEACH',
    name: 'PEACHO TOKEN',
    address: '6StzwSrFeQEkF2xwADqdoz63RXR8dftf9BZnk91o52rm',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6StzwSrFeQEkF2xwADqdoz63RXR8dftf9BZnk91o52rm/peach.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'MANA',
    name: 'Decentraland (Wormhole)',
    address: '7dgHoN8wBZCc5wbnQ2C47TDnBMAxG4Q5L3KjP67z8kNi',
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/878/thumb/decentraland-mana.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'SAND',
    name: 'Sandbox (Wormhole)',
    address: '49c7WuCZkQgc3M4qH8WuEUNXfgwupZf1xqWkDQ7gjRGt',
    decimals: 8,
    logoURI: 'https://gemini.com/images/currencies/icons/default/sand.svg',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'PSY',
    name: 'PSY Coin',
    address: '49jpm8SpyTwaGaJfUa4AmU28hmW1HoKuqzXkgykysowU',
    decimals: 9,
    logoURI: 'https://raw.githubusercontent.com/charliebakerr/psy-token/main/psycoinlogo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'SOLBEAR',
    name: 'Solar Bear',
    address: 'DktNJUJAWJyeLw3ykCkFNpGohE24SoEhevKBskRi6P1y',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DktNJUJAWJyeLw3ykCkFNpGohE24SoEhevKBskRi6P1y/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'SOLV',
    name: 'SOLVIEW',
    address: '7q3AdgKuMeDRnjaMQs7ppXjaw4HUxjsdyMrrfiSZraiN',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7q3AdgKuMeDRnjaMQs7ppXjaw4HUxjsdyMrrfiSZraiN/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'SVIZ',
    name: 'Space Vizsla',
    address: 'GV6n9Uow3XzMWSs8vwTCML8SvMA6ozbidaEfdPoSoraQ',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GV6n9Uow3XzMWSs8vwTCML8SvMA6ozbidaEfdPoSoraQ/VizslaMoonLogo100x100.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'MIX',
    name: 'MIX',
    address: '4oZyezadeP4KdskT3oDXWFR6Nsado4rGanaH6p6wNR3P',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4oZyezadeP4KdskT3oDXWFR6Nsado4rGanaH6p6wNR3P/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'GENE',
    name: 'Genopets',
    address: 'GENEtH5amGSi8kHAtQoezp1XEXwZJ8vcuePYnXdKrMYz',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GENEtH5amGSi8kHAtQoezp1XEXwZJ8vcuePYnXdKrMYz/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'GAMESHIB',
    name: 'GAME SHIB COIN',
    address: 'AMzb4Tc7gDGHrsz1zUQzjtmQS2AXWuejveAKXKSpsoPU',
    decimals: 9,
    logoURI: 'https://gameshib.com/images/gameshib420.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'SONAR',
    name: 'Sonar Watch',
    address: 'sonarX4VtVkQemriJeLm6CKeW3GDMyiBnnAEMw1MRAE',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/sonarX4VtVkQemriJeLm6CKeW3GDMyiBnnAEMw1MRAE/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'BITXBIT',
    name: 'BITXBIT',
    address: 'DK6PWMyuZ4NMjsm9AWNCTMKrajQYrtfMjMJ3QauX2UH5',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DK6PWMyuZ4NMjsm9AWNCTMKrajQYrtfMjMJ3QauX2UH5/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'Potion',
    name: 'Potion',
    address: 'GtHxqAqbaZB8eo8R8pGXUhWxs6X8WQWMWTUWKTgSFbHo',
    decimals: 6,
    logoURI: 'https://i.imgur.com/zURNQAX.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'KSOL',
    name: 'KITTYCOIN SOL',
    address: 'CBPfSGeSf76o3r4628k7BcZ5YBNxHh7hkCzu4AmVgk2Q',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CBPfSGeSf76o3r4628k7BcZ5YBNxHh7hkCzu4AmVgk2Q/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'SHIBU',
    name: 'SHIBU Token',
    address: '3iBZV8gvUFxp333FFogUPVi6MP9dEZ74xUxVzEQvNPii',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/shibuio/token-list/main/assets/mainnet/3iBZV8gvUFxp333FFogUPVi6MP9dEZ74xUxVzEQvNPii/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'CCC',
    name: 'Chairman Chow',
    address: 'E9bjYSAqabYAd2Zaev4qAMVNAiX7Z2Dp4Sn1JgsTn2b6',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/E9bjYSAqabYAd2Zaev4qAMVNAiX7Z2Dp4Sn1JgsTn2b6/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'VI',
    name: 'VI',
    address: '7zBWymxbZt7PVHQzfi3i85frc1YRiQc23K7bh3gos8ZC',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7zBWymxbZt7PVHQzfi3i85frc1YRiQc23K7bh3gos8ZC/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'GM',
    name: 'GM Solana',
    address: 'CmSryDa4mnDYUicq7qSESsTKAdgBP26jSYcg8zavVoJd',
    decimals: 7,
    logoURI: 'https://cdn.jsdelivr.net/gh/brendanhernou/gm/GMSolana.jpg',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'ICE',
    name: 'Fancy Diamond ICE',
    address: 'icex2Fy2KtXjfiAAUEHLPHu7XKDLvwiyVUPP9PNpSkF',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/icex2Fy2KtXjfiAAUEHLPHu7XKDLvwiyVUPP9PNpSkF/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'JUNGLE',
    name: 'Jungle',
    address: 'Aogv6j1wWiBAZcqRNN1Y89eozda2ke6rkc4CYy7c4iCi',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Aogv6j1wWiBAZcqRNN1Y89eozda2ke6rkc4CYy7c4iCi/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'Solar',
    name: 'Solar',
    address: '2wmKXX1xsxLfrvjEPrt2UHiqj8Gbzwxvffr9qmNjsw8g',
    decimals: 9,
    logoURI: 'https://raw.githubusercontent.com/SolarDexOfficial/solar_token_icon/main/token-icon.jpeg',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'REAL',
    name: 'Realy Token',
    address: 'AD27ov5fVU2XzwsbvnFvb1JpCBaCB5dRXrczV9CqSVGb',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AD27ov5fVU2XzwsbvnFvb1JpCBaCB5dRXrczV9CqSVGb/logo.svg',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'NXDF',
    name: 'NeXt-DeFi Protocol',
    address: 'Au6EdrSDubCUc34awy9c6iQAg5GSos9pPBXyZQtyZewV',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Au6EdrSDubCUc34awy9c6iQAg5GSos9pPBXyZQtyZewV/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'ROAR',
    name: 'Roar Token',
    address: 'DqxzPWQ2FKHn8pRoy9jCpA6M3GkEqYfieiAVwMYWVyXr',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DqxzPWQ2FKHn8pRoy9jCpA6M3GkEqYfieiAVwMYWVyXr/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'APT',
    name: 'Apricot',
    address: 'APTtJyaRX5yGTsJU522N4VYWg3vCvSb65eam5GrPT5Rt',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/APTtJyaRX5yGTsJU522N4VYWg3vCvSb65eam5GrPT5Rt/logo.svg',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'FIRE',
    name: 'Solfire Finance',
    address: 'AfXLBfMZd32pN6QauazHCd7diEWoBgw1GNUALDw3suVZ',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AfXLBfMZd32pN6QauazHCd7diEWoBgw1GNUALDw3suVZ/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'THECA',
    name: 'Theca',
    address: 'D3cm6WRnyBct3p7vFqyTt2CaynsGPuVQT2zW6WHSTX6q',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/D3cm6WRnyBct3p7vFqyTt2CaynsGPuVQT2zW6WHSTX6q/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'SGG',
    name: 'Solx Gaming Guild',
    address: '3eLpKZBgu6pKG2TSpvTfTeeimT294yxV2AEiBKZdY2ai',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3eLpKZBgu6pKG2TSpvTfTeeimT294yxV2AEiBKZdY2ai/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'SINGULARITY',
    name: 'Singularity',
    address: '3xXMjiMyu4hthrVWmsxvBrKtehBWFgSKRnGB9Je4mmdA',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3xXMjiMyu4hthrVWmsxvBrKtehBWFgSKRnGB9Je4mmdA/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: '1SOL',
    name: '1sol.io Token',
    address: '4ThReWAbAVZjNVgs5Ui9Pk3cZ5TYaD9u6Y89fp6EFzoF',
    decimals: 8,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4ThReWAbAVZjNVgs5Ui9Pk3cZ5TYaD9u6Y89fp6EFzoF/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'SWERVE',
    name: 'Swerve Protocol',
    address: '45ojchnvC3agGNvs86MWBq8N4miiTY6X8ECQzgQNDE4v',
    decimals: 9,
    logoURI: 'https://raw.githubusercontent.com/swervesolana/swerve-assets/main/logo_400x400.jpg',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'CAPY',
    name: 'Capybara',
    address: 'CAPYD6Lrm7bTZ6C7t7JvSxvpEcfKQ9YNB7kUjh6p6XBN',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CAPYD6Lrm7bTZ6C7t7JvSxvpEcfKQ9YNB7kUjh6p6XBN/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'MIMO',
    name: 'Million Monke',
    address: '9TE7ebz1dsFo1uQ2T4oYAKSm39Y6fWuHrd6Uk6XaiD16',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9TE7ebz1dsFo1uQ2T4oYAKSm39Y6fWuHrd6Uk6XaiD16/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'SHIBT',
    name: 'SHIBA LIGHT',
    address: '2946ofy854iifvXCQmHX2AJgxRBoQcchy1gfD26RtkHp',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2946ofy854iifvXCQmHX2AJgxRBoQcchy1gfD26RtkHp/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'POLE',
    name: 'Pole Aggregator Governance Token',
    address: 'PoLEXM5xikzieXENFvP7WwHJPorcFiCUVuMo9BAGZT3',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/PoLEXM5xikzieXENFvP7WwHJPorcFiCUVuMo9BAGZT3/logo.svg',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'VITAL',
    name: 'VITAL Token',
    address: '2FKuYE5D75e9Fjg3ymGBrFfVc8tVKac4SeyvZn5dGNUz',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2FKuYE5D75e9Fjg3ymGBrFfVc8tVKac4SeyvZn5dGNUz/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'CASH',
    name: 'Cashio Dollar',
    address: 'CASHVDm2wsJXfhj6VWxb7GiMdoLc17Du7paH4bNr5woT',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CASHVDm2wsJXfhj6VWxb7GiMdoLc17Du7paH4bNr5woT/icon.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'COW',
    name: 'Cash Cow',
    address: 'CowKesoLUaHSbAMaUxJUj7eodHHsaLsS65cy8NFyRDGP',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CowKesoLUaHSbAMaUxJUj7eodHHsaLsS65cy8NFyRDGP/icon.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'DJN',
    name: 'Fenix Danjon',
    address: 'GnzxEyULVPQYb5F5hxGc8dEGivctVrfr5mtsdp4z5xU2',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GnzxEyULVPQYb5F5hxGc8dEGivctVrfr5mtsdp4z5xU2/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'DFL',
    name: 'DeFi Land',
    address: 'DFL1zNkaGPWm1BqAVqRjCZvHmwTFrEaJtbzJWgseoNJh',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DFL1zNkaGPWm1BqAVqRjCZvHmwTFrEaJtbzJWgseoNJh/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'STARS',
    name: 'StarLaunch',
    address: 'HCgybxq5Upy8Mccihrp7EsmwwFqYZtrHrsmsKwtGXLgW',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HCgybxq5Upy8Mccihrp7EsmwwFqYZtrHrsmsKwtGXLgW/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'sUSDC-8',
    name: 'Saber Wrapped USD Coin (8 decimals)',
    address: '88881Hu2jGMfCs9tMu5Rr7Ah7WBNBuXqde4nR5ZmKYYy',
    decimals: 8,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/88881Hu2jGMfCs9tMu5Rr7Ah7WBNBuXqde4nR5ZmKYYy/icon.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'sBTC-9',
    name: 'Saber Wrapped Bitcoin (Sollet) (9 decimals)',
    address: '9999j2A8sXUtHtDoQdk528oVzhaKBsXyRGZ67FKGoi7H',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9999j2A8sXUtHtDoQdk528oVzhaKBsXyRGZ67FKGoi7H/icon.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'sUSDT-9',
    name: 'Saber Wrapped USDT (9 decimals)',
    address: 'AEUT5uFm1D575FVCoQd5Yq891FJEqkncZUbBFoFcAhTV',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AEUT5uFm1D575FVCoQd5Yq891FJEqkncZUbBFoFcAhTV/icon.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'srenBTC-10',
    name: 'Saber Wrapped renBTC (10 decimals)',
    address: 'BtX7AfzEJLnU8KQR1AgHrhGH5s2AHUTbfjhUQP8BhPvi',
    decimals: 10,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BtX7AfzEJLnU8KQR1AgHrhGH5s2AHUTbfjhUQP8BhPvi/icon.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'sCASH-9',
    name: 'Saber Wrapped CASH (9 decimals)',
    address: 'C9xqJe3gMTUDKidZsZ6jJ7tL9zSLimDUKVpgUbLZnNbi',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/C9xqJe3gMTUDKidZsZ6jJ7tL9zSLimDUKVpgUbLZnNbi/icon.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'srenBTC-9',
    name: 'Saber Wrapped renBTC (9 decimals)',
    address: 'FACTQhZBfRzC7A76antnpAoZtiwYmUfdAN8wz7e8rxC5',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FACTQhZBfRzC7A76antnpAoZtiwYmUfdAN8wz7e8rxC5/icon.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'swFTT-9',
    name: 'Saber Wrapped FTT (Wormhole) (9 decimals)',
    address: 'FTT9GrHBVHvDeUTgLU8FxVJouGqg9uiWGmmjETdm32Sx',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FTT9GrHBVHvDeUTgLU8FxVJouGqg9uiWGmmjETdm32Sx/icon.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'sFTT-9',
    name: 'Saber Wrapped Wrapped FTT (Sollet) (9 decimals)',
    address: 'FTT9rBBrYwcHam4qLvkzzzhrsihYMbZ3k6wJbdoahxAt',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FTT9rBBrYwcHam4qLvkzzzhrsihYMbZ3k6wJbdoahxAt/icon.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'sUSDC-9',
    name: 'Saber Wrapped USD Coin (9 decimals)',
    address: 'JEFFSQ3s8T3wKsvp4tnRAsUBW7Cqgnf8ukBZC4C8XBm1',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/JEFFSQ3s8T3wKsvp4tnRAsUBW7Cqgnf8ukBZC4C8XBm1/icon.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'swhETH-9',
    name: 'Saber Wrapped Ether (Wormhole) (9 decimals)',
    address: 'KNVfdSJyq1pRQk9AKKv1g5uyGuk6wpm4WG16Bjuwdma',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/KNVfdSJyq1pRQk9AKKv1g5uyGuk6wpm4WG16Bjuwdma/icon.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'srenLUNA-9',
    name: 'Saber Wrapped renLUNA (9 decimals)',
    address: 'KUANeD8EQvwpT1W7QZDtDqctLEh2FfSTy5pThE9CogT',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/KUANeD8EQvwpT1W7QZDtDqctLEh2FfSTy5pThE9CogT/icon.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'sBTC-8',
    name: 'Saber Wrapped Bitcoin (Sollet) (8 decimals)',
    address: 'SBTCB6pWqeDo6zGi9WVRMLCsKsN6JiR1RMUqvLtgSRv',
    decimals: 8,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SBTCB6pWqeDo6zGi9WVRMLCsKsN6JiR1RMUqvLtgSRv/icon.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'sETH-8',
    name: 'Saber Wrapped Ethereum (Sollet) (8 decimals)',
    address: 'SL819j8K9FuFPL84UepVcFkEZqDUUvVzwDmJjCHySYj',
    decimals: 8,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SL819j8K9FuFPL84UepVcFkEZqDUUvVzwDmJjCHySYj/icon.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'SHROOMZ',
    name: 'Crypto Mushroomz',
    address: '2vRgBSJEVPXxayrhXoazQyCKSGFYQG3ZdfT2Gv5gZykL',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2vRgBSJEVPXxayrhXoazQyCKSGFYQG3ZdfT2Gv5gZykL/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'NANA',
    name: 'NANA',
    address: '6uZ7MRGGf3FJhzk9TUk3QRMR2fz83WY9BEVBukRvMRVX',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6uZ7MRGGf3FJhzk9TUk3QRMR2fz83WY9BEVBukRvMRVX/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: '42',
    name: '42',
    address: '8Wmonq7dhFJXuHqFCcVgWTmmUPCBC4C6J5xbB5HhGb6n',
    decimals: 9,
    logoURI: 'https://cdn.jsdelivr.net/gh/fortytwotoken/42token/forty-two.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'TTT',
    name: 'TabTrader Token',
    address: 'FNFKRV3V8DtA3gVJN6UshMiLGYA8izxFwkNWmJbFjmRj',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FNFKRV3V8DtA3gVJN6UshMiLGYA8izxFwkNWmJbFjmRj/logo.svg',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'abUSDT',
    name: 'Wrapped USDT (Allbridge from BSC)',
    address: 'E77cpQ4VncGmcAXX16LHFFzNBEBb2U7Ar7LBmZNfCgwL',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BQcdHdAQW1hczDbBi9hiegXAR7A98Q9jx3X3iBBBDiq4/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'abUSDC',
    name: 'Wrapped USDC (Allbridge from BSC)',
    address: '8XSsNvaKU9FDhYWAv7Yc7qSNwuJSzVrXBNEk7AFiWF69',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BXXkv6z8ykpG1yuvUDPgh732wzVHB69RnB9YgSYh3itW/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'abBTCB',
    name: 'Wrapped BTC (Allbridge from BSC)',
    address: '3os2M3bX9qta154PRbU9rzaPUYAKAqVpaMMS8u2hoUQu',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/qfnqNqs3nCAHjnyCgLRDbBtq4p2MtHZxw8YjSyYhPoL/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'abETH',
    name: 'Wrapped ETH (Allbridge from BSC)',
    address: 'EyrnrbE5ujd3HQG5PZd9MbECN9yaQrqc8pRwGtaLoyC',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FeGn77dhg1KXRRFeSwwMiykZnZPw5JXW6naf2aQgZDQf/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'DAWG',
    name: 'DAWG',
    address: '3DHPqxdMXogNNnpqBMF8N4Zs4dn1WR31H7UjWq6FExwG',
    decimals: 9,
    logoURI: 'https://user-images.githubusercontent.com/15795037/143055147-72513c02-be9b-4639-bb30-3c1c037cb030.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'RLB',
    name: 'Rollbit Coin',
    address: 'RLBxxFkseAZ4RgJH3Sqn8jXxhmGoz9jWxDNJMh8pL7a',
    decimals: 2,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/RLBxxFkseAZ4RgJH3Sqn8jXxhmGoz9jWxDNJMh8pL7a/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'FLOCK',
    name: 'Flock',
    address: 'Hq9MuLDvUAWqC29JhqP2CUJP9879LfqNBHyRRREEXwtZ',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Hq9MuLDvUAWqC29JhqP2CUJP9879LfqNBHyRRREEXwtZ/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'pUSDC',
    name: 'Port Finance USDC',
    address: 'FgSsGV8GByPaMERxeQJPvZRZHf7zCBhrdYtztKorJS58',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FgSsGV8GByPaMERxeQJPvZRZHf7zCBhrdYtztKorJS58/USDC.svg',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'pUSDT',
    name: 'Port Finance USDT',
    address: '3RudPTAkfcq9Q9Jk8SVeCoecCBmdKMj6q5smsWzxqtqZ',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3RudPTAkfcq9Q9Jk8SVeCoecCBmdKMj6q5smsWzxqtqZ/USDT.svg',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'SamoShib',
    name: 'Samo Shib',
    address: 'BDCyYhw4zvf8wx3e8rXcrcYnmsX5BxF7R6g5ooXqu3uL',
    decimals: SupportedChainId.SOLANA,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BDCyYhw4zvf8wx3e8rXcrcYnmsX5BxF7R6g5ooXqu3uL/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'sUSDT-8',
    name: 'Saber Wrapped USDT (8 decimals)',
    address: 'T8KdT8hDzNhbGx5sjpEUxepnbDB1TZoCa7vtC5JjsMw',
    decimals: 8,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/T8KdT8hDzNhbGx5sjpEUxepnbDB1TZoCa7vtC5JjsMw/icon.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'GKC',
    name: 'Ghost Coin',
    address: '7MirouXpJ1J9wYT3jB9xSp8GKwjx9fJ2hHut5HxWxdLa',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7MirouXpJ1J9wYT3jB9xSp8GKwjx9fJ2hHut5HxWxdLa/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'CEX',
    name: 'Catena X',
    address: '21vatMcwZz53Eu2EUDCS9xoZUXdJ9ABMTQYNMKKkzoNW',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/21vatMcwZz53Eu2EUDCS9xoZUXdJ9ABMTQYNMKKkzoNW/Logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'XTAG',
    name: 'xHashtag Token',
    address: '5gs8nf4wojB5EXgDUWNLwXpknzgV2YWDhveAeBZpVLbp',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5gs8nf4wojB5EXgDUWNLwXpknzgV2YWDhveAeBZpVLbp/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'TRYB',
    name: 'BiLira',
    address: 'A94X2fRy3wydNShU4dRaDyap2UuoeWJGWyATtyp61WZf',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/A94X2fRy3wydNShU4dRaDyap2UuoeWJGWyATtyp61WZf/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'YORK',
    name: 'Yorkipoo',
    address: '5K1JtWpdSksVKaL6R2DuLpCDAjzxK6sq2CpXaXDWHVLg',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5K1JtWpdSksVKaL6R2DuLpCDAjzxK6sq2CpXaXDWHVLg/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'TWT',
    name: 'Trust Wallet (Wormhole)',
    address: 'HZNpqL7RT9gxf9eWoWsWzC5DfjzQ41XTQgEA7p3VzaaD',
    decimals: 8,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HZNpqL7RT9gxf9eWoWsWzC5DfjzQ41XTQgEA7p3VzaaD/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'CRYY',
    name: 'CRY Coin',
    address: '56tNQ29XBrbovm5K5SThuQatjCy92w2wKUaUeQ8WCD9g',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/56tNQ29XBrbovm5K5SThuQatjCy92w2wKUaUeQ8WCD9g/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'MOONRACE',
    name: 'Moonrace',
    address: 'vqU8NVkkgpFtt3YECwuQRD3RhX7LYaqZKrotZbdiBJn',
    decimals: 3,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/vqU8NVkkgpFtt3YECwuQRD3RhX7LYaqZKrotZbdiBJn/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'BBY',
    name: 'BabylonDAO',
    address: '5SZSVgnQDgKKxtCe3UA3x4T7tcSRNDaL3NmfdEqpuLzo',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5SZSVgnQDgKKxtCe3UA3x4T7tcSRNDaL3NmfdEqpuLzo/logo.jpg',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'aeMIM',
    name: 'Wrapped MIM (Allbridge from Ethereum)',
    address: '2ASbApnFVSTp2RJvMLgLVfbDwJvu1FRXdhJWrGs89Lhj',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2ASbApnFVSTp2RJvMLgLVfbDwJvu1FRXdhJWrGs89Lhj/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'aeFEI',
    name: 'Wrapped FEI (Allbridge from Ethereum)',
    address: 'BAexggGFsiLCKr17cSZF12wkHd8BkR1DBhzuSb78WTR2',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BAexggGFsiLCKr17cSZF12wkHd8BkR1DBhzuSb78WTR2/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'afBTC',
    name: 'Wrapped BTC (Allbridge from Fantom)',
    address: 'FdvkkCbCgYht1xTR1W9MBJhEF1JEPVhHtW1NXBYRzZts',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/qfnqNqs3nCAHjnyCgLRDbBtq4p2MtHZxw8YjSyYhPoL/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'afETH',
    name: 'Wrapped ETH (Allbridge from Fantom)',
    address: 'BiryxNvVTABRs3pEE4fvVuu4d17aAYEsNmcPnJ8E8WeT',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FeGn77dhg1KXRRFeSwwMiykZnZPw5JXW6naf2aQgZDQf/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'afUSDC',
    name: 'Wrapped USDC (Allbridge from Fantom)',
    address: 'Grk6b4UMRWkgyq4Y6S1BnNRF4hRgtnMFp7Sorkv6Ez4u',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BXXkv6z8ykpG1yuvUDPgh732wzVHB69RnB9YgSYh3itW/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'afDAI',
    name: 'Wrapped DAI (Allbridge from Fantom)',
    address: 'HjUhUzi6fVkY1BndaSc4Dcg2mCzvnqzXjVJtXsj78ver',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FYpdBuyAHSbdaAyD1sKkxyLWbAP8uUW9h6uvdhK74ij1/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: '$CULT',
    name: 'Culture Coin',
    address: '9qTA3A113oG94ppSpiJTwWCyj44wyNcgPAs5i9d7QQne',
    decimals: 2,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9qTA3A113oG94ppSpiJTwWCyj44wyNcgPAs5i9d7QQne/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: '$KSH',
    name: 'Keeshond Coin',
    address: '6j14WyX1Ag2pLWvn99euK4xp2VcZD62VeJv2iwCrYmT8',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6j14WyX1Ag2pLWvn99euK4xp2VcZD62VeJv2iwCrYmT8/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'PIXL',
    name: 'Pixels.so Token',
    address: '5L2YboFbHAUpBDDJjvDB5M6pu9CW2FRjyDB2asZyvjtE',
    decimals: 8,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5L2YboFbHAUpBDDJjvDB5M6pu9CW2FRjyDB2asZyvjtE/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'EYE',
    name: 'NftEyez Coin',
    address: 'G7eETAaUzmsBPKhokZyfbaT4tD9igdZSmfQGEYWem8Sw',
    decimals: 6,
    logoURI: 'https://raw.githubusercontent.com/NftEyez/assets/main/assets/eye-coin.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'FREN',
    name: 'SOL Frens',
    address: '7xd71KP4HwQ4sM936xL8JQZCwE4amUko1AdCCf6Znjrt',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7xd71KP4HwQ4sM936xL8JQZCwE4amUko1AdCCf6Znjrt/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'CHICKS',
    name: 'SolChicks',
    address: 'cxxShYRVcepDudXhe7U62QHvw8uBJoKFifmzggGKVC2',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/cxxShYRVcepDudXhe7U62QHvw8uBJoKFifmzggGKVC2/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'MARIO',
    name: 'Super Mario',
    address: '7rmV64vLfbrbS5rTFvojYWzCVEn8dnJ9RfSRx3nD6C5E',
    decimals: SupportedChainId.SOLANA,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7rmV64vLfbrbS5rTFvojYWzCVEn8dnJ9RfSRx3nD6C5E/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'BTCNFT',
    name: 'Bitcoin NFT Coin',
    address: 'iobVLW6ePUrcX7Y4DzW69QDpQt2CkJ6c3SZmaLozibj',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/iobVLW6ePUrcX7Y4DzW69QDpQt2CkJ6c3SZmaLozibj/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'PUFF',
    name: 'PUFF',
    address: 'G9tt98aYSznRk7jWsfuz9FnTdokxS6Brohdo9hSmjTRB',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/G9tt98aYSznRk7jWsfuz9FnTdokxS6Brohdo9hSmjTRB/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'BANA',
    name: 'Shibana',
    address: 'BhPXDQio8xtNC6k5Bg5fnUVL9kGN8uvRDNwW8MZBu8DL',
    decimals: SupportedChainId.SOLANA,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BhPXDQio8xtNC6k5Bg5fnUVL9kGN8uvRDNwW8MZBu8DL/Shibana.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'RACEFI',
    name: 'RaceFi Token',
    address: 'AAmGoPDFLG6bE82BgZWjVi8k95tj9Tf3vUN7WvtUm2BU',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AAmGoPDFLG6bE82BgZWjVi8k95tj9Tf3vUN7WvtUm2BU/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'GST',
    name: 'GST',
    address: 'AFbX8oGjGpmVFywbVouvhQSRmiW2aR1mohfahi4Y2AdB',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AFbX8oGjGpmVFywbVouvhQSRmiW2aR1mohfahi4Y2AdB/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'UNQ',
    name: 'UNQ',
    address: 'UNQtEecZ5Zb4gSSVHCAWUQEoNnSVEbWiKCi1v9kdUJJ',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/UNQtEecZ5Zb4gSSVHCAWUQEoNnSVEbWiKCi1v9kdUJJ/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'RUN',
    name: 'Run Token',
    address: '6F9XriABHfWhit6zmMUYAQBSy6XK5VF1cHXuW5LDpRtC',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6F9XriABHfWhit6zmMUYAQBSy6XK5VF1cHXuW5LDpRtC/RunGear.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'PSK',
    name: 'Pesky Penguins Droplet',
    address: 'pskJRUNzJbVu4RaZSUJYfvaTNXmFdRCutegL6P2Y9tG',
    decimals: 8,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/pskJRUNzJbVu4RaZSUJYfvaTNXmFdRCutegL6P2Y9tG/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'ZEN',
    name: 'ZEN',
    address: 'DYQ3oKHyF8DVDr2gm3tLmFzuQ4HTfrE78NvmCh3WJ1XC',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DYQ3oKHyF8DVDr2gm3tLmFzuQ4HTfrE78NvmCh3WJ1XC/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'FLW',
    name: 'Flow Coin',
    address: '7tE99RKS4RwQxEjvZfh4CQMoQMMTRdL5KQbugsfhNYPg',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7tE99RKS4RwQxEjvZfh4CQMoQMMTRdL5KQbugsfhNYPg/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'TUT',
    name: 'TUT',
    address: '6wShYhqA2gs3HUAZ4MyaPDpKPBWFJUQQUGaCoy2k1Tgz',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6wShYhqA2gs3HUAZ4MyaPDpKPBWFJUQQUGaCoy2k1Tgz/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'XMAS',
    name: 'XMAS DAO',
    address: 'CKGZzeufghDK7Sekk4MnP34m1TuEkmhGU25rs6YGeHdP',
    decimals: 1,
    logoURI: 'https://i.postimg.cc/d1V9LHsz/L1032992.jpg',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'SLCAT',
    name: 'Salad Cat Coin',
    address: '5rFmh8C6Zj1VfL7ogB6PyVDnQkqsayvEPsEbbkUPX8f',
    decimals: SupportedChainId.SOLANA,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5rFmh8C6Zj1VfL7ogB6PyVDnQkqsayvEPsEbbkUPX8f/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'BLOCK',
    name: 'Blockasset',
    address: 'NFTUkR4u7wKxy9QLaX2TGvd9oZSWoMo4jqSJqdMb7Nk',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/NFTUkR4u7wKxy9QLaX2TGvd9oZSWoMo4jqSJqdMb7Nk/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'JUUJUU',
    name: 'JUUJUU',
    address: 'zmFoYNC3CuGY1VmgAcxXzfLMnSMVZpJF6RGJU5vDxvT',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/zmFoYNC3CuGY1VmgAcxXzfLMnSMVZpJF6RGJU5vDxvT/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'FOSSIL',
    name: 'FOSSIL',
    address: '6xcfmgzPgABAuAfGDhvvLLMfMDur4at7tU7j3NudUviK',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6xcfmgzPgABAuAfGDhvvLLMfMDur4at7tU7j3NudUviK/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'DAOJONES',
    name: 'Fractionalized SMB-2367',
    address: 'r8nuuzXCchjtqsmQZVZDPXXq928tuk7KVH479GsKVpy',
    decimals: 2,
    logoURI: 'https://arweave.net/ncRp795w-ca_Xb5zkUBtBmxSQ_bcYA49E03NtMoHJMg',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'MCK',
    name: 'Mickey',
    address: 'E48Ueg1o9avL5s7XBjfLViercSrNSJCvmbZMvnwN873',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/E48Ueg1o9avL5s7XBjfLViercSrNSJCvmbZMvnwN873/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'BACON',
    name: 'BACON tokens',
    address: 'FMBfAxhiTwDmujiEGbexFtExHR9q7nqnRF1Rjd5UmhS7',
    decimals: 9,
    logoURI:
      'https://zplphm7g437efyuli2psx6tmh4nf7ux6zf3iw65xnk5zjltaomca.arweave.net/y9bzs-bm_kLii0afK_psPxpf0v7Jdot7t2q7lK5gcwQ',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'FLVR',
    name: 'Flokiverse',
    address: '3vDfXEw3MZQgjYpLbjoZDYmgVci16CsC6ZDLgUzmcKR2',
    decimals: 9,
    logoURI: 'https://cdn.jsdelivr.net/gh/DaveBane/Flokiverse/Flokiverselogo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'N2H4',
    name: 'Hydrazine',
    address: '4q5UBXJxE91BZKX548qhU8i5QBWvZdXzS3RZwfTgLQda',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4q5UBXJxE91BZKX548qhU8i5QBWvZdXzS3RZwfTgLQda/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'GOD',
    name: 'GOD',
    address: '9kt93AW5QMjFL6ZxomnSq3FbWiU5ibNeTSgBz9UDFSB6',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9kt93AW5QMjFL6ZxomnSq3FbWiU5ibNeTSgBz9UDFSB6/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'TRYB',
    name: 'BiLira (Wormhole)',
    address: '9QBTKuSCDaJjtxYnYcVzoiKENMdJ5DRei5ZUCEeWyZnj',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9QBTKuSCDaJjtxYnYcVzoiKENMdJ5DRei5ZUCEeWyZnj/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'daoSOL',
    name: 'daoSOL Token',
    address: 'GEJpt3Wjmr628FqXxTgxMce1pLntcPV4uFi8ksxMyPQh',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GEJpt3Wjmr628FqXxTgxMce1pLntcPV4uFi8ksxMyPQh/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'SBABYDOGE',
    name: 'SOL BABAY DOGE COIN',
    address: 'BABYsocP6cB95xvBDXnjXKX96VBNC37dmNWUtaV9Jk6v',
    decimals: 2,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BABYsocP6cB95xvBDXnjXKX96VBNC37dmNWUtaV9Jk6v/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'Hono',
    name: 'Hono',
    address: 'BGN9c9JJxMgmm7rUqeLanYwWwo2GbedjUFaXn7tAeuXK',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BGN9c9JJxMgmm7rUqeLanYwWwo2GbedjUFaXn7tAeuXK/hono.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'FANT',
    name: 'Phantasia',
    address: 'FANTafPFBAt93BNJVpdu25pGPmca3RfwdsDsRrT3LX1r',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FANTafPFBAt93BNJVpdu25pGPmca3RfwdsDsRrT3LX1r/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'PAW',
    name: 'CopyCats token',
    address: '3WV4fTWGvtWNvQb8oVU4t99By8KztDLtExqHnkPfHAA9',
    decimals: 9,
    logoURI: 'https://imagedelivery.net/aAmca-Po70V1nH4n9cT-uA/98c9b68b-1494-43d1-0c2c-31e5d0129a00/public',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'CRWNY',
    name: 'Crowny token',
    address: 'CRWNYkqdgvhGGae9CKfNka58j6QQkaD5bLhKXvUYqnc1',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CRWNYkqdgvhGGae9CKfNka58j6QQkaD5bLhKXvUYqnc1/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'McNoot',
    name: 'McNoot',
    address: '2geYxMQ9o466tQ3JitUVR5Xmuk4a11KXs7ZmSX1hiSJp',
    decimals: 9,
    logoURI: 'https://arweave.net/NyYZJt_ItdAhB3Jc4M4j8zrO_Ytz2RrSsuzqcp3YmZ8?ext=png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'BASIS',
    name: 'basis',
    address: 'Basis9oJw9j8cw53oMV7iqsgo6ihi9ALw4QR31rcjUJa',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Basis9oJw9j8cw53oMV7iqsgo6ihi9ALw4QR31rcjUJa/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'ALTAR',
    name: 'Altar',
    address: 'GJQ1iDoPWWo7pXeNKhC9BLD3FHL2tgFEVGMHYGEfQZT',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GJQ1iDoPWWo7pXeNKhC9BLD3FHL2tgFEVGMHYGEfQZT/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'swtUST-9',
    name: 'Saber Wrapped UST (Wormhole) (9 decimals)',
    address: 'UST98bfV6EASdTFQrRwCBczpehdMFwYCUdLT5tEbhpW',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/UST98bfV6EASdTFQrRwCBczpehdMFwYCUdLT5tEbhpW/icon.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'ppUSDC',
    name: 'Port Finance 2022 March Principal USDC',
    address: '6cKnRJskSTonD6kZiWt2Fy3NB6ZND6CbgA3vHiZ1kHEU',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FgSsGV8GByPaMERxeQJPvZRZHf7zCBhrdYtztKorJS58/USDC.svg',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'pyUSDC',
    name: 'Port Finance 2022 March Yield USDC',
    address: 'B64haiHLQoWdrvcJqufRG5dEMms96rDpwuaTjYTihQEo',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FgSsGV8GByPaMERxeQJPvZRZHf7zCBhrdYtztKorJS58/USDC.svg',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'MONGOOSE',
    name: 'Mongoose Coin',
    address: 'J7WYVzFNynk9D28eBCccw2EYkygygiLDCVCabV7CupWL',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/J7WYVzFNynk9D28eBCccw2EYkygygiLDCVCabV7CupWL/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'Zion',
    name: 'Zion',
    address: 'A7rqejP8LKN8syXMr4tvcKjs2iJ4WtZjXNs1e6qP3m9g',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/LousyNFTs/token-list/main/assets/mainnet/A7rqejP8LKN8syXMr4tvcKjs2iJ4WtZjXNs1e6qP3m9g/Ziontoken_2_84.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'SOLNUT',
    name: 'Solana Nut',
    address: 'Fv3ZG56M2cWvF8sy9VWzWyvtHPhugNc1BAzpyoAPvL7r',
    decimals: SupportedChainId.SOLANA,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Fv3ZG56M2cWvF8sy9VWzWyvtHPhugNc1BAzpyoAPvL7r/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'MOUNT',
    name: 'MOUNT',
    address: '9QXAu7FTf7hmswBQwKxvuqGgWH42FyQjqXJanUt6y4eC',
    decimals: 9,
    logoURI: 'https://arweave.net/fy9pbbRbYRg-Y40B34ojIGrb3wxpadQgsiktan1LfKY?ext=png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'MSP',
    name: 'MSP',
    address: '5Z7bWSvcxVeUkroSypFW3Tsw7vPoJUcCxhTFNenLxNoR',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5Z7bWSvcxVeUkroSypFW3Tsw7vPoJUcCxhTFNenLxNoR/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'KROOK',
    name: 'Krook Coin',
    address: 'AfARcLLqRHsZc4xPWHE9nXZAswZaW294Ff1xcYQbjkLq',
    decimals: 9,
    logoURI: 'https://user-images.githubusercontent.com/1009138/143821720-d9c6f5fd-96d7-424f-9b1f-b185451cbb31.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'SHDW',
    name: 'Shadow Token',
    address: 'SHDWyBxihqiCj6YekG2GUr7wqKLeLAMK1gHZck9pL6y',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SHDWyBxihqiCj6YekG2GUr7wqKLeLAMK1gHZck9pL6y/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'CC',
    name: 'Castle Coin',
    address: '3KTkQJEMJXP741EJBFcAS34Lx9t8GsBYaW2BUUWkeyDH',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3KTkQJEMJXP741EJBFcAS34Lx9t8GsBYaW2BUUWkeyDH/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: '$FORCE',
    name: 'Force',
    address: '9MjAmgHXbu5drkNa9XpzfozgsM5Dcq6bSnKZzdNrwscC',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9MjAmgHXbu5drkNa9XpzfozgsM5Dcq6bSnKZzdNrwscC/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'wDingocoin',
    name: 'Wrapped Dingocoin',
    address: '6VYF5jXq6rfq4QRgGMG6co7b1Ev1Lj7KSbHBxfQ9e1L3',
    decimals: 8,
    logoURI: 'https://wrap.dingocoin.org/wDingocoin.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'SANTA',
    name: 'Santaclaus',
    address: 'EctmRn2jMAdTDvQdG7mxadyiTvhGZiGYNrt9PWe6zioG',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EctmRn2jMAdTDvQdG7mxadyiTvhGZiGYNrt9PWe6zioG/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'MEAN',
    name: 'MEAN',
    address: 'MEANeD3XDdUmNMsRGjASkSWdC8prLYsoRJ61pPeHctD',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/MEANeD3XDdUmNMsRGjASkSWdC8prLYsoRJ61pPeHctD/logo.svg',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'CSC',
    name: 'Captain Shiba',
    address: '3PNqq4kEqgRSkV5dYdcc6mtoaoXdaun9ytoCr4BgX5yA',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3PNqq4kEqgRSkV5dYdcc6mtoaoXdaun9ytoCr4BgX5yA/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'DAPE',
    name: 'Degenerate Ape Academy Droplet',
    address: 'dapeM1DJj3xf2rC5o3Gcz1Cg3Rdu2ayZae9nGcsRRZT',
    decimals: 8,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/dapeM1DJj3xf2rC5o3Gcz1Cg3Rdu2ayZae9nGcsRRZT/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'WMP',
    name: 'Whalemap',
    address: 'BygDd5LURoqztD3xETc99WCxLUbTi6WYSht9XiBgZ4HW',
    decimals: 9,
    logoURI: 'https://raw.githubusercontent.com/ssi91/crypto/main/logo.svg',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'NOVA',
    name: 'NOVA FINANCE',
    address: 'BDrL8huis6S5tpmozaAaT5zhE5A7ZBAB2jMMvpKEeF8A',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BDrL8huis6S5tpmozaAaT5zhE5A7ZBAB2jMMvpKEeF8A/logo.svg',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'MKD',
    name: 'Musk Doge',
    address: 'FatneQg39zhrG6XdwYb8fzM4VgybpgqjisJYESSBD7FV',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FatneQg39zhrG6XdwYb8fzM4VgybpgqjisJYESSBD7FV/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'TIGER',
    name: 'TIGER COIN',
    address: '8z4ghJPp3ccvEtuXZbceGRfoX7AZHhcdwiapYzmsxmyC',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8z4ghJPp3ccvEtuXZbceGRfoX7AZHhcdwiapYzmsxmyC/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'Synapses',
    name: 'Synapses',
    address: '7HGJwFyKC5wPLZ9ctgVSgjBARJ4dZrGCjMRQmzWwe277',
    decimals: 9,
    logoURI: 'https://arweave.net/h5NOMoLgFKGBZGOkipic6XFRBdCi7PKWLf5qtm-urzg?ext=png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'SEI',
    name: 'Solanium Ecosystem Index',
    address: 'CRkwd2QedqDi5u6W2w6jeAViAUd1pR4AXs2aKvh7GW7M',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CRkwd2QedqDi5u6W2w6jeAViAUd1pR4AXs2aKvh7GW7M/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'LFGO',
    name: 'MEKKA FROGGO TOKEN',
    address: '7z1eQmEhhM9e1AVCBQc6BzMZWmCZRqHCLJtkDgHxzYnQ',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7z1eQmEhhM9e1AVCBQc6BzMZWmCZRqHCLJtkDgHxzYnQ/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'BAIL',
    name: 'BAIL COIN',
    address: '3e9pHUxa2nvAqso2Kr2KqJxYvZaz9qZLjoLaG77uQwB1',
    decimals: 9,
    logoURI: 'https://raw.githubusercontent.com/ApeLabsNFT/solpatrol/main/hammer.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'PAC',
    name: 'PAC Coin',
    address: '7vKX5rx57VPE1ozJesFzojdPjGZ3M89894PT27i6seUF',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7vKX5rx57VPE1ozJesFzojdPjGZ3M89894PT27i6seUF/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'atUST',
    name: 'Wrapped UST (Allbridge from Terra)',
    address: 'A96PoNcxa9LMxcF9HhKAfA1p3M1dGbubPMWf19gHAkgJ',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CXLBjMMcwkc17GfJtBos6rQCo1ypeH6eDbB82Kby4MRm/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'acEUR',
    name: 'Wrapped CEUR (Allbridge from Celo)',
    address: '7g166TuBmnoHKvS2PEkZx6kREZtbfjUxCHGWjCqoDXZv',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7g166TuBmnoHKvS2PEkZx6kREZtbfjUxCHGWjCqoDXZv/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: '$MINERAL',
    name: 'Mineral Token',
    address: 'FkBRohZpqx2c7zxe5cDhNq3AoFo2nPJus3xtdz9CvQmQ',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FkBRohZpqx2c7zxe5cDhNq3AoFo2nPJus3xtdz9CvQmQ/Mineral.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'SMBD',
    name: 'Solana Monkey Business Droplet',
    address: 'smbdJcLBrtKPhjrWCpDv5ABdJwz2vYo3mm6ojmePL3t',
    decimals: 8,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/smbdJcLBrtKPhjrWCpDv5ABdJwz2vYo3mm6ojmePL3t/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'BABYTIGER',
    name: 'Baby Tiger',
    address: '8JjBJdV73zPPmZvkgC91ni8RsbXWTkhpuSdxeZgaw6hD',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8JjBJdV73zPPmZvkgC91ni8RsbXWTkhpuSdxeZgaw6hD/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'INA',
    name: 'Inanna',
    address: 'HfMVgG3fQr45JtrQD3jpVki6E5H5BSdjN8kCAvDEDKMQ',
    decimals: 8,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/781d71f6cb76d2921c7f101fac3aa3010c627cdc/assets/mainnet/HfMVgG3fQr45JtrQD3jpVki6E5H5BSdjN8kCAvDEDKMQ/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'BRWNDO',
    name: 'BRAWNDO',
    address: 'At5j3zhbEj8mfFsSy1MPbjVhrX2uNmRMPEDZiyFcETNX',
    decimals: SupportedChainId.SOLANA,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/At5j3zhbEj8mfFsSy1MPbjVhrX2uNmRMPEDZiyFcETNX/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'NPC',
    name: 'NPC DAO',
    address: 'EuD5L5XSYKzyDC1YyYzmoWC8gmJhpEh2vMj4f8LeRW8r',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EuD5L5XSYKzyDC1YyYzmoWC8gmJhpEh2vMj4f8LeRW8r/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'SOULO',
    name: 'SouloCoin',
    address: 'Gz3u6eJaKEviYpPC5AwUziz891kNX76PNdsmJrnaNNY4',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Gz3u6eJaKEviYpPC5AwUziz891kNX76PNdsmJrnaNNY4/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'SOLID',
    name: 'Solid Protocol',
    address: 'A5UevXJdphkzXhRtTXj8JyoYYrWnkCLHVS986JHtRLyj',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/A5UevXJdphkzXhRtTXj8JyoYYrWnkCLHVS986JHtRLyj/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'GOOSE',
    name: 'Mongoose',
    address: 'FmpqmVcT4hSUfNhach31YUpf6M75bBYaC59JLMyCVNHH',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FmpqmVcT4hSUfNhach31YUpf6M75bBYaC59JLMyCVNHH/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'TRPY',
    name: 'Trippy Leaf',
    address: 'Fse2oFDfbwT89CqtuoFaHCBnGTMFLartDYDjPLZyc7e',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Fse2oFDfbwT89CqtuoFaHCBnGTMFLartDYDjPLZyc7e/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'QF',
    name: 'QuietFire',
    address: '76ijxiMkj4DX8q9QMtqpzTxFnT4KPmWv47sZf2kKoVwk',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/76ijxiMkj4DX8q9QMtqpzTxFnT4KPmWv47sZf2kKoVwk/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'SWIM',
    name: 'SWIM',
    address: 'JEHHZr57hJ7By3dL74HB9G9R77ZrTvDr1P2vSjQCAewF',
    decimals: 9,
    logoURI: 'https://arweave.net/x5q9Se-3YMgTRaZg8tfHRaQVnGnMbAw7Jd6F-I9fplE?ext=png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'PRISM',
    name: 'PRISM',
    address: 'PRSMNsEPqhGVCH1TtWiJqPjJyh2cKrLostPZTNy1o5x',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/PRSMNsEPqhGVCH1TtWiJqPjJyh2cKrLostPZTNy1o5x/logo.svg',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'RAD',
    name: 'RAD',
    address: 'B6aJ3TGfme3SMnLSouHXqWXjVFqYyqj7czzhzr8WJFAi',
    decimals: SupportedChainId.SOLANA,
    logoURI: 'https://raw.githubusercontent.com/NFTBrickLayer/zillaz-assets/main/RAD-logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'WAV',
    name: 'Fractionalized WAVE-999',
    address: '4NGNdLiQ1KG8GgqZimKku4WCLdXbNw6UQJvqax3fE6CJ',
    decimals: 2,
    logoURI: 'https://www.arweave.net/qV0rFkGG-cTdtgRu7le-k8DstT8_-pzG2MWsceSlXjQ?ext=png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'GGSG',
    name: 'Galactic Geckos Droplet',
    address: 'ggsgHDoX6tACq25XhQPUmbza8Fzwp9WdAzTU1voTwDi',
    decimals: 8,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ggsgHDoX6tACq25XhQPUmbza8Fzwp9WdAzTU1voTwDi/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'AAVE',
    name: 'Aave Token (Wormhole)',
    address: '3vAs4D1WE6Na4tCgt4BApgFfENbm8WY7q4cSPD1yM4Cg',
    decimals: 8,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5DQZ14hLDxveMH7NyGmTmUTRGgVAVXADp3cP2UHeH6hM/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'AKRO',
    name: 'Akropolis (Wormhole)',
    address: '12uHjozDVgyGWeLqQ8DMCRbig8amW5VmvZu3FdMMdcaG',
    decimals: 8,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/G3h8NZgJozk9crme2me6sKDJuSQ12mNCtvC9NbSWqGuk/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'COMP',
    name: 'Compound (Wormhole)',
    address: 'AwEauVaTMQRB71WeDnwf1DWSBxaMKjEPuxyLr1uixFom',
    decimals: 8,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3XnhArdJydrpbr9Nbj8wNUaozPL9WAo9YDyNWakhTm9X/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'FRONT',
    name: 'Frontier Token (Wormhole)',
    address: 'A9ik2NrpKRRG2snyTjofZQcTuav9yH3mNVHLsLiDQmYt',
    decimals: 8,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/A9ik2NrpKRRG2snyTjofZQcTuav9yH3mNVHLsLiDQmYt/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'GRT',
    name: 'Graph Token (Wormhole)',
    address: 'HGsLG4PnZ28L8A4R5nPqKgZd86zUUdmfnkTRnuFJ5dAX',
    decimals: 8,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BmxZ1pghpcoyT7aykj7D1o4AxWirTqvD7zD2tNngjirT/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'HGET',
    name: 'Hedget (Wormhole)',
    address: '2ueY1bLcPHfuFzEJq7yN1V2Wrpu8nkun9xG2TVCE1mhD',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2ueY1bLcPHfuFzEJq7yN1V2Wrpu8nkun9xG2TVCE1mhD/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'PERP',
    name: 'Perpetual (Wormhole)',
    address: '9BsnSWDPfbusseZfnXyZ3un14CyPMZYvsKjWY3Y8Gbqn',
    decimals: 8,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9sNArcS6veh7DLEo7Y1ZSbBCYtkuPVE6S3HhVrcWR2Zw/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'RSR',
    name: 'Reserve Rights (Wormhole)',
    address: 'DkbE8U4gSRuGHcVMA1LwyZPYUjYbfEbjW8DMR3iSXBzr',
    decimals: 8,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/64oqP1dFqqD8NEL4RPCpMyrHmpo31rj3nYxULVXvayfW/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'YFI',
    name: 'yearn.finance (Wormhole)',
    address: 'BXZX2JRJFjvKazM1ibeDFxgAngKExb74MRXzXKvgikxX',
    decimals: 8,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7TK6QeyTsnTT6KsnK2tHHfh62mbjNuFWoyUc8vo3CmmU/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'PEOPLE',
    name: 'ConstitutionDAO (Wormhole)',
    address: 'CobcsUrt3p91FwvULYKorQejgsm5HoQdv5T8RUZ6PnLA',
    decimals: 8,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CobcsUrt3p91FwvULYKorQejgsm5HoQdv5T8RUZ6PnLA/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'BOX',
    name: 'Solootbox DAO',
    address: 'DysbQiM8nPdZbBhvHM1EgcSE73EwtFWDanXwY8CDD3Jn',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DysbQiM8nPdZbBhvHM1EgcSE73EwtFWDanXwY8CDD3Jn/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'GCC',
    name: 'GitCompile Coin',
    address: '2NURMkJEkLWUXF91kbhBETkZ5E2D674DF2Wi5X2ZTsTH',
    decimals: 9,
    logoURI: 'https://raw.githubusercontent.com/gitcompile/assets/main/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'WAGMIX',
    name: 'WagmiX',
    address: 'cet4t25o8KbxxphkR2jiS3vqE2HJiy4rZTsKRMYrrnj',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/cet4t25o8KbxxphkR2jiS3vqE2HJiy4rZTsKRMYrrnj/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'WVIP',
    name: 'The WAGMI VIP Club',
    address: 'CQkTVkohEmyydNPNPH82c6aNPeXE72AatrhZcbntiAfg',
    decimals: SupportedChainId.SOLANA,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CQkTVkohEmyydNPNPH82c6aNPeXE72AatrhZcbntiAfg/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'SSK',
    name: 'SolShark',
    address: '4qw5MNc9oLKS22hiFTK6TNBHCqegDK3qzhMDgawtwnUL',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4qw5MNc9oLKS22hiFTK6TNBHCqegDK3qzhMDgawtwnUL/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'BAPE',
    name: 'BAPE',
    address: 'BgeRyFWWGHeVouqfHfcXUxmvfkgekhrXYVqQWf63kpJB',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BgeRyFWWGHeVouqfHfcXUxmvfkgekhrXYVqQWf63kpJB/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'CWM',
    name: 'Catalina Whale Mixer Droplet',
    address: 'cwmkTPCxDkYpBjLQRNhcCKxuxnAQW6ahS7JQLeXrsXt',
    decimals: 8,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/cwmkTPCxDkYpBjLQRNhcCKxuxnAQW6ahS7JQLeXrsXt/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'SB',
    name: 'SuperBonds Token',
    address: 'SuperbZyz7TsSdSoFAZ6RYHfAWe9NmjXBLVQpS8hqdx',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SuperbZyz7TsSdSoFAZ6RYHfAWe9NmjXBLVQpS8hqdx/logo.svg',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'AART',
    name: 'ALL ART',
    address: 'F3nefJBcejYbtdREjui1T9DPh5dBgpkKq7u2GAAMXs5B',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/allartprotocol/token-list/main/assets/mainnet/F3nefJBcejYbtdREjui1T9DPh5dBgpkKq7u2GAAMXs5B/logo.jpg',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'SLC',
    name: 'Solice',
    address: 'METAmTMXwdb8gYzyCPfXXFmZZw4rUsXX58PNsDg7zjL',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/METAmTMXwdb8gYzyCPfXXFmZZw4rUsXX58PNsDg7zjL/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'CGOLDR',
    name: 'CryptoGoldRush',
    address: '838ByxAj4QFwA6mhs7gUzYw8tKjZ5NJSw3XQudAuGZZC',
    decimals: 6,
    logoURI: 'https://cryptogoldrush.xyz/cgoldr.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'SYXT',
    name: 'SolanyxToken',
    address: '94jMUy411XNUw1CnkFr2514fq6KRc49W3kAmrjJiuZLx',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/94jMUy411XNUw1CnkFr2514fq6KRc49W3kAmrjJiuZLx/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'DALI',
    name: 'Fraktionalized Blockasset Legend Ali',
    address: 'ESrrTHaNKiC9saxaudX2j9iCM9r8qHRcLSrW5ypQ3W64',
    decimals: 3,
    logoURI: 'https://www.arweave.net/2Y3f2SeoT7HKHTQ_nUPfsLPyc59mPvNYSAsjKZuWNOk?ext=png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'TINY',
    name: 'TinyBits',
    address: 'HKfs24UEDQpHS5hUyKYkHd9q7GY5UQ679q2bokeL2whu',
    decimals: 6,
    logoURI: 'https://tinycolony.io/tinycolonytoken.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'CRH',
    name: 'crypto revolution HEROES',
    address: '4ZMwr188nAyRiJYsHLL5TBswXTU7aAmVio6SMP1mcdsJ',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4ZMwr188nAyRiJYsHLL5TBswXTU7aAmVio6SMP1mcdsJ/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'FLB',
    name: 'Floki Baby',
    address: '7AAtpqK78qbc7vx6BVWQ1D4PEjoccDbU293oGh74ovzN',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7AAtpqK78qbc7vx6BVWQ1D4PEjoccDbU293oGh74ovzN/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'ISS',
    name: 'Irish Setter Sol',
    address: '1SSBwC8hxB4GXzKKU4ENNpST7zgpTmucDe9NoAopWXX',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/1SSBwC8hxB4GXzKKU4ENNpST7zgpTmucDe9NoAopWXX/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: '$KAIDO',
    name: 'Kaidos Token',
    address: 'D5YJf7nqSp9JtQpMcZCthdEpMghK2gKmkLMLi2RhKSJt',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/D5YJf7nqSp9JtQpMcZCthdEpMghK2gKmkLMLi2RhKSJt/Kaido.jpg',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'pHONEY',
    name: 'pre-IDO HONEY',
    address: 'PHnyhLEnsD9SiP9tk9kHHKiCxCTPFnymzPspDqAicMe',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/PHnyhLEnsD9SiP9tk9kHHKiCxCTPFnymzPspDqAicMe/logo.svg',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'POOS',
    name: 'Legend Of PooShi',
    address: 'FdDEakNRY4k3orJuBXUcm9VkcXd8YXVPjuG5WuRN2tWH',
    decimals: SupportedChainId.SOLANA,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/ba3893b7f7822992a645bc130f78fa433baff2d3/assets/mainnet/FdDEakNRY4k3orJuBXUcm9VkcXd8YXVPjuG5WuRN2tWH/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'SBD',
    name: 'Solana Bird',
    address: '6HKq7SoHESuDz5ZbjZqgQkhrTiDFTfQdJapavMv7DbFb',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6HKq7SoHESuDz5ZbjZqgQkhrTiDFTfQdJapavMv7DbFb/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'MILK',
    name: 'Tiddy Juice Coin',
    address: 'CCKDRAd4Xwjoovtf2s1duu3d4TPTmFRyh1hfrb3ZUGR2',
    decimals: 9,
    logoURI: 'https://arweave.net/S71DNASA5TYlrK9giRy7QgqFNw1m4mxqY6OdtgY1E7k',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'CAC',
    name: 'Cosmic Ape Coin',
    address: 'E1s2muWwiLT2n3EQUL27hgviaPRRXWkpXD7ShpfgRvVz',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/E1s2muWwiLT2n3EQUL27hgviaPRRXWkpXD7ShpfgRvVz/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'atLUNA',
    name: 'Wrapped Luna (Allbridge from Terra)',
    address: '3LKZU3iQx9KM94S4uYRdYwAHTm6odDyzGQqTBNj7J27z',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Xf2yAXJfg82sWwdLUo2x9mZXy6JCdszdMZkcF1Hf4KV/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'CRB',
    name: 'Cerebro',
    address: '9A6dgXm79ASFyG42tui86F4gQTC56Ydw3mrNL61xhdr',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9A6dgXm79ASFyG42tui86F4gQTC56Ydw3mrNL61xhdr/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'EWS',
    name: 'Enterprise Web Service',
    address: '8WDJHzLR94ZCiJdkeGHMUY3TdWuryWgTGgWM9XRCbUG4',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8WDJHzLR94ZCiJdkeGHMUY3TdWuryWgTGgWM9XRCbUG4/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'CCG',
    name: 'Collectors Club Governance',
    address: '3FYzcJvLeQubLuAgacV6sDu9Ye8Eg1vFYpCxD3ogp74M',
    decimals: 6,
    logoURI: 'https://arweave.net/gBhj9k-4TGhwDwsnZxRQKMg_3ZQsvL1oeL0SUcrroPs',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'SVT',
    name: 'Solvent',
    address: 'svtMpL5eQzdmB3uqK9NXaQkq8prGZoKQFNVJghdWCkV',
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/svtMpL5eQzdmB3uqK9NXaQkq8prGZoKQFNVJghdWCkV/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'VIVAION',
    name: 'Vivaion Token',
    address: '2jw1uFmc1hhfJH3EqGhaE2rfZMMC2YBpxkZcdUbPppMn',
    decimals: 9,
    logoURI: 'https://arweave.net/2UtmXzq2ybKCFyb-U8gzlTtEVq4er4XKA-8Y2BIpABE',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'SOB2',
    name: 'SolaLambo Version 2',
    address: 'sobAUEhmPMrid5WtfXRCTRgASFFJbekKq8aLccC3DaL',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/sobAUEhmPMrid5WtfXRCTRgASFFJbekKq8aLccC3DaL/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'ST',
    name: 'Solana Tiger',
    address: '2NczZKqiEtv1CCufwBCLVRCh17w8aHAhvyGTU486KMo3',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2NczZKqiEtv1CCufwBCLVRCh17w8aHAhvyGTU486KMo3/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'ATL',
    name: 'Akuma Serpent',
    address: '2V1AVjDVM2gZn72ZufG2HfFHDKXzS5XaCupNeKrdcruT',
    decimals: 6,
    logoURI: 'https://raw.githubusercontent.com/onuraladi/assets/main/assets/trident.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'NURP',
    name: 'NURP Coin',
    address: '938xXsPKhBAXDLUfWNYuZdsnj57oGF6fCz8P6yLom19f',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/938xXsPKhBAXDLUfWNYuZdsnj57oGF6fCz8P6yLom19f/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'SNK',
    name: 'SolSnake',
    address: 'CZKnYioKuX2YzA2wnUMVXsSe3j259aaPsz7TfY2xnLmV',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CZKnYioKuX2YzA2wnUMVXsSe3j259aaPsz7TfY2xnLmV/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'TENU',
    name: 'Tesla Inu',
    address: 'J2beWpqSSoFXzzotd9uWQe9xSHZkszAntjYD7xCdnu1K',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/J2beWpqSSoFXzzotd9uWQe9xSHZkszAntjYD7xCdnu1K/logo.png',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'prtSOL',
    name: 'Parrot Stake Pool Token',
    address: 'BdZPG9xWrG3uFrx2KrUW1jT4tZ9VKPDWknYihzoPRJS3',
    decimals: 9,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9EaLkQrbjmbbuZG9Wdpo8qfNUEjHATJFSycEmw6f1rGX/logo.svg',
  },
  {
    chainId: SupportedChainId.SOLANA,
    symbol: 'sLUNA-9',
    name: 'Saber Wrapped LUNA (Wormhole) (9 decimals)',
    address: 'LUNGEjUXyP48nrC1GYY5o4eTAkwm4RdX8BxFUxWJBLB',
    decimals: 9,
    logoURI:
      'https://spl-token-icons.static-assets.ship.capital/icons/101/F6v4wfAdJB8D8p77bMXZgYt8TDKsYxLYxH5AFhUkYx9W.png',
  },
]
