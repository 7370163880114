import { Flex } from 'components/Box'
import { ArrowIcon, FlexIconWrapper, TextBox } from '../styles'
import Image from 'components/Image'
import { Field } from 'state/types'
import { useGetBlockchainInfo } from 'state/swap/selectors'
export const getIconByUrl = (logoURI) => {
  return <Image src={logoURI} width="40px" height="40px" classes="rounded-circle" />
}

const SwapSuccessCard = () => {
  const inputBlockchainNetwork = useGetBlockchainInfo(Field.INPUT)
  const outputBlockchainNetwork = useGetBlockchainInfo(Field.OUTPUT)

  return (
    <Flex width={'100%'} justifyContent={'space-between'}>
      <FlexIconWrapper>
        {inputBlockchainNetwork.icon}
        <TextBox>{inputBlockchainNetwork.name}</TextBox>
      </FlexIconWrapper>
      <Flex width={'100%'} alignItems={'center'} justifyContent={'center'} flex={1}>
        <ArrowIcon />
      </Flex>
      <FlexIconWrapper>
        {outputBlockchainNetwork.icon}
        <TextBox>{outputBlockchainNetwork.name}</TextBox>
      </FlexIconWrapper>
    </Flex>
  )
}

export default SwapSuccessCard
