import { Connection } from '@solana/web3.js'
import { SupportedChainId } from 'config/constants/chains'
import { useEffect, useState } from 'react'
import { usePoolState } from 'state/pools/selectors'
import { POOL_STATES } from 'state/pools/types'
import { useActiveStepIndex, useSteps, useSwapState } from 'state/swap/selectors'
import { SWAP_STATES } from 'state/swap/types'
import { SOLANA_RPC_ENDPOINT } from 'config/constants/endpoints'

export default function useSolanaTPS() {
  const [tps, setTps] = useState<number | null>(null)
  const { activeStepIndex } = useActiveStepIndex()
  const { steps } = useSteps()
  const pageState = useSwapState()
  const poolPageState = usePoolState()

  useEffect(() => {
    const fetchTpsData = async () => {
      // try {
      //   const connection = new Connection('https://chaotic-sly-flower.solana-mainnet.discover.quiknode.pro/58d46671a6b71d0b85d1b3f6c52b1f7d0f8fe79c/')
      //   const samples = await connection.getRecentPerformanceSamples(1)
      //   if (samples.length >= 1) {
      //     const short = samples
      //       .filter((sample) => sample.numTransactions !== 0)
      //       .map((sample) => sample.numTransactions / sample.samplePeriodSecs)
      //     const avgTps = short[0]
      //     setTps(+avgTps.toFixed(0))
      //   }
      // } catch (e) {
      //   // eslint-disable-next-line no-console
      //   console.log('e', e)
      // }
    }
    pageState !== SWAP_STATES.SWAP_COMPLETED && poolPageState !== POOL_STATES.POOL_COMPLETED && fetchTpsData()
  }, [activeStepIndex, steps, pageState])

  return { tps }
}
