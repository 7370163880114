import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 30 30" {...props}>
      <g id="Group_2277" data-name="Group 2277" transform="translate(-886 -239)">
        <circle
          id="Ellipse_113"
          data-name="Ellipse 113"
          cx="15"
          cy="15"
          r="15"
          transform="translate(886 239)"
          fill="#ff6c22"
        />
        <g id="hourglass-outline" transform="translate(895 245.025)">
          <path
            id="Path_95"
            data-name="Path 95"
            d="M113.455,65.949h9.526a1.4,1.4,0,0,0,1.442-1.513c-.593-5.218-4.134-5.132-4.134-7.462s3.586-2.2,4.133-7.462A1.406,1.406,0,0,0,122.981,48h-9.526a1.407,1.407,0,0,0-1.442,1.513c.548,5.261,4.133,5.089,4.133,7.462s-3.541,2.244-4.133,7.462A1.4,1.4,0,0,0,113.455,65.949Z"
            transform="translate(-112.005 -48)"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
          <path
            id="Path_96"
            data-name="Path 96"
            d="M162.183,156.426h-7.515c-.673,0-.863-.777-.391-1.258,1.143-1.158,3.449-1.987,3.449-3.315v-4.4c0-.856-1.64-1.51-2.654-2.9-.167-.229-.151-.552.275-.552H161.5a.327.327,0,0,1,.276.55c-1,1.391-2.675,2.041-2.675,2.9v4.4c0,1.317,2.4,2.028,3.469,3.317C163,155.689,162.854,156.426,162.183,156.426Z"
            transform="translate(-152.203 -139.858)"
            fill="#fff"
          />
        </g>
      </g>
    </Svg>
  )
}

export default Icon
