import { Flex } from 'components/Box'
import Image from 'components/Image'
import { FooterWrapper, IconContainer, Link } from './styles'
import { AtlasLogo, Logo } from 'components/Svg'
import { Text } from 'components/Text'
import useTheme from 'hooks/useTheme'
import { useTranslation } from 'contexts/Localization'
import useViewport from 'hooks/useViewport'
import packageJson from '../../../package.json'
import { STEP_BY_STEP_GUIDE, HOMEPAGE, FAQ } from 'config/constants/endpoints'

const Footer = () => {
  const { theme } = useTheme()
  const { t } = useTranslation()
  const { width } = useViewport()

  if (width <= 1200) {
    return (
      <Flex
        flexDirection={'column'}
        width={'100%'}
        mt={'auto'}
        borderTop={'1px solid rgb(24, 43, 60)'}
        pt={'1rem'}
        pb={'0.2rem'}
      >
        <Logo width={120} className={'mb-1'} />
        <Flex flexDirection={'column'} width={'100%'} alignItems={'center'} mt={'0.6rem'}>
          <Link className={'my-2'} href={HOMEPAGE} target={'_blank'}>
            {t('Homepage')}
          </Link>
          <Link className={'my-2'} href={STEP_BY_STEP_GUIDE} target={'_blank'}>
            {t('Step-by-Step Guide')}
          </Link>
          <Link className={'my-2'} href={FAQ} target={'_blank'}>
            {t('FAQs')}
          </Link>
        </Flex>
        <IconContainer width={'100%'} alignItems={'center'} justifyContent={'center'} mt={'0.6rem'}>
          <Flex className="icon">
            <a href={'https://twitter.com/atlas_dex'} target={'_blank'}>
              <Image src={'./images/Twitter.svg'} width="30" height="30" />
            </a>
          </Flex>
          <Flex className="icon">
            <a href={'https://discord.com/invite/atlasdex'} target={'_blank'}>
              <Image src={'./images/Discord.svg'} width="30" height="30" />
            </a>
          </Flex>
          <Flex className="icon">
            <a href={'https://atlasdex.medium.com/'} target={'_blank'}>
              <Image src={'./images/Medium.svg'} width="30" height="30" />
            </a>
          </Flex>
          <Flex className="icon">
            <a href={'https://github.com/atlas-dex/'} target={'_blank'}>
              <Image src={'./images/Github.svg'} width="30" height="30" />
            </a>
          </Flex>
        </IconContainer>
        <Flex flexDirection={'column'} width={'100%'} justifyContent={'center'} alignItems={'center'} mt={'0.6rem'}>
          <Text fontFamily={theme.fonts.primary} fontWeight={theme.fonts.regular}>
            V{packageJson.version}
          </Text>
        </Flex>
      </Flex>
    )
  }

  return (
    <Flex justifyContent={'space-between'} flexDirection={'row'} mt="auto" alignContent="center">
      <Flex ml="10px" className="link-container">
        <AtlasLogo width="30" />
        <Link href={HOMEPAGE} target={'_blank'}>
          {t('Homepage')}
        </Link>
        <Link href={STEP_BY_STEP_GUIDE} target={'_blank'}>
          {t('Step-by-Step Guide')}
        </Link>
        <Link href={FAQ} target={'_blank'}>
          {t('FAQs')}
        </Link>
      </Flex>
      <FooterWrapper>
        <IconContainer>
          <Flex className="icon">
            <a href={'https://twitter.com/atlas_dex'} target={'_blank'}>
              <Image src={'./images/Twitter.svg'} width="30" height="30" />
            </a>
          </Flex>
          <Flex className="icon">
            <a href={'https://discord.com/invite/atlasdex'} target={'_blank'}>
              <Image src={'./images/Discord.svg'} width="30" height="30" />
            </a>
          </Flex>
          <Flex className="icon">
            <a href={'https://atlasdex.medium.com/'} target={'_blank'}>
              <Image src={'./images/Medium.svg'} width="30" height="30" />
            </a>
          </Flex>
          <Flex className="icon">
            <a href={'https://github.com/atlas-dex/'} target={'_blank'}>
              <Image src={'./images/Github.svg'} width="30" height="30" />
            </a>
          </Flex>
        </IconContainer>
        <Text>v{packageJson.version}</Text>
      </FooterWrapper>
    </Flex>
  )
}

export default Footer
