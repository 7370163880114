import { useCallback } from 'react'
import axios from 'axios'
import * as Sentry from '@sentry/react'
import { JUPITER_API_URL } from 'config/constants/endpoints'
import { Currency, CurrencyAmount } from 'config/entities'

const useJupiterForRelayer = () => {
  const handleJupiterForRelayer = useCallback(
    async (inputCurrency: Currency, outputCurrency: Currency, amount: CurrencyAmount): Promise<CurrencyAmount> => {
      try {
        const url = `${JUPITER_API_URL}price?id=${outputCurrency.address}`

        const { data } = await axios(url)
        return new CurrencyAmount((1 / data.data.price) * amount.toEther().toNumber(), outputCurrency.decimals, false)
      } catch (error) {
        Sentry.captureException(error)
        return new CurrencyAmount(0, outputCurrency.decimals)
      }
    },
    [],
  )
  return { handleJupiterForRelayer }
}

export default useJupiterForRelayer
