import { SwapCardWrapper, SwapCardInner, WarningSection, InQueueText } from './styles'
import { Box, Flex } from 'components/Box'
import Button from 'components/Button'
import { Text } from 'components/Text'
import useTheme from 'hooks/useTheme'
import { AiFillWarning, AiOutlineQuestionCircle } from 'react-icons/ai'
import { useTranslation } from 'contexts/Localization'
import { SuccessIcon, BridgeIcon, Placeholder } from 'components/Svg'
import { NETWORKS_INFO } from 'config/constants/chains'
import { Currency } from 'config/entities'
import CheckMark from 'components/Svg/CheckMark'
import SwitchNetworkLoader, { getIconByChainID } from './components/SwitchNetwork'
import BridgeLoader from 'components/BridgeLoader'
// import { usePostLogs } from 'utils/postLogs'
import { AiOutlineLink } from 'react-icons/ai'
import SwapSuccessCard from './components/SwapSuccessCard'
import { ACTION_STEP_STATE, IStep, SWAP_STATES } from 'state/swap/types'
import { useActiveStepIndex, useActiveStepState, useStableSwapState } from 'state/stableSwap/selectors'
import { useStableSwapActionHandlers } from 'state/stableSwap/hooks'

interface ISwapInfoCard {
  step: IStep
  index: number
  handleDismiss: () => void
}

const SwapInfoCard: React.FC<ISwapInfoCard> = (props: ISwapInfoCard) => {
  const { step, index, handleDismiss } = props
  const { theme } = useTheme()
  const { t } = useTranslation()

  const activeStepState = useActiveStepState()
  const activeStepIndex = useActiveStepIndex()
  const { onActionStepStateChanged, onResetSwapState } = useStableSwapActionHandlers()
  const pageState = useStableSwapState()
  // const { postLogs } = usePostLogs()
  const handleBack = () => {
    handleDismiss()
    onResetSwapState()
    // postLogs('CANCELED', [])
  }
  const openHelpLink = () => {
    window.open(`https://docs.atlasdex.finance/atlas/Getting-Started/support-and-faqs`, '_blank')
  }

  // this step has passed this means that this should be successful
  if (index < activeStepIndex) {
    return (
      <SwapCardWrapper small>
        <SwapCardInner small>
          <Flex flex={1} alignItems="center" mb="auto">
            {getIconByChainID(step.chainId)}
            <Text
              ml="0.3rem"
              textTransform="uppercase"
              fontFamily={theme.fonts.primary}
              fontWeight={theme.fonts.semiBold}
            >
              {t(step.title)}
            </Text>
          </Flex>
          <Flex flex={2}>
            <SuccessIcon width="100" />
          </Flex>
        </SwapCardInner>
      </SwapCardWrapper>
    )
  }

  // if this step is yet to come, so we need to sort of hide everything
  if (index > activeStepIndex) {
    return (
      <SwapCardWrapper small>
        <SwapCardInner small>
          <Flex flex={1} alignItems="center" mb="auto">
            {getIconByChainID(step.chainId)}
            <Text
              ml="0.3rem"
              textTransform="uppercase"
              fontFamily={theme.fonts.primary}
              fontWeight={theme.fonts.semiBold}
            >
              {t(step.title).replace('%s', '')}
            </Text>
          </Flex>

          <Flex flex={2}>
            <Placeholder width="100" />
          </Flex>
        </SwapCardInner>
      </SwapCardWrapper>
    )
  }

  return (
    <SwapCardWrapper>
      <SwapCardInner>
        {/* Title of Card for Success */}
        {pageState === SWAP_STATES.SWAP_COMPLETED ? (
          <SwapSuccessCard />
        ) : (
          <Flex alignItems="center">
            {step.title === 'Bridge' ? <BridgeIcon width="30px" height="30px" /> : getIconByChainID(step.chainId)}
            <Text
              ml="0.3rem"
              textTransform="uppercase"
              fontFamily={theme.fonts.primary}
              fontWeight={theme.fonts.semiBold}
            >
              {step.payload?.inputCurrency
                ? t(step.title).replace('%s', (step.payload.inputCurrency as Currency).symbol)
                : t(step.title)}
            </Text>
          </Flex>
        )}

        <Flex flexDirection="column" height="100%" width="100%" alignItems={'center'} justifyContent={'center'}>
          {step.name === 'BRIDGE' && (
            <Box my="0.4rem">
              <Flex justifyContent={'space-between'} flexDirection={'column'} width={'100%'} height={'100%'}>
                <Flex flexDirection={'column'} justifyContent={'center'} mt={'50px'} alignItems={'center'}>
                  <InQueueText ml={'10px'} mt={'20px'}>
                    Your transaction has been queued
                  </InQueueText>
                </Flex>
                <Flex
                  justifyContent={'center'}
                  flexDirection={'column'}
                  width={'100%'}
                  alignItems={'center'}
                  mt={'50px'}
                >
                  <Text role="button" onClick={handleBack} className="fade-on-hover cancel-btn">
                    Done
                  </Text>
                </Flex>
              </Flex>
            </Box>
          )}
          {step.name !== 'BRIDGE' && (
            <Flex alignItems="center" pt="0.4rem" justifyContent="center" width={'100%'} height={'100%'}>
              {activeStepState.status === ACTION_STEP_STATE.OK ? (
                <Box>
                  <CheckMark />
                </Box>
              ) : activeStepState.status === ACTION_STEP_STATE.IN_PROGRESS ||
                activeStepState.status === ACTION_STEP_STATE.WAIT_FOR_LIBRARY ? (
                step.name === 'CHANGE_NETWORK' ? (
                  <SwitchNetworkLoader chainID={step.chainId} />
                ) : (
                  <Flex
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    width={'100%'}
                    height={'100%'}
                  >
                    <BridgeLoader />
                  </Flex>
                )
              ) : (
                <WarningSection>
                  <AiFillWarning className="warning-icon" />
                  {activeStepState?.message ? (
                    <Text className="warning-text">
                      {activeStepState?.message.includes('reject') || activeStepState.message.includes('denied')
                        ? t('User rejected the request.')
                        : t('error_message')}
                    </Text>
                  ) : (
                    <Text className="warning-text">
                      {activeStepState?.error.includes('declined') || activeStepState.error.includes('denied')
                        ? t('User rejected the request.')
                        : t('error_message')}
                    </Text>
                  )}
                  <Box onClick={openHelpLink} className="fade-on-hover help-text">
                    <AiOutlineQuestionCircle className={'mb-1'} /> Help
                  </Box>
                  <Button
                    className={'retry-btn'}
                    onClick={() => {
                      onActionStepStateChanged({
                        status: ACTION_STEP_STATE.IN_PROGRESS,
                        message: '',
                      })
                    }}
                  >
                    Retry
                  </Button>
                  <Text role="button" onClick={handleBack} className="fade-on-hover cancel-btn">
                    Cancel
                  </Text>
                </WarningSection>
              )}
            </Flex>
          )}
        </Flex>

        <Text
          mt="1.4rem"
          role="button"
          fontFamily={theme.fonts.primary}
          fontWeight={theme.fonts.semiBold}
          color={theme.colors.textOrange}
          fontSize="x-small"
          textTransform={'uppercase'}
          style={{ opacity: step?.result?.txid ? 1 : 0, wordSpacing: 2, textAlign: 'center' }}
          onClick={() =>
            step?.result?.txid &&
            window.open(`${NETWORKS_INFO[step.chainId].exploreLink}tx/${step?.result?.txid}`, '_blank')
          }
        >
          <AiOutlineLink style={{ margin: '0 0.2rem 0.2rem 0' }} size={14} color={theme.colors.textOrange} /> View on{' '}
          {NETWORKS_INFO[step.chainId].explorerName}
        </Text>
      </SwapCardInner>
    </SwapCardWrapper>
  )
}

export default SwapInfoCard
