import { SupportedChainId } from 'config/constants/chains'
import { Currency } from 'config/entities'

export const PolygonTokens: Currency[] = [
  {
    symbol: 'MATIC',
    chainId: SupportedChainId.POLYGON,
    name: 'MATIC',
    address: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3890.png',
  },
  {
    symbol: 'USDC',
    name: 'USD Coin',
    decimals: 6,
    address: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174',
    logoURI: 'https://tokens.1inch.io/0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48.png',
    chainId: SupportedChainId.POLYGON,
  },
  {
    symbol: 'AAVE',
    name: 'Aave',
    decimals: 18,
    address: '0xd6df932a45c0f255f85145f286ea0b292b21c90b',
    logoURI: 'https://tokens.1inch.io/0x7fc66500c84a76ad7e9c93437bfc5ac33e2ddae9.png',
    chainId: SupportedChainId.POLYGON,
  },
  {
    symbol: 'MUST',
    name: 'Must',
    decimals: 18,
    address: '0x9c78ee466d6cb57a4d01fd887d2b5dfb2d46288f',
    logoURI: 'https://tokens.1inch.io/0x9c78ee466d6cb57a4d01fd887d2b5dfb2d46288f.png',
    chainId: SupportedChainId.POLYGON,
  },
  {
    symbol: 'UNI',
    name: 'Uniswap',
    decimals: 18,
    address: '0xb33eaad8d922b1083446dc23f610c2567fb5180f',
    logoURI: 'https://tokens.1inch.io/0x1f9840a85d5af5bf1d1762f925bdaddc4201f984.png',
    chainId: SupportedChainId.POLYGON,
  },

  {
    symbol: 'USDT',
    name: 'Tether USD',
    decimals: 6,
    address: '0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
    logoURI: 'https://tokens.1inch.io/0xdac17f958d2ee523a2206206994597c13d831ec7.png',
    chainId: SupportedChainId.POLYGON,
  },
  {
    symbol: 'DAI',
    name: 'Dai Stablecoin',
    decimals: 18,
    address: '0x8f3cf7ad23cd3cadbd9735aff958023239c6a063',
    logoURI: 'https://tokens.1inch.io/0x6b175474e89094c44da98b954eedeac495271d0f.png',
    chainId: SupportedChainId.POLYGON,
  },
  {
    symbol: 'WBTC',
    name: 'Wrapped BTC',
    decimals: 8,
    address: '0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6',
    logoURI: 'https://tokens.1inch.io/0x2260fac5e5542a773aa44fbcfedf7c193bc2c599.png',
    chainId: SupportedChainId.POLYGON,
  },
  {
    symbol: 'LINK',
    name: 'ChainLink Token',
    decimals: 18,
    address: '0x53e0bca35ec356bd5dddfebbd1fc0fd03fabad39',
    logoURI: 'https://tokens.1inch.io/0x514910771af9ca656af840dff83e8264ecf986ca.png',
    chainId: SupportedChainId.POLYGON,
  },
  {
    symbol: 'MEME',
    name: 'MEME (PoS)',
    decimals: 8,
    address: '0xf2b5a8c37278bcdd50727d5ca879f8e5a4642e2e',
    logoURI: 'https://tokens.1inch.io/0xd5525d397898e5502075ea5e830d8914f6f0affe.png',
    chainId: SupportedChainId.POLYGON,
  },
  {
    symbol: 'SUPER',
    name: 'SuperFarm',
    decimals: 18,
    address: '0xa1428174f516f527fafdd146b883bb4428682737',
    logoURI: 'https://tokens.1inch.io/0xe53ec727dbdeb9e2d5456c3be40cff031ab40a55.png',
    chainId: SupportedChainId.POLYGON,
  },
  {
    symbol: 'MANA',
    name: 'Decentraland MANA',
    decimals: 18,
    address: '0xa1c57f48f0deb89f569dfbe6e2b7f46d33606fd4',
    logoURI: 'https://tokens.1inch.io/0x0f5d2fb29fb7d3cfee444a200298f468908cc942.png',
    chainId: SupportedChainId.POLYGON,
  },
  {
    symbol: 'YFI',
    name: 'yearn.finance',
    decimals: 18,
    address: '0xda537104d6a5edd53c6fbba9a898708e465260b6',
    logoURI: 'https://tokens.1inch.io/0x0bc529c00c6401aef6d220be8c6ea1667f6ad93e.png',
    chainId: SupportedChainId.POLYGON,
  },
  {
    symbol: 'WMATIC',
    name: 'Wrapped Matic',
    decimals: 18,
    address: '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270',
    logoURI: 'https://tokens.1inch.io/0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0.png',
    chainId: SupportedChainId.POLYGON,
  },
  {
    symbol: 'COMP',
    name: 'Compound',
    decimals: 18,
    address: '0x8505b9d2254a7ae468c0e9dd10ccea3a837aef5c',
    logoURI: 'https://tokens.1inch.io/0xc00e94cb662c3520282e6f5717214004a7f26888.png',
    chainId: SupportedChainId.POLYGON,
  },
  {
    symbol: 'BTU',
    name: 'BTU Protocol',
    decimals: 18,
    address: '0xfdc26cda2d2440d0e83cd1dee8e8be48405806dc',
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3737.png',
    chainId: SupportedChainId.POLYGON,
  },
  {
    symbol: 'AGA',
    name: 'AGA Token',
    decimals: 4,
    address: '0x033d942a6b495c4071083f4cde1f17e986fe856c',
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/6404.png',
    chainId: SupportedChainId.POLYGON,
  },
  {
    symbol: 'AGAr',
    name: 'AGA Rewards',
    decimals: 8,
    address: '0xf84bd51eab957c2e7b7d646a3427c5a50848281d',
    logoURI: 'https://tokens.1inch.io/0xb453f1f2ee776daf2586501361c457db70e1ca0f.png',
    chainId: SupportedChainId.POLYGON,
  },
  {
    symbol: 'ARIA20',
    name: 'ARIANEE',
    decimals: 18,
    address: '0x46f48fbdedaa6f5500993bede9539ef85f4bee8e',
    logoURI: 'https://tokens.1inch.io/0x46f48fbdedaa6f5500993bede9539ef85f4bee8e.png',
    chainId: SupportedChainId.POLYGON,
  },
  {
    symbol: 'AZUKI',
    name: 'DokiDokiAzuki',
    decimals: 18,
    address: '0x7cdc0421469398e0f3aa8890693d86c840ac8931',
    logoURI: 'https://tokens.1inch.io/0x910524678c0b1b23ffb9285a81f99c29c11cbaed.png',
    chainId: SupportedChainId.POLYGON,
  },
  {
    symbol: 'CEL',
    name: 'Celsius',
    decimals: 4,
    address: '0xd85d1e945766fea5eda9103f918bd915fbca63e6',
    logoURI: 'https://tokens.1inch.io/0xaaaebe6fe48e54f431b0c390cfaf0b017d09d42d.png',
    chainId: SupportedChainId.POLYGON,
  },
  {
    symbol: 'CFI',
    name: 'CyberFi Token',
    decimals: 18,
    address: '0xecf8f2fa183b1c4d2a269bf98a54fce86c812d3e',
    logoURI: 'https://tokens.1inch.io/0x63b4f3e3fa4e438698ce330e365e831f7ccd1ef4.png',
    chainId: SupportedChainId.POLYGON,
  },
  {
    symbol: 'DMT',
    name: 'Dark Matter Token',
    decimals: 18,
    address: '0xd28449bb9bb659725accad52947677cce3719fd7',
    logoURI: 'https://tokens.1inch.io/0xd28449bb9bb659725accad52947677cce3719fd7.png',
    chainId: SupportedChainId.POLYGON,
  },
  {
    symbol: 'DSLA',
    name: 'DSLA',
    decimals: 18,
    address: '0xa0e390e9cea0d0e8cd40048ced9fa9ea10d71639',
    logoURI: 'https://tokens.1inch.io/0x3affcca64c2a6f4e3b6bd9c64cd2c969efd1ecbe.png',
    chainId: SupportedChainId.POLYGON,
  },
  {
    symbol: 'ETH',
    name: 'Ether',
    decimals: 18,
    address: '0x7ceb23fd6bc0add59e62ac25578270cff1b9f619',
    logoURI: 'https://tokens.1inch.io/0x7ceb23fd6bc0add59e62ac25578270cff1b9f619.png',
    chainId: SupportedChainId.POLYGON,
  },
  {
    symbol: 'GHST',
    name: 'Aavegotchi GHST Token',
    decimals: 18,
    address: '0x385eeac5cb85a38a9a07a70c73e0a3271cfb54a7',
    logoURI: 'https://tokens.1inch.io/0x3f382dbd960e3a9bbceae22651e88158d2791550.png',
    chainId: SupportedChainId.POLYGON,
  },
  {
    symbol: 'HEX',
    name: 'HEXX',
    decimals: 8,
    address: '0x23d29d30e35c5e8d321e1dc9a8a61bfd846d4c5c',
    logoURI: 'https://tokens.1inch.io/0x2b591e99afe9f32eaa6214f7b7629768c40eeb39.png',
    chainId: SupportedChainId.POLYGON,
  },
  {
    symbol: 'IGG',
    name: 'IG Gold',
    decimals: 6,
    address: '0xe6fc6c7cb6d2c31b359a49a33ef08ab87f4de7ce',
    logoURI: 'https://tokens.1inch.io/0xe6fc6c7cb6d2c31b359a49a33ef08ab87f4de7ce.png',
    chainId: SupportedChainId.POLYGON,
  },
  {
    symbol: 'MONA',
    name: 'Monavale',
    decimals: 18,
    address: '0x6968105460f67c3bf751be7c15f92f5286fd0ce5',
    logoURI: 'https://tokens.1inch.io/0x275f5ad03be0fa221b4c6649b8aee09a42d9412a.png',
    chainId: SupportedChainId.POLYGON,
  },
  {
    symbol: 'OM',
    name: 'OM',
    decimals: 18,
    address: '0xc3ec80343d2bae2f8e680fdadde7c17e71e114ea',
    logoURI: 'https://tokens.1inch.io/0x3593d125a4f7849a1b059e64f4517a86dd60c95d.png',
    chainId: SupportedChainId.POLYGON,
  },
  {
    symbol: 'PICKLE',
    name: 'PickleToken',
    decimals: 18,
    address: '0x2b88ad57897a8b496595925f43048301c37615da',
    logoURI: 'https://tokens.1inch.io/0x429881672b9ae42b8eba0e26cd9c73711b891ca5.png',
    chainId: SupportedChainId.POLYGON,
  },
  {
    symbol: 'PPDEX',
    name: 'Pepedex',
    decimals: 18,
    address: '0x127984b5e6d5c59f81dacc9f1c8b3bdc8494572e',
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7592.png',
    chainId: SupportedChainId.POLYGON,
  },
  {
    symbol: 'SDT',
    name: 'Stake DAO Token',
    decimals: 18,
    address: '0x361a5a4993493ce00f61c32d4ecca5512b82ce90',
    logoURI: 'https://tokens.1inch.io/0x73968b9a57c6e53d41345fd57a6e6ae27d6cdb2f.png',
    chainId: SupportedChainId.POLYGON,
  },
  {
    symbol: 'SUSHI',
    name: 'SushiToken',
    decimals: 18,
    address: '0x0b3f868e0be5597d5db7feb59e1cadbb0fdda50a',
    logoURI: 'https://tokens.1inch.io/0x6b3595068778dd592e39a122f4f5a5cf09c90fe2.png',
    chainId: SupportedChainId.POLYGON,
  },
  {
    symbol: 'SWAP',
    name: 'TrustSwap Token',
    decimals: 18,
    address: '0x3809dcdd5dde24b37abe64a5a339784c3323c44f',
    logoURI: 'https://tokens.1inch.io/0xcc4304a31d09258b0029ea7fe63d032f52e44efe.png',
    chainId: SupportedChainId.POLYGON,
  },
  {
    symbol: 'SX',
    name: 'SportX',
    decimals: 18,
    address: '0x840195888db4d6a99ed9f73fcd3b225bb3cb1a79',
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8377.png',
    chainId: SupportedChainId.POLYGON,
  },
  {
    symbol: 'UBT',
    name: 'Unibright',
    decimals: 8,
    address: '0x7fbc10850cae055b27039af31bd258430e714c62',
    logoURI: 'https://tokens.1inch.io/0x8400d94a5cb0fa0d041a3788e395285d61c9ee5e.png',
    chainId: SupportedChainId.POLYGON,
  },
  {
    symbol: 'VISION',
    name: 'Vision Token',
    decimals: 18,
    address: '0x034b2090b579228482520c589dbd397c53fc51cc',
    logoURI: 'https://tokens.1inch.io/0x034b2090b579228482520c589dbd397c53fc51cc.png',
    chainId: SupportedChainId.POLYGON,
  },
  {
    symbol: 'WISE',
    name: 'Wise Token',
    decimals: 18,
    address: '0xb77e62709e39ad1cbeebe77cf493745aec0f453a',
    logoURI: 'https://tokens.1inch.io/0x66a0f676479cee1d7373f3dc2e2952778bff5bd6.png',
    chainId: SupportedChainId.POLYGON,
  },
  {
    symbol: 'WOLF',
    name: 'moonwolf.io',
    decimals: 9,
    address: '0x8f18dc399594b451eda8c5da02d0563c0b2d0f16',
    logoURI: 'https://tokens.1inch.io/0x8f18dc399594b451eda8c5da02d0563c0b2d0f16.png',
    chainId: SupportedChainId.POLYGON,
  },
  {
    symbol: 'iFARM',
    name: 'iFARM',
    decimals: 18,
    address: '0xab0b2ddb9c7e440fac8e140a89c0dbcbf2d7bbff',
    logoURI: 'https://tokens.1inch.io/0xa0246c9032bc3a600820415ae600c6388619a14d.png',
    chainId: SupportedChainId.POLYGON,
  },
  {
    symbol: 'mOCEAN',
    name: 'Ocean Token',
    decimals: 18,
    address: '0x282d8efce846a88b159800bd4130ad77443fa1a1',
    logoURI: 'https://tokens.1inch.io/0x967da4048cd07ab37855c090aaf366e4ce1b9f48.png',
    chainId: SupportedChainId.POLYGON,
  },
  {
    symbol: 'ANY',
    name: 'Anyswap',
    decimals: 18,
    address: '0x6ab6d61428fde76768d7b45d8bfeec19c6ef91a8',
    logoURI: 'https://tokens.1inch.io/0xf99d58e463a2e07e5692127302c20a191861b4d6.png',

    chainId: SupportedChainId.POLYGON,
  },
  {
    symbol: 'GFARM2',
    name: 'Gains V2',
    decimals: 18,
    address: '0x7075cab6bcca06613e2d071bd918d1a0241379e2',
    logoURI: 'https://tokens.1inch.io/0x7075cab6bcca06613e2d071bd918d1a0241379e2.png',
    chainId: SupportedChainId.POLYGON,
  },
  {
    symbol: 'Krill',
    name: 'Krill',
    decimals: 18,
    address: '0x05089c9ebffa4f0aca269e32056b1b36b37ed71b',
    logoURI: 'https://tokens.1inch.io/0x05089c9ebffa4f0aca269e32056b1b36b37ed71b.png',
    chainId: SupportedChainId.POLYGON,
  },
  {
    symbol: 'NFTP',
    name: 'NFT Platform Index',
    decimals: 18,
    address: '0xf7d9e281c5cb4c6796284c5b663b3593d2037af2',
    logoURI: 'https://tokens.1inch.io/0x05089c9ebffa4f0aca269e32056b1b36b37ed71b.png',
    chainId: SupportedChainId.POLYGON,
  },
  {
    symbol: 'PLOT',
    name: 'PLOT',
    decimals: 18,
    address: '0xe82808eaa78339b06a691fd92e1be79671cad8d3',
    logoURI: 'https://tokens.1inch.io/0x72f020f8f3e8fd9382705723cd26380f8d0c66bb.png',
    chainId: SupportedChainId.POLYGON,
  },
  {
    symbol: 'TEL',
    name: 'Telcoin',
    decimals: 2,
    address: '0xdf7837de1f2fa4631d716cf2502f8b230f1dcc32',
    logoURI: 'https://tokens.1inch.io/0x467bccd9d29f223bce8043b84e8c8b282827790f.png',
    chainId: SupportedChainId.POLYGON,
  },
  {
    symbol: 'amUSDC',
    name: 'Aave Matic Market USDC',
    decimals: 6,
    address: '0x1a13f4ca1d028320a707d99520abfefca3998b7f',
    logoURI: 'https://tokens.1inch.io/0xbcca60bb61934080951369a648fb03df4f96263c.png',

    chainId: SupportedChainId.POLYGON,
  },
  {
    symbol: 'amUSDT',
    name: 'Aave Matic Market USDT',
    decimals: 6,
    address: '0x60d55f02a771d515e077c9c2403a1ef324885cec',
    logoURI: 'https://tokens.1inch.io/0x3ed3b47dd13ec9a98b44e6204a523e766b225811.png',

    chainId: SupportedChainId.POLYGON,
  },
  {
    symbol: 'amWBTC',
    name: 'Aave Matic Market WBTC',
    decimals: 8,
    address: '0x5c2ed810328349100a66b82b78a1791b101c9d61',
    logoURI: 'https://tokens.1inch.io/0x9ff58f4ffb29fa2266ab25e75e2a8b3503311656.png',

    chainId: SupportedChainId.POLYGON,
  },
  {
    symbol: 'amWETH',
    name: 'Aave Matic Market WETH',
    decimals: 18,
    address: '0x28424507fefb6f7f8e9d3860f56504e4e5f5f390',
    logoURI: 'https://tokens.1inch.io/0x030ba81f1c18d280636f32af80b9aad02cf0854e.png',

    chainId: SupportedChainId.POLYGON,
  },
  {
    symbol: 'amWMATIC',
    name: 'Aave Matic Market WMATIC',
    decimals: 18,
    address: '0x8df3aad3a84da6b69a4da8aec3ea40d9091b2ac4',
    logoURI: 'https://tokens.1inch.io/0x8df3aad3a84da6b69a4da8aec3ea40d9091b2ac4.png',

    chainId: SupportedChainId.POLYGON,
  },
  {
    symbol: 'amAAVE',
    name: 'Aave Matic Market AAVE',
    decimals: 18,
    address: '0x1d2a0e5ec8e5bbdca5cb219e649b565d8e5c3360',
    logoURI: 'https://tokens.1inch.io/0xffc97d72e13e01096502cb8eb52dee56f74dad7b.png',

    chainId: SupportedChainId.POLYGON,
  },
  {
    symbol: 'amDAI',
    name: 'Aave Matic Market DAI',
    decimals: 18,
    address: '0x27f8d03b3a2196956ed754badc28d73be8830a6e',
    logoURI: 'https://tokens.1inch.io/0x028171bca77440897b824ca71d1c56cac55b68a3.png',

    chainId: SupportedChainId.POLYGON,
  },
  {
    symbol: 'CRV',
    name: 'CRV',
    decimals: 18,
    address: '0x172370d5cd63279efa6d502dab29171933a610af',
    logoURI: 'https://tokens.1inch.io/0xd533a949740bb3306d119cc777fa900ba034cd52.png',
    chainId: SupportedChainId.POLYGON,
  },
  {
    symbol: 'BIFI',
    name: 'beefy.finance',
    decimals: 18,
    address: '0xfbdd194376de19a88118e84e279b977f165d01b8',
    logoURI: 'https://tokens.1inch.io/0xca3f508b8e4dd382ee878a314789373d80a5190a.png',

    chainId: SupportedChainId.POLYGON,
  },
  {
    symbol: 'FISH',
    name: 'Fish',
    decimals: 18,
    address: '0x3a3df212b7aa91aa0402b9035b098891d276572b',
    logoURI: 'https://tokens.1inch.io/0x3a3df212b7aa91aa0402b9035b098891d276572b.png',
    chainId: SupportedChainId.POLYGON,
  },
  {
    symbol: 'PolyDoge',
    name: 'PolyDoge',
    decimals: 18,
    address: '0x8a953cfe442c5e8855cc6c61b1293fa648bae472',
    logoURI: 'https://tokens.1inch.io/0x8a953cfe442c5e8855cc6c61b1293fa648bae472.png',
    chainId: SupportedChainId.POLYGON,
  },
  {
    symbol: 'IRON',
    name: 'IRON Stablecoin',
    decimals: 18,
    address: '0xd86b5923f3ad7b585ed81b448170ae026c65ae9a',
    logoURI: 'https://tokens.1inch.io/0xd86b5923f3ad7b585ed81b448170ae026c65ae9a.png',
    chainId: SupportedChainId.POLYGON,
  },
  {
    symbol: 'TITAN',
    name: 'IRON Titanium Token',
    decimals: 18,
    address: '0xaaa5b9e6c589642f98a1cda99b9d024b8407285a',
    logoURI: 'https://tokens.1inch.io/0xaaa5b9e6c589642f98a1cda99b9d024b8407285a.png',
    chainId: SupportedChainId.POLYGON,
  },
  {
    symbol: 'DFYN',
    name: 'DFYN Token',
    decimals: 18,
    address: '0xc168e40227e4ebd8c1cae80f7a55a4f0e6d66c97',
    logoURI: 'https://tokens.1inch.io/0xc168e40227e4ebd8c1cae80f7a55a4f0e6d66c97.png',
    chainId: SupportedChainId.POLYGON,
  },
  {
    symbol: 'WOO',
    name: 'Wootrade Network',
    decimals: 18,
    address: '0x1b815d120b3ef02039ee11dc2d33de7aa4a8c603',
    logoURI: 'https://tokens.1inch.io/0x1b815d120b3ef02039ee11dc2d33de7aa4a8c603.png',
    chainId: SupportedChainId.POLYGON,
  },
  {
    symbol: 'WEXpoly',
    name: 'WaultSwap Polygon',
    decimals: 18,
    address: '0x4c4bf319237d98a30a929a96112effa8da3510eb',
    logoURI: 'https://tokens.1inch.io/0x4c4bf319237d98a30a929a96112effa8da3510eb.png',
    chainId: SupportedChainId.POLYGON,
  },
  {
    symbol: 'QI',
    name: 'Qi Dao',
    decimals: 18,
    address: '0x580a84c73811e1839f75d86d75d88cca0c241ff4',
    logoURI: 'https://tokens.1inch.io/0x580a84c73811e1839f75d86d75d88cca0c241ff4.png',
    chainId: SupportedChainId.POLYGON,
  },
  {
    symbol: 'miMATIC',
    name: 'miMATIC',
    decimals: 18,
    address: '0xa3fa99a148fa48d14ed51d610c367c61876997f1',
    logoURI: 'https://tokens.1inch.io/0xa3fa99a148fa48d14ed51d610c367c61876997f1.png',
    chainId: SupportedChainId.POLYGON,
  },
  {
    symbol: 'OMEN',
    name: 'Augury Finance',
    decimals: 18,
    address: '0x76e63a3e7ba1e2e61d3da86a87479f983de89a7e',
    logoURI: 'https://tokens.1inch.io/0x76e63a3e7ba1e2e61d3da86a87479f983de89a7e.png',
    chainId: SupportedChainId.POLYGON,
  },
  {
    symbol: 'KNC',
    name: 'Kyber Network Crystal v2',
    decimals: 18,
    address: '0x1c954e8fe737f99f68fa1ccda3e51ebdb291948c',
    logoURI: 'https://tokens.1inch.io/0x1c954e8fe737f99f68fa1ccda3e51ebdb291948c.png',
    chainId: SupportedChainId.POLYGON,
  },
  {
    symbol: 'BAL',
    name: 'Balancer',
    decimals: 18,
    address: '0x9a71012b13ca4d3d0cdc72a177df3ef03b0e76a3',
    logoURI: 'https://tokens.1inch.io/0x9a71012b13ca4d3d0cdc72a177df3ef03b0e76a3.png',
    chainId: SupportedChainId.POLYGON,
  },
  {
    symbol: 'ICE',
    name: 'Iron Finance ICE Token',
    decimals: 18,
    address: '0x4a81f8796e0c6ad4877a51c86693b0de8093f2ef',
    logoURI: 'https://tokens.1inch.io/0x4a81f8796e0c6ad4877a51c86693b0de8093f2ef.png',
    chainId: SupportedChainId.POLYGON,
  },
  {
    symbol: 'PAPR',
    name: 'PAPR',
    decimals: 18,
    address: '0xfbe49330e7b9f58a822788f86c1be38ab902bab1',
    logoURI: 'https://tokens.1inch.io/0xfbe49330e7b9f58a822788f86c1be38ab902bab1.png',
    chainId: SupportedChainId.POLYGON,
  },
  {
    symbol: 'ETHA',
    name: 'ETHA',
    decimals: 18,
    address: '0x59e9261255644c411afdd00bd89162d09d862e38',
    logoURI: 'https://tokens.1inch.io/0x59e9261255644c411afdd00bd89162d09d862e38.png',
    chainId: SupportedChainId.POLYGON,
  },
  {
    symbol: 'ADDY',
    name: 'Adamant',
    decimals: 18,
    address: '0xc3fdbadc7c795ef1d6ba111e06ff8f16a20ea539',
    logoURI: 'https://tokens.1inch.io/0xc3fdbadc7c795ef1d6ba111e06ff8f16a20ea539.png',
    chainId: SupportedChainId.POLYGON,
  },
  {
    symbol: 'UST',
    name: 'Wrapped UST Token (PoS)',
    decimals: 18,
    address: '0x692597b009d13c4049a947cab2239b7d6517875f',
    logoURI: 'https://tokens.1inch.io/0x692597b009d13c4049a947cab2239b7d6517875f.png',
    chainId: SupportedChainId.POLYGON,
  },
  {
    symbol: 'SNK',
    name: 'Snook',
    decimals: 18,
    address: '0x689f8e5913c158ffb5ac5aeb83b3c875f5d20309',
    logoURI: 'https://tokens.1inch.io/0x689f8e5913c158ffb5ac5aeb83b3c875f5d20309.png',
    chainId: SupportedChainId.POLYGON,
  },
  {
    symbol: 'IRIS',
    name: 'Iris',
    decimals: 18,
    address: '0xdab35042e63e93cc8556c9bae482e5415b5ac4b1',
    logoURI: 'https://tokens.1inch.io/0xdab35042e63e93cc8556c9bae482e5415b5ac4b1.png',
    chainId: SupportedChainId.POLYGON,
  },
  {
    symbol: 'xUSD',
    name: 'xDollar Stablecoin',
    decimals: 18,
    address: '0x3a3e7650f8b9f667da98f236010fbf44ee4b2975',
    logoURI: 'https://tokens.1inch.io/0x3a3e7650f8b9f667da98f236010fbf44ee4b2975.png',

    chainId: SupportedChainId.POLYGON,
  },
  {
    symbol: 'KOGECOIN',
    name: 'kogecoin.io',
    decimals: 9,
    address: '0x13748d548d95d78a3c83fe3f32604b4796cffa23',
    logoURI: 'https://tokens.1inch.io/0x13748d548d95d78a3c83fe3f32604b4796cffa23.png',
    chainId: SupportedChainId.POLYGON,
  },
  {
    symbol: 'EURS',
    name: 'STASIS EURS Token (PoS)',
    decimals: 2,
    address: '0xe111178a87a3bff0c8d18decba5798827539ae99',
    logoURI: 'https://tokens.1inch.io/0xe111178a87a3bff0c8d18decba5798827539ae99.png',
    chainId: SupportedChainId.POLYGON,
  },
  {
    symbol: 'XSGD',
    name: 'XSGD (PoS)',
    decimals: 6,
    address: '0x769434dca303597c8fc4997bf3dab233e961eda2',
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8489.png',
    chainId: SupportedChainId.POLYGON,
  },
  {
    symbol: '1FLR',
    name: 'Flare Token',
    decimals: 18,
    address: '0x5f0197ba06860dac7e31258bdf749f92b6a636d4',
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13020.png',
    chainId: SupportedChainId.POLYGON,
  },
  {
    symbol: 'APW',
    name: 'APWine Token (PoS)',
    decimals: 18,
    address: '0x6c0ab120dbd11ba701aff6748568311668f63fe0',
    logoURI: 'https://tokens.1inch.io/0x4104b135dbc9609fc1a9490e61369036497660c8.png',
    chainId: SupportedChainId.POLYGON,
  },
  {
    symbol: 'DHT',
    name: 'dHedge DAO Token (PoS)',
    decimals: 18,
    address: '0x8c92e38eca8210f4fcbf17f0951b198dd7668292',
    logoURI: 'https://tokens.1inch.io/0x8c92e38eca8210f4fcbf17f0951b198dd7668292.png',
    chainId: SupportedChainId.POLYGON,
  },
  {
    symbol: 'NACHO',
    name: 'NACHO',
    decimals: 18,
    address: '0xcd86152047e800d67bdf00a4c635a8b6c0e5c4c2',
    logoURI: 'https://tokens.1inch.io/0xcd86152047e800d67bdf00a4c635a8b6c0e5c4c2.png',
    chainId: SupportedChainId.POLYGON,
  },
  {
    symbol: 'INST',
    name: 'Instadapp (PoS)',
    decimals: 18,
    address: '0xf50d05a1402d0adafa880d36050736f9f6ee7dee',
    logoURI: 'https://tokens.1inch.io/0xf50d05a1402d0adafa880d36050736f9f6ee7dee.png',
    chainId: SupportedChainId.POLYGON,
  },
  {
    symbol: 'WIXS',
    name: 'Wrapped Ixs Token',
    decimals: 18,
    address: '0x1ba17c639bdaecd8dc4aac37df062d17ee43a1b8',
    logoURI: 'https://tokens.1inch.io/0x1ba17c639bdaecd8dc4aac37df062d17ee43a1b8.png',
    chainId: SupportedChainId.POLYGON,
  },
  {
    symbol: 'agEUR',
    name: 'agEUR',
    decimals: 18,
    address: '0xe0b52e49357fd4daf2c15e02058dce6bc0057db4',
    logoURI: 'https://tokens.1inch.io/0xe0b52e49357fd4daf2c15e02058dce6bc0057db4.png',
    chainId: SupportedChainId.POLYGON,
  },
  {
    symbol: 'NFTI',
    name: 'Scalara NFT Index (PoS)',
    decimals: 18,
    address: '0xc75ea0c71023c14952f3c7b9101ecbbaa14aa27a',
    logoURI: 'https://tokens.1inch.io/0xc75ea0c71023c14952f3c7b9101ecbbaa14aa27a.png',
    chainId: SupportedChainId.POLYGON,
  },
  {
    symbol: 'deUSDC',
    name: 'deBridge USD Coin',
    decimals: 6,
    address: '0x1ddcaa4ed761428ae348befc6718bcb12e63bfaa',
    logoURI: 'https://tokens.1inch.io/0x1ddcaa4ed761428ae348befc6718bcb12e63bfaa_2.png',

    chainId: SupportedChainId.POLYGON,
  },
  {
    symbol: 'FRAX',
    name: 'Frax',
    decimals: 18,
    address: '0x45c32fa6df82ead1e2ef74d17b76547eddfaff89',
    logoURI: 'https://tokens.1inch.io/0x45c32fa6df82ead1e2ef74d17b76547eddfaff89.png',

    chainId: SupportedChainId.POLYGON,
  },
  {
    symbol: 'FODL',
    name: 'Fodl (PoS)',
    decimals: 18,
    address: '0x5314ba045a459f63906aa7c76d9f337dcb7d6995',
    logoURI: 'https://tokens.1inch.io/0x5314ba045a459f63906aa7c76d9f337dcb7d6995.png',
    chainId: SupportedChainId.POLYGON,
  },
  {
    symbol: 'BUSDbs',
    name: 'Binance USD (Wormhole from BSC)',
    decimals: 18,
    address: '0xa8d394fe7380b8ce6145d5f85e6ac22d4e91acde',
    chainId: SupportedChainId.POLYGON,
    logoURI: 'https://raw.githubusercontent.com/certusone/wormhole-token-list/main/assets/BUSDbs_wh.png',
  },
  {
    address: '0xE6469Ba6D2fD6130788E0eA9C0a0515900563b59',
    chainId: SupportedChainId.POLYGON,
    decimals: 6,
    logoURI:
      'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9vMJfxuKxXBoEa7rM12mYLMwTacLMLDJqHozw96WQL8i/logo.png',
    name: 'UST (Wormhole)',
    symbol: 'UST',
  },
]
