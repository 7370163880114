import { SupportedChainId } from 'config/constants/chains'
import { BaseCurrency } from './baseCurrency'
import { CurrencyAmount } from '.'

// export interface Currency {
//   readonly chainId: SupportedChainId;
//   readonly address: string;
//   readonly name: string;
//   readonly decimals: number;
//   readonly symbol: string;
//   readonly logoURI?: string;
//   readonly extensions?: {
//     readonly [key: string]: { [key: string]: ExtensionValue } | ExtensionValue;
//   };
// }

/**
 * Represents an fungible token with a unique address and some metadata.
 */
export class Currency extends BaseCurrency {
  /**
   * The contract address on the chain on which this token lives
   */
  public readonly address: string

  public balance?: CurrencyAmount
  public coinGeckoId?: CurrencyAmount

  public constructor(
    chainId: SupportedChainId,
    address: string,
    decimals: number,
    symbol?: string,
    name?: string,
    logo?: string,
    balance = new CurrencyAmount(0),
  ) {
    super(chainId, decimals, symbol, name, logo)
    this.address = address
    this.balance = balance
  }

  /**
   * Returns true if the two tokens are equivalent, i.e. have the same chainId and address.
   * @param other other token to compare
   */
  public equals?(other: Currency): boolean {
    return this.chainId === other.chainId && this.address === other.address
  }
}
