import { enableMapSet } from 'immer'

enableMapSet()
import BigNumber from 'bignumber.js'
import useEagerConnect from 'hooks/wallets/useEagerConnect'
import useSentryUser from 'hooks/useSentryUser'
import useUserAgent from 'hooks/useUserAgent'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import React, { Fragment } from 'react'
import { PersistGate } from 'redux-persist/integration/react'
import { useStore, persistor } from 'state'
import { NextPage } from 'next'
import { Updaters } from '..'
import Providers from '../Providers'
import GlobalStyle from '../style/Global'
import LayoutWrapper from 'components/Layout/LayoutWrapper'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../custom.scss'
import { useCryptoUSDRates } from 'state/global/hooks'
// This config is required for number formatting
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

function GlobalHooks() {
  useEagerConnect()
  useUserAgent()
  useSentryUser()
  useCryptoUSDRates()
  return null
}

function MyApp(props: AppProps) {
  const { pageProps } = props
  const store = useStore(pageProps['initialReduxState'])

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=5, minimum-scale=1, viewport-fit=cover"
        />
        <meta name="description" content="Atlas DEX | DeFi Cross-chain Aggregator" />
        <meta name="theme-color" content="#1FC7D4" />
        <meta name="twitter:image" content="" />
        <meta name="twitter:description" content="Atlas DEX | DeFi Cross-chain Aggregator" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Atlas DEX | DeFi Cross-chain Aggregator" />
        <title>Atlas Dex</title>
      </Head>
      <Providers store={store}>
        <GlobalHooks />
        <Updaters />
        <GlobalStyle />
        <PersistGate loading={null} persistor={persistor}>
          <App {...props} />
        </PersistGate>
      </Providers>
    </>
  )
}

type NextPageWithLayout = NextPage & {
  Layout?: React.FC
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

const App = ({ Component, pageProps }: AppPropsWithLayout) => {
  // Use the layout defined at the page level, if available
  const Layout = LayoutWrapper || Fragment
  return (
    <Layout>
      <Component {...pageProps} />
    </Layout>
  )
}

export default MyApp
