import { ReactNode } from 'react'
import styled from 'styled-components'
import {
  border,
  BorderProps,
  fontFamily,
  FontFamilyProps,
  fontWeight,
  FontWeightProps,
  layout,
  LayoutProps,
  margin,
  MarginProps,
  PaddingProps,
} from 'styled-system'
import SpinnerCircle from '../SpinnerCircle'

type Variant = 'primary' | 'secondary'

interface IButton extends PaddingProps, MarginProps, BorderProps, FontFamilyProps, FontWeightProps, LayoutProps {
  variant?: Variant
  children?: ReactNode
  onClick?: () => void
  isLoading?: boolean
  disabled?: boolean
  className?: string
}

const Button: React.FC<IButton> = (props: IButton) => {
  const { variant = 'primary', children, isLoading, disabled, onClick, className } = props

  return (
    <ButtonOutline variant={variant} className={className} {...props} onClick={null}>
      <StyledButton variant={variant} className={className} {...props} onClick={disabled ? null : onClick}>
        {isLoading ? <SpinnerCircle radius={10} /> : children}
      </StyledButton>
    </ButtonOutline>
  )
}

const ButtonOutline = styled.div<{ variant: Variant; className: string }>`
  background: ${(props) => (props.variant === 'secondary' ? props.theme.colors.gradientBackground : 'transparent')};
  padding: 1px;
  border-radius: 2px;
  &:hover {
    opacity: 0.8;
  }
`

const StyledButton = styled.button<{ variant: Variant; disabled?: boolean }>`
  border: none;
  outline: none;
  background: ${(props) =>
    props.variant === 'primary' ? props.theme.colors.gradientBackground : props.theme.colors.background};
  color: ${({ theme }) => theme.colors.text};
  font-family: ${({ theme }) => theme.fonts.primary};
  font-weight: ${({ theme }) => theme.fonts.semiBold};
  border-radius: 12px;
  padding: 0.65rem 1.5rem;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};

  ${margin}
  ${border}
  ${fontFamily}
  ${fontWeight}
  ${layout}
`

export default Button
