import { useCallback } from 'react'
import * as Sentry from '@sentry/react'

import { Currency, CurrencyAmount } from 'config/entities'
import { SupportedChainId } from 'config/constants/chains'
import { ethers } from 'ethers'
import { getEVMNodeURL } from 'utils/getRpcUrl'
import use1inchForRelayer from 'views/Swap/Default/hooks/use1inchForRelayer'
import { getNativeCurrency, getStableCurrency, getGasLimit } from 'utils/getNativeCurrency'
import { RelayerOutput } from 'config/entities/RelayerOutput'
import { useCurrencyDollarPrice } from 'hooks/useCurrencyPrice'
import useJupiterForRelayer from 'views/Swap/Default/hooks/useJupiterForRelayer'

const useGetRelayerFee = () => {
  const { handle1InchForRelayer } = use1inchForRelayer()
  const { handleJupiterForRelayer } = useJupiterForRelayer()

  const { getCurrencyDollarPrice } = useCurrencyDollarPrice()

  const getRelayerFee = useCallback(
    async (
      inputChainId: SupportedChainId,
      outputChainId: SupportedChainId,
      inputLockCurrency: Currency,
      outputMintCurrency: Currency,
    ): Promise<RelayerOutput> => {
      try {
        const stableCurrency = getStableCurrency[outputChainId]
        const nativeCurrency = getNativeCurrency[outputChainId]
        const providerURL = getEVMNodeURL[outputChainId]
        const gasLimit = getGasLimit[outputChainId]
        const provider = new ethers.providers.JsonRpcProvider(providerURL)

        const enhancedPrice = await provider.getGasPrice()

        const gasFee = new CurrencyAmount(enhancedPrice.mul(gasLimit).toString(), nativeCurrency.decimals)

        const relayerFeeDollarValue =
          (await getCurrencyDollarPrice(nativeCurrency.coinGeckoId)) * gasFee.toEther().toNumber()
        let relayerFee = new CurrencyAmount(0, outputMintCurrency.decimals)
        if (outputChainId === SupportedChainId.SOLANA) {
          relayerFee = await handleJupiterForRelayer(
            stableCurrency,
            outputMintCurrency,
            new CurrencyAmount(relayerFeeDollarValue, stableCurrency.decimals, false),
          )
        } else {
          relayerFee = await handle1InchForRelayer(nativeCurrency, outputMintCurrency, gasFee)
        }

        return {
          relayerFee,
          relayerFeeDollarValue: relayerFee.toEther().toNumber() > 0 ? relayerFeeDollarValue : 0,
        }
      } catch (error) {
        console.log(error)
        Sentry.captureException(error)
        return {
          relayerFee: new CurrencyAmount(0, inputLockCurrency.decimals),
          relayerFeeDollarValue: 0,
        }
      }
    },
    [],
  )

  return { getRelayerFee }
}

export default useGetRelayerFee
